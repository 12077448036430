import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';
import { getRecommendationsByCategoryId } from 'modules/products/utils/getRecommendationsByCategory';
import { FC, useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any, string>;
  categoryId: number;
};

const { Paragraph } = Typography;

const StorageRecommendations: FC<PropTypes> = ({ controlled, categoryId }) => {
  const recommendationsByCategory = getRecommendationsByCategoryId(categoryId);
  const { field } = useController({
    ...controlled,
    defaultValue: recommendationsByCategory,
  });
  const [recommendations, setRecommendations] = useState<string[]>(
    recommendationsByCategory
  );

  const onRecommendationsChange = (value: string, index: number) => {
    const updatedRecommendations = recommendations.reduce((recs, text, i) => {
      if (!value && i === index) {
        return recs;
      }
      if (value && i === index) {
        return [...recs, value];
      }
      return [...recs, text];
    }, [] as string[]);

    setRecommendations(updatedRecommendations);
  };

  const onAddRecommendation = (value: string) => {
    if (!value) return;
    setRecommendations([...recommendations, value]);
  };

  useEffect(() => {
    field.onChange(recommendations);
  }, [recommendations]);

  useEffect(() => {
    setRecommendations(getRecommendationsByCategoryId(categoryId));
  }, [categoryId]);

  return (
    <Row gutter={[0, 5]}>
      {recommendations.map((text, i) => {
        return (
          <Col key={`${text}_${i}`} span={24}>
            <Paragraph
              className={styles.typography}
              editable={{
                triggerType: ['icon', 'text'],
                onChange: (value) => {
                  onRecommendationsChange(value, i);
                },
              }}
            >
              {text}
            </Paragraph>
          </Col>
        );
      })}
      <Col span={24}>
        <Paragraph
          className={styles.addRecommendation}
          editable={{
            icon: (
              <>
                <PlusOutlined />
              </>
            ),
            tooltip: 'Add',
            triggerType: ['text', 'icon'],
            onChange: onAddRecommendation,
          }}
        />
      </Col>
    </Row>
  );
};

export default StorageRecommendations;
