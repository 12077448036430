import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal, message, Popconfirm } from 'antd';
import { FormItem, Spin, Button } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateDonationCommentMutation } from 'types.d';

import { addCommentFormFields } from './fields';
import {
  addDonationCommentSchema,
  AddDonationCommentSchemaType,
} from './schema';

type Props = BaseModalProps & { donationId: string; previousValue: string };

const AddDonationCommentModal: FC<Props> = ({
  visible,
  donationId,
  hide,
  previousValue,
}) => {
  const { t } = useTranslation('donor.AddCommentModal');

  const { control, handleSubmit, formState, reset } =
    useForm<AddDonationCommentSchemaType>({
      resolver: yupResolver(addDonationCommentSchema),
      mode: 'onChange',
    });

  const [updateDonationComment, { loading }] = useUpdateDonationCommentMutation(
    {
      refetchQueries: ['getDonationList'],
      onCompleted: () => {
        message.success(t('commentSaved'));
        reset();
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  const submitForm = handleSubmit((formData) => {
    updateDonationComment({
      variables: {
        input: {
          id: donationId,
          comment: formData.comment,
        },
      },
    });
  });

  const okHandler = async () => {
    await submitForm();
    reset();
    hide();
  };

  const cancelHandler = () => {
    hide();
    reset();
  };

  useEffect(() => {
    reset({ comment: previousValue });
  }, [previousValue]);

  return (
    <Spin spinning={loading}>
      <Modal
        title={t('addDonationCommentModal')}
        visible={visible}
        onCancel={cancelHandler}
        footer={
          <>
            <Button onClick={cancelHandler}> {t('cancel')}</Button>
            <Popconfirm title={t('areYouSureSaveEdits')} onConfirm={okHandler}>
              <Button type="primary">{t('save')}</Button>
            </Popconfirm>
          </>
        }
      >
        <Form>
          {addCommentFormFields.map(({ Component, label, name }) => {
            const errorMessage = formState.errors[name]?.message;
            return (
              <FormItem
                key={name}
                label={label}
                extra={errorMessage}
                validateStatus={errorMessage && 'error'}
              >
                <Component control={control} />
              </FormItem>
            );
          })}
        </Form>
      </Modal>
    </Spin>
  );
};

export default AddDonationCommentModal;
