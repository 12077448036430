import { ControlledInput } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

enum CreateTeamFields {
  title = 'title',
}

const t = getTranslation('donor.CreateTeamModal');

const createTeamFormFields: FormFieldWithComponent<CreateTeamFields>[] = [
  {
    name: CreateTeamFields.title,
    label: t('title'),
    Component: ({ control }) => (
      <ControlledInput
        controlled={{ control, name: CreateTeamFields.title }}
        placeholder={t('enterTitle')}
      />
    ),
  },
];

export { createTeamFormFields, CreateTeamFields };
