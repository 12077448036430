import { Row, Col, Table, Grid, message, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import config from 'config';
import { SearchInput, Spin } from 'modules/common/components';
import { useDebounce, useSort } from 'modules/common/hooks';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  RequestListFilterInput,
  RequestOutput,
  useGetRequestListQuery,
  Order,
  RequestListSort,
  PermissionsEnum,
} from 'types.d';

import { getColumns } from './columns';
import styles from './styles.module.scss';

const RequestsTable: FC = () => {
  const { debounce, clearDebounce } = useDebounce();
  const [filter, setFilter] = useState<RequestListFilterInput>();
  const sort = useSort<RequestListSort>();
  const breakpoints = Grid.useBreakpoint();
  const pagination = usePagination();
  const currentUser = useCurrentUser();
  const canUserProcessRequest = currentUser.isCan(
    PermissionsEnum.ApproveDisapproveRequests
  );

  const { data, loading } = useGetRequestListQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        sort: sort.options,
        filter,
      },
    },
    onCompleted: (result) => {
      pagination.setTotal(result.getRequestList.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const requestList = data?.getRequestList.data as RequestOutput[];

  useEffect(() => clearDebounce, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    setFilter({
      number: Number(searchQuery) || undefined,
      ownerName:
        !Number(searchQuery) && searchQuery.length > 0
          ? searchQuery
          : undefined,
    });
  };
  const columns = useMemo(() => {
    return getColumns({ breakpoints, canUserProcessRequest });
  }, [breakpoints, currentUser]);

  const onTableChange: TableProps<RequestOutput>['onChange'] = (
    _,
    __,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<RequestOutput>;
      const fieldName =
        RequestListSort[
          tableSorter.columnKey?.toString() as keyof typeof RequestListSort
        ];
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        sort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        sort.removeSortOption(fieldName);
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <SearchInput onChange={debounce(handleSearch)} />
        </Col>
        <Col span={24}>
          <Table
            rowClassName={styles.row}
            rowKey={({ id }) => id}
            columns={columns}
            dataSource={requestList}
            onChange={onTableChange}
            pagination={pagination.config}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default RequestsTable;
