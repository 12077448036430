import { message } from 'antd';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FileOutput,
  UserAttachmentOutput,
  useDetachUserFileMutation,
  useGetUserAttachmentsQuery,
} from 'types.d';
import { fetchWithAuth } from 'utils/fetchWithAuth';
import { getAuthHeaders } from 'utils/getAuthHeaders';

import { UserAttachmentsTableProps } from './types';

export const useUserAttachmentsTable = (props: UserAttachmentsTableProps) => {
  const { userId } = props;
  const [showUploadList, setShowUploadedList] = useState(true);
  const { t } = useTranslation('owner.OwnerAttachmentsTable');
  const getUserAttachmentsQuery = useGetUserAttachmentsQuery({
    variables: {
      input: { userId },
    },
  });
  const [detachUserFile, detachUserFileMutation] = useDetachUserFileMutation({
    refetchQueries: ['getUserAttachments'],
    onCompleted: () => {
      message.success(t('fileDeleted'));
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const downloadFileHandler = async (file: FileOutput) => {
    const response = await fetchWithAuth(
      `${process.env.REACT_APP_SERVER_URI}/user/attachment/${file.id}`,
      {
        method: 'GET',
      }
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${file?.filename}`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  const isLoading = [getUserAttachmentsQuery, detachUserFileMutation].some(
    ({ loading }) => loading
  );
  const ownerAttachments: UserAttachmentOutput[] =
    getUserAttachmentsQuery.data?.getUserAttachments || [];

  const onChangeUploadFile = (uploadParams: UploadChangeParam) => {
    setShowUploadedList(true);
    if (uploadParams.file.status === 'done') {
      message.success(t('fileUploaded'));
      setShowUploadedList(false);
      getUserAttachmentsQuery.refetch({
        input: {
          userId,
        },
      });
    }
  };

  const detachFileHandler = (fileId: string) => {
    detachUserFile({
      variables: {
        input: {
          fileId,
          id: userId,
        },
      },
    });
  };

  const beforeUpload = (file: RcFile) => {
    const isLessThan1Mb = file.size / 1024 / 1024 < 1;
    if (!isLessThan1Mb) {
      message.error(t('fileMustBeLessThan', { size: 1 }));
      (file as UploadChangeParam['file']).status = 'error';
    }

    return isLessThan1Mb;
  };

  const uploadAttachmentProps: UploadProps = {
    showUploadList,
    name: 'file',
    action: `${process.env.REACT_APP_SERVER_URI}/user/attachment/upload`,
    headers: getAuthHeaders(),
    maxCount: 1,
    onChange: onChangeUploadFile,
    beforeUpload,
    data: {
      userId,
    },
  };

  return {
    isLoading,
    ownerAttachments,
    uploadAttachmentProps,
    downloadFileHandler,
    detachFileHandler,
  };
};
