import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, message, Row } from 'antd';
import { Button, FormItem, Spin } from 'modules/common/components';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateLocationMutation } from 'types.d';

import { getFormFields } from './fields';
import { IAddLocationsFormSchema, addLocationsFormSchema } from './schema';

const CreateLocationsForm: FC = () => {
  const { t } = useTranslation('user.CreateLocationsForm');
  const { control, formState, handleSubmit, reset } =
    useForm<IAddLocationsFormSchema>({
      resolver: yupResolver(addLocationsFormSchema),
      reValidateMode: 'onChange',
      mode: 'onChange',
      defaultValues: {
        name: undefined,
        type: undefined,
      },
    });
  const [createLocation, createLocationMutation] = useCreateLocationMutation({
    refetchQueries: ['getLocations'],
    onCompleted: () => {
      message.success(t('locationCreated'));
      reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const formFields = useMemo(() => {
    return getFormFields({ control });
  }, []);

  const submitForm = handleSubmit((formData) => {
    createLocation({
      variables: {
        input: formData,
      },
    });
  });

  return (
    <Spin spinning={createLocationMutation.loading}>
      <Form>
        <Row gutter={[10, 0]} align="top">
          {formFields.map((field) => {
            const { Component, getErrorMessage } = field;
            const errorMessage = getErrorMessage(formState.errors);
            const validateStatus = errorMessage && 'error';
            return (
              <Col span={6}>
                <FormItem extra={errorMessage} validateStatus={validateStatus}>
                  <Component />
                </FormItem>
              </Col>
            );
          })}
          <Col span={6}>
            <FormItem>
              <Button
                htmlType="submit"
                onClick={submitForm}
                type="primary"
                block
                disabled={!formState.isValid}
              >
                {t('create')}
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateLocationsForm;
