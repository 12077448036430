import { gql } from '@apollo/client';
import {
  CONTACT_OUTPUT_FRAGMENT,
  ROLE_OUTPUT_FRAGMENT,
  USER_OUTPUT_FRAGMENT,
} from 'modules/user/graphql/fragments';

export const SIGN_UP_MUTATION = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${ROLE_OUTPUT_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${USER_OUTPUT_FRAGMENT}
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation confirmEmail($input: EmailConfirmationInput!) {
    confirmEmail(input: $input)
  }
`;

export const MAKE_NOTIFICATIONS_READ_MUTATION = gql`
  mutation makeNotificationsRead {
    makeNotificationsRead
  }
`;

export const DELETE_NOTIFICATION_MUTATION = gql`
  mutation deleteNotification($input: RemoveNotificationInput!) {
    deleteNotification(input: $input)
  }
`;

export const ARCHIVE_OWNER_MUTATION = gql`
  mutation archiveUser($input: ArchiveUserInput!) {
    archiveUser(input: $input)
  }
`;

export const UNARCHIVE_OWNER_MUTATION = gql`
  mutation unArchiveUser($input: ArchiveUserInput!) {
    unArchiveUser(input: $input)
  }
`;

export const SIGN_UP_WITH_DONOR = gql`
  mutation signUpWithDonor($input: SignUpWithDonorInput!) {
    signUpWithDonor(input: $input)
  }
`;
