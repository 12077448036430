import { Col } from 'antd';
import { FormItem } from 'modules/common/components';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getDonorInfoFields } from './fields';

type Props = {
  fieldNamePrefix: string;
  enabled: boolean;
  sectionNamePrefix?: string;
};

const DonorInfoSection: FC<Props> = ({
  enabled,
  fieldNamePrefix,
  sectionNamePrefix,
}) => {
  const formContext = useFormContext();
  const fields = useMemo(() => getDonorInfoFields({ enabled }), [enabled]);

  return (
    <>
      {fields.map(({ name, Component, label }) => {
        const sectionName = sectionNamePrefix ? sectionNamePrefix + '.' : '';
        const fieldName = `${sectionName}${fieldNamePrefix}.${name}`;
        const sectionErrors = sectionNamePrefix
          ? formContext.formState.errors[sectionNamePrefix]
          : formContext.formState.errors;
        const errorMessage = sectionErrors?.[fieldNamePrefix]?.[name]?.message;

        return (
          <Col span={24} key={name}>
            <FormItem
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
              label={label}
            >
              <Component control={formContext.control} name={fieldName} />
            </FormItem>
          </Col>
        );
      })}
    </>
  );
};

export default DonorInfoSection;
