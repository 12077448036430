import { AppointmentType } from 'modules/appointments/enums';
import { Select } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { appointmentTypes } from './options';

type PropTypes = {
  controlled: UseControllerProps<any>;
  disableDaycareSelect?: boolean;
} & Omit<ComponentProps<typeof Select>, 'options'>;

const AppointmentTypeSelect: FC<PropTypes> = ({
  controlled,
  disableDaycareSelect,
  ...restProps
}) => {
  const controller = useController(controlled);
  const options = appointmentTypes.filter((item) => {
    if (!disableDaycareSelect) {
      return true;
    }

    return item.value !== AppointmentType.Daycare;
  });

  return (
    <Select
      options={options}
      adjustDropdownPosition
      {...restProps}
      filterOption={false}
      {...controller.field}
    />
  );
};

export default AppointmentTypeSelect;
