import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation refreshToken {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation {
    logout
  }
`;

export const RESEND_EMAIL_CONFIRMATION_CODE_MUTATION = gql`
  mutation resendEmailConfirmationCode(
    $input: ResendEmailConfirmationCodeInput!
  ) {
    resendEmailConfirmationCode(input: $input)
  }
`;
