import { EditOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import { Routes } from 'config/routes';
import { Button, Link } from 'modules/common/components';
import LocaleDate from 'modules/common/components/LocaleDate';
import OutcomeCell from 'modules/donations/components/OutcomeCell';
import { DonationOutput, DonationListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.DonorDonationsTable');

type Props = {
  updateDonationHandler: (donationId: string, previousValue?: string) => void;
  canAddCommentsToDonation: boolean;
  productCategories?: Record<number, string>;
};

export const getColumns = ({
  updateDonationHandler,
  canAddCommentsToDonation,
  productCategories,
}: Props): ColumnsType<DonationOutput> => [
  {
    title: t('donationId'),
    render: ({ shortId }: DonationOutput) => (
      <Link to={`${Routes.Donations}/${shortId}`}>{shortId}</Link>
    ),
    align: 'center',
  },
  {
    title: t('date'),
    key: DonationListSort.LastDonationDate,
    sorter: true,
    render: (row: DonationOutput) => (
      <LocaleDate dateValue={row.donationDate} />
    ),
    align: 'center',
  },
  {
    title: t('location'),
    render: (row: DonationOutput) => row.donor.location,
    align: 'center',
  },
  {
    title: t('comments'),
    render: (row: DonationOutput) => (
      <Row justify="center">
        {!row.comment && canAddCommentsToDonation && (
          <Col>
            <Button onClick={() => updateDonationHandler(row.id)}>
              {t('addComment')}
            </Button>
          </Col>
        )}
        <Col>
          {row.comment && (
            <Row>
              <Col className={styles.comment}>
                <abbr title={row.comment} className={styles.abbreviation}>
                  {row.comment}
                </abbr>
              </Col>
              {canAddCommentsToDonation && (
                <Col>
                  <EditOutlined
                    onClick={() => updateDonationHandler(row.id, row.comment!)}
                  />
                </Col>
              )}
            </Row>
          )}
        </Col>
        {!row.comment && !canAddCommentsToDonation && '-'}
      </Row>
    ),
    align: 'center',
  },
  {
    title: t('products'),
    align: 'center',
    render: (row: DonationOutput) => {
      const isCellFilled = Boolean(row.products?.length) || row.ineligible;
      return (
        <>
          {isCellFilled ? (
            <OutcomeCell donation={row} productCategories={productCategories} />
          ) : (
            '-'
          )}
        </>
      );
    },
  },
];
