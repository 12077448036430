import { SelectProps } from 'antd';
import { Select } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { ComponentProps, FC, useEffect } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DonorOutput,
  UserRole,
  useGetCurrentUserDonorsListLazyQuery,
  useGetDonorsListLazyQuery,
} from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
  onSelect?: (donor: DonorOutput) => void;
  daycare?: boolean;
  locations?: string[];
  selectedDonorIds?: string[];
} & Omit<ComponentProps<typeof Select>, 'options' | 'onSelect'>;

const DaycareDonorSelect: FC<PropTypes> = ({
  controlled,
  className,
  onSelect,
  locations,
  selectedDonorIds,
  ...restProps
}) => {
  const classNames = [className || ''].join(' ');
  const { t } = useTranslation('donor.DonorSelect');
  const controller = useController(controlled);
  const currentUser = useCurrentUser();
  const isOwner = currentUser.data?.role.value === UserRole.Owner;
  const { clearDebounce, debounce } = useDebounce();
  const [getCurrentUserDonorsList, getUserDonorsListQuery] =
    useGetCurrentUserDonorsListLazyQuery();
  const [getDonorsList, getDonorsListQuery] = useGetDonorsListLazyQuery();
  const donorList = [
    ...(getUserDonorsListQuery.data?.getCurrentUserDonorsList.data || []),
    ...(getDonorsListQuery.data?.getDonorsList.data || []),
  ];
  const loading = getUserDonorsListQuery.loading || getDonorsListQuery.loading;

  const donorListOptions: SelectProps['options'] = donorList.map((donor) => {
    const disabled = selectedDonorIds?.includes(donor.id);
    return {
      value: donor.id,
      label: (
        <>
          {donor.name} <b>{donor.user?.lastName || ''}</b>
        </>
      ),
      disabled,
    };
  });

  useEffect(() => {
    const input = {
      filter: {
        locations,
        isDaycare: true,
      },
    };

    if (isOwner) {
      getCurrentUserDonorsList({
        variables: {
          input,
        },
      });
    } else {
      getDonorsList({
        variables: {
          input,
        },
      });
    }
  }, [locations]);

  const handleSearch = (value: string) => {
    if (isOwner) {
      getUserDonorsListQuery.refetch({
        input: {
          filter: {
            searchingQuery: value,
            locations,
            isDaycare: true,
          },
        },
      });
      return;
    }

    getDonorsListQuery.refetch({
      input: {
        filter: {
          searchingQuery: value,
          locations,
          isDaycare: true,
        },
      },
    });
  };

  const handleSelect = (donorId: string) => {
    const selectedDonor =
      getUserDonorsListQuery.data?.getCurrentUserDonorsList.data.find(
        ({ id }) => id === donorId
      );
    onSelect && onSelect(selectedDonor as DonorOutput);
  };

  useEffect(() => clearDebounce, []);

  return (
    <Select
      options={donorListOptions}
      placeholder={t('selectOrSearchDonor')}
      {...restProps}
      onSelect={(donorId: any) => handleSelect(donorId)}
      className={classNames}
      showSearch
      mode="multiple"
      adjustDropdownPosition
      loading={loading}
      filterOption={false}
      onSearch={debounce(handleSearch)}
      defaultActiveFirstOption={false}
      {...controller.field}
    />
  );
};

export default DaycareDonorSelect;
