import { gql } from '@apollo/client';

export const NOTIFICATION_CONFIG_FRAGMENT = gql`
  fragment NotificationConfig on NotificationConfigOutput {
    name
    value
  }
`;

export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on NotificationOutput {
    id
    type
    isRead
    createdAt
  }
`;

export const NOTIFICATION_SUBSCRIPTION_FRAGMENT = gql`
  fragment NotificationSubscription on NotificationWebSocketOutput {
    id
    type
    isRead
  }
`;
