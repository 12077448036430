import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { ListTableDataType } from 'modules/common/types';

import styles from './styles.module.scss';

type PropTypes<T = { [key: string]: any }> = {
  data: (ListTableDataType & T)[];
  expandable?: ExpandableConfig<ListTableDataType & T>;
};

const ListTable = <T,>({ data, expandable }: PropTypes<T>) => {
  const columns: ColumnsType<ListTableDataType & T> = [
    {
      render: ({ key }) => `${key}:`,
    },
    {
      render: ({ value }) => value,
    },
  ];

  return (
    <Table
      showHeader={false}
      className={styles.table}
      dataSource={data}
      columns={columns}
      pagination={false}
      expandable={expandable}
    />
  );
};

export default ListTable;
