import { Col, Row } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { isPast, isToday } from 'date-fns';
import { AppointmentsCalendarSchemaType } from 'modules/appointments/components/AppointmentsCalendar/schema';
import MembersSelect from 'modules/appointments/components/MembersSelect';
import ModeTabs from 'modules/appointments/components/ModeTabs';
import MonthSelect from 'modules/appointments/components/MonthSelect';
import { TypeSelect } from 'modules/appointments/components/TypeSelect';
import YearSelect from 'modules/appointments/components/YearSelect';
import { useCreateAppointmentsModalContext } from 'modules/appointments/contexts/CreateAppointmentsModal';
import { Button } from 'modules/common/components';
import { LocationSelect } from 'modules/donor/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum, UserRole } from 'types.d';

import styles from './styles.module.scss';

import OwnerLocationFilterSelect from '../OwnerLocationFilterSelect';

type PropTypes = {
  value: Date;
  type: CalendarMode;
  onChange: (date: Date) => void;
  onTypeChange: (type: CalendarMode) => void;
  control: Control<AppointmentsCalendarSchemaType, any>;
};

const AppointmentsCalendarHeader: FC<PropTypes> = ({
  value,
  type,
  onChange,
  onTypeChange,
  control,
}) => {
  const { t } = useTranslation('appointments.AppointmentsCalendar.Header');
  const currentUser = useCurrentUser();
  const currentUserRole = currentUser?.data?.role.value;
  const isAllowedAppointmentCreation =
    (currentUserRole &&
      currentUserRole !== UserRole.Owner &&
      currentUser.isCan(PermissionsEnum.CreateTimeSlotOrAppointment)) ||
    false;
  const createAppointmentsModal = useCreateAppointmentsModalContext();
  const selectedDate = createAppointmentsModal?.modalConfig.selectedDate;
  const isSelectedDatePasted = selectedDate && isPast(selectedDate);
  const isSelectedDateToday = selectedDate && isToday(selectedDate);
  const isCreationButtonDisabled = isSelectedDateToday
    ? false
    : isSelectedDatePasted;

  return (
    <Row justify="end" gutter={[8, 8]}>
      <Col flex={1} />
      {currentUserRole !== UserRole.Owner && (
        <Col>
          <TypeSelect controlled={{ control, name: 'type' }} />
        </Col>
      )}
      {currentUserRole !== UserRole.Owner && (
        <Col>
          <MembersSelect
            controlled={{ control, name: 'userIds' }}
            className={styles.usersFilter}
          />
        </Col>
      )}
      <Col>
        {currentUserRole !== UserRole.Owner ? (
          <LocationSelect
            multiply
            maxTagCount="responsive"
            className={styles.locationSelect}
            controlled={{ control, name: 'location' }}
          />
        ) : (
          <OwnerLocationFilterSelect
            className={styles.locationSelect}
            controlled={{ control, name: 'location' }}
          />
        )}
      </Col>
      <Col>
        <YearSelect date={value} onChange={onChange} />
      </Col>
      <Col>
        <MonthSelect date={value} onChange={onChange} />
      </Col>
      <Col>
        <ModeTabs mode={type} onChange={onTypeChange} />
      </Col>
      {isAllowedAppointmentCreation && (
        <Col>
          <Button
            disabled={isCreationButtonDisabled}
            onClick={createAppointmentsModal?.showModal}
            type="primary"
          >
            {t('create')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default AppointmentsCalendarHeader;
