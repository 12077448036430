import { Button, Popconfirm, message } from 'antd';
import { Routes } from 'config/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRemoveOwnerMutation } from 'types.d';

type PropTypes = {
  ownerId?: string;
};

export const DeleteOwnerButton: FC<PropTypes> = ({ ownerId }) => {
  const { t } = useTranslation('owner.EditOwnerProfileForm.deleteButton');
  const [deleteOwner, deleteOwnerMutation] = useRemoveOwnerMutation();
  const navigation = useNavigate();

  const handleDelete = async () => {
    if (!ownerId) {
      return;
    }

    await deleteOwner({
      variables: {
        input: {
          id: ownerId,
        },
      },
      onCompleted: () => {
        message.success(t('userDeleted'));
        navigation(Routes.Owners);
      },
      onError: () => {
        message.error(t('deleteUserError'));
      },
      update: (cache) => {
        cache.evict({
          fieldName: 'getOwnersList',
        });
      },
    });
  };

  return (
    <Popconfirm
      title={t('sureToDeleteOwner')}
      okText={t('yes')}
      cancelText={t('no')}
      onConfirm={handleDelete}
    >
      <Button danger loading={deleteOwnerMutation.loading}>
        {t('deleteOwner')}
      </Button>
    </Popconfirm>
  );
};
