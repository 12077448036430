import { Routes } from 'config/routes';
import { ITabsItem } from 'modules/common/components/CardTabs';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import React from 'react';

import LocationsTable from '../LocationsTable';
import RegionsTable from '../RegionsTable';

export default function LocationCardTabs() {
  const locations: ITabsItem = {
    content: <LocationsTable />,
    title: 'Locations',
    key: Routes.Locations,
  };
  const regions: ITabsItem = {
    content: <RegionsTable />,
    title: 'Regions',
    key: Routes.Regions,
  };
  return (
    <div>
      <NavigationCardTabs items={[locations, regions]} />
    </div>
  );
}
