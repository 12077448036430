import YesNoRadioGroup from 'modules/donor/components/YesNoRadioGroup';
import { FC, ComponentProps } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof YesNoRadioGroup>;

const ControlledYesNoRadioGroup: FC<PropTypes> = ({
  controlled,
  ...restProps
}) => {
  const controller = useController(controlled);
  return <YesNoRadioGroup {...controller.field} {...restProps} />;
};

export default ControlledYesNoRadioGroup;
