import { FileTextOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Tooltip, Typography } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { format, isPast } from 'date-fns';
import AppointmentMembers from 'modules/appointments/components/AppointmentMembers';
import { useDaycareDetailModalContext } from 'modules/appointments/contexts/DaycareInfoModal';
import { useDaycareRegisterModalContext } from 'modules/appointments/contexts/DaycareRegisterModal';
import { useEditAppointmentsModalContext } from 'modules/appointments/contexts/EditAppointmentModal';
import { useEnrollDonorModalContext } from 'modules/appointments/contexts/EnrollDonorModal';
import { Button, Link } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppointmentDonor,
  AppointmentOutput,
  PermissionsEnum,
  UserRole,
} from 'types.d';

import styles from './styles.module.scss';

const { Text } = Typography;
const {
  DeleteAppointments,
  AddDonorToAppointment,
  CreateTimeSlotOrAppointment,
} = PermissionsEnum;

type PropTypes = {
  appointment: AppointmentOutput;
  onCancelAppointment?: (
    appointment: AppointmentOutput & { donor: AppointmentDonor }
  ) => void;
  onDeleteAppointment?: (appointment: AppointmentOutput) => void;
};

const AppointmentOverlay: FC<PropTypes> = ({
  appointment,
  onCancelAppointment,
  onDeleteAppointment,
}) => {
  const { t } = useTranslation('appointments.AppointmentOverlay');
  const currentUser = useCurrentUser();
  const enrollDonorModal = useEnrollDonorModalContext();
  const editAppointmentsModal = useEditAppointmentsModalContext();
  const registerDaycareModal = useDaycareRegisterModalContext();
  const detailDaycareModal = useDaycareDetailModalContext();

  const { title, start, end, donor, location, group, team, notes } =
    appointment;

  const currentUserRole = currentUser.data?.role.value;
  const isCurrentUserOwner = currentUserRole === UserRole.Owner;
  const isDaycareAppointment = appointment.isDaycare;
  const allowedToDeleteAppointments =
    currentUserRole &&
    !isCurrentUserOwner &&
    currentUser.isCan(DeleteAppointments);
  const isAllowedToEnroll =
    !donor &&
    !group &&
    !isPast(new Date(start)) &&
    currentUser.isCan(AddDonorToAppointment);
  const isAllowedToUnenroll =
    donor && ((isCurrentUserOwner && !isPast(new Date(start))) || false);
  const isAllowedToDelete =
    allowedToDeleteAppointments && !isPast(new Date(start));
  const isAllowedToCreate = currentUser.isCan(CreateTimeSlotOrAppointment);
  const registerButtonDisabled = new Date(appointment.start) < new Date();
  return (
    <Row gutter={[2, 0]}>
      <Col span={21}>
        <Text className={styles.title} ellipsis={{ tooltip: title }}>
          {title}
        </Text>
      </Col>
      {notes && !isCurrentUserOwner && (
        <Col span={3} onClick={(e) => e?.stopPropagation()}>
          <Tooltip title={notes} placement="topRight">
            <FileTextOutlined style={{ fontSize: 20 }} />
          </Tooltip>
        </Col>
      )}

      <Col span={24}>
        {format(new Date(start), config.APPOINTMENT_TIME_FORMAT)} -{' '}
        {format(new Date(end), config.APPOINTMENT_TIME_FORMAT)}
      </Col>
      <Col span={24}>
        {t('location')}:&nbsp;
        <Text ellipsis={{ tooltip: location }} className={styles.location}>
          {location}
        </Text>
      </Col>
      {donor && (
        <Col span={24}>
          {t('donor')}:&nbsp;
          <Link target="_blank" to={`${Routes.Donors}/${donor.shortId}`}>
            <Text
              ellipsis={{ tooltip: `${donor.name} ${donor.user?.lastName}` }}
              className={styles.donorName}
            >
              {`${donor.name} ${donor.user?.lastName || ''}`}
            </Text>
          </Link>
        </Col>
      )}
      {group && team && (
        <Col span={24}>
          {t('team')}:&nbsp;
          <Text ellipsis={{ tooltip: team.title }} className={styles.teamTitle}>
            <Link
              target="_blank"
              to={`${Routes.GroupProfileTeams.replace(
                ':id',
                group.id
              )}?teamId=${team.id}`}
            >
              {team.title}
            </Link>
          </Text>
        </Col>
      )}
      <Col span={24}>
        <Row gutter={5}>
          {isAllowedToCreate &&
            appointment &&
            !isPast(new Date(appointment.start)) && (
              <Col>
                <Button
                  block
                  onClick={() => editAppointmentsModal?.showModal(appointment)}
                >
                  {t('edit')}
                </Button>
              </Col>
            )}
          {isDaycareAppointment && isCurrentUserOwner && (
            <Col span={24}>
              <Button
                block
                disabled={registerButtonDisabled}
                onClick={() => registerDaycareModal?.showModal(appointment)}
              >
                {t('register')}
              </Button>
            </Col>
          )}
          {!isDaycareAppointment ? (
            <Col span={12}>
              <Button
                block
                disabled={!isAllowedToEnroll}
                onClick={() => {
                  isAllowedToEnroll && enrollDonorModal?.showModal(appointment);
                }}
                type="primary"
              >
                {isAllowedToEnroll ? t('enroll') : t('enrolled')}
              </Button>
            </Col>
          ) : (
            !isCurrentUserOwner && (
              <Col span={12}>
                <Button
                  onClick={() => detailDaycareModal?.showModal(appointment)}
                  block
                >
                  {t('detail')}
                </Button>
              </Col>
            )
          )}
          {isAllowedToUnenroll && (
            <Col span={12}>
              <Button
                block
                onClick={() => onCancelAppointment?.({ ...appointment, donor })}
                type="primary"
              >
                {t('cancel')}
              </Button>
            </Col>
          )}
          {isAllowedToDelete && (
            <Col span={12}>
              <Button
                block
                onClick={() => onDeleteAppointment?.(appointment)}
                type="primary"
              >
                {t('delete')}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
      <Divider className={styles.divider} plain>
        {t('members')}
      </Divider>
      <Col span={24}>
        <AppointmentMembers members={appointment.users} />
      </Col>
    </Row>
  );
};

export default AppointmentOverlay;
