import { PaperClipOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import config from 'config';
import { format, addDays } from 'date-fns';
import LabworkCardInfo from 'modules/donor/components/LabworkCardInfo';
import { useLabworkAttachmentsModalContext } from 'modules/donor/contexts/LabworkAttachmentsModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { LabworkOutput, LabworkStatus } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  labwork: LabworkOutput;
  updateLabworkHandler: (data: LabworkOutput) => void;
  updateLabworkStatusHandler: (id: string, status: LabworkStatus) => void;
};

const LabworkCard: FC<PropTypes> = (props) => {
  const labworkAttachmentsModal = useLabworkAttachmentsModalContext();
  const canUpdateLabwork =
    useCurrentUser().data?.permissions?.updateLabworkData || false;
  const canShareLabworkResults =
    useCurrentUser().data?.permissions?.shareLabworkResults || false;
  const canDeleteLabwork =
    useCurrentUser().data?.permissions?.deleteLabworkData || false;

  const attributes = {
    ...props,
    canUpdateLabwork,
    canShareLabworkResults,
    canDeleteLabwork,
  };

  const openLabworkAttachmentsModal = () => {
    labworkAttachmentsModal?.showModal({ labworkId: props.labwork.id });
  };

  return (
    <Card
      className={styles.labworkCard}
      title={format(
        addDays(
          new Date(props.labwork.collectionDate),
          config.LABWORK_DURATION
        ),
        config.DATE_FORMAT
      )}
      extra={
        <PaperClipOutlined
          className={styles.clipIcon}
          onClick={openLabworkAttachmentsModal}
        />
      }
    >
      <LabworkCardInfo {...attributes} />
    </Card>
  );
};

export default LabworkCard;
