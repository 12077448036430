import * as donorQueries from './queries/donors';

export const donorCachingQueries = [
  donorQueries.getDonor,
  donorQueries.getDonorCharts,
  donorQueries.getDonorDonations,
  donorQueries.getDonorLSR,
  donorQueries.getLabworks,
  donorQueries.getVitalsParameters,
];
