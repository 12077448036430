import { Row } from 'antd';
import { BundleTab } from 'modules/donor/components/RoutineTest/components/BundleTabs/BundleTab';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.RoutineTest.BundleSection');

const preset = [
  {
    title: t('tabTitle1'),
    subTitle: t('tabSubTitle1'),
    caption: t('caption'),
  },
  {
    title: t('tabTitle2'),
    subTitle: t('tabSubTitle2'),
    caption: t('caption'),
  },
  {
    title: t('tabTitle3'),
    subTitle: t('tabSubTitle3'),
    caption: t('caption'),
  },
  {
    title: t('tabTitle4'),
    subTitle: t('tabSubTitle4'),
    caption: t('caption'),
  },
];

export const BundleTabs: FC = () => {
  return (
    <Row>
      {preset.map((data) => (
        <BundleTab {...data} key={data.title} />
      ))}
    </Row>
  );
};
