import { Row, Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.scss';

type PropTypes = {
  location: string;
  date: string;
  end: string;
  start: string;
};

export const DaycareRegisterModalHeader: FC<PropTypes> = ({
  location,
  date,
  end,
  start,
}) => {
  const { t } = useTranslation('appointments.registerDaycareModal');
  return (
    <>
      <Row>
        <Col span={24} className={styles.title}>
          {location}
        </Col>
      </Row>
      <Row>
        <Col span={24} className={styles.subTitle}>
          {t('donationDay')}
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          className={styles.textCenter}
        >{`${date} ${start} - ${end}`}</Col>
      </Row>
    </>
  );
};
