import { AppointmentType } from 'types.d';
import * as yup from 'yup';

export type AppointmentsCalendarSchemaType = {
  userIds: string[];
  location: string[];
  type: AppointmentType;
};

//TODO: add error messages
export const appointmentsCalendarSchema = yup.object({
  userIds: yup.array(yup.string().required()).required(),
  location: yup.array(yup.string().required()).required(),
  type: yup.string().oneOf(Object.values(AppointmentType)).optional(),
});
