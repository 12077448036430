import { Col } from 'antd';
import Label from 'modules/products/components/Label';
import PrintLabelButton from 'modules/products/components/PrintLabelButton';
import { LabelSize } from 'modules/products/enums';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ProductOutput } from 'types.d';

type PropTypes = {
  products?: ProductOutput[];
  children?: ReactNode;
};

const PrintProductLabels: FC<PropTypes> = ({ products = [], children }) => {
  const [labelSize, setLabelSize] = useState<LabelSize>();
  const [readyToPrint, setReadyToPrint] = useState(false);
  const productLabelsRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => productLabelsRef.current,
    documentTitle: `Products`,
  });

  useEffect(() => {
    if (readyToPrint) {
      handlePrint();
      setReadyToPrint(false);
    }
  }, [readyToPrint, labelSize]);

  return (
    <>
      <Col>
        <PrintLabelButton
          onPrint={(labelSize) => {
            setLabelSize(labelSize);
            setReadyToPrint(true);
          }}
          button={children}
        />
      </Col>
      <Col span={0}>
        <div ref={productLabelsRef}>
          {products.map((product) => {
            return (
              <Label key={product.id} size={labelSize} product={product} />
            );
          })}
        </div>
      </Col>
    </>
  );
};

export default PrintProductLabels;
