import { Grid } from 'antd';
import DonorsTable from 'modules/donor/components/DonorsTable';
import GroupDonorCardList from 'modules/donor/components/GroupDonorCardList';

export const GroupProfileDonorsTab = () => {
  const breakpoints = Grid.useBreakpoint();
  const isBreakpointsEmpty = Object.keys(breakpoints).length === 0;

  const attributes = {
    showRemoveColumn: true,
    showSorting: true,
    showFilters: true,
    showTeamsColumn: true,
  };

  return (
    <>
      {!isBreakpointsEmpty &&
        (breakpoints.md ? (
          <DonorsTable {...attributes} />
        ) : (
          <GroupDonorCardList {...attributes} />
        ))}
    </>
  );
};
