import { UploadOutlined } from '@ant-design/icons';
import { Col, Row, Table, Tooltip, Upload } from 'antd';
import { ItemRender, UploadFile } from 'antd/lib/upload/interface';
import { Button } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './columns';
import { useLabworkAttachmentsTable } from './hook';
import { LabworkAttachmentsTableProps } from './types';

const LabworkAttachmentsTable: FC<LabworkAttachmentsTableProps> = (props) => {
  const { t } = useTranslation('donor.LabworkAttachmentsTable');
  const labworkAttachmentsTable = useLabworkAttachmentsTable(props);
  const { fileList, setFileList } = props;
  const currentUser = useCurrentUser();
  const {
    isLoading,
    labworAttachments,
    removeLabworkFileHandler,
    shareLabworkResultsHandler,
    downloadFileHandler,
    uploadAttachmentProps,
  } = labworkAttachmentsTable;

  const columns = getColumns({
    onDownload: downloadFileHandler,
    onDelete: removeLabworkFileHandler,
    onShare: shareLabworkResultsHandler,
    isCurrentUserAllowed: currentUser.isCan,
  });

  const itemRender: ItemRender<any> = (
    originNode: ReactElement,
    file: UploadFile
  ) => {
    if (file.status === 'error') {
      return <Tooltip title={file?.error}>{originNode.props.children}</Tooltip>;
    }
    return originNode;
  };

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Row justify="end">
          <Col>
            <Upload
              {...{
                ...uploadAttachmentProps,
                data: {
                  labworkId: props.labworkId,
                },
              }}
              fileList={fileList}
              itemRender={itemRender}
              onRemove={() => setFileList([])}
            >
              <Button icon={<UploadOutlined />}>{t('clickToUpload')}</Button>
            </Upload>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          loading={isLoading}
          dataSource={labworAttachments}
          columns={columns}
          pagination={false}
          size="small"
        />
      </Col>
    </Row>
  );
};

export default LabworkAttachmentsTable;
