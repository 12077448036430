import { gql } from '@apollo/client';
import { DONOR_FRAGMENT, FILE_FRAGMENT } from 'modules/donor/graphql/fragments';
import { CONTACT_OUTPUT_FRAGMENT } from 'modules/user/graphql/fragments';

import { OWNER_FRAGMENT, USER_ATTACHMENT_FRAGMENT } from '../fragments';

export const GET_USERS_CONTACTS = gql`
  query getUserContacts {
    getUserContacts {
      streetAddress
      city
      state
      zipCode
      email
      firstName
      lastName
      phoneNumber
      id
    }
  }
`;

export const GET_OWNER_QUERY = gql`
  query getOwner($input: GetUserInput!) {
    getOwner(input: $input) {
      ...Owner
      contacts {
        ...Contact
      }
    }
  }
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

export const GET_USER_DONOR_LIST_QUERY = gql`
  query getUserDonorsList($input: UserDonorListInput!) {
    getUserDonorsList(input: $input) {
      data {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

export const GET_OWNER_ATTACHMENTS_QUERY = gql`
  query getUserAttachments($input: GetUserAttachmentsListInput!) {
    getUserAttachments(input: $input) {
      ...UserAttachment
      file {
        ...File
      }
    }
  }
  ${USER_ATTACHMENT_FRAGMENT}
  ${FILE_FRAGMENT}
`;
