import * as yup from 'yup';

import {
  OptionalFormSectionSchemaType,
  optionalFormSectionSchema,
} from './OptionalSection/schema';
import {
  RequiredFormSectionSchemaType,
  requiredFormSectionSchema,
} from './RequiredSection/schema';

type CreateDonorSchemaType = {
  required: RequiredFormSectionSchemaType;
  optional: OptionalFormSectionSchemaType;
};

const createDonorSchema: yup.SchemaOf<CreateDonorSchemaType> = yup.object({
  required: requiredFormSectionSchema,
  optional: optionalFormSectionSchema,
});

export { createDonorSchema };
export type { CreateDonorSchemaType };
