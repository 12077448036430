import { Row, Col } from 'antd';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import RequestsTable from 'modules/requests/components/RequestsTable';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const RequestsPage: FC = () => {
  const { t } = useTranslation('requests.RequestsPage');

  return (
    <ProfileSideMenuWrapper title={t('requests')}>
      <Row>
        <Col span={24}>
          <RequestsTable />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default RequestsPage;
