import { Checkbox, ControlledCheckbox } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, ComponentProps, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { PermissionsEnum } from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
  disabled?: boolean;
} & ComponentProps<typeof ControlledCheckbox>;

const MedicalDirectorApproveControlledCheckbox: FC<PropTypes> = ({
  controlled,
  disabled,
  ...restProps
}) => {
  const controller = useController(controlled);
  const [selectedValue, setSelectedValue] = useState<number | null>(
    controller.field.value
  );
  const currentUser = useCurrentUser();
  const isPermitted = currentUser.isCan(PermissionsEnum.ApproveDonationMl);

  const handleCheckboxChange = (value: number) => {
    setSelectedValue((prev) => {
      const newValue = prev === value ? null : value;
      controller.field.onChange(newValue);

      return newValue;
    });
  };

  const isDisabled = disabled || !isPermitted;

  return (
    <div>
      <Checkbox
        checked={selectedValue === 250}
        onChange={() => handleCheckboxChange(250)}
        disabled={isDisabled}
        {...restProps}
      >
        250 ML
      </Checkbox>
      <Checkbox
        checked={selectedValue === 420}
        onChange={() => handleCheckboxChange(420)}
        disabled={isDisabled}
        {...restProps}
      >
        420 ML
      </Checkbox>
      <Checkbox
        checked={selectedValue === 450}
        onChange={() => handleCheckboxChange(450)}
        disabled={isDisabled}
        {...restProps}
      >
        450 ML
      </Checkbox>
    </div>
  );
};

export default MedicalDirectorApproveControlledCheckbox;
