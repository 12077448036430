import {
  emailSchema,
  usernameSchema,
  userLastNameSchema,
} from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

type ContactSchemaType = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  phoneNumber?: string | null;
};

const t = getTranslation('owner.ValidationErrors');

const transformEmptyString = (value: string) => (value === '' ? null : value);

const contactSchema: yup.SchemaOf<ContactSchemaType> = yup.object({
  firstName: usernameSchema
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  lastName: userLastNameSchema
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  email: emailSchema.notRequired().nullable().transform(transformEmptyString),
  streetAddress: yup
    .string()
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  city: yup.string().notRequired().nullable().transform(transformEmptyString),
  state: yup.string().notRequired().nullable().transform(transformEmptyString),
  zipCode: yup
    .string()
    .length(5, t('mustBeExactLength', { field: t('zipCode'), value: 5 }))
    .matches(/^[0-9]+$/, t('onlyNumbers'))
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  phoneNumber: yup
    .string()
    .matches(
      /\(___\) ___-____|^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g,
      t('phoneNumberDoesNotMatch')
    )
    .notRequired()
    .nullable()
    .transform((value) =>
      value === '' || value === '(___) ___-____' ? null : value
    ),
});

export { contactSchema };
export type { ContactSchemaType };
