import {
  donationInfoSchema,
  DonationInfoSchemaType,
} from 'modules/donations/components/DonationInfoSection/schema';
import {
  donorSchema,
  DonorSchemaType,
} from 'modules/donations/components/DonorInfoSection/schema';
import {
  environmentSchema,
  EnvironmentSchemaType,
} from 'modules/donations/components/EnvironmentSection/schema';
import {
  ExaminationSchemaType,
  examinationSchema,
} from 'modules/donations/components/ExaminationSection/schema';
import {
  quantitySchema,
  QuantitySchemaType,
} from 'modules/donations/components/QuantitySection/schema';
import { donationDateSchema } from 'modules/donations/schemas';
import * as yup from 'yup';

type VitalsSectionSchemaSchemaType = {
  examination: ExaminationSchemaType;
  donationInfo: DonationInfoSchemaType;
  environment: EnvironmentSchemaType;
  quantity: QuantitySchemaType;
  donor: DonorSchemaType;
  donationDate: Date;
};

const vitalsSectionSchema: yup.SchemaOf<VitalsSectionSchemaSchemaType> =
  yup.object({
    examination: examinationSchema,
    donationInfo: donationInfoSchema,
    environment: environmentSchema,
    quantity: quantitySchema,
    donor: donorSchema,
    donationDate: donationDateSchema,
  });

export { vitalsSectionSchema };
export type { VitalsSectionSchemaSchemaType };
