import { Col, Row } from 'antd';
import EditUserEmailForm from 'modules/admin/components/EditUserForm/EmailSection';
import { UserType } from 'modules/admin/components/EditUserForm/EmailSection/enum';
import { FormItem } from 'modules/common/components';
import ControlledDatePicker from 'modules/common/components/ControlledDatePicker';
import { PersonalInfoFields } from 'modules/owner/components/EditOwnerProfileForm/PersonalInfoSection/enum';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum, UserOutput } from 'types.d';

import { personalInfoFields } from './fields';
import { personalInfoSchema } from './schema';

type Props = {
  fieldNamePrefix: string;
  enabled: boolean;
  owner: UserOutput;
};

const PersonalInfoFormSection: FC<Props> = ({
  fieldNamePrefix,
  enabled,
  owner,
}) => {
  const { t } = useTranslation(
    'owner.EditOwnerProfileForm.PersonalInfoSection'
  );
  const currentUser = useCurrentUser();
  const allowToChangeEmail =
    currentUser.isCan(PermissionsEnum.UpdateOwnersEmail) && !owner.deletedAt;
  const { control, formState } = useFormContext();
  const birthdayError = formState.errors.personalInfo?.birthday?.message;

  return (
    <Col span={24}>
      <h2>{t('personalInfo')}</h2>
      {personalInfoFields.map(({ name, label, Component }) => {
        const fieldName = `${fieldNamePrefix}.${name}`;
        const errorMessage = formState.errors[fieldNamePrefix]?.[name]?.message;
        const isRequired =
          (personalInfoSchema.fields[name] as { spec?: { presence?: string } })
            ?.spec?.presence === 'required';

        return (
          <Row key={fieldName}>
            <Col span={24}>
              <FormItem
                required={isRequired}
                label={label}
                extra={errorMessage}
                validateStatus={errorMessage && 'error'}
              >
                <Component
                  disabled={!enabled}
                  control={control}
                  name={fieldName}
                />
              </FormItem>
            </Col>
          </Row>
        );
      })}
      <Row key={PersonalInfoFields.birthday}>
        <Col span={24}>
          <FormItem
            label={t('birthday')}
            extra={birthdayError}
            validateStatus={birthdayError && 'error'}
          >
            <ControlledDatePicker
              disabled={!enabled}
              placeholder={t('selectBirthdayDate')}
              controlled={{
                control,
                name: `personalInfo.${PersonalInfoFields.birthday}`,
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <EditUserEmailForm
        user={owner}
        canEdit={allowToChangeEmail}
        userType={UserType.Owner}
      />
    </Col>
  );
};

export default PersonalInfoFormSection;
