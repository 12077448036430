import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Col, Form, message, Row } from 'antd';
import { Alert, Button, Spin } from 'modules/common/components';
import AdditionalDonationInfoSection from 'modules/donations/components/CreateDonationForm/AdditionalInfoSection';
import VitalsSection from 'modules/donations/components/CreateDonationForm/VitalsSection';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  DonationOutput,
  useCreateDonationWithAdditionalInfoMutation,
  useGetDonorQuery,
  useGetPreviousDonationQuery,
  DonorStatus,
  DonorOutput,
  JugularVein,
  useGetLabworkListQuery,
  LabworkOutput,
} from 'types.d';

import { getDefaultFieldsData } from './getDefaultFieldsData';
import { getIneligibleFieldsData } from './getIneligibleFieldsData';
import { prepareDonationToCreate } from './prepareDonationToCreate';
import { createDonationSchema, CreateDonationSchemaType } from './schema';
import styles from './styles.module.scss';

import { CreateDonationAlerts } from '../CreateDonationAlerts';

const CreateDonationForm: FC = () => {
  const { t } = useTranslation('donation.CreateDonationForm');
  const [displayingAlertsAllowed, setDisplayingAlertsAllowed] = useState(false);
  const [isIneligible, setIneligible] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const shortDonorId = searchParams.get('donorId') || '';

  const createDonationForm = useForm<CreateDonationSchemaType>({
    resolver: isIneligible ? undefined : yupResolver(createDonationSchema),
    mode: 'onChange',
    defaultValues: getDefaultFieldsData(),
  });
  const { data: donorData, loading: loadingDonor } = useGetDonorQuery({
    variables: {
      input: {
        id: shortDonorId,
      },
    },
    onError: (error) => message.error(error.message),
    onCompleted: (data) => {
      const defaultFormData = getDefaultFieldsData(
        data.getDonor as DonorOutput
      );
      createDonationForm.reset(defaultFormData);
    },
  });
  const donorId = donorData?.getDonor.id;
  const { data: donorLabworkData, loading: donorLabworkDataLoading } =
    useGetLabworkListQuery({
      variables: {
        input: {
          filter: {
            donorId,
          },
        },
      },
    });
  const lastLabwork = donorLabworkData?.getLabworkList.data?.reduce(
    (latest, labwork) => {
      return new Date(labwork.collectionDate) > new Date(latest.collectionDate)
        ? labwork
        : latest;
    },
    donorLabworkData?.getLabworkList.data[0]
  ) as LabworkOutput;
  const isDonorArchived = donorData?.getDonor.status === DonorStatus.Archived;
  const ineligible = createDonationForm.watch(
    'vitalsSection.examination.ineligible'
  );
  const ineligibleReason = createDonationForm.watch(
    'vitalsSection.examination.ineligibleReason'
  );
  const isFormValid =
    !!ineligible && !!ineligibleReason
      ? true
      : createDonationForm.formState.isValid;
  const isSaveButtonDisabled = isDonorArchived || !isFormValid || !donorId;
  const { data: previousDonationData, loading: loadingPreviousDonation } =
    useGetPreviousDonationQuery({
      variables: {
        input: {
          donorId: donorId as string,
        },
      },
      onCompleted: (data) => {
        const previousVein = data.getPreviousDonation?.jugularVein;
        const oppositeVein =
          previousVein === JugularVein.Left
            ? JugularVein.Right
            : JugularVein.Left;
        createDonationForm.setValue(
          'vitalsSection.donationInfo.jugularVein',
          oppositeVein
        );
      },
      skip: !donorId,
    });
  const previousDonation = previousDonationData?.getPreviousDonation as
    | DonationOutput
    | undefined;
  const [createDonation, { loading: loadingCreatingDonation }] =
    useCreateDonationWithAdditionalInfoMutation({
      refetchQueries: ['getPreviousDonation'],
      onCompleted: () => {
        message.success(t('donationCreated'));
        createDonationForm.reset();
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const saveHandler = createDonationForm.handleSubmit((formData) => {
    const variables = prepareDonationToCreate({
      donorId: donorId as string,
      formData,
    });
    createDonation({
      variables,
    });
  });

  useEffect(() => {
    if (ineligible) {
      setDisplayingAlertsAllowed(false);
      setIneligible(true);
      const ineligibleFormValues = getIneligibleFieldsData(
        donorData?.getDonor as DonorOutput
      );
      createDonationForm.reset(ineligibleFormValues);
    } else {
      const defaultFormData = getDefaultFieldsData(
        donorData?.getDonor as DonorOutput
      );
      createDonationForm.reset(defaultFormData);
      setDisplayingAlertsAllowed(true);
      setIneligible(false);
    }
  }, [ineligible]);

  const loading =
    loadingCreatingDonation ||
    loadingDonor ||
    loadingPreviousDonation ||
    donorLabworkDataLoading;

  return (
    <Spin spinning={loading}>
      {isDonorArchived && (
        <Alert
          type="error"
          message={t('donorArchived')}
          className={styles.alert}
        />
      )}

      <FormProvider {...createDonationForm}>
        <Form>
          <Row>
            <Col span={24} className={styles.saveButton}>
              <Button
                type="primary"
                disabled={isSaveButtonDisabled}
                onClick={saveHandler}
              >
                {t('save')}
              </Button>
            </Col>
            <Col span={24}>
              {displayingAlertsAllowed && !ineligible && (
                <CreateDonationAlerts
                  previousDonationData={previousDonation}
                  lastLabwork={lastLabwork}
                />
              )}
            </Col>
            <Col span={24}>
              <VitalsSection
                previousDonation={previousDonation}
                donor={donorData?.getDonor as DonorOutput}
                sectionNamePrefix="vitalsSection"
                formEnabled={false}
              />
            </Col>
            <Col span={24}>
              <AdditionalDonationInfoSection
                fieldNamePrefix="additionalInfoSection"
                formEnabled={true}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Spin>
  );
};

export default CreateDonationForm;
