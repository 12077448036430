import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';

type ParamType = {
  headers: LabelKeyObject[];
  toHighlight: string[];
  withSymbol?: string;
};

export const highlightCsvHeadersLabel = ({
  headers,
  toHighlight,
  withSymbol = '*',
}: ParamType) => {
  const highlightedHeaders = headers?.map(({ label, key }) => {
    const isRequired = toHighlight.includes(key);
    const updatedLabel = isRequired ? `${label}${withSymbol}` : label;
    return { key, label: updatedLabel };
  });
  return highlightedHeaders;
};
