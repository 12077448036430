import { Button, Table, message, Col } from 'antd';
import { Spin } from 'modules/common/components';
import { getColumns } from 'modules/notification/components/NotificationTable/columns';
import { GET_CURRENT_USER_QUERY } from 'modules/user/graphql/queries/user';
import { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUpdateNotificationConfigListMutation,
  NotificationConfigOutput,
  useGetNotificationConfigListQuery,
} from 'types.d';
import { deepEqual } from 'utils/deepEqual';

import styles from './styles.module.scss';

const NotificationTable: FC = () => {
  const { t } = useTranslation('owner.NotificationsTable');
  const [notifications, setNotifications] = useState<
    NotificationConfigOutput[]
  >([]);
  const [initNotifications, setInitNotifications] = useState<
    NotificationConfigOutput[]
  >([]);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const notificationConfigListQuery = useGetNotificationConfigListQuery({
    onCompleted: ({ getNotificationConfigList }) => {
      const notification = getNotificationConfigList
        .map((item) => {
          const { __typename, ...otherData } = item;
          return otherData;
        })
        .sort((item1, item2) =>
          item1.name.localeCompare(item2.name)
        ) as NotificationConfigOutput[];
      setNotifications(notification);
      setInitNotifications(notification);
    },
  });

  useEffect(() => {
    setIsSaveButtonActive(!deepEqual(notifications, initNotifications));
  }, [notifications, initNotifications]);

  const [updateNotifications, updateNotificationsMutation] =
    useUpdateNotificationConfigListMutation({
      onError: (error) => {
        message.error(error.message);
      },
      onCompleted: () => {
        message.success(t('successfullySaved'));
        notificationConfigListQuery.refetch();
      },
    });
  const isLoading = [
    notificationConfigListQuery,
    updateNotificationsMutation,
  ].some(({ loading }) => loading);

  const saveHandler = () => {
    setIsSaveButtonActive(false);
    updateNotifications({
      refetchQueries: [GET_CURRENT_USER_QUERY],
      variables: {
        input: {
          notifications: notifications || [],
        },
      },
    });
  };

  const tableColumns = getColumns({
    onCellClick: ({ newNotification }) => {
      setNotifications((previousNotifications) => {
        return previousNotifications.map((notification) => {
          return notification.name === newNotification.name
            ? {
                ...notification,
                value: newNotification.value,
              }
            : notification;
        });
      });
    },
  });
  return (
    <Spin spinning={isLoading}>
      <Col className={styles.submitButtonWrapper}>
        <Button
          size="large"
          type="primary"
          onClick={saveHandler}
          disabled={!isSaveButtonActive}
        >
          {t('save')}
        </Button>
      </Col>
      <Col span={24}>
        <Table
          rowKey={({ name }) => name}
          columns={tableColumns}
          dataSource={notifications}
          bordered
          scroll={{ x: 300 }}
          pagination={false}
        />
      </Col>
    </Spin>
  );
};

export default NotificationTable;
