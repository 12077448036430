export enum ContactFields {
  firstName = 'firstName',
  lastName = 'lastName',
  streetAddress = 'streetAddress',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  email = 'email',
  phoneNumber = 'phoneNumber',
}

export enum PersonalInfoFields {
  firstName = 'firstName',
  lastName = 'lastName',
  streetAddress = 'streetAddress',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  phoneNumber = 'phoneNumber',
  locationPreferences = 'locationPreferences',
  birthday = 'birthday',
  contactPreferences = 'contactPreferences',
}
