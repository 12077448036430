import { CloudWatchLogsClient } from '@aws-sdk/client-cloudwatch-logs';
import { envServices } from 'utils/EnvServices';

class CloudWatchClient {
  protected readonly client: CloudWatchLogsClient;

  constructor() {
    this.client = new CloudWatchLogsClient({
      credentials: {
        accessKeyId: envServices.get('REACT_APP_CLOUDWATCH_ACCESS_KEY'),
        secretAccessKey: envServices.get(
          'REACT_APP_CLOUDWATCH_SECRET_ACCESS_KEY'
        ),
      },
      region: envServices.get('REACT_APP_CLOUDWATCH_REGION'),
    });
  }

  protected send(command: Parameters<typeof this.client.send>[0]) {
    return this.client.send(command);
  }
}

export { CloudWatchClient };
