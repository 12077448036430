import { DefaultOptionType } from 'antd/lib/select';

const mockedTeams: DefaultOptionType[] = [
  {
    label: 'Team A',
    value: 'teamA',
  },
  {
    label: 'Team B',
    value: 'teamB',
  },
];

export const mockedGroup: DefaultOptionType[] = [
  {
    label: 'Group 1',
    value: 'group2',
    children: mockedTeams,
  },
  {
    label: 'Group 2',
    value: 'group2',
    children: mockedTeams,
  },
];
