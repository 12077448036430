import { fetchWithAuth } from 'utils/fetchWithAuth';

export const downloadFileHandler = async (fileName: string, input: string) => {
  const response = await fetchWithAuth(input, {
    method: 'GET',
  });
  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};
