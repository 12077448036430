import { Row, Col, Pagination } from 'antd';
import { useSort } from 'modules/common/hooks';
import LabworkCard from 'modules/donor/components/LabworkCard';
import { LabworkCardListSorter } from 'modules/donor/components/LabworkCardList/LabworkCardListSorter';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { LabworkOutput, LabworkListSort, LabworkStatus } from 'types.d';

import styles from './styles.module.scss';

const useLabworkListSort = () => useSort<LabworkListSort>();

type Props = {
  labworks: LabworkOutput[];
  updateLabworkHandler: (data: LabworkOutput) => void;
  updateLabworkStatusHandler: (id: string, status: LabworkStatus) => void;
  pagination: ReturnType<typeof usePagination>;
  sort: ReturnType<typeof useLabworkListSort>;
};
const LabworkCardList: FC<Props> = ({
  labworks,
  pagination,
  sort,
  ...otherProps
}) => {
  return (
    <Row gutter={[0, 30]}>
      <Col className={styles.sorterWrapper}>
        <LabworkCardListSorter sort={sort} />
      </Col>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          {labworks?.map((labwork) => (
            <Col span={24} key={labwork.id}>
              <LabworkCard {...otherProps} labwork={labwork} />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        <Pagination {...pagination.config} />
      </Col>
    </Row>
  );
};

export default LabworkCardList;
