import { ControlledSelect } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { bloodTypes } from './options';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const BloodTypeSelect: FC<PropTypes> = (props) => {
  const { t } = useTranslation('donor.BloodTypeSelect');

  return (
    <ControlledSelect
      options={bloodTypes}
      placeholder={t('selectBloodType')}
      {...props}
    />
  );
};

export default BloodTypeSelect;
