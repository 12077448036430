import { BloodType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.BloodTypeSelect');

export const bloodTypes = Object.values(BloodType).map((value) => {
  return {
    value,
    label: t(value.toLowerCase()),
  };
});
