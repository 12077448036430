import Select from 'modules/common/components/Select';
import { FC, ComponentProps } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Select>;

const ControlledSelect: FC<PropTypes> = ({
  controlled,
  defaultValue,
  ...restProps
}) => {
  const controller = useController(controlled);

  return <Select {...controller.field} {...restProps} />;
};

export default ControlledSelect;
