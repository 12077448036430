import { Row, Col, Skeleton, Collapse } from 'antd';
import DonationDetails from 'modules/donations/components/DonationDetails';
import CustomerDetailsTable from 'modules/orders/components/CustomerDetailsTable';
import OrderItemsTable from 'modules/orders/components/OrderItemsTable';
import ProductDetails from 'modules/products/components/ProductDetails';
import { FC } from 'react';
import {
  CustomerOutput,
  OrderOutput,
  ProductOutput,
  WoocommerceProductCategoryOutput,
  useGetCustomerQuery,
  useGetProductsQuery,
  useGetWoocommerceCategoriesQuery,
} from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  order: OrderOutput;
};

const OrderDetails: FC<PropTypes> = ({ order }) => {
  const customerQuery = useGetCustomerQuery({
    variables: {
      input: {
        id: order.customerId,
      },
    },
  });
  const productsQuery = useGetProductsQuery({
    variables: {
      input: {
        filter: { orderId: order.id },
      },
    },
  });
  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
  });
  const woocommerceCategories =
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories;
  const customer = customerQuery.data?.getCustomer as CustomerOutput;
  const products = productsQuery.data?.getProducts.data as
    | ProductOutput[]
    | undefined;

  return (
    <Row gutter={[24, 0]} justify="space-between">
      {/* Disabled part of code */}
      {/* <Col span={8}>
        <BillingDetailsTable billing={order.billing} />
      </Col>
      <Col span={8}>
        <ShippingDetailsTable shipping={order.shipping} />
      </Col> 
      <Col span={8}>
        <OrderTotalTable orderData={order} />
      </Col> */}
      <Col lg={10} span={24}>
        {customerQuery.loading ? (
          <Skeleton />
        ) : (
          customer && <CustomerDetailsTable customer={customer} />
        )}
      </Col>
      <Col lg={10} span={24} className={styles.orderItemsContainer}>
        <OrderItemsTable items={order.items} />
      </Col>
      <Col span={24} className={styles.productsContainer}>
        {productsQuery.loading ? (
          <Skeleton />
        ) : (
          <Collapse ghost>
            {products?.map((product) => {
              const productCategory = woocommerceCategories?.find(
                (category) => category.id === product?.woocommerceCategoryId
              ) as WoocommerceProductCategoryOutput | undefined;

              return (
                <Collapse.Panel header={productCategory?.name} key={product.id}>
                  <ProductDetails
                    product={product}
                    woocommerceProductCategory={productCategory}
                  />
                  <DonationDetails donation={product.donation} />
                </Collapse.Panel>
              );
            })}
          </Collapse>
        )}
      </Col>
    </Row>
  );
};

export default OrderDetails;
