import { Button, Popconfirm, message } from 'antd';
import { Routes } from 'config/routes';
import { useModal } from 'modules/common/hooks';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DonorStatus,
  DonorType,
  useHasDonorRelatedConnectionsLazyQuery,
  useRemoveDonorMutation,
  useUpdateDonorMutation,
} from 'types.d';

import { DonorArchiveConfirmationModal } from '../DonorArchiveConfirmationModal';

type PropTypes = {
  donorId: string;
  donorType: DonorType;
  donorStatus: DonorStatus;
} & ComponentProps<typeof Button>;

export const DonorManagementButton: FC<PropTypes> = ({
  donorId,
  donorType,
  donorStatus,
  ...restProps
}) => {
  const [deleteDonor, deleteDonorMutation] = useRemoveDonorMutation();
  const { t } = useTranslation('donor.EditDonorForm.DeleteButton');
  const [updateDonor, updateDonorMutation] = useUpdateDonorMutation();
  const { show, config } = useModal();
  const navigate = useNavigate();
  const [hasRelatedConnections, hasRelatedConnectionsQuery] =
    useHasDonorRelatedConnectionsLazyQuery();

  const returnTo =
    donorType === DonorType.Grouped
      ? Routes.GroupDonors
      : Routes.IndividualDonors;

  const handleDelete = async () => {
    await deleteDonor({
      variables: { input: { id: donorId } },
      onCompleted: () => {
        navigate(returnTo);
        message.success(t('donorDeleted'));
      },
      onError: () => {
        message.error(t('deletingError'));
      },
      update: (cache) => {
        cache.evict({
          fieldName: 'getDonorsList',
        });
      },
    });
  };

  const handleArchive = async () => {
    await updateDonor({
      variables: {
        input: {
          id: donorId,
          status: DonorStatus.Archived,
        },
      },
      onCompleted: () => {
        navigate(returnTo);
        message.success(t('donorArchived'));
      },
      onError: () => {
        message.error(t('archivedError'));
      },
      update: (cache) => {
        cache.evict({
          fieldName: 'getDonorsList',
        });
      },
    });
  };

  const onConfirmFunction = async () => {
    const result = await hasRelatedConnections({
      variables: {
        input: {
          id: donorId,
        },
      },
    });
    if (!result.loading && !result.data?.hasDonorRelatedConnections) {
      await handleDelete();
    }
    if (result.data?.hasDonorRelatedConnections) {
      show();
    }
  };

  return (
    <>
      <Popconfirm
        title={t('areYouSureToDelete')}
        okText={t('yes')}
        cancelText={t('no')}
        onConfirm={() => onConfirmFunction()}
      >
        <Button
          danger
          {...restProps}
          loading={
            deleteDonorMutation.loading || hasRelatedConnectionsQuery.loading
          }
        >
          {t('delete')}
        </Button>
      </Popconfirm>
      <DonorArchiveConfirmationModal
        donorId={donorId}
        onOk={handleArchive}
        confirmLoading={updateDonorMutation.loading}
        donorStatus={donorStatus}
        {...config}
      />
    </>
  );
};
