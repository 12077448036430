import { Col, Row } from 'antd';
import { DefaultClinicParams } from 'config/default-clinic-params';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import AnimalIdentification from './AnimalIdentification';
import ContactForm from './ContactForm';
import Head from './Head';
import History from './History';
import styles from './styles.module.scss';

import { RoutineTestFormSchemaType } from '../../RoutineTestForm/schema';
import { FormPage } from '../components/FormPage';

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};

const GeneralSubmissionForm = forwardRef<any, PropTypes>(({ data }, ref) => {
  const { t } = useTranslation('donor.FormsToPrint.GeneralSubmissionForm');

  return (
    <FormPage classNames={styles.form} ref={ref}>
      <Head />
      <div className={styles.content}>
        <ContactForm data={data} />
        <History />
        <AnimalIdentification data={data} />
        <span className={styles.general}>{t('general')}</span>
      </div>
      <Row className={styles.footer}>
        <Col span={20} className={styles.footerNotes}>
          <p>{t('footerResponsibleNote')}</p>
          <p>{t('footerInternalNote')}</p>
        </Col>
        <Col span={4} className={styles.footerPage}>
          <p>
            {t('footerPageOf')}
            <br />
            {t('footerOrgCode')}
          </p>
        </Col>
      </Row>
    </FormPage>
  );
});

export default GeneralSubmissionForm;
