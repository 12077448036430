import { Button, Col } from 'antd';
import { FormItem, ControlledSearchInput } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  OrderOutput,
  PermissionsEnum,
  ProductOutput,
  useGetOrderLazyQuery,
} from 'types.d';

import { IUpdateProductTrackingInfoSchemaType } from '../schema';

type PropTypes = {
  product?: ProductOutput;
  onOrderUpdate?: (order?: OrderOutput) => void;
};

const WoocommerceOrderIdInput: FC<PropTypes> = ({ product, onOrderUpdate }) => {
  const { t } = useTranslation('products.ChangeProductTrackingInfoModal');
  const { setError, trigger, control, formState, watch, setValue } =
    useFormContext<IUpdateProductTrackingInfoSchemaType>();
  const fieldErrorMessages = formState.errors.woocommerceOrderId?.message;
  const woocommerceOrderId = watch('woocommerceOrderId');
  const [getOrder, getOrderQuery] = useGetOrderLazyQuery({
    onError: () => {
      setError('woocommerceOrderId', { message: t('orderDoesNotExist') });
    },
    onCompleted: ({ getOrder }) => {
      trigger();
      onOrderUpdate?.(getOrder);
    },
    fetchPolicy: 'cache-and-network',
  });
  const productOrder = getOrderQuery.data?.getOrder;
  const currentUser = useCurrentUser();
  const updateProductAllowed = currentUser.isCan(PermissionsEnum.UpdateProduct);
  const isLoading = getOrderQuery.loading;
  const isDisabledSearchButton =
    !updateProductAllowed || isLoading || !woocommerceOrderId;

  const searchOrder = (orderId: string) => {
    onOrderUpdate?.(undefined);
    getOrder({
      variables: {
        input: {
          id: Number(orderId),
        },
      },
    });
  };

  useEffect(() => {
    if (product?.woocommerceOrderId) {
      getOrder({
        variables: {
          input: { id: product.woocommerceOrderId },
        },
      });
    }
  }, [product?.woocommerceOrderId]);

  useEffect(() => {
    if (productOrder || woocommerceOrderId) {
      setValue('orderExist', productOrder?.id === woocommerceOrderId, {
        shouldValidate: true,
      });
    }
  }, [woocommerceOrderId, productOrder]);

  return (
    <Col span={24}>
      <FormItem
        label={t('woocommerceOrderId')}
        validateStatus={fieldErrorMessages && 'error'}
        extra={fieldErrorMessages}
      >
        <ControlledSearchInput
          enterButton={
            <Button
              disabled={isDisabledSearchButton}
              loading={isLoading}
              type="primary"
            >
              {t('verify')}
            </Button>
          }
          disabled={!updateProductAllowed}
          onSearch={searchOrder}
          loading={isLoading}
          controlled={{ control, name: 'woocommerceOrderId' }}
        />
      </FormItem>
    </Col>
  );
};

export default WoocommerceOrderIdInput;
