import { SelectProps } from 'antd';
import { setYear } from 'date-fns';
import { Select } from 'modules/common/components';
import { FC } from 'react';

type PropTypes = {
  date: Date;
  onChange?: (date: Date) => void;
} & Omit<SelectProps, 'value' | 'onChange'>;

const YearSelect: FC<PropTypes> = ({ date, onChange, ...restProps }) => {
  const year = date.getFullYear();
  const handleChange = (changedYear: number) => {
    const changedDate = setYear(date, changedYear);
    onChange && onChange(changedDate);
  };

  const years = [year - 1, year, year + 1].filter((year) => year >= 2022);

  const options = years.map((yearNumber) => ({
    value: yearNumber,
    label: yearNumber,
  }));

  return (
    <Select
      size="middle"
      dropdownMatchSelectWidth={false}
      value={year}
      adjustDropdownPosition
      onChange={handleChange}
      options={options}
      {...restProps}
    />
  );
};

export default YearSelect;
