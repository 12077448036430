import { DonationBaseChart } from 'modules/donor/components';
import { getConfig } from 'modules/donor/components/PackedCellVolumeChart/config';
import { FC } from 'react';
import { DonationOutput } from 'types';

type Props = {
  donationData: DonationOutput[];
};

const PackedCellVolumeChart: FC<Props> = ({ donationData }) => (
  <DonationBaseChart lineConfig={getConfig(donationData)} />
);

export default PackedCellVolumeChart;
