import { Col, Row } from 'antd';
import React from 'react';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

import CheckboxGroup from '../../components/CheckboxGroup';
import FormInput from '../../components/Input';

const t = getTranslation('donor.FormsToPrint.GeneralSubmissionForm.History');

const checkboxOptions = [
  t('Normal'),
  t('hematologicalHemorrhage'),
  t('dermatological'),
  t('fever'),
  t('neurological'),
  t('hepatic'),
  t('gastrointestinalDiarrhea'),
  t('abortionReproFailure'),
  t('endocrine'),
  t('suddenDeath'),
  t('urinaryUrogenital'),
  t('musculoskeletalLameness'),
  t('edema'),
  t('ocular'),
  t('neoplasia'),
  t('chronicWeightLoss'),
  t('productionPerformanceDecline'),
  t('respiratory'),
  t('anorexia'),
  t('cardiac'),
  t('erosionVesicular'),
  {
    fieldName: t('other'),
    withInput: true,
    inputValue: t('healthScreening'),
    isChecked: true,
  },
];

export default function History() {
  return (
    <div className={styles.history}>
      <div className={styles.historyCheckboxes}>
        <div className={styles.historyTitle}>
          <h4>{t('historyClinicalInfo')}</h4>
          <p>{t('checkAndApply')}</p>
        </div>
        <div className={styles.historyCheckboxesGrid}>
          {checkboxOptions.map((option, i) => {
            return i === 0 ? (
              <>
                <div className={styles.firstCheckbox} />
                <CheckboxGroup options={[option]} key={i} />
              </>
            ) : (
              <CheckboxGroup options={[option]} key={i} />
            );
          })}
        </div>
        <div className={styles.historyInputs}>
          <FormInput label="Clinical / Differential Diagnosis" />
          <Row align="middle">
            <Col span={14}>
              <p>
                {t('hasRelatedMaterialBeenSubmitted')}
                <b>{t('animalHerd')}</b>:
              </p>
            </Col>
            <Col span={10} className={styles.accession}>
              <CheckboxGroup options={[t('Y'), t('N')]} />
              <FormInput label={t('accession')} />
            </Col>
          </Row>
          <Row className={styles.donorInfo}>
            <Col span={8}>
              <FormInput label={t('dateOfOnsetIllness')} />
            </Col>
            <Col span={6}>
              <FormInput label={t('inAnimalsSubmitted')} />
            </Col>
            <Col span={3}>
              <FormInput label={t('herdSize')} />
            </Col>
            <Col span={3}>
              <FormInput label={t('deadNo')} />
            </Col>
            <Col span={2}>
              <FormInput label={t('affected')} />
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.additionalHistoryInfo}>
        <div className={styles.additionalHistoryTitle}>
          <h5>{t('title')}</h5>
        </div>
        <Row align="middle">
          <Col span={20} className={styles.healthScreening}>
            <p>{t('healthScreening')}</p>
          </Col>
          <Col span={4} className={styles.checkHere}>
            <div />
            <p>{t('checkHistory')}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
