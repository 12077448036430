import { EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import { Link } from 'modules/common/components';
import OutcomeCell from 'modules/donations/components/OutcomeCell';
import { DonationOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.DonorDonationCardInfo');

type Option = {
  label: string;
  value: JSX.Element | string;
};

type PropTypes = {
  donation: DonationOutput;
  addCommentHandler: (donationId: string, previousValue?: string) => void;
  canAddCommentsToDonation: boolean;
  productCategories?: Record<number, string>;
};

export const getOptions = ({
  addCommentHandler,
  donation,
  canAddCommentsToDonation,
  productCategories,
}: PropTypes): Option[] => {
  const isCellFilled =
    Boolean(donation.products?.length) || donation.ineligible;
  return [
    {
      label: t('donationId'),
      value: (
        <Link to={`${Routes.Donations}/${donation.shortId}`}>
          {donation.shortId}
        </Link>
      ),
    },
    {
      label: t('location'),
      value: donation.donor.location,
    },
    {
      label: t('comments'),
      value: (
        <>
          {!donation.comment && canAddCommentsToDonation && (
            <PlusSquareOutlined
              onClick={() => addCommentHandler(donation.id)}
            />
          )}
          {donation.comment && (
            <Row justify="space-between">
              <Col span={18} className={styles.comment}>
                <abbr title={donation.comment} className={styles.abbreviation}>
                  {donation.comment}
                </abbr>
              </Col>
              {canAddCommentsToDonation && (
                <Col>
                  <EditOutlined
                    onClick={() =>
                      addCommentHandler(donation.id, donation.comment!)
                    }
                  />
                </Col>
              )}
            </Row>
          )}
          {!donation.comment && !canAddCommentsToDonation && '-'}
        </>
      ),
    },
    {
      label: t('products'),
      value: (
        <>
          {isCellFilled ? (
            <OutcomeCell
              donation={donation}
              productCategories={productCategories}
            />
          ) : (
            '-'
          )}
        </>
      ),
    },
  ];
};
