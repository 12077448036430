import { Col, Layout, Row } from 'antd';
import { MainLayout } from 'modules/common/components';
import SignUpForm from 'modules/user/components/SignUpForm';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const SignUpPage: FC = () => {
  const { t } = useTranslation('user.SignUpPage');

  return (
    <MainLayout>
      <Layout.Content className={styles.content}>
        <Row justify="center" align="middle" className={styles.formContainer}>
          <Col span={24}>
            <h1 className={styles.title}>{t('signUp')}</h1>
          </Col>
          <Col flex="440px">
            <SignUpForm />
          </Col>
        </Row>
      </Layout.Content>
    </MainLayout>
  );
};

export default SignUpPage;
