import { DonationDateFilters } from 'modules/donor/types';
import {
  DonorListFilterInput,
  DonorStatus,
  BloodType,
  DonorType,
  LocationOutput,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.IndividualDonorListFilter');

type FilterOption = {
  title: string;
  key: keyof DonorListFilterInput;
  values: {
    label: string;
    value: DonorStatus | BloodType | DonationDateFilters | DonorType | string;
  }[];
};

type Props = {
  locations?: LocationOutput[];
};

export const getOptions = ({ locations }: Props): FilterOption[] => {
  const locationsValues =
    locations?.map((location) => ({
      value: location.name,
      label: location.name,
    })) || [];

  return [
    {
      title: t('status'),
      key: 'statuses',
      values: Object.keys(DonorStatus).map((key) => ({
        label: t(key.charAt(0).toLowerCase() + key.slice(1)),
        value: DonorStatus[key as DonorStatus],
      })),
    },
    {
      title: t('bloodType'),
      key: 'bloodType',
      values: Object.keys(BloodType).map((key) => ({
        label: t(BloodType[key as BloodType].toLocaleLowerCase()),
        value: BloodType[key as BloodType],
      })),
    },
    {
      title: t('lastDonationDate'),
      key: 'lastDonationInterval',
      values: Object.values(DonationDateFilters).map((filter) => {
        return {
          label: t(filter),
          value: filter,
        };
      }),
    },
    {
      title: t('type'),
      key: 'types',
      values: Object.values(DonorType).map((type) => {
        return {
          label: t(type.charAt(0).toLowerCase() + type.slice(1)),
          value: type,
        };
      }),
    },
    {
      title: t('locations'),
      key: 'locations',
      values: locationsValues,
    },
  ];
};
