import { Row, Col, Button, message } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DonorOutput,
  useCancelDaycareAppointmentRequestMutation,
} from 'types.d';

import styles from '../styles.module.scss';

type PropTypes = {
  daycareRequests: {
    id: string;
    donor: DonorOutput;
  }[];
};

export const WaitingForApprovalDonors: FC<PropTypes> = ({
  daycareRequests,
}) => {
  const { t } = useTranslation('appointments.registerDaycareModal');
  const [cancelRequest, { loading }] =
    useCancelDaycareAppointmentRequestMutation();

  const cancelHandler = (requestId: string) => {
    cancelRequest({
      variables: {
        id: requestId,
      },
      onCompleted() {
        message.success(t('donorRequestCanceled'));
      },
      onError() {
        message.error(t('cancelError'));
      },
      refetchQueries: ['getDaycareAppointmentInfoForCurrentUser'],
    });
  };

  message;
  return (
    <p className={styles.alreadyRegistered}>
      {t('donorsInRequest')}
      <span className={styles.registeredDonors}>
        {daycareRequests?.map((request) => {
          return (
            <Row justify="space-between">
              <Col>{request.donor.name}</Col>
              <Col>
                <Button
                  type="link"
                  loading={loading}
                  onClick={() => cancelHandler(request.id)}
                >
                  {t('cancelRequest')}
                </Button>
              </Col>
            </Row>
          );
        })}
      </span>
    </p>
  );
};
