import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { RegisterDaycareFields } from '../DaycareRegisterForm/fields';

export enum Answers {
  Yes = 'yes',
  No = 'no',
}

const { Answer, Donors, Note } = RegisterDaycareFields;

const t = getTranslation('appointments.registerDaycareModal');

export type RegisterDaycareAppointmentSchema = {
  [Answer]: Answers;
  [Donors]?: string[];
  [Note]?: string;
};

export const registerDaycareAppointmentSchema: yup.SchemaOf<RegisterDaycareAppointmentSchema> =
  yup.object({
    [Answer]: yup.mixed().oneOf(Object.values(Answers)).required(),
    [Donors]: yup.array().when(Answer, {
      is: Answers.Yes,
      then: yup
        .array()
        .of(yup.string())
        .min(1, t('selectAtLeastDonor'))
        .required(t('required')),
      otherwise: yup.array().nullable(),
    }),
    [Note]: yup
      .string()
      .optional()
      .max(100, t('noteSize', { size: 100 })),
  });
