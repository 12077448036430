import { Col, Modal, Row } from 'antd';
import { Button } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import LocationsTable from '../LocationsTable';

type PropTypes = {
  selectedDate?: Date;
} & BaseModalProps;

const EditLocationsModal: FC<PropTypes> = (props) => {
  const { t } = useTranslation('user.EditLocationsModal');
  const { visible, onCancel, onOk, hide, ...restModalProps } = props;

  const cancelHandler: BaseModalProps['onCancel'] = (e) => {
    hide();
    onCancel?.(e);
  };

  const okHandler: BaseModalProps['onCancel'] = (e) => {
    hide();
    onOk?.(e);
  };

  return (
    <Modal
      className={styles.editLocationModal}
      title={t('editLocations')}
      width={700}
      mask={false}
      wrapClassName={styles.wrapClassName}
      onCancel={cancelHandler}
      zIndex={1005}
      footer={
        <Row justify="end">
          <Col>
            <Button onClick={okHandler} type="primary">
              {t('ok')}
            </Button>
          </Col>
        </Row>
      }
      visible={visible}
      {...restModalProps}
      {...restModalProps}
    >
      <LocationsTable />
    </Modal>
  );
};

export default EditLocationsModal;
