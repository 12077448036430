import { PrinterOutlined } from '@ant-design/icons';
import { Dropdown, Menu, MenuProps } from 'antd';
import { LabelSize } from 'modules/products/enums';
import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  onPrint?: (labelSize: LabelSize) => void;
  button?: ReactNode;
};

const PrintLabelButton: FC<PropTypes> = ({ onPrint, button }) => {
  const handleCLick: MenuProps['onClick'] = ({ key }) => {
    const labelSize = key as LabelSize;
    onPrint && onPrint(labelSize);
  };

  return (
    <Dropdown
      overlay={
        <Menu
          onClick={handleCLick}
          items={[
            { label: '4x4', key: LabelSize.Large },
            { label: '3x3', key: LabelSize.Small },
          ]}
        />
      }
      trigger={['click', 'contextMenu']}
    >
      {button || <PrinterOutlined className={styles.printIcon} />}
    </Dropdown>
  );
};

export default PrintLabelButton;
