import { Button, Col, Row, message } from 'antd';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useGetAllIneligibleLazyQuery } from 'types.d';

import { ineligibleHeaders } from './headers';

const IneligibleReports = () => {
  const { t } = useTranslation('reports.IneligiblesReports');
  const filename = 'IneligibleParticipantsReports';
  const [getAllIneligible, getAllIneligibleQuery] =
    useGetAllIneligibleLazyQuery({
      onError: (error) => {
        message.error(error.message);
      },
    });
  const { loading: isLoading } = getAllIneligibleQuery;
  const receivedIneligibles =
    getAllIneligibleQuery.data?.getAllIneligibleParticipants?.ineligible || [];
  const mappedIneligibles = receivedIneligibles.map(
    ({ __typename, ...restFields }) => {
      return {
        ...restFields,
        ownerData: JSON.parse(restFields.ownerData),
        donorData: JSON.parse(restFields.donorData),
      };
    }
  );

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Button
          onClick={() => getAllIneligible()}
          loading={isLoading}
          disabled={isLoading}
          type="primary"
        >
          {t('generateCsvReport')}
        </Button>
      </Col>
      <Col span={24}>
        {Boolean(mappedIneligibles.length) && (
          <CSVLink
            headers={ineligibleHeaders}
            data={mappedIneligibles}
            filename={filename}
          >
            {`${filename}.csv`}
          </CSVLink>
        )}
      </Col>
    </Row>
  );
};

export default IneligibleReports;
