import { Routes } from 'config/routes';
import GroupProfileTabs from 'modules/donor/components/GroupProfileTabs';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { checkIsUUID } from 'utils/checkIsUUID';

const GroupProfilePage: FC = () => {
  const { t } = useTranslation('donor.GroupProfilePage');
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || '';

  useEffect(() => {
    if (!checkIsUUID(id)) {
      navigate(Routes.GroupDonors);
    }
  });

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <GroupProfileTabs groupId={id} />
    </ProfileSideMenuWrapper>
  );
};

export default GroupProfilePage;
