import { Tag } from 'antd';
import { useAppointmentsHighlightContext } from 'modules/appointments/contexts/AppointmentsHighlight';
import UserSelect from 'modules/user/components/UserSelect';
import { ComponentProps, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = Omit<ComponentProps<typeof UserSelect>, 'onChange'> & {
  onChange?: (value: { userId: string; color: string }) => void;
  disableHighlights?: boolean;
};

const MembersSelect: FC<PropTypes> = ({
  onChange,
  disableHighlights,
  ...restProps
}) => {
  const { t } = useTranslation('appointments.MembersSelect');
  const appointmentColors = useAppointmentsHighlightContext();
  const userRolesBlackList: UserRole[] = [UserRole.Owner];
  const userRoles = Object.values(UserRole).filter((role) => {
    return !userRolesBlackList.includes(role);
  });

  useEffect(() => {
    return () => appointmentColors?.reset();
  }, []);

  return (
    <UserSelect
      userRoles={userRoles}
      placeholder={t('members')}
      mode="multiple"
      onSelect={(memberId: any) =>
        !disableHighlights && appointmentColors?.addMember(memberId)
      }
      onDeselect={(memberId: any) =>
        !disableHighlights && appointmentColors?.removeMember(memberId)
      }
      tagRender={({ label, value, closable, onClose }) => {
        const onPreventMouseDown = (
          event: React.MouseEvent<HTMLSpanElement>
        ) => {
          event.preventDefault();
          event.stopPropagation();
        };

        return (
          <Tag
            color={
              disableHighlights ? undefined : appointmentColors?.colors?.[value]
            }
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            className={styles.tag}
          >
            <span className={styles.tagContent}>{label}</span>
          </Tag>
        );
      }}
      {...restProps}
    />
  );
};

export default MembersSelect;
