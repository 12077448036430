import { Col } from 'antd';
import { FormItem } from 'modules/common/components';
import ProductStatusSelect from 'modules/products/components/ProductStatusSelect';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IUpdateProductTrackingInfoSchemaType } from '../schema';

const FIELD_NAME = 'status';

const ProductStatusInput: FC = () => {
  const { t } = useTranslation('products.ChangeProductTrackingInfoModal');
  const { control, formState } =
    useFormContext<IUpdateProductTrackingInfoSchemaType>();
  const fieldErrorMessage = formState.errors[FIELD_NAME]?.message;

  return (
    <Col span={24}>
      <FormItem
        label={t('productStatus')}
        validateStatus={fieldErrorMessage && 'error'}
        extra={fieldErrorMessage}
      >
        <ProductStatusSelect controlled={{ control, name: FIELD_NAME }} />
      </FormItem>
    </Col>
  );
};

export default ProductStatusInput;
