import { Col, Row } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Link } from 'modules/common/components';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductOutput, WoocommerceProductCategoryOutput } from 'types.d';

import styles from './styles.module.scss';

type PropType = {
  product: ProductOutput;
  woocommerceProductCategory?: WoocommerceProductCategoryOutput;
};

const ProductDetails: FC<PropType> = ({
  product,
  woocommerceProductCategory,
}) => {
  const { t } = useTranslation('products.ProductDetails');

  const productInfo: Record<string, ReactNode> = {
    [t('expirationDates')]: (
      <>
        {format(new Date(product.donation.donationDate), config.DATE_FORMAT)}
        <br />
        {format(new Date(product.expirationDate), config.DATE_FORMAT)}
      </>
    ),
    [t('productName')]: `${woocommerceProductCategory?.name}`,
    [t('bloodType')]: `${product.donation.donor.bloodType || '-'}`,
    [t('volume')]: `${product.value} ml`,
    [t('donorId')]: (
      <Link
        target="_blank"
        to={Routes.DonorsProfile.replace(
          ':id',
          String(product.donation.donor.shortId)
        )}
      >
        {product.donation.donor.shortId}
      </Link>
    ),
    [t('donationDate')]: format(
      new Date(product.donation.donationDate),
      config.SHORT_DATE_FORMAT
    ),
    [t('anticoagulant')]: product.anticoagulant,
    [t('status')]: product.status,
  };

  return (
    <Row gutter={[0, 15]}>
      {Object.keys(productInfo).map((key, i) => {
        return (
          <Col sm={12} span={24} key={`${key}_${i}`}>
            <Row justify="space-between" gutter={10}>
              <Col span={12} className={styles.label}>
                {key}:
              </Col>
              <Col span={12}>{productInfo[key]}</Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default ProductDetails;
