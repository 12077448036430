import { Row, Col } from 'antd';
import { Routes } from 'config/routes';
import { Link } from 'modules/common/components';
import TeamSelect from 'modules/donor/components/TeamSelect';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DonorOutput, TeamOutput } from 'types.d';

type Option = {
  label: string;
  value: string | JSX.Element;
};

type PropTypes = {
  donor: DonorOutput;
  teams?: TeamOutput[];
  changeDonorTeamHandler?: (donor: DonorOutput, teamId: string) => void;
};

const GroupDonorCardInfo: FC<PropTypes> = ({
  donor,
  teams,
  changeDonorTeamHandler,
}) => {
  const { t } = useTranslation('donor.GroupDonorCardInfo');
  const bloodType = donor.bloodType ? t(donor.bloodType?.toLowerCase()) : '-';
  const optionsPreset = [
    { label: t('donorId'), value: `${donor.shortId}` },
    {
      label: t('petName'),
      value: <Link to={`${Routes.Donors}/${donor.shortId}`}>{donor.name}</Link>,
    },
    {
      label: t('owner'),
      value: (
        <Link to={`${Routes.Owners}/${donor.user?.shortId}`}>
          {donor.user?.lastName}
        </Link>
      ),
    },
    { label: t('bloodType'), value: bloodType },
    { label: t('status'), value: donor.status },
    {
      label: t('teams'),
      value: (
        <TeamSelect
          currentTeam={donor.team as TeamOutput | undefined}
          teams={teams || []}
          onSelect={(teamId: string) =>
            donor.team?.id !== teamId &&
            changeDonorTeamHandler &&
            changeDonorTeamHandler(donor, teamId)
          }
          size="small"
        />
      ),
      filter: teams == undefined,
    },
  ];

  const options = optionsPreset.reduce((previousValue, currentValue) => {
    const { filter, ...data } = currentValue;
    if (currentValue.filter) {
      return previousValue;
    }
    return [...previousValue, data as any];
  }, [] as Option[]);

  return (
    <Row gutter={[10, 0]}>
      {options.map(({ label, value }, i) => {
        return (
          <Col span={24} key={`${donor?.id}_${i}`}>
            <Row gutter={[10, 0]}>
              <Col span={12}>
                <b>{label}:</b>
              </Col>
              <Col span={12}>{value}</Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default GroupDonorCardInfo;
