import {
  FieldPolicy,
  FieldReadFunction,
  KeyArgsFunction,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies';

const skipTakePagination = (
  keyArgs?: KeySpecifier | KeyArgsFunction | false
): FieldPolicy<any> | FieldReadFunction<any> => {
  return {
    keyArgs,
    read(existing, { args }) {
      if (!args?.input.filter) {
        const take = args?.input.take;
        const skip = args?.input.skip;
        const data = existing?.data?.slice(skip, skip + take);
        const result = {
          ...existing,
          data,
        };
        return data?.length && !data?.includes(undefined) ? result : undefined;
      } else {
        return undefined;
      }
    },
    merge(existing, incoming, { args }) {
      const mergedData = existing?.data ? [...existing?.data] : [];
      for (let i = 0; i < incoming?.data.length; ++i) {
        mergedData[args?.input.skip + i] = incoming?.data[i];
      }
      return {
        ...existing,
        ...incoming,
        data: mergedData,
      };
    },
  };
};

export { skipTakePagination };
