import { CheckboxOptionType, RadioGroupProps } from 'antd';
import RadioGroup from 'modules/common/components/RadioGroup';
import { forwardRef } from 'react';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.YesNoRadioGroup');

const constantOptions: CheckboxOptionType[] = [
  {
    value: true,
    label: t('yes'),
  },
  {
    value: false,
    label: t('no'),
  },
];

type PropTypes = {
  onBlur?: RadioGroupProps['onChange'];
} & RadioGroupProps;

const YesNoRadioGroup = forwardRef<HTMLDivElement, PropTypes>(
  ({ options, onBlur, onChange, ...restProps }: PropTypes, ref) => {
    const handleChange: RadioGroupProps['onChange'] = (e) => {
      onChange?.(e);
      onBlur?.(e);
    };

    return (
      <RadioGroup
        onChange={handleChange}
        options={constantOptions}
        {...restProps}
        ref={ref}
      />
    );
  }
);

export default YesNoRadioGroup;
