import { ApolloQueryResult } from '@apollo/client';
import { GET_VITALS_PARAMETERS_LIST } from 'modules/donor/graphql/queries';
import { apolloClient } from 'providers/graphql';
import { GetVitalsParameterListQuery } from 'types';

export const getVitalsParameters = (): Promise<
  ApolloQueryResult<GetVitalsParameterListQuery>
> => {
  return apolloClient.query<GetVitalsParameterListQuery>({
    query: GET_VITALS_PARAMETERS_LIST,
  });
};
