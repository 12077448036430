import { FilledCircle } from 'modules/donor/components/RoutineTest/components/FilledCircle';
import { IndividualResultsSection } from 'modules/donor/components/RoutineTest/components/IndividualResultsSection';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.RoutineTest.HematologySection');

const preset = {
  title: t('hematology'),
  items: [
    {
      label: t('cbc'),
      details: t('cbcSubtitle'),
      dots: [<FilledCircle color="#8A4F9F" />],
      checked: true,
    },
    {
      label: t('comprehensiveCbc'),
      details: t('comprehensiveCbcSubtitle'),
      dots: [<FilledCircle color="#8A4F9F" />],
    },
  ],
};

export const HematologySection: FC = () => (
  <IndividualResultsSection {...preset} />
);
