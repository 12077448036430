import { Routes } from 'config/routes';
import DonorProfileCardTabs from 'modules/donor/components/DonorProfileCardTabs';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetDonorLazyQuery } from 'types.d';

const DonorProfilePage: FC = () => {
  const { t } = useTranslation('donor.DonorProfilePage');
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id || '';
  const donorId = isNaN(Number(id)) ? id : Number(id);
  const [getDonor] = useGetDonorLazyQuery({
    onCompleted: ({ getDonor }) => {
      //THIS LOGIC HAS BEEN PROVIDED TO SEE THIS PAGE ONLY WITH SHORT ID WHICH IS NUMBER
      navigate(`${Routes.Donors}/${getDonor.shortId}`, {
        replace: true,
      });
    },
    onError: () => {
      navigate(Routes.IndividualDonors);
    },
  });

  useEffect(() => {
    if (typeof donorId === 'string') {
      getDonor({
        variables: {
          input: { id: donorId },
        },
      });
    }
  }, [donorId]);

  return (
    <ProfileSideMenuWrapper title={t('donorProfile')}>
      {typeof donorId === 'number' && <DonorProfileCardTabs donorId={id} />}
    </ProfileSideMenuWrapper>
  );
};

export default DonorProfilePage;
