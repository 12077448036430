import { passwordSchema, phoneNumberSchema } from 'modules/user/schemas';
import * as yup from 'yup';

export type AccountSchema = {
  password?: string | null;
  phoneNumber?: string;
};

const transformEmptyString = (value: string) => (value === '' ? null : value);

export const accountSchema: yup.SchemaOf<AccountSchema> = yup.object({
  phoneNumber: phoneNumberSchema.notRequired(),
  password: passwordSchema
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
});
