import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import FormInput from '../../../components/Input';

export const Head = () => {
  const { t } = useTranslation(
    'donor.FormsToPrint.StateLabSubmissionForm.Head'
  );
  return (
    <Row className={styles.head}>
      <Col span={5}>
        <img
          src="https://navbb-assets.s3.amazonaws.com/VirginiaDepartmentofAgricultureandConsumerServices.png"
          className={styles.logo}
        />
      </Col>
      <Col span={14}>
        <Row>
          <h3>{t('title')}</h3>
        </Row>
        <Row justify="center">
          <a href="https://www.vdacs.virginia.gov/animals-animal-health-laboratory-services.shtml">
            https://www.vdacs.virginia.gov/animals-animal-health-laboratory-services.shtml
          </a>
        </Row>
        <Row className={styles.addressColumns}>
          <Col>
            <Row>{t('harrisonburgAddress')}</Row>
          </Col>
          <Col>
            <Row>{t('lynchburgAddress')}</Row>
          </Col>
          <Col>
            <Row>{t('warrentonAddress')}</Row>
          </Col>
          <Col>
            <Row>{t('wythevilleAddress')}</Row>
          </Col>
          <Col>
            <Row>{t('richmondOffice')}</Row>
          </Col>
        </Row>
      </Col>
      <Col span={5}>
        <div className={styles.accessionBox}>
          <p>{t('accession')}</p>
          <div className={styles.accessionBoxInputs}>
            <FormInput label={t('receipt')} />
            <FormInput label={t('amountOfPay')} classNames={styles.input} />
            <p>({t('labUseOnly')})</p>
          </div>
        </div>
      </Col>
    </Row>
  );
};
