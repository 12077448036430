import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { getEntries } from 'utils/object/getEntries';

const ineligibleHeadersConfig = {
  id: 'id',
  createdAt: 'createdAt',
  ownerData: {
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    contactPreferences: 'contactPreferences',
    locationPreferences: 'locationPreferences',
  },
  donorData: {
    name: 'name',
    age: 'age',
    neutered: 'neutered',
    weight: 'weight',
    location: 'location',
    hasTransfusion: 'hasTransfusion',
    takeMedications: 'takeMedications',
    primaryVeterinarian: 'primaryVeterinarian',
    species: 'species',
    gender: 'gender',
    medicationCourse: 'medicationCourse',
    microchipNumber: 'microchipNumber',
    distinguishableMarks: 'distinguishableMarks',
    hasMicrochip: 'hasMicrochip',
    hasHeartMurmur: 'hasHeartMurmur',
  },
};

const ineligibleHeadersConfigEntries = getEntries(ineligibleHeadersConfig);

export const ineligibleHeaders: LabelKeyObject[] =
  ineligibleHeadersConfigEntries.map(([key, label]) => ({ label, key }));
