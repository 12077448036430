import { DONATIONS_CSV_HEADERS } from 'modules/donations/constants';
import {
  CollectionSet,
  CollectionUnitsUsed,
  DonationUnit,
  FlowRate,
  JugularVein,
  VascularStructure,
  VeinUsed,
  WeatherType,
  CapillaryRefillTime,
  Mm,
} from 'types.d';

import { Dislikes } from '../DislikesSelect/enum';
import { DonationPositionUsed } from '../DonationPositionSelect/enum';
import { DonorMovement } from '../DonorMovementSelect/enum';
import { EmotionalSupport } from '../EmotionalSupportSelect/enum';
import { HoldersPosition } from '../HolderPositionSelect/enum';
import { Likes } from '../LikesSelect/enum';
import { MovementType } from '../MovementTypeSelect/enum';
import { TreatPreferences } from '../TreatmentPreferencesSelect/enum';

type KeyType = typeof DONATIONS_CSV_HEADERS[number]['key'];

const possibleValues: Partial<Record<KeyType, any>> = {
  capillaryRefillTime: CapillaryRefillTime,
  mucousMembrane: Mm,
  weatherTypes: [WeatherType],
  collectionSet: CollectionSet,
  jugularVein: JugularVein,
  unit: DonationUnit,
  preferences: TreatPreferences, //Do we really need this field
  numberOfCollectionUnitsUsed: CollectionUnitsUsed,
  veinUsed: VeinUsed,
  vascularStructure: VascularStructure,
  flowRate: FlowRate,
  donationPosition: DonationPositionUsed,
  holdersPosition: [HoldersPosition],
  donorMovement: DonorMovement,
  typeOfMovement: MovementType,
  emotionalSupport: [EmotionalSupport],
  dislikes: [Dislikes],
  likes: [Likes],
  treatPreferences: [TreatPreferences],
};

export const dataExample = Object.keys(possibleValues).map((field) => {
  const value = possibleValues[field as KeyType];
  const isArray = Array.isArray(value);
  const valueType = isArray ? value[0] : value;
  const firstValueTypeItem = Object.values(valueType)[0];
  const example = isArray
    ? Object.values(valueType).join('|')
    : firstValueTypeItem;
  const possibleValue = Object.values(valueType).join(', ');

  return {
    field,
    example,
    possibleValue,
  };
});
