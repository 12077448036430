import AuthRoute from 'modules/auth/components/AuthRoute';
import NoAuthRoute from 'modules/auth/components/NoAuthRoute';
import PublicRoute from 'modules/auth/components/PublicRoute';
import { RouteType } from 'modules/auth/enums';
import AppProvider from 'providers';
import { Routes, Route } from 'react-router-dom';

import './App.css';

import modules from './modules';

const mappedRoutes = {
  [RouteType.Auth]: AuthRoute,
  [RouteType.NoAuth]: NoAuthRoute,
  [RouteType.Public]: PublicRoute,
};

function App() {
  return (
    <AppProvider providers={modules.providers}>
      <Routes>
        {modules.routes?.map(
          ({ path, Component, roles, permissions, type, props }) => {
            const CurrentRoute = mappedRoutes[type];
            return (
              <Route
                key={path}
                path={path}
                element={
                  <CurrentRoute
                    Component={Component}
                    roles={roles}
                    permissions={permissions}
                    componentProps={props}
                  />
                }
              />
            );
          }
        )}
      </Routes>
    </AppProvider>
  );
}

export default App;
