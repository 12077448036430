import { FilterOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Checkbox, Divider } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Button } from 'modules/common/components';
import { UseFilterObject } from 'modules/common/hooks/useFilter';
import { DonationDateFilters } from 'modules/donor/types';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BloodType,
  DonorLastDonationIntervalInput,
  DonorListFilterInput,
  DonorStatus,
  DonorType,
  useGetLocationsQuery,
} from 'types.d';

import { getOptions } from './options';
import styles from './styles.module.scss';

type PropTypes = {
  onFilter?: (value?: DonorListFilterInput) => void;
  defaultValues?: DonorListFilterInput;
  donorListFilters?: UseFilterObject<DonorListFilterInput>;
};

const IndividualDonorListFilter: FC<PropTypes> = ({
  defaultValues,
  donorListFilters,
}) => {
  const { t } = useTranslation('donor.IndividualDonorListFilter');
  const { data: locationQueryData } = useGetLocationsQuery({
    variables: {
      input: {
        //TODO fix it using search query with async filters if it possible
        take: 100,
      },
    },
  });
  const [isVisible, setIsVisible] = useState(false);
  const [filter, setFilter] =
    useState<Record<keyof DonorListFilterInput, CheckboxValueType[]>>();
  const toFilter = (
    filters: Record<keyof DonorListFilterInput, CheckboxValueType[]>
  ): DonorListFilterInput => {
    const statuses = filters.statuses as DonorStatus[] | undefined;
    const types = filters.types as DonorType[] | undefined;
    const bloodType = filters.bloodType as BloodType[] | undefined;
    const donationDates = filters.lastDonationInterval as
      | DonationDateFilters[]
      | undefined;
    const locations = filters.locations as string[] | undefined;

    const donationIntervals = donationDates?.reduce<{
      from: number[];
      to: number[];
    }>(
      (interval, donationDate) => {
        const { from, to } = donationDateIntervals[donationDate];
        return {
          from: [...interval.from, from],
          to: [...interval.to, to],
        };
      },
      { from: [], to: [] }
    );
    const lastDonationInterval =
      donationDates?.length && donationIntervals
        ? {
            from: Math.min(...donationIntervals.from),
            to: Math.max(...donationIntervals.to),
          }
        : undefined;
    return {
      statuses,
      types,
      bloodType,
      lastDonationInterval,
      locations,
    };
  };

  const setIsVisibleTo = (value: boolean) => () => setIsVisible(value);

  const handleVisibleChange = (flag: boolean) => {
    setIsVisible(flag);
  };

  const filterHandler = () => {
    const transformedFilterValues = filter ? toFilter(filter) : filter;
    transformedFilterValues &&
      Object.keys(transformedFilterValues).forEach((fieldName) => {
        donorListFilters?.setFilterOption({
          fieldName: fieldName as keyof DonorListFilterInput,
          value: (transformedFilterValues as any)[fieldName],
        });
      });
    setIsVisible(false);
  };

  const onCheckboxChange =
    (key: keyof DonorListFilterInput) => (value: CheckboxValueType[]) => {
      setFilter({ ...filter, [key]: value } as any);
    };

  useEffect(() => {
    if (defaultValues) {
      const mappedValues = Object.keys(defaultValues).reduce(
        (filterValues, key) => {
          const filterFieldName = key as keyof DonorListFilterInput;
          if (key === 'lastDonationInterval') {
            const donationDates = defaultValues[
              filterFieldName
            ] as DonorLastDonationIntervalInput;

            const lastDonationInterval = Object.keys(
              donationDateIntervals
            ).filter((key) => {
              const { to, from } =
                donationDateIntervals[key as DonationDateFilters];
              const isSomeIntervalEdgeNotExist =
                typeof donationDates?.from !== 'number' ||
                typeof donationDates?.to !== 'number';

              if (isSomeIntervalEdgeNotExist) {
                return false;
              }

              const toValue = donationDates?.to as number;
              const fromValue = donationDates?.from as number;
              return to <= toValue && from >= fromValue;
            });

            return { ...filterValues, lastDonationInterval };
          }
          return { ...filterValues, [key]: defaultValues[filterFieldName] };
        },
        {} as Record<keyof DonorListFilterInput, CheckboxValueType[]>
      );
      setFilter(mappedValues);
    }
  }, [defaultValues, isVisible]);

  const options = getOptions({
    locations: locationQueryData?.getLocations.data,
  });

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={isVisible}
      overlayClassName={styles.dropdownOverlay}
      trigger={['click']}
      overlay={
        <Row>
          {options.map(({ title, key, values }) => {
            const filterValue = filter?.[key] as
              | CheckboxValueType[]
              | undefined;

            return (
              <Col span={24} key={key}>
                <Row>
                  <Col span={24} className={styles.title}>
                    {title}
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Checkbox.Group
                    value={filterValue}
                    onChange={onCheckboxChange(key)}
                  >
                    {values.map(({ value, label }, i) => (
                      <Col offset={1} span={22} key={i}>
                        <Checkbox value={value}>{label}</Checkbox>
                      </Col>
                    ))}
                  </Checkbox.Group>
                </Row>
              </Col>
            );
          })}
          <Divider />
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Button onClick={setIsVisibleTo(false)}>{t('cancel')}</Button>
              </Col>
              <Col span={12}>
                <Button type="primary" onClick={filterHandler}>
                  {t('filter')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <FilterOutlined
        className={styles.filterIcon}
        onClick={setIsVisibleTo(true)}
      />
    </Dropdown>
  );
};

const donationDateIntervals: Record<
  DonationDateFilters,
  DonorLastDonationIntervalInput
> = {
  [DonationDateFilters.from0to24daysFilter]: {
    from: 0,
    to: 24,
  },
  [DonationDateFilters.from24to29daysFilter]: {
    from: 24,
    to: 29,
  },
  [DonationDateFilters.moreThan30daysFilter]: {
    from: 30,
    to: 365,
  },
};

export default IndividualDonorListFilter;
