import { gql } from '@apollo/client';
import {
  ADDITIONAL_DONATION_INFO_FRAGMENT,
  DONATION_DONOR_FRAGMENT,
  DONATION_FRAGMENT,
  HOLDER_FRAGMENT,
} from 'modules/donations/graphql/fragments';
import { PICTURE_FRAGMENT } from 'modules/donor/graphql/fragments';
import { PRODUCT_FRAGMENT } from 'modules/products/graphql/fragments';

export const GET_WOOCOMMERCE_PRODUCT_CATEGORIES_QUERY = gql`
  query getWoocommerceCategories(
    $input: GetWoocommerceProductCategoriesInput!
  ) {
    getWoocommerceCategories(input: $input) {
      id
      count
      name
      slug
      parent
    }
  }
`;

export const GET_WOOCOMMERCE_PRODUCT_QUERY = gql`
  query getWoocommerceProducts($input: GetWoocommerceProductsInput!) {
    getWoocommerceProducts(input: $input) {
      data {
        categories {
          id
          name
          slug
        }
        date_created
        date_modified
        description
        id
        name
        price
        slug
        status
        stock_status
        stock_quantity
        type
      }
      meta {
        total
      }
    }
  }
`;

export const GET_PRODUCT_LIST = gql`
  query getProducts($input: ProductListInput!) {
    getProducts(input: $input) {
      data {
        ...Product
        donation {
          id
          collectionSet
          capillaryRefillTime
          approvedDonationsMl
          jugularVein
          maxLimit
          mucousMembrane
          packedCellVolume
          pulse
          respiration
          shouldDonate
          temperature
          totalSolids
          unit
          actual
          weatherTemperature
          weatherTypes
          weight
          examinationNotes
          preferences
          createdAt
          comment
          shortId
          donationDate
          ineligible
          ineligibleReason
          donor {
            ...DonationDonor
            team {
              id
              title
            }
            user {
              ...Holder
            }
            pictures {
              ...Picture
            }
          }
          additionalInfo {
            ...AdditionalDonationInfo
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${PRODUCT_FRAGMENT}
  ${PICTURE_FRAGMENT}
  ${DONATION_DONOR_FRAGMENT}
  ${ADDITIONAL_DONATION_INFO_FRAGMENT}
  ${HOLDER_FRAGMENT}
`;

export const GET_PRODUCT_QUERY = gql`
  query getProduct($input: GetProductInput!) {
    getProduct(input: $input) {
      ...Product
      donation {
        ...Donation
      }
    }
  }
  ${PRODUCT_FRAGMENT}
  ${DONATION_FRAGMENT}
`;

export const GET_PRODUCTS_COUNT = gql`
  query getProductsCount($input: GetProductsCountInput!) {
    getProductsCount(input: $input)
  }
`;

export const GET_WOOCOMMERCE_TOP_SELLERS = gql`
  query getWoocommerceTopSellers($input: GetWoocommerceTopSellersInput!) {
    getWoocommerceTopSellers(input: $input) {
      name
      product_id
      quantity
    }
  }
`;
