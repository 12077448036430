import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  dateValue: number | string | Date;
};

const LocaleDate: FC<Props> = ({ dateValue }) => {
  const translation = useTranslation();
  const date = new Date(dateValue)
    .toLocaleDateString(translation.i18n.language, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace(/ /g, ' ');
  return <>{date}</>;
};

export default LocaleDate;
