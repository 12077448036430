import { Row, Dropdown, message } from 'antd';
import AppointmentOverlay from 'modules/appointments/components/AppointmentOverlay';
import AppointmentTitle from 'modules/appointments/components/AppointmentTitle';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  AppointmentOutput,
  useRemoveAppointmentMutation,
  useUnenrollMyDonorFromAppointmentMutation,
} from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  appointments: AppointmentOutput[];
  onLoading?: (loading: boolean) => void;
};

const AppointmentsList: FC<PropTypes> = ({ appointments, onLoading }) => {
  const { t } = useTranslation('appointments.AppointmentsList');
  const [searchParams] = useSearchParams();
  const searchingAppointmentId = searchParams.get('appointment') || '';

  const [removeAppointment, removeAppointmentMutation] =
    useRemoveAppointmentMutation({
      update: (cache) => {
        cache.evict({ fieldName: 'getAppointments' });
      },
      onCompleted: () => {
        message.success(t('appointmentRemoved'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  const [unenrollMyDonor, unenrollMyDonorFromAppointmentMutation] =
    useUnenrollMyDonorFromAppointmentMutation({
      refetchQueries: ['getAppointmentsForCurrentUser'],
      onCompleted: () => {
        message.success(t('appointmentCanceled'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  const isLoading =
    unenrollMyDonorFromAppointmentMutation.loading ||
    removeAppointmentMutation.loading;

  const handleUnenrollDonor = ({
    donorId,
    appointmentId,
  }: {
    donorId: string;
    appointmentId: string;
  }) => {
    unenrollMyDonor({
      variables: {
        input: {
          donorId,
          id: appointmentId,
        },
      },
    });
  };
  const handleRemovingAppointment = (id: string) => {
    removeAppointment({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const searchingAppointmentRef = useRef(null);
  useEffect(() => {
    onLoading && onLoading(isLoading);
    const dropdownLabel = searchingAppointmentRef.current as any;
    dropdownLabel?.click();
  }, [isLoading]);

  return (
    <Row gutter={[0, 2]}>
      {appointments.map((appointment) => {
        return (
          <Dropdown
            trigger={['click']}
            overlayClassName={styles.appointmentOverlay}
            overlayStyle={{ width: 200, minWidth: 200 }}
            className={styles.appointment}
            key={appointment.id}
            overlay={
              <Row>
                <AppointmentOverlay
                  appointment={appointment}
                  onCancelAppointment={({ id, donor }) =>
                    handleUnenrollDonor({
                      donorId: donor?.id,
                      appointmentId: id,
                    })
                  }
                  onDeleteAppointment={({ id }) => {
                    handleRemovingAppointment(id);
                  }}
                />
              </Row>
            }
          >
            <div
              ref={
                (searchingAppointmentId === appointment.id &&
                  searchingAppointmentRef) ||
                null
              }
            >
              <AppointmentTitle appointment={appointment} />
            </div>
          </Dropdown>
        );
      })}
    </Row>
  );
};

export default AppointmentsList;
