import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Alert, Col, Form, message, Row } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Button, Spin } from 'modules/common/components';
import { GET_OWNER_QUERY } from 'modules/owner/graphql/queries';
import ArchivingButton from 'modules/user/components/ArchivingButton';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useUpdateOwnerInfoMutation,
  useGetOwnerQuery,
  UserOutput,
  OwnerOutput,
  PermissionsEnum,
  UserRole,
} from 'types.d';

import AdditionalContactsSection from './AdditionalContactsSection';
import { DeleteOwnerButton } from './DeleteOwnerButton';
import DonorsTable from './DonorsTable';
import PersonalInfoSection from './PersonalInfoSection';
import { editOwnerFormSchema, EditOwnerFormSchemaType } from './schema';
import styles from './styles.module.scss';

type PropTypes = {
  ownerId?: string | number;
};

const { UpdateOwnersData, ArchiveOwners } = PermissionsEnum;

const EditOwnerProfileForm: FC<PropTypes> = ({ ownerId }) => {
  const { t } = useTranslation('owner.EditOwnerProfileForm');
  const currentUser = useCurrentUser();
  const canUpdateOwners = currentUser.isCan(UpdateOwnersData);
  const canArchiveOwners = currentUser.isCan(ArchiveOwners);
  const canDeleteOwner = currentUser.data?.role.value === UserRole.Admin;
  const [formEnabled, setFormEnabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const [editOwner, editOwnerMutation] = useUpdateOwnerInfoMutation({
    refetchQueries: [
      {
        query: GET_OWNER_QUERY,
        variables: {
          input: { id: String(ownerId) },
        },
      },
    ],
    update: (cache) => {
      cache.evict({ id: `OwnerOutput:${ownerId}` });
    },
    onCompleted: (data) => {
      if (data.updateOwnerInfo.email !== ownerQuery.data?.getOwner?.email) {
        message.success(t('letterHasBeenSentToNewEmail'));
      } else {
        message.success(t('successfullySaved'));
      }
    },
    onError: (error) => message.error(error.message),
  });

  const editOwnerForm = useForm<EditOwnerFormSchemaType>({
    resolver: yupResolver(editOwnerFormSchema),
    mode: 'onChange',
  });

  const resetForm = (data?: OwnerOutput) => {
    editOwnerForm.reset({
      personalInfo: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber || '',
        streetAddress: data?.streetAddress || '',
        city: data?.city || '',
        state: data?.state || '',
        zipCode: data?.zipCode || '',
        locationPreferences: data?.locationPreferences || '',
        birthday: data?.birthday ? new Date(data?.birthday) : undefined,
        contactPreferences: data?.contactPreferences || undefined,
      },
      contact: {
        firstName: data?.contacts![0]?.firstName || '',
        lastName: data?.contacts![0]?.lastName || '',
        email: data?.contacts![0]?.email || '',
        streetAddress: data?.contacts![0]?.streetAddress || '',
        city: data?.contacts![0]?.city || '',
        state: data?.contacts![0]?.state || '',
        zipCode: data?.contacts![0]?.zipCode || '',
        phoneNumber: data?.contacts![0]?.phoneNumber || '',
      },
    });
  };

  const ownerQuery = useGetOwnerQuery({
    variables: {
      input: { id: String(ownerId) },
    },
    onError: (error) => {
      message.error(error.message);

      if (error.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.Owners);
    },
    onCompleted: (data) => resetForm(data.getOwner as OwnerOutput | undefined),
  });
  const owner = ownerQuery.data?.getOwner as OwnerOutput | undefined;
  const editOwnerHandler = editOwnerForm.handleSubmit(
    ({ contact, personalInfo }) => {
      const personalInfoEntries = Object.entries(personalInfo);
      const transformedPersonalInfo = personalInfoEntries.reduce(
        (transformed, entry) => {
          return { ...transformed, [entry[0]]: entry[1] || null };
        },
        {}
      );
      const personalInfoState = editOwnerForm.getFieldState('personalInfo');
      const contactState = editOwnerForm.getFieldState('contact');
      if (personalInfoState.isDirty || contactState.isDirty) {
        editOwner({
          variables: {
            input: {
              id: ownerQuery.data!.getOwner.id,
              ...(transformedPersonalInfo as Required<typeof personalInfo>),
              additionalContact: {
                ...contact,
                id: ownerQuery?.data?.getOwner?.contacts?.at(0)?.id,
              },
            },
          },
        });
      }
      setFormEnabled(!formEnabled);
    }
  );
  const isArchived = ownerQuery?.data?.getOwner?.isArchived;
  const deletedAt = ownerQuery.data?.getOwner.deletedAt as Date | null;
  const formattedDate =
    deletedAt && format(new Date(deletedAt), config.DATE_AND_TIME_FORMAT);
  const isOwnerArchived = isArchived === undefined ? true : isArchived;

  const checkIfSaveIsDisabled = () => {
    const isFormValid = editOwnerForm.formState.isValid;
    const isDirty = editOwnerForm.formState.isDirty;

    return !(isFormValid && isDirty && !isOwnerArchived);
  };

  return (
    <Spin spinning={editOwnerMutation.loading || ownerQuery.loading}>
      {canUpdateOwners && !deletedAt && (
        <Row className={styles.topFormButtonsContainer}>
          <Col span={24}>
            <Row gutter={[10, 10]} justify="space-between">
              <Col>
                {canDeleteOwner && <DeleteOwnerButton ownerId={owner?.id} />}
              </Col>
              <Col flex={1} />
              {canArchiveOwners && owner && (
                <Col>
                  <ArchivingButton user={owner} />
                </Col>
              )}
              <Col>
                <Button
                  onClick={() => {
                    resetForm(owner);
                    setFormEnabled(false);
                  }}
                  disabled={!formEnabled}
                >
                  {t('close')}
                </Button>
              </Col>
              <Col>
                {formEnabled && (
                  <Button
                    type="primary"
                    disabled={checkIfSaveIsDisabled()}
                    onClick={editOwnerHandler}
                  >
                    {t('save')}
                  </Button>
                )}

                {!formEnabled && (
                  <Button
                    type="primary"
                    disabled={isOwnerArchived}
                    onClick={() => {
                      setFormEnabled(!formEnabled);
                      resetForm(owner);
                      editOwnerForm.trigger();
                    }}
                  >
                    {t('edit')}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {deletedAt && (
        <Alert
          message={t('userDeleted')}
          description={`${t('userDeletedAt')} ${formattedDate}`}
          type="error"
        />
      )}
      {ownerQuery?.data?.getOwner && (
        <>
          <FormProvider {...editOwnerForm}>
            <Form labelCol={{ span: 12 }}>
              <Row justify="space-between" gutter={[10, 0]}>
                <Col sm={12} span={24}>
                  <PersonalInfoSection
                    fieldNamePrefix="personalInfo"
                    enabled={formEnabled}
                    owner={ownerQuery?.data?.getOwner as UserOutput}
                  />
                </Col>
                <Col sm={12} span={24}>
                  <AdditionalContactsSection
                    fieldNamePrefix="contact"
                    enabled={formEnabled}
                  />
                </Col>
              </Row>
            </Form>
          </FormProvider>
          {owner && (
            <Row justify="space-between" gutter={[10, 0]}>
              <Col sm={12} span={24}>
                <DonorsTable userId={owner.id} />
              </Col>
              <Col sm={12} span={24} />
            </Row>
          )}
        </>
      )}
    </Spin>
  );
};
export default EditOwnerProfileForm;
