import { Col, Row } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { GenderRadioButton } from 'modules/donor/components/RoutineTest/components/GenderRadioButton';
import { Label } from 'modules/donor/components/RoutineTest/components/Label';
import { RoutineTestFormSchemaType } from 'modules/donor/components/RoutineTestForm/schema';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type PropTypes = {
  data: RoutineTestFormSchemaType;
};

export const ContactsSection: FC<PropTypes> = ({ data }) => {
  const {
    veterinarian,
    ownerLastName,
    animalName,
    collectionDate,
    species,
    breed,
    age,
    gender,
    staffPet,
    patientID,
  } = data;

  const { t } = useTranslation('donor.RoutineTest.ContactsSection');

  return (
    <Col className={styles.contactsSection}>
      <Row key="veterinarian" align="bottom">
        <Label>{t('veterinarian')}:</Label>
        <Col flex="auto">
          <input className={styles.input} value={veterinarian || ''} readOnly />
        </Col>
      </Row>
      <Row key="ownerLastName" align="bottom">
        <Label>{t('ownerLastName')}:</Label>
        <Col flex="auto">
          <input
            className={styles.input}
            value={ownerLastName || ''}
            readOnly
          />
        </Col>
      </Row>
      <Row key="animalName" align="bottom">
        <Label>{t('animalName')}:</Label>
        <Col flex="auto">
          <input className={styles.input} value={animalName || ''} readOnly />
        </Col>
      </Row>
      <Row key="collectionDate" align="bottom">
        <Label>{t('collectionDate')}:</Label>
        <Col flex="auto">
          <input
            className={styles.input}
            value={
              collectionDate ? format(collectionDate, config.DATE_FORMAT) : ''
            }
            readOnly
          />
        </Col>
      </Row>
      <Row key="species" align="bottom">
        <Label key="speciesLabel">{t('species')}:</Label>
        <Col key="speciesInput" flex="1">
          <input className={styles.input} value={species || ''} readOnly />
        </Col>
        <Label key="breedLabel">{t('breed')}:</Label>
        <Col key="breedInput" flex="1">
          <input className={styles.input} value={breed || ''} readOnly />
        </Col>
        <Label key="ageLabel">{t('age')}:</Label>
        <Col key="ageInput" flex="1">
          <input className={styles.input} value={age || ''} readOnly />
        </Col>
      </Row>
      <Row key="gender" align="bottom">
        <Label>{t('gender')}:</Label>
        <GenderRadioButton checked={gender || false} />
        <Col className={styles.patientId}>
          <Label>{t('patientIdOrMrn')}:</Label>
        </Col>
        <Col flex="1 1 0px">
          <input className={styles.input} value={patientID || ''} readOnly />
        </Col>
      </Row>
      <Row key="staffPet" align="bottom">
        <Label>
          <span className={styles.orangeText}>{t('patientIdOrMrn')}:</span>
        </Label>
        <input
          type="radio"
          className={styles.staffPetRadioButton}
          checked={staffPet || false}
          readOnly
        />
        <Col>
          <span className={styles.orangeText}>{t('staffPetExplanation')}</span>
        </Col>
      </Row>
    </Col>
  );
};
