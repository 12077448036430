import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const ClinicSection = () => {
  const { t } = useTranslation('donor.RoutineTest.ClinicSection');

  return (
    <Col className={styles.clinicSection}>
      <Row align="middle" key="firstRow">
        <Col className={styles.label}>{t('clinicAccount')}:</Col>
        <Col>
          <input className={styles.input} value={2697} readOnly />
        </Col>
        <Col className={styles.label}>{t('zipCode')}:</Col>
        <Col flex="1 1 0px">
          <input className={styles.input} value={20110} readOnly />
        </Col>
      </Row>
      <Col key="secondRow">
        <Row align="middle">
          <Col className={styles.label}>{t('hospitalOrClinic')}:</Col>
          <Col flex="auto">
            <input className={styles.input} value="NAVBB" readOnly />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};
