import { ColumnsType } from 'antd/es/table/Table';
import { SortOrder } from 'antd/lib/table/interface';
import { Routes } from 'config/routes';
import { Link, CheckCell } from 'modules/common/components';
import { IDeleteProps } from 'modules/common/components/DeleteAction/interfaces';
import RestrictedLink from 'modules/common/components/RestrictedLink';
import { ISortOption } from 'modules/common/interfaces';
import TeamSelect from 'modules/donor/components/TeamSelect';
import {
  DonorListSort,
  DonorOutput,
  TeamOutput,
  BloodType,
  DonorStatus,
  DonorListFilterInput,
  PermissionsEnum,
  Order,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.DonorsTable');
const bloodTypeFilters = Object.values(BloodType).map((bloodType) => {
  return {
    text: t(bloodType.toLowerCase()),
    value: bloodType,
  };
});

const statusesFilters = Object.values(DonorStatus).map((status) => {
  return {
    text: t(status.toLowerCase()),
    value: status,
  };
});

type Props = IDeleteProps<DonorOutput> & {
  teams?: TeamOutput[];
  changeDonorTeamHandler?: (donor: DonorOutput, teamId: string) => void;
  showRemoveColumn?: boolean;
  showSorting?: boolean;
  showFilters?: boolean;
  showTeamsColumn?: boolean;
  onAttachClickHandler?: (newDonor: DonorOutput) => void;
  donorsToAttach?: DonorOutput[];
  freeSpaceInTeam?: number;
  filterValues?: DonorListFilterInput;
  sort?: ISortOption<DonorListSort>;
};

export const getColumns = ({
  teams,
  changeDonorTeamHandler,
  showFilters,
  showSorting,
  showTeamsColumn,
  onAttachClickHandler,
  donorsToAttach,
  freeSpaceInTeam,
  filterValues,
  sort,
}: Props): ColumnsType<DonorOutput> => {
  const getDefaultSort = (fieldName: DonorListSort): SortOrder | undefined => {
    if (sort?.fieldName === fieldName) {
      return sort.order === Order.Asc ? 'ascend' : 'descend';
    }
    return undefined;
  };
  const columns = [
    {
      title: t('donorId'),
      render: (row: DonorOutput) => row.shortId,
      align: 'center',
    },
    {
      title: t('petName'),
      key: DonorListSort.Name,
      render: (donor: DonorOutput) => (
        <Link
          to={`${Routes.DonorsProfile.replace(':id', String(donor.shortId))}`}
        >
          {donor.name}
        </Link>
      ),
      sorter: showSorting == undefined ? false : showSorting,
      sortOrder: getDefaultSort(DonorListSort.Name),
      align: 'center',
    },
    {
      title: t('ownersLastName'),
      key: DonorListSort.LastName,
      render: (row: DonorOutput) => (
        <RestrictedLink
          to={`${Routes.EditOwner.replace(':id', String(row.user?.shortId))}`}
          permissions={[PermissionsEnum.ViewTheListOfOwners]}
        >
          {row.user?.lastName || ''}
        </RestrictedLink>
      ),
      align: 'center',
    },
    {
      title: t('bloodType'),
      key: 'bloodType',
      render: (row: DonorOutput) => t(row.bloodType?.toLowerCase() || ''),
      filters: showFilters && bloodTypeFilters,
      filteredValue: showFilters ? filterValues?.bloodType || null : undefined,
      align: 'center',
    },
    {
      title: t('status'),
      key: 'statuses',
      render: (row: DonorOutput) => t(row.status.toLowerCase()),
      filters: showFilters && statusesFilters,
      align: 'center',
      filteredValue: showFilters ? filterValues?.statuses || null : undefined,
    },
    {
      title: t('teams'),
      key: DonorListSort.Team,
      render: (row: DonorOutput) => (
        <TeamSelect
          currentTeam={row.team as TeamOutput | undefined}
          teams={teams || []}
          onSelect={(teamId: string) =>
            row.team?.id !== teamId && changeDonorTeamHandler!(row, teamId)
          }
        />
      ),
      filter: showTeamsColumn == undefined ? true : !showTeamsColumn,
      sorter: showSorting == undefined ? false : showSorting,
      sortOrder: getDefaultSort(DonorListSort.Team),
      align: 'center',
    },
    //TODO: dDisabled code
    // {
    //   title: t('action'),
    //   render: (row: DonorOutput) => (
    //     <DeleteAction
    //       yesDeleteHandler={yesDeleteHandler}
    //       cancelDeleteHandler={cancelDeleteHandler}
    //       deleteHandler={() => deleteHandler && deleteHandler(row)}
    //       deleteEntity={deleteEntity}
    //     />
    //   ),
    //   filter: showRemoveColumn == undefined ? false : !showRemoveColumn,
    //   align: 'center',
    // },
    {
      title: t('attach'),
      render: (row: DonorOutput) => (
        <CheckCell
          disabled={
            freeSpaceInTeam !== undefined &&
            !donorsToAttach?.includes(row) &&
            freeSpaceInTeam <= (donorsToAttach || []).length
          }
          showTick={donorsToAttach?.includes(row) || false}
          onClick={() => onAttachClickHandler && onAttachClickHandler(row)}
        />
      ),
      filter: !onAttachClickHandler || !donorsToAttach,
      align: 'center',
    },
  ];
  return columns.reduce((previousValue, currentValue) => {
    const { filter, ...columnData } = currentValue;
    if (currentValue.filter) {
      return previousValue;
    }
    return [...previousValue, columnData as any];
  }, [] as ColumnsType<DonorOutput>);
};
