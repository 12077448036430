import { ApolloQueryResult } from '@apollo/client';
import { GET_DONOR_LIVES_SAVED_QUERY } from 'modules/donor/graphql/queries';
import { apolloClient } from 'providers/graphql';
import {
  GetDonorLivesSavedQuery,
  GetDonorLivesSavedQueryVariables,
} from 'types';

export const getDonorLSR = (
  donorId: string
): Promise<ApolloQueryResult<GetDonorLivesSavedQuery>> => {
  return apolloClient.query<
    GetDonorLivesSavedQuery,
    GetDonorLivesSavedQueryVariables
  >({
    query: GET_DONOR_LIVES_SAVED_QUERY,
    variables: {
      input: {
        donorId,
      },
    },
  });
};
