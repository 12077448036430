import Select from 'modules/common/components/Select';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, ComponentProps, useMemo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { InviteUserRole, UserRole } from 'types.d';

import { getOptions } from './options';

type PropTypes = {
  controlled: UseControllerProps<any>;
  omittedRoles?: InviteUserRole[];
} & Omit<ComponentProps<typeof Select>, 'options'>;

const InvitationRolesSelect: FC<PropTypes> = ({
  controlled,
  omittedRoles = [],
  ...restProps
}) => {
  const controller = useController(controlled);
  const currentUser = useCurrentUser();
  const isCurrentUserAdmin = currentUser.data?.role.value === UserRole.Admin;

  const roleOptions = useMemo(
    () =>
      getOptions({
        omittedRoles: isCurrentUserAdmin
          ? omittedRoles
          : [...omittedRoles, InviteUserRole.Admin],
      }),
    [omittedRoles]
  );

  return <Select options={roleOptions} {...controller.field} {...restProps} />;
};

export default InvitationRolesSelect;
