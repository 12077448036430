import { Col, Layout, Row } from 'antd';
import { Routes } from 'config/routes';
import { MainLayout } from 'modules/common/components';
import ResetPasswordForm from 'modules/user/components/ResetPasswordForm';
import { FC, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const ResetPasswordPage: FC = () => {
  const pageParams = useParams();
  const { code } = pageParams;
  const navigate = useNavigate();

  useEffect(() => {
    if (!code) {
      navigate(Routes.Login);
    }
  }, [navigate, code]);

  return (
    <MainLayout>
      <Layout.Content className={styles.content}>
        <Row justify="center" align="middle" className={styles.formContainer}>
          <Col flex="440px">{code && <ResetPasswordForm code={code} />}</Col>
        </Row>
      </Layout.Content>
    </MainLayout>
  );
};

export default ResetPasswordPage;
