import { Row, Col } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type PropTypes = {
  date: Date;
};

export const Head: FC<PropTypes> = ({ date }) => {
  const { t } = useTranslation('donor.RoutineTest.Head');

  return (
    <Row className={styles.head} align="bottom">
      <Row className={styles.content} justify="space-between" align="bottom">
        <Col className={styles.textWrapper}>
          <Col className={`${styles.headline} ${styles.large}`}>
            {t('routineTests')}
          </Col>
        </Col>
        <Col className={styles.textWrapper}>
          <Col className={`${styles.headline} ${styles.small}`}>
            <strong> {t('orderForm').toUpperCase()}</strong>|
            <span>
              {date &&
                format(date, config.ROUTINE_TEST_DATE_FORMAT).toUpperCase()}
            </span>
          </Col>
        </Col>
      </Row>
    </Row>
  );
};
