import { Spin } from 'modules/common/components';
import TeamCollapse from 'modules/donor/components/TeamCollapse';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TeamOutput, useFindGroupQuery } from 'types.d';

export const TeamsCardList: FC = () => {
  const [currentEdit, setCurrentEdit] = useState<string | null>(null);
  const params = useParams();
  const groupId = params.id || '';
  const { data: groupData, loading: findGroupLoading } = useFindGroupQuery({
    variables: {
      input: {
        id: groupId,
      },
    },
  });
  const teams = (groupData?.findGroup.teams as TeamOutput[]) || [];

  return (
    <Spin spinning={findGroupLoading}>
      {teams.map((team) => (
        <TeamCollapse
          key={team.id}
          team={team}
          setCurrentEdit={setCurrentEdit}
          currentEdit={currentEdit}
        />
      ))}
    </Spin>
  );
};
