import { Row, Col } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { getProductExpirationDate } from 'modules/products/utils/getProductExpirationDate';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type PropTypes = {
  donationDate: number | Date;
  productCategoryId: number;
};

const ProductExpirationDates: FC<PropTypes> = ({
  donationDate,
  productCategoryId,
}) => {
  const { t } = useTranslation('products.ProductExpirationDates');
  const expirationDate = getProductExpirationDate({
    categoryId: productCategoryId,
    donationDate,
  });
  return (
    <Row gutter={[10, 0]}>
      <Col span={10}>{t('expirationDate')}</Col>

      <Col span={10} className={styles.dateContainer}>
        {format(expirationDate, config.DATE_FORMAT)}
      </Col>
    </Row>
  );
};

export default ProductExpirationDates;
