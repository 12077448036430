import { PRODUCT_ID } from 'modules/products/constants/productId';
import { BloodType } from 'types.d';

const { Dea1Negative, Dea1Positive, Unknown } = BloodType;

export const productByBloodType: Record<BloodType, number[]> = {
  [Dea1Negative]: [
    PRODUCT_ID.FFP_CANINE_NEGATIVE_DOUBLE_UNIT,
    PRODUCT_ID.FFP_CANINE_NEGATIVE_SINGLE_UNIT,
    PRODUCT_ID.PRBC_CANINE_NEGATIVE_SINGLE_UNIT,
  ],
  [Dea1Positive]: [
    PRODUCT_ID.FFP_CANINE_POSITIVE_DOUBLE_UNIT,
    PRODUCT_ID.FFP_CANINE_POSITIVE_SINGLE_UNIT,
    PRODUCT_ID.PRBC_CANINE_POSITIVE_SINGLE_UNIT,
  ],
  [Unknown]: [],
};
