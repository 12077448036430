import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { addDays, subDays, lastDayOfMonth, startOfMonth } from 'date-fns';
import AppointmentsList from 'modules/appointments/components/AppointmentsList';
import { useCreateAppointmentsModalContext } from 'modules/appointments/contexts/CreateAppointmentsModal';
import { Spin, Calendar } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useGetAppointmentsQuery,
  AppointmentsFilterInput,
  UserRole,
  useGetAppointmentsForCurrentUserQuery,
  AppointmentOutput,
  PermissionsEnum,
  AppointmentType,
  useGetPrivateDaycareAppointmentForCurrentUserQuery,
} from 'types.d';

import Header from './components/Header';
import {
  AppointmentsCalendarSchemaType,
  appointmentsCalendarSchema,
} from './schema';
import { formatAppointments, getDateKey } from './utils';

const CURRENT_DATE = new Date();

const AppointmentsCalendar: FC = () => {
  const { debounce, clearDebounce } = useDebounce(1000);
  const [currentDate, setCurrentDate] = useState(CURRENT_DATE);
  const [calendarMode, setCalendarMode] = useState<CalendarMode>('month');
  const [appointmentsFilter, setAppointmentsFilter] =
    useState<AppointmentsFilterInput>({});
  const createAppointmentsModalContext = useCreateAppointmentsModalContext();
  const currentUser = useCurrentUser();
  const canSeeAppointmentsHistory = currentUser.isCan(
    PermissionsEnum.ViewTheHistoryOfAppointments
  );
  const currenUserRole = currentUser.data?.role.value;
  const { control, watch } = useForm<AppointmentsCalendarSchemaType>({
    resolver: yupResolver(appointmentsCalendarSchema),
  });
  const start = canSeeAppointmentsHistory
    ? subDays(startOfMonth(currentDate), 14)
    : CURRENT_DATE;
  const appointmentsInterval = {
    start,
    end: addDays(lastDayOfMonth(currentDate), 14),
  };
  const getAppointmentsForCurrentUserQuery =
    useGetAppointmentsForCurrentUserQuery({
      skip: currenUserRole !== UserRole.Owner,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          ...appointmentsInterval,
          filter: appointmentsFilter,
        },
      },
    });
  const getPrivateAppointmentForCurrentUser =
    useGetPrivateDaycareAppointmentForCurrentUserQuery({
      skip: currenUserRole !== UserRole.Owner,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          ...appointmentsInterval,
          filter: appointmentsFilter,
        },
      },
    });
  const getAppointmentsQuery = useGetAppointmentsQuery({
    skip: currenUserRole === UserRole.Owner,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        ...appointmentsInterval,
        filter: appointmentsFilter,
      },
    },
  });
  const [isAppointmentsListLoading, setIsAppointmentsListLoading] =
    useState(false);
  const isLoading =
    getAppointmentsQuery.loading ||
    getAppointmentsForCurrentUserQuery.loading ||
    getPrivateAppointmentForCurrentUser.loading ||
    isAppointmentsListLoading;
  const rawAppointments = getAppointmentsQuery.data?.getAppointments || [
    ...(getAppointmentsForCurrentUserQuery.data
      ?.getAppointmentsForCurrentUser || []),
    ...(getPrivateAppointmentForCurrentUser.data
      ?.getPrivateDaycareAppointmentForCurrentUser || []),
  ];

  const formattedAppointments = formatAppointments(
    rawAppointments as AppointmentOutput[]
  );

  const updateFilter = (filterValue: AppointmentsFilterInput) => {
    debounce(() => {
      setAppointmentsFilter((prevFilter) => ({
        ...prevFilter,
        ...filterValue,
      }));
    })(appointmentsFilter);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case 'userIds':
          updateFilter({
            userIds: value.userIds as string[] | undefined,
          });
          break;
        case 'location':
          updateFilter({
            locations: value.location as string[],
          });
          break;
        case 'type':
          updateFilter({
            type: value.type as AppointmentType,
          });
          break;
        case 'type':
          updateFilter({
            type: value.type,
          });
      }
    });
    return () => {
      subscription.unsubscribe();
      clearDebounce();
    };
  }, [watch]);

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col>
          <Calendar
            dateCellRender={(currentMoment) => {
              const dateKey = getDateKey(currentMoment);
              const appointments = formattedAppointments[dateKey];
              if (appointments) {
                return (
                  <AppointmentsList
                    onLoading={setIsAppointmentsListLoading}
                    appointments={appointments}
                  />
                );
              }
            }}
            headerRender={(headerRenderProps) => {
              return <Header {...headerRenderProps} control={control} />;
            }}
            value={currentDate}
            onSelect={(selectedDate) => {
              setCurrentDate(selectedDate);
              if (calendarMode === 'month') {
                createAppointmentsModalContext?.updateModalProps({
                  selectedDate,
                });
              }
            }}
            onPanelChange={(date, mode) => {
              setCurrentDate(date);
              createAppointmentsModalContext?.updateModalProps({
                selectedDate: date,
              });
              setCalendarMode(mode);
            }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default AppointmentsCalendar;
