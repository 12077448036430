import { locationPreferencesSchema, dateSchema } from 'modules/owner/schemas';
import {
  emailSchema,
  usernameSchema,
  phoneNumberSchema,
} from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { PersonalInfoFields } from './enum';

const {
  firstName,
  lastName,
  email,
  phoneNumber,
  state,
  city,
  streetAddress,
  zipCode,
  locationPreferences,
  birthday,
} = PersonalInfoFields;

type PersonalInfoSchemaType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  locationPreferences: string;
  birthday?: any;
};

const t = getTranslation('owner.ValidationErrors');

const personalInfoSchema: yup.SchemaOf<PersonalInfoSchemaType> = yup.object({
  [firstName]: usernameSchema,
  [lastName]: usernameSchema,
  [email]: emailSchema,
  [streetAddress]: yup
    .string()
    .required(t('required', { field: t('streetAddress') })),
  [city]: yup.string().required(t('required', { field: t('city') })),
  [state]: yup.string().required(t('required', { field: t('state') })),
  [zipCode]: yup
    .string()
    .length(5, t('mustBeExactLength', { field: t('zipCode'), value: 5 }))
    .matches(/^[0-9]+$/, t('onlyNumbers'))
    .required(t('required', { field: t('zipCode') })),
  [phoneNumber]: phoneNumberSchema,
  [locationPreferences]: locationPreferencesSchema,
  [birthday]: dateSchema,
});

export { personalInfoSchema };
export type { PersonalInfoSchemaType };
