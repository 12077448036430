import { Col } from 'antd';
import DonorProfileCardTabs from 'modules/donor/components/DonorProfileCardTabs';
import LabworkAttachmentsModal from 'modules/donor/components/LabworkAttachmentsModal';
import { useLabworkAttachmentsModalContext } from 'modules/donor/contexts/LabworkAttachmentsModal';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const DonorLabworkPage: FC = () => {
  const { t } = useTranslation('donor.DonorLabworkPage');
  const labworkAttachmentsModal = useLabworkAttachmentsModalContext();

  const params = useParams();
  const id = params.id || '';

  return (
    <>
      <ProfileSideMenuWrapper title={t('donorLabwork')}>
        <DonorProfileCardTabs donorId={id} />
      </ProfileSideMenuWrapper>
      <Col span={0}>
        {labworkAttachmentsModal?.modalConfig && (
          <LabworkAttachmentsModal {...labworkAttachmentsModal?.modalConfig} />
        )}
      </Col>
    </>
  );
};
export default DonorLabworkPage;
