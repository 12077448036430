import { UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Dragger } from 'modules/common/components';
import { getCsvHeaders } from 'modules/common/utils/papaparse/getCsvHeaders';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { getDonationsFromCsv } from './parseDonationsCsv';
import styles from './styles.module.scss';

type PropTypes = {
  clear: () => void;
  selectedFile: UploadFile[];
  setSelectedFile: (file: UploadFile[]) => void;
};

const DonationsDragger: FC<PropTypes> = ({
  clear,
  selectedFile,
  setSelectedFile,
}) => {
  const abstractCreateDonationsForm = useFormContext();
  const uploadProps: UploadProps = {
    accept: '.csv',
    maxCount: 1,
    className: styles.dragger,
    beforeUpload: async (file) => {
      const donationHeaders = await getCsvHeaders({ file });
      abstractCreateDonationsForm.setValue('csvHeaders', donationHeaders, {
        shouldValidate: true,
      });
      const donations = await getDonationsFromCsv(file);
      abstractCreateDonationsForm.setValue('donations', donations, {
        shouldValidate: true,
      });
      setSelectedFile([file]);
      return false;
    },
    onRemove: clear,
    fileList: selectedFile,
  };
  return <Dragger {...uploadProps} />;
};

export default DonationsDragger;
