import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { FC } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
};

const FileList: FC<PropTypes> = ({ fileList, setFileList }) => {
  if (!fileList.length) {
    return null;
  }

  const handleFileRemove = (fileUid: string) => {
    setFileList((fList) => fList.filter((f) => f.uid !== fileUid));
  };

  return (
    <List
      dataSource={fileList}
      renderItem={(file) => {
        return (
          <List.Item className={styles.listItem}>
            <div className={styles.fileName}>
              <PaperClipOutlined />
              {file.name}
            </div>
            <Button
              type="link"
              danger
              className={styles.delete}
              onClick={() => handleFileRemove(file.uid)}
            >
              <DeleteOutlined />
            </Button>
          </List.Item>
        );
      }}
    />
  );
};

export default FileList;
