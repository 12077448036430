import { Col, Divider, Row } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import config from 'config';
import { ControlledDatePicker, FormItem } from 'modules/common/components';
import {
  DonorInfoSection,
  ExaminationSection,
  EnvironmentSection,
  DonationInfoSection,
  QuantitySection,
} from 'modules/donations/components';
import { DonorAvatar } from 'modules/donor/components';
import { getPictureUrl } from 'modules/donor/components/DonorPicturesList/utils';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DonationOutput,
  FileOutput,
  DonorOutput,
  CollectionSet,
  PermissionsEnum,
} from 'types.d';

type Props = {
  donor: DonorOutput | undefined;
  previousDonation: DonationOutput | undefined;
  sectionNamePrefix: string;
  formEnabled: boolean;
};

const VitalsSection: FC<Props> = ({
  donor,
  previousDonation,
  sectionNamePrefix,
}: Props) => {
  const { t } = useTranslation('donation.VitalsSection');
  const breakpoint = useBreakpoint();
  const [pictureUrl, setPictureUrl] = useState<string>('');
  const currentUser = useCurrentUser();
  const canViewDonorsPictures = currentUser.isCan(
    PermissionsEnum.ViewDonorsPictures
  );

  useEffect(() => {
    const createPictureUrl = async () => {
      const donorPictureOutput = donor?.pictures;
      if (donorPictureOutput && donorPictureOutput.length > 0) {
        const url =
          donor?.pictures?.at(0)?.file &&
          (await getPictureUrl(donorPictureOutput.at(0)?.file as FileOutput));
        url && setPictureUrl(url);
      }
    };
    createPictureUrl();
  }, [donor]);

  const form = useFormContext();

  const weight = form.watch('vitalsSection.examination.weight');

  const autofillFieldsDueToWeight = (weight: number) => {
    const maxLimitValue = (
      ((weight * config.BLO0D_VOLUME_PER_KG) / 100) *
      config.BLO0D_VOLUME_PERCENTAGE
    ).toFixed(2);
    form.setValue('vitalsSection.donationInfo.maxLimit', maxLimitValue);

    const collectionSet =
      weight >= config.BIG_DONOR_MIN_WEIGHT
        ? CollectionSet.Quad
        : CollectionSet.Single;
    form.setValue('vitalsSection.donationInfo.collectionSet', collectionSet);
  };

  useEffect(() => {
    if (typeof weight !== 'number' || isNaN(Number(weight))) {
      form.resetField('vitalsSection.donationInfo.maxLimit');
      form.resetField('vitalsSection.donationInfo.collectionSet');
    } else {
      autofillFieldsDueToWeight(weight);
    }
  }, [weight]);

  return (
    <>
      {breakpoint.xl && (
        <>
          <Row gutter={16}>
            <Divider>{t('vitalsSection')}</Divider>

            <Divider plain>{t('examination')}</Divider>
            <Col span={6}>
              <Row gutter={[0, 15]}>
                {canViewDonorsPictures && (
                  <Col span={24}>
                    <DonorAvatar src={pictureUrl} />
                  </Col>
                )}
                <Col span={24}>
                  <DonorInfoSection
                    enabled={false}
                    fieldNamePrefix="donor"
                    sectionNamePrefix={sectionNamePrefix}
                  />
                </Col>
                <Col span={24}>
                  <FormItem
                    required
                    label={t('donationDate')}
                    validateStatus={
                      form.formState.errors.donationDate?.message && 'error'
                    }
                    extra={form.formState.errors.donationDate?.message}
                  >
                    <ControlledDatePicker
                      placeholder={t('pickDonationDate')}
                      controlled={{
                        control: form.control,
                        name: 'vitalsSection.donationDate',
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              <ExaminationSection
                enabled
                fieldNamePrefix="examination"
                sectionNamePrefix={sectionNamePrefix}
                previousDonation={previousDonation}
                showLinkToPreviousDonation
              />
            </Col>
          </Row>
          <Divider plain>{t('donationInfo')}</Divider>
          <Row gutter={16}>
            <Col span={6}>
              <EnvironmentSection
                enabled
                fieldNamePrefix="environment"
                sectionNamePrefix={sectionNamePrefix}
              />
            </Col>
            <Col span={12}>
              <DonationInfoSection
                enabled
                fieldNamePrefix="donationInfo"
                sectionNamePrefix={sectionNamePrefix}
              />
            </Col>
            <Col span={6}>
              <QuantitySection
                enabled
                fieldNamePrefix="quantity"
                sectionNamePrefix={sectionNamePrefix}
              />
            </Col>
          </Row>
        </>
      )}
      {!breakpoint.xl && (
        <Row gutter={[16, 16]}>
          {canViewDonorsPictures && (
            <Col span={24}>
              <Row>
                <Col>
                  <DonorAvatar src={pictureUrl} />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <DonorInfoSection
              enabled={false}
              fieldNamePrefix="donor"
              sectionNamePrefix={sectionNamePrefix}
            />
          </Col>
          <Col span={24}>
            <EnvironmentSection
              enabled
              fieldNamePrefix="environment"
              sectionNamePrefix={sectionNamePrefix}
            />
          </Col>
          <Col span={24}>
            <Divider plain orientation="left">
              {t('examination')}
            </Divider>
            <ExaminationSection
              fieldNamePrefix="examination"
              sectionNamePrefix={sectionNamePrefix}
              enabled
              previousDonation={previousDonation}
              showLinkToPreviousDonation
            />
          </Col>
          <Col span={24}>
            <Divider plain orientation="left">
              {t('donationInfo')}
            </Divider>
            <DonationInfoSection
              enabled
              fieldNamePrefix="donationInfo"
              sectionNamePrefix={sectionNamePrefix}
            />
          </Col>
          <Col span={24}>
            <QuantitySection
              enabled
              fieldNamePrefix="quantity"
              sectionNamePrefix={sectionNamePrefix}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default VitalsSection;
