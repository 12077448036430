import {
  bloodTypeSchema,
  petMarksSchema,
  donorTypeSchema,
  breedSchema,
  colorSchema,
  petSpeciesSchema,
  petGenderSchema,
  petWeightSchema,
  petNameSchema,
  petDateOfBirthSchema,
} from 'modules/donor/schemas';
import {
  BloodType,
  DonorType,
  AnimalGender,
  AnimalSpecies,
  DonorStatus,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donor.ValidationErrors');
const t2 = getTranslation('donor.CsvDonorsUploadingModal');

type CreateDonorSchemaType = {
  //Required fields
  name: string;
  dateOfBirth: Date;
  neutered: boolean;
  weight: number;
  species: AnimalSpecies;
  gender: AnimalGender;
  primaryVeterinarian: string;
  location: string;

  //Optional fields
  distinguishableMarks?: string;
  bloodType?: BloodType;
  donorType?: DonorType;
  breed?: string | null;
  color?: string | null;
  donationFrequency?: string;
  teamId?: string;
  comment?: string;
  medicationCourse?: string;
  microchipNumber?: string;
  type?: DonorType;
  status?: DonorStatus;
  rabiesDate?: Date;
  distemperDate?: Date;
  retiredDate?: Date;
  // nextDonationDate?: Date; //Disabled code
};

type CreateDonorsSchemaType = {
  donors: CreateDonorSchemaType[];
  userId: string;
  teamId?: string | null;
};

const createDonorSchema: yup.SchemaOf<CreateDonorSchemaType> = yup.object({
  //Required fields
  name: petNameSchema,
  dateOfBirth: petDateOfBirthSchema,
  neutered: yup.boolean().required(t('required', { field: t('neutered') })),
  weight: petWeightSchema,
  species: petSpeciesSchema,
  gender: petGenderSchema,
  primaryVeterinarian: yup
    .string()
    .required(t('required', { field: t('primaryVeterinarian') }))
    .max(255),
  location: yup.string().required(t('required', { field: t('location') })),
  // Optional fields
  distinguishableMarks: petMarksSchema,
  bloodType: bloodTypeSchema,
  donorType: donorTypeSchema,
  breed: breedSchema,
  color: colorSchema,
  donationFrequency: yup.string(),
  teamId: yup.string().when('donorType', {
    is: DonorType.Grouped,
    then: yup.string().required(),
  }),
  comment: yup.string().max(250),
  microchipNumber: yup.string(),
  medicationCourse: yup.string(),
  type: yup.mixed<DonorType>().oneOf(Object.values(DonorType)),
  status: yup.mixed<DonorStatus>().oneOf(Object.values(DonorStatus)),
  rabiesDate: yup.date(),
  distemperDate: yup.date(),
  retiredDate: yup.date(),
});

//TODO: add error messages
const createDonorsSchema: yup.SchemaOf<CreateDonorsSchemaType> = yup.object({
  donors: yup
    .array()
    .of(createDonorSchema)
    .min(1, t2('fileIsEmpty'))
    .max(50)
    .test({
      test: (donors = []) => {
        const isAllDonorsTypeEqual = donors?.every(
          ({ type }) => type === donors[0]?.type
        );
        return isAllDonorsTypeEqual;
      },
      message: t2('donorsAreNotSameType'),
    })
    .required(),
  userId: yup.string().required(t('required', { field: t('owner') })),
  teamId: yup.string().nullable(),
});

export { createDonorsSchema };
export type { CreateDonorsSchemaType, CreateDonorSchemaType };
