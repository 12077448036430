import { Col, Form, Row } from 'antd';
import config from 'config';
import { addMinutes, differenceInMinutes, format } from 'date-fns';
import { CreateAppointmentValidationSchema } from 'modules/appointments/components/CreateAppointmentsModal/schema';
import { useRecurringDateContext } from 'modules/appointments/contexts/RecurringDate';
import { AppointmentType } from 'modules/appointments/enums';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LocationType } from 'types.d';

import DatesSelect from './DatesSelect';
import DaycareDonorsSelect from './DaycareDonors';
import DonorSelect from './DonorSelect';
import { AppointmentFormField } from './fields';
import GroupSelect from './GroupSelect';
import { IsPrivateCheckbox } from './IsPrivateCheckbox';
import LocationsSelect from './LocationsSelect';
import NotesInput from './NotesInput';
import styles from './styles.module.scss';
import TeamSelect from './TeamSelect';
import TimeslotPicker from './TimeslotPicker';
import TitleSelect from './TitleSelect';
import TypeSelect from './TypeSelect';
import UserSelect from './UserSelect';

type PropTypes = {
  title?: string;
  isEditForm?: boolean;
};

const AppointmentsForm: FC<PropTypes> = ({ isEditForm, title }) => {
  const [hiddenFields, setHiddenFields] = useState<AppointmentFormField[]>([
    SelectedGroup,
    TeamId,
  ]);
  const { control, watch, setValue, resetField } =
    useFormContext<CreateAppointmentValidationSchema>();
  const recurringDates = watch('dates');
  const appointmentType = watch('type') as AppointmentType;
  const selectedGroup = watch('selectedGroup');
  const timeSlots = watch('timeslots');
  const location = watch('location');
  const splittingDisabled = Boolean(timeSlots)
    ? !canSplitTimeSlot(timeSlots)
    : false;
  const isDaycare = appointmentType === AppointmentType.Daycare;
  const isSplitCheckboxHidden =
    hiddenFields.includes(AppointmentFormField.Split) || isDaycare;
  const isHidden = (field: AppointmentFormField) => {
    return hiddenFields.includes(field);
  };
  const isEditDaycareAppointment =
    isEditForm && appointmentType === AppointmentType.Daycare;
  const recurringDate = useRecurringDateContext();

  useEffect(() => {
    if (appointmentType === AppointmentType.Grouped) {
      setHiddenFields([DonorId, Split]);
      isEditForm ? setValue(DonorId, undefined as never) : resetField(DonorId);
    } else {
      setHiddenFields([SelectedGroup, TeamId]);
      isEditForm ? setValue(TeamId, undefined as never) : resetField(TeamId);
      isEditForm
        ? setValue(SelectedGroup, undefined as never)
        : resetField(SelectedGroup);
    }
  }, [appointmentType]);

  useEffect(() => {
    if (!selectedGroup) {
      setValue(TeamId, null as never);
    }
  }, [selectedGroup]);

  useEffect(() => {
    resetField(DaycareDonorsIds);
  }, [location]);

  useEffect(() => {
    const [startTime, endTime] = timeSlots || [];
    if (startTime && !endTime) {
      setValue('timeslots', [startTime, addMinutes(startTime, 30)], {
        shouldValidate: true,
      });
    }
  }, [timeSlots]);
  const filter =
    appointmentType === AppointmentType.Daycare
      ? [LocationType.ForDaycareOwners]
      : [LocationType.ForExternalUsers, LocationType.ForInternalUsers];

  const cantSelectDaycare = isEditForm && !isEditDaycareAppointment;

  return (
    <Form labelCol={{ span: 8 }}>
      <Row gutter={[0, 5]}>
        <Col span={24} className={styles.title}>
          {title}
        </Col>
        {recurringDates?.length === 1 && (
          <Col span={24} className={styles.subtitle}>
            {format(recurringDates[0], config.DATE_FORMAT)}
          </Col>
        )}
        <TypeSelect
          disableDaycareSelect={cantSelectDaycare}
          control={control}
          required
          disabled={isEditForm}
        />
        <TitleSelect control={control} required />
        <TimeslotPicker
          required
          isSplitCheckboxHidden={isSplitCheckboxHidden}
          splittingDisabled={splittingDisabled}
        />
        {!isHidden(DonorId) && !isDaycare && (
          <DonorSelect showClearButtonByDefault={isEditForm} />
        )}
        {!isHidden(SelectedGroup) && (
          <GroupSelect
            control={control}
            required
            showClearButtonByDefault={isEditForm}
          />
        )}
        {!isHidden(TeamId) && <TeamSelect />}
        <LocationsSelect
          control={control}
          required
          daycare={isDaycare}
          filter={filter}
          disabled={isEditDaycareAppointment}
        />
        <UserSelect control={control} required />
        {isDaycare && !isEditDaycareAppointment && (
          <DaycareDonorsSelect location={location} />
        )}
        {isDaycare && <IsPrivateCheckbox control={control} />}
        <NotesInput control={control} />
        {recurringDate && <DatesSelect control={control} {...recurringDate} />}
      </Row>
    </Form>
  );
};

const { SelectedGroup, TeamId, DonorId, Split, DaycareDonorsIds } =
  AppointmentFormField;

const canSplitTimeSlot = ([start, end]: (Date | undefined)[]) => {
  if (!start || !end) {
    return false;
  }

  return 60 <= differenceInMinutes(end, start);
};

export default AppointmentsForm;
