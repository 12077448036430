import { QRCodeSVG } from 'qrcode.react';
import { ComponentProps, FC } from 'react';
import { envServices } from 'utils/EnvServices';

type PropTypes = { productId: string | number } & Omit<
  ComponentProps<typeof QRCodeSVG>,
  'value'
>;
const ProductQR: FC<PropTypes> = ({ productId, ...restProps }) => {
  return (
    <QRCodeSVG
      {...restProps}
      value={`${envServices.get('REACT_APP_CLIENT_URI')}/products/${productId}`}
    />
  );
};

export default ProductQR;
