import { InputNumber as AntdInputNumber, InputNumberProps } from 'antd';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  ({ className, ...restProps }: InputNumberProps, ref) => {
    const classNames = `${styles.input} ${className || ''}`;

    return <AntdInputNumber ref={ref} {...restProps} className={classNames} />;
  }
);

export default InputNumber;
