import { Col, Input } from 'antd';
import { set } from 'date-fns';
import {
  ControlledCheckbox,
  FormItem,
  TimeRangePicker,
} from 'modules/common/components';
import { ComponentProps, FC, useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23];

type PropTypes = {
  isSplitCheckboxHidden?: boolean;
  splittingDisabled?: boolean;
} & ComponentProps<typeof FormItem>;

const FIELD_NAME = AppointmentFormField.Timeslots;

const TimeslotPicker: FC<PropTypes> = ({
  isSplitCheckboxHidden,
  splittingDisabled,
  ...restFormItemProps
}) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const { control, formState, watch, trigger, setValue, getValues } =
    useFormContext<CreateAppointmentValidationSchema>();
  const getErrorMessage = (errors: FieldError | FieldError[]) => {
    if (Array.isArray(errors)) {
      return errors?.find((error) => error?.message)?.message;
    }
    const error = errors as unknown as FieldError;
    return error?.message;
  };
  const recurring = watch(AppointmentFormField.Dates);

  const fieldError = formState.errors.timeslots;
  const errorMessage = fieldError ? getErrorMessage(fieldError) : undefined;
  const timestamps = (recurring as Date[]).map((date) => date.getTime());
  const minTimestamp = Math.min(...timestamps);
  const startDate = timestamps.length ? new Date(minTimestamp) : new Date();

  useEffect(() => {
    if (!(recurring as Date[])?.length) {
      return;
    }

    const recurringSmallestDate = startDate;
    const timeRange = getValues(FIELD_NAME) as Date[];

    if (timeRange?.length > 0) {
      const newTimeRange = [
        set(timeRange[0], {
          date: recurringSmallestDate.getDate(),
          month: recurringSmallestDate.getMonth(),
        }),
        set(timeRange[1], {
          date: recurringSmallestDate.getDate(),
          month: recurringSmallestDate.getMonth(),
        }),
      ];

      setValue(FIELD_NAME, newTimeRange as never);
      trigger(FIELD_NAME);
    }
  }, [recurring]);
  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('timeSlots')}
        extra={errorMessage}
        validateStatus={errorMessage && 'error'}
        {...restFormItemProps}
      >
        <Input.Group>
          <TimeRangePicker
            hideDisabledOptions
            showSecond={false}
            minuteStep={30}
            adjustDropdownPosition
            startDate={startDate}
            disabledTime={(_date) => {
              return { disabledHours: () => DISABLED_HOURS };
            }}
            controlled={{ control, name: FIELD_NAME }}
          />

          {!isSplitCheckboxHidden && (
            <ControlledCheckbox
              disabled={splittingDisabled}
              controlled={{ control, name: AppointmentFormField.Split }}
            >
              {t('splitTimeSlot')}
            </ControlledCheckbox>
          )}
        </Input.Group>
      </FormItem>
    </Col>
  );
};

export default TimeslotPicker;
