import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { IDeleteProps } from './interfaces';

type Props<Entity> = {
  className?: string;
} & IDeleteProps<Entity>;

const DeleteAction = <Entity,>({
  deleteEntity,
  yesDeleteHandler,
  deleteHandler,
  cancelDeleteHandler,
  className,
}: Props<Entity>) => {
  const { t } = useTranslation('donor.DonorsTable');

  return (
    <span className={className}>
      <Popconfirm
        title={t('areYouSureToDelete')}
        onConfirm={yesDeleteHandler}
        onCancel={cancelDeleteHandler}
      >
        <DeleteOutlined
          onClick={() => deleteHandler && deleteHandler(deleteEntity!)}
        />
      </Popconfirm>
    </span>
  );
};
export default DeleteAction;
