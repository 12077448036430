import { yupResolver } from '@hookform/resolvers/yup';
import { Card } from 'antd';
import GroupCardInfo from 'modules/donor/components/GroupCardInfo';
import GroupListActionsButtons from 'modules/donor/components/GroupListActionsButtons';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GroupListItemOutput } from 'types.d';

import Title from './components/Title';
import { GroupTitleFormSchema, groupTitleFormSchema } from './schema';
import styles from './styles.module.scss';

type PropTypes = {
  group: GroupListItemOutput;
  setCurrentEdit: (group: null | string) => void;
  currentEdit: null | string;
};

const GroupCard: FC<PropTypes> = ({ group, currentEdit, setCurrentEdit }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const groupTitleForm = useForm<GroupTitleFormSchema>({
    resolver: yupResolver(groupTitleFormSchema),
    mode: 'onChange',
    defaultValues: {
      title: group.title,
    },
  });

  useEffect(() => {
    if (!isEditMode && groupTitleForm.formState.isDirty) {
      groupTitleForm.reset();
    }
  }, [isEditMode]);

  const cardClassName = `${styles.groupCard} ${
    !group.teamCount && styles.empty
  }`;

  const isEditCurrent = isEditMode ? currentEdit === group.id : isEditMode;

  return (
    <Card
      className={cardClassName}
      title={
        <Title
          group={group}
          isEditMode={isEditCurrent}
          errorMessage={groupTitleForm.formState.errors.title?.message}
          controlled={{ control: groupTitleForm.control, name: 'title' }}
        />
      }
      extra={
        <GroupListActionsButtons
          group={group}
          isEditMode={isEditCurrent}
          onToggleEditMode={(value) => {
            groupTitleForm.reset();
            setIsEditMode(value);
            setCurrentEdit(group.id);
          }}
          isMobile
          form={groupTitleForm}
        />
      }
    >
      <GroupCardInfo group={group} />
    </Card>
  );
};

export default GroupCard;
