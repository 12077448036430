import { ControlledSelect } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DonorStatus, PermissionsEnum } from 'types.d';

import { getDonorStatuesOptions } from './options';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const DonorStatusSelect: FC<PropTypes> = (props) => {
  const currentUser = useCurrentUser();
  const { t } = useTranslation('donor.DonorStatusSelect');
  const archiveDonorAllowed = currentUser.isCan(PermissionsEnum.ArchiveDonors);
  const donorStatusesOptions = getDonorStatuesOptions({
    exclude: !archiveDonorAllowed ? [DonorStatus.Archived] : [],
  });

  return (
    <ControlledSelect
      options={donorStatusesOptions}
      placeholder={t('selectStatus')}
      {...props}
    />
  );
};

export default DonorStatusSelect;
