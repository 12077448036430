import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, message } from 'antd';
import { BaseModalProps } from 'modules/common/types';
import { FC, useEffect, MouseEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppointmentOutput, useUpdateAppointmentMutation } from 'types.d';

import { getAppointmentFormValues } from './getAppointmentFormValues';

import CreateAppointmentsFormFields from '../CreateAppointmentsFormFields';
import {
  CreateAppointmentValidationSchema,
  createAppointmentValidationSchema,
} from '../CreateAppointmentsModal/schema';

type PropTypes = {
  appointment?: AppointmentOutput;
  onFormHandle?: () => void;
} & BaseModalProps;

export const EditAppointmentsModal: FC<PropTypes> = ({
  appointment,
  visible,
  onOk,
  hide,
  onFormHandle,
  ...restModalProps
}) => {
  const { t } = useTranslation('appointments.EditAppointmentsModal');
  const editAppointmentsForm = useForm<CreateAppointmentValidationSchema>({
    resolver: yupResolver(createAppointmentValidationSchema),
    mode: 'onChange',
    defaultValues: getAppointmentFormValues(appointment),
  });

  const [updateAppointment] = useUpdateAppointmentMutation();

  useEffect(() => {
    const formValues = getAppointmentFormValues(appointment);
    Object.keys(formValues).forEach((field) => {
      const typedField = field as keyof CreateAppointmentValidationSchema;
      editAppointmentsForm.setValue(typedField, formValues[typedField]);
    });
    editAppointmentsForm.reset(editAppointmentsForm.getValues());
  }, [appointment]);

  //this use effect allow chose only one date in recurring calendar
  useEffect(() => {
    const dates = editAppointmentsForm.getValues().dates;

    if (dates.length > 1) {
      const date = dates.slice(-1);
      editAppointmentsForm.setValue('dates', date);
    }
  }, [editAppointmentsForm.getValues().dates]);

  const updateAppointmentHandler = editAppointmentsForm.handleSubmit(
    async (formDate) => {
      if (!appointment) {
        return;
      }

      const {
        dates,
        timeslots,
        donorStatus,
        split,
        type,
        daycareDonorsIds,
        donorId: donorIdForm,
        selectedGroup,
        location,
        ...appointmentData
      } = formDate;

      const start = timeslots[0] || appointment.start;
      const end = timeslots[1] || appointment.end;
      const groupId = selectedGroup?.value;
      const donorId =
        donorIdForm === appointment.donor?.name
          ? appointment?.donor?.id
          : donorIdForm;

      await updateAppointment({
        variables: {
          input: {
            appointmentId: appointment.id,
            dates: [
              {
                start,
                end,
              },
            ],
            type,
            location,
            groupId,
            donorId,
            ...appointmentData,
          },
        },
        update: (cache) => {
          cache.evict({
            fieldName: 'getAppointments',
          });
        },
        onCompleted: () => {
          message.success(t('saved'));
        },
        onError: () => {
          message.error(t('errorWhileSaving'));
        },
        refetchQueries: ['getDaycareAppointmentInfo'],
      });
    }
  );

  const cancelHandler = () => {
    onFormHandle && onFormHandle();
    hide();
  };

  const okHandler = async (e: MouseEvent<HTMLElement>) => {
    onOk && onOk(e);
    onFormHandle && onFormHandle();
    hide();
    updateAppointmentHandler();
  };

  const isUpdateButtonDisabled =
    !editAppointmentsForm.formState.isValid ||
    !editAppointmentsForm.formState.isDirty;

  return (
    <Modal
      onCancel={cancelHandler}
      visible={visible}
      okText={t('save')}
      cancelText={t('close')}
      onOk={okHandler}
      destroyOnClose
      okButtonProps={{
        disabled: isUpdateButtonDisabled,
      }}
      {...restModalProps}
    >
      <FormProvider {...editAppointmentsForm}>
        <CreateAppointmentsFormFields isEditForm title={t('editAppointment')} />
      </FormProvider>
    </Modal>
  );
};
