import * as yup from 'yup';

export type AppointmentsCalendarSchemaType = {
  userIds: string[];
  locations: string[];
};

//TODO: add error messages
export const appointmentsCalendarSchema: yup.SchemaOf<AppointmentsCalendarSchemaType> =
  yup.object({
    userIds: yup.array(yup.string().required()).required(),
    locations: yup.array(yup.string().required()).required(),
  });
