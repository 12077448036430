import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const CreateTeamModalContext = createContext<ContextType | null>(null);

const CreateTeamModalProvider: ComponentWithChildren = ({ children }) => {
  const providerValue = useProviderValue();

  return (
    <CreateTeamModalContext.Provider value={providerValue}>
      {children}
    </CreateTeamModalContext.Provider>
  );
};

export const useCreateTeamModalContext = () => {
  const context = useContext(CreateTeamModalContext);
  if (context === undefined) {
    throw new Error(
      'the useCreateTeamModalContext should be used within CreateTeamModalProvider!'
    );
  }

  return context;
};

export default CreateTeamModalProvider;
