import { Select, SelectProps } from 'antd';
import config from 'config';
import styles from 'modules/common/components/Select/styles.module.scss';
import { useDebounce } from 'modules/common/hooks';
import { ComponentProps, FC, useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { DonorOutput, useGetCurrentUserDonorsListQuery } from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
  onSelect?: (donor: DonorOutput) => void;
} & Omit<ComponentProps<typeof Select>, 'options' | 'onSelect'>;

const CurrentUserDonorsSelect: FC<PropTypes> = ({
  controlled,
  className,
  onSelect,
  ...restProps
}) => {
  const classNames = [styles.select, className || ''].join(' ');
  const controller = useController(controlled);
  const { clearDebounce, debounce } = useDebounce();
  const getUsersListQuery = useGetCurrentUserDonorsListQuery({
    variables: {
      input: {
        //TODO:add searching functionality when it will be available on BE
        take: config.DEFAULT_LIMIT,
      },
    },
  });
  const donorList: SelectProps['options'] =
    getUsersListQuery.data?.getCurrentUserDonorsList.data.map((donor) => {
      return {
        value: donor.id,
        label: (
          <>
            {donor.name} <b>{donor.user?.lastName}</b>
          </>
        ),
      };
    });
  const handleSelect = (donorId: string) => {
    const selectedDonor =
      getUsersListQuery.data?.getCurrentUserDonorsList.data.find(
        ({ id }) => id === donorId
      );
    onSelect && onSelect(selectedDonor as DonorOutput);
  };

  const handleSearch = (value: string) => {
    getUsersListQuery.refetch({
      input: {
        filter: {
          searchingQuery: value,
          name: value,
        },
      },
    });
  };

  useEffect(() => clearDebounce, []);

  return (
    <Select
      options={donorList}
      {...restProps}
      className={classNames}
      onSelect={(donorId: any) => handleSelect(donorId)}
      loading={getUsersListQuery.loading}
      filterOption={false}
      defaultActiveFirstOption={false}
      showSearch
      onSearch={debounce(handleSearch)}
      {...controller.field}
    />
  );
};

export default CurrentUserDonorsSelect;
