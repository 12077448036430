import { Modal, Grid, message } from 'antd';
import config from 'config';
import { Spin } from 'modules/common/components';
import { useSort } from 'modules/common/hooks';
import { BaseModalProps } from 'modules/common/types';
import { AttachDonorsModalPropTypes } from 'modules/donor/types';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC, MouseEvent, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  DonorOutput,
  useFindGroupQuery,
  useFindDonorsForTeamQuery,
  useManageDonorsInTeamMutation,
  DonorListSort,
  DonorListFilterInput,
} from 'types.d';

import DonorsTeamList from '../DonorsTeamList';
import DonorsTeamTable from '../DonorsTeamTable';

type PropTypes = BaseModalProps & AttachDonorsModalPropTypes;

const AttachDonorsModal: FC<PropTypes> = ({ teamId, ...modalProps }) => {
  const { t } = useTranslation('donor.AttachDonorsModal');
  const breakpoints = Grid.useBreakpoint();
  const isBreakpointsEmpty = Object.keys(breakpoints).length === 0;
  const params = useParams();
  const groupId = params.id || '';
  const { data: groupData, loading: groupLoading } = useFindGroupQuery({
    variables: {
      input: {
        id: groupId,
      },
    },
  });
  const userId = (groupData && groupData?.findGroup?.user?.id) || '';
  const pagination = usePagination();
  const sort = useSort<DonorListSort>();
  const [filter, setFilter] = useState<DonorListFilterInput>({});
  const {
    loading: gettingDonorsLoading,
    data: donorsData,
    refetch,
  } = useFindDonorsForTeamQuery({
    variables: {
      input: {
        teamId,
        userId,
        filter,
        take: config.DEFAULT_LIMIT,
        sort: sort.options,
        skip: pagination.skip,
      },
    },
    onCompleted: ({ findDonorsForTeam }) => {
      pagination.setTotal(findDonorsForTeam.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const [manageDonorsInTeam, { loading: updatingDonorsLoading }] =
    useManageDonorsInTeamMutation({
      refetchQueries: ['findGroup', 'getUserDonorsList'],
      update: (cache) => {
        cache.evict({ id: `TeamOutput:${teamId}` });
      },
      onCompleted: () => {
        message.success(t('donorsHaveBeenAttached'));
      },
      onError: (error) => {
        message.error(error.message);
        setDonorsToAttach(donorsToAttachPrev);
      },
    });

  const donorsInCurrentTeam = useMemo(() => {
    const teams = groupData?.findGroup?.teams;
    const currentTeam = teams?.find((team) => team.id === teamId);
    const teamDonors = currentTeam?.donors as DonorOutput[] | undefined;
    return teamDonors?.length ? teamDonors : [];
  }, [groupData, teamId]);

  useEffect(() => {
    setDonorsToAttach(donorsInCurrentTeam);
    setDonorsToAttachPrev(donorsToAttach);
  }, [donorsInCurrentTeam]);

  const availableDonors = donorsData?.findDonorsForTeam.data || [];
  const [donorsToAttach, setDonorsToAttach] = useState<DonorOutput[]>([]);
  const [donorsToAttachPrev, setDonorsToAttachPrev] =
    useState<DonorOutput[]>(donorsToAttach);

  const onAttachClick = (newDonor: DonorOutput) => {
    const alreadyAttached = donorsToAttach.find(({ id }) => newDonor.id === id);
    const updatedDonorsToAttach = alreadyAttached
      ? donorsToAttach.filter((donor) => donor.id !== newDonor.id)
      : [...donorsToAttach, newDonor];

    setDonorsToAttach(updatedDonorsToAttach);
    setDonorsToAttachPrev(donorsToAttach);
  };

  const resetTableOptions = () => {
    setFilter({});
    sort.clearOptions();
  };

  const okHandler = (e: MouseEvent<HTMLElement>) => {
    if (!teamId) {
      return;
    }

    const donorIds = donorsToAttach.map(({ id }) => id);

    manageDonorsInTeam({
      variables: {
        input: {
          donorIds,
          teamId,
        },
      },
    });
    modalProps.onOk && modalProps.onOk(e);
    modalProps.hide();
    resetTableOptions();
  };

  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    modalProps.hide();
    modalProps.onCancel && modalProps.onCancel(e);
    resetTableOptions();
    setDonorsToAttach(donorsInCurrentTeam);
  };
  const isLoading =
    groupLoading || updatingDonorsLoading || gettingDonorsLoading;

  const isAttachedDonorsListChanged = () => {
    const teamDonorIds = donorsToAttach.map(({ id }) => id);

    const alreadyAttachedDonors = donorsInCurrentTeam.map(({ id }) => id);
    const difference = teamDonorIds
      .filter((id) => !alreadyAttachedDonors.includes(id))
      .concat(alreadyAttachedDonors.filter((id) => !teamDonorIds.includes(id)));

    return Boolean(difference.length);
  };
  const isOkButtonDisabled = !isAttachedDonorsListChanged();
  const donorsProps = {
    donors: availableDonors as DonorOutput[],
    attachedDonors: donorsToAttach,
    onAttachClickHandler: onAttachClick,
    pagination: pagination,
  };

  return (
    <Spin spinning={isLoading}>
      <Modal
        {...modalProps}
        width="100%"
        title={t('attachDonorsToTeamModal')}
        okButtonProps={{ disabled: isOkButtonDisabled }}
        onOk={okHandler}
        onCancel={cancelHandler}
        okText={t('attachDonors')}
      >
        {!isBreakpointsEmpty &&
          (breakpoints.md ? (
            <DonorsTeamTable
              {...donorsProps}
              sort={sort}
              filter={filter}
              setFilter={setFilter}
              isDonorsLoading={gettingDonorsLoading}
            />
          ) : (
            <DonorsTeamList {...donorsProps} />
          ))}
      </Modal>
    </Spin>
  );
};

export default AttachDonorsModal;
