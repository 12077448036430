import { ISortOption } from 'modules/common/interfaces';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Order } from 'types';

export type UseSortObject<FieldType = string> = {
  options: ISortOption<FieldType>[];
  setSortOption: (newSortOption: ISortOption<FieldType>) => void;
  removeSortOption: (fieldName: FieldType) => void;
  clearOptions: () => void;
};

export const useSort = <FieldType = string>(
  defaultOrder: ISortOption<FieldType>[] = []
): UseSortObject<FieldType> => {
  const [options, setOptions] =
    useState<ISortOption<FieldType>[]>(defaultOrder);
  const navigate = useNavigate();
  const location = useLocation();

  const setSortDataToUrl = (sortOption: ISortOption<FieldType>[]) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.forEach((value, key) => {
      if (value === 'ASC' || value === 'DESC') {
        urlSearchParams.delete(key);
      }
    });
    sortOption.forEach(({ fieldName, order }) => {
      urlSearchParams.set(fieldName as unknown as string, order);
    });
    navigate(`?${urlSearchParams.toString()}`);
  };

  const removeSortOptionFromUrl = (fieldName: string) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete(fieldName);
    navigate(`?${urlSearchParams.toString()}`);
  };

  const setSortOption = (newSortOption: ISortOption<FieldType>) => {
    const newSortOptions: ISortOption<FieldType>[] = [newSortOption];
    setOptions(newSortOptions);
    setSortDataToUrl(newSortOptions);
  };

  const removeSortOption = (fieldName: FieldType) => {
    setOptions([]);
    removeSortOptionFromUrl(fieldName as unknown as string);
  };

  const clearOptions = () => {
    options.forEach(({ fieldName }) => {
      removeSortOption(fieldName as FieldType);
    });
    setOptions([]);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const filters: ISortOption<FieldType>[] = [];

    urlSearchParams.forEach((value, key) => {
      if (value === 'ASC' || value === 'DESC') {
        filters.push({
          fieldName: key as unknown as FieldType,
          order: value as Order,
        });
      }
    });

    setOptions(filters);
  }, [location.search]);

  return {
    options,
    setSortOption,
    removeSortOption,
    clearOptions,
  };
};
