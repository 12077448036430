import { SelectProps } from 'antd';
import config from 'config';
import { Select } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { ComponentProps, FC, useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { DonorType, useGetDonorsListQuery } from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & Omit<ComponentProps<typeof Select>, 'options'>;

const DonorSelect: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);
  const { clearDebounce, debounce } = useDebounce();
  const getDonorsListQuery = useGetDonorsListQuery({
    variables: {
      input: {
        filter: {
          types: [DonorType.Individual, DonorType.Grouped],
        },
        take: config.DEFAULT_LIMIT,
      },
    },
  });
  const donorsList: SelectProps['options'] =
    getDonorsListQuery.data?.getDonorsList.data.map((donor) => {
      return {
        value: donor.id,
        label: (
          <>
            {donor.name} <b>{donor.user?.lastName}</b>
          </>
        ),
      };
    });

  const handleSearch = (value: string) => {
    getDonorsListQuery.refetch({
      input: {
        filter: {
          searchingQuery: value,
          types: [DonorType.Individual, DonorType.Grouped],
        },
      },
    });
  };

  useEffect(() => clearDebounce);

  return (
    <Select
      {...restProps}
      showSearch
      options={donorsList}
      loading={getDonorsListQuery.loading}
      filterOption={false}
      onSearch={debounce(handleSearch)}
      {...controller.field}
    />
  );
};

export default DonorSelect;
