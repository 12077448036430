import { Col } from 'antd';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import UsersCardTabs from 'modules/user/components/UsersCardTabs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const PermissionsPage: FC = () => {
  const { t } = useTranslation('admin.PermissionsPage');

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Col span={24}>
        <UsersCardTabs />
      </Col>
    </ProfileSideMenuWrapper>
  );
};

export default PermissionsPage;
