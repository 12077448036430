import { gql } from '@apollo/client';
import { DONATION_FRAGMENT } from 'modules/donations/graphql/fragments';
import {
  DONOR_FRAGMENT,
  GROUP_FRAGMENT,
  GROUP_LIST_ITEM_FRAGMENT,
  LABWORK_FRAGMENT,
  TEAM_FRAGMENT,
  RESERVED_DONOR_NOTE_FRAGMENT,
  VITALS_PARAMETER_FRAGMENT,
  PICTURE_FRAGMENT,
  FILE_FRAGMENT,
  GROUP_TEAM_FRAGMENT,
  LABWORK_ATTACHMENT_FRAGMENT,
  DONOR_NOTE_FRAGMENT,
} from 'modules/donor/graphql/fragments';
import { OWNER_FRAGMENT } from 'modules/owner/graphql/fragments';
import {
  CONTACT_OUTPUT_FRAGMENT,
  ROLE_WITHOUT_PERMISSIONS_OUTPUT_FRAGMENT,
} from 'modules/user/graphql/fragments';

export const GET_DONOR_LIST_QUERY = gql`
  query getDonorsList($input: DonorsListInput!) {
    getDonorsList(input: $input) {
      data {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
        reserveNotes {
          ...ReserveDonorNote
        }
      }
      meta {
        total
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

//TODO: optimize this query
export const GET_DONORS_FOR_TEAM_QUERY = gql`
  query findDonorsForTeam($input: DonorsListForTeamInput!) {
    findDonorsForTeam(input: $input) {
      data {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
        reserveNotes {
          ...ReserveDonorNote
        }
      }
      meta {
        total
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const GET_DONOR_QUERY = gql`
  query getDonor($input: GetDonorInput!) {
    getDonor(input: $input) {
      ...Donor
      lastLabworkFile {
        ...File
      }
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
      pictures {
        ...Picture
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${FILE_FRAGMENT}
  ${PICTURE_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const GET_CURRENT_USER_DONOR_LIST_QUERY = gql`
  query getCurrentUserDonorsList($input: CurrentUserDonorListInput!) {
    getCurrentUserDonorsList(input: $input) {
      data {
        ...Donor
        lastLabworkFile {
          ...File
        }
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
        reserveNotes {
          ...ReserveDonorNote
        }
      }
      meta {
        total
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
  ${FILE_FRAGMENT}
`;

export const FIND_GROUP_LIST_QUERY = gql`
  query findAllGroup($input: GroupListInput!) {
    findAllGroup(input: $input) {
      data {
        ...GroupListItem
        teams {
          ...GroupTeam
        }
      }
      meta {
        total
      }
    }
  }
  ${GROUP_LIST_ITEM_FRAGMENT}
  ${GROUP_TEAM_FRAGMENT}
`;

export const FIND_GROUP_QUERY = gql`
  query findGroup($input: FindGroupInput!) {
    findGroup(input: $input) {
      ...Group
      teams {
        ...Team
        donors {
          ...Donor
          user {
            ...Owner
            role {
              ...RoleWithoutPermissions
            }
            contacts {
              ...Contact
            }
          }
        }
      }
      user {
        ...Owner
        role {
          ...RoleWithoutPermissions
        }
        contacts {
          ...Contact
        }
      }
    }
  }
  ${ROLE_WITHOUT_PERMISSIONS_OUTPUT_FRAGMENT}
  ${GROUP_FRAGMENT}
  ${DONOR_FRAGMENT}
  ${TEAM_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

export const GET_DONOR_LIVES_SAVED_QUERY = gql`
  query getDonorLivesSaved($input: GetLivesSavedByDonorInput!) {
    getDonorLivesSaved(input: $input) {
      lives
    }
  }
`;

export const GET_CHARTS_DATA_QUERY_QUERY = gql`
  query getChartsData($input: GetSpecificDonorDonationsListInput!) {
    getChartsData(input: $input) {
      data {
        ...Donation
      }
      meta {
        total
      }
    }
  }
  ${DONATION_FRAGMENT}
`;

export const GET_LABWORK_LIST = gql`
  query getLabworkList($input: LabworkListInput!) {
    getLabworkList(input: $input) {
      data {
        ...Labwork
        donor {
          ...Donor
          user {
            ...Owner
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${LABWORK_FRAGMENT}
`;

export const GET_LABWORK = gql`
  query getLabwork($input: GetLabworkInput!) {
    getLabwork(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${FILE_FRAGMENT}
  ${LABWORK_FRAGMENT}
`;

export const GET_VITALS_PARAMETERS_LIST = gql`
  query getVitalsParameterList {
    getVitalsParameterList {
      ...VitalsParameter
    }
  }
  ${VITALS_PARAMETER_FRAGMENT}
`;

export const GET_APPOINTMENTS_DONORS_QUERY = gql`
  query getAppointmentsDonorsList {
    getAppointmentsDonorsList {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const GET_DONORS_BY_IDS_QUERY = gql`
  query getDonorsListByIds($input: GetDonorsByIdsInput!) {
    getDonorsListByIds(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const GET_TEAM_QUERY = gql`
  query findTeam($input: FindTeamInput!) {
    findTeam(input: $input) {
      ...Team
      donors {
        ...Donor
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${TEAM_FRAGMENT}
`;

export const GET_LABWORK_ATTACHMENTS_QUERY = gql`
  query getLabworkAttachments($input: GetLabworkAttachmentsListInput!) {
    getLabworkAttachments(input: $input) {
      ...LabworkAttachment
      file {
        ...File
      }
    }
  }
  ${LABWORK_ATTACHMENT_FRAGMENT}
  ${FILE_FRAGMENT}
`;

export const GET_DONOR_RELATED_CONNECTIONS = gql`
  query hasDonorRelatedConnections($input: DeleteDonorInput!) {
    hasDonorRelatedConnections(input: $input)
  }
`;

export const GET_DONOR_APPOINTMENTS = gql`
  query getAppointmentsDatesForDonor($donorId: String!) {
    getAppointmentsDatesForDonor(donorId: $donorId) {
      id
      start
      end
    }
  }
`;

export const GET_DONORS_NOTES = gql`
  query getDonorNotes($donorId: String!) {
    getDonorNotes(donorId: $donorId) {
      ...DonorNote
    }
  }

  ${DONOR_NOTE_FRAGMENT}
`;
