export default {
  ReportsPage: {
    reports: 'Reports',
  },
  ReportsTabs: {
    donors: 'Donors',
    donations: 'Donations',
    products: 'Products',
    owners: 'Owners',
    ineligibles: 'Ineligible Participants',
  },
  DonorsReportsCsvLink: {
    downloadReport: 'Download report',
  },
  DonorReports: {
    generateCsvReport: 'Generate donors CSV report',
  },
  DonationReports: {
    generateCsvReport: 'Generate donations CSV report',
  },
  OwnerReports: {
    generateCsvReport: 'Generate owners CSV report',
  },
  ProductReports: {
    generateCsvReport: 'Generate products CSV report',
  },
  IneligiblesReports: {
    generateCsvReport: 'Generate ineligible participants CSV report',
  },
};
