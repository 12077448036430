import { DONORS_CSV_HEADERS } from 'modules/donor/constants';
import { ComponentProps, FC } from 'react';
import { CSVLink } from 'react-csv';

import { dataExample } from './example';

type CSVLinkProps = ComponentProps<typeof CSVLink>;

const DonorsCsvTemplateDownload: FC = () => {
  return (
    <CSVLink
      filename="DonorsTemplate.csv"
      data={[dataExample]}
      headers={DONORS_CSV_HEADERS as unknown as CSVLinkProps['headers']}
    >
      DonorsTemplate.csv
    </CSVLink>
  );
};

export default DonorsCsvTemplateDownload;
