import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { PermissionsEnum } from 'types.d';

import RequestPage from './pages/Request';
import RequestsPage from './pages/Requests';

const requestsModule: IModule = {
  routes: [
    {
      path: Routes.Requests,
      Component: RequestsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewRequestsForApproval],
    },
    {
      path: Routes.Request,
      Component: RequestPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ApproveDisapproveRequests],
    },
  ],
};

export default requestsModule;
