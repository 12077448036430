import * as yup from 'yup';

export interface IUpdateDonorsOwner {
  id: string;
  ownerId: string | null;
}

export const updateDonorsOwnerSchema: yup.SchemaOf<IUpdateDonorsOwner> =
  yup.object({
    id: yup.string().required(),
    ownerId: yup.string().nullable().required(),
  });
