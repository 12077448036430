import { yupResolver } from '@hookform/resolvers/yup';
import { Table, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useSort } from 'modules/common/hooks';
import {
  GroupTitleFormSchema,
  groupTitleFormSchema,
} from 'modules/donor/components/GroupCard/schema';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  GroupListSort,
  GroupOutput,
  Order,
  GroupListItemOutput,
} from 'types.d';

import { getGroupColumns } from './columns';

const useGroupListSort = () => useSort<GroupListSort>();

type PropTypes = {
  groups?: GroupListItemOutput[];
  pagination: ReturnType<typeof usePagination>;
  sorting: ReturnType<typeof useGroupListSort>;
};

const GroupsTable: FC<PropTypes> = ({ groups, pagination, sorting }) => {
  const [currentGroup, setCurrentGroup] = useState<GroupListItemOutput>();

  const [isEditMode, setIsEditMode] = useState(false);
  const groupTitleForm = useForm<GroupTitleFormSchema>({
    resolver: yupResolver(groupTitleFormSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isEditMode && groupTitleForm.formState.isDirty) {
      groupTitleForm.reset();
    }
  }, [isEditMode]);

  const onTableChange: TableProps<GroupListItemOutput>['onChange'] = (
    _,
    __,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<GroupOutput>;
      const fieldName = tableSorter.columnKey as GroupListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        sorting.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        sorting.removeSortOption(fieldName);
      }
    }
  };

  const columns = getGroupColumns({
    onToggleEditMode: setIsEditMode,
    form: groupTitleForm,
    isEditMode,
    currentGroup,
    setCurrentGroup,
    sort: sorting?.options[0],
  });

  return (
    <Table
      rowKey={({ id }) => id}
      columns={columns}
      dataSource={groups}
      onChange={onTableChange}
      pagination={pagination.config}
    />
  );
};

export default GroupsTable;
