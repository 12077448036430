import { Select, SelectProps } from 'antd';
import { DonorMovement } from 'modules/donations/components/DonorMovementSelect/enum';
import { FC, useState, useEffect } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  controlled: UseControllerProps<any>;
} & SelectProps;

export const DonorMovementSelect: FC<Props> = ({
  controlled,
  ...restProps
}) => {
  const { t } = useTranslation('donation.DonorMovementSelect');
  const { field } = useController(controlled);
  const [selectedDonorMovement, setSelectedDonorMovement] = useState<
    string[] | undefined
  >();

  const onChangeHandler = (value: string) => {
    const [firstLocation, secondLocation] = value as unknown as string[];
    const newSelectedValue = value.length
      ? [secondLocation || firstLocation]
      : undefined;
    setSelectedDonorMovement(newSelectedValue);
    field.onChange(newSelectedValue?.[0]);
  };

  useEffect(() => {
    setSelectedDonorMovement(field.value ? [field.value] : undefined);
  }, [field.value]);
  const classNames = `${styles.select} ${restProps.className || ''}`;

  return (
    <Select
      {...restProps}
      mode="tags"
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      ref={field.ref}
      placeholder={t('selectDonorMovement')}
      value={selectedDonorMovement as any}
      className={classNames}
    >
      {Object.values(DonorMovement).map((movement) => {
        return (
          <Select.Option key={movement}>
            {t(movement.charAt(0).toLowerCase() + movement.slice(1))}
          </Select.Option>
        );
      })}
    </Select>
  );
};
