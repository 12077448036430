import { ControlledInput } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

import { DonorInfoFields } from './enum';

const t = getTranslation('donation.CreateDonationForm.DonorInfo');

export type DonationInfoFormFiledType = FormFieldWithComponent<
  DonorInfoFields,
  { name: string }
>;

type Props = {
  enabled: boolean;
};

export const getDonorInfoFields = ({
  enabled,
}: Props): DonationInfoFormFiledType[] =>
  [
    {
      name: DonorInfoFields.donorId,
      label: t(DonorInfoFields.donorId),
      Component: ({ control, name }) => (
        <ControlledInput
          disabled={!enabled}
          controlled={{ control, name }}
          defaultValue={t('failedToLoad')}
        />
      ),
    },
    {
      name: DonorInfoFields.donorName,
      label: t(DonorInfoFields.donorName),
      Component: ({ control, name }) => (
        <ControlledInput
          disabled={!enabled}
          controlled={{ control, name }}
          defaultValue={t('failedToLoad')}
        />
      ),
    },
  ] as DonationInfoFormFiledType[];
