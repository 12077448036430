import { Col, Row } from 'antd';
import { FC, useMemo } from 'react';
import { DonationOutput } from 'types.d';

import { getFields } from './fields';
import styles from './styles.module.scss';

type PropTypes = {
  donation: DonationOutput;
};

const DonationDetails: FC<PropTypes> = ({ donation }) => {
  const donationDetailsFields = useMemo(
    () => getFields({ donation }),
    [donation]
  );
  return (
    <Row gutter={[0, 15]}>
      {Object.keys(donationDetailsFields).map((fieldName, i) => {
        const fieldValue = donationDetailsFields[fieldName];
        return (
          <Col sm={12} span={24} key={`${fieldName}_${i}`}>
            <Row justify="space-between" gutter={10}>
              <Col span={12} className={styles.label}>
                {fieldName}:
              </Col>
              <Col span={12}>{fieldValue}</Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default DonationDetails;
