import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { InputRef, SearchProps } from 'antd/lib/input';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const SearchInput = forwardRef<InputRef, SearchProps>((props, ref) => {
  const { t } = useTranslation('common.SearchInput');
  return (
    <Input.Search
      prefix={<SearchOutlined className={styles.searchIcon} />}
      placeholder={t('searchBox')}
      ref={ref}
      {...props}
    />
  );
});

export default SearchInput;
