import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('user.EditLocationsForm');

export interface IAddLocationsFormSchema {
  name: string;
}

export const addRegionFormSchema: yup.SchemaOf<IAddLocationsFormSchema> =
  yup.object({
    name: yup
      .string()
      .max(100, t('lessThan', { max: 100 }))
      .strict(true)
      .trim(t('mustBeTrimmed'))
      .required(t('locationNameIsRequired')),
  });
