import { Tag } from 'antd';
import { Routes } from 'config/routes';
import { getUniqueProductTypes } from 'modules/products/utils/getUniqueProductTypes';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DonationOutput, PermissionsEnum } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  donation: DonationOutput;
  productCategories?: Record<number, string>;
};

const OutcomeCell: FC<PropTypes> = ({ donation, productCategories }) => {
  const { t } = useTranslation('donation.DonationsTable.OutcomeCell');
  const currentUser = useCurrentUser();
  const isCurUserCanSeeProducts = currentUser.isCan(
    PermissionsEnum.ViewProductList
  );

  const productNames = getUniqueProductTypes(donation)?.map((product) => {
    const productName =
      productCategories?.[product.woocommerceCategoryId] ||
      t('productDonation');
    return productName + ' ';
  });

  const productPath = isCurUserCanSeeProducts
    ? `${Routes.Products}?donation=${donation.shortId}`
    : '#';
  const productLinkClassName = !isCurUserCanSeeProducts
    ? styles.disabledLink
    : undefined;
  const ProductLink = (
    <Link className={productLinkClassName} to={productPath}>
      {productNames}
    </Link>
  );
  const IneligibleTag = <Tag color="default">{t('ineligible')}</Tag>;

  return donation.ineligible ? IneligibleTag : ProductLink;
};

export default OutcomeCell;
