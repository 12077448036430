import { userRoleSchema } from 'modules/user/schemas';
import { UserRole } from 'types';
import * as yup from 'yup';

type EditUserRoleSchema = {
  role: UserRole;
};

const editUserRoleSchema: yup.SchemaOf<EditUserRoleSchema> = yup.object({
  role: userRoleSchema,
});

export { editUserRoleSchema };
export type { EditUserRoleSchema };
