export default {
  OrdersPage: {
    orders: 'Orders',
  },
  OrdersTable: {
    orderNumber: 'Order number',
    orderDate: 'Order date',
    paymentMethod: 'Payment method',
    status: 'Status',
    trackingCode: 'Tracking code',
    searchByOrderNumber: 'Search by order number',
    more: 'More',
    any: 'Any',
    cancelled: 'Cancelled',
    completed: 'Completed',
    failed: 'Failed',
    onhold: 'On hold',
    pending: 'Pending',
    refunded: 'Refunded',
    trash: 'Trash',
    processing: 'Processing',
    shipped: 'Shipped',
  },
  BillingDetailsTable: {
    buyerName: 'Buyer name',
    street: 'Street',
    city: 'City',
    zip: 'ZIP',
    contact: 'Contact',
    email: 'Email',
  },
  ShippingDetailsTable: {
    buyerName: 'Buyer name',
    street: 'Street',
    city: 'City',
    zip: 'ZIP',
    contact: 'Contact',
  },
  CustomerDetailsTable: {
    customerName: 'Customer name',
    address: 'Address',
    street: 'Street',
    city: 'City',
    zip: 'ZIP',
    contact: 'Contact',
    email: 'Email',
  },
  OrderIdInput: {
    orderDoesNotExist: 'Order does not exist',
  },
};
