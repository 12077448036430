import { Popconfirm } from 'antd';
import { Button } from 'modules/common/components';
import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  onSave?: () => void;
  formIsValid?: boolean;
} & ComponentProps<typeof Button>;

const SaveButton: FC<PropTypes> = ({
  onSave,
  onClick,
  formIsValid,
  ...restProps
}) => {
  const { t } = useTranslation('donor.EditDonorForm.SaveButton');
  const [visible, setVisible] = useState(false);

  const confirm = () => {
    setVisible(false);
    onSave?.();
  };

  const cancel = () => {
    setVisible(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setVisible(newOpen);
      return;
    }
    if (formIsValid) {
      confirm();
    } else {
      setVisible(newOpen);
    }
  };
  return (
    <Popconfirm
      title={t('areYouSureToSave')}
      visible={visible}
      onVisibleChange={handleOpenChange}
      onConfirm={confirm}
      onCancel={cancel}
      okText={t('yes')}
      cancelText={t('no')}
    >
      <Button type="primary" {...restProps}>
        {t('save')}
      </Button>
    </Popconfirm>
  );
};

export default SaveButton;
