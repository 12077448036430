import { useState } from 'react';

import { ContextType } from './type';

export const useProviderValue = (): ContextType => {
  const [activeKey, setActiveKey] = useState<string | string[]>([]);

  const onChange = (key: string | string[]): void => {
    setActiveKey(key);
  };

  const onClear = (): void => {
    setActiveKey([]);
  };

  return {
    activeKey,
    onChange,
    onClear,
  };
};
