import { gql } from '@apollo/client';

export const OWNER_FRAGMENT = gql`
  fragment Owner on OwnerOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
    type
    isArchived
    contactPreferences
    deletedAt
  }
`;

export const USER_ATTACHMENT_FRAGMENT = gql`
  fragment UserAttachment on UserAttachmentOutput {
    id
    createdAt
    updatedAt
  }
`;
