import { gql } from '@apollo/client';
import {
  CONTACT_OUTPUT_FRAGMENT,
  PERMISSION_FRAGMENT,
  ROLE_OUTPUT_FRAGMENT,
  USER_OUTPUT_FRAGMENT,
} from 'modules/user/graphql/fragments';

export const GET_ALL_INELIGIBLE_QUERY = gql`
  query getAllIneligible {
    getAllIneligibleParticipants {
      ineligible {
        id
        createdAt
        ownerData
        donorData
      }
    }
  }
`;

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${ROLE_OUTPUT_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${USER_OUTPUT_FRAGMENT}
`;

export const GET_MY_PERMISSIONS_QUERY = gql`
  query getMyPermissions {
    getMyPermissions {
      ...Permission
      role {
        ...Role
      }
    }
  }
  ${ROLE_OUTPUT_FRAGMENT}
  ${PERMISSION_FRAGMENT}
`;

export const GET_USER_LIST = gql`
  query getUsersList($input: GetUserListInput!) {
    getUsersList(input: $input) {
      data {
        ...User
        contacts {
          ...Contact
        }
        role {
          ...Role
        }
      }
      meta {
        total
      }
    }
  }
  ${ROLE_OUTPUT_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${USER_OUTPUT_FRAGMENT}
`;

export const GET_OWNER_LIST = gql`
  query getOwnersList($input: GetOwnerListInput!) {
    getOwnersList(input: $input) {
      data {
        ...User
        contacts {
          ...Contact
        }
        role {
          ...Role
        }
      }
      meta {
        total
      }
    }
  }
  ${ROLE_OUTPUT_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${USER_OUTPUT_FRAGMENT}
`;

export const CHECK_USER_CONNECTION_QUERY = gql`
  query checkUserConnection {
    checkUserConnection
  }
`;
