import {
  petSpeciesSchema,
  petGenderSchema,
  petWeightSchemaInKg,
  petNameSchema,
  petDateOfBirthSchema,
} from 'modules/donor/schemas';
import { AnimalGender, AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { RequiredFormSectionField } from './fields';

const t = getTranslation('donor.ValidationErrors');

const {
  Name,
  DateOfBirth,
  Neutered,
  Weight,
  Species,
  Gender,
  MedicationCourse,
  PrimaryVeterinarian,
  UserId,
  Location,
  Region,
} = RequiredFormSectionField;

type RequiredFormSectionSchemaType = {
  name: string;
  dateOfBirth: Date;
  neutered: boolean;
  weight: number;
  species: AnimalSpecies;
  gender: AnimalGender;
  medicationCourse?: string;
  primaryVeterinarian: string;
  userId: string;
  region?: string;
  location: string;
};

const requiredFormSectionSchema: yup.SchemaOf<RequiredFormSectionSchemaType> =
  yup.object({
    [Name]: petNameSchema,
    [DateOfBirth]: petDateOfBirthSchema,
    [Neutered]: yup.boolean().required(t('required', { field: t('neutered') })),
    [Weight]: petWeightSchemaInKg,
    [Species]: petSpeciesSchema,
    [Gender]: petGenderSchema,
    [MedicationCourse]: yup
      .string()
      .max(255, t('shorterThan', { max: 255, field: t('medicationHistory') })),
    [PrimaryVeterinarian]: yup
      .string()
      .required(t('required', { field: t('primaryVeterinarian') }))
      .max(
        255,
        t('shorterThan', { max: 255, field: t('primaryVeterinarian') })
      ),
    [UserId]: yup
      .string()
      .required(t('required', { field: t('ownerLastName') })),
    [Location]: yup.string().required(t('required', { field: t('location') })),
    [Region]: yup.string(),
  });

export { requiredFormSectionSchema };
export type { RequiredFormSectionSchemaType };
