import * as yup from 'yup';

import {
  contactSchema,
  ContactSchemaType,
} from './AdditionalContactsSection/schema';
import {
  personalInfoSchema,
  PersonalInfoSchemaType,
} from './PersonalInfoSection/schema';

type CreateProfileFormSchemaType = {
  personalInfo: PersonalInfoSchemaType;
  contact: ContactSchemaType;
};

const createProfileFormSchema: yup.SchemaOf<CreateProfileFormSchemaType> =
  yup.object({
    personalInfo: personalInfoSchema,
    contact: contactSchema,
  });

export { createProfileFormSchema };
export type { CreateProfileFormSchemaType };
