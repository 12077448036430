import { Row, Col, Pagination } from 'antd';
import config from 'config';
import { Spin } from 'modules/common/components';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import NotificationCard from 'modules/notification/components/NotificationCard';
import { GET_NOTIFICATION_LIST } from 'modules/notification/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect } from 'react';
import {
  NotificationOutput,
  useGetNotificationListQuery,
  useMakeNotificationsReadMutation,
} from 'types.d';

const NotificationsList: FC = () => {
  const currentUser = useCurrentUser();
  const pagination = usePagination();
  const { data, loading: getLoading } = useGetNotificationListQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          userId: currentUser.data?.id,
        },
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
      },
    },
    onCompleted: ({ getNotificationsList }) => {
      pagination.setTotal(getNotificationsList.meta.total);
    },
  });

  const [updatePubSubList, { loading: updateLoading }] =
    useMakeNotificationsReadMutation({
      refetchQueries: [
        {
          query: GET_NOTIFICATION_LIST,
          variables: {
            input: {
              filter: {
                userId: currentUser.data?.id,
                isRead: false,
              },
            },
          },
        },
      ],
    });

  useEffect(() => {
    updatePubSubList();
  }, []);

  const isLoading = getLoading || updateLoading;

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[0, 10]} justify="end">
        {data?.getNotificationsList.data.map((pubSub) => {
          return (
            <Col span={24} key={pubSub.id}>
              <NotificationCard notification={pubSub as NotificationOutput} />
            </Col>
          );
        })}
        <Col>
          <Pagination {...pagination.config} />
        </Col>
      </Row>
    </Spin>
  );
};

export default NotificationsList;
