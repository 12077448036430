import { donorStatusSchema } from 'modules/donor/schemas';
import { DonorStatus, RetiredReasons } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { PetChipField } from './fields';

const { chipNo, status, hasMicrochip, RetiredClarification, RetiredReason } =
  PetChipField;

type DonorStatusSectionSchemaType = {
  status: DonorStatus;
  microchipNumber?: string;
  hasMicrochip?: boolean;
  retiredReason?: RetiredReasons | null;
  retiredClarification?: string | null;
};

const t = getTranslation('donor.ValidationErrors');

const donorStatusSectionSchema: yup.SchemaOf<DonorStatusSectionSchemaType> =
  yup.object({
    [status]: donorStatusSchema.required(),
    [chipNo]: yup
      .string()
      .test({
        test: (value) => value?.trim() === value,
        message: t('mustBeTrim'),
      })
      .max(50, t('shorterThan', { max: 50, field: t('chipNo') })),
    [hasMicrochip]: yup.boolean(),
    [RetiredClarification]: yup
      .string()
      .max(
        255,
        t('shorterThan', { max: 255, field: t('retiredClarification') })
      )
      .optional()
      .nullable(),
    [RetiredReason]: yup
      .mixed<RetiredReasons>()
      .oneOf([...Object.values(RetiredReasons), null])
      .when(status, {
        is: DonorStatus.Retired,
        then: (schema) =>
          schema.required(t('required', { field: t('RetiredReason') })),
        otherwise: (schema) => schema.optional(),
      })
      .nullable(),
  });

export { donorStatusSectionSchema };
export type { DonorStatusSectionSchemaType };
