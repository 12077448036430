import { DatePicker } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import config from 'config';
import { addDays, format } from 'date-fns';
import LabworkStatusSelect from 'modules/donor/components/LabworkStatusSelect';
import moment, { Moment } from 'moment';
import { LabworkOutput, LabworkListSort, LabworkStatus } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import ActionsCell from './ActionsCell';
import styles from './styles.module.scss';

const t = getTranslation('donor.LabworkTable');

type Props = {
  updateLabworkHandler: (data: LabworkOutput) => void;
  updateLabworkStatusHandler: (id: string, status: LabworkStatus) => void;
  removeLabworkHandler: (id: string) => void;
  canUpdateLabwork: boolean;
  canShareLabworkResults: boolean;
  canUpdateLabworkStatus: boolean;
  cadDeleteLabwork: boolean;
};

export const getColumns = ({
  updateLabworkHandler,
  updateLabworkStatusHandler,
  removeLabworkHandler,
  canUpdateLabwork,
  canUpdateLabworkStatus,
}: Props): ColumnsType<LabworkOutput> => [
  {
    title: t('date'),
    key: LabworkListSort.CollectionDate,
    render: (row: LabworkOutput) => (
      <DatePicker
        value={moment(new Date(row.collectionDate))}
        onChange={(value: Moment | null, dateString: string) => {
          updateLabworkHandler({
            ...row,
            collectionDate: new Date(dateString),
          });
        }}
        format={(value) => format(value.toDate(), config.DATE_FORMAT)}
        className={styles.datePicker}
        allowClear={false}
        disabled={!canUpdateLabwork}
        disabledDate={(current) => current && current > moment().endOf('day')}
      />
    ),
    defaultSortOrder: 'descend',
    align: 'center',
    sorter: true,
  },
  {
    title: t('status'),
    render: (labwork: LabworkOutput) => (
      <LabworkStatusSelect
        defaultValue={labwork.status}
        onSelect={(status: LabworkStatus) =>
          updateLabworkStatusHandler(labwork.id, status)
        }
        disabled={!canUpdateLabworkStatus}
      />
    ),
    align: 'center',
  },
  {
    title: t('dueDate'),
    render: (row: LabworkOutput) =>
      format(
        addDays(new Date(row.collectionDate), config.LABWORK_DURATION),
        config.DATE_FORMAT
      ),
    align: 'center',
  },
  {
    title: t('actions'),
    render: (labwork: LabworkOutput) => {
      return (
        <ActionsCell
          labwork={labwork}
          removeHandler={() => removeLabworkHandler(labwork.id)}
        />
      );
    },
    align: 'center',
  },
];
