import DonorApplication from 'modules/donor/components/DonorApplicationForm';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DonorApplicationPage: FC = () => {
  const { t } = useTranslation('donor.DonorApplicationPage');
  return (
    <ProfileSideMenuWrapper title={t('donorApplication')}>
      <DonorApplication />
    </ProfileSideMenuWrapper>
  );
};

export default DonorApplicationPage;
