import { CheckboxOptionType, RadioGroupProps } from 'antd';
import { ControlledRadioGroup } from 'modules/common/components';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & RadioGroupProps;

const t = getTranslation('donor.DonorSpeciesRadioGroup');

const constantOptions: CheckboxOptionType[] = [
  {
    value: AnimalSpecies.Dog,
    label: t('dog'),
  },
  {
    value: AnimalSpecies.Cat,
    label: t('cat'),
  },
];

const DonorSpeciesRadioGroup: FC<PropTypes> = ({
  controlled,
  ...restProps
}) => {
  return (
    <ControlledRadioGroup
      options={constantOptions}
      controlled={controlled}
      {...restProps}
    />
  );
};

export default DonorSpeciesRadioGroup;
