import LocationCardTabs from 'modules/locations/components/LocationCardTabs';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import React from 'react';

export default function LocationsPage() {
  return (
    <ProfileSideMenuWrapper title="Locations">
      <LocationCardTabs />
    </ProfileSideMenuWrapper>
  );
}
