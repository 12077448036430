import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { Row, Col } from 'antd';
import { Select } from 'modules/common/components';
import { IOptionType } from 'modules/common/components/Sorter/interfaces';
import { useSort } from 'modules/common/hooks';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from 'types.d';

type PropTypes = {
  sorting: ReturnType<typeof useSort>;
  options: IOptionType[];
  defaultFieldName?: string;
  defaultOrder?: Order;
};

export const Sorter: FC<PropTypes> = ({
  sorting,
  options,
  defaultFieldName,
  defaultOrder,
}) => {
  const { t } = useTranslation('common.Sorter');
  const [order, setOrder] = useState<Order>(defaultOrder || Order.Asc);
  const [fieldName, setFieldName] = useState<string | undefined>(
    defaultFieldName
  );

  const onSelect = (data: string) => {
    sorting.clearOptions();
    setFieldName(data);
  };

  useEffect(() => {
    fieldName &&
      sorting.setSortOption({
        fieldName,
        order,
      });
  }, [order, fieldName]);

  const onClickAscending = () => {
    sorting.clearOptions();
    setOrder(Order.Asc);
  };

  const onClickDescending = () => {
    sorting.clearOptions();
    setOrder(Order.Desc);
  };

  return (
    <Row gutter={[10, 0]} align="middle">
      {(order === Order.Desc || order === undefined) && (
        <Col>
          <SortAscendingOutlined onClick={onClickAscending} />
        </Col>
      )}
      {(order === Order.Asc || order === undefined) && (
        <Col>
          <SortDescendingOutlined onClick={onClickDescending} />
        </Col>
      )}
      <Col>
        <Select
          options={options}
          defaultValue={defaultFieldName}
          placeholder={t('selectSorter')}
          onSelect={onSelect}
        />
      </Col>
    </Row>
  );
};
