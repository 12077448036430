import { ApolloLink, DocumentNode } from '@apollo/client';
import { Operation, NextLink } from '@apollo/client/link/core/types';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import {
  UPDATE_DONATION_COMMENT_MUTATION,
  CREATE_DONATION_WITH_ADDITIONAL_INFO_MUTATION,
} from 'modules/donations/graphql/mutations';
import {
  UPDATE_DONOR_MUTATION,
  CREATE_LABWORK_MUTATION,
  UPDATE_LABWORK_MUTATION,
} from 'modules/donor/graphql/mutations';

export type SerializedMutation = {
  name: string;
  variables: Record<string, any>;
};

export const OFFLINE_MUTATIONS_MAP: Record<string, DocumentNode> = {
  updateDonor: UPDATE_DONOR_MUTATION,
  createLabwork: CREATE_LABWORK_MUTATION,
  updateLabwork: UPDATE_LABWORK_MUTATION,
  updateDonationComment: UPDATE_DONATION_COMMENT_MUTATION,
  createDonationWithAdditionalInfo:
    CREATE_DONATION_WITH_ADDITIONAL_INFO_MUTATION,
};

export const checkIfOperationSupportsOfflineMode = (operation: Operation) => {
  return Object.keys(OFFLINE_MUTATIONS_MAP).some(
    (key) => key === operation.operationName
  );
};

export class OfflineLink extends ApolloLink {
  request(operation: Operation, forward: NextLink) {
    const operationSupportsOfflineMode =
      checkIfOperationSupportsOfflineMode(operation);
    if (operationSupportsOfflineMode) {
      const newMutation: SerializedMutation = {
        name: operation.operationName,
        variables: operation.variables,
      };
      let offlineMutationsJsonString =
        localStorage.getItem(LOCALE_STORAGE_KEYS.OFFLINE_MUTATIONS) || '';
      let existingOfflineMutationsList: SerializedMutation[] =
        offlineMutationsJsonString !== ''
          ? JSON.parse(offlineMutationsJsonString)
          : [];
      const isAlreadySaved = existingOfflineMutationsList.some(
        (mutation) => JSON.stringify(mutation) === JSON.stringify(newMutation)
      );
      if (!isAlreadySaved) {
        localStorage.setItem(
          LOCALE_STORAGE_KEYS.OFFLINE_MUTATIONS,
          JSON.stringify([...existingOfflineMutationsList, newMutation])
        );
      }

      return forward(operation).map((data) => {
        offlineMutationsJsonString =
          localStorage.getItem(LOCALE_STORAGE_KEYS.OFFLINE_MUTATIONS) || '';
        existingOfflineMutationsList =
          offlineMutationsJsonString !== ''
            ? JSON.parse(offlineMutationsJsonString)
            : [];
        const newOfflineMutationsList = existingOfflineMutationsList.filter(
          (mutation) => JSON.stringify(mutation) !== JSON.stringify(newMutation)
        );
        localStorage.setItem(
          LOCALE_STORAGE_KEYS.OFFLINE_MUTATIONS,
          JSON.stringify(newOfflineMutationsList)
        );
        return data;
      });
    }
    return forward(operation);
  }
}
