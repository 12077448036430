import { emailSchema } from 'modules/user/schemas';
import * as yup from 'yup';

type SignInSchemaType = {
  email: string;
  password: string;
  durable?: boolean;
};

const signInSchema: yup.SchemaOf<SignInSchemaType> = yup.object({
  email: emailSchema,
  password: yup.string().required(),
  durable: yup.boolean(),
});

export { signInSchema };
export type { SignInSchemaType };
