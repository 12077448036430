import { Col } from 'antd';
import { FormItem } from 'modules/common/components';
import { useUpdateDonorsOwnerModalContext } from 'modules/donor/contexts/UpdateDonorsOwnerModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserRole } from 'types.d';

import { getFields } from './fields';

type PropTypes = {
  fieldNamePrefix: string;
  disabled?: boolean;
};

const RequiredFormSection: FC<PropTypes> = ({ fieldNamePrefix, disabled }) => {
  const { control, formState } = useFormContext();
  const currentUser = useCurrentUser();
  const isCurrentUserAdmin = currentUser.data?.role.value === UserRole.Admin;
  const updateDonorsOwnerModal = useUpdateDonorsOwnerModalContext();
  const requiredFormSectionFields = useMemo(() => {
    return getFields({
      onEditOwnerClick: updateDonorsOwnerModal?.showModal,
      isEditOwnerAllowed: isCurrentUserAdmin,
    });
  }, [isCurrentUserAdmin]);

  return (
    <>
      {requiredFormSectionFields.map(({ Component, label, name }) => {
        const fieldName = `${fieldNamePrefix}.${name}`;
        const errorMessage = formState.errors[fieldNamePrefix]?.[name]?.message;
        return (
          <Col span={24} key={fieldName}>
            <FormItem
              label={label}
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
            >
              <Component
                control={control}
                name={fieldName}
                disabled={disabled}
              />
            </FormItem>
          </Col>
        );
      })}
    </>
  );
};

export default RequiredFormSection;
