import { CloseCircleFilled } from '@ant-design/icons';
import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Select } from 'modules/common/components';
import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamOutput, useFindGroupQuery } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  teams?: TeamOutput[];
  groupId?: string;
  currentTeam?: TeamOutput;
  onSelect?: (teamId: string) => void;
  onChangeControlled?: (...event: any[]) => void;
} & Omit<ComponentProps<typeof Select>, 'options' | 'onSelect'>;

const TeamSelect: FC<PropTypes> = ({
  teams,
  groupId,
  currentTeam,
  onSelect,
  onChangeControlled,
  allowClear,
  ...restProps
}) => {
  const { t } = useTranslation('donor.TeamSelect');
  const [isCloseBtnHidden, setIsCloseBtnHidden] = useState<boolean>(true);

  const findGroupQuery = useFindGroupQuery({
    skip: !groupId || Boolean(teams?.length),
    variables: {
      input: {
        id: groupId || '',
      },
    },
  });
  const groupTeams = findGroupQuery.data?.findGroup.teams || teams;
  const options: SelectProps['options'] = groupTeams?.map((team) => {
    return {
      value: team.id,
      label: team.title,
    };
  });

  const currentValue = currentTeam
    ? {
        value: currentTeam.id,
        label: currentTeam.title,
      }
    : null;

  const onClear = () => {
    onChangeControlled && onChangeControlled('');
    setIsCloseBtnHidden(true);
  };

  const clearButton = isCloseBtnHidden ? undefined : (
    <CloseCircleFilled onClick={onClear} />
  );

  const onSelectHandler = (value: any, option: DefaultOptionType) => {
    onSelect && onSelect(value);
    onChangeControlled && onChangeControlled(value);

    if (allowClear) {
      setIsCloseBtnHidden(false);
    }
  };

  return (
    <Select
      options={options}
      adjustDropdownPosition
      placeholder={t('selectTeam')}
      {...restProps}
      className={styles.select}
      loading={findGroupQuery.loading}
      showSearch={false}
      filterOption={false}
      onSelect={onSelectHandler}
      defaultActiveFirstOption={false}
      value={currentValue || restProps.value}
      suffixIcon={allowClear ? clearButton : undefined}
    />
  );
};

export default TeamSelect;
