import { highlightCsvHeadersLabel } from 'modules/common/utils/papaparse/highlightCsvHeadersLabel';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { DONATIONS_CSV_HEADERS } from 'modules/donations/constants';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';

import { CreateDonationSchemaType, createDonationSchema } from '../../schema';

const isRequired = getIsRequiredInSchema(createDonationSchema);
const createDonationSchemaKeys = Object.keys(
  createDonationSchema.fields
) as unknown as (keyof CreateDonationSchemaType)[];
const requiredHeaders = createDonationSchemaKeys.filter(isRequired);

export const highlightedHeaders = highlightCsvHeadersLabel({
  headers: DONATIONS_CSV_HEADERS as unknown as LabelKeyObject[],
  toHighlight: requiredHeaders,
});
