import { Row } from 'antd';
import React from 'react';

import styles from './styles.module.scss';

export default function Specimens() {
  return (
    <div className={styles.specimens}>
      <h4>Specimens</h4>
      <input className={styles.input} />
      <input className={styles.input} />
      <Row className={styles.inputWrapper}>
        <label>Nec</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>His</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>CP</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>Bac</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>Ser</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>Vir</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>Par</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>EM</label>
        <input className={styles.input} />
      </Row>
      <Row className={styles.inputWrapper}>
        <label>Oth</label>
        <input className={styles.input} />
      </Row>
      <p>Office Use Only!</p>
    </div>
  );
}
