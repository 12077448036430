import { Col } from 'antd';
import {
  ControlledTextArea,
  FormItem,
  Button,
} from 'modules/common/components';
import { useHidingFields } from 'modules/common/hooks';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { FC, useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DonorType } from 'types.d';

import { getFields, OptionalFormSectionField } from './fields';
import { mockedGroup } from './mockedData';
import { optionalFormSectionSchema } from './schema';

import styles from '../styles.module.scss';

type PropTypes = {
  fieldNamePrefix: string;
  userId?: string;
};

const OptionalFormSection: FC<PropTypes> = ({ fieldNamePrefix, userId }) => {
  const { t } = useTranslation('donor.CreateDonorForm.OptionalSection');
  const [displayCommentField, setDisplayCommentField] = useState(false);
  const isRequired = getIsRequiredInSchema(optionalFormSectionSchema);
  const { showField, hideField, hiddenFields } =
    useHidingFields<OptionalFormSectionField>({
      hiddenFields: [OptionalFormSectionField.Team],
    });
  const optionalFormSectionFields = useMemo(
    () =>
      getFields({
        hiddenFields,
        groups: mockedGroup,
        userId,
      }),
    [hiddenFields, userId]
  );

  const getFieldName = (name: OptionalFormSectionField): string => {
    return `${fieldNamePrefix}.${name}`;
  };

  const { control, formState, watch } = useFormContext();
  const donorTypeFieldName = getFieldName(OptionalFormSectionField.DonorType);
  const hasMicrochipFieldName = getFieldName(
    OptionalFormSectionField.hasMicrochip
  );
  const donorType = watch(donorTypeFieldName) as DonorType;
  const hasMicrochip = watch(hasMicrochipFieldName) as DonorType;

  useEffect(() => {
    const { Team } = OptionalFormSectionField;
    donorType === DonorType.Grouped ? showField(Team) : hideField(Team);
  }, [donorType]);

  useEffect(() => {
    const { ChipNo } = OptionalFormSectionField;
    if (hasMicrochip) {
      showField(ChipNo);
    } else {
      hideField(ChipNo);
    }
  }, [hasMicrochip]);

  const commentErrorMessage =
    formState.errors[fieldNamePrefix]?.comment?.message;

  return (
    <>
      {optionalFormSectionFields.map(({ Component, label, name }) => {
        const fieldName = getFieldName(name);
        const errorMessage = formState.errors[fieldNamePrefix]?.[name]?.message;
        const required = isRequired(name);
        return (
          <Col span={24} key={fieldName}>
            <FormItem
              label={label}
              extra={errorMessage}
              required={required}
              validateStatus={errorMessage && 'error'}
            >
              <Component control={control} name={fieldName} />
            </FormItem>
          </Col>
        );
      })}
      <Col span={24}>
        {/*TODO: remove this temporary text*/}
        <FormItem className={styles.flatFormItem} label={t('labwork')}>
          Date of next labwork
        </FormItem>
      </Col>
      <Col span={24}>
        {/*TODO: change this on real formula*/}
        <FormItem className={styles.flatFormItem} label={t('livesSavedRatio')}>
          Create formula
        </FormItem>
      </Col>
      <Col span={24}>
        {displayCommentField ? (
          <FormItem
            label={t('comment')}
            validateStatus={commentErrorMessage && 'error'}
            extra={commentErrorMessage}
          >
            <ControlledTextArea
              placeholder={t('enterComment')}
              controlled={{
                control,
                name: `${fieldNamePrefix}.${OptionalFormSectionField.Comment}`,
              }}
              className={styles.textArea}
            />
          </FormItem>
        ) : (
          <Button
            className={styles.leaveCommentButton}
            onClick={() => setDisplayCommentField(true)}
          >
            {t('leaveComment')}
          </Button>
        )}
      </Col>
    </>
  );
};

export default OptionalFormSection;
