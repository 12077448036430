import { Routes } from 'config/routes';
import { ITabsItem } from 'modules/common/components/CardTabs';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import DonationReports from 'modules/reports/components/DonationReports';
import DonorReports from 'modules/reports/components/DonorReports';
import OwnerReports from 'modules/reports/components/OwnerReports';
import ProductReports from 'modules/reports/components/ProductReports';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IneligibleReports from '../IneligibleParticipantReports';

const ReportsTabs: FC = () => {
  const { t } = useTranslation('reports.ReportsTabs');

  const reportsCardTabs: ITabsItem[] = [
    {
      title: t('donors'),
      content: <DonorReports />,
      key: Routes.DonorsReports,
    },
    {
      title: t('donations'),
      content: <DonationReports />,
      key: Routes.DonationReports,
    },
    {
      title: t('products'),
      content: <ProductReports />,
      key: Routes.ProductsReports,
    },
    {
      title: t('owners'),
      content: <OwnerReports />,
      key: Routes.OwnersReports,
    },
    {
      title: t('ineligibles'),
      content: <IneligibleReports />,
      key: Routes.IneligiblesReports,
    },
  ];

  return <NavigationCardTabs items={reportsCardTabs} />;
};

export default ReportsTabs;
