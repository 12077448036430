import { Row, Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type PropTypes = {
  text?: string;
};

export const AdditionalTestsSection: FC<PropTypes> = ({ text }) => {
  const { t } = useTranslation('donor.RoutineTest.AdditionalTestsSection');

  return (
    <Row>
      <Col span={24} className={styles.title}>
        {t('title').toUpperCase()}
      </Col>
      <Col span={24} className={styles.textArea}>
        {text && text}
      </Col>
    </Row>
  );
};
