import { useState } from 'react';

type PropTypes<FieldNameType> = {
  hiddenFields?: FieldNameType[];
};

export const useHidingFields = <FieldNameType>(
  props?: PropTypes<FieldNameType>
) => {
  const [hiddenFields, setHiddenFields] = useState(props?.hiddenFields || []);

  const hideField = (...fieldNames: FieldNameType[]) => {
    const fieldsToHide = fieldNames.filter((fieldName) => {
      return !hiddenFields.includes(fieldName);
    });
    setHiddenFields((prevHiddenFields) => [
      ...prevHiddenFields,
      ...fieldsToHide,
    ]);
  };

  const showField = (...fieldNames: FieldNameType[]) => {
    const fieldsToDisplay = fieldNames.filter((fieldName) => {
      return hiddenFields.includes(fieldName);
    });
    const newFields = hiddenFields.filter((field) => {
      return !fieldsToDisplay.includes(field);
    });

    setHiddenFields(newFields);
  };

  return {
    hideField,
    showField,
    hiddenFields,
  };
};
