import { Col, Row, Switch } from 'antd';
import { InputNumber } from 'modules/common/components';
import { ComponentProps, FC, useMemo, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useGetVitalsParameterListQuery, VitalsParametersType } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof InputNumber>;

const RespirationInput: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const getVitalsParameterListQuery = useGetVitalsParameterListQuery();
  const isLoading = getVitalsParameterListQuery.loading;
  const vitalsParameters =
    getVitalsParameterListQuery.data?.getVitalsParameterList;
  const respirationVitalParameter = vitalsParameters?.find(
    (parameter) => parameter.type === VitalsParametersType.Respiration
  );
  const respirationCriticalValue =
    respirationVitalParameter?.criticalValues?.[0];
  const [checked, setChecked] = useState(false);
  const controller = useController(controlled);
  const isInputDisabled = isLoading || restProps.disabled || checked;
  const inputPlaceholder = checked
    ? respirationCriticalValue
    : restProps.placeholder;
  const defaultValue = useMemo(() => controller.field.value, []);
  const [value, setValue] = useState(controller.field.value);
  const switchChildren = (
    <div className={styles.criticalValue}>{respirationCriticalValue}</div>
  );

  const onCheck = (value: boolean) => {
    setValue(value ? undefined : defaultValue);
    setChecked(value);
    controller.field.onChange(value ? 500 : defaultValue);
  };

  return (
    <Row gutter={[10, 0]} align="middle">
      <Col span={6}>
        <Switch
          checkedChildren={switchChildren}
          unCheckedChildren={switchChildren}
          checked={checked}
          onChange={onCheck}
          loading={isLoading}
          disabled={isLoading || restProps.disabled}
          title={respirationCriticalValue}
        />
      </Col>
      <Col span={18}>
        <InputNumber
          {...controller.field}
          {...restProps}
          value={checked ? value : controller.field.value}
          disabled={isInputDisabled}
          placeholder={inputPlaceholder}
        />
      </Col>
    </Row>
  );
};

export default RespirationInput;
