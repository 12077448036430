import { IndividualResultsSection } from 'modules/donor/components/RoutineTest/components/IndividualResultsSection';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.RoutineTest.UrinalysisSection');

const preset = {
  title: t('title').toUpperCase(),
  items: [
    {
      label: t('itemTitle1'),
      details: (
        <>
          {t('itemSubTitle1')}
          <br />
          <span className={styles.redText}>
            {t('itemSubTitleAdditionalInfo1')}
          </span>
        </>
      ),
    },
    {
      label: t('itemTitle2'),
      details: t('itemSubTitle2'),
    },
  ],
};

export const UrinalysisSection: FC = () => (
  <IndividualResultsSection {...preset} />
);
