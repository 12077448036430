import { Gutter } from 'antd/lib/grid/row';
import { LabelSize } from 'modules/products/enums';
import { BloodType, AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('products.Label');

const { Large, Small } = LabelSize;

export const QR_SIZES: Partial<Record<LabelSize, number>> = {
  [Large]: 60,
  [Small]: 40,
};

export const LABEL_SIZE_STYLES: Partial<Record<LabelSize, string>> = {
  [Large]: styles.large,
};

export const BLOOD_TYPE_TRANSLATIONS: Partial<Record<BloodType, string>> = {
  [BloodType.Dea1Negative]: t('negative'),
  [BloodType.Dea1Positive]: t('positive'),
  [BloodType.Unknown]: t('unknown'),
};

export const LABEL_GUTTER: Partial<Record<LabelSize, [Gutter, Gutter]>> = {
  [Large]: [0, 9],
  [Small]: [0, 7],
};

export const DONOR_SPECIES: Partial<Record<AnimalSpecies, string>> = {
  [AnimalSpecies.Cat]: t('feline'),
  [AnimalSpecies.Dog]: t('canine'),
};
