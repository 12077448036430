import { DefaultOptionType } from 'antd/lib/select';
import { InviteUserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type ParaTypes = {
  omittedRoles?: InviteUserRole[];
};

const t = getTranslation('user.InvitationRolesSelect');

export const getOptions = ({ omittedRoles }: ParaTypes = {}) => {
  const userRoleOptions = Object.values(InviteUserRole).reduce(
    (result, currentRole) => {
      const isRoleOmitted = omittedRoles?.includes(currentRole);
      const localRole = `${currentRole[0].toLowerCase()}${currentRole.substring(
        1
      )}`;
      return isRoleOmitted
        ? result
        : [
            ...result,
            {
              label: t(localRole),
              value: currentRole,
            },
          ];
    },
    [] as DefaultOptionType[]
  );
  return userRoleOptions;
};
