import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, TableColumnType } from 'antd';
import { ControlledInput, FormItem } from 'modules/common/components';
import { Control, FormState } from 'react-hook-form';
import { LocationOutput, LocationType, RegionOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { ICreateRegionsFormSchema } from './schema';
import styles from './styles.module.scss';

const t = getTranslation('user.LocationsTable');

type FormErrorsType = FormState<ICreateRegionsFormSchema>['errors'];
type ParamType = {
  editableKey?: string;
  setEditableKey?: (
    editableKey?: string,
    locationName?: string,
    currentLocationType?: LocationType
  ) => void;
  onSaveChanges?: () => void;
  onDelete?: (locationId: string) => void;
  control: Control<ICreateRegionsFormSchema, any>;
  savingIsDisabled?: boolean;
  isAllowedToEdit?: boolean;
  isAllowedToDelete?: boolean;
  errors: FormErrorsType;
  isMobile: boolean;
};

export const getColumns = (
  params: ParamType
): TableColumnType<RegionOutput>[] => {
  const {
    control,
    setEditableKey,
    editableKey,
    onDelete,
    onSaveChanges,
    savingIsDisabled,
    isAllowedToDelete,
    isAllowedToEdit,
    errors,
  } = params;

  const isEditing = (locationId: string) => editableKey === locationId;
  const getErrorMessage = (errors: FormErrorsType) => errors.name?.message;

  return [
    {
      width: '70%%',
      title: t('name'),
      render: ({ id, name }: LocationOutput) => {
        const errorMessage = getErrorMessage(errors);
        const validateStatus = errorMessage && 'error';
        return !isEditing(id) ? (
          <span title={name} className={styles.locationName}>
            {name}
          </span>
        ) : (
          <FormItem
            extra={errorMessage}
            validateStatus={validateStatus}
            className={styles.formItem}
          >
            <ControlledInput
              size="small"
              controlled={{ control, name: 'name', defaultValue: name }}
            />
          </FormItem>
        );
      },
    },
    {
      width: '30%',
      render: ({ id, name, type }: LocationOutput) => {
        return isEditing(id) ? (
          <Row gutter={15}>
            <Col span={12}>
              <Popconfirm
                overlayClassName={styles.popConfirm}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                title={t('sureToSave')}
                onConfirm={onSaveChanges}
                disabled={savingIsDisabled}
              >
                <Button type="text" size="small" disabled={savingIsDisabled}>
                  <CheckOutlined />
                </Button>
              </Popconfirm>
            </Col>
            <Col span={12}>
              <Button
                onClick={() => setEditableKey?.(undefined)}
                type="text"
                size="small"
              >
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
        ) : (
          <Row align="middle">
            <Col span={12}>
              <Button
                onClick={() => setEditableKey?.(id, name, type)}
                type="link"
                size="small"
                disabled={!isAllowedToEdit}
              >
                <EditOutlined />
              </Button>
            </Col>
            <Col span={12}>
              <Popconfirm
                overlayClassName={styles.popConfirm}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                title={t('sureToDelete')}
                onConfirm={() => onDelete?.(id)}
                disabled={!isAllowedToDelete}
              >
                <Button danger type="link" disabled={!isAllowedToDelete}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];
};
