import { passwordSchema } from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('user.ResetPasswordForm');

type ResetPasswordSchemaType = {
  password: string;
  confirmPassword: string;
};

const resetPasswordSchema: yup.SchemaOf<ResetPasswordSchemaType> = yup.object({
  password: passwordSchema,
  confirmPassword: passwordSchema.oneOf(
    [yup.ref('password'), null],
    t('passwordMustMatch')
  ),
});

export { resetPasswordSchema };
export type { ResetPasswordSchemaType };
