import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Grid, message, Row, Spin, Table } from 'antd';
import config from 'config';
import { useEditLocationsModalContext } from 'modules/locations/contexts/EditLocationsModal';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  LocationType,
  PermissionsEnum,
  useDeleteLocationMutation,
  useGetLocationsQuery,
  useUpdateLocationMutation,
} from 'types.d';

import { getColumns } from './columns';
import {
  createLocationsFormSchema,
  ICreateLocationsFormSchema,
} from './schema';
import styles from './styles.module.scss';

import AddLocationsForm from '../CreateLocationsForm';

const LocationsTable: FC = () => {
  const editLocationsModal = useEditLocationsModalContext();

  const { t } = useTranslation('user.LocationsTable');
  const { xs } = Grid.useBreakpoint();
  const [isMobile, setMobile] = useState<boolean>(false);
  const pagination = usePagination({ urlPagination: false });
  const currentUser = useCurrentUser();
  const [locationName, setLocationName] = useState<string | undefined>();
  const [locationType, setLocationType] = useState<LocationType | undefined>();
  const [locationRegion, setLocationRegion] = useState<string | undefined>();
  const createNewLocationAllowed = currentUser.isCan(
    PermissionsEnum.CreteLocation
  );
  const deleteLocationAllowed = currentUser.isCan(
    PermissionsEnum.DeleteLocation
  );
  const updateLocationAllowed = currentUser.isCan(
    PermissionsEnum.UpdateLocation
  );

  useEffect(() => {
    xs !== undefined && setMobile(xs);
  }, [xs]);

  const [deleteLocation, deleteLocationMutation] = useDeleteLocationMutation({
    refetchQueries: ['getLocations'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      message.success(t('locationDeleted'));
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const [updateLocation, updateLocationMutation] = useUpdateLocationMutation({
    refetchQueries: ['getLocations'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      message.success(t('locationUpdated'));
      editLocationForm.reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const editLocationForm = useForm<ICreateLocationsFormSchema>({
    resolver: yupResolver(createLocationsFormSchema),
    mode: 'onChange',
    defaultValues: {
      id: undefined,
      name: undefined,
      type: undefined,
    },
  });
  const editableKey = editLocationForm.watch('id');
  const inputtedName = editLocationForm.watch('name');
  const newLocationType = editLocationForm.watch('type');
  const newLocationRegion = editLocationForm.watch('region');

  const setEditableKey = (
    locationId?: string,
    currentLocationName?: string,
    currentLocationType?: LocationType,
    currentLocationRegion?: string
  ) => {
    editLocationForm.reset();
    //It made in purpose, to be able set value of 'id' to undefined
    setLocationName(currentLocationName);
    setLocationType(currentLocationType);
    setLocationRegion(currentLocationRegion);
    editLocationForm.setValue('id', locationId as string, {
      shouldValidate: true,
    });
  };
  const locationsQuery = useGetLocationsQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        //TODO: add filter
      },
    },
    onCompleted: (result) => {
      pagination.setTotal(result.getLocations.meta.total);
    },
  });
  const updateLocationHandler = editLocationForm.handleSubmit(
    ({ region, ...formData }) => {
      updateLocation({
        variables: {
          input: {
            ...formData,
            regionId: region,
          },
        },
      });
    }
  );
  const deleteLocationHandler = (id: string) => {
    deleteLocation({ variables: { input: { id } } });
  };
  const locations = locationsQuery.data?.getLocations;
  const isLoading = [
    deleteLocationMutation,
    updateLocationMutation,
    locationsQuery,
  ].some(({ loading }) => loading);
  const isNewValues =
    locationName !== inputtedName ||
    locationType !== newLocationType ||
    locationRegion !== newLocationRegion;
  const savingIsDisabled =
    !editLocationForm.formState.isValid ||
    !editLocationForm.formState.isDirty ||
    !isNewValues;

  const locationTableColumns = useMemo(
    () =>
      getColumns({
        control: editLocationForm.control,
        setEditableKey,
        editableKey,
        onSaveChanges: updateLocationHandler,
        savingIsDisabled,
        onDelete: deleteLocationHandler,
        isAllowedToDelete: deleteLocationAllowed,
        isAllowedToEdit: updateLocationAllowed,
        errors: editLocationForm.formState.errors,
        isMobile,
      }),
    [
      editLocationForm.control,
      inputtedName,
      editableKey,
      editLocationForm.formState.isValid,
      editLocationForm.formState.isDirty,
      isMobile,
    ]
  );

  useEffect(() => {
    editLocationForm.reset();
    setEditableKey(undefined);
  }, [editLocationsModal?.modalConfig.visible]);

  return (
    <Row gutter={[0, 20]}>
      {createNewLocationAllowed && (
        <Col span={24}>
          <AddLocationsForm />
        </Col>
      )}
      <Col span={24} className={styles.tableWrapper}>
        <Spin spinning={isLoading}>
          <Table
            rowKey={({ id }) => id}
            columns={locationTableColumns}
            dataSource={locations?.data}
            pagination={pagination.config}
            size="small"
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default LocationsTable;
