import { EmptyCircle } from 'modules/donor/components/RoutineTest/components/EmptyCircle';
import { FilledCircle } from 'modules/donor/components/RoutineTest/components/FilledCircle';
import { IndividualResultsSection } from 'modules/donor/components/RoutineTest/components/IndividualResultsSection';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.RoutineTest.ChemistrySection');

const preset = {
  title: t('chemistry'),
  items: [
    {
      label: t('chem18'),
      details: t('chem18Subtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
      checked: true,
    },
    {
      label: t('chem25'),
      details: t('chem25Subtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
  ],
};

export const ChemistrySection: FC = () => (
  <IndividualResultsSection {...preset} />
);
