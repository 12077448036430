import { isPast, isYesterday } from 'date-fns';
import { AppointmentType } from 'modules/appointments/enums';
import { DonorStatus } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('appointments.CreateAppointmentsModal');

type SelectedGroup = { label?: string; value: string };

export type CreateAppointmentValidationSchema = {
  type: AppointmentType;
  title: string;
  timeslots: Date[];
  split?: boolean;
  donorId?: string | null;
  daycareDonorsIds?: string[];
  selectedGroup?: SelectedGroup;
  teamId?: string | null;
  location: string;
  userIds: string[];
  donorStatus?: DonorStatus;
  isPrivate?: boolean;
  notes?: string;
  dates: Date[];
};

const selectedGroup: yup.SchemaOf<SelectedGroup> = yup.object({
  label: yup.string(),
  value: yup.string().required(),
});

export const createAppointmentValidationSchema: yup.SchemaOf<CreateAppointmentValidationSchema> =
  yup.object({
    type: yup
      .mixed<AppointmentType>()
      .oneOf(Object.values(AppointmentType))
      .required(t('typeRequired')),
    title: yup
      .string()
      .required(t('titleRequired'))
      .max(100, t('maxLengthIs', { max: 100 })),
    selectedGroup: selectedGroup
      .nullable()
      .notRequired()
      .default(undefined)
      .when('donorId', {
        is: (donorId: string) => donorId,
        then: () =>
          selectedGroup
            .notRequired()
            .default(undefined)
            .oneOf([undefined, null]),
      })
      .when('type', {
        is: AppointmentType.Grouped,
        then: () => selectedGroup.nullable().required(t('groupRequired')),
      }),
    teamId: yup.string().nullable(),
    timeslots: yup
      .array(
        yup
          .date()
          .required()
          .test({
            test: (value) => {
              return value ? value?.getHours() >= 8 : false;
            },
            message: t('wrongHours'),
          })
          .test({
            test: (value, { parent }) => {
              const [firstTime, secondTime] = parent;
              const isSecondSlotValid = secondTime > firstTime;
              return value ? !isPast(value) && isSecondSlotValid : true;
            },
            message: t('selectFutureDate'),
          }) as yup.DateSchema<Date>
      )
      .min(2)
      .max(2)
      .transform((val) => (val === null ? undefined : val))
      .required(t('rangeRequired')),
    split: yup.boolean(),
    donorId: yup.string().nullable().default(null),
    daycareDonorsIds: yup.array().optional(),
    donorStatus: yup
      .mixed<DonorStatus>()
      .notOneOf([DonorStatus.Archived], t('archivedDonor'))
      .notRequired(),
    location: yup
      .string()
      .required(t('locationRequired'))
      .max(100, t('maxLengthIs', { max: 100 })),
    userIds: yup
      .array(yup.string().required())
      .min(1, t('memberRequired'))
      .max(3, t('maxMembers', { max: 3 }))
      .required(t('memberRequired')),
    dates: yup
      .array()
      .of(
        yup
          .date()
          .required()
          .test({
            test: (value) => (value ? !isYesterday(value) : true),
            message: t('dateInPast'),
          })
      )
      .min(1, t('selectAtLeastOneDate'))
      .required(t('selectAtLeastOneDate')),
    notes: yup.string().max(255, t('maxLengthIs', { max: 255 })),
    isPrivate: yup.boolean().optional().default(false),
  });
