import { Row, Col } from 'antd';
import { RadioButtonWithLetterInside } from 'modules/donor/components/RoutineTest/components/RadioButtonWithLetterInside';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.RoutineTest.LaboratoryUseOnlyTable');

type IPreset = {
  text: string;
  letters: string[];
  rightAdOn?: string;
};

const preset = [
  [
    {
      text: t('sst'),
      letters: [undefined, 'M'],
    },
    {
      text: t('btt'),
      letters: ['M', undefined],
    },
    {
      text: t('urine'),
      letters: ['T', 'C', 'O'],
    },
    {
      text: t('histo'),
      letters: ['J', 'T', 'F'],
    },
  ],
  [
    {
      text: t('rtt'),
      letters: [undefined, 'M'],
    },
    {
      text: t('gtt'),
      letters: ['M', undefined],
    },
    {
      text: t('swab'),
      letters: [undefined],
    },
    {
      text: t('cyto'),
      letters: ['R', 'L', 'C'],
    },
  ],
  [
    {
      text: t('ltt'),
      letters: [undefined, 'M'],
    },
    {
      text: t('ctt'),
      letters: [undefined],
    },
    {
      text: t('media'),
      letters: ['T', 'P'],
    },
    {
      text: t('other'),
    },
  ],
  [
    {
      text: t('grnTT'),
      letters: [undefined, 'M'],
    },
    {
      text: t('ptt'),
      letters: [undefined],
    },
    {
      text: t('other'),
    },
    {
      text: t('slide') + ' #',
    },
  ],
  [
    {
      text: t('slide') + ' #',
    },
    {
      text: t('other'),
    },
    {
      text: t('fecal'),
      letters: ['T', 'B', 'O'],
    },
    {
      text: t('onCells'),
      letters: [undefined],
    },
  ],
  [
    {
      text: t('crit') + ' #',
    },
    {
      text: t('frozen'),
    },
    {
      text: t('history'),
      rightAdOn: '#Pg',
    },
    {
      text: t('totalPg') + ' #',
    },
  ],
] as IPreset[][];

export const LaboratoryUseOnlyTable = () => {
  return (
    <div className={styles.laboratoryUseTable}>
      <Col className={styles.title}>LABORATORY USE ONLY</Col>
      <table>
        <tbody>
          {preset.map((trData, trIndex) => {
            return (
              <tr key={trIndex}>
                {trData.map((tdData, tdIndex) => {
                  return (
                    <td key={tdIndex}>
                      <Row align="middle">
                        <Col span={10}>{tdData.text}</Col>
                        <Col span={14}>
                          {tdData.letters ? (
                            <Row>
                              {tdData.letters.map((letter, index) => (
                                <Col flex={1} key={index}>
                                  <RadioButtonWithLetterInside
                                    letter={letter}
                                  />
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <Row>
                              <Col span={tdData.rightAdOn ? 16 : 22}>
                                <input className={styles.input} readOnly />
                              </Col>
                              <Col>{tdData.rightAdOn && tdData.rightAdOn}</Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
