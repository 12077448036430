import { DonorListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.GroupDonorCardListSorter');
export const options = [
  {
    value: DonorListSort.Name,
    label: t('petName'),
  },
  {
    value: DonorListSort.Team,
    label: t('teams'),
  },
];
