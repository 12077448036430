import { Col } from 'antd';
import GeneralLabworkTable from 'modules/donor/components/GeneralLabworkTable';
import LabworkAttachmentsModal from 'modules/donor/components/LabworkAttachmentsModal';
import { useLabworkAttachmentsModalContext } from 'modules/donor/contexts/LabworkAttachmentsModal';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { useTranslation } from 'react-i18next';

const LabworkPage = () => {
  const { t } = useTranslation('donor.LabworkPage');
  const labworkAttachmentsModal = useLabworkAttachmentsModalContext();

  return (
    <>
      <ProfileSideMenuWrapper title={t('title')}>
        <GeneralLabworkTable />
      </ProfileSideMenuWrapper>
      <Col span={0}>
        {labworkAttachmentsModal?.modalConfig && (
          <LabworkAttachmentsModal {...labworkAttachmentsModal?.modalConfig} />
        )}
      </Col>
    </>
  );
};

export default LabworkPage;
