import { Col, Row, Typography } from 'antd';
import { useAppointmentsHighlightContext } from 'modules/appointments/contexts/AppointmentsHighlight';
import { FC } from 'react';
import { AppointmentUser } from 'types.d';

import styles from './styles.module.scss';

const { Text } = Typography;

type PropTypes = {
  members: AppointmentUser[];
};

const AppointmentMembers: FC<PropTypes> = ({ members }) => {
  const appointmentsColors = useAppointmentsHighlightContext();

  return (
    <Row gutter={[0, 5]}>
      {members.map((user) => {
        const name = `${user.firstName} ${user.lastName}`;
        const backgroundColor = appointmentsColors?.colors[user.id];
        return (
          <Col
            span={24}
            style={{ backgroundColor }}
            className={styles.appointmentMember}
          >
            <Text ellipsis={{ tooltip: name }}>{name}</Text>
          </Col>
        );
      })}
    </Row>
  );
};

export default AppointmentMembers;
