import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import ControlledDatePicker from 'modules/common/components/ControlledDatePicker';
import { FormFieldWithComponent } from 'modules/common/types';
import { LocationSelect } from 'modules/donor/components';
import { getTranslation } from 'utils/getTranslation';

import { ContactFields, PersonalInfoFields } from './enum';

import ContactPreferences from '../ContactPreferences';

const t = getTranslation('owner.EditProfileForm');

type ParamTypes = {
  disabled?: boolean;
};

export const getContactFields = ({
  disabled,
}: ParamTypes): FormFieldWithComponent<
  ContactFields,
  { defaultValue?: any; name: string }
>[] => {
  return [
    {
      label: t('firstName'),
      name: ContactFields.firstName,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterFirstName')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('lastName'),
      name: ContactFields.lastName,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterLastName')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('streetAddress'),
      name: ContactFields.streetAddress,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterStreetAddress')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('city'),
      name: ContactFields.city,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterCity')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('state'),
      name: ContactFields.state,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterState')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('zipCode'),
      name: ContactFields.zipCode,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterZipCode')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('email'),
      name: ContactFields.email,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterEmail')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('phone'),
      name: ContactFields.phoneNumber,
      Component: (props) => (
        <ControlledPhoneInput
          placeholder={t('enterPhone')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
  ];
};

export const getPersonalInfoFields = ({
  disabled,
}: ParamTypes): FormFieldWithComponent<
  PersonalInfoFields,
  { defaultValue?: any; name: string }
>[] => {
  return [
    {
      label: t('firstName'),
      name: PersonalInfoFields.firstName,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterFirstName')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('lastName'),
      name: PersonalInfoFields.lastName,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterLastName')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('streetAddress'),
      name: PersonalInfoFields.streetAddress,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterStreetAddress')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('city'),
      name: PersonalInfoFields.city,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterCity')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('state'),
      name: PersonalInfoFields.state,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterState')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('zipCode'),
      name: PersonalInfoFields.zipCode,
      Component: (props) => (
        <ControlledInput
          placeholder={t('enterZipCode')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('phone'),
      name: PersonalInfoFields.phoneNumber,
      Component: (props) => (
        <ControlledPhoneInput
          placeholder={t('enterPhone')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('locationPreferences'),
      name: PersonalInfoFields.locationPreferences,
      Component: (props) => (
        <LocationSelect
          placeholder={t('enterLocationPreferences')}
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
    {
      label: t('birthday'),
      name: PersonalInfoFields.birthday,
      Component: (props) => (
        <ControlledDatePicker
          disabled={disabled}
          placeholder={t('selectBirthdayDate')}
          controlled={{ ...props }}
        />
      ),
    },
    {
      label: t('contactPreferences'),
      name: PersonalInfoFields.contactPreferences,
      Component: (props) => (
        <ContactPreferences
          disabled={disabled}
          controlled={{
            ...props,
          }}
        />
      ),
    },
  ];
};
