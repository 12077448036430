import { gql } from '@apollo/client';
import {
  DONATION_FRAGMENT,
  ADDITIONAL_DONATION_INFO_FRAGMENT,
} from 'modules/donations/graphql/fragments';

export const CREATE_DONATION_MUTATION = gql`
  mutation createDonation($input: CreateDonationInput!) {
    createDonation(input: $input) {
      ...Donation
    }
  }
  ${DONATION_FRAGMENT}
`;

export const UPDATE_DONATION_MUTATION = gql`
  mutation updateDonation($input: UpdateDonationInput!) {
    updateDonation(input: $input) {
      ...Donation
    }
  }
  ${DONATION_FRAGMENT}
`;

export const UPDATE_DONATION_COMMENT_MUTATION = gql`
  mutation updateDonationComment($input: UpdateDonationCommentInput!) {
    updateDonationComment(input: $input) {
      ...Donation
    }
  }
  ${DONATION_FRAGMENT}
`;

export const UPDATE_DONATION_DONOR_MUTATION = gql`
  mutation updateDonationDonor($input: UpdateDonationDonorInput!) {
    updateDonationDonor(input: $input) {
      ...Donation
    }
  }
  ${DONATION_FRAGMENT}
`;

export const CREATE_ADDITIONAL_DONATION_INFO_MUTATION = gql`
  mutation createAdditionalDonationInfo(
    $input: CreateAdditionalDonationInfoInput!
  ) {
    createAdditionalDonationInfo(input: $input) {
      ...AdditionalDonationInfo
    }
  }
  ${ADDITIONAL_DONATION_INFO_FRAGMENT}
`;

export const UPDATE_ADDITIONAL_DONATION_INFO_MUTATION = gql`
  mutation updateAdditionalDonationInfo(
    $input: UpdateAdditionalDonationInfoInput!
  ) {
    updateAdditionalDonationInfo(input: $input) {
      ...AdditionalDonationInfo
    }
  }
  ${ADDITIONAL_DONATION_INFO_FRAGMENT}
`;

export const CREATE_DONATION_WITH_ADDITIONAL_INFO_MUTATION = gql`
  mutation createDonationWithAdditionalInfo(
    $input: CreateDonationWithAdditionalInfoInput!
  ) {
    createDonationWithAdditionalInfo(input: $input) {
      ...Donation
    }
  }
  ${DONATION_FRAGMENT}
`;

export const CREATE_DONATIONS_WITH_ADDITIONAL_INFO_MUTATION = gql`
  mutation createDonationsWithAdditionalInfo(
    $input: CreateDonationsWithAdditionalInfoInput!
  ) {
    createDonationsWithAdditionalInfo(input: $input)
  }
`;

export const DELETE_DONATION_MUTATION = gql`
  mutation deleteDonation($donationId: String!) {
    deleteDonation(donationId: $donationId)
  }
`;
