import { Row, Col } from 'antd';
import { DefaultClinicParams } from 'config/default-clinic-params';
import { RoutineTestFormSchemaType } from 'modules/donor/components/RoutineTestForm/schema';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import CheckboxGroup from '../../components/CheckboxGroup';
import FormInput from '../../components/Input';

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};

export default function ContactForm({ data }: PropTypes) {
  const { t } = useTranslation('donor.FormsToPrint');
  return (
    <div>
      <h3 className={styles.title}>{t('GeneralSubmissionFormTitle')}</h3>
      <div className={styles.contactFormWrapper}>
        <Row className={styles.contactFormTopSection}>
          <Col span={12} className={styles.contactFormTopSectionRight}>
            <FormInput label={t('enterCornelAHDC')} value={data.cornelAHDC} />
          </Col>
          <Col span={12}>
            <FormInput label={t('inputInternalCaseNumber')} />
          </Col>
        </Row>
        <Row className={styles.contactFormMidSection}>
          <Col span={12} className={styles.contactFormMidSectionLeft}>
            <FormInput
              label={t('submittingVeterinarian')}
              classNames={styles.boldLabel}
              value={data.veterinarian}
            />
            <FormInput label={t('clinicName')} value={data.clinicName} />
            <FormInput label={t('address')} value={data.clinicAddress} />
            <FormInput label={t('cityStateZip')} value={data.CityStateZip} />
            <Row>
              <Col span={14}>
                <FormInput label={t('phoneNumber')} value={data.telephone} />
              </Col>
              <Col span={10}>
                <FormInput label={t('faxNumber')} value="(         )" />
              </Col>
            </Row>
            <FormInput label={t('emailAddress')} value={data.email} />
            <FormInput label={t('submittingVetSignature')} />
          </Col>
          <Col span={12} className={styles.contactFormMidSectionRight}>
            <FormInput label={t('owner')} classNames={styles.boldLabel} />
            <FormInput label={t('address')} />
            <FormInput label={t('cityStateZip')} />
            <FormInput label={t('phoneNumber')} value="(         )" />
            <Row>
              <Col span={14}>
                <FormInput label={t('country')} />
              </Col>
              <Col span={10}>
                <FormInput label={t('town')} />
              </Col>
              <FormInput label={t('NYSPremisesID')} />
            </Row>
          </Col>
        </Row>
        <Row className={styles.contactFormBottomSection} align="middle">
          <Col span={9} className={styles.checkboxes}>
            <CheckboxGroup
              options={[t('Regulatory'), t('export')]}
              label={t('checkAppropriate')}
              labelPlacement="left"
            />
          </Col>
          <Col span={15} className={styles.contactFormBottomSectionRight}>
            <Row>
              <Col span={14}>
                <Row justify="end">
                  <FormInput label={t('destinationCountry')} />
                </Row>
              </Col>
              <Col span={10}>
                <FormInput label={t('shipperExporter')} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
