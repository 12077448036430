import {
  ControlledInputNumber,
  ControlledSelect,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { UpdateVitalsParameterFormFields } from 'modules/donor/components/UpdateVitalsParametersModal/enum';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.UpdateVitalsParametersModal');

type Props = {
  enabled: boolean;
};

export const getVitalsParametersFormFields = ({
  enabled,
}: Props): FormFieldWithComponent<UpdateVitalsParameterFormFields>[] => [
  {
    name: UpdateVitalsParameterFormFields.temperatureToCheckMin,
    label: t(UpdateVitalsParameterFormFields.temperatureToCheckMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: `${UpdateVitalsParameterFormFields.temperatureToCheckMin}`,
        }}
        placeholder={t('enterTemperatureToCheckMin')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.temperatureToCheckMax,
    label: t(UpdateVitalsParameterFormFields.temperatureToCheckMax),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: `${UpdateVitalsParameterFormFields.temperatureToCheckMax}`,
        }}
        placeholder={t('enterTemperatureToCheckMax')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.temperatureToStopMin,
    label: t(UpdateVitalsParameterFormFields.temperatureToStopMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: `${UpdateVitalsParameterFormFields.temperatureToStopMin}`,
        }}
        placeholder={t('enterTemperatureToStopMin')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.temperatureToStopMax,
    label: t(UpdateVitalsParameterFormFields.temperatureToStopMax),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: `${UpdateVitalsParameterFormFields.temperatureToStopMax}`,
        }}
        placeholder={t('enterTemperatureToStopMax')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.pulseMin,
    label: t(UpdateVitalsParameterFormFields.pulseMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.pulseMin,
        }}
        placeholder={t('enterPulseMin')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.pulseMax,
    label: t(UpdateVitalsParameterFormFields.pulseMax),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.pulseMax,
        }}
        placeholder={t('enterPulseMax')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.respirationMin,
    label: t(UpdateVitalsParameterFormFields.respirationMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.respirationMin,
        }}
        placeholder={t('enterRespirationMin')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.respirationCriticalValues,
    label: t(UpdateVitalsParameterFormFields.respirationCriticalValues),
    Component: ({ control }) => (
      <ControlledSelect
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.respirationCriticalValues,
        }}
        placeholder={t('enterRespirationCriticalValues')}
        mode="tags"
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.packedCellVolumeMin,
    label: t(UpdateVitalsParameterFormFields.packedCellVolumeMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.packedCellVolumeMin,
        }}
        placeholder={t('enterPackedCellVolumeMin')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.packedCellVolumeMax,
    label: t(UpdateVitalsParameterFormFields.packedCellVolumeMax),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.packedCellVolumeMax,
        }}
        placeholder={t('enterPackedCellVolumeMax')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.totalSolidsMin,
    label: t(UpdateVitalsParameterFormFields.totalSolidsMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.totalSolidsMin,
        }}
        placeholder={t('enterTotalSolidsMin')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.totalSolidsMax,
    label: t(UpdateVitalsParameterFormFields.totalSolidsMax),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.totalSolidsMax,
        }}
        placeholder={t('enterTotalSolidsMax')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.mucousMembraneCriticalValues,
    label: t(UpdateVitalsParameterFormFields.mucousMembraneCriticalValues),
    Component: ({ control }) => (
      <ControlledSelect
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.mucousMembraneCriticalValues,
        }}
        placeholder={t('enterMucousMembraneCriticalValues')}
        mode="tags"
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.capillaryRefillTimeMin,
    label: t(UpdateVitalsParameterFormFields.capillaryRefillTimeMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.capillaryRefillTimeMin,
        }}
        placeholder={t('enterCapillaryRefillTimeMin')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.capillaryRefillTimeMax,
    label: t(UpdateVitalsParameterFormFields.capillaryRefillTimeMax),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.capillaryRefillTimeMax,
        }}
        placeholder={t('enterCapillaryRefillTimeMax')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.weightPercentageDifference,
    label: t(UpdateVitalsParameterFormFields.weightPercentageDifference),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.weightPercentageDifference,
        }}
        placeholder={t('enterWeightPercentageDifference')}
        disabled={!enabled}
      />
    ),
  },
  {
    name: UpdateVitalsParameterFormFields.weightMin,
    label: t(UpdateVitalsParameterFormFields.weightMin),
    Component: ({ control }) => (
      <ControlledInputNumber
        controlled={{
          control,
          name: UpdateVitalsParameterFormFields.weightMin,
        }}
        placeholder={t('enterWeightMin')}
        disabled={!enabled}
      />
    ),
  },
];
