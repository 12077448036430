import { ApolloQueryResult } from '@apollo/client';
import config from 'config';
import { GET_SPECIFIC_DONOR_DONATION_LIST_QUERY } from 'modules/donations/graphql/queries';
import { apolloClient } from 'providers/graphql';
import {
  GetSpecificDonorDonationsListQuery,
  GetSpecificDonorDonationsListQueryVariables,
} from 'types';

export const getDonorDonations = (
  donorId: string
): Promise<ApolloQueryResult<GetSpecificDonorDonationsListQuery>> => {
  return apolloClient.query<
    GetSpecificDonorDonationsListQuery,
    GetSpecificDonorDonationsListQueryVariables
  >({
    query: GET_SPECIFIC_DONOR_DONATION_LIST_QUERY,
    variables: {
      input: {
        take: config.PAGE_SIZE, //TODO: fix the issue with receiving donor donations, for offline mode it takes only 10
        skip: 0,
        sort: [],
        filter: {
          donorId,
        },
      },
    },
  });
};
