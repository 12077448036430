import { Col, Row, Collapse } from 'antd';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { TFunction } from 'react-i18next';

import styles from './styles.module.scss';

const { Panel } = Collapse;

type PropTypes = {
  translation: TFunction;
} & FallbackProps;

const FallbackError: FC<PropTypes> = ({
  translation: t,
  error,
  resetErrorBoundary,
}) => {
  return (
    <Row className={styles.container} justify="center">
      <Col span={24} xl={12} lg={18}>
        <Row gutter={[10, 20]}>
          <Col className={styles.title} span={24}>
            {t('somethingWentWrong')}
          </Col>
          <Col span={24} className={styles.errorMessage}>
            {error.message}
          </Col>
          <Col span={24}>
            <Collapse className={styles.errorStack} ghost>
              <Panel key={0} header={t('more')}>
                <pre>{error.stack}</pre>
              </Panel>
            </Collapse>
          </Col>
          <Col span={24}>
            <Button onClick={resetErrorBoundary}>{t('tryAgain')}</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FallbackError;
