import { Row, Col } from 'antd';
import { AdditionalTestsSection } from 'modules/donor/components/RoutineTest/components/AdditionalTestsSection';
import { BundleSection } from 'modules/donor/components/RoutineTest/components/BundleSection';
import { ChemistrySection } from 'modules/donor/components/RoutineTest/components/ChemistrySection';
import { ClinicSection } from 'modules/donor/components/RoutineTest/components/ClinicSection';
import { ContactsSection } from 'modules/donor/components/RoutineTest/components/ContactsSection';
import { CopyInfoSection } from 'modules/donor/components/RoutineTest/components/CopyInfoSection';
import { CopyrightSection } from 'modules/donor/components/RoutineTest/components/CopyrightSection';
import { DetailsSection } from 'modules/donor/components/RoutineTest/components/DetailsSection';
import { EndocrinologySection } from 'modules/donor/components/RoutineTest/components/EndocrinologySection';
import { FecalSection } from 'modules/donor/components/RoutineTest/components/FecalSection';
import { Head } from 'modules/donor/components/RoutineTest/components/Head';
import { HematologySection } from 'modules/donor/components/RoutineTest/components/HematologySection';
import { IndividualTestsSection } from 'modules/donor/components/RoutineTest/components/IndividualTestsSection';
import { LaboratoryUseOnlyTable } from 'modules/donor/components/RoutineTest/components/LaboratoryUseOnlyTable';
import { QuestionsSection } from 'modules/donor/components/RoutineTest/components/QuestionsSection';
import { SerologySection } from 'modules/donor/components/RoutineTest/components/SerologySection';
import { UrinalysisSection } from 'modules/donor/components/RoutineTest/components/UrinalysisSection';
import { RoutineTestFormSchemaType } from 'modules/donor/components/RoutineTestForm/schema';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  data: RoutineTestFormSchemaType;
};

const RoutineTest = forwardRef<any, PropTypes>(({ data }, ref) => {
  return (
    <div ref={ref} className={styles.page}>
      <Head date={data.collectionDate} />
      <Row className={styles.contactsRow}>
        <Row className={styles.content}>
          <Col span={12}>
            <Col span={23}>
              <ContactsSection data={data} />
            </Col>
          </Col>
          <Col span={12}>
            <Col offset={1}>
              <ClinicSection />
              <Row>
                <Col span={24}>
                  <LaboratoryUseOnlyTable />
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Row>
      <Row className={styles.content}>
        <BundleSection />
      </Row>
      <Row className={styles.resultsSection}>
        <Row className={styles.content}>
          <Col span={12}>
            <Col span={23}>
              <ChemistrySection />
            </Col>
            <Col span={23}>
              <HematologySection />
            </Col>
            <Col span={23}>
              <EndocrinologySection />
            </Col>
            <Col span={23}>
              <UrinalysisSection />
            </Col>
          </Col>
          <Col span={12}>
            <Col offset={1}>
              <FecalSection />
            </Col>
            <Col offset={1}>
              <SerologySection />
            </Col>
            <Col offset={1}>
              <IndividualTestsSection />
            </Col>
            <Col offset={1}>
              <AdditionalTestsSection text={data.notes} />
            </Col>
          </Col>
        </Row>
      </Row>
      <Row className={styles.content}>
        <DetailsSection />
      </Row>
      <Row className={styles.content}>
        <QuestionsSection />
      </Row>
      <Row className={styles.content}>
        <CopyInfoSection />
      </Row>
      <Row className={styles.content}>
        <CopyrightSection />
      </Row>
    </div>
  );
});

export default RoutineTest;
