import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal, message, Popconfirm } from 'antd';
import { FormItem, Spin, Button } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import {
  convertVitalsParametersList,
  reconvertToVitalsParametersList,
} from 'modules/donor/components/UpdateVitalsParametersModal/utils';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useMemo } from 'react';
import { useForm, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useUpdateVitalsParametersListMutation,
  useGetVitalsParameterListQuery,
  VitalsParameterOutput,
  PermissionsEnum,
} from 'types.d';

import { getVitalsParametersFormFields } from './fields';
import {
  updateVitalsParametersSchema,
  UpdateVitalsParametersSchemaType,
} from './schema';

const UpdateVitalsParametersModal: FC<BaseModalProps> = ({ visible, hide }) => {
  const { t } = useTranslation('donor.UpdateVitalsParametersModal');
  const currentUser = useCurrentUser();
  const canUpdateVitalsChartParameters = currentUser.isCan(
    PermissionsEnum.UpdateVitalsChartParameters
  );

  const { control, handleSubmit, formState, reset } =
    useForm<UpdateVitalsParametersSchemaType>({
      resolver: yupResolver(updateVitalsParametersSchema),
      mode: 'onChange',
    });

  const { data, loading: gettingVitalsParameters } =
    useGetVitalsParameterListQuery();

  const vitalsParametersFormFields = useMemo(() => {
    return getVitalsParametersFormFields({
      enabled: canUpdateVitalsChartParameters,
    });
  }, [canUpdateVitalsChartParameters]);

  const [
    updateVitalsParametersList,
    { loading: updateVitalsParametersLoading },
  ] = useUpdateVitalsParametersListMutation({
    refetchQueries: ['getVitalsParameterList'],
    onCompleted: () => {
      message.success(t('vitalsParametersUpdated'));
      reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const submitForm = handleSubmit((formData) => {
    updateVitalsParametersList({
      variables: {
        input: {
          vitalsParameters: reconvertToVitalsParametersList(
            formData,
            data?.getVitalsParameterList as VitalsParameterOutput[]
          ),
        },
      },
    });
  });

  const okHandler = async () => {
    await submitForm();
    reset();
    hide();
  };

  const cancelHandler = () => {
    hide();
    reset();
  };

  useEffect(() => {
    data?.getVitalsParameterList &&
      reset(
        convertVitalsParametersList(
          data?.getVitalsParameterList as VitalsParameterOutput[]
        )
      );
  }, [data]);
  const isLoading = gettingVitalsParameters || updateVitalsParametersLoading;
  const isSaveButtonDisabled =
    !formState.isValid || isLoading || !formState.isDirty;

  return (
    <Spin spinning={isLoading}>
      <Modal
        key={t('updateVitalsParameters')}
        title={t('updateVitalsParameters')}
        visible={visible}
        onCancel={cancelHandler}
        footer={
          canUpdateVitalsChartParameters ? (
            <>
              <Button onClick={cancelHandler}> {t('cancel')}</Button>
              <Popconfirm
                disabled={isSaveButtonDisabled}
                title={t('areYouSureSaveEdits')}
                onConfirm={okHandler}
              >
                <Button disabled={isSaveButtonDisabled} type="primary">
                  {t('save')}
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Button onClick={cancelHandler}> {t('close')}</Button>
          )
        }
      >
        <Form>
          {vitalsParametersFormFields.map(({ Component, label, name }) => {
            const error = formState.errors[name] as any as
              | FieldError
              | undefined;

            return (
              <FormItem
                key={name}
                label={label}
                extra={error?.message}
                validateStatus={error?.message && 'error'}
              >
                <Component control={control} />
              </FormItem>
            );
          })}
        </Form>
      </Modal>
    </Spin>
  );
};

export default UpdateVitalsParametersModal;
