import { useState, useEffect } from 'react';
import { getOnlineStatus } from 'utils/getOnlineStatus';

function useOnlineStatus() {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = async () => {
    const onlineStatus = await getOnlineStatus();
    setNetwork(onlineStatus && window.navigator.onLine);
  };

  useEffect(() => {
    updateNetwork();
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return isOnline;
}

export default useOnlineStatus;
