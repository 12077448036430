import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { FC, useMemo } from 'react';
import { DonationOutput } from 'types';

import {
  MobileExaminationSectionView,
  DesktopExaminationSectionView,
} from './components';
import { getExaminationFields } from './fields';

type Props = {
  fieldNamePrefix: string;
  enabled: boolean;
  previousDonation?: DonationOutput;
  showLinkToPreviousDonation?: boolean;
  sectionNamePrefix?: string;
};

const ExaminationSection: FC<Props> = ({
  fieldNamePrefix,
  enabled,
  previousDonation,
  showLinkToPreviousDonation,
  sectionNamePrefix,
}) => {
  const breakpoint = useBreakpoint();

  const fields = useMemo(
    () =>
      getExaminationFields({
        enabled,
      }),
    [enabled]
  );

  return (
    <>
      {breakpoint.xl && (
        <DesktopExaminationSectionView
          fieldNamePrefix={fieldNamePrefix}
          fields={fields}
          formEnabled={enabled}
          showLinkToPreviousDonation={showLinkToPreviousDonation}
          previousDonation={previousDonation}
          sectionNamePrefix={sectionNamePrefix}
        />
      )}
      {!breakpoint.xl && (
        <MobileExaminationSectionView
          fields={fields}
          formEnabled={enabled}
          showLinkToPreviousDonation={showLinkToPreviousDonation}
          previousDonation={previousDonation}
          fieldNamePrefix={fieldNamePrefix}
          sectionNamePrefix={sectionNamePrefix}
        />
      )}
    </>
  );
};
export default ExaminationSection;
