import { ApolloQueryResult } from '@apollo/client';
import { GET_DONORS_BY_IDS_QUERY } from 'modules/donor/graphql/queries';
import { apolloClient } from 'providers/graphql';
import {
  GetDonorsListByIdsQuery,
  GetDonorsListByIdsQueryVariables,
} from 'types';

export const getDonorsByIds = async (
  ids: string[]
): Promise<ApolloQueryResult<GetDonorsListByIdsQuery>> => {
  return apolloClient.query<
    GetDonorsListByIdsQuery,
    GetDonorsListByIdsQueryVariables
  >({
    query: GET_DONORS_BY_IDS_QUERY,
    variables: {
      input: {
        ids,
      },
    },
  });
};
