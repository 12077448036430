import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form, message } from 'antd';
import { Alert, Button, FormItem, Spin } from 'modules/common/components';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useInviteUserMutation } from 'types.d';

import { InviteFields } from './enum';
import { fields } from './fields';
import { InviteSchema, inviteSchema } from './schema';
import styles from './styles.module.scss';

const InviteForm: FC = () => {
  const { t } = useTranslation('admin.InvitePage');
  const navigate = useNavigate();
  const defaultFields = Object.values(InviteFields).reduce(
    (fields, fieldName) => {
      return { ...fields, [fieldName]: '' };
    },
    {} as InviteSchema
  );
  const { control, handleSubmit, formState, reset } = useForm<InviteSchema>({
    resolver: yupResolver(inviteSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultFields,
    },
  });
  const [errorMessage, setErrorMessage] = useState<string>();

  const [inviteUser, inviteUserMutation] = useInviteUserMutation({
    onError: ({ message: m }) => {
      setErrorMessage(m);
    },
    onCompleted: (result) => {
      if (result?.inviteUser) {
        message.success(t('success'));
        reset();
        removeErrorMessage();
      }
    },
  });

  const removeErrorMessage = () => setErrorMessage(undefined);

  const inviteHandler = handleSubmit((data) => {
    inviteUser({
      variables: {
        input: {
          ...data,
        },
      },
    });
  });

  const closeHandler = () => {
    navigate(-1);
  };

  return (
    <Spin spinning={inviteUserMutation.loading}>
      {errorMessage && (
        <Alert
          message={errorMessage}
          type="error"
          closable
          afterClose={removeErrorMessage}
        />
      )}
      <Form>
        {fields.map(({ label, name, Component }) => {
          const error = formState.errors[name]?.message;
          return (
            <FormItem
              key={name}
              label={label}
              extra={error}
              validateStatus={error && 'error'}
            >
              <Component name={name} control={control} />
            </FormItem>
          );
        })}
        <div className={styles.buttonsContainer}>
          <Button onClick={closeHandler}>{t('close')}</Button>

          <Button
            disabled={!formState.isValid}
            onClick={inviteHandler}
            type="primary"
          >
            {t('invite')}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default InviteForm;
