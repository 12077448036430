import { Col, Typography } from 'antd';
import config from 'config';
import { format, addDays } from 'date-fns';
import { FormItem, Input } from 'modules/common/components';
import { useHidingFields } from 'modules/common/hooks';
import styles from 'modules/donor/components/EditDonorForm/styles.module.scss';
import { downloadFileHandler } from 'modules/donor/utils/download-file-handler';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  DonorType,
  useGetDonorLivesSavedLazyQuery,
  DonorOutput,
  useGetDonorQuery,
  PermissionsEnum,
} from 'types.d';

import { getFields, OptionalFormSectionField } from './fields';

type PropTypes = {
  fieldNamePrefix: string;
  disabled?: boolean;
  donor?: DonorOutput;
};

const OptionalFormSection: FC<PropTypes> = ({
  fieldNamePrefix,
  disabled,
  donor,
}) => {
  const pageParams = useParams();
  const shortDonorId = pageParams.id || '';
  const { t } = useTranslation('donor.EditDonorForm.OptionalSection');
  const { showField, hideField, hiddenFields } =
    useHidingFields<OptionalFormSectionField>({
      hiddenFields: [OptionalFormSectionField.Team],
    });
  const ownerId = donor?.user?.id;
  const {
    control,
    formState,
    watch,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useFormContext();
  const optionalFormSectionFields = useMemo(
    () => getFields({ hiddenFields, ownerId: ownerId }),
    [hiddenFields, ownerId]
  );
  const currentUser = useCurrentUser();
  const donorTypeFieldName = `${fieldNamePrefix}.${OptionalFormSectionField.DonorType}`;
  const donorAttendDaycareDaysFieldName = `${fieldNamePrefix}.${OptionalFormSectionField.AttendDaycareDays}`;
  const donorType = watch(donorTypeFieldName) as DonorType;
  const [getDonorLivesSaved, getDonorLivesSavedQuery] =
    useGetDonorLivesSavedLazyQuery();
  useGetDonorQuery({
    variables: { input: { id: shortDonorId } },
    onCompleted(data) {
      if (data?.getDonor.id) {
        getDonorLivesSaved({
          variables: {
            input: {
              donorId: data?.getDonor.id,
            },
          },
        });
      }
    },
  });
  useEffect(() => {
    const { Team, IsDaycare } = OptionalFormSectionField;
    if (donorType === DonorType.Individual) {
      showField(IsDaycare);
      hideField(Team);
    } else if (donorType === DonorType.Grouped) {
      showField(Team);
      hideField(IsDaycare);
    }
    trigger();
  }, [donorType]);

  useEffect(() => {
    if (getValues().optional?.wontRegularlyAttendDaycare) {
      setValue(donorAttendDaycareDaysFieldName, []);
    }
    if (
      !getValues().optional?.wontRegularlyAttendDaycare &&
      !getValues().optional?.attendDaycareDays.length
    ) {
      setError('optional.attendDaycareDays', {
        message: t('attendDaycareDaysValidationError'),
      });
    } else {
      clearErrors('optional.attendDaycareDays');
    }
  }, [watch().optional?.wontRegularlyAttendDaycare]);

  return (
    <>
      {optionalFormSectionFields.map(({ Component, label, name }) => {
        const petWithoutOwnerTeamError =
          name === OptionalFormSectionField.Team && !ownerId;
        const fieldName = `${fieldNamePrefix}.${name}`;
        const errorMessage = petWithoutOwnerTeamError
          ? t('cantChooseTeamForDonorWithoutOwner')
          : formState.errors[fieldNamePrefix]?.[name]?.message;
        const isAttendDaysSelectDisabled =
          name === OptionalFormSectionField.AttendDaycareDays &&
          watch(
            `${fieldNamePrefix}.${OptionalFormSectionField.WontRegularlyAttendDaycare}`
          );

        const isDisabledApproveML =
          name === OptionalFormSectionField.ApprovedDonationML &&
          !currentUser.isCan(PermissionsEnum.ApproveDonationMl);

        const isFieldDisabled =
          isAttendDaysSelectDisabled || isDisabledApproveML || disabled;

        return (
          <Col span={24} key={fieldName}>
            <FormItem
              label={label}
              extra={errorMessage}
              validateStatus={
                !petWithoutOwnerTeamError && errorMessage && 'error'
              }
            >
              <Component
                control={control}
                name={fieldName}
                disabled={isFieldDisabled}
              />
            </FormItem>
          </Col>
        );
      })}
      {donor?.lastLabworkFile && (
        <Col span={24}>
          <FormItem className={styles.flatFormItem} label={t('labworkResults')}>
            {donor.lastLabworkFile.map((file) => {
              return (
                <>
                  <Typography.Link
                    download
                    onClick={async () =>
                      donor?.lastLabworkFile &&
                      downloadFileHandler(
                        file.filename,
                        `${process.env.REACT_APP_SERVER_URI}/labwork/files/${file.id}`
                      )
                    }
                  >
                    {file?.filename}
                  </Typography.Link>
                  <br />
                </>
              );
            })}
          </FormItem>
        </Col>
      )}
      {donor?.lastLabworkDate && (
        <Col span={24}>
          <FormItem className={styles.flatFormItem} label={t('nextLabwork')}>
            {format(
              addDays(
                new Date(donor?.lastLabworkDate),
                config.LABWORK_DURATION
              ),
              config.DATE_FORMAT
            )}
          </FormItem>
        </Col>
      )}
      <Col span={24}>
        <FormItem className={styles.flatFormItem} label={t('livesSavedRatio')}>
          <Input
            value={getDonorLivesSavedQuery?.data?.getDonorLivesSaved.lives}
            disabled={true}
            addonAfter={t('lives').toUpperCase()}
          />
        </FormItem>
      </Col>
    </>
  );
};

export default OptionalFormSection;
