export enum ExaminationFields {
  temperature = 'temperature',
  pulse = 'pulse',
  respiration = 'respiration',
  capillaryRefillTime = 'capillaryRefillTime',
  mucousMembrane = 'mucousMembrane',
  packedCellVolume = 'packedCellVolume',
  totalSolids = 'totalSolids',
  weight = 'weight',
  examinationNotes = 'examinationNotes',
  ineligible = 'ineligible',
  ineligibleReason = 'ineligibleReason',
  ineligibleReasonDetails = 'ineligibleReasonDetails',
  species = 'species',
}
