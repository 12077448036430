import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Select } from 'modules/common/components';
import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocationType } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
  adjustDropdownPosition?: boolean;
} & SelectProps;

const LocationSelect: FC<PropTypes> = ({
  controlled,
  className,
  ...restProps
}) => {
  const { t } = useTranslation('user.LocationTypeSelect');
  const classNames = `${styles.locationSelect} ${className || ''}`;
  const { field } = useController(controlled);
  const locationTypes: DefaultOptionType[] | undefined = Object.values(
    LocationType
  ).map((locationType) => {
    const localLabel = `${locationType[0].toLowerCase()}${locationType.substring(
      1
    )}`;
    return {
      label: t(localLabel),
      value: locationType,
    };
  });

  return (
    <Select
      placeholder={t('selectLocationType')}
      {...restProps}
      className={classNames}
      options={locationTypes}
      {...field}
    />
  );
};

export default LocationSelect;
