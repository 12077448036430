import { apolloClient } from 'providers/graphql';
import {
  SerializedMutation,
  OFFLINE_MUTATIONS_MAP,
} from 'providers/graphql/offline-link';

export const runOfflineMutations = async (
  offlineMutations: SerializedMutation[]
): Promise<void> => {
  await Promise.all(
    offlineMutations.map(async (mutationData: SerializedMutation) => {
      await apolloClient.mutate({
        mutation: OFFLINE_MUTATIONS_MAP[mutationData.name],
        variables: mutationData.variables,
      });
    })
  );
};
