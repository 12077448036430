import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('owner.ValidationErrors');

export const addressSchema = yup
  .string()
  .required(t('required', { field: t('address') }))
  .min(3, t('moreThan', { min: 3 }))
  .max(255, t('lessThan', { max: 255 }));

export const locationPreferencesSchema = yup
  .string()
  .required(t('required', { field: t('locationPreferences') }))
  .min(3, t('moreThan', { min: 3 }))
  .max(255, t('lessThan', { max: 255 }));

export const dateSchema = yup.date().typeError(t('date'));
