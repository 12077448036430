import {
  ControlledInput,
  ControlledTextArea,
  ControlledDatePicker,
  ControlledCheckbox,
  ControlledSelect,
  ControlledInputNumber,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import {
  BloodTypeSelect,
  ControlledYesNoRadioGroup,
  DonorTeamSelect,
  DonorTypeSelect,
} from 'modules/donor/components';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

import MedicalDirectorApproveControlledCheckbox from '../../MedicalDirectorApproveControlledCheckbox';

const t = getTranslation('donor.EditDonorForm.OptionalSection');

export enum OptionalFormSectionField {
  Marks = 'distinguishableMarks',
  BloodType = 'bloodType',
  DonorType = 'donorType',
  IsDaycare = 'isDaycare',
  AttendDaycareDays = 'attendDaycareDays',
  WontRegularlyAttendDaycare = 'wontRegularlyAttendDaycare',
  Team = 'team',
  Breed = 'breed',
  Color = 'color',
  RabiesDate = 'rabiesDate',
  DistemperDate = 'distemperDate',
  RetiredDate = 'retiredDate',
  DonationMLApprovedAmount = 'donationMLApprovedAmount',
  ApprovedDonationML = 'approvedDonationML',
  Comment = 'comment',
}

type FormFieldType = FormFieldWithComponent<
  OptionalFormSectionField,
  { name: string; disabled?: boolean }
>;

type PropTypes = {
  hiddenFields?: OptionalFormSectionField[];
  ownerId?: string;
};

export const getFields = ({
  hiddenFields,
  ownerId,
}: PropTypes): FormFieldType[] => {
  const formFields: FormFieldType[] = [
    {
      name: OptionalFormSectionField.Marks,
      label: t('distinguishableMarks'),
      Component: ({ control, name, disabled }) => (
        <ControlledTextArea
          controlled={{ control, name }}
          placeholder={t('enterDistinguishableMarks')}
          disabled={disabled}
          className={styles.marksArea}
        />
      ),
    },
    {
      name: OptionalFormSectionField.DonationMLApprovedAmount,
      label: t('medicalDirectorApproved'),
      Component: ({ control, name, disabled }) => (
        <MedicalDirectorApproveControlledCheckbox
          controlled={{ control, name }}
          disabled={disabled}
        />
      ),
    },
    {
      name: OptionalFormSectionField.ApprovedDonationML,
      label: t('petApprovedDonationML'),
      Component: ({ control, name, disabled }) => (
        <ControlledInputNumber
          className={styles.inputNumber}
          controlled={{ control, name }}
          disabled={true}
          addonAfter={t('ml').toUpperCase()}
        />
      ),
    },
    {
      name: OptionalFormSectionField.BloodType,
      label: t('bloodType'),
      Component: ({ control, name, disabled }) => (
        <BloodTypeSelect controlled={{ control, name }} disabled={disabled} />
      ),
    },
    {
      name: OptionalFormSectionField.DonorType,
      label: t('donorType'),
      Component: ({ control, name, disabled }) => (
        <DonorTypeSelect
          controlled={{ control, name }}
          placeholder={t('selectDonorType')}
          disabled={disabled}
        />
      ),
    },
    {
      name: OptionalFormSectionField.Team,
      label: t('team'),
      Component: ({ control, name, disabled }) => {
        return (
          <DonorTeamSelect
            userIds={ownerId ? [ownerId] : undefined}
            controlled={{ control, name }}
            placeholder={t('selectTeam')}
            disabled={ownerId ? disabled : true}
          />
        );
      },
    },
    {
      name: OptionalFormSectionField.IsDaycare,
      label: t('isDaycareLabel'),
      Component: ({ control, name, disabled }) => {
        return (
          <ControlledCheckbox
            disabled={disabled}
            controlled={{ control, name }}
          >
            {t('isDaycare')}
          </ControlledCheckbox>
        );
      },
    },
    {
      name: OptionalFormSectionField.AttendDaycareDays,
      label: t('attendDaycareDays'),
      Component: ({ control, name, disabled }) => (
        <ControlledSelect
          disabled={disabled}
          controlled={{
            control,
            name,
          }}
          options={[
            { label: 'Monday', value: 'Monday' },
            { label: 'Tuesday', value: 'Tuesday' },
            { label: 'Wednesday', value: 'Wednesday' },
            { label: 'Thursday', value: 'Thursday' },
            { label: 'Friday', value: 'Friday' },
          ]}
          mode="multiple"
          placeholder={t('attendDaycareDaysPlaceholder')}
        />
      ),
    },
    {
      name: OptionalFormSectionField.WontRegularlyAttendDaycare,
      label: t('isRegularlyAttendDaycare'),
      Component: ({ control, name, disabled }) => (
        <ControlledYesNoRadioGroup
          disabled={disabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: OptionalFormSectionField.Breed,
      label: t('breed'),
      Component: ({ control, name, disabled }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('enterBreed')}
          disabled={disabled}
        />
      ),
    },
    {
      name: OptionalFormSectionField.Color,
      label: t('color'),
      Component: ({ control, name, disabled }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('enterColor')}
          disabled={disabled}
        />
      ),
    },
    {
      name: OptionalFormSectionField.RabiesDate,
      label: t('rabiesDate'),
      Component: ({ control, name, disabled }) => (
        <ControlledDatePicker
          controlled={{ control, name }}
          placeholder={t('enterDate')}
          disabled={disabled}
        />
      ),
    },
    {
      name: OptionalFormSectionField.DistemperDate,
      label: t('distemperDate'),
      Component: ({ control, name, disabled }) => (
        <ControlledDatePicker
          controlled={{ control, name }}
          placeholder={t('enterDate')}
          disabled={disabled}
        />
      ),
    },
    {
      name: OptionalFormSectionField.RetiredDate,
      label: t('retiredDate'),
      Component: ({ control, name, disabled }) => (
        <ControlledDatePicker
          controlled={{ control, name }}
          placeholder={t('enterDate')}
          disabled={disabled}
        />
      ),
    },
    {
      name: OptionalFormSectionField.Comment,
      label: t('comment'),
      Component: ({ control, name, disabled }) => (
        <ControlledTextArea
          disabled={disabled}
          className={styles.marksArea}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
  ];

  if (hiddenFields?.length) {
    return formFields.filter(({ name }) => !hiddenFields.includes(name));
  }
  return formFields;
};
