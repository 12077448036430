import { GroupListItemOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.GroupCardInfo');

type Option = {
  label: string;
  value: JSX.Element | string;
};

export const getOptions = (group: GroupListItemOutput): Option[] => [
  {
    label: t('owner'),
    value: group.user ? `${group.user.firstName} ${group.user.lastName}` : '-',
  },
  {
    label: t('totalDonors'),
    value: `${group.donorCount}`,
  },
];
