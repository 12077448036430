import { PhoneInput, Input } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
  size?: ComponentProps<typeof Input>['size'];
} & Omit<ComponentProps<typeof PhoneInput>, 'size'>;

const ControlledPhoneInput: FC<PropTypes> = ({
  controlled,
  size,
  ...restProps
}) => {
  const controller = useController(controlled);
  const { onChange, onBlur, value, ref } = controller.field;
  const errorMessage = controller.formState.errors[controlled.name]?.message;

  return (
    <PhoneInput
      {...restProps}
      alwaysShowMask
      onChange={onChange}
      value={!value ? '' : value}
      onBlur={onBlur}
      style={{ border: errorMessage && '1px solid red' }}
      children={(props) => {
        return (
          <Input
            {...props}
            disabled={restProps.disabled}
            size={size}
            ref={ref}
          />
        );
      }}
    />
  );
};

export default ControlledPhoneInput;
