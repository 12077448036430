import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Routes } from 'config/routes';
import { Button, Link } from 'modules/common/components';
import { useTranslation } from 'react-i18next';
import { DaycareAppointmentResponseOutput, DonorOutput } from 'types';

type PropTypes = {
  enrollLoading: boolean;
  unenrollHandler: (daycareId: string, donorId: string) => void;
  daycareData: DaycareAppointmentResponseOutput;
};

export const ExpandedRowRender = ({
  unenrollHandler,
  daycareData,
  enrollLoading,
}: PropTypes) => {
  const { t } = useTranslation('appointments.DaycareDetailModal');
  const columns: ColumnsType<DonorOutput> = [
    {
      title: t('donorName'),
      key: 'donorName',
      render: (donor: DonorOutput) => {
        return (
          <Link
            target="_blank"
            to={Routes.DonorsProfile.replace(':id', `${donor.shortId}`)}
          >
            {donor.name}
          </Link>
        );
      },
    },
    {
      title: t('donorStatus'),
      key: 'status',
      render: (donor: DonorOutput) => {
        return t(donor.status.toLocaleLowerCase());
      },
    },
    {
      title: t('action'),
      key: 'action',
      render: (donor: DonorOutput) => {
        return (
          <Button
            type="link"
            onClick={() => unenrollHandler(daycareData.id, donor.id)}
            loading={enrollLoading}
          >
            {t('unenroll')}
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={daycareData.registeredDonors}
      pagination={false}
      size="small"
    />
  );
};
