import { UserRole } from 'types.d';

const { Admin, InventoryTeam, CollectionTeam, MedicalDirector, DogHandler } =
  UserRole;

export const INTERNAL_TEAM_ROLES = [
  Admin,
  InventoryTeam,
  CollectionTeam,
  MedicalDirector,
  DogHandler,
];

export const ALLOWED_SIGNS = ",`()'";
