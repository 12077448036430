import { Radio, RadioGroupProps } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  mode: CalendarMode;
  onChange?: (mode: CalendarMode) => void;
} & Omit<RadioGroupProps, 'value' | 'onChange'>;

const ModeTabs: FC<PropTypes> = ({ mode, onChange, ...restProps }) => {
  const { t } = useTranslation('appointments.AppointmentsCalendar.ModeTabs');
  return (
    <Radio.Group
      size="middle"
      onChange={(e) => onChange && onChange(e.target.value)}
      value={mode}
      {...restProps}
    >
      <Radio.Button value="month">{t('month')}</Radio.Button>
      <Radio.Button value="year">{t('year')}</Radio.Button>
    </Radio.Group>
  );
};

export default ModeTabs;
