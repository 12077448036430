import { ColumnsType } from 'antd/es/table/Table';
import { SortOrder } from 'antd/lib/table/interface';
import { ISortOption } from 'modules/common/interfaces';
import Title from 'modules/donor/components/GroupCard/components/Title';
import { GroupTitleFormSchema } from 'modules/donor/components/GroupCard/schema';
import GroupListActionsButtons from 'modules/donor/components/GroupListActionsButtons';
import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { GroupListSort, GroupListItemOutput, Order } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.GroupsTable');

type Props = {
  onToggleEditMode: (value: boolean) => void;
  isEditMode: boolean;
  form: UseFormReturn<GroupTitleFormSchema, any>;
  currentGroup: GroupListItemOutput | undefined;
  setCurrentGroup: Dispatch<SetStateAction<GroupListItemOutput | undefined>>;
  sort?: ISortOption<GroupListSort>;
};

export const getGroupColumns = ({
  isEditMode,
  onToggleEditMode,
  form,
  currentGroup,
  setCurrentGroup,
  sort,
}: Props): ColumnsType<GroupListItemOutput> => {
  const getDefaultSort = (fieldName: GroupListSort): SortOrder | undefined => {
    if (sort?.fieldName === fieldName) {
      return sort.order === Order.Asc ? 'ascend' : 'descend';
    }
    return undefined;
  };

  return [
    {
      title: t('owner'),
      key: GroupListSort.Owner,
      defaultSortOrder: getDefaultSort(GroupListSort.Owner),
      render: ({ user }: GroupListItemOutput) => {
        return user ? `${user.firstName} ${user.lastName}` : '-';
      },
      align: 'center',
      sorter: true,
    },
    {
      title: t('groups'),
      key: GroupListSort.Title,
      sorter: true,
      defaultSortOrder: getDefaultSort(GroupListSort.Title),
      render: (row: GroupListItemOutput) => (
        <Title
          group={row}
          errorMessage={form.formState.errors.title?.message}
          isEditMode={isEditMode && row === currentGroup}
          controlled={{ control: form.control, name: 'title' }}
          showCount={false}
        />
      ),
      align: 'center',
    },
    {
      title: t('teams'),
      render: (row: GroupListItemOutput) =>
        `${t('total')} : ${row.teamCount} ${t('teams').toLowerCase()}`,
      key: GroupListSort.Teams,
      sorter: true,
      defaultSortOrder: getDefaultSort(GroupListSort.Teams),
      align: 'center',
    },
    {
      title: t('totalDonors'),
      render: (row: GroupListItemOutput) => row.donorCount,
      key: GroupListSort.Donors,
      sorter: true,
      defaultSortOrder: getDefaultSort(GroupListSort.Donors),
      align: 'center',
    },
    {
      title: t('action'),
      render: (row: GroupListItemOutput) => (
        <GroupListActionsButtons
          group={row}
          isEditMode={isEditMode && row === currentGroup}
          onToggleEditMode={(value: boolean) => {
            setCurrentGroup(row);
            form.reset({ title: row.title });
            onToggleEditMode(value);
          }}
          form={form}
        />
      ),
      align: 'center',
    },
  ];
};
