import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const ChangeProductTrackingInfoModalContext = createContext<ContextType | null>(
  null
);

const ChangeProductTrackingInfoModalProvider: ComponentWithChildren = ({
  children,
}) => {
  const providerValue = useProviderValue();

  return (
    <ChangeProductTrackingInfoModalContext.Provider value={providerValue}>
      {children}
    </ChangeProductTrackingInfoModalContext.Provider>
  );
};

export const useChangeProductTrackingInfoModalContext = () => {
  const context = useContext(ChangeProductTrackingInfoModalContext);
  if (context === undefined) {
    throw new Error(
      'the useChangeProductTrackingInfoModalContext should be used within ChangeProductTrackingInfoModalProvider!'
    );
  }

  return context;
};

export default ChangeProductTrackingInfoModalProvider;
