import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import InvitationRolesSelect from 'modules/user/components/InvitationRolesSelect';
import { InviteUserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { InviteFields } from './enum';

const t = getTranslation('admin.InvitePage');

export const fields: FormFieldWithComponent<
  InviteFields,
  { name: InviteFields }
>[] = [
  {
    label: t('firstName'),
    name: InviteFields.firstName,
    Component: ({ name, control }) => (
      <ControlledInput
        controlled={{ name, control }}
        placeholder={t('enterFirstName')}
      />
    ),
  },
  {
    label: t('lastName'),
    name: InviteFields.lastName,
    Component: ({ name, control }) => (
      <ControlledInput
        controlled={{ name, control }}
        placeholder={t('enterLastName')}
      />
    ),
  },
  {
    label: t('email'),
    name: InviteFields.email,
    Component: ({ name, control }) => (
      <ControlledInput
        controlled={{ name, control }}
        placeholder={t('email')}
      />
    ),
  },
  {
    label: t('phone'),
    name: InviteFields.phoneNumber,
    placeholder: t('enterPhone'),
    Component: ({ name, control }) => {
      return <ControlledPhoneInput controlled={{ name, control }} />;
    },
  },
  {
    label: t('role'),
    name: InviteFields.role,
    Component: ({ name, control }) => (
      <InvitationRolesSelect
        placeholder={t('selectUserRole')}
        controlled={{ name, control }}
        omittedRoles={[InviteUserRole.Owner]}
      />
    ),
  },
];
