import { Form, FormItemProps } from 'antd';
import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  children?: ReactNode;
} & FormItemProps;

const FormItem: FC<PropTypes> = ({ children, className, ...restProps }) => {
  const defaultProps: FormItemProps = {
    colon: false,
    className: `${styles.formItem} ${className}`,
  };
  return (
    <Form.Item {...defaultProps} {...restProps}>
      {children}
    </Form.Item>
  );
};

export default FormItem;
