import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Row, Col, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import { SortOrder } from 'antd/lib/table/interface';
import config from 'config';
import { Routes } from 'config/routes';
import { format, differenceInDays } from 'date-fns';
import { Link, Button } from 'modules/common/components';
import { ISortOption } from 'modules/common/interfaces';
import {
  BloodType,
  DonorListFilterInput,
  DonorListSort,
  DonorOutput,
  Order,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.ReserveDonorsTable');

const bloodTypes = Object.values(BloodType);

type PropTypes = {
  addNewNoteHandler: (donorId: string) => void;
  updateNoteHandler: (noteId: string, previousValue: string) => void;
  deleteNoteHandler: (noteId: string) => void;
  showSorterTooltip?: boolean;
  sort?: ISortOption<DonorListSort>;
  filter?: DonorListFilterInput;
};

export const getColumns = ({
  addNewNoteHandler,
  updateNoteHandler,
  deleteNoteHandler,
  showSorterTooltip,
  sort,
  filter,
}: PropTypes): ColumnsType<DonorOutput> => {
  const getDefaultSort = (fieldName: DonorListSort): SortOrder | undefined => {
    if (sort?.fieldName === fieldName) {
      return sort.order === Order.Asc ? 'ascend' : 'descend';
    }
    return undefined;
  };

  return [
    {
      title: t('donorId'),
      render: (row: DonorOutput) => row.shortId,
      align: 'center',
    },
    {
      title: t('petName'),
      key: DonorListSort.Name,
      sorter: true,
      sortOrder: getDefaultSort(DonorListSort.Name),
      showSorterTooltip,
      render: (donor: DonorOutput) => (
        <Link
          to={`${Routes.DonorsProfile.replace(':id', String(donor.shortId))}`}
        >
          {donor.name}
        </Link>
      ),
      align: 'center',
    },
    {
      title: t('ownerLastName'),
      key: DonorListSort.LastName,
      sorter: true,
      sortOrder: getDefaultSort(DonorListSort.LastName),
      showSorterTooltip,
      render: (row: DonorOutput) => row.user?.lastName,
      align: 'center',
    },
    {
      title: t('bloodType'),
      render: (row: DonorOutput) =>
        row.bloodType && t(row.bloodType.toLowerCase()),
      filters: bloodTypes.map((bloodType) => {
        return {
          text: t(bloodType.toLowerCase()),
          value: bloodType,
        };
      }),
      align: 'center',
      filteredValue: filter?.bloodType || null,
    },
    {
      title: t('enteredDate'),
      key: DonorListSort.ReservedDate,
      sorter: true,
      sortOrder: getDefaultSort(DonorListSort.ReservedDate),
      showSorterTooltip,
      render: (row: DonorOutput) =>
        format(new Date(row.becameReservedDonorDate), config.DATE_FORMAT),
      align: 'center',
    },
    {
      title: t('daysInReserve'),
      render: (row: DonorOutput) =>
        differenceInDays(new Date(), new Date(row.becameReservedDonorDate)),
      align: 'center',
    },
    {
      title: t('notes'),
      render: (row: DonorOutput) => (
        <Col className={styles.columnWrapper}>
          {row.reserveNotes?.map((note) => (
            <Row gutter={[16, 0]} justify="space-between" key={note.id}>
              <Col className={styles.date}>
                {format(new Date(note.createdAt), config.DATE_FORMAT)}
              </Col>
              <Col className={styles.comment}>
                <abbr title={note.text} className={styles.abbreviation}>
                  {note.text}
                </abbr>
              </Col>
              <Col className={styles.noteActionButtons}>
                <EditOutlined
                  onClick={() => updateNoteHandler(note.id, note.text)}
                />

                <Popconfirm
                  title={t('deleteNoteConfirm')}
                  onConfirm={() => deleteNoteHandler(note.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Col>
            </Row>
          ))}
          <Button onClick={() => addNewNoteHandler(row.id)}>
            {t('addInfo')}
          </Button>
        </Col>
      ),
      align: 'center',
    },
  ];
};
