import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form, message } from 'antd';
import { Routes } from 'config/routes';
import EditUserEmailForm from 'modules/admin/components/EditUserForm/EmailSection';
import { UserType } from 'modules/admin/components/EditUserForm/EmailSection/enum';
import EditUserRoleForm from 'modules/admin/components/EditUserForm/RoleSection';
import { GET_USER_QUERY } from 'modules/admin/graphql/queries';
import { Button, FormItem, Spin } from 'modules/common/components';
import { ProductLocationSelect } from 'modules/donor/components';
import { GET_USER_LIST } from 'modules/user/graphql/queries/user';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  GetUserQuery,
  PermissionsEnum,
  useGetUserQuery,
  UserOutput,
  useUpdateUserInfoMutation,
} from 'types.d';

import fields from './fields';
import { editUserInfoSchema, EditUserInfoSchema } from './schema';
import styles from './styles.module.scss';

const { UpdateUserEmail, ChangeUserRole, UpdateOwnProfile } = PermissionsEnum;

type Props = {
  ownProfile?: boolean;
  userId?: string;
};

const EditUserForm: FC<Props> = ({ ownProfile, userId }) => {
  const { t } = useTranslation('admin.EditUserForm');
  const [formEnabled, setFormEnabled] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const allowToChangeEmail = currentUser.isCan(UpdateUserEmail);
  const allowToChangeUserRole = currentUser.isCan(ChangeUserRole);
  const allowToEditOwnProfile = currentUser.isCan(UpdateOwnProfile);

  const { control, formState, reset, handleSubmit } =
    useForm<EditUserInfoSchema>({
      resolver: yupResolver(editUserInfoSchema),
      mode: 'onChange',
    });

  const resetForm = (data: GetUserQuery | undefined) => {
    reset({
      firstName: data?.getUser.firstName,
      lastName: data?.getUser.lastName,
      phoneNumber: data?.getUser.phoneNumber || '',
      productLocationPreference: data?.getUser.productLocationPreference,
    });
  };

  const userQuery = useGetUserQuery({
    variables: {
      input: {
        id: (ownProfile ? String(currentUser.data?.shortId) : userId) || '',
      },
    },
    onError: (error) => {
      message.error(error.message);

      if (error.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.Users);
    },
    onCompleted: (data) => resetForm(data),
  });

  const [updateUserInfo, updateUserInfoMutation] = useUpdateUserInfoMutation({
    refetchQueries: [GET_USER_QUERY, GET_USER_LIST],
    onCompleted: () => {
      message.success(t('successfullySaved'));
    },
    onError: (error) => message.error(error.message),
  });

  const editUserHandler = handleSubmit((data) => {
    updateUserInfo({
      variables: {
        input: {
          id: userQuery?.data?.getUser.id || '',
          productLocationPreference: data?.productLocationPreference,
          ...data,
        },
      },
    });
    setFormEnabled(!formEnabled);
  });

  const closeHandler = () => {
    resetForm(userQuery.data);
    setFormEnabled(false);
  };

  return (
    <Spin spinning={userQuery.loading || updateUserInfoMutation.loading}>
      <Form className={styles.form}>
        {fields.map(({ label, name, Component }) => {
          const error = formState.errors[name]?.message;

          return (
            <FormItem
              key={name}
              label={label}
              validateStatus={error && 'error'}
              extra={error}
            >
              <Component
                key={name}
                name={name}
                control={control}
                disabled={!formEnabled}
              />
            </FormItem>
          );
        })}
        {userQuery?.data?.getUser && (
          <EditUserEmailForm
            user={userQuery?.data?.getUser as UserOutput}
            canEdit={
              ownProfile
                ? allowToEditOwnProfile && allowToChangeEmail
                : allowToChangeEmail
            }
            userType={UserType.InternalTeam}
          />
        )}

        {userQuery?.data?.getUser && allowToChangeUserRole && !ownProfile && (
          <EditUserRoleForm user={userQuery?.data?.getUser as UserOutput} />
        )}

        {userQuery?.data?.getUser && (
          <ProductLocationSelect
            control={{ control }}
            disabled={!formEnabled}
            userRole={userQuery?.data?.getUser.role}
          />
        )}

        {!(ownProfile && !allowToEditOwnProfile) && (
          <div className={styles.buttonsContainer}>
            <Button onClick={closeHandler} disabled={!formEnabled}>
              {t('close')}
            </Button>

            {formEnabled && (
              <Button
                disabled={!formState.isValid || !formState.isDirty}
                onClick={editUserHandler}
                type="primary"
              >
                {t('save')}
              </Button>
            )}

            {!formEnabled && (
              <Button
                type="primary"
                onClick={() => {
                  setFormEnabled(!formEnabled);
                  resetForm(userQuery.data);
                }}
              >
                {t('edit')}
              </Button>
            )}
          </div>
        )}
      </Form>
    </Spin>
  );
};
export default EditUserForm;
