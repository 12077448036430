import { emailSchema } from 'modules/user/schemas';
import * as yup from 'yup';

type EditUserEmailSchema = {
  email: string;
};

const editUserEmailSchema: yup.SchemaOf<EditUserEmailSchema> = yup.object({
  email: emailSchema,
});

export { editUserEmailSchema };
export type { EditUserEmailSchema };
