import { Card } from 'antd';
import LocaleDate from 'modules/common/components/LocaleDate';
import DonorDonationCardInfo from 'modules/donor/components/DonorDonationCardInfo';
import { FC } from 'react';
import { DonationOutput } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  donation: DonationOutput;
  addCommentHandler: (donationId: string, previousValue?: string) => void;
  productCategories?: Record<number, string>;
};

const DonorDonationCard: FC<PropTypes> = (props) => {
  return (
    <Card
      className={styles.donationCard}
      title={<LocaleDate dateValue={props.donation.donationDate} />}
    >
      <DonorDonationCardInfo {...props} />
    </Card>
  );
};

export default DonorDonationCard;
