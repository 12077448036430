import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  children: JSX.Element[] | JSX.Element;
  classNames?: string;
};

export const FormPage = forwardRef<any, PropTypes>(
  ({ children, classNames }, ref) => {
    return (
      <div className={styles.page}>
        <div className={`${styles.pageBody} ${classNames}`} ref={ref}>
          {children}
        </div>
      </div>
    );
  }
);
