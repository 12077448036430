import { Col } from 'antd';
import {
  ControlledInputNumber,
  ControlledTextArea,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import {
  CapillaryRefillTimeSelect,
  MucousMembraneSelect,
  RespirationInput,
} from 'modules/donations/components';
import styles from 'modules/donations/components/CreateDonationForm/styles.module.scss';
import { getTranslation } from 'utils/getTranslation';

import { ExaminationFields } from './enum';

const t = getTranslation('donation.CreateDonationForm.Examination');

export type ExaminationFormFiledType = FormFieldWithComponent<
  ExaminationFields,
  { name: string }
>;
type Props = {
  enabled: boolean;
};

export const getExaminationFields = ({
  enabled,
}: Props): ExaminationFormFiledType[] => [
  {
    name: ExaminationFields.temperature,
    label: t(ExaminationFields.temperature),
    Component: ({ control, name }) => (
      <ControlledInputNumber
        disabled={!enabled}
        addonAfter={t('f')}
        controlled={{ control, name }}
        placeholder={t('enterTemperature')}
        step={1}
        min={0}
        stringMode
      />
    ),
  },
  {
    name: ExaminationFields.pulse,
    label: t(ExaminationFields.pulse),
    Component: ({ control, name }) => (
      <ControlledInputNumber
        disabled={!enabled}
        addonAfter={t('bmp')}
        controlled={{ control, name }}
        placeholder={t('enterPulse')}
        step={1}
        min={0}
        stringMode
      />
    ),
  },
  {
    name: ExaminationFields.respiration,
    label: t(ExaminationFields.respiration),
    Component: ({ control, name }) => (
      <RespirationInput
        disabled={!enabled}
        addonAfter={t('bpm')}
        controlled={{ control, name }}
        placeholder={t('enterRespiration')}
        min={0}
        stringMode
      />
    ),
  },
  {
    name: ExaminationFields.capillaryRefillTime,
    label: t(ExaminationFields.capillaryRefillTime),
    Component: ({ control, name }) => (
      <CapillaryRefillTimeSelect
        disabled={!enabled}
        className={styles.selectInCell}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    name: ExaminationFields.mucousMembrane,
    label: t(ExaminationFields.mucousMembrane),
    Component: ({ control, name }) => (
      <MucousMembraneSelect
        disabled={!enabled}
        className={styles.selectInCell}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    name: ExaminationFields.packedCellVolume,
    label: t(ExaminationFields.packedCellVolume),
    Component: ({ control, name }) => (
      <ControlledInputNumber
        disabled={!enabled}
        addonAfter="%"
        controlled={{ control, name }}
        placeholder={t('enterPackedCellVolume')}
        min={0}
        stringMode
      />
    ),
  },
  {
    name: ExaminationFields.totalSolids,
    label: t(ExaminationFields.totalSolids),
    Component: ({ control, name }) => (
      <ControlledInputNumber
        disabled={!enabled}
        addonAfter={t('gPerDL')}
        controlled={{ control, name }}
        placeholder={t('enterTotalSolids')}
        min={0}
        stringMode
      />
    ),
  },
  {
    name: ExaminationFields.weight,
    label: t(ExaminationFields.weight),
    Component: ({ control, name }) => (
      <ControlledInputNumber
        disabled={!enabled}
        addonAfter={t('kg')}
        controlled={{ control, name }}
        placeholder={t('enterWeight')}
      />
    ),
  },
  {
    name: ExaminationFields.examinationNotes,
    Component: ({ control, name }) => (
      <Col>
        {t('examinationNotes')}
        <ControlledTextArea
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
          size="large"
        />
      </Col>
    ),
  },
];
