import { ApolloQueryResult } from '@apollo/client';
import { GET_DONOR_QUERY } from 'modules/donor/graphql/queries';
import { apolloClient } from 'providers/graphql';
import { GetDonorQuery, GetDonorQueryVariables } from 'types.d';

export const getDonor = (
  id: string
): Promise<ApolloQueryResult<GetDonorQuery>> => {
  return apolloClient.query<GetDonorQuery, GetDonorQueryVariables>({
    query: GET_DONOR_QUERY,
    variables: {
      input: {
        id,
      },
    },
  });
};
