import { CheckOutlined } from '@ant-design/icons';
import { FC } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  onClick: () => void;
  showTick: boolean;
  disabled?: boolean;
};

const CheckCell: FC<PropTypes> = ({ onClick, showTick, disabled }) => {
  const tickClassName = showTick ? styles.containerOn : styles.containerOff;
  const className = [
    styles.container,
    tickClassName,
    disabled ? styles.disabled : '',
  ].join(' ');

  return (
    <div className={className} onClick={!disabled ? onClick : undefined}>
      <CheckOutlined />
    </div>
  );
};

export default CheckCell;
