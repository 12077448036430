import { Modal } from 'antd';
import { BaseModalProps } from 'modules/common/types';
import { RoutineTestForm } from 'modules/donor/components/RoutineTestForm';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  donorId?: string;
} & BaseModalProps;

const RoutineTestModal: FC<Props> = ({
  hide,
  onCancel,
  onOk,
  donorId,
  ...restProps
}) => {
  const { t } = useTranslation('donor.RoutineTestModal');
  const routineTestFormRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const onCancelHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    hide();
    onCancel && onCancel(e);
  };

  const onOkHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const current = routineTestFormRef?.current as any;
    current.createAndPrintHandler();
    onOk && onOk(e);
  };

  return (
    <Modal
      onCancel={onCancelHandler}
      onOk={onOkHandler}
      okButtonProps={{
        disabled: !isFormValid,
      }}
      cancelText={t('close')}
      {...restProps}
      title={t('title')}
      destroyOnClose
    >
      <RoutineTestForm
        donorId={donorId}
        ref={routineTestFormRef}
        hide={hide}
        setIsFormValid={setIsFormValid}
      />
    </Modal>
  );
};

export default RoutineTestModal;
