import { Row } from 'antd';
import React from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  children: JSX.Element | JSX.Element[] | string;
  position?: 'center' | 'start' | 'end';
};

export default function Title({ children, position = 'center' }: PropTypes) {
  return (
    <Row className={styles.title} justify={position}>
      <h3>{children}</h3>
    </Row>
  );
}
