import { SettingOutlined } from '@ant-design/icons';
import { Row, Col, Spin, message } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { addYears, subYears } from 'date-fns';
import RangeDatePicker from 'modules/common/components/RangeDatePicker';
import { useModal } from 'modules/common/hooks';
import {
  TotalSolidsChart,
  PackedCellVolumeChart,
  TemperatureChart,
  WeightChart,
} from 'modules/donor/components';
import UpdateVitalsParametersModal from 'modules/donor/components/UpdateVitalsParametersModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import moment, { Moment } from 'moment';
import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DonationOutput,
  DonationListSort,
  Order,
  useGetChartsDataQuery,
  SpecificDonorDonationListFilter,
  useGetDonorQuery,
  PermissionsEnum,
} from 'types.d';

const Charts: FC = () => {
  const params = useParams();
  const shortDonorId = params.id || '';
  const dateFormat = config.CHARTS_DATE_PICKER_FORMAT;
  const [data, setData] = useState<DonationOutput[]>([]);
  const [filter, setFilter] = useState<SpecificDonorDonationListFilter>();
  const navigate = useNavigate();
  const donorQuery = useGetDonorQuery({
    variables: {
      input: {
        id: shortDonorId,
      },
    },
    onCompleted: ({ getDonor }) => {
      setFilter({ donorId: getDonor.id });
    },
    onError: (err) => {
      message.error(err.message);
      if (err.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.IndividualDonors);
    },
  });
  const donorId = donorQuery.data?.getDonor.id;
  const [take, setTake] = useState<number | undefined>(
    config.CHARTS_DEFAULT_DONATIONS_LIMIT
  );
  const [datePickerValues, setDatePickerValues] = useState<
    [Moment, Moment] | undefined
  >();
  const currentUser = useCurrentUser();
  const updateVitalsChartParameters = currentUser.isCan(
    PermissionsEnum.UpdateVitalsChartParameters
  );

  const getChartsDataQuery = useGetChartsDataQuery({
    variables: {
      input: {
        sort: [
          { fieldName: DonationListSort.LastDonationDate, order: Order.Desc },
        ],
        filter,
        take,
      },
    },
    onCompleted: (result) => {
      const donationsList = result.getChartsData.data as DonationOutput[];
      const sortedDonations = [...donationsList].sort(
        (a, b) =>
          new Date(a.donationDate).getTime() -
          new Date(b.donationDate).getTime()
      );
      setData(sortedDonations);
    },
    onError: (error) => {
      message.error(error.message);
    },
    skip: !Boolean(donorId),
  });

  const onDateRangeChange = (values: [string, string]) => {
    const fromDate = new Date(values[0]);
    const toDate = new Date(values[1]);
    setTake(undefined);
    setFilter((previousFilter = {} as SpecificDonorDonationListFilter) => {
      return {
        ...previousFilter,
        fromDate,
        toDate,
      };
    });
    setDatePickerValues([
      moment(values[0], dateFormat),
      moment(values[1], dateFormat),
    ]);
  };

  useEffect(() => {
    if (data && data.length > 0 && !filter?.fromDate && !filter?.toDate) {
      const toDate = data[data.length - 1].donationDate;
      const fromDate = data[0].donationDate;
      setDatePickerValues([
        moment(fromDate.toString(), dateFormat),
        moment(toDate.toString(), dateFormat),
      ]);
    }
  }, [data]);

  const updateVitalsParametersModal = useModal();

  const openSettingHandler = () => {
    updateVitalsParametersModal.show();
  };

  return (
    <Spin spinning={getChartsDataQuery.loading}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <RangeDatePicker
                value={datePickerValues}
                picker="month"
                disabledDate={(d) =>
                  !d ||
                  d.isAfter(addYears(new Date(), 3)) ||
                  d.isBefore(subYears(new Date(), 3))
                }
                onChange={(values, formatString) =>
                  onDateRangeChange(formatString)
                }
                allowClear={false}
              />
            </Col>
            {updateVitalsChartParameters && (
              <Col>
                <SettingOutlined onClick={openSettingHandler} />
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-evenly" gutter={[32, 40]}>
            <Col xxl={{ span: 8 }} xl={{ span: 12 }} span={24}>
              <WeightChart donationData={data} />
            </Col>
            <Col xxl={{ span: 8 }} xl={{ span: 12 }} span={24}>
              <PackedCellVolumeChart donationData={data} />
            </Col>
            <Col xxl={{ span: 8 }} xl={{ span: 12 }} span={24}>
              <TotalSolidsChart donationData={data} />
            </Col>
            <Col xxl={{ span: 8 }} xl={{ span: 12 }} span={24}>
              <TemperatureChart donationData={data} />
            </Col>
          </Row>
        </Col>
      </Row>
      <UpdateVitalsParametersModal {...updateVitalsParametersModal.config} />
    </Spin>
  );
};
export default Charts;
