import { ColumnsType } from 'antd/es/table/Table';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { RequestOutput, RequestListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import DateCell from './components/DateCell';

const t = getTranslation('requests.RequestsTable');

type PropTypes = {
  breakpoints: Partial<Record<Breakpoint, boolean>>;
  canUserProcessRequest: boolean;
};

export const getColumns = (props: PropTypes): ColumnsType<RequestOutput> => [
  {
    title: props.breakpoints.sm ? t('requestNumber') : '#',
    render: (row: RequestOutput) => row.number,
    width: '20%',
    align: 'center',
  },
  {
    title: t('ownerName'),
    key: RequestListSort.Owner,
    render: (row: RequestOutput) => {
      const ownerFullName = `${row.donor.user?.firstName} ${row.donor.user?.lastName}`;
      return row.donor.user ? ownerFullName : '-';
    },
    width: '40%',
    align: 'center',
    showSorterTooltip: props.breakpoints.md,
    sorter: true,
  },
  {
    title: t('date'),
    key: RequestListSort.Date,
    render: (row: RequestOutput) => (
      <DateCell
        canUserProcessRequest={props.canUserProcessRequest}
        request={row}
      />
    ),
    width: props.breakpoints.sm ? '30%' : '40%',
    align: 'center',
    sorter: true,
    showSorterTooltip: props.breakpoints.md,
  },
];
