import { Col, message, Row, Grid, Radio, RadioChangeEvent } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { Button, SearchInput, Spin } from 'modules/common/components';
import {
  useDebounce,
  useFilter,
  useModal,
  useSort,
} from 'modules/common/hooks';
import { ISortOption } from 'modules/common/interfaces';
import CsvDonorsUploadingModal from 'modules/donor/components/CsvDonorsUploadingModal';
import IndividualDonorCardList from 'modules/donor/components/IndividualDonorCardList';
import IndividualDonorListFilter from 'modules/donor/components/IndividualDonorListFilter';
import IndividualDonorListSort from 'modules/donor/components/IndividualDonorListSort';
import IndividualDonorListTable from 'modules/donor/components/IndividualDonorListTable';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DonorListFilterInput,
  DonorListSort,
  DonorOutput,
  DonorType,
  PermissionsEnum,
  useGetDonorsListQuery,
} from 'types.d';

enum DaycareSwitcherValue {
  All,
  Daycare,
}

const IndividualDonorListPage: FC = () => {
  const { t } = useTranslation('donor.IndividualDonorListPage');
  const donorsModal = useModal();
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const [daycareSwitchValue, setDaycareSwitchValue] = useState(
    DaycareSwitcherValue.All
  );
  const currentUser = useCurrentUser();
  const canUpdateDonors = currentUser.isCan(PermissionsEnum.UpdateDonorsData);
  const breakpoints = Grid.useBreakpoint();
  const isBreakpointsEmpty = Object.keys(breakpoints).length === 0;
  const pagination = usePagination();
  const { debounce, clearDebounce } = useDebounce();
  const [sort, setSort] = useState<ISortOption<DonorListSort>[]>();
  const donorListSort = useSort<DonorListSort>();
  const donorListFilters = useFilter<DonorListFilterInput>([
    { fieldName: 'types', value: [DonorType.Individual] },
  ]);

  const setSearchInput = useRef(false);
  const setDaycareRadio = useRef(false);
  const getDonorsListQuery = useGetDonorsListQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        sort: donorListSort.options,
        filter: donorListFilters.getFilterObject(),
      },
    },
    onCompleted: (result) => {
      pagination.setTotal(result.getDonorsList.meta.total);
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const donorList =
    (getDonorsListQuery.data?.getDonorsList.data as DonorOutput[]) || [];

  const individualDonorListConfig = {
    pagination,
    sort: sort?.[0],
    donorList,
    setSort,
  };

  const handleDaycareSwitch = (e: RadioChangeEvent) => {
    const value = e.target.value;
    const isDaycareFilterValue = value === DaycareSwitcherValue.Daycare || null;
    donorListFilters.setFilterOption({
      fieldName: 'isDaycare',
      value: isDaycareFilterValue,
    });
    setDaycareSwitchValue(value);
  };

  const navigate = useNavigate();

  const navigateTo = (route: Routes) => () => navigate(route);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setDaycareSwitchValue(DaycareSwitcherValue.All);
    setSearchInputValue(searchValue);
    pagination.moveTo(1);
    debounce(() => {
      donorListFilters.setFilterOption({
        fieldName: 'searchingQuery',
        value: searchValue,
      });
    })(searchValue);
  };

  useEffect(() => {
    return clearDebounce;
  }, []);

  useEffect(() => {
    if (
      !setSearchInput.current &&
      donorListFilters?.getFilterObject().searchingQuery
    ) {
      setSearchInputValue(
        donorListFilters?.getFilterObject().searchingQuery || ''
      );
      setSearchInput.current = true;
    }
    if (
      !setDaycareRadio.current &&
      donorListFilters?.getFilterObject().isDaycare
    ) {
      setDaycareSwitchValue(DaycareSwitcherValue.Daycare);
      setDaycareRadio.current = true;
    }
  }, [donorListFilters]);

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Spin spinning={getDonorsListQuery.loading}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={[16, 10]} justify="space-between" align="middle">
              <Col flex={1}>
                <SearchInput
                  onChange={handleSearch}
                  placeholder={t('searchDonor')}
                  maxLength={100}
                  value={
                    donorListFilters?.getFilterObject().searchingQuery
                      ? searchInputValue
                      : undefined
                  }
                />
              </Col>
              <Col>
                <Radio.Group
                  onChange={handleDaycareSwitch}
                  value={daycareSwitchValue}
                >
                  <Radio.Button value={DaycareSwitcherValue.All}>
                    {t('all')}
                  </Radio.Button>
                  <Radio.Button value={DaycareSwitcherValue.Daycare}>
                    {t('daycare')}
                  </Radio.Button>
                </Radio.Group>
              </Col>
              <Col>
                <Button onClick={() => donorsModal?.show()}>
                  {t('uploadCsv')}
                </Button>
              </Col>
              {!breakpoints.md && (
                <Col>
                  <IndividualDonorListFilter
                    donorListFilters={donorListFilters}
                    defaultValues={donorListFilters.getFilterObject()}
                  />
                </Col>
              )}

              {!breakpoints.md && (
                <Col>
                  <IndividualDonorListSort
                    defaultValue={donorListSort.options?.[0]} //TODO: dont forget ot change this default value if the list will allow using multi sorting.
                    donorListSort={donorListSort}
                  />
                </Col>
              )}
              {canUpdateDonors && (
                <Col>
                  <Button
                    type="primary"
                    onClick={navigateTo(Routes.CreateDonors)}
                  >
                    {t('addNewDonor')}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24}>
            {!isBreakpointsEmpty &&
              (breakpoints.md ? (
                <IndividualDonorListTable
                  {...individualDonorListConfig}
                  donorListSort={donorListSort}
                  donorListFilters={donorListFilters}
                />
              ) : (
                <IndividualDonorCardList {...individualDonorListConfig} />
              ))}
          </Col>
        </Row>
      </Spin>
      {donorsModal?.config && (
        <CsvDonorsUploadingModal {...donorsModal.config} />
      )}
    </ProfileSideMenuWrapper>
  );
};

export default IndividualDonorListPage;
