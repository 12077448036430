import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import { getTranslation } from 'utils/getTranslation';

type PropTypes = {
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
};

const t = getTranslation('donor.EditDonorForm.NoteSection');

export const NoteFileUploader: React.FC<PropTypes> = ({
  fileList,
  setFileList,
}) => {
  const handleFileChange = (data: UploadChangeParam<UploadFile>) =>
    setFileList(data.fileList);

  const beforeUpload = (file: RcFile) => {
    const isValidType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isValidType) {
      message.error(t('mustBeAllowedFormat'));
      return Upload.LIST_IGNORE;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('smallerThan', { size: `{2} mb` }));
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  return (
    <Upload
      beforeUpload={beforeUpload}
      fileList={fileList}
      onChange={handleFileChange}
      accept=".jpg,.jpeg,.png,.pdf"
      multiple
      showUploadList={false}
    >
      <Button>
        <UploadOutlined />
      </Button>
    </Upload>
  );
};
