import { Col, Layout, Row } from 'antd';
import AcceptInviteForm from 'modules/admin/components/AcceptInviteForm';
import { MainLayout } from 'modules/common/components';
import { FC } from 'react';

import styles from './styles.module.scss';

const AcceptInvitePage: FC = () => {
  return (
    <MainLayout>
      <Layout.Content className={styles.content}>
        <Row></Row>
        <Row justify="center" align="middle" className={styles.formContainer}>
          <Col flex="440px">
            <AcceptInviteForm />
          </Col>
        </Row>
      </Layout.Content>
    </MainLayout>
  );
};

export default AcceptInvitePage;
