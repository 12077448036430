import { Row } from 'antd';
import { Spin } from 'modules/common/components';
import EmailNotVerified from 'modules/user/components/EmailNotVerified';
import EmailVerified from 'modules/user/components/EmailVerified';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useConfirmEmailMutation } from 'types.d';

import styles from './styles.module.scss';

const EmailConfirmationPage: FC = () => {
  const { t } = useTranslation('user.EmailConfirmationPage');
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();
  const [confirmEmail, confirmEmailMutation] = useConfirmEmailMutation({
    onError: (error) => {
      const errorException = error.graphQLErrors?.[0]?.extensions
        ?.exception as any;
      setUserEmail(errorException?.response?.email);

      setErrorMessage(error.message);
    },
  });
  const isVerified = confirmEmailMutation.data?.confirmEmail;

  const confirmEmailHandler = async (code: string) => {
    await confirmEmail({
      variables: {
        input: {
          code,
        },
      },
    });
  };

  useEffect(() => {
    if (params.emailConfirmationCode && !confirmEmailMutation.called) {
      confirmEmailHandler(params.emailConfirmationCode);
    }
  }, []);

  return (
    <Spin
      size="large"
      spinning={confirmEmailMutation.loading}
      wrapperClassName={styles.spinWrapper}
    >
      <Row className={styles.container} justify="center" align="middle">
        {isVerified && !errorMessage && (
          <EmailVerified message={t('emailVerified')} />
        )}
        {errorMessage && !isVerified && (
          <EmailNotVerified message={errorMessage} userEmail={userEmail} />
        )}
      </Row>
    </Spin>
  );
};

export default EmailConfirmationPage;
