import { donationUnitSchema, actualSchema } from 'modules/donations/schemas';
import { DonationUnit } from 'types';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donation.CreateDonationForm.Quantity');

type QuantitySchemaType = {
  approvedDonationsMl: number;
  unit?: DonationUnit;
  actual?: number;
};

const quantitySchema: yup.SchemaOf<QuantitySchemaType> = yup.object().shape(
  {
    approvedDonationsMl: yup
      .number()
      .required(t('required', { field: t('expected') }))
      .min(0, t('enterValidValues'))
      .max(1000, t('enterValidValues'))
      .typeError(t('enterValidValues')),
    unit: donationUnitSchema,
    actual: actualSchema,
  },
  [['unit', 'actual']]
);

export { quantitySchema };
export type { QuantitySchemaType };
