import { LocationType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('user.EditLocationsForm');

export interface IAddLocationsFormSchema {
  name: string;
  type: LocationType;
  regionId: string;
}

export const addLocationsFormSchema: yup.SchemaOf<IAddLocationsFormSchema> =
  yup.object({
    name: yup
      .string()
      .max(100, t('lessThan', { max: 100 }))
      .strict(true)
      .trim(t('mustBeTrimmed'))
      .required(t('locationNameIsRequired')),
    type: yup
      .mixed<LocationType>()
      .oneOf(Object.values(LocationType))
      .required(),
    regionId: yup
      .string()
      .trim(t('mustBeTrimmed'))
      .required(t('locationNameIsRequired')),
  });
