export const DONATIONS_CSV_HEADERS = [
  { label: 'Donor ID', key: 'shortDonorId' },
  { label: 'Temperature', key: 'temperature' },
  { label: 'Pulse', key: 'pulse' },
  { label: 'Respiration', key: 'respiration' },
  { label: 'Capillary Refill Time', key: 'capillaryRefillTime' },
  { label: 'Mucous Membrane', key: 'mucousMembrane' },
  { label: 'Packed Cell Volume', key: 'packedCellVolume' },
  { label: 'Total Solids', key: 'totalSolids' },
  { label: 'Weight', key: 'weight' },
  { label: 'Working', key: 'working' },
  { label: 'Weather Temperature', key: 'weatherTemperature' },
  { label: 'Weather Types', key: 'weatherTypes' },
  { label: 'Should Donate', key: 'shouldDonate' },
  { label: 'Collection Set', key: 'collectionSet' },
  { label: 'Jugular Vein', key: 'jugularVein' },
  { label: 'Unit', key: 'unit' },
  { label: 'Actual', key: 'actual' },
  { label: 'Max Limit', key: 'maxLimit' },
  { label: 'Approved donations Ml', key: 'approvedDonationsMl' },
  { label: 'Preferences', key: 'preferences' },
  { label: 'Examination Notes', key: 'examinationNotes' },
  { label: 'Comment', key: 'comment' },
  { label: 'Donation Date', key: 'donationDate' },
  {
    label: 'Number of Collection Units Used',
    key: 'numberOfCollectionUnitsUsed',
  },
  { label: 'Vein Used', key: 'veinUsed' },
  { label: 'Vascular Structure', key: 'vascularStructure' },
  { label: 'Flow Rate', key: 'flowRate' },
  { label: 'Donation Position', key: 'donationPosition' },
  { label: 'Holders Position', key: 'holdersPosition' },
  { label: 'Donor Movement', key: 'donorMovement' },
  { label: 'Type of Movement', key: 'typeOfMovement' },
  { label: 'Emotional Support', key: 'emotionalSupport' },
  { label: 'Dislikes', key: 'dislikes' },
  { label: 'Likes', key: 'likes' },
  { label: 'Treat Preferences', key: 'treatPreferences' },
  { label: 'Primary Holder ID', key: 'shortPrimaryHolderId' },
  { label: 'Secondary Holder ID', key: 'shortSecondaryHolderId' },
  { label: 'Poker ID', key: 'shortPokerId' },
] as const;

export const REQUIRED_CSV_HEADER_SYMBOL = '*';
