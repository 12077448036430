import { Radio, Col, Row, message } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { Button, Spin, SearchInput } from 'modules/common/components';
import Link from 'modules/common/components/Link';
import { useSort, useDebounce, useFilter } from 'modules/common/hooks';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import OwnerListTable from 'modules/owner/components/OwnerListTable';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PermissionsEnum,
  UserListSort,
  useGetOwnersListQuery,
  UserOutput,
  OwnersListFilterInput,
  UserRole,
} from 'types.d';

enum DonorStatus {
  Active,
  Deleted,
}

const OwnerListPage: FC = () => {
  const { t } = useTranslation('owner.OwnerListPage');
  const currentUser = useCurrentUser();
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const [deletedSwitchValue, setDeletedSwitchValue] = useState(
    DonorStatus.Active
  );
  const setSearchInput = useRef(false);
  const setDeletedRadio = useRef(false);
  const isUpdateOwnersDataAllowed = currentUser.isCan(
    PermissionsEnum.UpdateOwnersData
  );
  const isAdmin = currentUser.data?.role.value === UserRole.Admin;
  const pagination = usePagination();
  const sort = useSort<UserListSort>();
  const ownersFilters = useFilter<OwnersListFilterInput>();

  const getUsersListQuery = useGetOwnersListQuery({
    variables: {
      input: {
        filter: ownersFilters.getFilterObject(),
        take: config.DEFAULT_LIMIT,
        skip: pagination.skip,
        sort: sort.options,
      },
    },
    onCompleted: (data) => {
      pagination.setTotal(data.getOwnersList.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const ownerList = getUsersListQuery.data?.getOwnersList.data as UserOutput[];
  const ownerListConfig = {
    pagination,
    ownerList,
    sorting: sort,
  };

  const { debounce } = useDebounce();
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    pagination.moveTo(1);
    debounce(() => {
      ownersFilters.setFilterOption({
        fieldName: 'searchingQuery',
        value: searchValue,
      });
    })(searchValue);
  };

  const handleDeletedUsersFilter = (value: number) => {
    const showDeletedRadioValue = value === DonorStatus.Deleted;
    const switchPosition = showDeletedRadioValue
      ? DonorStatus.Deleted
      : DonorStatus.Active;
    setDeletedSwitchValue(switchPosition);
    ownersFilters.setFilterOption({
      fieldName: 'isDeleted',
      value: showDeletedRadioValue,
    });
  };

  const showDeletedColumn =
    deletedSwitchValue === DonorStatus.Deleted && isAdmin;

  useEffect(() => {
    if (
      !setSearchInput.current &&
      ownersFilters?.getFilterObject().searchingQuery
    ) {
      setSearchInputValue(
        ownersFilters?.getFilterObject().searchingQuery || ''
      );
      setSearchInput.current = true;
    }

    if (
      !setDeletedRadio.current &&
      ownersFilters?.getFilterObject().isDeleted
    ) {
      handleDeletedUsersFilter(DonorStatus.Deleted);
      setDeletedRadio.current = true;
    }
  }, [ownersFilters]);

  return (
    <ProfileSideMenuWrapper title={t('title')}>
      <Spin spinning={getUsersListQuery.loading}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={[16, 10]} justify="space-between" align="middle">
              <Col flex={1}>
                <SearchInput
                  onChange={handleSearch}
                  value={searchInputValue}
                  placeholder={t('searchOwner')}
                  maxLength={100}
                />
              </Col>
              {isAdmin && (
                <Col>
                  <Radio.Group
                    defaultValue={DonorStatus.Active}
                    onChange={(e) => handleDeletedUsersFilter(e.target.value)}
                    value={deletedSwitchValue}
                  >
                    <Radio.Button value={DonorStatus.Active}>
                      {t('active')}
                    </Radio.Button>
                    <Radio.Button value={DonorStatus.Deleted}>
                      {t('deleted')}
                    </Radio.Button>
                  </Radio.Group>
                </Col>
              )}
              {isUpdateOwnersDataAllowed && (
                <Col>
                  <Button type="primary">
                    <Link to={Routes.CreateOwner}>{t('addNewOwner')}</Link>
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24}>
            <OwnerListTable
              {...ownerListConfig}
              showDeletedColumn={showDeletedColumn}
              ownersFilters={ownersFilters}
            />
          </Col>
        </Row>
      </Spin>
    </ProfileSideMenuWrapper>
  );
};

export default OwnerListPage;
