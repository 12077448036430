import { ColumnsType } from 'antd/es/table/Table';
import { SortOrder } from 'antd/lib/table/interface';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Link } from 'modules/common/components';
import RestrictedLink from 'modules/common/components/RestrictedLink';
import { ISortOption } from 'modules/common/interfaces';
import { DonationDateFilters } from 'modules/donor/types';
import {
  BloodType,
  DonorListSort,
  DonorOutput,
  DonorStatus,
  DonorLastDonationIntervalInput,
  DonorType,
  DonorListFilterInput,
  Order,
  PermissionsEnum,
  LocationOutput,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.IndividualDonorListTable');

const statuses = Object.values(DonorStatus);
const donationDateFilters = Object.values(DonationDateFilters);
const donorTypes = Object.values(DonorType);
export const donationDateIntervals: Record<
  DonationDateFilters,
  DonorLastDonationIntervalInput
> = {
  [DonationDateFilters.from0to24daysFilter]: {
    from: 0,
    to: 24,
  },
  [DonationDateFilters.from24to29daysFilter]: {
    from: 24,
    to: 29,
  },
  [DonationDateFilters.moreThan30daysFilter]: {
    from: 30,
    to: 365,
  },
};
const bloodTypes = Object.values(BloodType);

type Props = {
  showFilters: boolean;
  showSorters: boolean;
  locations?: LocationOutput[];
  filter?: DonorListFilterInput;
  sort?: ISortOption<DonorListSort>;
};

export const getColumns = ({
  showSorters,
  showFilters,
  filter,
  sort,
  locations,
}: Props): ColumnsType<DonorOutput> => {
  filter?.lastDonationInterval;
  const defaultLastDonationInterval = Object.keys(donationDateIntervals).filter(
    (key) => {
      const { to } = donationDateIntervals[key as DonationDateFilters];
      const isSomeIntervalEdgeNotExist =
        typeof filter?.lastDonationInterval?.from !== 'number' ||
        typeof filter?.lastDonationInterval?.to !== 'number';
      if (isSomeIntervalEdgeNotExist) {
        return false;
      }
      const defaultValue = filter?.lastDonationInterval?.to as number;
      return to <= defaultValue;
    }
  );

  const getDefaultSort = (fieldName: DonorListSort): SortOrder | undefined => {
    if (sort?.fieldName === fieldName) {
      return sort.order === Order.Asc ? 'ascend' : 'descend';
    }
    return undefined;
  };

  const locationFilter = locations?.map((location) => ({
    value: location.name,
    text: location.name,
  }));

  return [
    {
      title: t('donorId'),
      render: (row: DonorOutput) => row.shortId,
    },
    {
      title: t('petName'),
      key: DonorListSort.Name,
      sorter: showSorters,
      defaultSortOrder: getDefaultSort(DonorListSort.Name),
      render: (donor: DonorOutput) => (
        <Link
          to={`${Routes.DonorsProfile.replace(':id', String(donor.shortId))}`}
        >
          {donor.name}
        </Link>
      ),
    },
    {
      title: t('ownerLastName'),
      key: DonorListSort.LastName,
      sorter: showSorters,
      defaultSortOrder: getDefaultSort(DonorListSort.LastName),
      render: (row: DonorOutput) =>
        row.user && (
          <RestrictedLink
            to={`${Routes.EditOwner.replace(':id', String(row.user.shortId))}`}
            permissions={[PermissionsEnum.ViewTheListOfOwners]}
          >
            {row.user.lastName}
          </RestrictedLink>
        ),
    },
    {
      title: t('bloodType'),
      render: (bloodType: BloodType) => bloodType && t(bloodType.toLowerCase()),
      dataIndex: 'bloodType',
      key: 'bloodType',
      defaultFilteredValue: filter?.bloodType,
      filteredValue: filter?.bloodType || null,
      filters: showFilters
        ? bloodTypes.map((bloodType) => {
            return {
              text: t(bloodType.toLowerCase()),
              value: bloodType,
            };
          })
        : undefined,
    },
    {
      title: t('status'),
      render: (status: DonorStatus) =>
        t(status.charAt(0).toLowerCase() + status.slice(1)),
      dataIndex: 'status',
      key: 'status',
      defaultFilteredValue: filter?.statuses,
      filteredValue: filter?.statuses || null,
      filters: showFilters
        ? statuses.map((status) => {
            return {
              text: t(status.charAt(0).toLowerCase() + status.slice(1)),
              value: status,
            };
          })
        : undefined,
    },
    {
      title: t('lastDonationDate'),
      dataIndex: 'lastDonationDate',
      key: 'lastDonationDate',
      defaultFilteredValue: defaultLastDonationInterval,
      filteredValue: defaultLastDonationInterval || null,
      filters: showFilters
        ? donationDateFilters.map((filter) => {
            return {
              text: t(filter),
              value: filter,
            };
          })
        : undefined,
      render: (lastDonationDate: string | undefined) => {
        return lastDonationDate
          ? format(new Date(lastDonationDate), config.DATE_FORMAT)
          : '-';
      },
    },
    {
      title: t('location'),
      dataIndex: 'location',
      key: 'locationPreferences',
      filteredValue: filter?.locations || null,
      filterSearch: true,
      filters: showFilters ? locationFilter : undefined,
      render: (location: string) => {
        return location;
      },
    },
    {
      key: 'type',
      title: t('type'),
      render: (type: DonorType) => {
        return t(type.charAt(0).toLowerCase() + type.slice(1));
      },
      dataIndex: 'type',
      defaultFilteredValue: filter?.types,
      filteredValue: filter?.types || null,
      filters: showFilters
        ? donorTypes.map((type) => {
            return {
              text: t(type.charAt(0).toLowerCase() + type.slice(1)),
              value: type,
            };
          })
        : undefined,
    },
  ];
};
