import { Popconfirm, message } from 'antd';
import { FC, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProductOutput,
  ProductStatus,
  useDeleteProductMutation,
} from 'types.d';

type PropTypes = {
  product: ProductOutput;
  onDelete?: (affected: boolean) => void;
  children?: ReactNode;
};

const DeleteProductButton: FC<PropTypes> = ({
  children,
  product,
  onDelete,
}) => {
  const { t } = useTranslation(
    'products.ProductDropdownActions.DeleteProductButton'
  );
  const ref = useRef<null>(null);
  const [isPopConfirmVisible, setIsPopConfirmVisible] = useState(false);
  const isProductCollected = product.status === ProductStatus.Collected;
  const [deleteProduct, deleteProductMutation] = useDeleteProductMutation({
    refetchQueries: ['getProducts'],
    variables: {
      input: {
        id: product.id,
      },
    },
    onCompleted: () => {
      message.success(t('productHasBeenDeleted'));
      onDelete?.(true);
    },
    onError: (error) => {
      message.error(error.message);
      onDelete?.(false);
    },
  });

  return (
    <Popconfirm
      ref={ref}
      title={t('sureToDeleteProduct')}
      onConfirm={async () => {
        await deleteProduct();
      }}
      onCancel={() => setIsPopConfirmVisible(false)}
      visible={isPopConfirmVisible}
      onVisibleChange={setIsPopConfirmVisible}
      okButtonProps={{
        loading: deleteProductMutation.loading,
        title: t('yes'),
      }}
      cancelText={t('cancel')}
      disabled={!isProductCollected}
    >
      {children || t('deleteProduct')}
    </Popconfirm>
  );
};

export default DeleteProductButton;
