import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { message } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Routes } from 'config/routes';
import { Spin } from 'modules/common/components';
import { getPictureUrl } from 'modules/donor/components/DonorPicturesList/utils';
import { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
  DonationOutput,
  useGetDonationQuery,
  useUpdateDonationDonorMutation,
  useUpdateDonationMutation,
  WeatherType,
  FileOutput,
} from 'types.d';

import DesktopEditDonationFormView from './DesktopEditDonationFormView';
import { mapDefaultValues } from './mapDefaultValues';
import MobileEditDonationFormView from './MobileEditDonationFormView';
import { editDonationSchema, EditDonationSchemaType } from './schema';

const EditDonationForm: FC = () => {
  const { t } = useTranslation('donation.EditDonationForm');
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();
  const params = useParams();
  const donationShortId = params.id || '';
  const [formEnabled, setFormEnabled] = useState<boolean>(false);
  const [pictureUrl, setPictureUrl] = useState<string>('');
  const [isIneligible, setIneligible] = useState<boolean>(false);

  const editDonationForm = useForm<EditDonationSchemaType>({
    resolver: isIneligible ? undefined : yupResolver(editDonationSchema),
    mode: 'onChange',
  });

  const ineligible = editDonationForm.watch('examination.ineligible');
  const ineligibleReason = editDonationForm.watch(
    'examination.ineligibleReason'
  );
  const isFormValid = isIneligible ? true : editDonationForm.formState.isValid;

  const { data: donationData, loading: loadingDonationData } =
    useGetDonationQuery({
      variables: {
        input: {
          id: donationShortId,
        },
      },
      onCompleted: (data) => {
        const defaultFormValues = mapDefaultValues(
          data.getDonation as DonationOutput
        );
        editDonationForm.reset(defaultFormValues);
      },
      onError() {
        navigate(Routes.NotFound);
      },
    });
  const donationUUID = donationData?.getDonation.id;
  const [editDonation, { loading: loadingEditingDonation }] =
    useUpdateDonationMutation({
      update(cache) {
        cache.evict({ fieldName: 'getProducts' });
      },
      onCompleted: () => {
        editDonationForm.reset();
        message.success(t('donationUpdated'));
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const [editDonationDonor, { loading: loadingEditingDonationDonor }] =
    useUpdateDonationDonorMutation({
      update(cache) {
        cache.evict({ fieldName: 'getProducts' });
        cache.evict({ fieldName: 'getSpecificDonorDonationsList' });
      },
      onCompleted: () => {
        editDonationForm.reset();
        message.success(t('donationDonorUpdated'));
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  useEffect(() => {
    const createPictureUrl = async () => {
      const donorPictureOutput = donationData?.getDonation.donor?.pictures;
      if (donorPictureOutput && donorPictureOutput.length > 0) {
        const url =
          donationData?.getDonation.donor?.pictures?.at(0)?.file &&
          (await getPictureUrl(donorPictureOutput.at(0)?.file as FileOutput));
        url && setPictureUrl(url);
      }
    };
    createPictureUrl();
  }, [donationData]);

  const essentialFormFields: (keyof EditDonationSchemaType)[] = [
    'donationInfo',
    'environment',
    'examination',
    'quantity',
    'donationDate',
  ];
  const isEssentialDataUpdated = essentialFormFields.some(
    (key) => editDonationForm.getFieldState(key).isDirty
  );
  const donorIdIsChanged = editDonationForm.getFieldState('donorId').isDirty;

  useEffect(() => {
    setIneligible(!!ineligible && !!ineligibleReason);
  }, [ineligible, ineligibleReason]);

  const saveHandler = editDonationForm.handleSubmit(
    async ({
      examination,
      donationInfo,
      environment,
      quantity,
      donorId,
      donationDate,
    }) => {
      const { species, ...restExaminationFields } = examination;
      if (isEssentialDataUpdated) {
        await editDonation({
          variables: {
            input: {
              donationDate,
              id: donationUUID as string,
              ...restExaminationFields,
              ...donationInfo,
              ...environment,
              ...quantity,
              ineligibleReason: ineligible ? ineligibleReason : null,
              weatherTypes: environment.weatherTypes as WeatherType[], //TODO: delete after fixing problem with validation schema of array of enums
            },
          },
        });
      }
      if (donorIdIsChanged) {
        editDonationDonor({
          variables: {
            input: {
              id: donationUUID as string,
              donorId,
            },
          },
        });
      }
      setFormEnabled(false);
    }
  );

  const closeHandler = () => {
    const donation = donationData?.getDonation as DonationOutput | undefined;
    const defaultFormValues = donation ? mapDefaultValues(donation) : undefined;
    editDonationForm.reset(defaultFormValues);
    setFormEnabled(false);
  };

  const editHandler = () => {
    setFormEnabled(!formEnabled);
    const donation = donationData?.getDonation as DonationOutput | undefined;
    const defaultFormValues = donation ? mapDefaultValues(donation) : undefined;
    editDonationForm.reset(defaultFormValues);
  };

  const loading =
    loadingEditingDonation ||
    loadingDonationData ||
    loadingEditingDonationDonor;

  return (
    <Spin spinning={loading}>
      <FormProvider {...editDonationForm}>
        {breakpoint.xl && (
          <DesktopEditDonationFormView
            isFormValid={isFormValid}
            formEnabled={formEnabled}
            closeHandler={closeHandler}
            saveHandler={saveHandler}
            editHandler={editHandler}
            pictureUrl={pictureUrl}
            donationId={donationShortId}
          />
        )}
        {!breakpoint.xl && (
          <MobileEditDonationFormView
            isFormValid={isFormValid}
            formEnabled={formEnabled}
            closeHandler={closeHandler}
            saveHandler={saveHandler}
            editHandler={editHandler}
            pictureUrl={pictureUrl}
            donationId={donationShortId}
          />
        )}
      </FormProvider>
    </Spin>
  );
};

export default EditDonationForm;
