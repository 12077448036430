import { ControlledSelect } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { animalSpecies } from './options';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const PetSpeciesSelect: FC<PropTypes> = (props) => {
  const { t } = useTranslation('donor.ControlledPetSpeciesSelect');

  return (
    <ControlledSelect
      adjustDropdownPosition
      options={animalSpecies}
      placeholder={t('pickSpecies')}
      {...props}
    />
  );
};

export default PetSpeciesSelect;
