import colors from 'config/colors.scss';
import { DonationOutput } from 'types';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.RespirationChart');

export const getConfig = (donationData: DonationOutput[]) => {
  return {
    data: donationData,
    xField: 'donationDate',
    yField: 'respiration',
    color: colors.pastelGreen,
    xAxis: {
      title: {
        text: t('date'),
      },
    },
    yAxis: {
      title: {
        text: t('respiration'),
      },
      alias: t('respiration'),
    },
  };
};
