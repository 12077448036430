import TextArea from 'modules/common/components/TextArea';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof TextArea>;

const ControlledTextArea: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);
  return <TextArea {...controller.field} {...restProps} />;
};

export default ControlledTextArea;
