export enum AppointmentFormField {
  Type = 'type',
  Title = 'title',
  Timeslots = 'timeslots',
  Split = 'split',
  DonorId = 'donorId',
  SelectedGroup = 'selectedGroup',
  TeamId = 'teamId',
  Location = 'location',
  UserIds = 'userIds',
  Dates = 'dates',
  DonorStatus = 'donorStatus',
  Notes = 'notes',
  DaycareDonorsIds = 'daycareDonorsIds',
  IsPrivate = 'isPrivate',
}
