import { Row, Col } from 'antd';
import { getOptions } from 'modules/donor/components/DonorDonationCardInfo/options';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { DonationOutput, PermissionsEnum } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  donation: DonationOutput;
  addCommentHandler: (donationId: string, previousValue?: string) => void;
  productCategories?: Record<number, string>;
};

const DonorDonationCardInfo: FC<PropTypes> = (props) => {
  const currentUser = useCurrentUser();
  const canAddCommentsToDonation = currentUser.isCan(
    PermissionsEnum.AddCommentsToTheDonationAtTheDonorProfile
  );

  return (
    <Row gutter={[10, 0]}>
      {getOptions({ ...props, canAddCommentsToDonation }).map(
        ({ label, value }, i) => {
          return (
            <Col span={24} key={`${props.donation?.id}_${i}`}>
              <Row gutter={[10, 0]}>
                <Col span={12} className={styles.optionLabel}>
                  {label}:
                </Col>
                <Col span={12}>{value}</Col>
              </Row>
            </Col>
          );
        }
      )}
    </Row>
  );
};

export default DonorDonationCardInfo;
