import { Select } from 'antd';
import { options } from 'modules/donor/components/LabworkStatusSelect/options';
import { ComponentProps, FC } from 'react';
import { LabworkStatus } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  onSelect: (value: LabworkStatus) => void;
} & Omit<ComponentProps<typeof Select>, 'options' | 'onSelect'>;

const LabworkStatusSelect: FC<PropTypes> = ({ onSelect, ...restProps }) => {
  const currentValue = options?.find(
    ({ value }) => value === restProps.defaultValue
  );

  return (
    <Select
      {...restProps}
      options={options}
      className={styles.select}
      onSelect={(value: any) => onSelect(value)}
      filterOption={false}
      defaultActiveFirstOption={false}
      value={currentValue}
    />
  );
};

export default LabworkStatusSelect;
