import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Col, Form, message, Row } from 'antd';
import { Button, Spin } from 'modules/common/components';
import { GET_CURRENT_USER_QUERY } from 'modules/user/graphql/queries/user';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateCurrentOwnerInfoMutation } from 'types.d';

import AdditionalContactsSection from './AdditionalContactsSection';
import PersonalInfoSection from './PersonalInfoSection';
import { EditProfileFormSchemaType, editProfileFormSchema } from './schema';

const EditProfileForm: FC = () => {
  const { t } = useTranslation('owner.EditProfileForm');
  const currentUser = useCurrentUser();
  const [isEditMode, setIsEditMode] = useState(false);
  const userContact = currentUser.data?.contacts![0];
  const editProfileFrom = useForm<EditProfileFormSchemaType>({
    resolver: yupResolver(editProfileFormSchema),
    mode: 'onChange',
  });

  const [updateOwnerInfo, updateOwnerInfoMutation] =
    useUpdateCurrentOwnerInfoMutation({
      refetchQueries: ['currentUser'],
      onCompleted: () => {
        message.success(t('saved'));
        setIsEditMode(false);
      },
      onError: (error) => message.error(error.message),
    });

  useEffect(() => {
    resetValues();
  }, [currentUser]);

  const isLoading = updateOwnerInfoMutation.loading;

  const resetValues = () => {
    editProfileFrom.reset({
      personalInfo: {
        firstName: currentUser.data?.firstName,
        lastName: currentUser.data?.lastName,
        phoneNumber: currentUser.data?.phoneNumber,
        streetAddress: currentUser.data?.streetAddress,
        city: currentUser.data?.city,
        state: currentUser.data?.state,
        zipCode: currentUser.data?.zipCode,
        locationPreferences: currentUser.data?.locationPreferences,
        birthday: currentUser.data?.birthday
          ? new Date(currentUser.data?.birthday)
          : undefined,
        contactPreferences: currentUser.data?.contactPreferences,
      },
      contact: {
        firstName: userContact?.firstName,
        lastName: userContact?.lastName,
        email: userContact?.email,
        streetAddress: userContact?.streetAddress,
        city: userContact?.city,
        state: userContact?.state,
        zipCode: userContact?.zipCode,
        phoneNumber: userContact?.phoneNumber,
      },
    });
  };

  const closeHandler = () => {
    setIsEditMode(false);
    resetValues();
  };

  const editHandler = () => {
    editProfileFrom.trigger();
    setIsEditMode(true);
  };

  const saveHandler = editProfileFrom.handleSubmit(
    ({ contact, personalInfo }) => {
      const personalInfoState = editProfileFrom.getFieldState('personalInfo');
      const contactState = editProfileFrom.getFieldState('contact');

      if (personalInfoState.isDirty || contactState.isDirty) {
        updateOwnerInfo({
          refetchQueries: [GET_CURRENT_USER_QUERY],
          variables: {
            input: {
              id: currentUser.data!.id,
              ...personalInfo,
              additionalContact: {
                ...contact,
                id: userContact?.id,
              },
            },
          },
        });
      }
    }
  );

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...editProfileFrom}>
        <Row>
          <Col span={24}>
            <Form>
              <Row justify="space-between">
                <Col span={10}>
                  <PersonalInfoSection disabled={!isEditMode} />
                </Col>
                <Col span={10}>
                  <AdditionalContactsSection disabled={!isEditMode} />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Button onClick={closeHandler} disabled={!isEditMode}>
                  {t('close')}
                </Button>
              </Col>
              <Col>
                {isEditMode ? (
                  <Button
                    type="primary"
                    disabled={!editProfileFrom.formState.isValid}
                    onClick={saveHandler}
                  >
                    {t('save')}
                  </Button>
                ) : (
                  <Button onClick={editHandler} type="primary">
                    {t('edit')}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </FormProvider>
    </Spin>
  );
};

export default EditProfileForm;
