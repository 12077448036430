import { getMinutes, getHours, set as setDateMeasurements } from 'date-fns';
import DatePicker from 'modules/common/components/DatePicker';
import { RangeValue } from 'rc-picker/lib/interface';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
  startDate?: Date;
  adjustDropdownPosition?: boolean;
} & ComponentProps<typeof DatePicker.RangePicker>;

const TimeRangePicker: FC<PropTypes> = ({
  controlled,
  startDate,
  adjustDropdownPosition,
  ...restProps
}) => {
  const controller = useController(controlled);
  const { onChange, ...restControllerField } = controller.field;
  const onCalendarChange = (dates: RangeValue<Date>) => {
    const startTime = dates?.[0];
    const endTime = dates?.[1];
    const isTimeSelected = (startTime || endTime) && startDate;

    if (!isTimeSelected) {
      return controller.field.onChange(dates);
    }

    const start =
      startTime &&
      setDateMeasurements(startDate, {
        hours: getHours(startTime),
        minutes: getMinutes(startTime),
        seconds: 0,
        milliseconds: 0,
      });
    const end =
      endTime &&
      setDateMeasurements(startDate, {
        hours: getHours(endTime),
        minutes: getMinutes(endTime),
        seconds: 0,
        milliseconds: 0,
      });

    controller.field.onChange([start, end]);
  };

  const getPopupContainer = (triggerNode: any) => {
    return triggerNode.parentNode;
  };

  return (
    <DatePicker.RangePicker
      getPopupContainer={adjustDropdownPosition ? getPopupContainer : undefined}
      picker="time"
      className={styles.timeRangePicker}
      {...restProps}
      {...restControllerField}
      onCalendarChange={onCalendarChange}
    />
  );
};

export default TimeRangePicker;
