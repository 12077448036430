import { Table, TableProps, message } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import config from 'config';
import { Spin } from 'modules/common/components';
import { useSort } from 'modules/common/hooks/useSort';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import {
  DonorOutput,
  Order,
  useGetCurrentUserDonorsListQuery,
  UserDonorListSort,
} from 'types.d';

import { columns } from './columns';

const OwnerDonorsTable = () => {
  const pagination = usePagination();
  const donorListSort = useSort<UserDonorListSort>();
  const getDonorListQuery = useGetCurrentUserDonorsListQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        sort: donorListSort.options,
      },
    },
    onCompleted: ({ getCurrentUserDonorsList }) => {
      pagination.setTotal(getCurrentUserDonorsList.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const donorList = getDonorListQuery.data?.getCurrentUserDonorsList
    .data as DonorOutput[];

  const onTableChange: TableProps<DonorOutput>['onChange'] = (
    _,
    __,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<DonorOutput>;
      const fieldName = tableSorter.columnKey as UserDonorListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        donorListSort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        donorListSort.removeSortOption(fieldName);
      }
    }
  };

  return (
    <Spin spinning={getDonorListQuery.loading}>
      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={donorList}
        scroll={{ x: 750 }}
        onChange={onTableChange}
        pagination={pagination.config}
      />
    </Spin>
  );
};

export default OwnerDonorsTable;
