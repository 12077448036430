import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { PermissionsEnum } from 'types.d';

import AcceptInvitePage from './pages/AcceptInvite';
import EditUserPage from './pages/EditUser';
import InvitePage from './pages/Invite';
import PermissionsPage from './pages/Permissions';
import UsersPage from './pages/Users';

const adminModule: IModule = {
  routes: [
    {
      path: Routes.InviteUsers,
      Component: InvitePage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.AddUsers],
    },
    {
      path: Routes.UsersPermissions,
      Component: PermissionsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ManagePermissions],
    },
    {
      path: Routes.AcceptInvite,
      Component: AcceptInvitePage,
      type: RouteType.Public,
    },
    {
      path: Routes.Users,
      Component: UsersPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewUsersList],
    },
    {
      path: Routes.EditUsers,
      Component: EditUserPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.UpdateUserInfo],
    },
  ],
};

export default adminModule;
