import { Col, Row } from 'antd';
import { CreateDonationForm } from 'modules/donations/components';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CreateDonationPage: FC = () => {
  const { t } = useTranslation('donation.CreateDonationPage');

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Row>
        <Col span={24}>
          <CreateDonationForm />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default CreateDonationPage;
