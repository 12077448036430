import { Col, Row } from 'antd';
import config from 'config';
import { DefaultClinicParams } from 'config/default-clinic-params';
import { format } from 'date-fns';
import { RoutineTestFormSchemaType } from 'modules/donor/components/RoutineTestForm/schema';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

import CheckboxGroup from '../../../components/CheckboxGroup';
import FormInput from '../../../components/Input';

const t = getTranslation('donor.FormsToPrint');

const receiveOptions = [t('pickUp'), t('fax'), t('email')];

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};

export default function ContactSection({ data }: PropTypes) {
  return (
    <div className={styles.contactsSection}>
      <Row>
        <Col span={10}>
          <label className={styles.radioButtonLabel}>{t('checkOne')}</label>
          <CheckboxGroup options={receiveOptions} active={t('email')} />
        </Col>
        <Col span={14} className={styles.inputWrapper}>
          <b>
            <label>{t('reportTo')}</label>
          </b>
          <input className={styles.input} value={data.email} />
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <FormInput label={t('veterinarian')} value={data.veterinarian} />
          <FormInput label={t('clinicName')} value={data.clinicName} />
          <FormInput label={t('addressLabel')} value={data.clinicAddress} />
          <FormInput label={t('cityStateZipLabel')} value={data.CityStateZip} />
          <FormInput label={t('phone')} value={data.telephone} />
        </Col>
        <Col span={13} offset={1}>
          <Row>
            <Col span={15}>
              <FormInput label={t('animalID')} value={data.animalName} />
            </Col>
            <Col span={9}>
              <FormInput
                label={t('date')}
                value={format(data.collectionDate, config.DATE_FORMAT)}
              />
            </Col>
          </Row>
          <FormInput label={t('owner')} value={data.ownerLastName} />
          <FormInput label={t('addressLabel')} />
          <FormInput label={t('cityStateZipLabel')} />
          <FormInput label={t('county')} />
        </Col>
        <Row className={styles.petInfo}>
          <FormInput label={t('specimen')} value={data.specimen} />
          <FormInput label={t('species')} value={data.species} />
          <FormInput label={t('breed')} value={data.breed} />
          <FormInput label={t('sex')} value={data.gender} />
          <FormInput label={t('age')} value={`${data.age}`} />
        </Row>
      </Row>
    </div>
  );
}
