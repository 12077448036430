import {
  additionalDonationInfoSchema,
  AdditionalDonationInfoSchema,
} from 'modules/donations/components/AdditionalDonationInfoForm/schema';
import {
  vitalsSectionSchema,
  VitalsSectionSchemaSchemaType,
} from 'modules/donations/components/CreateDonationForm/VitalsSection/schema';
import * as yup from 'yup';

type CreateDonationSchemaType = {
  vitalsSection: VitalsSectionSchemaSchemaType;
  additionalInfoSection: AdditionalDonationInfoSchema;
};

const createDonationSchema: yup.SchemaOf<CreateDonationSchemaType> = yup.object(
  {
    vitalsSection: vitalsSectionSchema,
    additionalInfoSection: additionalDonationInfoSchema,
  }
);

export { createDonationSchema };
export type { CreateDonationSchemaType };
