import { ListTable } from 'modules/common/components';
import { ListTableDataType } from 'modules/common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerOutput } from 'types';

type PropTypes = {
  customer: CustomerOutput;
};

const CustomerDetailsTable: FC<PropTypes> = ({ customer }) => {
  const { t } = useTranslation('orders.CustomerDetailsTable');
  const { firstName, lastName, email, shipping } = customer;
  const dateSource: ListTableDataType[] = [
    {
      key: t('customerName'),
      value: `${firstName} ${lastName}`,
    },
    {
      key: t('address'),
      value: `${shipping.company} ${shipping.firstAddress} ${shipping.city} ${shipping.state} ${shipping.postcode}`,
    },
    // Disabled code
    // {
    //   key: t('city'),
    //   value: 'New York',
    // },
    // {
    //   key: t('zip'),
    //   value: '12345',
    // },
    // {
    //   key: t('contact'),
    //   value: '(123)-456-7890',
    // },
    { key: t('email'), value: email || '-' },
  ];

  return <ListTable data={dateSource} />;
};

export default CustomerDetailsTable;
