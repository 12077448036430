import { Badge, BadgeProps } from 'antd';
import { ComponentWithChildren } from 'modules/common/types';

import styles from './styles.module.scss';

type PropTypes = {
  hidden?: boolean;
} & BadgeProps;

const BadgeAsterisk: ComponentWithChildren<PropTypes> = ({
  children,
  className,
  hidden,
  ...restProps
}) => {
  const classNames = [styles.badge, className || ''].join(' ');
  return (
    <Badge
      offset={[5, 0]}
      className={classNames}
      count={hidden ? undefined : <span className={styles.asterisk}>*</span>}
      {...restProps}
    >
      {children}
    </Badge>
  );
};

export default BadgeAsterisk;
