/* eslint-disable unused-imports/no-unused-imports */
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Row, Col, Form, message } from 'antd';
import config from 'config';
import { Spin, FormItem } from 'modules/common/components';
import RoutineTest from 'modules/donor/components/RoutineTest';
import { routineTestFormFields } from 'modules/donor/components/RoutineTestForm/fields';
import {
  RoutineTestFormSchemaType,
  routineTestFormSchemaType,
} from 'modules/donor/components/RoutineTestForm/schema';
import { RoutineTestGender } from 'modules/donor/components/RoutineTestGenderSelect/enum';
import {
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  useGetDonorQuery,
  DonorOutput,
  useCreateLabworkMutation,
  AnimalGender,
} from 'types.d';

type Props = {
  donorId?: string;
  hide: () => void;
  setIsFormValid: (isValid: boolean) => void;
};

export const RoutineTestForm = forwardRef(
  ({ donorId: passedDonorId, hide, setIsFormValid }: Props, ref) => {
    const { t } = useTranslation('donor.RoutineTestForm');
    const params = useParams();
    const incomingDonorId = passedDonorId ? passedDonorId : params.id || '';
    const testRef = useRef(null);

    const { data, loading: getDonorLoading } = useGetDonorQuery({
      variables: {
        input: {
          id: incomingDonorId,
        },
      },
    });
    const donorId = data?.getDonor.id;
    const handlePrint = useReactToPrint({
      content: () => testRef.current,
      documentTitle: `Routine_tests_${incomingDonorId}`,
    });
    const formFields = useMemo(() => routineTestFormFields, [donorId]);

    const createLabworkForm = useForm<RoutineTestFormSchemaType>({
      resolver: yupResolver(routineTestFormSchemaType),
      mode: 'onChange',
    });

    setIsFormValid(createLabworkForm.formState.isValid);

    const [createLabwork, { loading: createLabworkLoading }] =
      useCreateLabworkMutation({
        refetchQueries: ['getLabworkList'],
        onCompleted: () => {
          message.success(t('labworkHasBeenSuccessfullyCreated'));
        },
        onError: (error) => {
          message.error(error.message);
        },
      });

    const convertDonorGender = (donor: DonorOutput) => {
      return donor.gender === AnimalGender.Male
        ? donor.neutered
          ? RoutineTestGender.MN
          : RoutineTestGender.M
        : donor.neutered
        ? RoutineTestGender.FS
        : RoutineTestGender.F;
    };

    useEffect(() => {
      const donor = data?.getDonor as DonorOutput | undefined;
      if (donor) {
        createLabworkForm.reset({
          animalName: donor.name,
          species: donor.species,
          breed: donor.breed || '',
          age: donor.age,
          gender: convertDonorGender(donor),
          ownerLastName: donor.user?.lastName,
          patientID: donor.shortId.toString(),
          veterinarian: config.DEFAULT_VETERINARIAN,
          donorId: donor.id,
        });
      }
    }, [data]);

    useImperativeHandle(ref, () => ({
      createAndPrintHandler: createLabworkForm.handleSubmit((formData) => {
        createLabwork({
          variables: {
            input: {
              donorId: formData.donorId,
              collectionDate: formData.collectionDate,
            },
          },
        });
        handlePrint();
        hide();
      }),
    }));

    const isLoading = createLabworkLoading || getDonorLoading;

    return (
      <Spin spinning={isLoading}>
        <FormProvider {...createLabworkForm}>
          <Form labelCol={{ span: 12 }}>
            <Row>
              <Col span={24}>
                {formFields.map(({ Component, label, name }) => {
                  const errorMessage =
                    createLabworkForm.formState.errors?.[name]?.message;
                  return (
                    <Col span={24} key={name}>
                      <FormItem
                        label={label}
                        extra={errorMessage}
                        validateStatus={errorMessage && 'error'}
                      >
                        <Component
                          control={createLabworkForm.control}
                          name={name}
                        />
                      </FormItem>
                    </Col>
                  );
                })}
              </Col>
            </Row>
          </Form>
        </FormProvider>
        <Col span={0}>
          <RoutineTest ref={testRef} data={createLabworkForm.getValues()} />
        </Col>
      </Spin>
    );
  }
);
