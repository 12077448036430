import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Alert, Col, Form, message, Row, Typography } from 'antd';
import config from 'config';
import { addDays, format } from 'date-fns';
import EditUserEmailForm from 'modules/admin/components/EditUserForm/EmailSection';
import { UserType } from 'modules/admin/components/EditUserForm/EmailSection/enum';
import { Button, FormItem, Spin } from 'modules/common/components';
import DeleteAction from 'modules/common/components/DeleteAction';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useRemoveCurrentOwnerMutation,
  useUpdateCurrentOwnerInfoMutation,
  UserOutput,
  useCancelDeletingCurrentOwnerMutation,
} from 'types.d';

import { fields } from './fields';
import { accountSchema, AccountSchema } from './schema';
import styles from './styles.module.scss';

const AccountForm: FC = () => {
  const { t } = useTranslation('owner.ProfilePage');
  const currentUser = useCurrentUser();
  const deletionIntentionAt = currentUser.data?.deletionIntentionAt
    ? new Date(currentUser.data?.deletionIntentionAt)
    : undefined;
  const { control, handleSubmit, formState, reset, setValue } =
    useForm<AccountSchema>({
      resolver: yupResolver(accountSchema),
      mode: 'onChange',
      defaultValues: {
        phoneNumber: currentUser.data?.phoneNumber || undefined,
      },
    });
  const [cancelAccountDeleting, cancelAccountDeletingMutation] =
    useCancelDeletingCurrentOwnerMutation({
      onCompleted: () => {
        message.success(t('accountDeletingCanceled'));
        currentUser.refetchData();
      },
      onError: (error) => {
        message.error(error.message);
      },
    });

  const [updateOwnerInfo, updateOwnerInfoMutation] =
    useUpdateCurrentOwnerInfoMutation({
      onCompleted: () => {
        message.success(t('successfullyUpdated'));
        setValue('password', '');
      },
      onError: (err) => {
        message.error(err.message);
      },
    });

  const [removeCurrentOwner, removeCurrentOwnerMutation] =
    useRemoveCurrentOwnerMutation({
      onCompleted: () => {
        message.success(t('requestOnDeletingSent'), 3);
        currentUser.refetchData();
      },
      onError: (err) => {
        message.error(err.message);
      },
    });
  const isLoading =
    updateOwnerInfoMutation.loading ||
    removeCurrentOwnerMutation.loading ||
    cancelAccountDeletingMutation.loading;

  const isSaveButtonDisabled =
    !formState.isValid || !formState.isDirty || isLoading;

  const saveHandler = handleSubmit(async (formData) => {
    if (!currentUser.data) {
      return;
    }

    await updateOwnerInfo({
      variables: {
        input: {
          id: currentUser.data?.id,
          ...formData,
        },
      },
    });

    reset(formData);
  });

  const calculateDeletionDate = (date: Date) => {
    const newDate = addDays(date, date.getHours() > 12 ? 6 : 5);
    newDate.setHours(12);
    newDate.setMinutes(0);

    return newDate;
  };

  const deleteHandler = () => {
    removeCurrentOwner();
  };
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[15, 15]}>
        {deletionIntentionAt && (
          <Col span={24}>
            <Alert
              showIcon
              type="warning"
              message={t('accountDeleted')}
              action={
                <Button onClick={() => cancelAccountDeleting()} type="ghost">
                  {t('cancelDeleting')}
                </Button>
              }
              description={t('accountWillBeDeleted', {
                deletingDate: format(
                  calculateDeletionDate(deletionIntentionAt),
                  config.DATE_AND_TIME_FORMAT
                ),
              })}
            />
          </Col>
        )}
        <Col span={24}>
          <EditUserEmailForm
            user={currentUser.data as UserOutput}
            canEdit
            userType={UserType.CurrentOwner}
          />
        </Col>
        <Col span={24}>
          <Form>
            {fields.map(({ label, name, Component }) => {
              const errorMessage =
                formState.errors[name as keyof AccountSchema]?.message;
              return (
                <FormItem
                  key={name}
                  label={label}
                  extra={errorMessage}
                  validateStatus={errorMessage && 'error'}
                >
                  <Component key={name} control={control} name={name} />
                </FormItem>
              );
            })}
          </Form>
        </Col>
        {!deletionIntentionAt && (
          <Col span={24}>
            <Typography.Text>{t('deleteYourAccount')}</Typography.Text>
            <DeleteAction
              deleteEntity={currentUser}
              yesDeleteHandler={deleteHandler}
              className={styles.deleteButton}
            />
          </Col>
        )}

        <Col span={24} className={styles.buttonsContainer}>
          <Button onClick={() => reset()}>{t('close')}</Button>
          <Button
            disabled={isSaveButtonDisabled}
            onClick={saveHandler}
            type="primary"
          >
            {t('save')}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default AccountForm;
