import { passwordSchema } from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { AcceptInviteFields } from './enum';

const { password, confirmPassword } = AcceptInviteFields;

const acceptInviteTranslations = getTranslation('admin.AcceptInvitePage');

type AcceptInviteSchema = Record<AcceptInviteFields, string>;

const acceptInviteSchema: yup.SchemaOf<AcceptInviteSchema> = yup.object({
  [password]: passwordSchema,
  [confirmPassword]: passwordSchema.oneOf(
    [yup.ref('password'), null],
    acceptInviteTranslations('passwordMustMatch')
  ),
});

export { acceptInviteSchema };
export type { AcceptInviteSchema };
