import { DONATIONS_CSV_HEADERS } from 'modules/donations/constants';
import {
  CollectionSet,
  CollectionUnitsUsed,
  DonationUnit,
  FlowRate,
  JugularVein,
  VascularStructure,
  VeinUsed,
  WeatherType,
  CapillaryRefillTime,
  Mm,
} from 'types.d';

import { Dislikes } from '../DislikesSelect/enum';
import { DonationPositionUsed } from '../DonationPositionSelect/enum';
import { DonorMovement } from '../DonorMovementSelect/enum';
import { EmotionalSupport } from '../EmotionalSupportSelect/enum';
import { HoldersPosition } from '../HolderPositionSelect/enum';
import { Likes } from '../LikesSelect/enum';
import { MovementType } from '../MovementTypeSelect/enum';
import { TreatPreferences } from '../TreatmentPreferencesSelect/enum';

type KeyType = typeof DONATIONS_CSV_HEADERS[number]['key'];

export const dataExample: Record<KeyType, string> = {
  shortDonorId: '10001', //TODO: change on short ID
  temperature: '100',
  pulse: '100',
  respiration: '100',
  capillaryRefillTime: CapillaryRefillTime.BetweenOneAndTwo,
  mucousMembrane: Mm.Pink,
  packedCellVolume: '90',
  totalSolids: '90',
  weight: '25',
  working: 'true',
  weatherTemperature: '100',
  weatherTypes: [WeatherType.Clear, WeatherType.Sunny, WeatherType.Snow].join(
    '|'
  ),
  shouldDonate: 'true',
  collectionSet: CollectionSet.Single,
  jugularVein: JugularVein.Left,
  unit: DonationUnit.Unit1,
  actual: '450',
  maxLimit: '680',
  approvedDonationsMl: '450',
  preferences: TreatPreferences.MilkBones, //Do we really need this field
  examinationNotes: 'Nothing',
  comment: 'Nothing',
  donationDate: '01/Jan/2023',
  numberOfCollectionUnitsUsed: CollectionUnitsUsed.One,
  veinUsed: VeinUsed.Both,
  vascularStructure: VascularStructure.Deep,
  flowRate: FlowRate.Fast,
  donationPosition: DonationPositionUsed.LeftLateral,
  holdersPosition: [HoldersPosition.AtFeet, HoldersPosition.AtHead].join('|'),
  donorMovement: DonorMovement.VeryStill,
  typeOfMovement: MovementType.HeadMovement,
  emotionalSupport: [
    EmotionalSupport.LikesEarRubs,
    EmotionalSupport.LikesFaceKisses,
    EmotionalSupport.LikesNameSaid,
  ].join('|'),
  dislikes: [Dislikes.Clippers, Dislikes.Pokes].join('|'),
  likes: [Likes.Taps, Likes.EarDials, Likes.RoughPets, Likes.GumRubs].join('|'),
  treatPreferences: [
    TreatPreferences.MilkBones,
    TreatPreferences.DoesNotLikeTreats,
  ].join('|'),
  shortPrimaryHolderId: '',
  shortSecondaryHolderId: '',
  shortPokerId: '',
};
