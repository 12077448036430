import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const DaycareDetailModal = createContext<ContextType | null>(null);

const DaycareRegisterDetailProvider: ComponentWithChildren = ({ children }) => {
  const providerValue = useProviderValue();

  return (
    <DaycareDetailModal.Provider value={providerValue}>
      {children}
    </DaycareDetailModal.Provider>
  );
};

export const useDaycareDetailModalContext = () => {
  const context = useContext(DaycareDetailModal);
  if (context === undefined) {
    throw new Error(
      'the useDaycareDetailModalContext should be used within DaycareRegisterDetailProvider!'
    );
  }

  return context;
};

export default DaycareRegisterDetailProvider;
