import { EmptyCircle } from 'modules/donor/components/RoutineTest/components/EmptyCircle';
import { FilledCircle } from 'modules/donor/components/RoutineTest/components/FilledCircle';
import { IndividualResultsSection } from 'modules/donor/components/RoutineTest/components/IndividualResultsSection';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.RoutineTest.IndividualTestsSection');

const preset = {
  title: t('individualTests'),
  items: [
    {
      label: t('dggrLipase'),
      details: t('dggrLipaseSubtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
    {
      label: t('sdma'),
      details: t('sdmaSubtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
    {
      label: t('triglycerides'),
      details: t('triglyceridesSubtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
  ],
};

export const IndividualTestsSection: FC = () => (
  <IndividualResultsSection {...preset} />
);
