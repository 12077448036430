import { IVitalsParameters } from 'modules/donor/components/UpdateVitalsParametersModal/interface';
import { UpdateVitalsParametersSchemaType } from 'modules/donor/components/UpdateVitalsParametersModal/schema';
import {
  VitalsParameterOutput,
  VitalsParametersType,
  UpdateVitalsParameterInput,
} from 'types.d';

export const convertVitalsParametersList = (
  parameters: VitalsParameterOutput[]
): UpdateVitalsParametersSchemaType => {
  const parametersValues: IVitalsParameters = getParametersValues(parameters);
  return <UpdateVitalsParametersSchemaType>{
    temperatureToCheckMax:
      parametersValues[VitalsParametersType.TemperatureToCheck].max,
    temperatureToCheckMin:
      parametersValues[VitalsParametersType.TemperatureToCheck].min,
    temperatureToStopMin:
      parametersValues[VitalsParametersType.TemperatureToStop].min,
    temperatureToStopMax:
      parametersValues[VitalsParametersType.TemperatureToStop].max,
    pulseMax: parametersValues[VitalsParametersType.Pulse].max,
    pulseMin: parametersValues[VitalsParametersType.Pulse].min,
    respirationMin: parametersValues[VitalsParametersType.Respiration].min,
    respirationCriticalValues:
      parametersValues[VitalsParametersType.Respiration].criticalValues,
    packedCellVolumeMax:
      parametersValues[VitalsParametersType.PackedCellVolume].max,
    packedCellVolumeMin:
      parametersValues[VitalsParametersType.PackedCellVolume].min,
    totalSolidsMin: parametersValues[VitalsParametersType.TotalSolids].min,
    totalSolidsMax: parametersValues[VitalsParametersType.TotalSolids].max,
    mucousMembraneCriticalValues:
      parametersValues[VitalsParametersType.MucousMembrane].criticalValues,
    capillaryRefillTimeMax:
      parametersValues[VitalsParametersType.CapillaryRefillTimeValue].max,
    capillaryRefillTimeMin:
      parametersValues[VitalsParametersType.CapillaryRefillTimeValue].min,
    weightPercentageDifference:
      parametersValues[VitalsParametersType.Weight].percentageDifference,
    weightMin: parametersValues[VitalsParametersType.Weight].min,
  };
};

export const reconvertToVitalsParametersList = (
  formData: UpdateVitalsParametersSchemaType,
  parameters: VitalsParameterOutput[]
): UpdateVitalsParameterInput[] => {
  const parametersValues = getParametersValues(parameters);
  return [
    {
      id: parametersValues[VitalsParametersType.TemperatureToCheck].id,
      min: formData.temperatureToCheckMin,
      max: formData.temperatureToCheckMax,
    },
    {
      id: parametersValues[VitalsParametersType.TemperatureToStop].id,
      min: formData.temperatureToStopMin,
      max: formData.temperatureToStopMax,
    },
    {
      id: parametersValues[VitalsParametersType.Pulse].id,
      min: formData.pulseMin,
      max: formData.pulseMax,
    },
    {
      id: parametersValues[VitalsParametersType.Respiration].id,
      min: formData.respirationMin,
      criticalValues: formData.respirationCriticalValues,
    },
    {
      id: parametersValues[VitalsParametersType.PackedCellVolume].id,
      min: formData.packedCellVolumeMin,
      max: formData.packedCellVolumeMax,
    },
    {
      id: parametersValues[VitalsParametersType.TotalSolids].id,
      min: formData.totalSolidsMin,
      max: formData.totalSolidsMax,
    },
    {
      id: parametersValues[VitalsParametersType.MucousMembrane].id,
      criticalValues: formData.mucousMembraneCriticalValues,
    },
    {
      id: parametersValues[VitalsParametersType.CapillaryRefillTimeValue].id,
      min: formData.capillaryRefillTimeMin,
      max: formData.capillaryRefillTimeMax,
    },
    {
      id: parametersValues[VitalsParametersType.Weight].id,
      min: formData.weightMin,
      percentageDifference: formData.weightPercentageDifference,
    },
  ];
};

export const getParametersValues = (parameters: VitalsParameterOutput[]) =>
  parameters.reduce((previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue.type]: {
        ...currentValue,
        type: undefined,
      },
    };
  }, {} as any);
