import { Col, Row } from 'antd';
import { FormItem } from 'modules/common/components';
import { getPersonalInfoFields } from 'modules/owner/components/EditProfileForm/fields';
import { EditProfileFormSchemaType } from 'modules/owner/components/EditProfileForm/schema';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

type PropTypes = {
  disabled?: boolean;
};

const PersonalInfoFormSection: FC<PropTypes> = ({ disabled }) => {
  const { control, formState } = useFormContext<EditProfileFormSchemaType>();
  const personalInfoFields = useMemo(
    () => getPersonalInfoFields({ disabled }),
    [disabled]
  );

  return (
    <Col span={24}>
      {personalInfoFields.map(({ name, Component, label }) => {
        const fieldName = `personalInfo.${name}`;
        const errorMessage = formState.errors.personalInfo?.[name]?.message;
        return (
          <Row key={fieldName}>
            <Col span={24}>
              <FormItem
                label={label}
                extra={errorMessage}
                validateStatus={errorMessage && 'error'}
              >
                <Component control={control} name={fieldName} />
              </FormItem>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

export default PersonalInfoFormSection;
