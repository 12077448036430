import { AppointmentType } from 'modules/appointments/enums';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('appointments.AppointmentTitleSelect');

const COMMON_APPOINTMENT_TITLE = {
  NewDonorAppointment: t('newDonorAppointment'),
  donation: t('donation'),
  recheck: t('recheck'),
};

const GROUP_APPOINTMENT_TILE = {
  screening: t('screening'),
  ...COMMON_APPOINTMENT_TITLE,
};

const titlesToSelectOption = (object: Record<string, string>) => {
  return Object.values(object).map((title) => {
    return {
      value: title,
      label: title,
    };
  });
};

export const appointmentTitles = {
  [AppointmentType.Individual]: titlesToSelectOption(COMMON_APPOINTMENT_TITLE),
  [AppointmentType.Grouped]: titlesToSelectOption(GROUP_APPOINTMENT_TILE),
  [AppointmentType.Daycare]: titlesToSelectOption(COMMON_APPOINTMENT_TITLE),
};
