import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const CreateProductModalContext = createContext<ContextType | null>(null);

const CreateProductModalProvider: ComponentWithChildren = ({ children }) => {
  const providerValue = useProviderValue();

  return (
    <CreateProductModalContext.Provider value={providerValue}>
      {children}
    </CreateProductModalContext.Provider>
  );
};

export const useCreateProductModalContext = () => {
  const context = useContext(CreateProductModalContext);
  if (!context) {
    throw new Error(
      'the useCreateProductModalContext should be used within CreateProductModalProvider!'
    );
  }

  return context;
};

export default CreateProductModalProvider;
