import { FormFieldWithComponent } from 'modules/common/types';
import { CollectionUnitsUsedSelect } from 'modules/donations/components/CollectionUnitsUsedSelect';
import { DislikesSelect } from 'modules/donations/components/DislikesSelect';
import { DonationPositionSelect } from 'modules/donations/components/DonationPositionSelect';
import { DonorMovementSelect } from 'modules/donations/components/DonorMovementSelect';
import { EmotionalSupportSelect } from 'modules/donations/components/EmotionalSupportSelect';
import { FlowRateSelect } from 'modules/donations/components/FlowRateSelect';
import { HolderPositionSelect } from 'modules/donations/components/HolderPositionSelect';
import { LikesSelect } from 'modules/donations/components/LikesSelect';
import { MovementTypeSelect } from 'modules/donations/components/MovementTypeSelect';
import { TreatmentPreferencesSelect } from 'modules/donations/components/TreatmentPreferencesSelect';
import { VascularStructureSelect } from 'modules/donations/components/VascularStructureSelect';
import { VeinUsedSelect } from 'modules/donations/components/VeinUsedSelect';
import UserSelect from 'modules/user/components/UserSelect';
import { UserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { AdditionalDonationInfoFields } from './enum';

const t = getTranslation('donation.CreateDonationForm.DonationInfo');

type FormFiledType = FormFieldWithComponent<
  AdditionalDonationInfoFields,
  { name: string }
> & { tooltip?: string };

type Props = {
  enabled: boolean;
};

export const getAdditionalDonationInfoFields = ({
  enabled,
}: Props): FormFiledType[] =>
  [
    // DISABLED CODE (totalCollectedMls duplicates in donation form)
    // {
    //   name: AdditionalDonationInfoFields.totalCollectedMls,
    //   label: t(AdditionalDonationInfoFields.totalCollectedMls),
    //   Component: ({ control, name }) => (
    //     <ControlledInputNumber
    //       disabled={!enabled}
    //       controlled={{ control, name }}
    //       addonAfter={t('ml')}
    //       stringMode
    //       placeholder={t('enterTotalCollectedMls')}
    //     />
    //   ),
    // },
    {
      name: AdditionalDonationInfoFields.numberOfCollectionUnitsUsed,
      label: t(AdditionalDonationInfoFields.numberOfCollectionUnitsUsed),
      Component: ({ control, name }) => (
        <CollectionUnitsUsedSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.primaryHolder,
      label: t(AdditionalDonationInfoFields.primaryHolder),
      Component: ({ control, name }) => (
        <UserSelect
          disabled={!enabled}
          userRoles={[UserRole.Admin, UserRole.CollectionTeam]}
          controlled={{ control, name }}
          placeholder={t('enterPrimaryHolderName')}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.secondaryHolder,
      label: t(AdditionalDonationInfoFields.secondaryHolder),
      Component: ({ control, name }) => (
        <UserSelect
          disabled={!enabled}
          userRoles={[UserRole.Admin, UserRole.CollectionTeam]}
          controlled={{ control, name }}
          placeholder={t('enterSecondaryHolderName')}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.poker,
      label: t(AdditionalDonationInfoFields.poker),
      Component: ({ control, name }) => (
        <UserSelect
          disabled={!enabled}
          userRoles={[UserRole.Admin, UserRole.CollectionTeam]}
          controlled={{ control, name }}
          placeholder={t('enterPokerName')}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.veinUsed,
      label: t(AdditionalDonationInfoFields.veinUsed),
      Component: ({ control, name }) => (
        <VeinUsedSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.vascularStructure,
      label: t(AdditionalDonationInfoFields.vascularStructure),
      Component: ({ control, name }) => (
        <VascularStructureSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.flowRate,
      label: t(AdditionalDonationInfoFields.flowRate),
      Component: ({ control, name }) => (
        <FlowRateSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.donationPosition,
      label: t(AdditionalDonationInfoFields.donationPosition),
      Component: ({ control, name }) => (
        <DonationPositionSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.holdersPosition,
      label: t(AdditionalDonationInfoFields.holdersPosition),
      Component: ({ control, name }) => (
        <HolderPositionSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.donorMovement,
      label: t(AdditionalDonationInfoFields.donorMovement),
      Component: ({ control, name }) => (
        <DonorMovementSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.typeOfMovement,
      label: t(AdditionalDonationInfoFields.typeOfMovement),
      Component: ({ control, name }) => (
        <MovementTypeSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.emotionalSupport,
      label: t(AdditionalDonationInfoFields.emotionalSupport),
      Component: ({ control, name }) => (
        <EmotionalSupportSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.dislikes,
      label: t(AdditionalDonationInfoFields.dislikes),
      Component: ({ control, name }) => (
        <DislikesSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.likes,
      label: t(AdditionalDonationInfoFields.likes),
      Component: ({ control, name }) => (
        <LikesSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: AdditionalDonationInfoFields.treatPreferences,
      label: t(AdditionalDonationInfoFields.treatPreferences),
      Component: ({ control, name }) => (
        <TreatmentPreferencesSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
  ] as FormFiledType[];
