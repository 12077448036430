import config from 'config';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationPayloadOutput } from 'types';

type PropTypes = {
  payload: NotificationPayloadOutput;
};

const AppointmentCanceled: FC<PropTypes> = ({ payload }) => {
  const { t } = useTranslation(
    'notification.NotificationCard.AppointmentCanceled'
  );
  const appointment = payload?.appointment;
  if (!appointment) {
    return null;
  }
  const content = t('appointmentJustCanceled', {
    appointmentTitle: appointment.title,
    start: format(new Date(appointment.start), config.LOCALIZED_TIME_FORMAT),
    end: format(new Date(appointment.end), config.LOCALIZED_TIME_FORMAT),
    donorName: `${appointment.donor?.name} ${appointment.donor?.user?.lastName}`,
    context: appointment.donor ? 'withDonor' : undefined,
  });
  return <>{content}</>;
};

export default AppointmentCanceled;
