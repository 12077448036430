import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal, message } from 'antd';
import { FormItem, Spin } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { FC, MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateGroupMutation } from 'types.d';

import { createGroupFormFields } from './fields';
import { createGroupSchema, CreateGroupSchemaType } from './schema';

const CreateGroupModal: FC<BaseModalProps> = ({
  visible,
  onOk,
  onCancel,
  hide,
  ...restModalProps
}) => {
  const { t } = useTranslation('donor.CreateGroupModal');
  const [createGroup, createGroupMutation] = useCreateGroupMutation({
    refetchQueries: ['findAllGroup'],
    onCompleted: () => {
      message.success(t('groupHasBeenCreated'));
      hide();
      reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const { control, handleSubmit, formState, reset } =
    useForm<CreateGroupSchemaType>({
      resolver: yupResolver(createGroupSchema),
      mode: 'onChange',
    });
  const isOkButtonDisabled = !formState.isValid || createGroupMutation.loading;
  const submitForm = handleSubmit((formData) => {
    createGroup({
      variables: {
        input: {
          title: formData.title,
          team: { title: formData.teamTitle },
          userId: formData.userId,
        },
      },
    });
  });

  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    onCancel && onCancel(e);
    reset();
    hide();
  };

  const okHandler = async (e: MouseEvent<HTMLElement>) => {
    await submitForm();
    onOk && onOk(e);
  };

  return (
    <Modal
      {...restModalProps}
      title={t('createGroup')}
      visible={visible}
      onOk={okHandler}
      onCancel={cancelHandler}
      okText={t('create')}
      okButtonProps={{
        disabled: isOkButtonDisabled,
      }}
    >
      <Spin spinning={createGroupMutation.loading}>
        <Form>
          {createGroupFormFields.map(({ Component, label, name }) => (
            <FormItem
              key={name}
              label={label}
              help={formState.errors[name]?.message}
              validateStatus={formState.errors[name]?.message && 'error'}
            >
              <Component control={control} />
            </FormItem>
          ))}
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateGroupModal;
