import { Routes } from 'config/routes';
import EditUserForm from 'modules/admin/components/EditUserForm';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserLazyQuery } from 'types.d';

const EditUserPage: FC = () => {
  const { t } = useTranslation('admin.EditUserPage');
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const userId = isNaN(Number(id)) ? id : Number(id);
  const [getUser] = useGetUserLazyQuery({
    onCompleted: ({ getUser }) => {
      //THIS LOGIC HAS BEEN PROVIDED TO SEE THIS PAGE ONLY WITH SHORT ID WHICH IS NUMBER
      navigate(`${Routes.Users}/${getUser.shortId}`, {
        replace: true,
      });
    },
    onError() {
      navigate(Routes.Users);
    },
  });

  useEffect(() => {
    if (typeof userId === 'string') {
      getUser({
        variables: {
          input: { id: userId },
        },
      });
    }
  }, [userId]);

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      {typeof userId === 'number' && <EditUserForm userId={id} />}
    </ProfileSideMenuWrapper>
  );
};

export default EditUserPage;
