import { SelectProps } from 'antd';
import { AppointmentType } from 'modules/appointments/enums';
import { Select } from 'modules/common/components';
import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { appointmentTitles } from './options';
import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
  appointmentType: AppointmentType;
} & SelectProps;

const AppointmentTitleSelect: FC<PropTypes> = ({
  controlled,
  className,
  appointmentType,
  ...restProps
}) => {
  const classNames = `${styles.appointmentSelect} ${className || ''}`;
  const { field } = useController(controlled);
  const { t } = useTranslation('appointments.AppointmentTitleSelect');
  const onChangeHandler = (value: string) => {
    const titles = value as unknown as string[];
    const [firstLocation, secondLocation] = titles;
    const newSelectedValue = value.length
      ? [secondLocation || firstLocation]
      : undefined;
    field.onChange(newSelectedValue?.[0]);
  };

  return (
    <Select
      placeholder={t('selectOrEnterTitle')}
      {...restProps}
      adjustDropdownPosition
      className={classNames}
      options={appointmentTitles[appointmentType]}
      mode="tags"
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      ref={field.ref}
      value={field.value}
    />
  );
};

export default AppointmentTitleSelect;
