import { NotificationType } from 'types.d';

import * as Contents from './components';

const {
  AppointmentCanceled,
  AppointmentCreated,
  AppointmentForGroupCreated,
  DonorEnrolled,
  DonorUnenrolled,
  RequestCreated,
} = NotificationType;

export const NotificationContentByType = {
  [AppointmentCreated]: Contents.AppointmentCreated,
  [AppointmentCanceled]: Contents.AppointmentCanceled,
  [AppointmentForGroupCreated]: Contents.AppointmentForGroupCreated,
  [DonorEnrolled]: Contents.DonorEnrolled,
  [DonorUnenrolled]: Contents.DonorUnenrolled,
  [RequestCreated]: Contents.RequestCreated,
} as const;
