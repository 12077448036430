import { gql } from '@apollo/client';
import { DONATION_FRAGMENT } from 'modules/donations/graphql/fragments';

export const GET_PREVIOUS_DONATION_QUERY = gql`
  query getPreviousDonation($input: GetPreviousDonationInput!) {
    getPreviousDonation(input: $input) {
      ...Donation
    }
  }
  ${DONATION_FRAGMENT}
`;

export const GET_DONATION_QUERY = gql`
  query getDonation($input: GetDonationInput!) {
    getDonation(input: $input) {
      ...Donation
    }
  }
  ${DONATION_FRAGMENT}
`;

export const GET_DONATION_LIST_QUERY = gql`
  query getDonationList($input: GetDonationsListInput!) {
    getDonationsList(input: $input) {
      data {
        ...Donation
      }
      meta {
        total
      }
    }
  }
  ${DONATION_FRAGMENT}
`;

export const GET_SPECIFIC_DONOR_DONATION_LIST_QUERY = gql`
  query getSpecificDonorDonationsList(
    $input: GetSpecificDonorDonationsListInput!
  ) {
    getSpecificDonorDonationsList(input: $input) {
      data {
        ...Donation
      }
      meta {
        total
      }
    }
  }
  ${DONATION_FRAGMENT}
`;
