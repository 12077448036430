import { ControlledSelect } from 'modules/common/components';
import { ComponentProps, FC } from 'react';

import { productStatuses } from './options';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const ProductStatusSelect: FC<PropTypes> = (props) => {
  return <ControlledSelect options={productStatuses} {...props} />;
};

export default ProductStatusSelect;
