import { CheckboxOptionType, RadioGroupProps } from 'antd';
import { ControlledRadioGroup } from 'modules/common/components';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { DonationUnit } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & RadioGroupProps;

const t = getTranslation('donation.DonationUnitRadioGroup');

const constantOptions: CheckboxOptionType[] = [
  {
    value: DonationUnit.Unit0,
    label: t('unit0'),
  },
  {
    value: DonationUnit.Unit1,
    label: t('unit1'),
  },
  {
    value: DonationUnit.Unit2,
    label: t('unit2'),
  },
];

const ControlledDonationUnitRadioGroup: FC<PropTypes> = ({
  controlled,
  ...restProps
}) => {
  return (
    <ControlledRadioGroup
      options={constantOptions}
      controlled={controlled}
      {...restProps}
    />
  );
};

export default ControlledDonationUnitRadioGroup;
