import { Routes } from 'config/routes';
import { IModule } from 'modules/common/interfaces';

import { RouteType } from './enums';
import LoginPage from './pages/Login';
import ResendConfirmationCode from './pages/ResendConfirmationCode';

const authModule: IModule = {
  routes: [
    {
      path: Routes.Login,
      Component: LoginPage,
      type: RouteType.NoAuth,
    },
    {
      path: Routes.ResendConfirmationCode,
      Component: ResendConfirmationCode,
      type: RouteType.NoAuth,
    },
  ],
};

export default authModule;
