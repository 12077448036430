import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { envServices } from 'utils/EnvServices';

import { typePolicies } from './typePolicies';

const httpLink = createHttpLink({
  uri: `${envServices.get('REACT_APP_SERVER_URI')}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const refreshToken = localStorage.getItem(LOCALE_STORAGE_KEYS.REFRESH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: refreshToken ? `Bearer ${refreshToken}` : '',
    },
  };
});

const clientWithRefreshToken = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies,
  }),
});

export default clientWithRefreshToken;
