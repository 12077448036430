import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export default function Head() {
  const { t } = useTranslation('donor.FormsToPrint.GeneralSubmissionForm.Head');

  return (
    <div className={styles.head}>
      <Row justify="center" className={styles.title}>
        <h1>{t('formTitle')}</h1>
      </Row>
      <div className={styles.headColumns}>
        <div>
          <img
            src="https://navbb-assets.s3.amazonaws.com/cornel-university-ico.svg"
            alt="icon"
            className={styles.icon}
          />
        </div>
        <div>
          <div className={styles.nameColumn}>
            <h2>{t('animalHealthDiagnosticCenter')}</h2>
            <p>{t('collegeOfVeterinaryMedicineCornellUniversity')}</p>
            <p>{t('inPartnershipWith')}</p>
          </div>
          <div className={styles.addresses}>
            <div>
              {t('USPostalServiceAddress')} <br /> {t('boxAddress')} <br />
              {t('boxCity')}
            </div>
            <div>
              {t('fedExService')} <br /> {t('fedAddress')} <br /> {t('fedCity')}
            </div>
          </div>
        </div>
        <div className={styles.contacts}>
          <p>{t('AHDCContacts')}</p>
          <p className={styles.contactsItem}>
            {t('phone')} <span>607-253-3900</span>
          </p>
          <p className={styles.contactsItem}>
            {t('fax')} <span>607-253-3943</span>
          </p>
          <p className={styles.contactsItem}>
            {t('web')} <span>ahdc.vet.cornell.edu</span>
          </p>
          <p className={styles.contactsItem}>
            {t('email')} <span>diagcenter@cornell.edu</span>
          </p>
        </div>
        <div>
          <div className={styles.labUseOnly}>
            <div>
              <p>{t('labUseOnly')}</p>
            </div>
            <div>
              <p>{t('accessionNumberAndDate')}</p>
            </div>
          </div>
          <p className={styles.note}>{t('note')}</p>
        </div>
      </div>
    </div>
  );
}
