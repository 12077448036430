import { Col } from 'antd';
import { RoutineTestGender } from 'modules/donor/components/RoutineTestGenderSelect/enum';
import { FC } from 'react';

import styles from './styles.module.scss';

const values = Object.values(RoutineTestGender);

type PropTypes = {
  checked: string;
};

export const GenderRadioButton: FC<PropTypes> = ({ checked }) => {
  return (
    <Col>
      {values.map((value, index) => (
        <span key={value}>
          <input
            type="radio"
            name="gender"
            value={value}
            className={styles.radioButton}
            checked={checked === value}
            key={index}
            readOnly
          />
          <span className={styles.radioButtonLabel}>{value}</span>
        </span>
      ))}
    </Col>
  );
};
