import { LineConfig } from '@ant-design/charts';
import { Routes } from 'config/routes';
import { NavigateFunction } from 'react-router-dom';

export const getConfig = (
  { onReady, xAxis, yAxis, ...otherProps }: LineConfig,
  navigate: NavigateFunction
): LineConfig => {
  return {
    ...otherProps,
    padding: 'auto',
    onReady: onReady
      ? onReady
      : (plot) => {
          plot.chart.on('plot:click', (evt: any) => {
            const { x, y } = evt;
            const donation = plot.chart.getTooltipItems({ x, y })[0].data;
            navigate(`${Routes.Donations}/${donation.shortId}`);
            window.location.reload();
          });
        },

    yAxis: yAxis && {
      title: {
        text: yAxis.title?.text,
        style: { fontSize: 18 },
      },
      alias: yAxis.alias,
    },
  };
};
