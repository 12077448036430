import {
  ControlledInput,
  ControlledInputNumber,
  ControlledTextArea,
} from 'modules/common/components';
import { YearMonthSelect } from 'modules/common/components/YearMonthSelect';
import { FormFieldWithComponent } from 'modules/common/types';
import {
  LocationSelect,
  PetGenderSelect,
  PetSpeciesSelect,
} from 'modules/donor/components';
import ControlledYesNoRadioGroup from 'modules/donor/components/ControlledYesNoRadioGroup';
import RegionSelect from 'modules/locations/components/RegionSelect/inex';
import UserSelect from 'modules/user/components/UserSelect';
import { UserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.CreateDonorForm.RequiredSection');

export enum RequiredFormSectionField {
  Name = 'name',
  UserId = 'userId',
  Species = 'species',
  DateOfBirth = 'dateOfBirth',
  Gender = 'gender',
  Neutered = 'neutered',
  Weight = 'weight',
  MedicationCourse = 'medicationCourse',
  PrimaryVeterinarian = 'primaryVeterinarian',
  Region = 'region',
  Location = 'location',
}

type FormFiledType = FormFieldWithComponent<
  RequiredFormSectionField,
  { name: string }
>;

type PropTypes = {
  hiddenFields?: RequiredFormSectionField[];
  region?: string;
};

export const getFields = (props?: PropTypes): FormFiledType[] => {
  const hiddenFields = props?.hiddenFields;

  const requiredFormSectionFields: FormFiledType[] = [
    {
      name: RequiredFormSectionField.Name,
      label: t('petName'),
      Component: ({ control, name }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('enterPetName')}
        />
      ),
    },
    {
      name: RequiredFormSectionField.UserId,
      label: t('lastName'),
      Component: ({ control, name }) => {
        return (
          <UserSelect
            userRoles={[UserRole.Owner]}
            controlled={{ control, name }}
            placeholder={t('enterOwnerName')}
          />
        );
      },
    },
    {
      name: RequiredFormSectionField.Species,
      label: t('specie'),
      Component: ({ control, name }) => (
        <PetSpeciesSelect controlled={{ control, name }} />
      ),
    },
    {
      name: RequiredFormSectionField.DateOfBirth,
      label: t('dateOfBirth'),
      Component: ({ control, name }) => (
        <YearMonthSelect
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: RequiredFormSectionField.Gender,
      label: t('petGender'),
      Component: ({ control, name }) => (
        <PetGenderSelect controlled={{ control, name }} />
      ),
    },
    {
      name: RequiredFormSectionField.Weight,
      label: t('petWeight'),
      Component: ({ control, name }) => (
        <ControlledInputNumber
          className={styles.inputNumber}
          controlled={{ control, name }}
          placeholder={t('enterPetWeight')}
          addonAfter={t('kg').toUpperCase()}
        />
      ),
    },
    {
      name: RequiredFormSectionField.Region,
      label: t('pleaseSelectLocation'),
      Component: ({ control, name }) => (
        <RegionSelect controlled={{ control, name }} />
      ),
    },
    {
      name: RequiredFormSectionField.Location,
      Component: ({ control, name }) => (
        <LocationSelect
          controlled={{ control, name }}
          regions={props?.region ? [props?.region] : undefined}
        />
      ),
    },
    {
      name: RequiredFormSectionField.PrimaryVeterinarian,
      label: t('primaryVeterinarian'),
      Component: ({ control, name }) => (
        <ControlledInput
          placeholder={t('enterPrimaryVeterinarian')}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: RequiredFormSectionField.Neutered,
      label: t('neutered'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup controlled={{ control, name }} />
      ),
    },
    {
      name: RequiredFormSectionField.MedicationCourse,
      label: t('medicationHistory'),
      Component: ({ control, name }) => (
        <ControlledTextArea
          controlled={{ control, name }}
          placeholder={t('enterMedicationHistory')}
          className={styles.textArea}
        />
      ),
    },
  ];
  if (hiddenFields?.length) {
    return requiredFormSectionFields.filter(
      ({ name }) => !hiddenFields.includes(name)
    );
  }
  return requiredFormSectionFields;
};
