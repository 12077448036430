import { CalendarProps, Col, Row } from 'antd';
import { isSameDay, isToday } from 'date-fns';
import { FC, useMemo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import styles from './styles.module.scss';

import Calendar from '../Calendar';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & CalendarProps<Date>;

const MultipleDatePicker: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);
  const { onChange, value } = controller.field;
  const selectedDates = useMemo(() => value || [], [value]);
  const selectDate = (date: Date) => {
    const isAlreadySelected = checkIsSelected(date);
    const newSelectedDates = isAlreadySelected
      ? selectedDates?.filter((selectedDate: any) => {
          return !isSameDay(date, selectedDate);
        })
      : [...selectedDates, date];
    onChange(newSelectedDates);
  };

  const checkIsSelected = (date: Date): boolean => {
    const foundDate = selectedDates?.find((selectedDate: any) => {
      return isSameDay(date, selectedDate);
    });
    return Boolean(foundDate);
  };

  return (
    <Calendar
      fullscreen={false}
      onSelect={selectDate}
      dateFullCellRender={(date) => {
        const isSelected = checkIsSelected(date);
        return (
          <Row justify="center" align="middle">
            <Col
              className={`${styles.day} ${isToday(date) && styles.today} ${
                isSelected && styles.selected
              }`}
            >
              {date.getDate()}
            </Col>
          </Row>
        );
      }}
      {...restProps}
    />
  );
};

export default MultipleDatePicker;
