import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Button, message } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { UploadFile } from 'antd/lib/upload/interface';
import { ControlledTextArea } from 'modules/common/components';
import FileList from 'modules/common/components/FileList';
import { GET_DONORS_NOTES } from 'modules/donor/graphql/queries';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAddNoteMutation } from 'types.d';
import { fetchWithAuth } from 'utils/fetchWithAuth';
import { getTranslation } from 'utils/getTranslation';

import { NoteFileUploader } from './NoteFileUploader';
import { CreateNoteSchemaType, createNoteSchema } from './schema';
import styles from './styles.module.scss';

type PropTypes = {
  donorId: string;
};

const t = getTranslation('donor.EditDonorForm.NoteSection');

export const CreateNoteForm: React.FC<PropTypes> = ({ donorId }) => {
  const createNoteForm = useForm<CreateNoteSchemaType>({
    resolver: yupResolver(createNoteSchema),
    mode: 'onChange',
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUploading, setFileUploading] = useState(false);

  const [addNote, addNoteMutation] = useAddNoteMutation();

  const handleUpload = async (noteId: string, file: UploadFile) => {
    if (!file.originFileObj) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file.originFileObj);
    formData.append('noteId', noteId);

    await fetchWithAuth(
      `${process.env.REACT_APP_SERVER_URI}/donor/donor-note-files`,
      {
        method: 'POST',
        body: formData,
      }
    );
  };

  const submitForm = createNoteForm.handleSubmit(
    ({ text }: CreateNoteSchemaType) => {
      addNote({
        variables: {
          input: {
            text,
            donorId,
          },
        },
        async onCompleted(noteMutation) {
          const noteId = noteMutation.addNote.id;
          setFileUploading(true);
          const uploadedPromises = fileList.map((file) =>
            handleUpload(noteId, file)
          );

          await Promise.all(uploadedPromises);

          await addNoteMutation.client.refetchQueries({
            include: [GET_DONORS_NOTES],
          });

          message.success(t('noteAdded'));
          createNoteForm.reset();
          setFileList([]);
          setFileUploading(false);
        },
        onError() {
          message.error(t('noteAddingError'));
        },
      });
    }
  );

  return (
    <div>
      <form>
        <FormItem
          label={t('notes')}
          className={styles.notesForm}
          labelAlign="left"
        >
          <ControlledTextArea
            maxLength={2000}
            className={styles.commentArea}
            controlled={{ name: 'text', control: createNoteForm.control }}
          />
        </FormItem>
        <FileList fileList={fileList} setFileList={setFileList} />
        <Col span={24} className={styles.noteInputWrapperButtons}>
          <NoteFileUploader fileList={fileList} setFileList={setFileList} />
          <Button
            onClick={submitForm}
            disabled={!createNoteForm.formState.isValid}
            loading={addNoteMutation.loading || fileUploading}
          >
            {t('send')}
          </Button>
        </Col>
      </form>
    </div>
  );
};
