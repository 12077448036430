import { ControlledSelect } from 'modules/common/components';
import { ComponentProps, FC } from 'react';

import { donorTypes } from './options';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const DonorTypeSelect: FC<PropTypes> = (props) => {
  return <ControlledSelect options={donorTypes} {...props} />;
};

export default DonorTypeSelect;
