import { GroupListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.GroupCardListSorter');
export const options = [
  {
    value: GroupListSort.Teams,
    label: t('teams'),
  },
  {
    value: GroupListSort.Title,
    label: t('groups'),
  },
  {
    value: GroupListSort.Owner,
    label: t('owner'),
  },
  {
    value: GroupListSort.Donors,
    label: t('donors'),
  },
];
