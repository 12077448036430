import { Checkbox } from 'modules/common/components';
import { FC, ComponentProps } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Checkbox>;

const ControlledInput: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);

  return (
    <Checkbox
      {...controller.field}
      checked={controller.field.value}
      {...restProps}
    />
  );
};

export default ControlledInput;
