import { DonorStatus } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('appointments.EnrollDonorModal');

export type EnrollDonorSchemaType = {
  donorId: string;
  donorStatus?: DonorStatus;
};
export const enrollDonorSchema: yup.SchemaOf<EnrollDonorSchemaType> =
  yup.object({
    donorId: yup.string().required(t('pleaseSelectDonor')),
    donorStatus: yup
      .mixed<DonorStatus>()
      .notOneOf([DonorStatus.Archived], t('archivedDonor'))
      .notRequired(),
  });
