export default {
  RequestsPage: {
    requests: 'Requests',
  },
  RequestsTable: {
    requestNumber: 'Request number',
    ownerName: 'Owner name',
    date: 'Date',
  },
  RequestPage: {
    request: 'Request',
  },
  Request: {
    name: 'Name',
    lastName: 'Last name',
    species: 'Species',
    age: 'Age',
    gender: 'Gender',
    neutered: 'Spayed or neutered',
    weight: 'Weight',
    distinguishableMarks: 'Distinguishable marks',
    bloodType: 'Blood type',
    donorType: 'Donor type',
    team: 'Team',
    breed: 'Breed',
    hasTransfusion: 'Has transfusion',
    takesMedications: 'Takes medications',
    medicationCourse: 'Medication course',
    primaryVeterinarian: 'Primary veterinarian',
    delete: 'Delete',
    approve: 'Approve',
    disapprove: 'Disapprove',
    resolved: 'Request successfully resolved!',
    requestAlreadyProcessed: 'Request has already been processed',
    kg: 'kg',
  },
  RejectionReasonModal: {
    rejectionReason: 'Rejection reason',
    reason: 'Reason',
    ok: 'Ok',
    cancel: 'Cancel',
    reasonIsRequired: 'Rejection reason is required',
  },
  SchemasError: {
    reasonMaxLengthError:
      'Max length of the rejection reason is 500 characters',
    reasonMinLengthError: 'Min length of the rejection reason is 10 characters',
  },
};
