import config from 'config';
import { ComponentProps, FC, ReactNode } from 'react';
import ReactInputMask from 'react-input-mask';

import styles from './styles.module.scss';

type PropTypes = {
  mask?: ComponentProps<typeof ReactInputMask>['mask'];
  children?: (props: any) => ReactNode;
} & Omit<ComponentProps<typeof ReactInputMask>, 'mask' | 'children'>;

const PhoneInput: FC<PropTypes> = ({ children, ...restProps }) => {
  const firstNumberIndex = config.PHONE_NUMBER_MASK.indexOf('9');
  const firstNumber = restProps.value
    ?.toString()
    .slice(firstNumberIndex, firstNumberIndex + 1);
  const className = `ant-input mask-phone-input ${styles.phoneInput} ${
    !Number(firstNumber) && styles.grayColor
  } ${restProps.className || ''}`;

  return (
    <ReactInputMask
      {...restProps}
      mask={config.PHONE_NUMBER_MASK}
      className={className}
      children={children as any}
    />
  );
};

export default PhoneInput;
