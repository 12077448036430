import { anticoagulantSchema } from 'modules/products/schemas';
import { Anticoagulant } from 'types';
import * as yup from 'yup';

export type CreateProductSchema = {
  woocommerceCategoryId: number;
  quantity: number;
  woocommerceProductId: number;
  anticoagulant: Anticoagulant;
  storageRequirements: string[];
  values: number[];
};

export const createProductSchema: yup.SchemaOf<CreateProductSchema> =
  yup.object({
    woocommerceCategoryId: yup.number().required(),
    quantity: yup.number().required(),
    woocommerceProductId: yup.number().required(),
    anticoagulant: anticoagulantSchema.required(),
    storageRequirements: yup.array().of(yup.string().required()),
    values: yup
      .array()
      .of(yup.number().required())
      .required()
      .when('quantity', (quantity) => {
        return yup
          .array()
          .of(yup.number().required())
          .min(quantity)
          .max(quantity);
      })
      .default([]),
  });
