import { DonorOutput } from 'types';

import { EditDonorSchemaType } from './schema';

type ReturnedType = {
  required?: Partial<EditDonorSchemaType['required']>;
  optional?: Partial<EditDonorSchemaType['optional']>;
  donorStatus?: Partial<EditDonorSchemaType['donorStatus']>;
  additional?: Partial<EditDonorSchemaType['additional']>;
};

export const getDefaultParams = (donor: Partial<DonorOutput>): ReturnedType => {
  return {
    required: {
      ...donor,
      weight: donor.weight,
      lastName: donor.user?.lastName,
      medicationCourse: donor.medicationCourse || undefined,
      donationFrequency: donor.donationFrequency || undefined,
      dateOfBirth: new Date(donor.dateOfBirth),
    },
    optional: {
      // TODO: and donationFrequency
      distinguishableMarks: donor.distinguishableMarks || undefined,
      team: donor.team?.id,
      donorType: donor.type,
      bloodType: donor.bloodType || undefined,
      breed: donor.breed || undefined,
      color: donor.color || undefined,
      rabiesDate: donor.rabiesDate ? new Date(donor.rabiesDate) : null,
      distemperDate: donor.distemperDate ? new Date(donor.distemperDate) : null,
      retiredDate: donor.retiredDate ? new Date(donor.retiredDate) : null,
      isDaycare: donor.isDaycare || undefined,
      donationMLApprovedAmount: donor.donationMLApprovedAmount,
      approvedDonationML: donor.approvedDonationML,
      attendDaycareDays: donor.attendDaycareDays || [],
      wontRegularlyAttendDaycare: !donor.canRegularlyAttendDaycare,
      comment: donor.comment || null,
    },
    donorStatus: {
      status: donor.status,
      microchipNumber: donor.microchipNumber || undefined,
      hasMicrochip:
        typeof donor.hasMicrochip === 'boolean'
          ? donor.hasMicrochip
          : undefined,
      retiredClarification: donor.retiredReasonComment,
      retiredReason: donor.retiredReason,
    },
    additional: {
      firstCertificatePrinted: donor.firstCertificatePrinted,
      secondCertificatePrinted: donor.secondCertificatePrinted,
    },
  };
};
