import {
  ControlledInput,
  ControlledSelect,
  ControlledInputNumber,
  ControlledTextArea,
} from 'modules/common/components';
import { YearMonthSelect } from 'modules/common/components/YearMonthSelect';
import { FormFieldWithComponent } from 'modules/common/types';
import {
  BloodTypeSelect,
  ControlledYesNoRadioGroup,
  LocationSelect,
} from 'modules/donor/components';
import RegionSelect from 'modules/locations/components/RegionSelect/inex';
import { AnimalGender, AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

enum DonorApplicationField {
  Name = 'name',
  Species = 'species',
  Breed = 'breed',
  DateOfBirth = 'dateOfBirth',
  Gender = 'gender',
  Neutered = 'neutered',
  Weight = 'weight',
  HasTransfusion = 'hasTransfusion',
  TakeMedications = 'takeMedications',
  MedicationsCourseComment = 'medicationCourse',
  PrimaryVeterinarian = 'primaryVeterinarian',
  Region = 'region',
  Location = 'location',
  BloodType = 'bloodType',
}

const t = getTranslation('donor.DonorApplicationForm');

const animalGenderOptions = Object.values(AnimalGender).map((value) => {
  return {
    value,
    label: value,
  };
});

const animalSpeciesOptions = Object.values(AnimalSpecies).map((value) => {
  return {
    value,
    label: value,
  };
});

type FormFiledType = FormFieldWithComponent<
  DonorApplicationField,
  { name: string }
> & { tooltip?: string };

type ParamTypes = {
  donorSpecie?: AnimalSpecies;
  region?: string;
};

const getFields = ({
  donorSpecie = AnimalSpecies.Dog,
  region,
}: ParamTypes): FormFiledType[] => {
  const donorApplicationFormFields: FormFiledType[] = [
    {
      name: DonorApplicationField.Name,
      label: t('petName'),
      Component: ({ control, name }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('yourAnswer')}
        />
      ),
    },
    {
      name: DonorApplicationField.DateOfBirth,
      label: t('dateOfBirth'),
      Component: ({ control, name }) => (
        <YearMonthSelect controlled={{ control, name }} />
      ),
    },
    {
      name: DonorApplicationField.Neutered,
      label: t('neutered'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: DonorApplicationField.Species,
      label: t('petSpecies'),
      Component: ({ control, name }) => (
        <ControlledSelect
          options={animalSpeciesOptions}
          defaultValue={undefined}
          controlled={{
            control,
            name,
          }}
          placeholder={t('selectSpecie')}
        />
      ),
    },
    {
      name: DonorApplicationField.Breed,
      label: t('breed'),
      Component: ({ control, name }) => (
        <ControlledInput
          controlled={{
            control,
            name,
          }}
          placeholder={t('yourAnswer')}
        />
      ),
    },
    {
      name: DonorApplicationField.Weight,
      label: t('petWeight', { context: donorSpecie }),
      Component: ({ control, name }) => (
        <ControlledInputNumber
          className={styles.inputNumber}
          controlled={{ control, name }}
          placeholder={t('enterPetWeight')}
          addonAfter={t('lb').toUpperCase()}
        />
      ),
    },
    {
      name: DonorApplicationField.BloodType,
      label: t('bloodType'),
      Component: ({ control, name }) => (
        <BloodTypeSelect
          controlled={{ control, name }}
          placeholder={t('selectBloodType')}
        />
      ),
    },
    {
      name: DonorApplicationField.Region,
      label: t('pleaseSelectLocation'),
      Component: ({ control, name }) => (
        <RegionSelect controlled={{ control, name }} />
      ),
    },
    {
      name: DonorApplicationField.Location,
      Component: ({ control, name }) => (
        <LocationSelect
          controlled={{ control, name }}
          regions={region ? [region] : undefined}
        />
      ),
    },
    {
      name: DonorApplicationField.HasTransfusion,
      label: t('transfusion'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: DonorApplicationField.TakeMedications,
      label: t('takeMedications'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: DonorApplicationField.MedicationsCourseComment,
      label: t('medicationCommentary'),
      tooltip: `${t('kindOfMedication')} \n ${t('medicationTerm')}`,
      Component: ({ control, name }) => (
        <ControlledTextArea
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: DonorApplicationField.PrimaryVeterinarian,
      label: t('primaryVeterinarian'),
      Component: ({ control, name }) => (
        <ControlledInput
          controlled={{
            control,
            name,
          }}
          placeholder={t('yourAnswer')}
        />
      ),
    },
    {
      name: DonorApplicationField.Gender,
      label: t('gender'),
      Component: ({ control, name }) => (
        <ControlledSelect
          options={animalGenderOptions}
          defaultValue={undefined}
          controlled={{
            control,
            name,
          }}
          placeholder={t('selectGender')}
        />
      ),
    },
  ];

  return donorApplicationFormFields;
};

export { getFields, DonorApplicationField };
