import { Button, Col, message, Table, Row, TableProps, Grid } from 'antd';
import { SorterResult, FilterValue } from 'antd/lib/table/interface';
import config from 'config';
import { Routes } from 'config/routes';
import { TableColumns } from 'modules/admin/components/InternalUsersTable/enum';
import { Spin, SearchInput } from 'modules/common/components';
import { useSort, useDebounce } from 'modules/common/hooks';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useGetUsersListQuery,
  useRemoveUserMutation,
  UserRole,
  UserOutput,
  UserListSort,
  Order,
  UsersListFilterInput,
  PermissionsEnum,
} from 'types.d';

import { getColumns } from './columns';
import styles from './styles.module.scss';

const { AddUsers, DeleteUsers, UpdateUserInfo } = PermissionsEnum;

const { useBreakpoint } = Grid;
const USER_ROLES_TO_DISPLAY = Object.values(UserRole).filter(
  (role) => role !== UserRole.Owner
);

const InternalUsersTable = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const currentUserId = currentUser.data?.id;
  const { t } = useTranslation('admin.UsersListPage');
  const pagination = usePagination();
  const sort = useSort<UserListSort>();
  const [filter, setFilter] = useState<UsersListFilterInput>({
    roles: USER_ROLES_TO_DISPLAY,
  });
  const canInvite = currentUser.isCan(AddUsers);
  const canDelete = currentUser.isCan(DeleteUsers);
  const canUpdateUserInfo = currentUser.isCan(UpdateUserInfo);
  const getUsersListQuery = useGetUsersListQuery({
    variables: {
      input: {
        filter: filter,
        take: config.DEFAULT_LIMIT,
        skip: pagination.skip,
        sort: sort.options,
      },
    },
    onCompleted: (data) => {
      pagination.setTotal(data.getUsersList.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const isLoading = getUsersListQuery.loading;
  const users = getUsersListQuery.data?.getUsersList.data as UserOutput[];

  const inviteOnClickHandler = () => {
    navigate(Routes.InviteUsers);
  };

  const navigateToEditUserPage = (userId: string) => {
    navigate(`${Routes.Users}/${userId}`);
  };

  const [removeUser, removeUserMutation] = useRemoveUserMutation({
    update(cache) {
      cache.evict({ fieldName: 'getUsersList' });
    },
    onCompleted: () => {
      message.success(t('successfullyDeleted'));
    },
    onError: (error) => message.error(error.message),
  });

  const deleteUserHandler = (id: string) => {
    removeUser({ variables: { input: { id } } });
  };
  const { md } = useBreakpoint();
  const columns = getColumns({
    onClickEditIcon: navigateToEditUserPage,
    deleteHandler: deleteUserHandler,
    canDelete,
    canUpdateUserInfo,
    currentUserId,
    showSorterTooltip: md,
  });

  const { debounce } = useDebounce();
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchingQuery = e.target.value || undefined;
    setFilter((previousFilter) => {
      return {
        ...previousFilter,
        searchingQuery,
      };
    });
  };

  const toFilter = (filters: Record<string, FilterValue | null>) => {
    const roles = filters[TableColumns.role] as UserRole[] | undefined;
    return { roles: roles ? roles : USER_ROLES_TO_DISPLAY };
  };

  const onTableChange: TableProps<UserOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<UserOutput>;
      const fieldName =
        UserListSort[
          tableSorter.columnKey?.toString() as keyof typeof UserListSort
        ];
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        sort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        sort.removeSortOption(fieldName);
      }
    }
    if (extra.action === 'filter') {
      const newFilterData = toFilter(filters);
      setFilter((previousFilter) => {
        return {
          ...previousFilter,
          ...newFilterData,
        };
      });
    }
  };

  return (
    <Spin spinning={isLoading || removeUserMutation.loading}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row gutter={[16, 10]} justify="space-between" align="middle">
            <Col flex={1}>
              <SearchInput
                onChange={debounce(handleSearch)}
                placeholder={t('searchUser')}
                maxLength={100}
              />
            </Col>
            {canInvite && (
              <Col>
                <Button onClick={inviteOnClickHandler} type="primary">
                  {t('inviteUser')}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Table
            rowKey={({ id }) => id}
            rowClassName={({ id }) =>
              id === currentUserId ? styles.disabled : ''
            }
            columns={columns}
            dataSource={users}
            onChange={onTableChange}
            scroll={{ x: 750 }}
            pagination={pagination.config}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default InternalUsersTable;
