import { DonationOutput } from 'types.d';

import { EditDonationSchemaType } from './schema';

export const mapDefaultValues = (
  donation: DonationOutput
): Partial<EditDonationSchemaType> => {
  return {
    donationInfo: {
      shouldDonate: donation.shouldDonate as boolean,
      maxLimit: donation.maxLimit,
      collectionSet: donation.collectionSet,
      jugularVein: donation.jugularVein,
      //Removed position from here
      preferences: donation.preferences || '',
    },
    environment: {
      weatherTypes: donation.weatherTypes,
      weatherTemperature: donation.weatherTemperature,
    },
    quantity: {
      unit: donation.unit || undefined,
      actual: Number.isNaN(donation.actual)
        ? undefined
        : (donation.actual as number),
      approvedDonationsMl: donation.approvedDonationsMl,
    },
    examination: {
      temperature: donation.temperature,
      pulse: donation.pulse,
      respiration: donation.respiration,
      capillaryRefillTime: donation.capillaryRefillTime,
      mucousMembrane: donation.mucousMembrane,
      packedCellVolume: donation.packedCellVolume,
      totalSolids: donation.totalSolids,
      weight: donation.weight,
      examinationNotes: donation.examinationNotes || undefined,
      ineligible: donation.ineligible || false,
      ineligibleReason: donation.ineligibleReason,
      ineligibleReasonDetails: donation.ineligibleReasonDetails,
    },
    donorId: donation.donor.shortId.toString(),
    donationDate: new Date(donation.donationDate || undefined),
  };
};
