import {
  LeftOutlined,
  PrinterOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import {
  eachDayOfInterval,
  isToday as checkIsToday,
  isPast,
  format,
} from 'date-fns';
import MembersSelect from 'modules/appointments/components/MembersSelect';
import { TaskListMode } from 'modules/appointments/components/TaskSchedule/enum';
import { AppointmentsCalendarSchemaType } from 'modules/appointments/components/TaskSchedule/schema';
import { Button, Segmented } from 'modules/common/components';
import { LocationSelect } from 'modules/donor/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum, UserRole } from 'types.d';

import styles from './styles.module.scss';

const CURRENT_DATE = new Date();
const WEEKS_LIMIT = 52;
const { PrintTasksSchedule, ViewTasksScheduleOfSpecificUser } = PermissionsEnum;

type PropTypes = {
  mode: TaskListMode;
  onTypeChange: (mode: TaskListMode) => void;
  control: Control<AppointmentsCalendarSchemaType, any>;
  onPrint?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  selectedDay?: Date;
};

const AppointmentsCalendarHeader: FC<PropTypes> = ({
  mode,
  onTypeChange,
  control,
  onPrint,
  onNext,
  onPrevious,
  selectedDay = CURRENT_DATE,
}) => {
  const { t } = useTranslation('appointments.TaskSchedule.Header');
  const currentUser = useCurrentUser();
  const [dayOffset, setDayOffset] = useState(0);
  const currentUserRole = currentUser?.data?.role.value;
  const canPrintTaskSchedule = currentUser.isCan(PrintTasksSchedule);
  const canViewTasksScheduleOfSpecificUser = currentUser.isCan(
    ViewTasksScheduleOfSpecificUser
  );

  const segmentedModeOptions = Object.values(TaskListMode).map(
    (tasklistMode) => {
      return {
        label: t(tasklistMode),
        value: tasklistMode,
      };
    }
  );
  const isPreviousButtonDisabled = dayOffset <= -WEEKS_LIMIT * 7;
  const isNextButtonDisabled = dayOffset >= WEEKS_LIMIT * 7;

  useEffect(() => {
    const interval =
      selectedDay.getTime() > CURRENT_DATE.getTime()
        ? {
            start: CURRENT_DATE,
            end: selectedDay,
          }
        : {
            start: selectedDay,
            end: CURRENT_DATE,
          };
    const daysOfInterval = eachDayOfInterval(interval);
    const isToday = checkIsToday(selectedDay);
    setDayOffset(
      (daysOfInterval.length - 1) * (isPast(selectedDay) && !isToday ? -1 : 1)
    );
  }, [selectedDay]);

  return (
    <Row gutter={8}>
      <Col>
        <Button onClick={onPrevious} disabled={isPreviousButtonDisabled}>
          <LeftOutlined />
        </Button>
      </Col>
      <Col>
        <Button onClick={onNext} disabled={isNextButtonDisabled}>
          <RightOutlined />
        </Button>
      </Col>
      <Col flex={1}>
        <span className={styles.yearTitle}>{format(selectedDay, 'yyyy')}</span>
      </Col>
      {currentUserRole !== UserRole.Owner &&
        canViewTasksScheduleOfSpecificUser && (
          <Col>
            <MembersSelect
              controlled={{ control, name: 'userIds' }}
              className={styles.usersFilter}
            />
          </Col>
        )}
      <Col>
        <LocationSelect
          className={styles.locationSelect}
          multiply
          maxTagCount="responsive"
          controlled={{ control, name: 'locations' }}
        />
      </Col>
      <Col>
        <Segmented
          value={mode}
          options={segmentedModeOptions}
          onChange={(value) => onTypeChange(value as TaskListMode)}
        />
      </Col>
      {canPrintTaskSchedule && (
        <Col>
          <PrinterOutlined className={styles.printLabel} onClick={onPrint} />
        </Col>
      )}
    </Row>
  );
};

export default AppointmentsCalendarHeader;
