import { IndividualResultsSection } from 'modules/donor/components/RoutineTest/components/IndividualResultsSection';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.RoutineTest.FecalSection');

const preset = {
  title: t('fecal'),
  items: [
    {
      label: t('ovaParasite'),
      details: t('ovaParasiteSubtitle'),
    },
    {
      label: t('giardia'),
      details: t('giardiaSubtitle'),
    },
  ],
};

export const FecalSection: FC = () => <IndividualResultsSection {...preset} />;
