// import { Col, Row } from 'antd';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import ChangeProductTrackingInfoModal from 'modules/products/components/ChangeProductTrackingInfoModal';
import ProductsPageTabs from 'modules/products/components/ProductsPageTabs';
// import ProductsTable from 'modules/products/components/ProductsTable';
import { useChangeProductTrackingInfoModalContext } from 'modules/products/contexts/ChangeProductTrackingInfoModal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ProductsPage: FC = () => {
  const { t } = useTranslation('products.ProductsPage');
  const changeProductTrackingInfoModal =
    useChangeProductTrackingInfoModalContext();

  return (
    <ProfileSideMenuWrapper title={t('products')}>
      <ProductsPageTabs />
      {changeProductTrackingInfoModal && (
        <ChangeProductTrackingInfoModal
          {...changeProductTrackingInfoModal?.modalConfig}
        />
      )}
    </ProfileSideMenuWrapper>
  );
};

export default ProductsPage;
