import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import { ITabsItem } from 'modules/common/components/CardTabs';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import ProductsTable from 'modules/products/components/ProductsTable';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ProductsDashboardTable from '../ProductsDashboardTable';

const ProductsPageTabs: FC = () => {
  const { t } = useTranslation('products.ProductsPageTabs');

  const products: ITabsItem = {
    title: t('products'),
    content: <ProductsTable />,
    key: `${Routes.Products}`,
  };

  const productsDashboard: ITabsItem = {
    title: t('dashboard'),
    content: <ProductsDashboardTable />,
    key: `${Routes.ProductsDashboard}`,
  };

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <NavigationCardTabs items={[products, productsDashboard]} />
      </Col>
    </Row>
  );
};

export default ProductsPageTabs;
