import { UploadOutlined } from '@ant-design/icons';
import { Alert, Col, Row, Table, Tooltip, Upload, message } from 'antd';
import { ItemRender, UploadFile } from 'antd/lib/upload/interface';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Button } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PermissionsEnum, useGetOwnerQuery } from 'types.d';

import { getColumns } from './columns';
import { useUserAttachmentsTable } from './hook';
import styles from './styles.module.scss';
import { UserAttachmentsTableProps } from './types';

const UserAttachmentsTable: FC<UserAttachmentsTableProps> = (props) => {
  const { t } = useTranslation('owner.OwnerAttachmentsTable');
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const canEditOwnerProfile = currentUser.isCan(
    PermissionsEnum.UpdateOwnersData
  );
  const ownerQuery = useGetOwnerQuery({
    variables: {
      input: { id: String(props.userId) },
    },
    onError: (error) => {
      message.error(error.message);

      if (error.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.Owners);
    },
  });
  const {
    isLoading,
    downloadFileHandler,
    detachFileHandler,
    uploadAttachmentProps,
    ownerAttachments,
  } = useUserAttachmentsTable({ userId: `${ownerQuery.data?.getOwner.id}` });
  const columns = getColumns({
    onDownload: downloadFileHandler,
    onDelete: detachFileHandler,
    canEditOwnerProfile,
  });

  const deletedAt = ownerQuery.data?.getOwner.deletedAt as Date | null;
  const formattedDate =
    deletedAt && format(new Date(deletedAt), config.DATE_AND_TIME_FORMAT);

  const itemRender: ItemRender<any> = (
    originNode: ReactElement,
    file: UploadFile
  ) => {
    if (file.status === 'error') {
      return (
        <Tooltip title={t('fileMustBeLessThan', { size: 1 })}>
          {originNode.props.children}
        </Tooltip>
      );
    }
    return originNode;
  };

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Row justify="end">
          <Col span={24}>
            {!deletedAt ? (
              <Upload
                itemRender={itemRender}
                {...uploadAttachmentProps}
                className={styles.uploadContainer}
              >
                {canEditOwnerProfile && (
                  <Button icon={<UploadOutlined />}>{t('upload')}</Button>
                )}
              </Upload>
            ) : (
              <Alert
                message={t('userDeleted')}
                description={`${t('userDeletedAt')} ${formattedDate}`}
                type="error"
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          loading={isLoading}
          dataSource={ownerAttachments}
          columns={columns}
          pagination={false}
          size="small"
        />
      </Col>
    </Row>
  );
};

export default UserAttachmentsTable;
