import { RightOutlined } from '@ant-design/icons';
import { CollapseProps } from 'antd';
import { FC } from 'react';

import styles from './styles.module.scss';

type ParametersOfExpandIconFunction = Parameters<
  Exclude<CollapseProps['expandIcon'], undefined>
>;

type PropTypes = ArrayElement<ParametersOfExpandIconFunction>;

const ExpandIcon: FC<PropTypes> = ({ collapsible, isActive }) => {
  const opacity = collapsible === 'disabled' ? 0 : 1;
  return (
    <RightOutlined
      className={styles.expandIcon}
      style={{ opacity }}
      rotate={isActive ? 90 : 0}
    />
  );
};

export default ExpandIcon;
