import { Text } from 'modules/common/components';
import { FC } from 'react';
import { CapillaryRefillTime } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donation.CreateDonationForm.Examination');

type Props = {
  value: any;
};

export const PreviousDonationValue: FC<Props> = ({ value }) => {
  return (
    <>
      {typeof value === 'boolean' && (
        <Text>{(value as boolean) ? t('yes') : t('no')}</Text>
      )}
      {Object.values(CapillaryRefillTime).includes(value) && (
        <Text>
          {t(
            value?.toString().charAt(0).toLowerCase() +
              value?.toString().slice(1)
          )}
        </Text>
      )}
      {typeof value !== 'boolean' &&
        !Object.values(CapillaryRefillTime).includes(value) && (
          <Text>{value?.toString()}</Text>
        )}
    </>
  );
};
