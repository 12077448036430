import { highlightCsvHeadersLabel } from 'modules/common/utils/papaparse/highlightCsvHeadersLabel';
import {
  DONATIONS_CSV_HEADERS,
  REQUIRED_CSV_HEADER_SYMBOL,
} from 'modules/donations/constants';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';

import { dataExample } from './example';

type PropTypes = {
  requiredHeaders?: string[];
};

const DonorsCsvTemplateDownload: FC<PropTypes> = ({ requiredHeaders = [] }) => {
  const defaultHeaders = DONATIONS_CSV_HEADERS as unknown as LabelKeyObject[];
  const highlightedHeaders = highlightCsvHeadersLabel({
    headers: defaultHeaders,
    toHighlight: requiredHeaders,
    withSymbol: REQUIRED_CSV_HEADER_SYMBOL,
  });
  return (
    <CSVLink
      filename="DonationsTemplate.csv"
      data={[dataExample]}
      headers={highlightedHeaders}
    >
      DonationsTemplate.csv
    </CSVLink>
  );
};

export default DonorsCsvTemplateDownload;
