import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('user.LocationsTable');
export interface ICreateRegionsFormSchema {
  id: string;
  name: string;
}

export const createRegionsFormSchema: yup.SchemaOf<ICreateRegionsFormSchema> =
  yup.object({
    id: yup.string().required(),
    name: yup
      .string()
      .max(100, t('lessThan', { max: 100 }))
      .strict(true)
      .trim(t('mustBeTrimmed'))
      .required(t('locationNameIsRequired')),
  });
