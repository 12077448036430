import { Col, Row } from 'antd';
import { DefaultClinicParams } from 'config/default-clinic-params';
import { RoutineTestFormSchemaType } from 'modules/donor/components/RoutineTestForm/schema';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import CheckboxGroup from '../../../components/CheckboxGroup';
import FormInput from '../../../components/Input';
import Title from '../Title';

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};

export default function GeneralSubmissionForm({ data }: PropTypes) {
  const { t } = useTranslation('donor.FormsToPrint');
  return (
    <Row>
      <Title>{t('generalLaboratorySubmissionForm')}</Title>
      <div className={styles.formWrapper}>
        <div className={styles.formBox}>
          <Title position="start">{t('veterinarian')}</Title>
          <FormInput label={t('clinic')} value={data.clinicName} />
          <FormInput label={t('addressLabel')} value={data.clinicAddress} />
          <FormInput label={t('cityStateZipLabel')} value={data.CityStateZip} />
          <FormInput label={t('phone')} value={data.telephone} />
          <FormInput label={t('fax')} />
          <FormInput label={t('email')} value={data.email} />
          <CheckboxGroup
            options={['US mail', 'Fax', 'E-mail']}
            active={'E-mail'}
            label={t('preferredReportDistributionMethod')}
          />
        </div>
        <div className={styles.formBox}>
          <Title position="start">{t('ownerSubmitter')}</Title>
          <FormInput label={t('name')} />
          <FormInput label={t('addressLabel')} />
          <FormInput label={t('cityStateZipLabel')} />
          <FormInput label={t('phone')} />
          <FormInput label={t('fax')} />
          <FormInput label={t('email')} />
          <CheckboxGroup
            options={['US mail', 'Fax', 'E-mail', 'No report']}
            label={t('preferredReportDistributionMethod')}
          />
        </div>
        <div className={styles.formBox}>
          <Title position="start">{t('billTo')}</Title>
          <CheckboxGroup
            direction="column"
            active={t('vetPractice')}
            options={[
              t('vetPractice'),
              t('ownerOption'),
              { fieldName: t('other'), withInput: true },
            ]}
          />
        </div>
        <div className={styles.formBox}>
          <Title position="start">{t('purposeOfTest')}</Title>
          <CheckboxGroup
            size="sm"
            className={styles.testPurposeCheckboxes}
            options={[
              t('diagnostic'),
              t('regulatory'),
              t('surveillance'),
              {
                fieldName: t('other'),
                withInput: true,
                inputValue: t('healthScreening'),
                isChecked: true,
              },
            ]}
          />
          <Title position="start">{t('flockHerdInformation')}</Title>
          <Row className={styles.herdInformation}>
            <Col span={8}>
              <FormInput label={t('numberInGroup')} />
            </Col>
            <Col span={8}>
              <FormInput label={t('numberAffected')} />
            </Col>
            <Col span={8}>
              <FormInput label={t('numberDead')} />
            </Col>
          </Row>
        </div>
      </div>
    </Row>
  );
}
