import { DONORS_CSV_HEADERS } from 'modules/donor/constants';

type KeyType = typeof DONORS_CSV_HEADERS[number]['key'];

export const dataExample: Record<KeyType, string> = {
  name: 'Chewbacca',
  location: 'Manassas Hunt Club',
  dateOfBirth: '03/Jan/2021',
  neutered: 'yes',
  weight: '60',
  primaryVeterinarian: 'none',
  species: 'Dog',
  gender: 'Male',
  type: 'Grouped',
  bloodType: 'Dea1Positive',
  status: 'Active',
  breed: 'Foxhound',
  color: 'Tricolor',
  rabiesDate: '01/Jan/2023',
  distemperDate: '01/Jan/2023',
  retiredDate: '01/Jan/2023',
};
