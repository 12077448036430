import { Collapse } from 'antd';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateDonationsSchemaType } from '../../schema';
import { highlightedHeaders } from '../DonationsDragger/highlightedHeaders';

const ErrorMessages: FC = () => {
  const { t } = useTranslation('donation.UploadDonationsModal.ErrorMessages');
  const formContext = useFormContext<CreateDonationsSchemaType>();
  const donationsErrorMessages = formContext.formState.errors.donations || [];
  const csvHeaderErrors = formContext.formState.errors.csvHeaders || [];

  return (
    <Collapse className={styles.collapse}>
      {csvHeaderErrors.length && (
        <Collapse.Panel header={t('headers')} key="header">
          <ul className={styles.error}>
            {Object.values(csvHeaderErrors).map((error, i) => {
              const message = Array.isArray(error)
                ? error.map(({ message }) => message).join(', ')
                : error.message;
              return (
                <li key={`${message}_${i}`}>
                  {t('headerMustBe', {
                    index: i,
                    header: highlightedHeaders[i].label,
                  })}
                </li>
              );
            })}
          </ul>
        </Collapse.Panel>
      )}
      {donationsErrorMessages.map((donationsErrors, i) => {
        return (
          <Collapse.Panel header={`Row #${i + 1}`} key={i}>
            <ul className={styles.error}>
              {Object.values(donationsErrors).map((error, i) => {
                const message = Array.isArray(error)
                  ? error.map(({ message }) => message).join(', ')
                  : error.message;
                return <li key={`${message}_${i}`}>{message}</li>;
              })}
            </ul>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export default ErrorMessages;
