import { Row, Col, Button, message } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DonorOutput, useUnenrollDonorFromDaycareMutation } from 'types.d';

import styles from '../styles.module.scss';

type PropTypes = {
  daycareId?: string;
  registeredDonors: DonorOutput[];
};

export const RegisteredDonors: FC<PropTypes> = ({
  registeredDonors,
  daycareId,
}) => {
  const { t } = useTranslation('appointments.registerDaycareModal');
  const [unenrollDonorFromDaycare, { loading }] =
    useUnenrollDonorFromDaycareMutation();

  const handleUnenroll = (donorId: string) => {
    if (!daycareId) {
      return;
    }

    unenrollDonorFromDaycare({
      variables: {
        input: {
          donorId,
          daycareId,
        },
      },
      refetchQueries: ['getDaycareAppointmentInfoForCurrentUser'],
      onCompleted() {
        message.success(t('donorUnenrolled'));
      },
      onError() {
        message.error(t('unenrollError'));
      },
    });
  };

  return (
    <p className={styles.alreadyRegistered}>
      {t('registeredDonors')}
      <span className={styles.registeredDonors}>
        {registeredDonors?.map((donor) => {
          return (
            <Row justify="space-between">
              <Col>{donor.name}</Col>
              <Col>
                <Button
                  type="link"
                  loading={loading}
                  onClick={() => handleUnenroll(donor.id)}
                >
                  {t('unenroll')}
                </Button>
              </Col>
            </Row>
          );
        })}
      </span>
    </p>
  );
};
