import { DefaultOptionType } from 'antd/lib/select';
import { ControlledSelect } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RetiredReasons } from 'types.d';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const DonorRetiredReasonSelect: FC<PropTypes> = (props) => {
  const { t } = useTranslation('donor.DonorRetiredReasonSelect');
  const retiredReasons: DefaultOptionType[] = Object.values(RetiredReasons).map(
    (reason) => ({ label: t(reason), value: reason })
  );

  return (
    <ControlledSelect
      options={retiredReasons}
      placeholder={t('selectReason')}
      {...props}
    />
  );
};

export default DonorRetiredReasonSelect;
