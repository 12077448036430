import { Table, TableProps, message, Col, Row } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import config from 'config';
import { SearchInput, Spin } from 'modules/common/components';
import { useDebounce, useFilter, useSort } from 'modules/common/hooks';
import { getColumns } from 'modules/donor/components/DonorsTable/columns';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { GET_USER_DONOR_LIST_QUERY } from 'modules/owner/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  DonorOutput,
  DonorListSort,
  Order,
  DonorListFilterInput,
  useGetUserDonorsListQuery,
  useRemoveDonorMutation,
  useUpdateDonorMutation,
  useFindGroupQuery,
  PermissionsEnum,
} from 'types.d';

type Props = {
  donors?: DonorOutput[];
  removeHandler?: (donor: DonorOutput) => void;
  showRemoveColumn?: boolean;
  showSorting?: boolean;
  showFilters?: boolean;
  showTeamsColumn?: boolean;
  onAttachClickHandler?: (newDonor: DonorOutput) => void;
  donorsToAttach?: DonorOutput[];
  pagination?: ReturnType<typeof usePagination>;
  withoutSearch?: boolean;
  freeSpaceInTeam?: number;
};

const DonorsTable: FC<Props> = ({
  donors: passedDonors,
  removeHandler,
  showRemoveColumn,
  showSorting,
  showFilters,
  showTeamsColumn,
  onAttachClickHandler,
  donorsToAttach,
  pagination: passedPagination,
  withoutSearch,
  freeSpaceInTeam,
}) => {
  const { t } = useTranslation('donor.DonorsTable');

  const params = useParams();
  const groupId = params.id || '';
  const groupQuery = useFindGroupQuery({
    variables: {
      input: {
        id: groupId,
      },
    },
  });

  const userId =
    (groupQuery.data && groupQuery.data?.findGroup?.user?.id) || '';
  const donorListSort = useSort<DonorListSort>();
  const donorListFilters = useFilter<DonorListFilterInput>();
  const pagination = usePagination();
  // const [filter, setFilter] = useState<DonorListFilterInput>({});
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const [currentDonor, setCurrentDonor] = useState<DonorOutput>();
  const { debounce, clearDebounce } = useDebounce();
  const setSearchInput = useRef(false);
  const getUserDonorsListQueryVariables = {
    variables: {
      input: {
        userId,
        sort: donorListSort.options,
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        filter: donorListFilters.getFilterObject(),
      },
    },
  };

  const { data: userDonorsData, loading: gettingDonorsLoading } =
    useGetUserDonorsListQuery({
      ...getUserDonorsListQueryVariables,
      onCompleted: ({ getUserDonorsList }) => {
        pagination.setTotal(getUserDonorsList.meta.total);
      },
    });

  const [removeDonor, { loading: removeDonorLoading }] = useRemoveDonorMutation(
    {
      refetchQueries: [
        {
          query: GET_USER_DONOR_LIST_QUERY,
          ...getUserDonorsListQueryVariables,
        },
        'findGroup',
      ],
      onCompleted: () => {
        message.success(t('successfullyDeleted'));
      },
      onError: ({ message: m }) => {
        message.error(m);
      },
      awaitRefetchQueries: true,
    }
  );

  const removeDonorHandler = () => {
    removeDonor({
      variables: {
        input: {
          id: currentDonor!.id,
        },
      },
    });
  };

  const [updateDonor, { loading: updateDonorLoading }] = useUpdateDonorMutation(
    {
      refetchQueries: [
        {
          query: GET_USER_DONOR_LIST_QUERY,
          ...getUserDonorsListQueryVariables,
        },
        'findGroup',
      ],
      onCompleted: () => {
        message.success(t('successfullyUpdated'));
      },
      onError: ({ message: m }) => {
        message.error(m);
      },
      awaitRefetchQueries: true,
    }
  );

  const changeDonorTeamHandler = (donor: DonorOutput, teamId?: string) => {
    updateDonor({
      variables: {
        input: {
          id: donor.id,
          teamId: teamId ? teamId : null,
        },
      },
    });
  };

  const deleteHandler = (record: DonorOutput) => {
    setCurrentDonor(record);
  };

  const yesDeleteHandler = async () => {
    removeHandler ? removeHandler(currentDonor!) : removeDonorHandler();
    setCurrentDonor(undefined);
  };

  const onTableChange: TableProps<DonorOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<DonorOutput>;
      const fieldName = tableSorter.columnKey as DonorListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        donorListSort?.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        donorListSort?.removeSortOption(fieldName);
      }
    }

    if (extra.action === 'filter') {
      Object.keys(filters).forEach((fieldName) => {
        donorListFilters?.setFilterOption({
          fieldName: fieldName as keyof DonorListFilterInput,
          value: (filters as any)[fieldName],
        });
      });
    }
  };

  const isLoading =
    gettingDonorsLoading || updateDonorLoading || removeDonorLoading;
  const currentUser = useCurrentUser();
  const canArchiveDonors = currentUser.isCan(PermissionsEnum.ArchiveDonors);
  const donors = passedDonors
    ? passedDonors
    : userDonorsData?.getUserDonorsList.data;
  const teams = groupQuery.data?.findGroup.teams;

  const columns = getColumns({
    deleteEntity: currentDonor,
    deleteHandler,
    cancelDeleteHandler: () => setCurrentDonor(undefined),
    yesDeleteHandler,
    teams,
    changeDonorTeamHandler,
    showSorting,
    showFilters,
    showRemoveColumn: showRemoveColumn && canArchiveDonors,
    showTeamsColumn,
    onAttachClickHandler,
    donorsToAttach,
    freeSpaceInTeam,
    filterValues: donorListFilters.getFilterObject(),
    sort: donorListSort?.options[0],
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    pagination.moveTo(1);
    debounce(() => {
      donorListFilters.setFilterOption({
        fieldName: 'searchingQuery',
        value: searchValue,
      });
    })(searchValue);
  };

  useEffect(() => {
    return clearDebounce;
  }, []);

  useEffect(() => {
    if (
      !setSearchInput.current &&
      donorListFilters?.getFilterObject().searchingQuery
    ) {
      setSearchInputValue(
        donorListFilters?.getFilterObject().searchingQuery || ''
      );
      setSearchInput.current = true;
    }
  }, [donorListFilters]);

  return (
    <Row gutter={[0, 15]}>
      {!withoutSearch && (
        <Col span={24}>
          <SearchInput
            onChange={handleSearch}
            placeholder={t('searchDonor')}
            maxLength={100}
            value={searchInputValue}
          />
        </Col>
      )}
      <Col span={24}>
        <Spin spinning={isLoading}>
          <Table
            onChange={onTableChange}
            rowKey={({ id }) => id}
            columns={columns}
            dataSource={donors as DonorOutput[]}
            pagination={
              passedDonors
                ? passedPagination
                  ? passedPagination.config
                  : false
                : pagination.config
            }
            scroll={{ x: 750 }}
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default DonorsTable;
