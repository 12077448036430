import { Row, Col } from 'antd';
import { Routes } from 'config/routes';
import { ITabsItem } from 'modules/common/components/CardTabs';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import EditOwnerProfileForm from 'modules/owner/components/EditOwnerProfileForm';
import OwnerAttachmentsTable from 'modules/owner/components/OwnerAttachmentsTable';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  ownerId: string;
};

const EditOwnerProfileCardTabs: FC<Props> = ({ ownerId }) => {
  const { t } = useTranslation('owner.EditOwnerProfileCardTabs');

  const editProfile: ITabsItem = {
    title: t('profile'),
    content: <EditOwnerProfileForm ownerId={ownerId} />,
    key: Routes.EditOwner.replace(':id', ownerId),
  };

  const attachments: ITabsItem = {
    title: t('attachments'),
    content: <OwnerAttachmentsTable userId={ownerId} />,
    key: Routes.OwnerAttachment.replace(':id', ownerId),
  };

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <NavigationCardTabs items={[editProfile, attachments]} />
      </Col>
    </Row>
  );
};

export default EditOwnerProfileCardTabs;
