import { Table, TableProps, message, Row, Col, Grid } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import config from 'config';
import { Spin, SearchInput } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import OrderDetails from 'modules/orders/components/OrderDetails';
import { FC, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OrderOutput,
  useGetOrdersListQuery,
  OrdersListSort,
  OrdersListSortInput,
  Order,
  OrderStatusFilter,
  OrdersListFilterInput,
} from 'types.d';

import { getOrdersTableColumn } from './columns';
import styles from './styles.module.scss';

const { useBreakpoint } = Grid;

const OrdersTable: FC = () => {
  const { t } = useTranslation('orders.OrdersTable');
  const pagination = usePagination();
  const [ordersListSort, setOrdersListSort] = useState<OrdersListSortInput>();
  const [filters, setFilters] = useState<OrdersListFilterInput>({});
  const ordersListQuery = useGetOrdersListQuery({
    variables: {
      input: {
        take: config.PAGE_SIZE,
        skip: pagination.skip,
        sort: ordersListSort,
        filter: filters,
      },
    },
    onCompleted: (data) => {
      pagination.setTotal(data.getOrdersList.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const isLoading = ordersListQuery.loading;
  const ordersList = ordersListQuery.data?.getOrdersList.data as OrderOutput[];

  const onTableChange: TableProps<OrderOutput>['onChange'] = (
    _,
    filter,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<OrderOutput>;
      const fieldName = tableSorter.columnKey as OrdersListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        setOrdersListSort({ fieldName, order });
      }
      if (!(sorter as any).order) {
        setOrdersListSort(undefined);
      }
    }

    if (extra.action === 'filter') {
      const filterStatus = filter.status as OrderStatusFilter[];
      const status = filterStatus?.length
        ? filterStatus
        : [OrderStatusFilter.Any];

      setFilters((previousFilter) => {
        return {
          ...previousFilter,
          status,
        };
      });
    }
  };

  const { debounce } = useDebounce();
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchingQuery = e.target.value || undefined;
    pagination.moveTo(1);
    setFilters((previousFilter) => {
      return {
        ...previousFilter,
        number: Number(searchingQuery),
      };
    });
  };

  const { md } = useBreakpoint();
  const column = getOrdersTableColumn(md);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Col flex={1}>
            <SearchInput
              onChange={debounce(handleSearch)}
              placeholder={t('searchByOrderNumber')}
              maxLength={100}
            />
          </Col>
        </Col>
        <Col span={24}>
          <Table
            className={styles.table}
            columns={column}
            onChange={onTableChange}
            expandable={{
              expandedRowRender: (order) => <OrderDetails order={order} />,
            }}
            rowKey={({ id }) => id}
            dataSource={ordersList}
            pagination={pagination.config}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default OrdersTable;
