import { Form, Radio } from 'antd';
import {
  ControlledRadioGroup,
  ControlledTextArea,
  FormItem,
} from 'modules/common/components';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DaycareDonorSelect from './DaycareDonorsSelect';
import { RegisterDaycareFields } from './fields';
import styles from './styles.module.scss';

import {
  Answers,
  RegisterDaycareAppointmentSchema,
} from '../RegisterOnDaycareAppointmentModal/schema';

type PropTypes = {
  location: string;
};

export const DaycareRegisterForm: FC<PropTypes> = ({ location }) => {
  const { t } = useTranslation('appointments.registerDaycareModal');
  const registerDaycareAppointmentForm =
    useFormContext<RegisterDaycareAppointmentSchema>();
  const answer = registerDaycareAppointmentForm.watch('answer');
  const chosenYes = answer === Answers.Yes;

  useEffect(() => {
    if (chosenYes) {
      return;
    }
    registerDaycareAppointmentForm.resetField(RegisterDaycareFields.Donors);
    registerDaycareAppointmentForm.resetField(RegisterDaycareFields.Note);
  }, [answer]);

  const donorsSelectError = (
    registerDaycareAppointmentForm.formState.errors[
      RegisterDaycareFields.Donors
    ] as any
  )?.message;

  const radioError =
    registerDaycareAppointmentForm.formState.errors[
      RegisterDaycareFields.Answer
    ]?.message;

  const noteError =
    registerDaycareAppointmentForm.formState.errors[RegisterDaycareFields.Note]
      ?.message;

  const locations = location.split(',').map((loc) => loc.trim());

  return (
    <Form>
      <FormItem validateStatus={radioError && 'error'}>
        <ControlledRadioGroup
          controlled={{
            name: RegisterDaycareFields.Answer,
            control: registerDaycareAppointmentForm.control,
          }}
          className={styles.radioGroup}
        >
          <Radio value={Answers.Yes}>{t('yesAnswer')}</Radio>
          <Radio value={Answers.No}>{t('noAnswer')}</Radio>
        </ControlledRadioGroup>
      </FormItem>
      {chosenYes && (
        <>
          <FormItem
            label={t('chooseDonor')}
            extra={donorsSelectError}
            validateStatus={donorsSelectError && 'error'}
          >
            <DaycareDonorSelect
              adjustDropdownPosition
              locations={locations}
              allowClear
              controlled={{
                name: RegisterDaycareFields.Donors,
                control: registerDaycareAppointmentForm.control,
              }}
            />
          </FormItem>
          <FormItem
            validateStatus={noteError && 'error'}
            extra={noteError}
            label={t('notes')}
          >
            <ControlledTextArea
              placeholder={t('leaveNotes')}
              controlled={{
                name: RegisterDaycareFields.Note,
                control: registerDaycareAppointmentForm.control,
              }}
            />
          </FormItem>
        </>
      )}
    </Form>
  );
};
