import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NotificationPayloadOutput } from 'types.d';

type PropTypes = {
  payload: NotificationPayloadOutput;
};

const RequestCreated: FC<PropTypes> = ({ payload }) => {
  const { t } = useTranslation('notification.NotificationCard.RequestCreated');
  const request = payload.request;

  if (!request) {
    return null;
  }

  return (
    <Row align="middle">
      <Col>{t('requestCreated')}&nbsp;</Col>
      <Col>
        <Link to={Routes.Request.replace(':id', request.id)}>
          {t('processRequest')}
        </Link>
      </Col>
    </Row>
  );
};

export default RequestCreated;
