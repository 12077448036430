import { Collapse } from 'antd';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './styles.module.scss';

import { CreateDonorsSchemaType } from '../../schema';

const ErrorMessages: FC = () => {
  const formContext = useFormContext<CreateDonorsSchemaType>();
  const errorMessages = formContext.formState.errors.donors || [];

  return (
    <Collapse className={styles.collapse}>
      {errorMessages.map((donorErrors, i) => {
        return (
          <Collapse.Panel header={`Row #${i + 1}`} key={i}>
            <ul className={styles.error}>
              {Object.values(donorErrors).map(({ message }, i) => (
                <li key={`${message}_${i}`}>{message}</li>
              ))}
            </ul>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export default ErrorMessages;
