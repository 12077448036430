import SearchInput from 'modules/common/components/SearchInput';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof SearchInput>;

const ControlledSearchInput: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);

  return <SearchInput {...controller.field} {...restProps} />;
};

export default ControlledSearchInput;
