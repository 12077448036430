import { ProductOutput } from 'types.d';

import { IUpdateProductTrackingInfoSchemaType } from './schema';

export const getDefaultValues = (
  product?: ProductOutput
): Partial<IUpdateProductTrackingInfoSchemaType> => {
  return {
    woocommerceOrderId: product?.woocommerceOrderId
      ? `${product?.woocommerceOrderId}`
      : undefined,
    id: product?.id,
    status: product?.status,
    orderExist: Boolean(product?.woocommerceOrderId),
  };
};
