import { Sorter } from 'modules/common/components';
import { useSort } from 'modules/common/hooks';
import { options } from 'modules/donor/components/GroupCardList/GroupCardListSorter/options';
import { FC } from 'react';
import { GroupListSort } from 'types.d';

const useGroupListSort = () => useSort<GroupListSort>();

type PropTypes = {
  sorting: ReturnType<typeof useGroupListSort>;
};

export const GroupCardListSorter: FC<PropTypes> = ({ sorting }) => (
  <Sorter sorting={sorting as ReturnType<typeof useSort>} options={options} />
);
