import { InboxOutlined } from '@ant-design/icons';
import { Upload, UploadProps } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  title?: string;
  subtitle?: string;
} & UploadProps;

const Dragger: FC<PropTypes> = ({ title, subtitle, ...restProps }) => {
  const { t } = useTranslation('common.Dragger');
  return (
    <Upload.Dragger {...restProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{title || t('title')}</p>
      {subtitle && <p className="ant-upload-hint">{subtitle}</p>}
    </Upload.Dragger>
  );
};

export default Dragger;
