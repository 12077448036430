import { Table } from 'antd';
import { getColumns } from 'modules/donations/components/DonationInfoSection/columns';
import { IDonationInfoTableData } from 'modules/donations/components/DonationInfoSection/interfaces';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  dataSource: IDonationInfoTableData[];
  fieldNamePrefix: string;
  sectionNamePrefix?: string;
};

const DesktopDonationInfoView: FC<Props> = ({
  dataSource,
  fieldNamePrefix,
  sectionNamePrefix,
}) => {
  const formContext = useFormContext();

  return (
    <Table
      rowKey={({ question }) => question}
      columns={getColumns(formContext, fieldNamePrefix, sectionNamePrefix)}
      dataSource={dataSource}
      size="small"
      pagination={false}
    />
  );
};

export default DesktopDonationInfoView;
