import { ControlledInputNumber } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import {
  CollectionSetsSelect,
  JugularVeinsSelect,
} from 'modules/donations/components';
import ControlledYesNoRadioGroup from 'modules/donor/components/ControlledYesNoRadioGroup';
import { getTranslation } from 'utils/getTranslation';

import { DonationInfoFields } from './enum';

const t = getTranslation('donation.CreateDonationForm.DonationInfo');

export type DonationInfoFormFiledType = FormFieldWithComponent<
  DonationInfoFields,
  { name: string }
>;

type Props = {
  enabled: boolean;
};

export const getDonationInfoFields = ({
  enabled,
}: Props): DonationInfoFormFiledType[] =>
  [
    {
      name: DonationInfoFields.shouldDonate,
      label: t(DonationInfoFields.shouldDonate),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          disabled={!enabled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: DonationInfoFields.maxLimit,
      label: t(DonationInfoFields.maxLimit),
      Component: ({ control, name }) => {
        return (
          <ControlledInputNumber
            disabled={!enabled}
            controlled={{ control, name }}
            addonAfter={t('ml')}
            stringMode
            placeholder={t('maxLimit')}
          />
        );
      },
    },
    {
      name: DonationInfoFields.collectionSet,
      label: t(DonationInfoFields.collectionSet),
      Component: ({ control, name }) => (
        <CollectionSetsSelect
          disabled={!enabled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: DonationInfoFields.jugularVein,
      label: t(DonationInfoFields.jugularVein),
      Component: ({ control, name }) => (
        <JugularVeinsSelect
          disabled={!enabled}
          controlled={{ control, name }}
        />
      ),
    },
    // DISABLED CODE (position duplicates in donation form)
    // {
    //   name: DonationInfoFields.position,
    //   label: t(DonationInfoFields.position),
    //   Component: ({ control, name }) => (
    //     <PositionsSelect disabled={!enabled} controlled={{ control, name }} />
    //   ),
    // },
  ] as DonationInfoFormFiledType[];
