import { Col } from 'antd';
import { ControlledInputNumber } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { WeatherTypesSelect } from 'modules/donations/components/WeatherTypesSelect';
import { getTranslation } from 'utils/getTranslation';

import { EnvironmentFields } from './enum';

const t = getTranslation('donation.CreateDonationForm.Environment');

export type EnvironmentFormFiledType = FormFieldWithComponent<
  EnvironmentFields,
  { name: string }
>;

type Props = {
  enabled: boolean;
};

export const getEnvironmentFields = ({
  enabled,
}: Props): EnvironmentFormFiledType[] => [
  {
    name: EnvironmentFields.weatherTemperature,
    Component: ({ control, name }) => (
      <Col span={24}>
        <ControlledInputNumber
          disabled={!enabled}
          addonAfter={t('f')}
          controlled={{ control, name }}
          placeholder={t('enterTemperature')}
          stringMode
        />
      </Col>
    ),
  },
  {
    name: EnvironmentFields.weatherTypes,
    Component: ({ control, name }) => (
      <Col span={24}>
        <WeatherTypesSelect
          disabled={!enabled}
          controlled={{
            control,
            name,
          }}
        />
      </Col>
    ),
  },
];
