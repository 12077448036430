import { Col, Modal, Row, Divider } from 'antd';
import { BaseModalProps } from 'modules/common/types';
import LabelInfo from 'modules/products/components/LabelInfo';
import { FC } from 'react';
import { ProductOutput } from 'types';

type PropTypes = {
  products: ProductOutput[];
} & BaseModalProps;

const LabelsInfoModal: FC<PropTypes> = ({
  hide,
  onCancel,
  onOk,
  products,
  ...restProps
}) => {
  const onCancelHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    hide();
    onCancel && onCancel(e);
  };

  const onOkHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    hide();
    onOk && onOk(e);
  };

  return (
    <Modal onCancel={onCancelHandler} onOk={onOkHandler} {...restProps}>
      <Row gutter={[0, 16]}>
        {products.map((product, i) => (
          <div key={i}>
            <Col span={24}>
              <LabelInfo product={product} />
            </Col>
            {products.length - 1 !== i && <Divider />}
          </div>
        ))}
      </Row>
    </Modal>
  );
};

export default LabelsInfoModal;
