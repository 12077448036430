import config from 'config';
import { format } from 'date-fns';
import { envServices } from 'utils/EnvServices';

import { CloudWatchCommands } from './CloudWatchCommands';
import { LogLevel } from './enums';
import { ILogger } from './interfaces';
import { InputLogEvent } from './types';

const { Error, Warn, Info, Http, Verbose, Debug } = LogLevel;

const levelPriorities: Record<LogLevel, number> = {
  [Error]: 0,
  [Warn]: 1,
  [Info]: 2,
  [Http]: 3,
  [Verbose]: 4,
  [Debug]: 5,
};

const cloudWatchEnabled = envServices.get(
  'REACT_APP_CLOUDWATCH_LOGGER_ENABLED'
);

type LogQueueItem = {
  level: LogLevel;
  logEvents: InputLogEvent[];
};

type CollectedLogEvents = Partial<Record<LogLevel, InputLogEvent[]>>;

class CloudWatchLogger extends CloudWatchCommands implements ILogger {
  private readonly level = envServices.get('REACT_APP_LOG_LEVEL');

  private collectedLogEvents: CollectedLogEvents = {};

  private logsQueue: LogQueueItem[] = [];

  private sequenceTokens: Partial<Record<LogLevel, string>> = {};

  private collectorTimeout?: NodeJS.Timeout;

  private collectionDelay = 5000;

  private maxLogEventsCollection = 10;

  private isBusy = false;

  private async log(level: LogLevel, logEvents: InputLogEvent[]) {
    try {
      const { nextSequenceToken } = await this.putLogEvents({
        logEvents,
        logStreamName: level,
        sequenceToken: this.sequenceTokens[level],
      });
      this.sequenceTokens[level] = nextSequenceToken;
    } catch (error: any) {
      const expectedSequenceToken = error.expectedSequenceToken;
      if (expectedSequenceToken) {
        const { nextSequenceToken } = await this.putLogEvents({
          logEvents,
          logStreamName: level,
          sequenceToken: expectedSequenceToken,
        });
        this.sequenceTokens[level] = nextSequenceToken;
      }
    }
  }

  info(message: string) {
    this.collectMessagesWithFurtherSending(LogLevel.Info, message);
  }

  error(message: string) {
    this.collectMessagesWithFurtherSending(LogLevel.Error, message);
  }

  warn(message: string) {
    this.collectMessagesWithFurtherSending(LogLevel.Warn, message);
  }

  private formatMessage(message: string, level: LogLevel) {
    return `[${format(
      new Date().getTime(),
      config.DATE_AND_TIME_FORMAT
    )}] ${level} - ${message}`;
  }

  private resolveQueue() {
    this.isBusy = true;
    const sendLogs = async () => {
      if (!this.logsQueue.length) {
        this.isBusy = false;
        return;
      }
      const [{ level, logEvents }, ...restQueueItems] = this.logsQueue;
      await this.log(level, logEvents);
      this.logsQueue = restQueueItems;
      sendLogs();
    };
    sendLogs();
  }

  private collectMessagesWithFurtherSending(level: LogLevel, message: string) {
    const isAllowToLog = levelPriorities[this.level] >= levelPriorities[level];
    const formattedMessage = this.formatMessage(message, level);

    if (!isAllowToLog) {
      return;
    }
    if (!cloudWatchEnabled) {
      return console.log(formattedMessage);
    }

    const currentTimestamp = new Date().getTime();
    const collectedLogEvents = this.collectedLogEvents[level] || [];
    const logEvent = {
      timestamp: currentTimestamp,
      message: formattedMessage,
    };
    this.collectedLogEvents[level] = [...collectedLogEvents, logEvent];
    const isCollectorCrowded =
      this.collectedLogEvents[level]!.length >= this.maxLogEventsCollection;

    if (this.collectorTimeout && !isCollectorCrowded) {
      clearTimeout(this.collectorTimeout as NodeJS.Timeout);
    }

    this.collectorTimeout = setTimeout(
      async (logEvents) => {
        this.logsQueue = this.makeQueueFromCollectedLogEvents(logEvents);
        if (!this.isBusy) {
          this.resolveQueue();
        }
      },
      this.collectionDelay,
      this.collectedLogEvents
    );
  }

  private makeQueueFromCollectedLogEvents(
    collectedLogEvents: CollectedLogEvents
  ) {
    return Object.values(LogLevel).reduce((queue, logLevel) => {
      const logEventsForLevel = collectedLogEvents[logLevel] || [];

      if (!logEventsForLevel.length) {
        return queue;
      }
      return [
        ...queue,
        {
          level: logLevel,
          logEvents: logEventsForLevel,
        },
      ];
    }, [] as LogQueueItem[]);
  }
}

export { CloudWatchLogger };
