import { ColumnsType } from 'antd/es/table/Table';
import { Routes } from 'config/routes';
import { Link, CheckCell } from 'modules/common/components';
import RestrictedLink from 'modules/common/components/RestrictedLink';
import {
  DonorListSort,
  DonorOutput,
  BloodType,
  DonorStatus,
  PermissionsEnum,
  DonorListFilterInput,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.DonorsTable');
const bloodTypeFilters = Object.values(BloodType).map((bloodType) => {
  return {
    text: t(bloodType.toLowerCase()),
    value: bloodType,
  };
});

const statusesFilters = Object.values(DonorStatus).map((status) => {
  return {
    text: t(status.toLowerCase()),
    value: status,
  };
});

type Props = {
  attachedDonors: DonorOutput[];
  onAttachClickHandler?: (newDonor: DonorOutput) => void;
  filterValues: DonorListFilterInput;
};

export const getColumns = ({
  attachedDonors,
  filterValues,
  onAttachClickHandler,
}: Props): ColumnsType<DonorOutput> => {
  return [
    {
      title: t('donorId'),
      render: (row: DonorOutput) => row.shortId,
      align: 'center',
    },
    {
      title: t('petName'),
      key: DonorListSort.Name,
      render: (donor: DonorOutput) => (
        <Link
          to={`${Routes.DonorsProfile.replace(':id', String(donor.shortId))}`}
        >
          {donor.name}
        </Link>
      ),
      sorter: true,
      align: 'center',
    },
    {
      title: t('ownersLastName'),
      key: DonorListSort.LastName,
      render: (row: DonorOutput) => (
        <RestrictedLink
          to={`${Routes.EditOwner.replace(':id', String(row.user?.shortId))}`}
          permissions={[PermissionsEnum.ViewTheListOfOwners]}
        >
          {row.user?.lastName || ''}
        </RestrictedLink>
      ),
      align: 'center',
    },
    {
      title: t('bloodType'),
      key: 'bloodType',
      render: (row: DonorOutput) => t(row.bloodType?.toLowerCase() || ''),
      filters: bloodTypeFilters,
      filteredValue: filterValues?.bloodType || null,
      align: 'center',
    },
    {
      title: t('status'),
      key: 'statuses',
      render: (row: DonorOutput) => t(row.status.toLowerCase()),
      filters: statusesFilters,
      filteredValue: filterValues?.statuses || null,
      align: 'center',
    },
    {
      title: t('attach'),
      render: (donor: DonorOutput) => (
        <CheckCell
          showTick={attachedDonors?.some(({ id }) => id === donor.id)}
          onClick={() => onAttachClickHandler?.(donor)}
        />
      ),
      align: 'center',
    },
  ];
};
