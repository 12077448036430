import { useState } from 'react';

import highlightColors from './highlightColors.scss';
import { ContextType } from './type';

const mappedHighlightColors = Object.values(highlightColors);

export const useProviderValue = (): ContextType => {
  const [colors, setColors] = useState<Record<string, string>>({});
  const [memberIds, setMemberIds] = useState<string[]>([]);

  const reset = () => {
    setColors({});
    setMemberIds([]);
  };

  const addMember = (memberId: string) => {
    const newMemberIds = [...memberIds, memberId];
    const color = getColorByIndex(newMemberIds.length - 1);
    setMemberIds(newMemberIds);
    setColors({ ...colors, [memberId]: color });
  };

  const removeMember = (memberId: string) => {
    const filteredMemberIds = memberIds.filter((id) => id !== memberId);
    const newColorsEntries = Object.entries(colors);
    const filteredColorEntries = newColorsEntries.filter(
      ([userId]) => userId !== memberId
    );
    const newColors = Object.fromEntries(filteredColorEntries);
    setColors(newColors);
    setMemberIds(filteredMemberIds);
  };

  const getColorByIndex = (index: number) => {
    const colorsAmount = mappedHighlightColors.length;
    if (!Number(index) || index === colorsAmount - 1) {
      return mappedHighlightColors[index];
    }
    return mappedHighlightColors[index % colorsAmount];
  };

  return {
    colors,
    reset,
    addMember,
    removeMember,
  };
};
