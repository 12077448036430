import { Row } from 'antd';
import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  boxWidth: number;
  boxHeight: number;
  circleWidth: number;
  circleHeight: number;
  checked?: boolean;
};

export const RadioButtonInBox: FC<Props> = ({
  boxWidth,
  boxHeight,
  circleWidth,
  circleHeight,
  checked,
}) => {
  return (
    <Row
      className={styles.box}
      style={{ width: boxWidth, height: boxHeight }}
      justify="center"
      align="middle"
    >
      <input
        type="radio"
        checked={checked}
        style={{
          width: circleWidth,
          height: circleHeight,
        }}
      />
    </Row>
  );
};
