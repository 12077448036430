import { Button, Col, Row } from 'antd';
import { SearchInput } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import PrintProductLabels from 'modules/products/components/PrintProductLabels';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ProductOutput } from 'types.d';

type PropTypes = {
  onSearch?: (value: string | undefined) => void;
  selectedProducts?: ProductOutput[];
  searchingValue?: string | null;
};

const Header: FC<PropTypes> = ({
  onSearch,
  selectedProducts,
  searchingValue,
}) => {
  const { t } = useTranslation('products.ProductsTable.Header');
  const { debounce, clearDebounce } = useDebounce();
  const [searchInputValue, setSearchInputValue] = useState<
    string | undefined
  >();
  const [searchParams] = useSearchParams();
  const hasSelectedRows = Boolean(selectedProducts?.length);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchingValue = e.target.value || undefined;
    setSearchInputValue(searchingValue);
    debounce((searchingValue?: string) => onSearch?.(searchingValue))(
      searchingValue
    );
  };

  useEffect(() => {
    return clearDebounce;
  }, []);

  return (
    <Row gutter={[10, 0]}>
      <Col flex={1}>
        <SearchInput
          onChange={handleSearch}
          placeholder={t('searchProduct')}
          maxLength={100}
          defaultValue={searchParams.get('donation') || undefined}
          value={searchingValue === null ? undefined : searchInputValue}
        />
      </Col>
      {hasSelectedRows && (
        <Col>
          <PrintProductLabels products={selectedProducts}>
            <Button block type="primary">
              {t('print')}
            </Button>
          </PrintProductLabels>
        </Col>
      )}
    </Row>
  );
};

export default Header;
