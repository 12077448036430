import { ControlledTextArea } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

enum AddCommentFields {
  comment = 'comment',
}

const t = getTranslation('donor.AddCommentModal');

const addCommentFormFields: FormFieldWithComponent<AddCommentFields>[] = [
  {
    name: AddCommentFields.comment,
    label: t(AddCommentFields.comment),
    Component: ({ control }) => (
      <ControlledTextArea
        controlled={{ control, name: AddCommentFields.comment }}
        placeholder={t('enterComment')}
        size="middle"
      />
    ),
  },
];

export { addCommentFormFields, AddCommentFields };
