import { AppointmentType } from 'modules/appointments/enums';
import { AppointmentOutput } from 'types.d';

import { CreateAppointmentValidationSchema } from '../CreateAppointmentsModal/schema';

export const getAppointmentFormValues = (
  appointment?: AppointmentOutput
): Record<keyof CreateAppointmentValidationSchema, any> => {
  const isDaycare = !!appointment?.isDaycare;
  const appointmentType =
    (isDaycare && AppointmentType.Daycare) ||
    (appointment?.donor && AppointmentType.Individual) ||
    (appointment?.group && AppointmentType.Grouped) ||
    AppointmentType.Individual;

  const timeslots = appointment
    ? [new Date(appointment.start), new Date(appointment.end)]
    : [];

  const date = timeslots[0] || new Date();

  return {
    type: appointmentType,
    timeslots,
    isPrivate: appointment?.isPrivate,
    title: appointment?.title,
    dates: [date],
    donorId: appointment?.donor?.id,
    donorStatus: appointment?.donor?.status,
    location: appointment?.location,
    notes: appointment?.notes || '',
    userIds: appointment?.users?.map((user) => user.id) || [],
    selectedGroup: appointment?.group
      ? {
          key: appointment?.group?.id,
          value: appointment?.group?.id,
          children: appointment?.group?.title,
        }
      : undefined,
    split: false,
    teamId: appointment?.team?.id,
    daycareDonorsIds: [],
  };
};
