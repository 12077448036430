import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, message } from 'antd';
import { DeletePopconfirm, Spin } from 'modules/common/components';
import { GroupTitleFormSchema } from 'modules/donor/components/GroupCard/schema';
import { useCreateTeamModalContext } from 'modules/donor/contexts/CreateTeamModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useUpdateGroupMutation,
  useRemoveGroupMutation,
  GroupListItemOutput,
} from 'types.d';
import { checkForLoading } from 'utils/checkForLoading';

import styles from './styles.module.scss';

type PropTypes = {
  group: GroupListItemOutput;
  onToggleEditMode: (value: boolean) => void;
  isEditMode: boolean;
  form: UseFormReturn<GroupTitleFormSchema, any>;
  isMobile?: boolean;
};

const GroupListActionsButtons: FC<PropTypes> = ({
  group,
  isEditMode,
  onToggleEditMode,
  form,
  isMobile,
}) => {
  const { t } = useTranslation('donor.GroupListActionsButtons');
  const { id } = group;

  const [updateGroup, updateGroupMutation] = useUpdateGroupMutation({
    refetchQueries: ['findAllGroup'],
    onCompleted: () => {
      message.success(t('successfullyUpdated'));
    },
    onError: ({ message: m }) => {
      message.error(m);
    },
  });

  const [removeGroup, removeGroupMutation] = useRemoveGroupMutation({
    refetchQueries: ['findAllGroup'],
    onCompleted: () => {
      message.success(t('successfullyDeleted'));
    },
    onError: ({ message: m }) => {
      message.error(m);
    },
  });

  const onGroupUpdate = form.handleSubmit((data) => {
    updateGroup({
      variables: {
        input: { id, title: data.title },
      },
      onCompleted(data) {
        if (!isMobile) {
          return;
        }
        form.reset({ title: data.updateGroup.title });
      },
    });
    onToggleEditMode(false);
  });

  const onGroupDelete = () => {
    removeGroup({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const user = useCurrentUser();
  const canUpdateDonors = user.data?.permissions?.updateDonorsData || false;
  const canArchiveDonors = user.data?.permissions?.archiveDonors || false;

  const createTeamModalContext = useCreateTeamModalContext();

  const openCreateTeamModal = () => {
    createTeamModalContext?.showModal({
      groupId: group.id,
    });
  };

  const checkClassName = form.formState.isValid
    ? undefined
    : styles.disabledIcon;
  const isLoading = checkForLoading([removeGroupMutation, updateGroupMutation]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[15, 0]} justify="center">
        {isEditMode ? (
          <>
            <Col>
              <Button onClick={() => onToggleEditMode(false)}>
                <CloseOutlined />
              </Button>
            </Col>
            <Col>
              <Button
                onClick={onGroupUpdate}
                disabled={!form.formState.isValid || !form.formState.isDirty}
              >
                <CheckOutlined className={checkClassName} />
              </Button>
            </Col>
          </>
        ) : (
          <>
            {canUpdateDonors && (
              <>
                <Col>
                  <PlusSquareOutlined onClick={openCreateTeamModal} />
                </Col>
                <Col>
                  <EditOutlined onClick={() => onToggleEditMode(true)} />
                </Col>
              </>
            )}
            {canArchiveDonors && (
              <Col>
                <DeletePopconfirm
                  title={t('sureYouWantToDeleteThisGroup')}
                  onConfirm={onGroupDelete}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </Spin>
  );
};

export default GroupListActionsButtons;
