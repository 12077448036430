import FormItem from 'antd/lib/form/FormItem';
import { ControlledCheckbox } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

type PropTypes = {
  control: Control<CreateAppointmentValidationSchema, any>;
} & ComponentProps<typeof FormItem>;

const FIELD_NAME = AppointmentFormField.IsPrivate;

export const IsPrivateCheckbox: FC<PropTypes> = ({
  control,
  ...restFormItemProps
}) => {
  const fieldState = control.getFieldState(FIELD_NAME);
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const error = fieldState.error as unknown as FieldError | undefined;
  return (
    <FormItem
      className={styles.formItem}
      label={t('isPrivate')}
      extra={error?.message}
      validateStatus={error?.message && 'error'}
      {...restFormItemProps}
    >
      <ControlledCheckbox
        controlled={{ control, name: FIELD_NAME }}
      ></ControlledCheckbox>
    </FormItem>
  );
};
