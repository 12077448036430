import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import OwnerListPage from 'modules/owner/pages/OwnersList';
import ProfileAccountPage from 'modules/owner/pages/ProfileAccount';
import ProfileNotificationsPage from 'modules/owner/pages/ProfileNotifications';
import { UserRole, PermissionsEnum } from 'types.d';

import OwnerAttachmentsPage from './pages/Attachments';
import CreateOwnerProfilePage from './pages/CreateOwnerProfile';
import EditOwnerPage from './pages/EditOwner';

const ownerModule: IModule = {
  routes: [
    {
      path: Routes.ProfileAccount,
      Component: ProfileAccountPage,
      type: RouteType.Auth,
      roles: [UserRole.Owner],
    },
    {
      path: Routes.ProfileNotifications,
      Component: ProfileNotificationsPage,
      type: RouteType.Auth,
    },
    {
      path: Routes.CreateOwner,
      Component: CreateOwnerProfilePage,
      type: RouteType.Auth,
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
      permissions: [PermissionsEnum.UpdateOwnersData],
    },
    {
      path: Routes.EditOwner,
      Component: EditOwnerPage,
      type: RouteType.Auth,
    },
    {
      path: Routes.OwnerAttachment,
      Component: OwnerAttachmentsPage,
      type: RouteType.Auth,
    },
    {
      path: Routes.Owners,
      Component: OwnerListPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewTheListOfOwners],
    },
  ],
};

export default ownerModule;
