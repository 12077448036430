import { CATEGORY_ID } from 'modules/products/constants/categoryId';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('products.StorageRecommendations');

const recommendationsForBlood = [
  t('storeUprightBetween', { fromF: 34, toF: 42, fromC: 1, toC: 6 }),
  t('crossMatchBeforeTransfusion'),
  t('mixBeforeUse'),
  t('administerWithFilteredTransfusionSet'),
  t('doNotWhenExpired'),
  t('doNotAddMedicationToBlood'),
];

const recommendationsForPlasma = [
  t('storeFrozenBetween', { fromC: -18, toC: -30 }),
  t('crossMatchBeforeTransfusion'),
  t('mixBeforeUse'),
  t('administerWithFilteredTransfusionSet'),
  `${t('doNotWhenExpired')} ${t('unlessUsingFP')}`,
  t('doNotAddMedicationToPlasma'),
];

export const getRecommendationsByCategoryId = (categoryId: number) => {
  const plasmaCategories = [
    CATEGORY_ID.FROZEN_PLASMA,
    CATEGORY_ID.FRESH_FROZEN_PLASMA,
  ];
  return plasmaCategories.includes(categoryId)
    ? recommendationsForPlasma
    : recommendationsForBlood;
};
