import { Col, Row } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import ModeTabs from 'modules/appointments/components/ModeTabs';
import MonthSelect from 'modules/appointments/components/MonthSelect';
import YearSelect from 'modules/appointments/components/YearSelect';
import { FC } from 'react';
import {
  UseFormResetField,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

import styles from './styles.module.scss';

type PropTypes = {
  value: Date;
  type: CalendarMode;
  onChange: (date: Date) => void;
  onTypeChange: (type: CalendarMode) => void;
  resetField: UseFormResetField<FieldValues>;
  dates: Date[];
  setValue: UseFormSetValue<FieldValues>;
};

const AppointmentsCalendarHeader: FC<PropTypes> = ({
  value,
  type,
  onChange,
  onTypeChange,
  resetField,
  dates,
  setValue,
}) => {
  const handleChange = (date: Date) => {
    const val = dates;
    onChange(date);
    resetField('dates');
    setValue('dates', val, { shouldValidate: true });
  };

  return (
    <Row gutter={8} className={styles.headerContainer}>
      <Col flex={1} />
      <Col>
        <YearSelect size="small" date={value} onChange={handleChange} />
      </Col>
      <Col>
        <MonthSelect size="small" date={value} onChange={handleChange} />
      </Col>
      <Col>
        <ModeTabs size="small" mode={type} onChange={onTypeChange} />
      </Col>
    </Row>
  );
};

export default AppointmentsCalendarHeader;
