import { DefaultOptionType } from 'antd/lib/select';
import { ControlledSelect } from 'modules/common/components';
import { ComponentProps, FC } from 'react';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const ProductQuantitySelect: FC<PropTypes> = (props) => {
  return <ControlledSelect options={quantityOptions} {...props} />;
};

const quantityOptions: DefaultOptionType[] = [1, 2, 3, 4].map((el) => ({
  value: el,
  label: el,
}));

export default ProductQuantitySelect;
