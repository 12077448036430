export enum ContactFields {
  firstName = 'firstName',
  lastName = 'lastName',
  streetAddress = 'streetAddress',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  email = 'email',
  phoneNumber = 'phoneNumber',
}
