import { LocationType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('user.LocationsTable');
export interface ICreateLocationsFormSchema {
  id: string;
  name: string;
  type: LocationType;
  region?: string;
}

export const createLocationsFormSchema: yup.SchemaOf<ICreateLocationsFormSchema> =
  yup.object({
    id: yup.string().required(),
    name: yup
      .string()
      .max(100, t('lessThan', { max: 100 }))
      .strict(true)
      .trim(t('mustBeTrimmed'))
      .required(t('locationNameIsRequired')),
    type: yup
      .mixed<LocationType>()
      .oneOf(Object.values(LocationType))
      .required(),
    region: yup.string(),
  });
