import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export type FilterOption<FieldType> = {
  fieldName: keyof FieldType;
  value: unknown;
};

export type UseFilterObject<FieldType> = {
  options: FilterOption<FieldType>[];
  setFilterOption: (newFilterOption: FilterOption<FieldType>) => void;
  removeFilterOption: (fieldName: keyof FieldType) => void;
  clearOptions: () => void;
  getFilterObject: () => FieldType;
};

export const useFilter = <FieldType,>(
  defaultFilters: FilterOption<FieldType>[] = []
): UseFilterObject<FieldType> => {
  const [options, setOptions] = useState<FilterOption<FieldType>[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const setFilterDataToUrl = (filterOptions: FilterOption<FieldType>[]) => {
    const urlSearchParams = new URLSearchParams(location.search);
    filterOptions.forEach(({ fieldName, value }) => {
      if (!value) {
        urlSearchParams.delete(fieldName as string);
      } else {
        urlSearchParams.set(fieldName as string, JSON.stringify(value));
      }
    });
    navigate(`?${urlSearchParams.toString()}`);
  };

  const removeFilterOptionFromUrl = (fieldName: string) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete(fieldName);
    navigate(`?${urlSearchParams.toString()}`);
  };

  const setFilterOption = (newFilterOption: FilterOption<FieldType>) => {
    const index = options.findIndex(
      ({ fieldName }) => fieldName === newFilterOption.fieldName
    );

    const filterOptionNotExist = index === -1;
    if (filterOptionNotExist) {
      setOptions((oldOptions) => {
        const newOptions = [...oldOptions, newFilterOption];
        setFilterDataToUrl(newOptions);
        return newOptions;
      });
    } else {
      setOptions((oldOptions) => {
        const newOptions = oldOptions.map((filterOption) => {
          return filterOption.fieldName === newFilterOption.fieldName
            ? newFilterOption
            : filterOption;
        });

        setFilterDataToUrl(newOptions);
        return newOptions;
      });
    }
  };

  const removeFilterOption = (fieldName: keyof FieldType) => {
    const newFilterOptions = options.filter(
      (option) => option.fieldName !== fieldName
    );
    setOptions(newFilterOptions);
    removeFilterOptionFromUrl(fieldName as string);
  };

  const clearOptions = () => {
    options.forEach(({ fieldName }) => {
      removeFilterOption(fieldName);
    });
    setOptions([]);
  };

  const getFilterObject = () => {
    const filterObject = options.reduce((acc, current) => {
      return { ...acc, [current.fieldName]: current.value };
    }, {});

    return filterObject as FieldType;
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const filters: FilterOption<FieldType>[] = [];

    const isUrlParamFilterValue = (value: string, key: string) => {
      const invalidValues = ['asc', 'desc'];
      const invalidKeys = ['page'];

      return (
        !invalidValues.includes(value.toLocaleLowerCase()) &&
        !invalidKeys.includes(key.toLocaleLowerCase())
      );
    };

    urlSearchParams.forEach((value, key) => {
      if (!isUrlParamFilterValue(value, key)) {
        return;
      }
      filters.push({
        fieldName: key as keyof FieldType,
        value: value ? JSON.parse(value) : value,
      });
    });

    if (filters.length !== 0) {
      setOptions(filters);
    } else {
      defaultFilters.forEach((option) => {
        setFilterOption(option);
      });
    }
  }, [location]);

  return {
    options,
    setFilterOption,
    removeFilterOption,
    clearOptions,
    getFilterObject,
  };
};
