import { Row, Col } from 'antd';
import { Routes } from 'config/routes';
import { ControlledInput } from 'modules/common/components';
import Link from 'modules/common/components/Link';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { GroupListItemOutput } from 'types';

import styles from './styles.module.scss';

type PropTypes = {
  group: GroupListItemOutput;
  controlled: UseControllerProps<any, string>;
  isEditMode?: boolean;
  errorMessage?: string;
  showCount?: boolean;
};

const Title: FC<PropTypes> = ({
  isEditMode,
  group,
  errorMessage,
  controlled,
  showCount,
}) => {
  return isEditMode ? (
    <Row className={styles.inputWrapper}>
      <Col span={24}>
        <ControlledInput
          status={errorMessage && 'error'}
          className={styles.input}
          controlled={controlled}
        />
      </Col>
      <Col span={24}>{errorMessage}</Col>
    </Row>
  ) : (
    <Link to={`${Routes.Group}/${group.id}`}>{`${group.title} ${
      showCount === undefined || showCount ? `(${group.teamCount})` : ''
    }`}</Link>
  );
};

export default Title;
