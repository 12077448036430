import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import CreateOwnerProfileForm from 'modules/owner/components/CreateOwnerProfileForm';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CreateOwnerProfilePage: FC = () => {
  const { t } = useTranslation('owner.CreateOwnerPage');
  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <CreateOwnerProfileForm />
    </ProfileSideMenuWrapper>
  );
};

export default CreateOwnerProfilePage;
