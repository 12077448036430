import { Col, Row } from 'antd';
import CreateDonorForm from 'modules/donor/components/CreateDonorForm';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CreateDonorPage: FC = () => {
  const { t } = useTranslation('donor.CreateDonorPage');

  return (
    <ProfileSideMenuWrapper title={t('createDonor')}>
      <Row>
        <Col span={24}>
          <CreateDonorForm />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default CreateDonorPage;
