import { DefaultClinicParams } from 'config/default-clinic-params';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import AnimalIdentification from './components/AnimalIdentification';
import GeneralSubmissionForm from './components/GeneralSubmissionForm';
import { Head } from './components/Head';
import History from './components/History';
import Receipt from './components/Receipt';
import styles from './styles.module.scss';

import { RoutineTestFormSchemaType } from '../../RoutineTestForm/schema';
import { FormPage } from '../components/FormPage';

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};

const StateLabSubmissionForm = forwardRef<any, PropTypes>(({ data }, ref) => {
  const { t } = useTranslation('donor.FormsToPrint.StateLabSubmissionForm');

  return (
    <FormPage classNames={styles.form} ref={ref}>
      <Head />
      <GeneralSubmissionForm data={data} />
      <AnimalIdentification data={data} />
      <History />
      <Receipt />
      <p className={styles.footerText}>{t('footerText')}</p>
    </FormPage>
  );
});

export default StateLabSubmissionForm;
