import { EditOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import {
  Button,
  ControlledInput,
  ControlledInputNumber,
  ControlledTextArea,
} from 'modules/common/components';
import { YearMonthSelect } from 'modules/common/components/YearMonthSelect';
import { FormFieldWithComponent } from 'modules/common/types';
import {
  ControlledYesNoRadioGroup,
  DonationFrequencyInput,
  LocationSelect,
  PetGenderSelect,
  PetSpeciesSelect,
} from 'modules/donor/components';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.EditDonorForm.RequiredSection');

export enum RequiredFormSectionField {
  Name = 'name',
  LastName = 'lastName',
  Species = 'species',
  Age = 'age',
  Gender = 'gender',
  Neutered = 'neutered',
  Weight = 'weight',
  MedicationsCourseComment = 'medicationCourse',
  PrimaryVeterinarian = 'primaryVeterinarian',
  Location = 'location',
  DonationFrequency = 'donationFrequency',
  AcceptSimparicaTrio = 'acceptSimparicaTrio',
  DateOfBirth = 'dateOfBirth',
}

type FormFiledType = FormFieldWithComponent<
  RequiredFormSectionField,
  { name: string; disabled?: boolean }
>;

type ParamTypes = {
  onEditOwnerClick?: () => void;
  isEditOwnerAllowed?: boolean;
};

export const getFields = ({
  onEditOwnerClick,
  isEditOwnerAllowed,
}: ParamTypes): FormFiledType[] => {
  const requiredFormSectionFields: FormFiledType[] = [
    {
      name: RequiredFormSectionField.Name,
      label: t('petName'),
      Component: ({ control, name, disabled }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('enterPetName')}
          disabled={disabled}
        />
      ),
    },
    {
      name: RequiredFormSectionField.LastName,
      label: t('lastName'),
      Component: ({ control, name }) => (
        <Input.Group compact className={styles.lastNameContainer}>
          <ControlledInput controlled={{ control, name }} disabled />
          {isEditOwnerAllowed && (
            <Button
              className={styles.editOwnerButton}
              onClick={onEditOwnerClick}
            >
              <EditOutlined />
            </Button>
          )}
        </Input.Group>
      ),
    },
    {
      name: RequiredFormSectionField.Species,
      label: t('species'),
      Component: ({ control, name, disabled }) => (
        <PetSpeciesSelect controlled={{ control, name }} disabled={disabled} />
      ),
    },
    {
      name: RequiredFormSectionField.Age,
      label: t('petAge'),
      Component: ({ control, name }) => (
        <ControlledInputNumber
          min={0}
          className={styles.inputNumber}
          controlled={{ control, name }}
          placeholder={t('enterPetAge')}
          disabled={true}
          addonAfter={t('years').toUpperCase()}
        />
      ),
    },
    {
      name: RequiredFormSectionField.DateOfBirth,
      label: t('dateOfBirth'),
      Component: ({ control, name, disabled }) => (
        <YearMonthSelect
          disabled={disabled}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: RequiredFormSectionField.Gender,
      label: t('petGender'),
      Component: ({ control, name, disabled }) => (
        <PetGenderSelect controlled={{ control, name }} disabled={disabled} />
      ),
    },
    {
      name: RequiredFormSectionField.Weight,
      label: t('petWeight'),
      Component: ({ control, name, disabled }) => (
        <ControlledInputNumber
          className={styles.inputNumber}
          controlled={{ control, name }}
          placeholder={t('enterPetWeight')}
          disabled={disabled}
          addonAfter={t('kg').toUpperCase()}
        />
      ),
    },
    {
      name: RequiredFormSectionField.Location,
      label: t('pleaseSelectLocation'),
      Component: ({ control, name, disabled }) => (
        <LocationSelect
          single
          showSearch
          controlled={{ control, name }}
          disabled={disabled}
        />
      ),
    },
    {
      name: RequiredFormSectionField.Neutered,
      label: t('neutered'),
      Component: ({ control, name, disabled }) => (
        <ControlledYesNoRadioGroup
          controlled={{ control, name }}
          disabled={disabled}
        />
      ),
    },
    {
      name: RequiredFormSectionField.AcceptSimparicaTrio,
      label: t('acceptSimparicaTrio'),
      Component: ({ control, name, disabled }) => (
        <ControlledYesNoRadioGroup
          controlled={{ control, name }}
          disabled={disabled}
        />
      ),
    },
    {
      name: RequiredFormSectionField.MedicationsCourseComment,
      label: t('medicationsHistory'),
      Component: ({ control, name, disabled }) => (
        <ControlledTextArea
          controlled={{ control, name }}
          placeholder={t('enterMedicationHistory')}
          disabled={disabled}
          className={styles.historyArea}
        />
      ),
    },
    {
      name: RequiredFormSectionField.PrimaryVeterinarian,
      label: t('primaryVeterinarian'),
      Component: ({ control, name, disabled }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('enterPrimaryVeterinarian')}
          disabled={disabled}
        />
      ),
    },
    {
      name: RequiredFormSectionField.DonationFrequency,
      label: t('donationFrequency'),
      Component: ({ control, name, disabled }) => (
        <DonationFrequencyInput
          controlled={{ control, name }}
          disabled={disabled}
        />
      ),
    },
  ];

  return requiredFormSectionFields;
};
