import { Table } from 'antd';
import config from 'config';
import { Spin } from 'modules/common/components';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { DonorOutput, useGetUserDonorsListQuery } from 'types.d';

import { columns } from './columns';

type Props = {
  userId: string;
};

const DonorsTable: FC<Props> = ({ userId }) => {
  const pagination = usePagination();
  const getDonorListQuery = useGetUserDonorsListQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        userId,
      },
    },
    onCompleted: ({ getUserDonorsList }) => {
      pagination.setTotal(getUserDonorsList.meta.total);
    },
  });
  const donorList = getDonorListQuery.data?.getUserDonorsList
    .data as DonorOutput[];

  return (
    <Spin spinning={getDonorListQuery.loading}>
      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={donorList}
        pagination={{ ...pagination.config, position: ['bottomCenter'] }}
      />
    </Spin>
  );
};

export default DonorsTable;
