import { Select, SelectProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MovementType } from './enum';
import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & SelectProps;

export const MovementTypeSelect: FC<PropTypes> = ({
  controlled,
  ...restProps
}) => {
  const { t } = useTranslation('donation.MovementTypeSelect');
  const { field } = useController(controlled);
  const [selectedMovementType, setSelectedMovementType] = useState<
    string[] | undefined
  >();

  const onChangeHandler = (value: string) => {
    const [firstLocation, secondLocation] = value as unknown as string[];
    const newSelectedValue = value.length
      ? [secondLocation || firstLocation]
      : undefined;
    setSelectedMovementType(newSelectedValue);
    field.onChange(newSelectedValue?.[0]);
  };

  useEffect(() => {
    if (field.value) {
      setSelectedMovementType([field.value]);
    }
  }, [field.value]);
  const classNames = `${styles.select} ${restProps.className || ''}`;

  return (
    <Select
      {...restProps}
      mode="tags"
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      ref={field.ref}
      placeholder={t('selectMovementType')}
      value={selectedMovementType as any}
      className={classNames}
    >
      {Object.values(MovementType).map((movementType) => {
        return (
          <Select.Option key={movementType}>
            {t(movementType.charAt(0).toLowerCase() + movementType.slice(1))}
          </Select.Option>
        );
      })}
    </Select>
  );
};
