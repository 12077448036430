import {
  AdditionalDonationInfoInput,
  DonationWithAdditionalInfoInput,
  VitalsDonationInfoInput,
} from 'types.d';

import { CreateDonationSchemaType } from './schema';

const ADDITIONAL_FIELD_NAMES: (keyof CreateDonationSchemaType)[] = [
  'numberOfCollectionUnitsUsed',
  'veinUsed',
  'vascularStructure',
  'flowRate',
  'donationPosition',
  'holdersPosition',
  'donorMovement',
  'typeOfMovement',
  'emotionalSupport',
  'dislikes',
  'likes',
  'treatPreferences',
  'shortPrimaryHolderId',
  'shortSecondaryHolderId',
  'shortPokerId',
];

const VITALS_FIELD_NAMES: (keyof CreateDonationSchemaType)[] = [
  'temperature',
  'pulse',
  'respiration',
  'capillaryRefillTime',
  'mucousMembrane',
  'packedCellVolume',
  'totalSolids',
  'weight',
  'working',
  'weatherTemperature',
  'weatherTypes',
  'shouldDonate',
  'collectionSet',
  'jugularVein',
  'unit',
  'actual',
  'shortDonorId',
  'preferences',
  'comment',
  'maxLimit',
  'approvedDonationsMl',
  'donationDate',
];

export const prepareDonationsForCreating = (
  donations: CreateDonationSchemaType[]
): DonationWithAdditionalInfoInput[] => {
  return donations.map((donationData) => {
    const additional = ADDITIONAL_FIELD_NAMES.reduce(
      (vitalsData, fieldName) => {
        return { ...vitalsData, [fieldName]: donationData[fieldName] };
      },
      {} as AdditionalDonationInfoInput
    );
    const vitals = VITALS_FIELD_NAMES.reduce((vitalsData, fieldName) => {
      return { ...vitalsData, [fieldName]: donationData[fieldName] };
    }, {} as VitalsDonationInfoInput);
    return { additional, vitals };
  });
};
