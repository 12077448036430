import config from 'config';
import { useState } from 'react';

type CallbackType<ParamTypes> = (params: ParamTypes) => any;

export const useDebounce = (delay: number = config.DEBOUNCE_DElAY) => {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>();

  const debounce = <ParamTypes>(callback: CallbackType<ParamTypes>) => {
    return (params: ParamTypes) => {
      clearDebounce();
      setTimeoutRef(
        setTimeout(() => {
          callback(params);
        }, delay)
      );
    };
  };

  const clearDebounce = () => {
    timeoutRef && clearTimeout(timeoutRef);
  };

  return { debounce, clearDebounce };
};
