import { SelectProps } from 'antd';
import config from 'config';
import { add, format, setMonth, startOfYear } from 'date-fns';
import { Select } from 'modules/common/components';
import { FC } from 'react';

type PropTypes = {
  date: Date;
  onChange?: (date: Date) => void;
} & Omit<SelectProps, 'value' | 'onChange'>;

const MonthSelect: FC<PropTypes> = ({ date, onChange, ...restProps }) => {
  const month = date.getMonth();
  const START_OF_YEAR = startOfYear(date);
  const months = new Array(12).fill(0).map((_, i) => {
    const dateToFormat = add(START_OF_YEAR, { months: i });
    return format(dateToFormat, config.SHORT_MONTH_FORMAT);
  });

  const handleChange = (changedMonth: number) => {
    const changedDate = setMonth(date, changedMonth);
    onChange && onChange(changedDate);
  };

  const options = months.map((monthName, index) => {
    return {
      value: index,
      label: monthName,
    };
  });

  return (
    <Select
      size="middle"
      dropdownMatchSelectWidth={false}
      value={months[month]}
      adjustDropdownPosition
      onChange={handleChange}
      options={options}
      {...restProps}
    />
  );
};

export default MonthSelect;
