import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Modal, Row, Spin, message } from 'antd';
import { BaseModalProps } from 'modules/common/types';
import OrderDetails from 'modules/orders/components/OrderDetails';
import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_QUERY,
} from 'modules/products/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  OrderOutput,
  PermissionsEnum,
  ProductOutput,
  UserRole,
  useGetProductLazyQuery,
  useUpdateProductMutation,
} from 'types.d';

import Footer from './Footer';
import { getDefaultValues } from './getDefaultValues';
import ProductStatusInput from './ProductStatusInput';
import {
  updateProductTrackingInfoSchema,
  IUpdateProductTrackingInfoSchemaType,
} from './schema';
import styles from './styles.module.scss';
import WoocommerceOrderIdInput from './WoocommerceOrderIdInput';

type PropTypes = BaseModalProps & {
  product?: ProductOutput;
};

const ChangeProductTrackingInfoModal: FC<PropTypes> = ({
  hide,
  onCancel,
  onOk,
  product,
  ...restModalProps
}) => {
  const { t } = useTranslation('products.ChangeProductTrackingInfoModal');
  const [productOrder, setProductOrder] = useState<OrderOutput | undefined>();
  const [getProduct, getProductQuery] = useGetProductLazyQuery();
  const [updateProduct, updateProductMutation] = useUpdateProductMutation({
    refetchQueries: () => [
      {
        query: GET_PRODUCT_QUERY,
        variables: {
          input: {
            id: product?.shortId,
          },
        },
      },
      {
        query: GET_PRODUCT_LIST,
        variables: {
          input: {
            filter: { orderId: productOrder?.id },
          },
        },
      },
    ],
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: ({ updateProduct }) => {
      message.success(t('productUpdated'));
      const defaultValues = getDefaultValues(updateProduct as ProductOutput);
      updateProductTrackingInfoForm.reset(defaultValues, { keepDirty: false });
    },
  });
  const currentUser = useCurrentUser();
  const defaultValues = getDefaultValues(product);
  const updateProductTrackingInfoForm =
    useForm<IUpdateProductTrackingInfoSchemaType>({
      resolver: yupResolver(updateProductTrackingInfoSchema),
      mode: 'onChange',
      defaultValues,
    });
  const { formState } = updateProductTrackingInfoForm;
  const isLoading = updateProductMutation.loading || formState.isValidating;
  const updateProductAllowed = currentUser.isCan(PermissionsEnum.UpdateProduct);
  const okButtonIsDisabled =
    isLoading ||
    !formState.isValid ||
    !formState.isDirty ||
    !updateProductAllowed;
  const isDisabledUntieButton =
    !Boolean(getProductQuery.data?.getProduct?.woocommerceOrderId) ||
    !updateProductAllowed;
  const canUpdateProductStatusManually =
    currentUser.data?.role.value === UserRole.Admin;

  const onCancelHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onCancel && onCancel(e);
    setProductOrder(undefined);
    hide();
  };

  const onOkHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onOk && onOk(e);
    submitForm();
  };

  const submitForm = updateProductTrackingInfoForm.handleSubmit((formData) => {
    const { id, woocommerceOrderId, status } = formData;
    updateProduct({
      variables: {
        input: {
          id,
          status,
          woocommerceOrderId: Number(woocommerceOrderId),
        },
      },
    });
  });

  const dropOrderId = async () => {
    const productId = updateProductTrackingInfoForm.getValues('id');
    updateProduct({
      variables: {
        input: {
          id: productId,
          woocommerceOrderId: null,
        },
      },
      onCompleted: () => {
        setProductOrder(undefined);
      },
    });
  };

  useEffect(() => {
    const defaultValues = getDefaultValues(product);
    updateProductTrackingInfoForm.reset(defaultValues);
  }, [product]);

  useEffect(() => {
    if (product?.shortId) {
      getProduct({
        variables: {
          input: {
            id: product.shortId,
          },
        },
      });
    }
  }, [product?.shortId]);

  return (
    <Modal
      {...restModalProps}
      className={styles.modal}
      width="100%"
      title={t('updateProductInfo')}
      onCancel={onCancelHandler}
      footer={
        <Footer
          okButtonProps={{
            disabled: okButtonIsDisabled,
            onClick: onOkHandler,
          }}
          cancelButtonProps={{
            onClick: onCancelHandler,
          }}
          untieButtonProps={{
            disabled: isDisabledUntieButton,
            onClick: dropOrderId,
          }}
        />
      }
    >
      <Spin spinning={isLoading}>
        <FormProvider {...updateProductTrackingInfoForm}>
          <Form layout="vertical">
            <Row align="middle">
              <WoocommerceOrderIdInput
                product={product}
                onOrderUpdate={setProductOrder}
              />
              {canUpdateProductStatusManually && <ProductStatusInput />}
              {productOrder && (
                <Col span={24}>
                  <OrderDetails order={productOrder} />
                </Col>
              )}
            </Row>
          </Form>
        </FormProvider>
      </Spin>
    </Modal>
  );
};

export default ChangeProductTrackingInfoModal;
