import { DonorStatus } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.DonorStatusSelect');

type ParamTypes = {
  exclude?: DonorStatus[];
};

export const getDonorStatuesOptions = (params?: ParamTypes) => {
  const donorStatuses = Object.values(DonorStatus).filter((status) => {
    return !params?.exclude?.includes(status);
  });

  return donorStatuses.map((value) => {
    return {
      value,
      label: t(value.charAt(0).toLowerCase() + value.slice(1)),
    };
  });
};
