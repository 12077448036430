import { Row, Col } from 'antd';
import { ControlledSelect } from 'modules/common/components';
import Select from 'modules/common/components/Select';
import { ComponentProps, FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { CapillaryRefillTime } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

type Props = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Select>;

const t = getTranslation('donation.CapillaryRefillTimeSelect');

const capillaryRefillTimeOptions = Object.values(CapillaryRefillTime).map(
  (value) => {
    return {
      value,
      label: t(value.charAt(0).toLowerCase() + value.slice(1)),
    };
  }
);

export const CapillaryRefillTimeSelect: FC<Props> = ({
  controlled,
  ...otherProps
}) => {
  return (
    <Row align="stretch">
      <Col flex={1}>
        <ControlledSelect
          {...otherProps}
          options={capillaryRefillTimeOptions}
          defaultValue={undefined}
          controlled={controlled}
          placeholder={t('selectCapillaryRefillTime')}
        />
      </Col>
      <Col className={styles.addon}>{t('sec').toUpperCase()}</Col>
    </Row>
  );
};
