import { Col, Row, message } from 'antd';
import config from 'config';
import { Button, Spin, SearchInput } from 'modules/common/components';
import { useModal, useSort, useDebounce } from 'modules/common/hooks';
import CreateGroupModal from 'modules/donor/components/CreateGroupModal';
import CreateTeamModal from 'modules/donor/components/CreateTeamModal';
import GroupList from 'modules/donor/components/GroupList';
import { useCreateTeamModalContext } from 'modules/donor/contexts/CreateTeamModal';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GroupListSort,
  useFindAllGroupQuery,
  GroupListFilterInput,
} from 'types.d';

const GroupDonorListPage: FC = () => {
  const { t } = useTranslation('donor.Groups');

  const canUpdateDonors =
    useCurrentUser().data?.permissions?.updateDonorsData || false;

  const pagination = usePagination();
  const groupListSort = useSort<GroupListSort>();
  const [filter, setFilter] = useState<GroupListFilterInput>({});
  const { debounce } = useDebounce();
  const { loading, data } = useFindAllGroupQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        sort: groupListSort.options,
        filter,
      },
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: ({ findAllGroup }) => {
      pagination.setTotal(findAllGroup.meta.total);
    },
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchingQuery = e.target.value || undefined;
    pagination.moveTo(1);
    setFilter((previousFilter) => {
      return {
        ...previousFilter,
        searchingQuery,
      };
    });
  };

  const createGroupModal = useModal();
  const createTeamModalContext = useCreateTeamModalContext();
  const groups = data ? data.findAllGroup.data : [];

  const attributes = {
    groups,
    pagination,
    sorting: groupListSort,
  };

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Spin spinning={loading}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={[16, 10]} justify="space-between" align="middle">
              <Col flex={1}>
                <SearchInput
                  placeholder={t('searchGroup')}
                  maxLength={100}
                  onChange={debounce(handleSearch)}
                />
              </Col>
              {canUpdateDonors && (
                <Col>
                  <Button
                    type={'primary'}
                    onClick={() => createGroupModal.show()}
                  >
                    {t('createGroup')}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24}>
            <GroupList {...attributes} />
          </Col>
        </Row>
      </Spin>
      <CreateGroupModal {...createGroupModal.config} />
      {createTeamModalContext && (
        <CreateTeamModal {...createTeamModalContext.modalConfig} />
      )}
    </ProfileSideMenuWrapper>
  );
};

export default GroupDonorListPage;
