import { ControlledSelect } from 'modules/common/components';
import Select from 'modules/common/components/Select';
import { ComponentProps, FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WeatherType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type Props = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Select>;

const t = getTranslation('donation.WeatherTypesSelect');

const weatherTypeOptions = Object.values(WeatherType).map((value) => {
  return {
    value,
    label: t(value.charAt(0).toLowerCase() + value.slice(1)),
  };
});

export const WeatherTypesSelect: FC<Props> = ({
  controlled,
  ...otherProps
}) => {
  const { t } = useTranslation('donation.WeatherTypesSelect');

  return (
    <ControlledSelect
      {...otherProps}
      options={weatherTypeOptions}
      defaultValue={undefined}
      controlled={controlled}
      placeholder={t('selectWeatherTypes')}
      mode="multiple"
      allowClear
    />
  );
};
