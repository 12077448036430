import * as yup from 'yup';

export const checkIsUUID = (str: string): boolean => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  try {
    yup.string().matches(regexExp).validateSync(str);
    return true;
  } catch (error) {
    return false;
  }
};
