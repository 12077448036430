import { Row, Col, Layout } from 'antd';
import SignInForm from 'modules/auth/components/SignInForm';
import { MainLayout, Alert } from 'modules/common/components';
import { useLocationState } from 'modules/common/hooks/useLocationState';
import { NavigationStateKeys } from 'modules/navigation/enums';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const LoginPage = () => {
  const { t } = useTranslation('auth.SignInPage');
  const { data, clearLocationState } = useLocationState();
  const stateMessage = data?.[NavigationStateKeys.Message];

  useEffect(() => {
    clearLocationState();
  }, []);

  return (
    <MainLayout>
      <Layout.Content className={styles.content}>
        <Row justify="center" align="middle" className={styles.formContainer}>
          <Col span={24}>
            <h1 className={styles.title}>{t('signIn')}</h1>
          </Col>
          <Col flex="440px">
            {stateMessage && (
              <Alert message={stateMessage} type="info" closable />
            )}
            <SignInForm />
          </Col>
        </Row>
      </Layout.Content>
    </MainLayout>
  );
};

export default LoginPage;
