import { EmptyCircle } from 'modules/donor/components/RoutineTest/components/EmptyCircle';
import { FilledCircle } from 'modules/donor/components/RoutineTest/components/FilledCircle';
import { IndividualResultsSection } from 'modules/donor/components/RoutineTest/components/IndividualResultsSection';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.RoutineTest.EndocrinologySection');

const preset = {
  title: t('endocrinology'),
  items: [
    {
      label: t('totalT4'),
      details: t('totalT4Subtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
    {
      label: t('freeT4'),
      details: t('freeT4Subtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
    {
      label: t('tsh'),
      details: t('tshSubtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
    {
      label: t('cortisol'),
      details: t('cortisolSubtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
    {
      label: t('fructosamine'),
      details: t('fructosamineSubtitle'),
      dots: [<EmptyCircle color="#D94C2F" />, <FilledCircle color="#D94C2F" />],
    },
  ],
};

export const EndocrinologySection: FC = () => (
  <IndividualResultsSection {...preset} />
);
