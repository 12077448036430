import { Col, Row, ModalProps, ButtonProps } from 'antd';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  okButtonProps?: ModalProps['okButtonProps'];
  cancelButtonProps?: ModalProps['okButtonProps'];
  untieButtonProps?: ButtonProps;
};

const Footer: FC<PropTypes> = ({
  cancelButtonProps,
  okButtonProps,
  untieButtonProps,
}) => {
  const { t } = useTranslation(
    'products.ChangeProductTrackingInfoModal.Footer'
  );

  return (
    <Row gutter={[10, 0]}>
      <Col>
        <Button block {...untieButtonProps}>
          {t('untie')}
        </Button>
      </Col>

      <Col flex={1} />
      <Col>
        <Button {...cancelButtonProps}>{t('cancel')}</Button>
      </Col>
      <Col>
        <Button {...okButtonProps} type="primary">
          {t('update')}
        </Button>
      </Col>
    </Row>
  );
};

export default Footer;
