import config from 'config';
import { DonorOutput } from 'types.d';

export const getDefaultFieldsData = (donor?: DonorOutput) => {
  return {
    vitalsSection: {
      donationDate: new Date(),
      donor: {
        donorName: donor?.name,
        donorId: donor?.shortId.toString(),
      },
      donationInfo: {
        shouldDonate: undefined,
        maxLimit: undefined,
        collectionSet: undefined,
        jugularVein: undefined,
        //Removed position from here
        preferences: undefined,
      },
      environment: {
        weatherTypes: undefined,
        weatherTemperature: undefined,
      },
      quantity: {
        unit: undefined,
        actual: undefined,
        approvedDonationsMl: donor?.approvedDonationML,
      },
      examination: {
        species: donor?.species,
        temperature: undefined,
        pulse: undefined,
        respiration: undefined,
        capillaryRefillTime: config.DEFAULT_CAPILLARY_REFILL_TIME,
        mucousMembrane: config.DEFAULT_MUCOUS_MEMBRANE,
        packedCellVolume: undefined,
        totalSolids: undefined,
        weight: undefined,
        examinationNotes: undefined,
        ineligible: undefined,
        ineligibleReason: undefined,
      },
    },
    additionalInfoSection: {
      donationPosition: undefined,
      donorMovement: undefined,
      flowRate: undefined,
      holdersPosition: undefined,
      numberOfCollectionUnitsUsed: undefined,
      veinUsed: undefined,
      dislikes: undefined,
      emotionalSupport: undefined,
      likes: undefined,
      poker: undefined,
      primaryHolder: undefined,
      secondaryHolder: undefined,
      treatPreferences: undefined,
      typeOfMovement: undefined,
      vascularStructure: undefined,
    },
  };
};
