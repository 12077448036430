import { ColumnsType } from 'antd/lib/table';
import { Tick } from 'modules/common/components';
import { NotificationConfigOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('owner.NotificationsTable');

type ClickCellValueType = {
  newNotification: NotificationConfigOutput;
};

type GetColumnsPropTypes = {
  onCellClick: (value: ClickCellValueType) => void;
};

export const getColumns = ({
  onCellClick,
}: GetColumnsPropTypes): ColumnsType<NotificationConfigOutput> => [
  {
    title: t('notifications'),
    render: ({ name }: NotificationConfigOutput) =>
      t(name.charAt(0).toLowerCase() + name.slice(1)),
    align: 'center',
  },
  {
    title: t('values'),
    align: 'center',
    render: (notification: NotificationConfigOutput) => {
      const showTick = notification.value;
      const onClickHandler = () => {
        onCellClick({
          newNotification: { ...notification, value: !notification.value },
        });
      };
      return <Tick showTick={showTick} onClick={onClickHandler} />;
    },
  },
];
