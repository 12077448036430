import { Col, Row } from 'antd';
import { FormItem } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { DonationInfoFormFiledType } from 'modules/donations/components/DonationInfoSection/fields';
import styles from 'modules/donations/components/EditDonationForm/styles.module.scss';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { donationInfoSchema } from '../../schema';

type Props = {
  fields: DonationInfoFormFiledType[];
  fieldNamePrefix: string;
  sectionNamePrefix?: string;
};

const MobileDonationInfoView: FC<Props> = ({
  fieldNamePrefix,
  fields,
  sectionNamePrefix,
}) => {
  const formContext = useFormContext();
  const isRequired = getIsRequiredInSchema(donationInfoSchema);

  return (
    <Row gutter={[16, 16]}>
      {fields.map(({ name, Component, label }) => {
        const sectionName = sectionNamePrefix ? sectionNamePrefix + '.' : '';
        const fieldName = `${sectionName}${fieldNamePrefix}.${name}`;
        const sectionErrors = sectionNamePrefix
          ? formContext.formState.errors[sectionNamePrefix]
          : formContext.formState.errors;
        const errorMessage = sectionErrors?.[fieldNamePrefix]?.[name]?.message;

        return (
          <Col span={24} key={name}>
            <Col>
              <FormItem
                className={styles.formItem}
                extra={errorMessage}
                validateStatus={errorMessage && 'error'}
                label={label}
                required={isRequired(name)}
              >
                <Component control={formContext.control} name={fieldName} />
              </FormItem>
            </Col>
          </Col>
        );
      })}
    </Row>
  );
};

export default MobileDonationInfoView;
