import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import DonationProfileCardTabs from 'modules/donations/components/DonationProfileCardTabs';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDonationLazyQuery } from 'types.d';

const EditDonationPage: FC = () => {
  const { t } = useTranslation('donation.EditDonationPage');
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || '';
  const donationId = isNaN(Number(id)) ? id : Number(id);
  const [getDonation] = useGetDonationLazyQuery({
    onCompleted: ({ getDonation }) => {
      //THIS LOGIC HAS BEEN PROVIDED TO SEE THIS PAGE ONLY WITH SHORT ID WHICH IS NUMBER
      navigate(`${Routes.Donations}/${getDonation.shortId}`, {
        replace: true,
      });
    },
    onError() {
      navigate(Routes.CollectionDonations);
    },
  });

  useEffect(() => {
    if (typeof donationId === 'string') {
      getDonation({
        variables: {
          input: { id: donationId },
        },
      });
    }
  }, [donationId]);

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Row>
        <Col span={24}>
          {typeof donationId === 'number' && (
            <DonationProfileCardTabs donationId={id} />
          )}
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default EditDonationPage;
