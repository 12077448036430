import * as yup from 'yup';

enum LogLevel {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Http = 'http',
  Verbose = 'verbose',
  Debug = 'debug',
}

export type EnvFile = {
  REACT_APP_SERVER_URI: string;
  REACT_APP_CLIENT_URI: string;
  REACT_APP_UPS_URI: string;
  REACT_APP_WP_ADMIN_URI: string;
  REACT_APP_HOST_WS: string;
  REACT_APP_PRODUCT_CREATION_ALLOWED: boolean;
  REACT_APP_CACHE_EXPIRATION: number;
  REACT_APP_RESEND_EMAIL_CODE_COOL_DOWN: number;

  //Logger
  REACT_APP_LOG_LEVEL: LogLevel;
  REACT_APP_LOG_GROUP_NAME: string;
  REACT_APP_LOG_STREAM_NAME: string;
  REACT_APP_CLOUDWATCH_LOGGER_ENABLED: boolean;

  // AWS
  REACT_APP_CLOUDWATCH_ACCESS_KEY: string;
  REACT_APP_CLOUDWATCH_SECRET_ACCESS_KEY: string;
  REACT_APP_CLOUDWATCH_REGION: string;
};

export const envFileValidationSchema: yup.SchemaOf<EnvFile> = yup.object({
  REACT_APP_SERVER_URI: yup.string().required(),
  REACT_APP_CLIENT_URI: yup.string().required(),
  REACT_APP_UPS_URI: yup.string().required(),
  REACT_APP_WP_ADMIN_URI: yup.string().required(),
  REACT_APP_HOST_WS: yup.string().required(),
  REACT_APP_PRODUCT_CREATION_ALLOWED: yup.boolean().required(),
  REACT_APP_CACHE_EXPIRATION: yup.number().required(),
  REACT_APP_RESEND_EMAIL_CODE_COOL_DOWN: yup.number().required(),

  //Logger
  REACT_APP_LOG_LEVEL: yup
    .mixed<LogLevel>()
    .oneOf(Object.values(LogLevel))
    .required(),
  REACT_APP_LOG_GROUP_NAME: yup.string().required(),
  REACT_APP_LOG_STREAM_NAME: yup.string().required(),
  REACT_APP_CLOUDWATCH_LOGGER_ENABLED: yup.boolean().required(),

  // AWS
  REACT_APP_CLOUDWATCH_ACCESS_KEY: yup.string().required(),
  REACT_APP_CLOUDWATCH_SECRET_ACCESS_KEY: yup.string().required(),
  REACT_APP_CLOUDWATCH_REGION: yup.string().required(),
});
