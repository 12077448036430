import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Divider, Form, Button, message } from 'antd';
import { Spin } from 'modules/common/components';
import { GET_REQUEST_LIST } from 'modules/requests/graphql/queries';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useCreateDonorForUserMutation,
  AnimalSpecies,
  BloodType,
} from 'types.d';

import OptionalFormSection from './OptionalSection';
import PetFormSection from './RequiredSection';
import { createDonorSchema, CreateDonorSchemaType } from './schema';

const CreateDonorForm: FC = () => {
  const { t } = useTranslation('donor.CreateDonorForm');
  const [createDonor, createDonorMutation] = useCreateDonorForUserMutation({
    refetchQueries: [GET_REQUEST_LIST],
    onCompleted: () => {
      message.success(t('donorCreated'));
      createDonorForm.reset();
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const isLoading = createDonorMutation.loading;
  const createDonorForm = useForm<CreateDonorSchemaType>({
    resolver: yupResolver(createDonorSchema),
    mode: 'onChange',
  });

  const createDonorHandler = createDonorForm.handleSubmit((data) => {
    const {
      bloodType,
      teamId,
      donorType,
      microchipNumber,
      distinguishableMarks,
      comment,
      color,
      breed,
      hasMicrochip,
    } = data.optional;
    const {
      dateOfBirth,
      gender,
      name,
      neutered,
      primaryVeterinarian,
      species,
      weight,
      medicationCourse,
      location,
    } = data.required;

    createDonor({
      variables: {
        input: {
          donorData: {
            dateOfBirth,
            breed,
            bloodType,
            color,
            gender,
            name,
            neutered,
            primaryVeterinarian,
            species,
            status: undefined,
            teamId,
            type: donorType,
            weight,
            distinguishableMarks,
            medicationCourse,
            microchipNumber,
            comment,
            location,
            hasMicrochip,
          },
          userId: data.required.userId,
        },
      },
    });
  });

  const resetForm = () => createDonorForm.reset();

  useEffect(() => {
    createDonorForm.reset({
      required: {
        species: AnimalSpecies.Dog,
      },
      optional: {
        bloodType: BloodType.Unknown,
      },
    });
  }, [createDonorForm]);

  const specie = createDonorForm.watch('required.species');
  const weight = createDonorForm.watch('required.weight');
  const userId = createDonorForm.watch('required.userId');

  useEffect(() => {
    if (weight) {
      createDonorForm.trigger('required.weight');
    }
  }, [specie, weight]);

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...createDonorForm}>
        <Form labelCol={{ span: 12 }}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" gutter={[10, 0]}>
                <Col sm={12} span={24}>
                  <PetFormSection fieldNamePrefix="required" />
                </Col>
                <Col sm={0} span={24}>
                  <Divider />
                </Col>
                <Col sm={12} span={24}>
                  <OptionalFormSection
                    fieldNamePrefix="optional"
                    userId={userId}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <Button
                onClick={resetForm}
                disabled={!createDonorForm.formState.isDirty}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={!createDonorForm.formState.isValid}
                onClick={createDonorHandler}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Spin>
  );
};

export default CreateDonorForm;
