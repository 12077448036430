export function shortenTextWithEllipsis(
  longText: string,
  maxLength: number
): string {
  if (longText.length <= maxLength) {
    return longText;
  }

  return longText.substring(0, maxLength) + '...';
}
