import { Routes } from 'config/routes';
import EditUserForm from 'modules/admin/components/EditUserForm';
import { ITabsItem } from 'modules/common/components/CardTabs';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import NotificationTable from 'modules/notification/components/NotificationTable';
import AccountForm from 'modules/owner/components/AccountForm';
import EditProfileForm from 'modules/owner/components/EditProfileForm';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'types.d';

const ProfileCardTabs: FC = () => {
  const { t } = useTranslation('user.ProfileCardTabs');
  const currentUser = useCurrentUser();
  const isCurrentUserOwner = currentUser.data?.role.value === UserRole.Owner;

  const contactInfoItem: ITabsItem = {
    title: t('contact'),
    content: <EditProfileForm />,
    key: Routes.Profile,
  };

  const accountItem: ITabsItem = {
    title: t('account'),
    content: <AccountForm />,
    key: Routes.ProfileAccount,
  };

  const editUserFormItem: ITabsItem = {
    title: t('account'),
    content: <EditUserForm ownProfile={true} />,
    key: Routes.Profile,
  };

  const notificationsItem: ITabsItem = {
    title: t('notifications'),
    content: <NotificationTable />,
    key: Routes.ProfileNotifications,
  };

  const ownerTabs = [contactInfoItem, accountItem, notificationsItem];
  const internalTeamTabs = [editUserFormItem, notificationsItem];

  return (
    <NavigationCardTabs
      items={isCurrentUserOwner ? ownerTabs : internalTeamTabs}
    />
  );
};

export default ProfileCardTabs;
