import { DefaultOptionType } from 'antd/lib/select';
import { ControlledSelect } from 'modules/common/components';
import { CATEGORY_ID } from 'modules/products/constants/categoryId';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetWoocommerceCategoriesQuery } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
};

const categoriesBlackList = [
  CATEGORY_ID.UNCATEGORIZED,
  CATEGORY_ID.MEDICAL_SUPPLY,
];

const CategorySelect: FC<PropTypes> = ({ controlled }) => {
  const { t } = useTranslation('products.CategorySelect');
  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
  });
  const categories =
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories.reduce(
      (ctgs, { id, name }) => {
        if (categoriesBlackList.includes(id)) {
          return ctgs;
        }
        return [...ctgs, { value: id, label: name }];
      },
      [] as DefaultOptionType[]
    );
  return (
    <ControlledSelect
      options={categories}
      loading={getWoocommerceCategoriesQuery.loading}
      className={styles.select}
      controlled={controlled}
      placeholder={t('selectProductCategory')}
    />
  );
};

export default CategorySelect;
