export default {
  SearchInput: {
    searchBox: 'Search box',
  },
  DeletePopconfirm: {
    doYouWantToDelete: 'Are you sure you want to delete?',
    yes: 'Yes',
    no: 'No',
  },
  Sorter: {
    selectSorter: 'Select sorter',
  },
  EditAction: {
    sureToEdit: 'Sure to edit?',
  },
  EditableTableCell: {
    pleaseInput: 'Please input {{field}}',
  },
  Dragger: {
    title: 'Click or drag file to this area to upload',
  },
  GraphqlErrorMessages: {
    networkError:
      'Failed to fetch! Once you back online the request will be sent again!',
  },
  NotFoundPage: {
    subTitle: 'Sorry, the page you visited does not exist.',
    title: '404',
    backHome: 'Back home',
  },
  OfflineMutationsProvider: {
    unsavedDataExist: "You have unsaved data, please click 'OK' to save it",
    dataHasBeenSaved: 'All offline data has been saved!',
  },
  RestrictedLink: {
    noPermission: 'You don`t have permission',
  },
  CacheManager: {
    saveCache: 'Save cache',
    clearCache: 'Clear cache',
  },
  yearMonthSelect: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    selectMonth: 'Select month',
    selectYear: 'Select year',
  },
};
