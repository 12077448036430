import { Grid, message } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { Spin } from 'modules/common/components';
import { useSort, useModal } from 'modules/common/hooks';
import DonorDonationCardList from 'modules/donor/components/DonorDonationCardList';
import {
  AddDonationCommentModal,
  DonorDonationsTable,
} from 'modules/donor/components/index';
import { AddDonationCommentProps } from 'modules/donor/types';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DonationListSort,
  DonationOutput,
  useGetDonorQuery,
  useGetSpecificDonorDonationsListQuery,
  useGetWoocommerceCategoriesQuery,
} from 'types.d';

export const DonorDonationsList: FC = () => {
  const breakpoints = Grid.useBreakpoint();
  const isBreakpointsEmpty = Object.keys(breakpoints).length === 0;
  const params = useParams();
  const navigate = useNavigate();
  const shortDonorId = params.id || '';
  const pagination = usePagination();
  const donationListSort = useSort<DonationListSort>();
  const getDonorQuery = useGetDonorQuery({
    variables: {
      input: {
        id: shortDonorId,
      },
    },
    onError: (err) => {
      message.error(err.message);
      if (err.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.IndividualDonors);
    },
  });
  const donorId = getDonorQuery.data?.getDonor.id;
  const addDonationCommentModal = useModal<AddDonationCommentProps>();
  const getDonationListQuery = useGetSpecificDonorDonationsListQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        sort: donationListSort.options,
        filter: {
          donorId: donorId as string,
        },
      },
    },
    onCompleted: ({ getSpecificDonorDonationsList }) => {
      pagination.setTotal(getSpecificDonorDonationsList.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
    skip: !Boolean(donorId),
  });
  const donations = getDonationListQuery.data?.getSpecificDonorDonationsList
    .data as DonationOutput[];

  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
  });
  const productCategories =
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories.reduce(
      (categories, category) => {
        return { ...categories, [category.id]: category.name };
      },
      {} as Record<number, string>
    );

  const addCommentHandler = (donationId: string, previousValue?: string) => {
    addDonationCommentModal.show({
      donationId: donationId,
      previousValue: previousValue || '',
    });
  };

  const props = {
    donations,
    addCommentHandler,
    sort: donationListSort,
    pagination,
    productCategories,
  };

  const isLoading =
    getDonationListQuery.loading ||
    getWoocommerceCategoriesQuery.loading ||
    getDonorQuery.loading;

  return (
    <Spin spinning={isLoading}>
      {!isBreakpointsEmpty &&
        (breakpoints.md ? (
          <DonorDonationsTable {...props} />
        ) : (
          <DonorDonationCardList {...props} />
        ))}
      <AddDonationCommentModal {...addDonationCommentModal.config} />
    </Spin>
  );
};
