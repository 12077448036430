import { Popconfirm } from 'antd';
import { Checkbox } from 'modules/common/components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductOutput, ProductStatus } from 'types.d';

import styles from './styles.module.scss';

const { Collected } = ProductStatus;

type PropTypes = {
  product: ProductOutput;
  onChecked?: () => void;
};

const ENABLED_FOR_STATUSES: ProductStatus[] = [Collected];

const AvailabilityCheckbox: FC<PropTypes> = ({ onChecked, product }) => {
  const { t } = useTranslation('products.AvailabilityCheckbox');
  const [isChecked, setIsChecked] = useState(product.published);
  const disabled = !ENABLED_FOR_STATUSES.includes(product.status) || isChecked;
  const checkboxClassNames = `${disabled ? styles.disabled : ''}`;

  const onCheckedHandler = () => {
    setIsChecked(true);
    onChecked && onChecked();
  };

  return (
    <Popconfirm
      title={t('makeAvailable')}
      onConfirm={onCheckedHandler}
      okText={t('yes')}
      cancelText={t('no')}
      disabled={disabled}
    >
      <Checkbox className={checkboxClassNames} checked={isChecked} />
    </Popconfirm>
  );
};

export default AvailabilityCheckbox;
