import {
  emailSchema,
  usernameSchema,
  phoneNumberSchema,
  inviteUserRoleSchema,
} from 'modules/user/schemas';
import { InviteUserRole } from 'types.d';
import * as yup from 'yup';

import { InviteFields } from './enum';

const { firstName, lastName, email, phoneNumber, role } = InviteFields;

type InviteSchema = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: InviteUserRole;
};

const inviteSchema: yup.SchemaOf<InviteSchema> = yup.object({
  [firstName]: usernameSchema,
  [lastName]: usernameSchema,
  [email]: emailSchema,
  [phoneNumber]: phoneNumberSchema,
  [role]: inviteUserRoleSchema,
});

export { inviteSchema };
export type { InviteSchema };
