import { format } from 'date-fns';
import { AppointmentOutput } from 'types';

export const formatAppointments = (appointments: AppointmentOutput[]) => {
  return appointments.reduce((formattedAppointments, appointment) => {
    const dateKey = getDateKey(new Date(appointment.start));
    return {
      ...formattedAppointments,
      [dateKey]: [...(formattedAppointments?.[dateKey] || []), appointment],
    };
  }, {} as { [key: string]: AppointmentOutput[] });
};

export const getDateKey = (date: Date): string => {
  return format(date, 'MM.dd.yyyy');
};
