import { DonationProductOutput, DonationOutput } from 'types';

export const getUniqueProductTypes = (donation: DonationOutput) => {
  return donation.products?.reduce((resultArray, currentProduct) => {
    return resultArray.find(
      (product) =>
        product.woocommerceCategoryId === currentProduct.woocommerceCategoryId
    )
      ? resultArray
      : [...resultArray, currentProduct];
  }, [] as DonationProductOutput[]);
};
