import { Row, Col } from 'antd';
import { getOptions } from 'modules/donor/components/LabworkCardInfo/options';
import { FC } from 'react';
import { LabworkOutput, LabworkStatus } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  labwork: LabworkOutput;
  updateLabworkHandler: (data: LabworkOutput) => void;
  updateLabworkStatusHandler: (id: string, status: LabworkStatus) => void;
  canUpdateLabwork: boolean;
};

const LabworkCardInfo: FC<PropTypes> = (props) => {
  return (
    <Row gutter={[10, 0]}>
      {getOptions(props).map(({ label, value }, i) => {
        return (
          <Col
            span={24}
            key={`${props.labwork?.id}_${i}`}
            className={styles.option}
          >
            <Row gutter={[10, 0]}>
              <Col span={12} className={styles.optionLabel}>
                {label}:
              </Col>
              <Col span={12}>{value}</Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};
export default LabworkCardInfo;
