import { ControlledSelect } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { animalGender } from './options';

type PropTypes = Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const RoutineTestGenderSelect: FC<PropTypes> = (props) => {
  const { t } = useTranslation('donor.ControlledPetGenderSelect');

  return (
    <ControlledSelect
      adjustDropdownPosition
      options={animalGender}
      placeholder={t('pickGender')}
      {...props}
    />
  );
};

export default RoutineTestGenderSelect;
