import colors from 'config/colors.scss';
import { DonationOutput } from 'types';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.PulseChart');

export const getConfig = (donationData: DonationOutput[]) => {
  return {
    data: donationData,
    xField: 'donationDate',
    yField: 'pulse',
    color: colors.pastelBlue,
    xAxis: {
      title: {
        text: t('date'),
      },
    },
    yAxis: {
      title: {
        text: t('pulse'),
      },
      alias: t('pulse'),
    },
  };
};
