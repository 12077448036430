import React from 'react';

import styles from './styles.module.scss';

import FormInput from '../Input';

type CheckboxParams = {
  fieldName: string;
  withInput?: boolean;
  inputValue?: string;
  isChecked?: boolean;
};

type PropTypes = {
  options: (string | CheckboxParams)[];
  className?: string;
  label?: string;
  labelPlacement?: 'top' | 'left';
  direction?: 'row' | 'column';
  size?: 'sm' | 'default';
  active?: string;
};

export default function CheckboxGroup({
  label,
  options,
  direction,
  className,
  size,
  active,
  labelPlacement = 'top',
}: PropTypes) {
  return (
    <div className={labelPlacement === 'left' ? styles.labelLeft : ''}>
      {label && <p className={styles.title}>{label}</p>}
      <div
        className={`${styles.checkboxes} ${
          direction === 'column' && styles.column
        } ${size == 'sm' && styles.sm} ${className}`}
      >
        {options.map((option, i) => {
          const optionText =
            typeof option === 'string' ? option : option.fieldName;
          const optionParams = typeof option !== 'string' ? option : null;
          const withInput = typeof option === 'object' && option.withInput;
          const isActive = active === option || optionParams?.isChecked;
          return (
            <span
              key={i}
              className={`${styles.checkboxButtonWrapper} checkbox`}
            >
              <input
                type="checkbox"
                name={optionText}
                readOnly
                checked={isActive}
              />
              {withInput ? (
                <FormInput
                  label={optionText}
                  classNames={size === 'sm' ? styles.sm : ''}
                  value={option.inputValue}
                />
              ) : (
                <label className={styles.radioButtonLabel}>{optionText}</label>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
}
