import { Image, ImageProps } from 'antd';
import { FC } from 'react';

const NavbbLogo: FC<ImageProps> = ({ width, height, ...restProps }) => {
  return (
    <Image
      {...restProps}
      preview={false}
      src={`https://i0.wp.com/www.navbb.com/wp-content/uploads/2019/06/cropped-NAVBB-Logo-2.jpg?fit=${width}%2C${height}&ssl=1`}
    />
  );
};

export default NavbbLogo;
