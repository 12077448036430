import { capillaryRefillTimeSchema, mMSchema } from 'modules/donations/schemas';
import { petSpeciesSchema, petWeightSchemaInKg } from 'modules/donor/schemas';
import {
  AnimalSpecies,
  CapillaryRefillTime,
  IneligibleReason,
  Mm,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donation.CreateDonationForm.Examination');

type ExaminationSchemaType = {
  temperature: number;
  pulse: number;
  respiration: number;
  capillaryRefillTime: CapillaryRefillTime;
  mucousMembrane: Mm;
  packedCellVolume: number;
  totalSolids: number;
  weight: number;
  examinationNotes?: string;
  ineligible?: boolean;
  ineligibleReason?: IneligibleReason | null;
  ineligibleReasonDetails?: string | null;
  species?: AnimalSpecies;
};

const examinationSchema: yup.SchemaOf<ExaminationSchemaType> = yup.object({
  species: petSpeciesSchema.notRequired(),
  temperature: yup
    .number()
    .min(90, t('invalid', { field: t('temperature') }))
    .max(115, t('invalid', { field: t('temperature') }))
    .typeError(t('invalid', { field: t('temperature') }))
    .required(t('required', { field: t('temperature') })),

  pulse: yup
    .number()
    .min(10, t('invalid', { field: t('pulse') }))
    .max(225, t('invalid', { field: t('pulse') }))
    .typeError(t('invalid', { field: t('pulse') }))
    .required(t('required', { field: t('pulse') })),
  respiration: yup
    .number()
    .min(1, t('invalid', { field: t('respiration') }))
    .max(500, t('invalid', { field: t('respiration') }))
    .typeError(t('invalid', { field: t('respiration') }))
    .required(t('required', { field: t('respiration') })),
  capillaryRefillTime: capillaryRefillTimeSchema,
  mucousMembrane: mMSchema,
  packedCellVolume: yup
    .number()
    .typeError(t('invalid', { field: t('packedCellVolume') }))
    .required(t('required', { field: t('packedCellVolume') }))
    .min(0, t('invalid', { field: t('packedCellVolume') }))
    .max(100, t('lessThan', { max: 100 })),
  totalSolids: yup
    .number()
    .min(1, t('invalid', { field: t('totalSolids') }))
    .max(11, t('invalid', { field: t('totalSolids') }))
    .typeError(t('invalid', { field: t('totalSolids') }))
    .required(t('required', { field: t('totalSolids') })),
  weight: petWeightSchemaInKg,
  examinationNotes: yup.string(),
  ineligible: yup.boolean(),
  ineligibleReasonDetails: yup.string().optional().nullable(),
  ineligibleReason: yup
    .mixed()
    .nullable()
    .optional()
    .when('ineligible', {
      is: true,
      then: yup
        .mixed()
        .oneOf(Object.values(IneligibleReason))
        .required(t('required', { field: t('ineligibleReason') })),
    }),
});

export { examinationSchema };
export type { ExaminationSchemaType };
