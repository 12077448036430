import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Row, Col } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import { Button } from 'modules/common/components';
import { UserOutput, UserListSort, UserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('admin.UsersListPage');
const userRoles = Object.values(UserRole);
const internalUserFilters = userRoles.reduce((roleFilters, role) => {
  if (role === UserRole.Owner) {
    return roleFilters;
  }
  return [
    ...roleFilters,
    {
      text: t(`${role[0].toLowerCase()}${role.substring(1)}`),
      value: role,
    },
  ];
}, [] as any[]);

type ColumnsProps = {
  deleteHandler?: (userId: string) => void;
  onClickEditIcon?: (userId: string) => void;
  canDelete: boolean;
  canUpdateUserInfo: boolean;
  currentUserId?: string;
  showSorterTooltip?: boolean;
};

export const getColumns = ({
  onClickEditIcon,
  deleteHandler,
  canDelete,
  canUpdateUserInfo,
  currentUserId,
  showSorterTooltip,
}: ColumnsProps): ColumnsType<UserOutput> => {
  return [
    {
      title: t('firstName'),
      render: (record: UserOutput) => record.firstName,
      key: UserListSort.FirstName,
      sorter: true,
      showSorterTooltip,
      align: 'center',
    },
    {
      title: t('lastName'),
      render: (record: UserOutput) => record.lastName,
      key: UserListSort.LastName,
      sorter: true,
      showSorterTooltip,
      align: 'center',
    },
    {
      title: t('email'),
      render: ({ email }: UserOutput) => email,
      align: 'center',
    },
    {
      title: t('role'),
      render: ({ role }: UserOutput) =>
        role
          ? t(`${role.value[0].toLowerCase()}${role.value.substring(1)}`)
          : '',
      align: 'center',
      filters: internalUserFilters,
    },
    {
      render: (record: UserOutput) => {
        const isMe = currentUserId === record.id;
        return !isMe ? (
          <Row justify="space-around">
            {canUpdateUserInfo && (
              <Col>
                <Button
                  type="text"
                  onClick={() => onClickEditIcon && onClickEditIcon(record.id)}
                  icon={<EditOutlined />}
                />
              </Col>
            )}
            {canDelete && (
              <Col>
                <Popconfirm
                  title={t('areYouSureYouWantToDeleteUser')}
                  onConfirm={() => deleteHandler && deleteHandler(record.id)}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <Button type="text" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Col>
            )}
          </Row>
        ) : (
          t('me')
        );
      },
      align: 'center',
    },
  ];
};
