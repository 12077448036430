import config from 'config';
import { RoutineTestGender } from 'modules/donor/components/RoutineTestGenderSelect/enum';
import {
  AnimalGender,
  AnimalSpecies,
  BloodType,
  DonorStatus,
  DonorType,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donor.ValidationErrors');

export const petAgeSchema = yup
  .number()
  .integer(t('mustBeInt'))
  .typeError(t('mustBeNumber'))
  .required(t('required', { field: t('petAge') }))
  .min(1, t('olderThan', { min: 1 }))
  .max(7, t('youngerThan', { max: 7 }));

export const petDateOfBirthSchema = yup
  .date()
  .typeError(t('mustBeValidDate'))
  .required(t('required', { field: t('petBirthDate') }))
  .max(new Date(), t('mustBeInPast'));

export const petNameSchema = yup
  .string()
  .strict(true)
  .trim(t('mustBeTrimmed'))
  .typeError(t('mustBeString'))
  .required(t('required', { field: t('petName') }))
  .min(1, t('longerThan', { min: 1, field: t('petName') }))
  .max(30, t('shorterThan', { max: 30, field: t('petName') }))
  .matches(/^[a-z ,.'-]+$/i, t('onlyAlphabets')); //Alphabets & spaces;

export const petWeightSchema = yup
  .number()
  .typeError(t('mustBeNumber'))
  .required(t('required', { field: t('petWeight') }))
  .when(['species'], {
    is: (specie: AnimalSpecies) => specie === AnimalSpecies.Dog,
    then: yup
      .number()
      .min(
        config.MIN_DOG_WEIGHT,
        t('minWeight', { min: config.MIN_DOG_WEIGHT, type: 'pounds' })
      )
      .typeError(t('mustBeNumber'))
      .required(t('required', { field: t('petWeight') })),
    otherwise: yup
      .number()
      .min(
        config.MIN_CAT_WEIGHT,
        t('minWeight', { min: config.MIN_CAT_WEIGHT, type: 'pounds' })
      )
      .typeError(t('mustBeNumber'))
      .required(t('required', { field: t('petWeight') })),
  });

export const petApprovedDonationMLSchema = yup
  .number()
  .typeError(t('mustBeNumber'));

export const petWeightSchemaInKg = yup
  .number()
  .typeError(t('mustBeNumber'))
  .required(t('required', { field: t('petWeight') }))
  .when(['species'], {
    is: (specie: AnimalSpecies) => specie === AnimalSpecies.Dog,
    then: yup
      .number()
      .min(
        config.MIN_DOG_WEIGHT_KG,
        t('minWeight', { min: config.MIN_DOG_WEIGHT_KG, type: 'kilograms' })
      )
      .typeError(t('mustBeNumber'))
      .required(t('required', { field: t('petWeight') })),
    otherwise: yup
      .number()
      .min(
        config.MIN_CAT_WEIGHT_KG,
        t('minWeight', { min: config.MIN_CAT_WEIGHT_KG, type: 'kilograms' })
      )
      .typeError(t('mustBeNumber'))
      .required(t('required', { field: t('petWeight') })),
  });

export const petGenderSchema = yup
  .mixed<AnimalGender>()
  .oneOf(Object.values(AnimalGender))
  .required(t('required', { field: t('petGender') }));

export const routineTestGenderSchema = yup
  .mixed<RoutineTestGender>()
  .oneOf(Object.values(RoutineTestGender))
  .required(t('required', { field: t('petGender') }));

export const petSpeciesSchema = yup
  .mixed<AnimalSpecies>()
  .oneOf(Object.values(AnimalSpecies))
  .required(t('required', { field: t('species') }));

export const bloodTypeSchema = yup
  .mixed<BloodType>()
  .oneOf(Object.values(BloodType));

export const petMarksSchema = yup
  .string()
  .max(255, t('shorterThan', { max: 255, field: t('distinguishableMarks') }));

export const donorTypeSchema = yup
  .mixed<DonorType>()
  .oneOf(Object.values(DonorType));

export const donorStatusSchema = yup
  .mixed<DonorStatus>()
  .oneOf(Object.values(DonorStatus));

export const teamTitleSchema = yup
  .string()
  .typeError(t('mustBeString'))
  .min(3)
  .matches(/^[A-Za-z0-9\s]+$/g, t('onlyValidSymbols'))
  .test({
    test: (value) => value?.trim() === value,
    message: t('mustBeTrimmed'),
  })
  .max(50);

export const groupTitleSchema = yup
  .string()
  .typeError(t('mustBeString'))
  .min(3)
  .max(50)
  .matches(/^\S.*\S$|^\S$/, t('noSpacesAtEdgesMessage'));

export const breedSchema = yup
  .string()
  .nullable()
  .transform((value) => (value === '' ? null : value))
  .typeError(t('mustBeString'))
  .min(2, t('longerThan', { min: 2, field: t('breed') }))
  .max(50, t('shorterThan', { max: 50, field: t('breed') }))
  .matches(/^[aA-zZ ]+$/, t('onlyAlphabets'));

export const colorSchema = yup
  .string()
  .nullable()
  .transform((value) => (value === '' ? null : value))
  .typeError(t('mustBeString'))
  .min(2, t('longerThan', { min: 2, field: t('color') }))
  .max(50, t('shorterThan', { max: 50, field: t('color') }))
  .matches(/^[aA-zZ ]+$/, t('onlyAlphabets'));
