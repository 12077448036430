export default {
  ProfileSideMenuWrapper: {
    profile: 'Profile',
    donors: 'Donors',
    appointments: 'Appointments',
    logout: 'Logout',
    client: 'Client',
    server: 'Server',
    cacheEnabled: 'Cache is enabled',
    cacheDisabled: 'Cache is disabled',
  },
};
