import { Col } from 'antd';
import { FormItem } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { FC, useEffect, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

import { getFields } from './fields';
import { requiredFormSectionSchema } from './schema';

import { CreateDonorSchemaType } from '../schema';

type PropTypes = {
  fieldNamePrefix: string;
};

interface ICreateDonorFormErrors {
  [key: string]:
    | {
        [key: string]: FieldError | undefined;
      }
    | undefined;
}

const RequiredFormSection: FC<PropTypes> = ({ fieldNamePrefix }) => {
  const { control, formState, watch, resetField } =
    useFormContext<CreateDonorSchemaType>();
  const region = watch('required.region');
  const requiredFormSectionFields = useMemo(
    () => getFields({ region }),
    [fieldNamePrefix, region]
  );
  useEffect(() => {
    resetField('required.location');
  }, [region]);
  const isRequired = getIsRequiredInSchema(requiredFormSectionSchema);
  const errors = formState.errors as ICreateDonorFormErrors;

  return (
    <>
      {requiredFormSectionFields.map(({ Component, label, name }) => {
        const fieldName = `${fieldNamePrefix}.${name}`;
        const errorMessage = errors[fieldNamePrefix]?.[name]?.message;
        const required = isRequired(name);
        return (
          <Col span={24} key={fieldName}>
            <FormItem
              label={label}
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
              required={required}
            >
              <Component control={control} name={fieldName} />
            </FormItem>
          </Col>
        );
      })}
    </>
  );
};

export default RequiredFormSection;
