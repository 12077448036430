import { Col, Row } from 'antd';
import { BundleTabs } from 'modules/donor/components/RoutineTest/components/BundleTabs';
import { Marks } from 'modules/donor/components/RoutineTest/components/Marks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const BundleSection: FC = () => {
  const { t } = useTranslation('donor.RoutineTest.BundleSection');

  return (
    <div className={styles.bundleSection}>
      <Col className={styles.title}>
        <strong>{t('title')}</strong>
      </Col>
      <Col className={styles.explanation}>{t('explanationFirstRow')}</Col>
      <Col className={styles.explanation}>{t('explanationSecondRow')}</Col>
      <Row className={styles.bundleTabs}>
        <BundleTabs />
      </Row>
      <Marks />
    </div>
  );
};
