import * as yup from 'yup';

import { AddCommentFields } from './fields';

const { comment } = AddCommentFields;

type AddDonationCommentSchemaType = {
  comment?: string;
};

const addDonationCommentSchema: yup.SchemaOf<AddDonationCommentSchemaType> =
  yup.object({
    [comment]: yup.string(),
  });

export { addDonationCommentSchema };
export type { AddDonationCommentSchemaType };
