import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Select } from 'modules/common/components';
import { FC, useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  LocationType,
  useGetLocationsQuery,
  useGetPublicLocationsQuery,
} from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
  multiply?: boolean;
  publicLocationsOnly?: boolean;
  single?: boolean;
  tags?: boolean;
  locationTypes?: LocationType[];
  regions?: string[];
} & SelectProps;

const LocationSelect: FC<PropTypes> = ({
  controlled,
  className,
  multiply,
  publicLocationsOnly,
  single,
  locationTypes,
  tags,
  regions,
  ...restProps
}) => {
  const { t } = useTranslation('donor.LocationSelect');
  const classNames = `${styles.locationSelect} ${className || ''}`;
  const { field } = useController(controlled);
  const [isOpen, setIsOpen] = useState<boolean>();

  const locationsQuery = useGetLocationsQuery({
    skip: publicLocationsOnly,
    variables: {
      input: {
        take: 100, //TODO: change it after adding here the searching functionality.
        filter: {
          types: locationTypes,
          regionIds: regions,
        },
      },
    },
  });
  const publicLocationsQuery = useGetPublicLocationsQuery({
    skip: !publicLocationsOnly,
    variables: {
      input: {
        take: 100, //TODO: change it after adding here the searching functionality.
        filter: {
          regionIds: regions,
        },
      },
    },
  });
  const [selectLocationValue, setSelectLocationValue] = useState<string>(
    field.value ? field.value : undefined
  );

  useEffect(() => {
    if (field.value) {
      setSelectLocationValue(field.value);
    }
  }, [field.value]);

  const isLoading = publicLocationsQuery.loading || locationsQuery.loading;
  const onChangeHandler = (value: string) => {
    setIsOpen(false);
    setSelectLocationValue(value);
    if (single) {
      return field.onChange(value);
    }
    const locations = value as unknown as string[];
    const [firstLocation, secondLocation] = locations;
    if (multiply) {
      field.onChange(locations);
      return;
    }
    const newSelectedValue = value.length
      ? [secondLocation || firstLocation]
      : undefined;
    field.onChange(newSelectedValue?.[0]);
  };
  const receivedLocations =
    locationsQuery.data?.getLocations.data ||
    publicLocationsQuery.data?.getPublicLocations.data;
  const locations: DefaultOptionType[] | undefined = receivedLocations?.map(
    ({ name }) => {
      return {
        label: name,
        value: name,
      };
    }
  );

  useEffect(() => {
    field.value === undefined && setSelectLocationValue(field.value);
  }, [field.value]);

  const selectMode: 'tags' | 'multiple' | undefined = single
    ? undefined
    : tags
    ? 'tags'
    : 'multiple';

  return (
    <Select
      {...restProps}
      adjustDropdownPosition
      allowClear
      open={isOpen}
      onDropdownVisibleChange={(open) => setIsOpen(open)}
      className={classNames}
      options={locations}
      loading={isLoading}
      mode={selectMode}
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      ref={field.ref}
      placeholder={t('selectLocation')}
      value={selectLocationValue}
    />
  );
};

export default LocationSelect;
