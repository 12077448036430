import { yupResolver } from '@hookform/resolvers/yup';
import { Form, message, Modal } from 'antd';
import { FormItem, Spin } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import {
  FIND_GROUP_LIST_QUERY,
  FIND_GROUP_QUERY,
} from 'modules/donor/graphql/queries';
import { CreateTeamModalPropTypes } from 'modules/donor/types';
import { FC, MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateTeamMutation } from 'types.d';

import { createTeamFormFields } from './fields';
import { createTeamSchema, CreateTeamSchemaType } from './schema';

type PropTypes = CreateTeamModalPropTypes & BaseModalProps;

const CreateTeamModal: FC<PropTypes> = ({ groupId, ...modalProps }) => {
  const { t } = useTranslation('donor.CreateTeamModal');

  const { control, handleSubmit, formState, reset } =
    useForm<CreateTeamSchemaType>({
      resolver: yupResolver(createTeamSchema),
      mode: 'onChange',
    });

  const [createTeam, { loading }] = useCreateTeamMutation({
    onCompleted: () => {
      message.success(t('teamHasBeenCreated'));
      reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const submitForm = handleSubmit((formData) => {
    createTeam({
      refetchQueries: [
        {
          query: FIND_GROUP_LIST_QUERY,
          variables: {
            input: {},
          },
        },
        {
          query: FIND_GROUP_QUERY,
          variables: {
            input: {
              id: groupId,
            },
          },
        },
      ],
      variables: {
        input: { ...formData, groupId },
      },
    });
  });

  const okHandler = (e: MouseEvent<HTMLElement>) => {
    submitForm().then((s) => s);
    modalProps.onOk && modalProps.onOk(e);
    reset();
  };

  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    modalProps.hide();
    modalProps.onCancel && modalProps.onCancel(e);
    reset();
  };

  return (
    <Spin spinning={loading}>
      <Modal
        {...modalProps}
        title={t('createTeamModal')}
        onOk={okHandler}
        onCancel={cancelHandler}
        okText={t('create')}
        okButtonProps={{ disabled: !formState.isValid }}
      >
        <Form>
          {createTeamFormFields.map(({ Component, label, name }) => (
            <FormItem
              key={name}
              label={label}
              extra={formState.errors[name]?.message}
              validateStatus={formState.errors[name]?.message && 'error'}
            >
              <Component control={control} />
            </FormItem>
          ))}
        </Form>
      </Modal>
    </Spin>
  );
};

export default CreateTeamModal;
