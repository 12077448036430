import { Grid } from 'antd';
import AttachDonorsModal from 'modules/donor/components/AttachDonorsModal';
import { TeamsCardList } from 'modules/donor/components/TeamsCardList';
import TeamsTable from 'modules/donor/components/TeamsTable';
import { useAttachDonorsModalContext } from 'modules/donor/contexts/AttachDonorsModal';

export const GroupProfileTeamsTab = () => {
  const breakpoints = Grid.useBreakpoint();
  const isBreakpointsEmpty = Object.keys(breakpoints).length === 0;

  const attachDonorsModalContext = useAttachDonorsModalContext();

  return (
    <>
      {!isBreakpointsEmpty &&
        (breakpoints.md ? <TeamsTable /> : <TeamsCardList />)}
      {attachDonorsModalContext?.modalConfig.teamId && (
        <AttachDonorsModal {...attachDonorsModalContext.modalConfig} />
      )}
    </>
  );
};
