import { FormItem } from 'modules/common/components';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { PetField } from '../fields';
import { SectionFormField } from '../types';

type PropTypes = {
  fields: SectionFormField[];
  fieldNamePrefix: string;
  isDaycareLocationSelected?: boolean;
};

const PetFormSection: FC<PropTypes> = ({
  fields,
  fieldNamePrefix,
  isDaycareLocationSelected,
}) => {
  const { control, formState, watch } = useFormContext();
  const doesPetTakeMedications = watch(
    `${fieldNamePrefix}.${PetField.TakeMedications}`
  );
  return (
    <>
      {fields.map(({ Component, name, label, tooltip }) => {
        const fieldName = `${fieldNamePrefix}.${name}`;
        const isAdditionalDaycareField =
          name === PetField.WontRegularlyAttendDaycare ||
          name === PetField.AttendDaycareDays;

        if (isAdditionalDaycareField && !isDaycareLocationSelected) {
          return null;
        }

        if (
          name === PetField.MedicationsCourseComment &&
          !doesPetTakeMedications
        ) {
          return null;
        }
        const isDisabled =
          watch(`${fieldNamePrefix}.${PetField.WontRegularlyAttendDaycare}`) &&
          name === PetField.AttendDaycareDays;
        return (
          <FormItem
            key={fieldName}
            label={label}
            extra={formState.errors[fieldNamePrefix]?.[name]?.message}
            validateStatus={
              formState.errors[fieldNamePrefix]?.[name]?.message && 'error'
            }
            tooltip={tooltip}
          >
            <Component
              control={control}
              name={fieldName}
              disabled={isDisabled}
            />
          </FormItem>
        );
      })}
    </>
  );
};

export default PetFormSection;
