import { ApolloProvider } from '@apollo/client';
import { IProvider } from 'modules/common/interfaces';
import CacheExpirationProvider from 'providers/cacheExpiration';
import { apolloClient } from 'providers/graphql';
import I18NextProvider from 'providers/i18n';
import OfflineMutationsProvider from 'providers/offlineMutations';
import { UserProvider } from 'providers/user/UserProvider';
import { FC, ReactNode } from 'react';

type PropTypes = {
  children: ReactNode;
  providers?: IProvider[];
};

const AppProvider: FC<PropTypes> = ({ children, providers = [] }) => {
  if (!providers.length) {
    return (
      <ApolloProvider client={apolloClient}>
        <UserProvider>
          <CacheExpirationProvider>
            <OfflineMutationsProvider>
              <I18NextProvider>{children}</I18NextProvider>
            </OfflineMutationsProvider>
          </CacheExpirationProvider>
        </UserProvider>
      </ApolloProvider>
    );
  }
  const [{ Provider }, ...restProviders] = providers;
  return (
    <AppProvider providers={restProviders}>
      <Provider>{children}</Provider>
    </AppProvider>
  );
};

export default AppProvider;
