import { groupTitleSchema } from 'modules/donor/schemas';
import * as yup from 'yup';

export type GroupTitleFormSchema = {
  title: string;
};

export const groupTitleFormSchema: yup.SchemaOf<GroupTitleFormSchema> =
  yup.object({
    title: groupTitleSchema.required(),
  });
