import { Routes } from 'config/routes';
import { ICustomRoute } from 'modules/common/interfaces';
import { NavigationStateKeys } from 'modules/navigation/enums';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { useLocation, Navigate } from 'react-router-dom';
import { getRefreshToken, getAccessToken } from 'utils/getAccessToken';

const AuthRoute = ({
  componentProps = {},
  Component,
  roles,
  permissions,
}: ICustomRoute) => {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const refreshToken = getRefreshToken();
  const accessToken = getAccessToken();

  if (!refreshToken && !accessToken) {
    currentUser.cleanData();
  }

  if (
    !currentUser?.data?.id ||
    (!currentUser?.data?.id && !currentUser.isLoading)
  ) {
    return (
      <Navigate
        to={Routes.Login}
        state={{ [NavigationStateKeys.RedirectionPath]: location.pathname }}
      />
    );
  }

  const isAllowedRole = roles
    ? roles?.includes(currentUser?.data?.role?.value)
    : true;

  const isAllowedPermissions = permissions?.length
    ? permissions.every((permission) => currentUser.isCan(permission))
    : true;

  return isAllowedRole && isAllowedPermissions ? (
    <Component {...componentProps} />
  ) : (
    <Navigate to={Routes.Profile} />
  );
};

export default AuthRoute;
