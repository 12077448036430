import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import DeleteProductButton from 'modules/products/components/DeleteProductButton';
import { useChangeProductTrackingInfoModalContext } from 'modules/products/contexts/ChangeProductTrackingInfoModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum, ProductOutput, ProductStatus } from 'types.d';

import styles from './styles.module.scss';

enum ProductActions {
  DeleteProduct = 'DeleteProduct',
  TrackingInfo = 'TrackingInfo',
}

type PropTypes = {
  product: ProductOutput;
};

const ProductDropdownActions: FC<PropTypes> = ({ product }) => {
  const { t } = useTranslation('products.ProductDropdownActions');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const currentUser = useCurrentUser();
  const deleteProductAllowed = currentUser.isCan(PermissionsEnum.DeleteProduct);
  const changeProductTrackingInfoModalContext =
    useChangeProductTrackingInfoModalContext();
  const isProductCollected = product.status === ProductStatus.Collected;

  const productInfoMenuItem: ItemType = {
    label: t('productInfo'),
    key: ProductActions.TrackingInfo,
    onClick: () => {
      setIsDropdownVisible(false);
      changeProductTrackingInfoModalContext?.showModal(product);
    },
  };

  const deleteProductMenuItem: ItemType = {
    label: (
      <DeleteProductButton
        product={product}
        onDelete={() => setIsDropdownVisible(false)}
      />
    ),
    key: ProductActions.DeleteProduct,
    disabled: !isProductCollected || !deleteProductAllowed,
  };

  const menuOptions: ItemType[] = [productInfoMenuItem, deleteProductMenuItem];

  return (
    <Dropdown
      trigger={['click']}
      visible={isDropdownVisible}
      onVisibleChange={setIsDropdownVisible}
      overlay={<Menu items={menuOptions} />}
    >
      <MoreOutlined className={styles.moreIcon} />
    </Dropdown>
  );
};

export default ProductDropdownActions;
