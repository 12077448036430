import { NotificationOutlined } from '@ant-design/icons';
import { Badge, Col, notification, Row } from 'antd';
import { Routes } from 'config/routes';
import { Spin } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useNotificationAddedSubscription,
  useGetNotificationListQuery,
} from 'types.d';

import styles from './styles.module.scss';

const NotificationsWrapper: FC = () => {
  const { t } = useTranslation('notification.NotificationWrapper');
  const currentUser = useCurrentUser();
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  useNotificationAddedSubscription({
    variables: {
      input: {
        userId: currentUser.data?.id || '',
      },
    },

    onSubscriptionData: (subscriptionData) => {
      subscriptionData.client.refetchQueries({
        include: ['getNotificationList'],
      });
      const data = subscriptionData.subscriptionData.data?.notificationAdded;
      notification.open({
        message:
          t(`${data?.type[0].toLowerCase()}${data?.type.slice(1)}`) ||
          t('newNotification'),
      });
      setHasNewNotifications(true);
    },
  });

  const { loading } = useGetNotificationListQuery({
    variables: {
      input: {
        filter: {
          userId: currentUser.data?.id,
          isRead: false,
        },
      },
    },
    onCompleted: (data) => {
      data?.getNotificationsList?.data?.length > 0
        ? setHasNewNotifications(true)
        : setHasNewNotifications(false);
    },
  });

  const navigate = useNavigate();
  const navigateToNotificationsPage = () => {
    navigate(Routes.UserNotifications);
  };

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        {currentUser.data?.firstName} {currentUser.data?.lastName}
      </Col>
      <Col span={24}>
        <Badge dot={hasNewNotifications} className={styles.badge}>
          {loading ? (
            <Spin />
          ) : (
            <NotificationOutlined
              className={styles.notificationIcon}
              onClick={navigateToNotificationsPage}
            />
          )}
        </Badge>
      </Col>
    </Row>
  );
};
export default NotificationsWrapper;
