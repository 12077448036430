import { MutationHookOptions } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { message, Modal } from 'antd';
import {
  getMinutes,
  getHours,
  set as setDateMeasurements,
  differenceInMinutes,
  addMinutes,
} from 'date-fns';
import { AppointmentType } from 'modules/appointments/enums';
import { Spin } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { FC, MouseEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useCreateAppointmentsMutation,
  useCreateAppointmentsWithDonorMutation,
  useCreateAppointmentsWithGroupMutation,
  useCreateDaycareAppointmentsWithDonorsMutation,
} from 'types.d';

import {
  CreateAppointmentValidationSchema,
  createAppointmentValidationSchema,
} from './schema';

import CreateAppointmentsFormFields from '../CreateAppointmentsFormFields';

type PropTypes = {
  selectedDate?: Date;
  onFormHandle?: () => void;
} & BaseModalProps;

const CreateAppointmentsModal: FC<PropTypes> = ({
  selectedDate = new Date(),
  visible,
  onCancel,
  onOk,
  hide,
  onFormHandle,
  ...restModalProps
}) => {
  const { t } = useTranslation('appointments.CreateAppointmentsModal');
  const defaultMutationHookOptions: MutationHookOptions<any, any, any> = {
    update: (cache) => {
      cache.evict({ fieldName: 'getAppointments' });
    },
    onCompleted: () => {
      message.success(t('appointmentHasBeenCreated'));
      reset();
      hide();
    },
    onError: (error) => {
      message.error(error.message);
    },
  };
  const [createAppointment, createAppointmentMutation] =
    useCreateAppointmentsMutation(defaultMutationHookOptions);
  const [createAppointmentWithDonor, createAppointmentWithDonorMutation] =
    useCreateAppointmentsWithDonorMutation(defaultMutationHookOptions);
  const [createAppointmentWithGroup, createAppointmentWithGroupMutation] =
    useCreateAppointmentsWithGroupMutation(defaultMutationHookOptions);
  const [
    createDaycareAppointmentsWithDonors,
    createDaycareAppointmentsWithDonorsMutation,
  ] = useCreateDaycareAppointmentsWithDonorsMutation(
    defaultMutationHookOptions
  );
  const isLoading = [
    createAppointmentMutation,
    createAppointmentWithDonorMutation,
    createAppointmentWithGroupMutation,
    createDaycareAppointmentsWithDonorsMutation,
  ].some(({ loading }) => loading);
  const createAppointmentsForm = useForm<CreateAppointmentValidationSchema>({
    resolver: yupResolver(createAppointmentValidationSchema),
    mode: 'onChange',
    defaultValues: {
      type: AppointmentType.Individual,
      timeslots: [],
      title: undefined,
      dates: [selectedDate],
    },
  });

  const { handleSubmit, reset, formState } = createAppointmentsForm;
  const isOkButtonDisabled = !formState.isValid || isLoading;
  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    onCancel && onCancel(e);
    onFormHandle && onFormHandle();
    hide();
    reset();
  };
  const okHandler = async (e: MouseEvent<HTMLElement>) => {
    onOk && onOk(e);
    onFormHandle && onFormHandle();
    createAppointmentHandler();
  };
  const createAppointmentHandler = handleSubmit((formDate) => {
    const {
      timeslots,
      donorStatus,
      type,
      selectedGroup,
      teamId,
      donorId,
      split,
      dates,
      location,
      notes,
      daycareDonorsIds,
      ...mandatoryFields
    } = formDate;
    const [startTime, endTime] = timeslots;
    const appointmentDates = (dates || [selectedDate]).reduce<
      { start: Date; end: Date }[]
    >((dates, date) => {
      if (!split) {
        const [start, end] = roundTimeSlot(date, startTime, endTime);
        return [...dates, { start, end }];
      }
      const timeSlotDifference = differenceInMinutes(endTime, startTime);

      const availablePieces = Math.floor(timeSlotDifference / 30);

      const splittedTimeSlot = Array.from(Array(availablePieces).keys()).map(
        (slotIndex) => {
          const [start, end] = roundTimeSlot(
            date,
            addMinutes(startTime, 30 * slotIndex),
            addMinutes(startTime, 30 * (slotIndex + 1))
          );
          return {
            start,
            end,
          };
        }
      );
      return [...dates, ...splittedTimeSlot];
    }, [] as any);
    const baseVariables = {
      dates: appointmentDates,
      location,
      notes,
      isDaycare: AppointmentType.Daycare === type,
      ...mandatoryFields,
    };
    if (selectedGroup) {
      createAppointmentWithGroup({
        variables: {
          input: { ...baseVariables, groupId: selectedGroup.value, teamId },
        },
      });
    }
    if (donorId) {
      createAppointmentWithDonor({
        variables: { input: { ...baseVariables, donorId, location } },
      });
    }
    if (daycareDonorsIds?.length && daycareDonorsIds?.length > 0) {
      createDaycareAppointmentsWithDonors({
        variables: {
          input: {
            ...baseVariables,
            donorIds: daycareDonorsIds,
          },
        },
      });
    }
    if (!donorId && !selectedGroup && !daycareDonorsIds) {
      createAppointment({
        variables: {
          input: {
            ...baseVariables,
          },
        },
      });
    }
  });

  useEffect(() => {
    if (selectedDate && visible) {
      createAppointmentsForm.setValue('dates', [selectedDate]);
    }
  }, [visible]);

  return (
    <Modal
      width={400}
      onOk={okHandler}
      onCancel={cancelHandler}
      okButtonProps={{ disabled: isOkButtonDisabled }}
      destroyOnClose
      {...restModalProps}
      visible={visible}
    >
      <Spin spinning={isLoading}>
        <FormProvider {...createAppointmentsForm}>
          <CreateAppointmentsFormFields title={t('createAppointment')} />
        </FormProvider>
      </Spin>
    </Modal>
  );
};

const roundTimeSlot = (
  forDate: Date,
  fromDate: Date,
  toDate: Date
): [Date, Date] => {
  const start = setDateMeasurements(forDate, {
    hours: getHours(fromDate),
    minutes: getMinutes(fromDate),
    seconds: 0,
    milliseconds: 0,
  });
  const end = setDateMeasurements(forDate, {
    hours: getHours(toDate),
    minutes: getMinutes(toDate),
    seconds: 0,
    milliseconds: 0,
  });
  return [start, end];
};

export default CreateAppointmentsModal;
