import { ApolloError } from '@apollo/client';
import { message } from 'antd';

export const showGraphqlErrorMessage = (error: ApolloError): void => {
  const extensions = error.graphQLErrors[0].extensions as {
    response: { message: [string] };
  };

  const errorMessage = extensions.response.message[0];
  message.error(errorMessage);
};
