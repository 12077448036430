import { Col, Layout, Row } from 'antd';
import RegisterPetForm from 'modules/donor/components/RegisterPetForm';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const RegisterPetPage: FC = () => {
  const { t } = useTranslation('donor.RegisterPetPage');

  return (
    <Layout.Content className={styles.content}>
      <Row justify="center" align="middle" className={styles.innerContainer}>
        <Col span={24}>
          <div className={styles.logo}>
            <img
              src="https://navbb-assets.s3.amazonaws.com/logo.webp"
              alt="logo"
            />
          </div>
        </Col>
        <Col span={24}>
          <h1 className={styles.title}>{t('k9PetRegistration')}</h1>
        </Col>
        <Col span={24}>
          <h2 className={styles.subtitle}>{t('thanksForSupport')}</h2>
          <h2 className={styles.subtitle}>{t('pleaseFillForm')}</h2>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col className={styles.formContainer}>
              <RegisterPetForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default RegisterPetPage;
