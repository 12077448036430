import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { FC, useMemo } from 'react';

import { DesktopDonationInfoView, MobileDonationInfoView } from './components';
import { getDonationInfoFields } from './fields';
import { IDonationInfoTableData } from './interfaces';

type Props = {
  fieldNamePrefix: string;
  enabled: boolean;
  sectionNamePrefix?: string;
};

const DonationInfoSection: FC<Props> = ({
  fieldNamePrefix,
  enabled,
  sectionNamePrefix,
}) => {
  const breakpoint = useBreakpoint();
  const fields = useMemo(() => getDonationInfoFields({ enabled }), [enabled]);

  const dataSource: IDonationInfoTableData[] = fields.map((field) => {
    return {
      question: field.name,
      answer: field,
    } as IDonationInfoTableData;
  });

  return (
    <>
      {breakpoint.xl && (
        <DesktopDonationInfoView
          dataSource={dataSource}
          fieldNamePrefix={fieldNamePrefix}
          sectionNamePrefix={sectionNamePrefix}
        />
      )}
      {!breakpoint.xl && (
        <MobileDonationInfoView
          fieldNamePrefix={fieldNamePrefix}
          fields={fields}
          sectionNamePrefix={sectionNamePrefix}
        />
      )}
    </>
  );
};

export default DonationInfoSection;
