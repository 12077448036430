import { FileOutput, DonorOutput } from 'types';
import { fetchWithAuth } from 'utils/fetchWithAuth';

export const getPictureUrl = async (file: FileOutput) => {
  const response = await fetchWithAuth(
    `${process.env.REACT_APP_SERVER_URI}/donor/files/${file.id}`,
    {
      method: 'GET',
    }
  );
  const blob = await response.blob();
  return window.URL.createObjectURL(new Blob([blob]));
};

export const getDonorPictureUrls = async (donor: DonorOutput) => {
  return (
    donor?.pictures &&
    (await Promise.all(
      donor.pictures?.map(async ({ file, id }) => {
        const url = await getPictureUrl(file);
        return { id, url };
      })
    ))
  );
};
