import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form, message } from 'antd';
import { Routes } from 'config/routes';
import {
  Button,
  ControlledPasswordInput,
  FormItem,
  Spin,
} from 'modules/common/components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAcceptInviteMutation } from 'types.d';
import { checkIsUUID } from 'utils/checkIsUUID';

import fields from './fields';
import { acceptInviteSchema, AcceptInviteSchema } from './schema';
import styles from './styles.module.scss';

const AcceptInviteForm = () => {
  const { t } = useTranslation('admin.AcceptInvitePage');
  const navigate = useNavigate();
  const params = useParams();
  const isCorrectId = checkIsUUID(params.id || '');
  const [acceptInvite, acceptInviteUserMutation] = useAcceptInviteMutation({
    onCompleted: (data) => {
      message.success(t('approved', { email: data.acceptInvite.email }));
      reset();
      navigate(Routes.Login, { replace: true });
    },
    onError: (error) => {
      message.error(error.message);
      navigate(Routes.NotFound);
      reset();
    },
  });

  const { control, handleSubmit, formState, reset } =
    useForm<AcceptInviteSchema>({
      resolver: yupResolver(acceptInviteSchema),
      mode: 'onChange',
    });

  const approveHandler = handleSubmit((data) => {
    const { confirmPassword } = data;
    acceptInvite({
      variables: {
        input: {
          password: confirmPassword,
          id: params.id || '',
        },
      },
    });
  });

  useEffect(() => {
    if (!isCorrectId) {
      navigate(Routes.NotFound);
    }
  }, [params]);

  return (
    <Spin spinning={acceptInviteUserMutation.loading}>
      <Form>
        {fields.map(({ label, name }) => {
          const errorMessage = !formState.isValid
            ? formState.errors[name]?.message
            : '';
          return (
            <FormItem
              key={name}
              label={label}
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
            >
              <ControlledPasswordInput controlled={{ name, control }} />
            </FormItem>
          );
        })}
        <div className={styles.buttonsContainer}>
          <Button
            disabled={!formState.isValid}
            onClick={approveHandler}
            type="primary"
          >
            {t('acceptInvite')}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default AcceptInviteForm;
