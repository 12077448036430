import { Grid } from 'antd';
import { useSort } from 'modules/common/hooks';
import { GroupsTable, GroupCardList } from 'modules/donor/components';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { GroupListSort, GroupListItemOutput } from 'types.d';

const useGroupListSort = () => useSort<GroupListSort>();

type Props = {
  groups: GroupListItemOutput[];
  pagination: ReturnType<typeof usePagination>;
  sorting: ReturnType<typeof useGroupListSort>;
};

const GroupList: FC<Props> = (props) => {
  const breakpoints = Grid.useBreakpoint();

  return (
    <>
      {breakpoints.md ? (
        <GroupsTable {...props} />
      ) : (
        <GroupCardList {...props} />
      )}
    </>
  );
};

export default GroupList;
