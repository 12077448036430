import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Col, message, Modal, Row } from 'antd';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { Spin, Dragger } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DonorType,
  OwnerType,
  useCreateDonorsForUserMutation,
  useGetUserLazyQuery,
} from 'types.d';

import ErrorMessages from './components/ErrorMessages';
import Footer from './components/Footer';
import OwnerSelect from './components/OwnerSelect';
import OwnerTeamSelect from './components/OwnerTeamSelect';
import { parseDonorsCsv } from './parser';
import { createDonorsSchema, CreateDonorsSchemaType } from './schema';
import styles from './styles.module.scss';

const CsvDonorsUploadingModal: FC<BaseModalProps> = (props) => {
  const { t } = useTranslation('donor.CsvDonorsUploadingModal');
  const [selectedFile, setSelectedFile] = useState<UploadFile[]>([]);
  const [ownerType, setOwnerType] = useState<OwnerType | null>(null);
  const onCompleted = () => {
    message.success(t('donorsCreated'));
    resetModal();
  };
  const onError = (error: any) => {
    const errMsg =
      typeof error.message === 'string' ? error.message : t('creationFailed');
    message.error(errMsg);
  };
  const [createDonorsForUser, createDonorsForUserQuery] =
    useCreateDonorsForUserMutation({
      refetchQueries: ['getDonorsList'],
      onCompleted,
      onError,
    });
  const [getUser, getUserLazyQuery] = useGetUserLazyQuery({
    onCompleted: (data) => {
      setOwnerType?.(data?.getUser?.type as OwnerType | null);
    },
  });
  const isLoading =
    createDonorsForUserQuery.loading || getUserLazyQuery.loading;
  const abstractCreateDonorsForm = useForm<CreateDonorsSchemaType>({
    resolver: yupResolver(createDonorsSchema),
    mode: 'onChange',
  });
  const selectedUserId = abstractCreateDonorsForm.watch('userId');
  const donors = abstractCreateDonorsForm.watch('donors');
  const uploadDonors = abstractCreateDonorsForm.handleSubmit(
    ({ donors, userId, teamId }) => {
      const donorsData = donors.map((donor) => {
        return { ...donor, teamId };
      });
      createDonorsForUser({
        variables: {
          input: {
            donorsData,
            userId,
          },
        },
      });
    }
  );
  const donorsErrorMessages =
    abstractCreateDonorsForm.formState.errors.donors || [];
  const donorsErrorMessage = (donorsErrorMessages as unknown as FieldError)
    ?.message;
  const isDonorsDataValid =
    Boolean(donors?.length) && !Boolean(donorsErrorMessages.length);

  const isGroupeAvailable =
    selectedUserId && donors?.at(0)?.type === DonorType.Grouped;

  const handleCancel = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    props.onCancel && props.onCancel(event);
    resetModal();
    props.hide();
  };

  const resetModal = () => {
    abstractCreateDonorsForm.reset();
    setSelectedFile([]);
  };

  const uploadProps: UploadProps = {
    accept: '.csv',
    maxCount: 1,
    beforeUpload: (file) => {
      parseDonorsCsv({
        file,
        onComplete: ({ data }) => {
          abstractCreateDonorsForm.setValue('donors', data, {
            shouldValidate: true,
          });
          abstractCreateDonorsForm.trigger();
        },
      });
      setSelectedFile([file]);
      return false;
    },
    onRemove: () => {
      setSelectedFile([]);
      abstractCreateDonorsForm.clearErrors('donors');
      abstractCreateDonorsForm.setValue('donors', [], {
        shouldValidate: true,
      });
    },
    fileList: selectedFile,
  };

  useEffect(() => {
    if (selectedUserId) {
      getUser({
        variables: { input: { id: selectedUserId } },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [selectedUserId]);

  const isOwnerGrouped = ownerType === OwnerType.Group;
  const donorTypeWillBeChanged =
    ((!isGroupeAvailable && isOwnerGrouped) ||
      (isGroupeAvailable && !isOwnerGrouped)) &&
    Boolean(donors?.length);

  return (
    <Spin spinning={isLoading}>
      <Modal
        {...props}
        className={styles.modal}
        title={t('uploadDonors')}
        onCancel={handleCancel}
        afterClose={() => setOwnerType(null)}
        footer={
          <Footer
            cancelButtonProps={{ onClick: handleCancel }}
            okButtonProps={{
              onClick: uploadDonors,
              disabled: !abstractCreateDonorsForm.formState.isValid,
            }}
          />
        }
      >
        <FormProvider {...abstractCreateDonorsForm}>
          <Row gutter={[10, 10]}>
            <Col span={12} className={styles.uploadContainer}>
              <Dragger {...uploadProps} />
            </Col>
            <Col span={12} className={styles.additionalInfoContainer}>
              <OwnerSelect
                control={abstractCreateDonorsForm.control}
                onClear={() => setOwnerType(null)}
              />
              {isOwnerGrouped && (
                <OwnerTeamSelect
                  control={abstractCreateDonorsForm.control}
                  userIds={[selectedUserId]}
                />
              )}
            </Col>

            {Boolean(donorsErrorMessages.length) && (
              <Col span={24}>
                <Row gutter={[10, 0]} justify="center">
                  <Col>
                    <CloseCircleOutlined className={styles.errorIcon} />
                  </Col>
                  <Col span={24} />
                  <Col>
                    <h4>{t('dataIsNotValid')}:</h4>
                  </Col>
                </Row>
              </Col>
            )}
            {isDonorsDataValid && (
              <Col span={24}>
                <Row gutter={[10, 0]} justify="center">
                  <Col>
                    <CheckCircleOutlined className={styles.successIcon} />
                  </Col>
                  <Col span={24} />
                  <Col>
                    <h4>{t('dataIsValid')}</h4>
                  </Col>
                </Row>
              </Col>
            )}
            {donorTypeWillBeChanged && (
              <Col span={24}>
                <Alert
                  type="warning"
                  message={t('donorsTypeWillBeChanged')}
                  className={styles.typeChangeMessage}
                />
              </Col>
            )}

            {Boolean(donorsErrorMessage) && (
              <Col span={24} className={styles.errors}>
                <Alert type="error" message={donorsErrorMessage} />
              </Col>
            )}
            {Boolean(donorsErrorMessages.length) && (
              <Col span={24} className={styles.errors}>
                <ErrorMessages />
              </Col>
            )}
          </Row>
        </FormProvider>
      </Modal>
    </Spin>
  );
};

export default CsvDonorsUploadingModal;
