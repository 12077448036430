import { Routes } from 'config/routes';
import CardTabs, { ITabsItem } from 'modules/common/components/CardTabs';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type PropTypes = {
  items?: ITabsItem[];
};

const NavigationCardTabs: FC<PropTypes> = ({ items }) => {
  const [activeTab, setActiveTab] = useState<string>(Routes.Users);
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeTabs = (tabKey: string) => {
    navigate(tabKey);
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  return (
    <CardTabs
      onChange={onChangeTabs}
      activeKey={activeTab}
      items={items || []}
    />
  );
};

export default NavigationCardTabs;
