import { gql } from '@apollo/client';
import {
  DONOR_FRAGMENT,
  RESERVED_DONOR_NOTE_FRAGMENT,
  GROUP_FRAGMENT,
  LABWORK_FRAGMENT,
  TEAM_FRAGMENT,
  VITALS_PARAMETER_FRAGMENT,
  DONOR_NOTE_FRAGMENT,
} from 'modules/donor/graphql/fragments';
import { OWNER_FRAGMENT } from 'modules/owner/graphql/fragments';
import { CONTACT_OUTPUT_FRAGMENT } from 'modules/user/graphql/fragments';

export const CREATE_DONORS_FOR_USER_MUTATION = gql`
  mutation createDonorsForUser($input: CreateDonorsForUserInput!) {
    createDonorsForUser(input: $input)
  }
`;

export const CREATE_DONORS = gql`
  mutation createDonors($input: CreateDonors!) {
    createDonors(input: $input)
  }
`;

export const CREATE_DONOR_MUTATION = gql`
  mutation createDonor($input: CreateDonorInput!) {
    createDonor(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const UPDATE_GROUP_MUTATION = gql`
  mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      title
    }
  }
`;

export const UPDATE_TEAM_MUTATION = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      title
    }
  }
`;

export const REMOVE_GROUP_MUTATION = gql`
  mutation removeGroup($input: RemoveGroupInput!) {
    removeGroup(input: $input)
  }
`;

export const CREATE_TEAM_MUTATION = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
    }
  }
`;

export const REMOVE_TEAM_MUTATION = gql`
  mutation removeTeam($input: RemoveTeamInput!) {
    removeTeam(input: $input)
  }
`;

export const REMOVE_DONOR_MUTATION = gql`
  mutation removeDonor($input: DeleteDonorInput!) {
    deleteDonor(input: $input)
  }
`;

export const CREATE_GROUP_MUTATION = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
    }
  }
`;

export const INVITE_OWNER_WITH_DONOR = gql`
  mutation inviteOwnerWithDonor($input: InviteOwnerWithDonorInput!) {
    inviteOwnerWithDonor(input: $input)
  }
`;

export const CREATE_DONOR_FOR_USER = gql`
  mutation createDonorForUser($input: CreateDonorForUserInput!) {
    createDonorForUser(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const UPDATE_DONOR_MUTATION = gql`
  mutation updateDonor($input: UpdateDonorInput!) {
    updateDonor(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const ADD_DONOR_NOTE_MUTATION = gql`
  mutation addNoteToReserveDonor($input: CreateDonorNoteInput!) {
    addNoteToReserveDonor(input: $input) {
      ...ReserveDonorNote
    }
  }
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const UPDATE_DONOR_NOTE_MUTATION = gql`
  mutation updateReserveDonorNote($input: UpdateDonorNoteInput!) {
    updateReserveDonorNote(input: $input) {
      ...ReserveDonorNote
    }
  }
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const DELETE_DONOR_NOTE_MUTATION = gql`
  mutation deleteReserveDonorNote($input: DeleteDonorNoteInput!) {
    deleteReserveDonorNote(input: $input)
  }
`;

export const UPDATE_LABWORK_MUTATION = gql`
  mutation updateLabwork($input: UpdateLabworkInput!) {
    updateLabwork(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${LABWORK_FRAGMENT}
`;

export const UPDATE_LABWORK_STATUS_MUTATION = gql`
  mutation updateLabworkStatus($input: UpdateLabworkStatusInput!) {
    updateLabworkStatus(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${LABWORK_FRAGMENT}
`;

export const CREATE_LABWORK_MUTATION = gql`
  mutation createLabwork($input: CreateLabworkInput!) {
    createLabwork(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${LABWORK_FRAGMENT}
`;

export const SHARE_LABWORK_RESULTS_MUTATION = gql`
  mutation shareLabworkResults($input: ShareLabworkResultsWithOwnerInput!) {
    shareLabworkResults(input: $input)
  }
`;

export const REMOVE_LABWORK_MUTATION = gql`
  mutation removeLabwork($input: RemoveLabworkInput!) {
    removeLabwork(input: $input)
  }
`;

export const DETACH_LABWORK_FILE_MUTATION = gql`
  mutation detachLabworkFile($input: DetachLabworkFileInput!) {
    detachLabworkFile(input: $input)
  }
`;

export const UPDATE_VITALS_PARAMETERS_LIST_MUTATION = gql`
  mutation updateVitalsParametersList(
    $input: UpdateVitalsParametersListInput!
  ) {
    updateVitalsParameterList(input: $input) {
      ...VitalsParameter
    }
  }
  ${VITALS_PARAMETER_FRAGMENT}
`;

export const UPDATE_OWNER_OF_DONOR_MUTATION = gql`
  mutation updateDonorsOwner($input: UpdateDonorsOwnerInput!) {
    updateDonorsOwner(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${RESERVED_DONOR_NOTE_FRAGMENT}
`;

export const UPDATE_OWNER_OF_GROUP_MUTATION = gql`
  mutation updateGroupOwner($input: UpdateGroupOwnerInput!) {
    updateGroupOwner(input: $input) {
      ...Group
      teams {
        ...Team
        donors {
          ...Donor
        }
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${DONOR_FRAGMENT}
  ${TEAM_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

export const MANAGE_DONORS_IN_TEAM_MUTATION = gql`
  mutation manageDonorsInTeam($input: ManageDonorsInTeamInput!) {
    manageDonorsInTeam(input: $input)
  }
`;

export const ADD_NOTE_MUTATION = gql`
  mutation addNote($input: CreateDonorNoteInput!) {
    addNote(input: $input) {
      ...DonorNote
    }
  }

  ${DONOR_NOTE_FRAGMENT}
`;

export const UPDATE_NOTE_MUTATION = gql`
  mutation updateNote($input: UpdateDonorNoteInput!) {
    updateNote(input: $input) {
      ...DonorNote
    }
  }

  ${DONOR_NOTE_FRAGMENT}
`;

export const DELETE_NOTE_MUTATION = gql`
  mutation deleteNote($input: DeleteDonorNoteInput!) {
    deleteNote(input: $input)
  }
`;
