import { PasswordInput } from 'modules/common/components';
import { FC, ComponentProps } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof PasswordInput>;

const ControlledInput: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);

  return <PasswordInput {...controller.field} {...restProps} />;
};

export default ControlledInput;
