import { ListTable } from 'modules/common/components';
import { ListTableDataType } from 'modules/common/types';
import { FC } from 'react';
import { OrderItemOutput } from 'types.d';

type PropTypes = {
  items: OrderItemOutput[];
};

const OrderItemsTable: FC<PropTypes> = ({ items }) => {
  const dateSource: ListTableDataType[] = items.map(({ name, quantity }) => ({
    key: name,
    value: `${quantity}`,
  }));

  return <ListTable data={dateSource} />;
};

export default OrderItemsTable;
