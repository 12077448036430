import { SelectProps } from 'antd';
import { ControlledSelect } from 'modules/common/components';
import React, { useMemo } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetRegionsQuery } from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & SelectProps;

export default function RegionSelect({
  controlled,
  ...selectProps
}: PropTypes) {
  const { t } = useTranslation('locations.RegionSelect');
  const regionsQuery = useGetRegionsQuery();

  const options = useMemo(
    () =>
      regionsQuery.data?.getRegions.map((region) => ({
        label: region.name,
        value: region.id,
      })),
    [regionsQuery.data]
  );
  return (
    <ControlledSelect
      allowClear
      loading={regionsQuery.loading}
      placeholder={t('selectRegion')}
      controlled={controlled}
      options={options}
      {...selectProps}
    />
  );
}
