import TeamSelect from 'modules/donor/components/TeamSelect';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

//TODO: try to remove it when todo in TeamSelect will be resolved.

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof TeamSelect>;

const ControlledTeamSelect: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);
  const { onChange, ...restControlledProps } = controller.field;

  return (
    <TeamSelect
      onChangeControlled={onChange}
      {...restProps}
      {...restControlledProps}
    />
  );
};

export default ControlledTeamSelect;
