import { ApolloQueryResult } from '@apollo/client';
import config from 'config';
import { GET_LABWORK_LIST } from 'modules/donor/graphql/queries';
import { apolloClient } from 'providers/graphql';
import { GetLabworkListQuery } from 'types';

export const getLabworks = (
  donorId: string
): Promise<ApolloQueryResult<GetLabworkListQuery>> => {
  return apolloClient.query<GetLabworkListQuery>({
    query: GET_LABWORK_LIST,
    variables: {
      input: {
        take: config.PAGE_SIZE, //TODO: fix the issue with receiving labworks, for offline mode it takes only 10
        skip: 0,
        sort: [],
        filter: {
          donorId,
        },
      },
    },
  });
};
