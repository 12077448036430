import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import ProductsPageTabs from 'modules/products/components/ProductsPageTabs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ProductsPage: FC = () => {
  const { t } = useTranslation('products.ProductsDashboardPage');

  return (
    <ProfileSideMenuWrapper title={t('dashboard')}>
      <ProductsPageTabs />
    </ProfileSideMenuWrapper>
  );
};

export default ProductsPage;
