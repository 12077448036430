import { UserOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { Routes } from 'config/routes';
import { PermissionsType } from 'modules/user/types';
import { PermissionsEnum, RoleOutput, UserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { MenuOptionType } from '../types';

const t = getTranslation('admin.Navigation');

export const getMenuItemsOfInternalTeamPreset = (
  userRole: UserRole
): MenuOptionType[] => [
  {
    key: Routes.Profile,
    label: (
      <Row>
        <Col>
          <UserOutlined />
        </Col>
        <Col offset={1}>{t(userRole.toLowerCase())}</Col>
      </Row>
    ),
  },
  {
    key: Routes.Users,
    label: t('users'),
    permissions: [PermissionsEnum.ViewUsersList],
  },
  {
    key: Routes.Requests,
    label: t('requestsForApproval'),
    permissions: [PermissionsEnum.ViewRequestsForApproval],
  },
  {
    key: Routes.Appointments,
    label: t('appointments'),
    permissions: [PermissionsEnum.ViewAppointments],
  },
  {
    key: Routes.Schedule,
    label: t('schedule'),
    permissions: [
      PermissionsEnum.ViewTasksSchedule,
      PermissionsEnum.ViewAppointments,
    ],
  },
  {
    key: Routes.Locations,
    label: t('locations'),
    permissions: [],
  },
  {
    key: Routes.Donors,
    label: t('donors'),
    children: [
      {
        key: Routes.IndividualDonors,
        label: t('individualDonorList'),
        permissions: [PermissionsEnum.ViewTheListOfDonors],
      },
      {
        key: Routes.GroupDonors,
        label: t('groupDonorList'),
        permissions: [PermissionsEnum.ViewTheListOfDonors],
      },
      {
        key: Routes.ReserveDonors,
        label: t('trainingReserveDonors'),
        permissions: [PermissionsEnum.ViewTheListOfTrainingReserveDonors],
      },
      {
        key: Routes.AppointmentsDonors,
        label: t('appointmentsDonors'),
        permissions: [PermissionsEnum.ViewTheListOfDonors],
      },
    ],
    permissions: [PermissionsEnum.ViewTheListOfDonors],
  },
  {
    key: Routes.Labwork,
    label: t('labwork'),
    permissions: [PermissionsEnum.ViewLabworkResults],
    roles: [
      UserRole.Admin,
      UserRole.CollectionTeam,
      UserRole.DogHandler,
      UserRole.MedicalDirector,
      UserRole.InventoryTeam,
    ],
  },
  {
    key: Routes.Owners,
    label: t('owners'),
    permissions: [PermissionsEnum.ViewTheListOfOwners],
  },
  {
    key: `${Routes.CollectionDonations}`,
    label: t('donationDataCollectionPage'),
    permissions: [PermissionsEnum.ViewDonationDataCollectionPage],
  },
  {
    key: Routes.Products,
    label: t('productList'),
    permissions: [PermissionsEnum.ViewProductList],
  },
  {
    key: Routes.Orders,
    label: t('orders'),
    permissions: [PermissionsEnum.ViewListOfOrders],
  },
  {
    key: `${Routes.Reports}`,
    label: t('reports'),
    permissions: [PermissionsEnum.ViewReports],
  },
];

const checkIfUserHasCanViewMenuItem = (
  userPermissions: PermissionsType,
  menuItem: { key: Routes; label: string; permissions?: PermissionsEnum[] }
) =>
  menuItem.permissions
    ? menuItem.permissions.every((item) => {
        return userPermissions[item];
      })
    : true;

const menuItemReducer = (
  previousItems: any,
  currentItem: any,
  userPermissions: PermissionsType
) => {
  return checkIfUserHasCanViewMenuItem(userPermissions, currentItem)
    ? [
        ...previousItems,
        {
          ...currentItem,
          children: currentItem.children
            ? currentItem.children?.reduce(
                (previousChildrenItems: any, currentChildrenItem: any) =>
                  menuItemReducer(
                    previousChildrenItems,
                    currentChildrenItem,
                    userPermissions
                  ),
                [] as any[]
              )
            : undefined,
        },
      ]
    : previousItems;
};

export const getMenuItemsOfInternalTeam = (
  role: RoleOutput,
  permissions: PermissionsType
): MenuOptionType[] => {
  return getMenuItemsOfInternalTeamPreset(role.value).reduce(
    (previousMenuItems, currentMenuItem) =>
      menuItemReducer(previousMenuItems, currentMenuItem, permissions),
    [] as MenuOptionType[]
  );
};
