import { Table, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useSort } from 'modules/common/hooks';
import { getColumns } from 'modules/donor/components/LabworkTable/columns';
import { usePrintLabworkModalContext } from 'modules/donor/contexts/PrintLabworkFormModal';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import {
  LabworkOutput,
  Order,
  LabworkListSort,
  PermissionsEnum,
  LabworkStatus,
} from 'types.d';

import PrintLabworkModal from '../PrintLabworkModal';

const useLabworkListSort = () => useSort<LabworkListSort>();

type Props = {
  labworks: LabworkOutput[];
  updateLabworkHandler: (data: LabworkOutput) => void;
  updateLabworkStatusHandler: (id: string, status: LabworkStatus) => void;
  removeLabworkHandler: (id: string) => void;
  pagination: ReturnType<typeof usePagination>;
  sort: ReturnType<typeof useLabworkListSort>;
};

const LabworkTable: FC<Props> = ({
  labworks,
  pagination,
  sort,
  removeLabworkHandler,
  ...otherProps
}) => {
  const currentUser = useCurrentUser();
  const modalConfig = usePrintLabworkModalContext();
  const canUpdateLabwork = currentUser.isCan(PermissionsEnum.UpdateLabworkData);
  const canShareLabworkResults = currentUser.isCan(
    PermissionsEnum.ShareLabworkResults
  );
  const canUpdateLabworkStatus = currentUser.isCan(
    PermissionsEnum.UpdateLabworkStatus
  );
  const cadDeleteLabwork = currentUser.isCan(PermissionsEnum.DeleteLabworkData);

  const columns = getColumns({
    ...otherProps,
    canUpdateLabwork,
    canShareLabworkResults,
    canUpdateLabworkStatus,
    cadDeleteLabwork,
    removeLabworkHandler,
  });

  const onTableChange: TableProps<LabworkOutput>['onChange'] = (
    _,
    __,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<LabworkOutput>;
      const fieldName = tableSorter.columnKey as LabworkListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        sort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        sort.removeSortOption(fieldName);
      }
    }
  };

  return (
    <>
      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={labworks}
        onChange={onTableChange}
        pagination={pagination.config}
        scroll={{ x: 750 }}
      />
      {modalConfig && (
        <PrintLabworkModal
          {...modalConfig?.modalConfig}
          hide={modalConfig.hideModal}
        />
      )}
    </>
  );
};

export default LabworkTable;
