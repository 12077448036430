import { Row, Col } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { AppointmentOutput } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  appointment: AppointmentOutput;
};

const AppointmentTitle: FC<PropTypes> = ({ appointment }) => {
  const [play, setPlay] = useState(false);
  const { start, donor, group, title, end, isDaycare } = appointment;
  const startFormatted = format(new Date(start), config.LOCALIZED_TIME_FORMAT);
  const endFormatted = format(new Date(end), config.LOCALIZED_TIME_FORMAT);

  const time = isDaycare
    ? `${startFormatted} - ${endFormatted}`
    : startFormatted;

  const donorName = donor
    ? `${donor.name} ${donor.user?.lastName || ''}`
    : undefined;
  const groupName = group ? `${group.title}` : undefined;
  const marqueeElements = document.querySelectorAll(
    `#mq${appointment.id} .marquee`
  );

  return (
    <div
      id={`mq${appointment.id}`}
      onMouseOver={() => {
        setPlay(true);
      }}
      onMouseLeave={() => {
        setPlay(false);
        marqueeElements?.forEach(({ classList }) =>
          classList.remove('marquee')
        );
        marqueeElements?.forEach(({ offsetWidth }: any) => {});
        marqueeElements?.forEach(({ classList }) => classList.add('marquee'));
      }}
    >
      <Marquee gradientWidth={0} play={play} loop={1}>
        <Row className={styles.appointmentTitle} gutter={[5, 0]}>
          <Col>{time}</Col>
          <Col>{title}</Col>
          {donorName && <Col> - {donorName}</Col>}
          {groupName && <Col> - {groupName}</Col>}
        </Row>
      </Marquee>
    </div>
  );
};

export default AppointmentTitle;
