import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Row, Col, message } from 'antd';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { endOfDay } from 'date-fns';
import { Button } from 'modules/common/components';
import {
  addDonorToCacheSchema,
  AddDonorToCacheSchema,
} from 'modules/donor/components/AddDonorToCacheForm/schema';
import { DonorSelect } from 'modules/donor/components/index';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DonorOutput } from 'types';
import { donorCachingQueries } from 'utils/OfflineServices/donorCachingQueries';

import styles from './styles.module.scss';

type Props = {
  refetchDonorListHandler: () => void;
  appointmentsDonorList: DonorOutput[];
};

export const AddDonorToCacheForm: FC<Props> = ({
  refetchDonorListHandler,
  appointmentsDonorList,
}) => {
  const { t } = useTranslation('donor.AddDonorToCacheForm');

  const createDonorForm = useForm<AddDonorToCacheSchema>({
    resolver: yupResolver(addDonorToCacheSchema),
    mode: 'onChange',
  });

  const addDonorToCacheHandler = createDonorForm.handleSubmit((data) => {
    const id = data.donorId;
    const existingDonorIdsJson =
      localStorage.getItem(LOCALE_STORAGE_KEYS.DONOR_IDS) || '';
    const existingDonorIds: string[] =
      existingDonorIdsJson !== '' ? JSON.parse(existingDonorIdsJson) : [];
    const alreadyInCache =
      existingDonorIds.includes(id) ||
      appointmentsDonorList.find((donor) => donor.id === id);

    if (!alreadyInCache) {
      donorCachingQueries.map((query) => query(id));
      const donorsExpirationJson = localStorage.getItem(
        LOCALE_STORAGE_KEYS.DONOR_IDS_EXPIRATION_DATE
      );
      const donorsExpirationDate = donorsExpirationJson
        ? new Date(donorsExpirationJson)
        : null;

      const currentDate = new Date();

      if (donorsExpirationDate && currentDate < donorsExpirationDate) {
        const newDonorIds = JSON.stringify([...existingDonorIds, id]);
        localStorage.setItem(LOCALE_STORAGE_KEYS.DONOR_IDS, newDonorIds);
      }

      if (
        !donorsExpirationDate ||
        (donorsExpirationDate && currentDate >= donorsExpirationDate)
      ) {
        const newDonorIds = JSON.stringify([id]);
        const newExpirationDate = endOfDay(currentDate).toJSON();
        localStorage.setItem(LOCALE_STORAGE_KEYS.DONOR_IDS, newDonorIds);
        localStorage.setItem(
          LOCALE_STORAGE_KEYS.DONOR_IDS_EXPIRATION_DATE,
          newExpirationDate
        );
      }
      refetchDonorListHandler();
      message.success(t('donorSavedToCache'));
    }

    if (alreadyInCache) {
      message.warning(t('alreadyInCache'));
    }
  });

  const disabled = !createDonorForm.formState.isValid;

  return (
    <Row>
      <Col flex="1 1 auto">
        <DonorSelect
          className={styles.select}
          allowClear
          controlled={{ control: createDonorForm.control, name: 'donorId' }}
        />
      </Col>
      <Col>
        <Button
          type="primary"
          disabled={disabled}
          onClick={addDonorToCacheHandler}
        >
          {t('cache')}
        </Button>
      </Col>
    </Row>
  );
};
