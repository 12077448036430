import { ControlledCheckbox } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.EditDonorForm.AdditionalSection');

export enum AdditionalFormSectionField {
  FirstCertificatePrinted = 'firstCertificatePrinted',
  SecondCertificatePrinted = 'secondCertificatePrinted',
}

type FormFieldType = FormFieldWithComponent<
  AdditionalFormSectionField,
  { name: string; disabled?: boolean }
>;

export const fields: FormFieldType[] = [
  {
    name: AdditionalFormSectionField.FirstCertificatePrinted,
    label: t(AdditionalFormSectionField.FirstCertificatePrinted),
    Component: ({ control, name, disabled }) => (
      <ControlledCheckbox controlled={{ control, name }} disabled={disabled} />
    ),
  },
  {
    name: AdditionalFormSectionField.SecondCertificatePrinted,
    label: t(AdditionalFormSectionField.SecondCertificatePrinted),
    Component: ({ control, name, disabled }) => (
      <ControlledCheckbox controlled={{ control, name }} disabled={disabled} />
    ),
  },
];
