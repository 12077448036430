import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { SerializedMutation } from 'providers/graphql/offline-link';

export const getOfflineMutations = (): SerializedMutation[] => {
  const existingOfflineMutationsStringData =
    localStorage.getItem(LOCALE_STORAGE_KEYS.OFFLINE_MUTATIONS) || '';
  return existingOfflineMutationsStringData !== ''
    ? JSON.parse(existingOfflineMutationsStringData)
    : [];
};
