import { ControlledSelect } from 'modules/common/components';
import Select from 'modules/common/components/Select';
import { ComponentProps, FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { FlowRate } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type Props = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Select>;

const t = getTranslation('donation.FlowRateSelect');

const flowRateOptions = Object.values(FlowRate).map((value) => {
  return {
    value,
    label: t(value.charAt(0).toLowerCase() + value.slice(1)),
  };
});

export const FlowRateSelect: FC<Props> = ({ controlled, ...otherProps }) => {
  return (
    <ControlledSelect
      {...otherProps}
      options={flowRateOptions}
      defaultValue={undefined}
      controlled={controlled}
      placeholder={t('selectFlowRate')}
    />
  );
};
