import Spin from 'modules/common/components/Spin';
import { FC } from 'react';

import styles from './styles.module.scss';

const ScreenSpinner: FC = () => {
  return (
    <div className={styles.loaderWrapper}>
      <Spin size="large" className={styles.spinner} />
    </div>
  );
};

export default ScreenSpinner;
