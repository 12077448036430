import {
  petSpeciesSchema,
  petGenderSchema,
  petNameSchema,
  petWeightSchema,
  bloodTypeSchema,
  petDateOfBirthSchema,
} from 'modules/donor/schemas';
import { AnimalGender, AnimalSpecies, BloodType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { DonorApplicationField } from './fields';

const t = getTranslation('donor.ValidationErrors');

const {
  Name,
  DateOfBirth,
  Breed,
  Neutered,
  Weight,
  HasTransfusion,
  TakeMedications,
  PrimaryVeterinarian,
  Species,
  Gender,
  MedicationsCourseComment,
  Location,
  Region,
  BloodType: TypeOfBlood,
} = DonorApplicationField;

type DonorApplicationSchemaType = {
  name: string;
  dateOfBirth: Date;
  neutered: boolean;
  weight: number;
  hasTransfusion: boolean;
  takeMedications: boolean;
  primaryVeterinarian: string;
  species: AnimalSpecies;
  breed: string;
  gender: AnimalGender;
  medicationCourse?: string;
  region?: string;
  location: string;
  bloodType: BloodType;
};

const donorApplicationSchema: yup.SchemaOf<DonorApplicationSchemaType> =
  yup.object({
    [Name]: petNameSchema,
    [DateOfBirth]: petDateOfBirthSchema,
    [Neutered]: yup.boolean().required(t('required', { field: t('neutered') })),
    [Weight]: petWeightSchema,
    [HasTransfusion]: yup
      .boolean()
      .required(t('required', { field: t('hasTransfusion') })),
    [TakeMedications]: yup
      .boolean()
      .required(t('required', { field: t('takeMedications') })),
    [PrimaryVeterinarian]: yup
      .string()
      .required(t('required', { field: t('primaryVeterinarian') }))
      .max(255),
    [Species]: petSpeciesSchema,
    [Breed]: yup
      .string()
      .max(50, t('atMost', { max: 50, field: t('breed') }))
      .required(t('required', { field: t('breed') })),
    [Gender]: petGenderSchema,
    [MedicationsCourseComment]: yup.string().when(TakeMedications, {
      is: true,
      then: yup.string().required(t('provideTreatmentInfo')),
    }),
    [Location]: yup.string().required(t('required', { field: t('location') })),
    [Region]: yup.string(),
    [TypeOfBlood]: bloodTypeSchema.required(),
  });

export { donorApplicationSchema };
export type { DonorApplicationSchemaType };
