import { Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
export default function Head() {
  const { t } = useTranslation(
    'donor.FormsToPrint.AthensVeterinaryDiagnostic.Head'
  );
  return (
    <Row>
      <Col span={15}>
        <Row className={styles.headerLeft}>
          <Row>
            <h2>
              <b>{t('laboratoryName')}</b>
            </h2>
          </Row>
          <Row>
            <p>{t('collegeName')}</p>
          </Row>
          <Row>
            <p>{t('universityName')}</p>
          </Row>
          <Row>
            <p>{t('address')}</p>
          </Row>
          <Row>
            <p>
              <b>{t('contactInfo')}</b>
            </p>
          </Row>
          <Row>
            <p>
              <b>
                {t('website')} <a href={t('websiteUrl')}>{t('websiteUrl')}</a>
              </b>
            </p>
          </Row>
        </Row>
      </Col>
      <Col span={9} className={styles.headerRight}>
        <div className={styles.licenseNumber}>
          <p>{t('licenseNumberRequired')}</p>
          <Row>
            <Input value={'0301201195'} />
          </Row>
        </div>
      </Col>
    </Row>
  );
}
