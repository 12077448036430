import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { NotFoundPage } from 'modules/common/pages/NotFoundPage';
import { Navigate } from 'react-router-dom';

const commonModule: IModule = {
  routes: [
    {
      path: '/',
      Component: () => {
        return <Navigate to={`${Routes.Profile}`} />;
      },
      type: RouteType.Auth,
    },
    {
      path: '/',
      Component: () => {
        return <Navigate to={`${Routes.Login}`} />;
      },
      type: RouteType.NoAuth,
    },
    {
      path: '/',
      Component: () => {
        return <Navigate to={`${Routes.Login}`} />;
      },
      type: RouteType.Public,
    },
    {
      path: Routes.NotFound,
      Component: NotFoundPage,
      type: RouteType.Public,
    },
    {
      path: '*',
      Component: () => {
        return <Navigate to={`${Routes.NotFound}`} />;
      },
      type: RouteType.Public,
    },
  ],
};

export default commonModule;
