import { Col, Collapse, Row } from 'antd';
import RecurringCalendar from 'modules/appointments/components/RecurringCalendar';
import { FC } from 'react';
import { Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

type PropTypes = {
  control: Control<CreateAppointmentValidationSchema, any>;
  activeKey: string | string[];
  onChange: (key: string | string[]) => void;
};

const FIELD_NAME = AppointmentFormField.Dates;

const TitleSelect: FC<PropTypes> = ({ control, activeKey, onChange }) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const fieldState = control.getFieldState(FIELD_NAME);
  const error = fieldState.error as unknown as FieldError | undefined;

  return (
    <Col span={24}>
      <Collapse ghost activeKey={activeKey} onChange={onChange}>
        <Collapse.Panel
          className={styles.recurringContainer}
          key="recurring"
          header={
            <Row gutter={[10, 0]}>
              <Col>{t('recurring')}</Col>
              {error?.message && (
                <Col className={styles.errorMessage}>{error.message}</Col>
              )}
            </Row>
          }
        >
          <RecurringCalendar controlled={{ control, name: FIELD_NAME }} />
        </Collapse.Panel>
      </Collapse>
    </Col>
  );
};

export default TitleSelect;
