import { Location } from 'modules/locations/components/LocationSelect/enums';
import { BloodType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donation.DonationsTable');

export const bloodTypesFilters = Object.values(BloodType).map((bloodType) => {
  return {
    text: t(bloodType.toLowerCase()),
    value: bloodType,
  };
});

const locationsValues = Object.values(Location);
export const locationsFilters = Object.keys(Location).map((key, index) => {
  return {
    text: t(key.toLowerCase()),
    value: locationsValues[index],
  };
});
