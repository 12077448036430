import { Result } from 'antd';
import { Routes } from 'config/routes';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: FC = () => {
  const { t } = useTranslation('common.NotFoundPage');
  return (
    <Result
      status="404"
      title={t('title')}
      subTitle={t('subTitle')}
      extra={
        <Button href={Routes.Profile} type="primary">
          {t('backHome')}
        </Button>
      }
    />
  );
};
