import { Col, Row, Divider } from 'antd';
import { FormItem } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { getAdditionalDonationInfoFields } from 'modules/donations/components/AdditionalDonationInfoForm/fields';
import { additionalDonationInfoSchema } from 'modules/donations/components/AdditionalDonationInfoForm/schema';
import { FC, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  fieldNamePrefix: string;
  formEnabled: boolean;
};

const AdditionalDonationInfoSection: FC<Props> = ({
  fieldNamePrefix,
  formEnabled,
}) => {
  const { t } = useTranslation('donation.AdditionalDonationInfoSection');
  const fields = useMemo(
    () => getAdditionalDonationInfoFields({ enabled: formEnabled }),
    [formEnabled]
  );
  const { control, formState } = useFormContext();
  const isRequired = getIsRequiredInSchema(additionalDonationInfoSchema);

  return (
    <Col span={24}>
      <Divider>{t('additionalInfoSection')}</Divider>
      <Row justify="space-between" gutter={16}>
        {fields.map(({ name, Component, label }) => {
          const error = formState.errors[fieldNamePrefix]?.[name] as
            | FieldError
            | undefined;
          const fieldName = `${fieldNamePrefix}.${name}`;

          return (
            <Col xs={24} sm={24} md={24} lg={24} xl={7} key={name}>
              <Col>
                <FormItem
                  className={styles.formItem}
                  extra={error?.message}
                  validateStatus={error?.message && 'error'}
                  label={label}
                  required={isRequired(name)}
                >
                  <Component control={control} name={fieldName} />
                </FormItem>
              </Col>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default AdditionalDonationInfoSection;
