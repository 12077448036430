import {
  AdditionalFormSectionSchemaType,
  additionalFormSectionSchema,
} from 'modules/donor/components/EditDonorForm/AdditionalSection/schema';
import * as yup from 'yup';

import {
  DonorStatusSectionSchemaType,
  donorStatusSectionSchema,
} from './DonorStatusSection/shcema';
import {
  OptionalFormSectionSchemaType,
  optionalFormSectionSchema,
} from './OptionalSection/schema';
import {
  RequiredFormSectionSchemaType,
  requiredFormSectionSchema,
} from './RequiredSection/schema';

type EditDonorSchemaType = {
  required: RequiredFormSectionSchemaType;
  optional: OptionalFormSectionSchemaType;
  additional: AdditionalFormSectionSchemaType;
  donorStatus: DonorStatusSectionSchemaType;
};

const editDonorSchema: yup.SchemaOf<EditDonorSchemaType> = yup.object({
  required: requiredFormSectionSchema,
  optional: optionalFormSectionSchema,
  additional: additionalFormSectionSchema,
  donorStatus: donorStatusSectionSchema,
});

export { editDonorSchema };
export type { EditDonorSchemaType };
