import TaskSchedule from 'modules/appointments/components/TaskSchedule';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TaskSchedulePage: FC = () => {
  const { t } = useTranslation('appointments.TaskSchedulePage');
  return (
    <ProfileSideMenuWrapper title={t('taskSchedule')}>
      <TaskSchedule />
    </ProfileSideMenuWrapper>
  );
};

export default TaskSchedulePage;
