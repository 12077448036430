import {
  bloodTypeSchema,
  petMarksSchema,
  donorTypeSchema,
  breedSchema,
  colorSchema,
  petSpeciesSchema,
  petGenderSchema,
  petWeightSchemaInKg,
  petAgeSchema,
  petNameSchema,
} from 'modules/donor/schemas';
import { BloodType, DonorType, AnimalGender, AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donor.ValidationErrors');

type CreateDonorSchemaType = {
  //Required fields
  name: string;
  age: number;
  neutered: boolean;
  weight: number;
  species: AnimalSpecies;
  gender: AnimalGender;
  primaryVeterinarian: string;
  location: string;

  //Optional fields
  distinguishableMarks?: string;
  bloodType?: BloodType;
  donorType?: DonorType;
  breed?: string | null;
  color?: string | null;
  donationFrequency?: string;
  teamId?: string;
  comment?: string;
  medicationCourse?: string;
  microchipNumber?: string;
};

const createDonorSchema: yup.SchemaOf<CreateDonorSchemaType> = yup.object({
  //Required fields
  name: petNameSchema,
  age: petAgeSchema,
  neutered: yup.boolean().required(t('required', { field: t('neutered') })),
  weight: petWeightSchemaInKg,
  species: petSpeciesSchema,
  gender: petGenderSchema,
  primaryVeterinarian: yup
    .string()
    .required(t('required', { field: t('primaryVeterinarian') }))
    .max(255),
  location: yup.string().required(t('required', { field: t('location') })),

  // Optional fields
  distinguishableMarks: petMarksSchema,
  bloodType: bloodTypeSchema,
  donorType: donorTypeSchema,
  breed: breedSchema,
  color: colorSchema,
  donationFrequency: yup.string(),
  teamId: yup.string().when('donorType', {
    is: DonorType.Grouped,
    then: yup.string().required(),
  }),
  comment: yup.string().max(250),
  microchipNumber: yup.string(),
  medicationCourse: yup.string(),
});

export { createDonorSchema };
export type { CreateDonorSchemaType };
