export const LOCALE_STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  DONOR_IDS: 'donor_ids',
  DONOR_IDS_EXPIRATION_DATE: 'donor_ids_expiration_date',
  OFFLINE_MUTATIONS: 'offline-mutations',
  CACHE_EXPIRES_IN: 'cache_expires_in',
  RESEND_EMAIL_CONFIRMATION_CODE_ALLOWED_IN:
    'resend_email_confirmation_code_will_be_allowed',
};
