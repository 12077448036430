import { Input } from 'antd';
import { TextAreaRef, TextAreaProps } from 'antd/lib/input/TextArea';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const TextArea = forwardRef<TextAreaRef, TextAreaProps>(
  ({ className, ...restProps }, ref) => {
    const classNames = `${styles.textArea} ${className || ''}`;
    return <Input.TextArea ref={ref} className={classNames} {...restProps} />;
  }
);

export default TextArea;
