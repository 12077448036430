import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import NotificationsList from 'modules/notification/components/NotificationsList';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NotificationsPage: FC = () => {
  const { t } = useTranslation('notification.NotificationsPage');
  return (
    <ProfileSideMenuWrapper title={t('notifications')}>
      <NotificationsList />
    </ProfileSideMenuWrapper>
  );
};

export default NotificationsPage;
