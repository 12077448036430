import { Col, Row } from 'antd';
import { FormItem } from 'modules/common/components';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getContactFields } from '../fields';
import { EditProfileFormSchemaType } from '../schema';

type PropTypes = {
  disabled?: boolean;
};

const AdditionalContactsField: FC<PropTypes> = ({ disabled }) => {
  const { control, formState } = useFormContext<EditProfileFormSchemaType>();
  const contactFields = useMemo(
    () => getContactFields({ disabled }),
    [disabled]
  );

  return (
    <Col span={24}>
      {contactFields.map(({ name, label, Component }) => {
        const fieldName = `contact.${name}`;
        const errorMessage = formState.errors.contact?.[name]?.message;
        return (
          <Row key={fieldName}>
            <Col span={24}>
              <FormItem
                label={label}
                extra={errorMessage}
                validateStatus={errorMessage && 'error'}
              >
                <Component control={control} name={fieldName} />
              </FormItem>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

export default AdditionalContactsField;
