import { LabworkListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.LabworkCardListSorter');
export const options = [
  {
    value: LabworkListSort.CollectionDate,
    label: t('collectionDate'),
  },
];
