import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AlignType } from 'rc-table/lib/interface';
import { FileOutput, UserAttachmentOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('owner.OwnerAttachmentsTable');

type Props = {
  onDownload: (file: FileOutput) => void;
  onDelete?: (fileId: string) => void;
  canEditOwnerProfile: boolean;
};

export const getColumns = ({
  onDownload,
  onDelete,
  canEditOwnerProfile,
}: Props) => {
  const columns: ColumnsType<UserAttachmentOutput> = [
    {
      title: t('file'),
      width: '70%',
      ellipsis: true,
      render: (row: UserAttachmentOutput) => {
        return (
          <Typography.Link
            download
            onClick={async () => row.file && onDownload(row.file)}
          >
            {row.file.filename}
          </Typography.Link>
        );
      },
    },
  ];

  if (canEditOwnerProfile) {
    return [
      ...columns,
      {
        title: t('deleteFile'),
        width: '15%',
        align: 'center' as AlignType,
        render: ({ file }: UserAttachmentOutput) => {
          return (
            <Popconfirm
              title={t('sureToDeleteAttachment')}
              onConfirm={() => onDelete?.(file.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          );
        },
      },
    ];
  }
  return columns;
};
