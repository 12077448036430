import { SelectProps } from 'antd';
import config from 'config';
import { ControlledSelect } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { ComponentProps, FC, useEffect } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useFindAllGroupQuery } from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
  userIds?: string[];
} & Omit<ComponentProps<typeof ControlledSelect>, 'options'>;

const DonorTeamSelect: FC<PropTypes> = ({ userIds, ...restProps }) => {
  const { clearDebounce, debounce } = useDebounce();
  const defaultInput = {
    take: config.DEFAULT_LIMIT,
    filter: userIds ? { userIds } : undefined,
  };
  const findAllGroupQuery = useFindAllGroupQuery({
    variables: {
      input: defaultInput,
    },
  });
  const groupList: SelectProps['options'] =
    findAllGroupQuery.data?.findAllGroup.data.map((group) => {
      return {
        label: group.title,
        value: group.id,
        children: group.teams?.map((team) => {
          return {
            label: team.title,
            value: team.id,
          };
        }),
      };
    });

  const handleSearch = (value: string) => {
    findAllGroupQuery.refetch({
      input: {
        ...defaultInput,
        filter: {
          ...defaultInput.filter,
          searchingQuery: value,
        },
      },
    });
  };

  useEffect(() => clearDebounce, []);

  return (
    <ControlledSelect
      {...restProps}
      showSearch
      options={groupList}
      loading={findAllGroupQuery.loading}
      filterOption={false}
      onSearch={debounce(handleSearch)}
      defaultActiveFirstOption={false}
    />
  );
};

export default DonorTeamSelect;
