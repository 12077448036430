import { Col } from 'antd';
import { ControlledInputNumber } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import ControlledDonationUnitRadioGroup from 'modules/donations/components/DonationUnitRadioGroup';
import { getTranslation } from 'utils/getTranslation';

import { QuantityFields } from './enum';

const t = getTranslation('donation.CreateDonationForm.Quantity');

export type QuantityFormFiledType = FormFieldWithComponent<
  QuantityFields,
  { name: string }
>;

type Props = {
  enabled: boolean;
};

export const getQuantityFields = ({
  enabled,
}: Props): QuantityFormFiledType[] => [
  {
    name: QuantityFields.approvedDonationsMl,
    label: t('Approved donation amount, ML'),
    Component: ({ control, name }) => {
      return (
        <Col span={24}>
          <ControlledInputNumber
            disabled={true}
            controlled={{ control, name }}
            placeholder={t('enterExpected')}
            addonAfter={t('ml')}
            stringMode
          />
        </Col>
      );
    },
  },
  {
    name: QuantityFields.unit,
    Component: ({ control, name }) => (
      <ControlledDonationUnitRadioGroup
        disabled={!enabled}
        controlled={{ control, name }}
      />
    ),
  },
  {
    name: QuantityFields.actual,
    label: t('actual'),
    Component: ({ control, name }) => (
      <Col span={24}>
        <ControlledInputNumber
          disabled={!enabled}
          controlled={{ control, name }}
          placeholder={t('enterActual')}
          addonAfter={t('ml')}
          stringMode
        />
      </Col>
    ),
  },
];
