import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { PermissionsEnum } from 'types.d';

import ChangeProductTrackingInfoModalProvider from './contexts/ChangeProductTrackingInfoModal';
import ProductsDashboardPage from './pages/Dashboard';
import ProductPage from './pages/Product';
import ProductsPage from './pages/Products';

const productsModule: IModule = {
  routes: [
    {
      path: Routes.Products,
      Component: ProductsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewProductList],
    },
    {
      path: Routes.Product,
      Component: ProductPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewProductList],
    },
    {
      path: Routes.ProductsDashboard,
      Component: ProductsDashboardPage,
      type: RouteType.Auth,
    },
  ],
  providers: [{ Provider: ChangeProductTrackingInfoModalProvider }],
};

export default productsModule;
