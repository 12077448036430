import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donation.PossibleDonationValuesDownload');

export const headers: LabelKeyObject[] = [
  { label: t('field'), key: 'field' },
  { label: t('possibleValue'), key: 'possibleValue' },
  { label: t('example'), key: 'example' },
];
