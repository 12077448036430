import { Col } from 'antd';
import { FormItem } from 'modules/common/components';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { fields } from './fields';
import styles from './styles.module.scss';

type PropTypes = {
  fieldNamePrefix: string;
  disabled?: boolean;
};

const AdditionalFormSection: FC<PropTypes> = ({
  fieldNamePrefix,
  disabled,
}) => {
  const { control, formState } = useFormContext();

  return (
    <>
      {fields.map(({ Component, label, name }) => {
        const fieldName = `${fieldNamePrefix}.${name}`;
        const errorMessage = formState.errors[fieldNamePrefix]?.[name]?.message;
        return (
          <Col span={24} key={fieldName}>
            <FormItem
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
            >
              <Component
                control={control}
                name={fieldName}
                disabled={disabled}
              />
              <span className={styles.customLabel}>{label}</span>
            </FormItem>
          </Col>
        );
      })}
    </>
  );
};

export default AdditionalFormSection;
