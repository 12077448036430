import { ControlledSelect } from 'modules/common/components';
import Select from 'modules/common/components/Select';
import { EmotionalSupport } from 'modules/donations/components/EmotionalSupportSelect/enum';
import { ComponentProps, FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { getTranslation } from 'utils/getTranslation';

type Props = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Select>;

const t = getTranslation('donation.EmotionalSupportSelect');

const emotionalSupportOptions = Object.values(EmotionalSupport).map((value) => {
  return {
    value,
    label: t(value.charAt(0).toLowerCase() + value.slice(1)),
  };
});

export const EmotionalSupportSelect: FC<Props> = ({
  controlled,
  ...otherProps
}) => {
  return (
    <ControlledSelect
      {...otherProps}
      options={emotionalSupportOptions}
      defaultValue={undefined}
      controlled={controlled}
      placeholder={t('selectEmotionalSupport')}
      mode="tags"
    />
  );
};
