import { EditOutlined } from '@ant-design/icons';
import { Col, Input } from 'antd';
import { Button, FormItem } from 'modules/common/components';
import { LocationSelect } from 'modules/donor/components';
import { useEditLocationsModalContext } from 'modules/locations/contexts/EditLocationsModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { ComponentProps, FC } from 'react';
import { Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocationType, PermissionsEnum } from 'types.d';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

type PropTypes = {
  control: Control<CreateAppointmentValidationSchema, any>;
  canAddDonorToAppointment?: boolean;
  daycare?: boolean;
  disabled?: boolean;
  filter?: LocationType[];
} & ComponentProps<typeof FormItem>;

const FIELD_NAME = AppointmentFormField.Location;

const LocationsSelect: FC<PropTypes> = ({
  canAddDonorToAppointment,
  control,
  daycare,
  disabled,
  filter,
  ...restFormItemProps
}) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const currentUser = useCurrentUser();
  const editLocationsAllowed =
    currentUser.isCan(PermissionsEnum.UpdateLocation) ||
    currentUser.isCan(PermissionsEnum.DeleteLocation) ||
    currentUser.isCan(PermissionsEnum.CreteLocation);
  const editLocationsModal = useEditLocationsModalContext();
  const fieldState = control.getFieldState(FIELD_NAME);
  const error = fieldState.error as unknown as FieldError | undefined;
  const locationSelectClassName = [
    styles.locationSelect,
    editLocationsAllowed ? styles.editable : '',
  ].join(' ');

  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('location')}
        extra={error?.message}
        validateStatus={error?.message && 'error'}
        {...restFormItemProps}
      >
        <Input.Group compact className={styles.locationsContainer}>
          <LocationSelect
            className={locationSelectClassName}
            showArrow
            locationTypes={filter}
            single
            disabled={disabled}
            controlled={{ control, name: FIELD_NAME }}
          />

          <Button
            className={styles.editLocationsButton}
            onClick={editLocationsModal?.showModal}
            disabled={!editLocationsAllowed}
          >
            <EditOutlined />
          </Button>
        </Input.Group>
      </FormItem>
    </Col>
  );
};

export default LocationsSelect;
