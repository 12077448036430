import { message } from 'antd';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { Link, To } from 'react-router-dom';
import { PermissionsEnum } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type PropTypes = {
  to: To;
  permissions: PermissionsEnum[];
  children: JSX.Element | string;
};

const RestrictedLink: FC<PropTypes> = ({ to, children, permissions }) => {
  const currentUser = useCurrentUser();
  const canVisitPage = permissions.every((permission) =>
    currentUser.isCan(permission)
  );
  const t = getTranslation('common.RestrictedLink');

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!canVisitPage) {
      e.preventDefault();
      message.warning(t('noPermission'));
    }
  };
  return (
    <Link to={to} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default RestrictedLink;
