import { ColumnsType } from 'antd/lib/table';
import config from 'config';
import {
  eachDayOfInterval,
  startOfWeek,
  lastDayOfWeek,
  format,
  isSameDay,
} from 'date-fns';
import { AppointmentOutput } from 'types.d';

import { TaskListMode } from './enum';
import styles from './styles.module.scss';

import TaskScheduleItem from '../TaskScheduleItem';

const CURRENT_DATE = new Date();

type ParamTypes = {
  mode: TaskListMode;
  onDayClick: (date: Date) => void;
  selectedDay?: Date;
};

export const getColumns = ({
  mode,
  onDayClick,
  selectedDay = CURRENT_DATE,
}: ParamTypes): ColumnsType<AppointmentOutput[]> => {
  const width = mode === TaskListMode.Daily ? '50%' : '12.5%';
  const daysOfWeek = eachDayOfInterval({
    start: startOfWeek(selectedDay, { weekStartsOn: 1 }),
    end: lastDayOfWeek(selectedDay, { weekStartsOn: 1 }),
  });
  const businessWeekdays = daysOfWeek.slice(0, 7);
  const daysToRender =
    mode === TaskListMode.Daily ? [selectedDay] : businessWeekdays;
  const dynamicColumns: ColumnsType<AppointmentOutput[]> = daysToRender.map(
    (date) => {
      format(date, config.SCHEDULE_DATE_FORMAT);

      return {
        title: (
          <div onClick={() => onDayClick(date)}>
            {format(date, config.SCHEDULE_DATE_FORMAT)}
          </div>
        ),
        className: styles.dayColumn,
        width: '13%',
        render: (appointments: AppointmentOutput[]) => {
          const [appointment] = appointments;
          if (isSameDay(new Date(appointment.start), date)) {
            return <TaskScheduleItem appointments={appointments} />;
          }
        },
      };
    }
  );
  return [
    {
      render: ([{ start, end }]: AppointmentOutput[]) =>
        `${format(new Date(start), config.APPOINTMENT_TIME_FORMAT)} - ${format(
          new Date(end),
          config.APPOINTMENT_TIME_FORMAT
        )}`,
      width: 9,
      title: 'Time',
    },
    ...dynamicColumns,
  ];
};
