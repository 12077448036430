export default {
  NotificationWrapper: {
    appointmentCanceled: 'Appointment canceled',
    appointmentCreated: 'Appointment created',
    appointmentForGroupCreated: 'Appointment for group created',
    donorEnrolled: 'Donor enrolled',
    donorUnenrolled: 'Donor unenrolled',
    newNotification: 'New notification',
    requestCreated: 'Request Created',
  },
  NotificationsPage: {
    notifications: 'Notifications',
  },
  NotificationCard: {
    sureToDeleteNotification: 'Are you sure you want to delete notification?',
    appointmentCanceled: 'Appointment Canceled',
    appointmentCreated: 'Appointment Created',
    appointmentForGroupCreated: 'Appointment For Group Created',
    donorEnrolled: 'Donor Enrolled',
    donorUnenrolled: 'Donor Unenrolled',
    requestCreated: 'Request created',
    AppointmentCanceled: {
      appointmentJustCanceled:
        '{{appointmentTitle}} has just canceled on {{start}} - {{end}}',
      appointmentJustCanceled_withDonor:
        '{{appointmentTitle}} has just canceled on {{start}} - {{end}} with {{donorName}}',
    },
    AppointmentCreated: {
      appointmentJustCreated:
        '{{appointmentTitle}} has just created to {{appointmentMembers}} on {{start}} - {{end}}',
      appointmentJustCreated_withDonor:
        '{{appointmentTitle}} has just created to {{appointmentMembers}} on {{start}} - {{end}} with {{donorName}}',
    },
    AppointmentForGroupCreated: {
      appointmentJustCreated:
        '{{appointmentTitle}} has just created to {{appointmentMembers}} on {{start}} - {{end}}',
      appointmentJustCreated_withGroup:
        '{{appointmentTitle}} has just created to {{appointmentMembers}} on {{start}} - {{end}} for {{groupTitle}}',
    },
    DonorEnrolled: {
      donorJustEnrolled:
        'Donor has just enrolled to {{appointmentTitle}} with {{appointmentMembers}} on {{start}} - {{end}}',
      donorJustEnrolled_withDonor:
        '{{donorName}} has just enrolled to {{appointmentMembers}} on {{start}} - {{end}}',
    },
    DonorUnenrolled: {
      donorJustUnenrolled:
        'Appointment {{appointmentTitle}} has been canceled by owner on {{start}} - {{end}}',

      donorJustUnenrolled_withDonor:
        'Appointment has been canceled by owner with donor {{donorName}} on {{start}} - {{end}}',
    },
    RequestCreated: {
      requestCreated: 'New donor has been created and waiting for approval.',
      processRequest: 'Process request',
      requestAlreadyProcessed: 'The request has already been processed',
      unknownError: 'Something was wrong',
    },
  },
};
