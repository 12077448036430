import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

import { EditUserFields } from './enum';

const t = getTranslation('admin.InvitePage');

const fields: FormFieldWithComponent<
  EditUserFields,
  { name: EditUserFields; disabled?: boolean }
>[] = [
  {
    label: t('firstName'),
    name: EditUserFields.firstName,
    Component: ({ name, control, disabled }) => (
      <ControlledInput
        disabled={disabled}
        controlled={{ name, control }}
        placeholder={t('enterFirstName')}
      />
    ),
  },
  {
    label: t('lastName'),
    name: EditUserFields.lastName,
    Component: ({ name, control, disabled }) => (
      <ControlledInput
        disabled={disabled}
        controlled={{ name, control }}
        placeholder={t('enterLastName')}
      />
    ),
  },
  {
    label: t('phone'),
    name: EditUserFields.phoneNumber,
    Component: ({ name, control, disabled }) => (
      <ControlledPhoneInput
        disabled={disabled}
        controlled={{ name, control }}
        placeholder={t('enterPhone')}
      />
    ),
  },
];

export default fields;
