import { SelectOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Collapse, Row, message } from 'antd';
import { Routes } from 'config/routes';
import { DeletePopconfirm, Spin } from 'modules/common/components';
import GroupDonorCardInfo from 'modules/donor/components/GroupDonorCardInfo';
import TeamActionsButtons from 'modules/donor/components/TeamActionsButtons';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TeamOutput, DonorOutput, useUpdateDonorMutation } from 'types.d';

import ExpandIcon from './components/ExpandIcon';
import Header from './components/Header';
import { TeamTitleFromSchema, teamTitleFormSchema } from './schema';
import styles from './styles.module.scss';

const { Panel } = Collapse;

type PropTypes = {
  team: TeamOutput;
  setCurrentEdit: (group: null | string) => void;
  currentEdit: null | string;
};
//TODO: add sorting
const TeamCollapse: FC<PropTypes> = ({ team, currentEdit, setCurrentEdit }) => {
  const { t } = useTranslation('donor.TeamCollapse');

  const [isEditMode, setIsEditMode] = useState(false);
  const teamTitleForm = useForm<TeamTitleFromSchema>({
    resolver: yupResolver(teamTitleFormSchema),
    mode: 'onChange',
    defaultValues: {
      title: team.title,
    },
  });

  useEffect(() => {
    if (!isEditMode && teamTitleForm.formState.isDirty) {
      teamTitleForm.reset();
    }
  }, [isEditMode]);

  const [updateDonor, { loading }] = useUpdateDonorMutation({
    refetchQueries: ['findGroup', 'getUserDonorsList'],
    onCompleted: () => {
      message.success(t('successfullyUpdated'));
    },
    onError: ({ message: m }) => {
      message.error(m);
    },
  });

  const removeDonorFromTeamHandler = (donor: DonorOutput) => {
    updateDonor({
      variables: {
        input: {
          id: donor.id,
          teamId: null,
        },
      },
    });
  };

  const navigate = useNavigate();
  const navigateToDonorProfile = (donorId: string) => () => {
    navigate(`${Routes.Donors}/${donorId}`);
  };

  const collapsible = team.donors?.length ? undefined : 'disabled';
  const isEditCurrent = isEditMode ? currentEdit === team.id : isEditMode;
  return (
    <Spin spinning={loading}>
      <Collapse
        accordion
        className={styles.teamCollapse}
        collapsible={collapsible}
        expandIcon={(panelProps) => <ExpandIcon {...panelProps} />}
      >
        <Panel
          key={team.id}
          header={
            <Header
              isEditMode={isEditCurrent}
              controlled={{ control: teamTitleForm.control, name: 'title' }}
              team={team}
              errorMessage={teamTitleForm.formState.errors.title?.message}
            />
          }
          extra={
            <TeamActionsButtons
              team={team}
              isEditMode={isEditCurrent}
              onToggleEditMode={(value) => {
                teamTitleForm.reset();
                setIsEditMode(value);
                setCurrentEdit(team.id);
              }}
              isMobile
              form={teamTitleForm}
            />
          }
        >
          <Collapse>
            {team.donors?.map((donor) => {
              return (
                <Panel
                  key={donor.id}
                  header={donor.name}
                  extra={
                    <Row gutter={[10, 0]}>
                      <Col>
                        <SelectOutlined
                          className={styles.donorLink}
                          onClick={navigateToDonorProfile(
                            String(donor.shortId)
                          )}
                        />
                      </Col>
                      <Col>
                        <DeletePopconfirm
                          title={t('sureToDelete')}
                          onConfirm={() => removeDonorFromTeamHandler(donor)}
                        />
                      </Col>
                    </Row>
                  }
                >
                  <GroupDonorCardInfo donor={donor} />
                </Panel>
              );
            })}
          </Collapse>
        </Panel>
      </Collapse>
    </Spin>
  );
};

export default TeamCollapse;
