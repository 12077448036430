import { Col, message, Row, Grid } from 'antd';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { Spin } from 'modules/common/components';
import { AddDonorToCacheForm } from 'modules/donor/components/AddDonorToCacheForm';
import { CacheManagement } from 'modules/donor/components/CacheManagement';
import IndividualDonorCardList from 'modules/donor/components/IndividualDonorCardList';
import IndividualDonorListTable from 'modules/donor/components/IndividualDonorListTable';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DonorOutput,
  useGetAppointmentsDonorsListQuery,
  useGetDonorsListByIdsQuery,
} from 'types.d';

const AppointmentsDonors: FC = () => {
  const { t } = useTranslation('donor.AppointmentsDonors');
  const breakpoints = Grid.useBreakpoint();
  const isBreakpointsEmpty = Object.keys(breakpoints).length === 0;
  const pagination = usePagination();
  const getAppointmentsDonorsListQuery = useGetAppointmentsDonorsListQuery({
    onCompleted: (result) => {
      pagination.setTotal(result.getAppointmentsDonorsList.length);
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const existingDonorIds = getDonorIdsFromLocalStorage();

  const getAdditionalDonorListQuery = useGetDonorsListByIdsQuery({
    variables: {
      input: {
        ids: existingDonorIds,
      },
    },
  });

  const appointmentsDonorList =
    (getAppointmentsDonorsListQuery.data
      ?.getAppointmentsDonorsList as DonorOutput[]) || [];

  const additionalDonorList =
    (getAdditionalDonorListQuery.data?.getDonorsListByIds as DonorOutput[]) ||
    [];

  const donorList = [...appointmentsDonorList, ...additionalDonorList];

  const individualDonorListConfig = {
    pagination,
    donorList,
  };

  const isLoading =
    getAdditionalDonorListQuery.loading ||
    getAppointmentsDonorsListQuery.loading;

  const refetchDonorListHandler = () => {
    const donorIds = getDonorIdsFromLocalStorage();

    getAdditionalDonorListQuery.refetch({
      input: {
        ids: donorIds,
      },
    });
  };

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')} tip={t('pageDescription')}>
      <Spin spinning={isLoading}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <CacheManagement />
          </Col>
          <Col span={24}>
            <AddDonorToCacheForm
              refetchDonorListHandler={refetchDonorListHandler}
              appointmentsDonorList={appointmentsDonorList}
            />
          </Col>
          <Col span={24}>
            {!isBreakpointsEmpty &&
              (breakpoints.md ? (
                <IndividualDonorListTable {...individualDonorListConfig} />
              ) : (
                <IndividualDonorCardList {...individualDonorListConfig} />
              ))}
          </Col>
        </Row>
      </Spin>
    </ProfileSideMenuWrapper>
  );
};

const getDonorIdsFromLocalStorage = (): string[] => {
  const existingDonorIdsJson =
    localStorage.getItem(LOCALE_STORAGE_KEYS.DONOR_IDS) || '';
  return existingDonorIdsJson !== '' ? JSON.parse(existingDonorIdsJson) : [];
};

export default AppointmentsDonors;
