import { Col } from 'antd';
import MembersSelect from 'modules/appointments/components/MembersSelect';
import { FormItem } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

type PropTypes = {
  control: Control<CreateAppointmentValidationSchema, any>;
  canAddDonorToAppointment?: boolean;
} & ComponentProps<typeof FormItem>;

const FIELD_NAME = AppointmentFormField.UserIds;

const UserSelect: FC<PropTypes> = ({
  canAddDonorToAppointment,
  control,
  ...restFormItemProps
}) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const fieldState = control.getFieldState(FIELD_NAME);
  const errorMessage = (fieldState.error as unknown as FieldError)?.message;

  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('assignTo')}
        extra={errorMessage}
        validateStatus={errorMessage && 'error'}
        {...restFormItemProps}
      >
        <MembersSelect
          adjustDropdownPosition
          disableHighlights
          mode="multiple"
          controlled={{ control, name: FIELD_NAME }}
        />
      </FormItem>
    </Col>
  );
};

export default UserSelect;
