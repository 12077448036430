import { Table, message, TableProps, Row, Col, Grid } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import config from 'config';
import { Spin } from 'modules/common/components';
import { useModal, useSort } from 'modules/common/hooks';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import LabelsInfoModal from 'modules/products/components/LabelsInfoModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  Order,
  PermissionsEnum,
  ProductListFilterInput,
  ProductListSort,
  ProductOutput,
  useGetProductsQuery,
  useGetWoocommerceCategoriesQuery,
  useMakeProductAvailableMutation,
} from 'types.d';
import { envServices } from 'utils/EnvServices';

import { getColumns } from './columns';
import Header from './Header';

const { CreateLabels, MakeProductsAvailable } = PermissionsEnum;
const { useBreakpoint } = Grid;

const ProductsTable: FC = () => {
  const { t } = useTranslation('products.ProductsTable');
  const currentUser = useCurrentUser();
  const productListSort = useSort<ProductListSort>();
  const [selectedProducts, setSelectedProducts] = useState<ProductOutput[]>([]);
  const [productFilters, setProductFilters] =
    useState<ProductListFilterInput>();
  const isAllowedProductCreation = envServices.get(
    'REACT_APP_PRODUCT_CREATION_ALLOWED'
  );
  const canCreateLabels =
    currentUser.isCan(CreateLabels) && isAllowedProductCreation;
  const canMakeProductsAvailable =
    currentUser.isCan(MakeProductsAvailable) && isAllowedProductCreation;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchQuery = searchParams.get('donation') || undefined;
    setProductFilters((previousFilter) => {
      return {
        ...previousFilter,
        searchQuery,
      };
    });
  }, [searchParams]);

  const labelsInfoModal = useModal<{ products?: ProductOutput[] }>();
  const [makeProductAvailable, makeProductAvailableMutation] =
    useMakeProductAvailableMutation({
      refetchQueries: ['getProducts'],
      onCompleted: () => {
        message.success(t('productAvailable'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
  });
  const productCategories =
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories.reduce(
      (categories, category) => {
        return { ...categories, [category.id]: category.name };
      },
      {} as Record<number, string>
    );
  const selectedProductsIds = selectedProducts.map(({ id }) => id);
  const pagination = usePagination();
  const productsQuery = useGetProductsQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        filter: productFilters,
        sort: productListSort.options,
      },
    },
    onCompleted: ({ getProducts }) => {
      pagination.setTotal(getProducts.meta.total);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const isLoading =
    getWoocommerceCategoriesQuery.loading ||
    productsQuery.loading ||
    makeProductAvailableMutation.loading;
  const products = productsQuery.data?.getProducts.data as
    | ProductOutput[]
    | undefined;
  const { md } = useBreakpoint();
  const productsTableColumns = useMemo(() => {
    return getColumns({
      productCategories,
      showSorterTooltip: md,
      canCreateLabels,
      canMakeProductsAvailable,
      filterValue: productFilters,
      onPrint: (product) => labelsInfoModal.show({ products: [product] }),
      onAvailable: ({ id }) => {
        makeProductAvailable({
          variables: {
            input: {
              id,
            },
          },
          refetchQueries: ['getProducts'],
        });
      },
    });
  }, [productCategories, products]);

  const onSearch = (searchQuery: string | undefined) => {
    pagination.moveTo(1);
    setProductFilters({ searchQuery });
  };

  const onTableChange: TableProps<ProductOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<ProductOutput>;
      const fieldName = tableSorter.columnKey as ProductListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        productListSort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        productListSort.removeSortOption(fieldName);
      }
    }

    if (extra.action === 'filter') {
      setProductFilters((previousFilters) => ({
        ...previousFilters,
        ...filters,
        searchQuery: null,
      }));
    }
  };

  const onSelect = (changedRow: ProductOutput, selected: boolean): void => {
    setSelectedProducts((previousProducts: ProductOutput[]) =>
      selected
        ? [...previousProducts, changedRow]
        : previousProducts.filter((p) => p.id !== changedRow.id)
    );
  };

  const onSelectAll = (
    selected: boolean,
    _selectedRows: ProductOutput[],
    changedRows: ProductOutput[]
  ) => {
    setSelectedProducts((previousProducts: ProductOutput[]) => {
      if (selected) {
        return [...previousProducts, ...changedRows];
      }

      const changedRowsIds = changedRows.map((row) => row.id);
      return previousProducts.filter((p) => !changedRowsIds.includes(p.id));
    });
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Header
              onSearch={onSearch}
              selectedProducts={selectedProducts}
              searchingValue={productFilters?.searchQuery}
            />
          </Col>
          <Col span={24}>
            <Table
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedProductsIds,
                onSelect,
                onSelectAll,
              }}
              rowKey={({ id }) => id}
              columns={productsTableColumns}
              dataSource={products || []}
              onChange={onTableChange}
              pagination={pagination.config}
              scroll={{ x: 750 }}
            />
          </Col>
        </Row>
      </Spin>
      <LabelsInfoModal products={[]} {...labelsInfoModal.config} />
    </>
  );
};

export default ProductsTable;
