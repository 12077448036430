import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row, message } from 'antd';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddDonorsToDaycareMutation } from 'types.d';

import { AddDonorsSchema, addDonorsValidationSchema } from './schema';
import styles from './styles.module.scss';

import DaycareDonorSelect from '../../DaycareRegisterForm/DaycareDonorsSelect';

type PropTypes = {
  location: string;
  appointmentId: string;
  selectedDonorIds?: string[];
};

export const AddDonorsButton: FC<PropTypes> = ({
  location,
  appointmentId,
  selectedDonorIds,
}) => {
  const { t } = useTranslation('appointments.DaycareDetailModal');
  const [showDonorForm, setShowDonorForm] = useState(false);
  const { control, reset, getValues, formState, handleSubmit } =
    useForm<AddDonorsSchema>({
      resolver: yupResolver(addDonorsValidationSchema),
      mode: 'onChange',
    });
  const [addDonorsToDaycare, addDonorsToDaycareMutation] =
    useAddDonorsToDaycareMutation();

  const isSaveDisabled = !formState.isValid || !appointmentId;

  const onClose = () => {
    reset();
    setShowDonorForm(false);
  };

  const onSubmit = handleSubmit((data) => {
    if (!data.daycareDonorsIds?.length || !appointmentId) {
      return;
    }

    addDonorsToDaycare({
      variables: {
        input: {
          appointmentId,
          donorIds: getValues().daycareDonorsIds as string[],
        },
      },
      onCompleted() {
        reset();
        setShowDonorForm(false);
        message.success(t('donorAdded'));
      },
      onError() {
        message.error(t('donorAddedError'));
      },
      refetchQueries: ['getDaycareAppointmentInfo'],
    });
  });

  return (
    <>
      {!showDonorForm ? (
        <Button onClick={() => setShowDonorForm(true)}>{t('addDonors')}</Button>
      ) : (
        <Form>
          <Row align="middle" gutter={10}>
            <Col>
              <DaycareDonorSelect
                maxTagCount="responsive"
                selectedDonorIds={selectedDonorIds}
                className={styles.select}
                locations={[location]}
                controlled={{ name: 'daycareDonorsIds', control }}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={onSubmit}
                loading={addDonorsToDaycareMutation.loading}
                disabled={isSaveDisabled}
              >
                {t('save')}
              </Button>
            </Col>
            <Col>
              <Button onClick={onClose}>{t('close')}</Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
