import { Select as AntSelect, SelectProps } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

//TODO: fix a problem with clearing ant select on mobile (double-clicking on a clear button)

const { OptGroup, Option } = AntSelect;

type PropTypes = SelectProps & { adjustDropdownPosition?: boolean };

const Select = forwardRef<RefSelectProps, PropTypes>(
  ({ className, options, adjustDropdownPosition, ...restProps }, ref) => {
    const classNames = `${styles.select} ${className || ''}`;

    const getPopupContainer = (triggerNode: any) => {
      return triggerNode.parentNode;
    };

    return (
      <AntSelect
        getPopupContainer={
          adjustDropdownPosition ? getPopupContainer : undefined
        }
        ref={ref}
        {...restProps}
        className={classNames}
      >
        {options?.map((option) => {
          if (option.children) {
            return (
              <OptGroup
                key={option.value}
                label={option.label}
                value={option.value}
              >
                {option.children.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </OptGroup>
            );
          }
          return (
            <Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </Option>
          );
        })}
      </AntSelect>
    );
  }
);

export default Select;
