import { Row, Col } from 'antd';
import { Alert, Spin } from 'modules/common/components';
import { getDonationAlerts } from 'modules/donations/components/CreateDonationAlerts/alerts';
import { CreateDonationSchemaType } from 'modules/donations/components/CreateDonationForm/schema';
import { IVitalsParameters } from 'modules/donor/components/UpdateVitalsParametersModal/interface';
import { getParametersValues } from 'modules/donor/components/UpdateVitalsParametersModal/utils';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  useGetVitalsParameterListQuery,
  VitalsParameterOutput,
  DonationOutput,
  LabworkOutput,
} from 'types.d';

type Props = {
  previousDonationData?: DonationOutput;
  lastLabwork?: LabworkOutput;
};

export const CreateDonationAlerts: FC<Props> = ({
  previousDonationData,
  lastLabwork,
}) => {
  const { data, loading: gettingVitalsParameters } =
    useGetVitalsParameterListQuery();
  const parametersValues: IVitalsParameters =
    data?.getVitalsParameterList &&
    getParametersValues(
      data?.getVitalsParameterList as VitalsParameterOutput[]
    );
  const form = useFormContext();
  const donationAlerts = getDonationAlerts(
    parametersValues,
    form.watch() as CreateDonationSchemaType,
    previousDonationData,
    lastLabwork
  );
  const isAlertToDisplayExisted = donationAlerts.some(({ rule }) => rule());
  useEffect(() => {
    form.setValue(
      'vitalsSection.donationInfo.shouldDonate',
      !isAlertToDisplayExisted
    );
  }, [isAlertToDisplayExisted]);

  return (
    <Spin spinning={gettingVitalsParameters}>
      <Row gutter={[0, 10]}>
        {donationAlerts.map((value) => {
          return (
            value.rule() && (
              <Col span={24} key={value.message}>
                <Alert
                  type="error"
                  message={value.message}
                  description={value.description}
                  closable
                />
              </Col>
            )
          );
        })}
      </Row>
    </Spin>
  );
};
