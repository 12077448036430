import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donor.AddDonorToCacheForm');

type AddDonorToCacheSchema = {
  donorId: string;
};

const addDonorToCacheSchema: yup.SchemaOf<AddDonorToCacheSchema> = yup.object({
  donorId: yup.string().required(t('donorRequired')),
});
export { addDonorToCacheSchema };
export type { AddDonorToCacheSchema };
