import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Divider, Form, Button, message } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Spin } from 'modules/common/components';
import CollectedMlsChart from 'modules/donor/components/CollectedMlsChart';
import DonorAvatar from 'modules/donor/components/DonorAvatar';
import { getPictureUrl } from 'modules/donor/components/DonorPicturesList/utils';
import AdditionalFormSection from 'modules/donor/components/EditDonorForm/AdditionalSection';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetDonorLazyQuery,
  useUpdateDonorMutation,
  DonorOutput,
  FileOutput,
  UserRole,
} from 'types.d';

import { getDefaultParams } from './defaultParams';
import { DonorManagementButton } from './DonorManagementButton';
import DonorStatusSection from './DonorStatusSection';
import OptionalFormSection from './OptionalSection';
import { prepareDataForDonorUpdating } from './prepareDataForUpdate';
import PetFormSection from './RequiredSection';
import SaveButton from './SaveButton';
import { editDonorSchema, EditDonorSchemaType } from './schema';
import styles from './styles.module.scss';

const EditDonorForm: FC = () => {
  const { t } = useTranslation('donor.EditDonorForm');
  const navigate = useNavigate();
  const pageParams = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const [pictureUrl, setPictureUrl] = useState<string>();
  const donorId = pageParams.id;
  const editDonorForm = useForm<EditDonorSchemaType>({
    resolver: yupResolver(editDonorSchema),
    mode: 'onChange',
  });
  const canUpdateDonors =
    useCurrentUser().data?.permissions?.updateDonorsData || false;
  const canViewVitalsChart =
    useCurrentUser().data?.permissions?.viewVitalsChart || false;
  const canChangeDonorStatus =
    useCurrentUser().data?.permissions?.changeDonorStatus || false;
  const canDeleteDonors = useCurrentUser().data?.role.value === UserRole.Admin;
  const [updateDonor, updateDonorMutation] = useUpdateDonorMutation({
    onCompleted: () => {
      message.success('Donor has been updated!');
    },
    onError: (error) => {
      const donor = getDonorQuery.data?.getDonor as DonorOutput;
      const defaultParams = getDefaultParams(donor);
      editDonorForm.reset(defaultParams);
      message.error(error.message);
    },
  });
  const [getDonor, getDonorQuery] = useGetDonorLazyQuery({
    onCompleted: (data) => {
      const donor = data.getDonor as DonorOutput;
      const defaultParams = getDefaultParams(donor);
      editDonorForm.reset(defaultParams);
      const createPictureUrl = async () => {
        const donorPictureOutput = donor?.pictures;
        if (donorPictureOutput && donorPictureOutput.length > 0) {
          const url =
            donor?.pictures?.at(0)?.file &&
            (await getPictureUrl(donorPictureOutput.at(0)?.file as FileOutput));
          url && setPictureUrl(url);
        }
      };
      createPictureUrl();
    },
    onError: (err) => {
      message.error(err.message);
      if (err.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.IndividualDonors);
    },
  });
  const donor = getDonorQuery.data?.getDonor as DonorOutput;
  const isLoading = getDonorQuery.loading || updateDonorMutation.loading;
  const savedButtonDisabled = !(
    editDonorForm.formState.isValid && editDonorForm.formState.isDirty
  );
  const editDonorHandler = editDonorForm.handleSubmit(
    (formData) => {
      submitForm(formData);
    },
    () => {
      const formData = editDonorForm.getValues();
      submitForm(formData);
    }
  );

  const submitForm = (formData: EditDonorSchemaType) => {
    const preparedData = prepareDataForDonorUpdating(formData, donor);
    updateDonor({
      variables: {
        input: preparedData,
      },
    });
    setIsEditMode(false);
  };

  const cancelHandler = () => {
    editDonorForm.reset();
    setIsEditMode(false);
  };

  const turnOnEditMode = () => {
    editDonorForm.trigger();
    setIsEditMode(true);
  };

  useEffect(() => {
    if (donorId) {
      getDonor({
        variables: {
          input: {
            id: donorId,
          },
        },
      });
    }
  }, [donorId]);

  const specie = editDonorForm.watch('required.species');
  const weight = editDonorForm.watch('required.weight');

  useEffect(() => {
    if (weight) {
      editDonorForm.trigger('required.weight');
    }
  }, [specie, weight]);

  return (
    <Spin spinning={isLoading}>
      <Row justify="space-between">
        <Col>
          {canDeleteDonors && donor && (
            <DonorManagementButton
              donorId={donor?.id}
              donorType={donor?.type}
              donorStatus={donor?.status}
            />
          )}
        </Col>
        <Col>
          {canUpdateDonors && (
            <Row justify="end">
              <Col className={styles.cancelButtonWrapper}>
                <Button onClick={cancelHandler} disabled={!isEditMode}>
                  {t('cancel')}
                </Button>
              </Col>
              <Col>
                {isEditMode ? (
                  <SaveButton
                    disabled={savedButtonDisabled}
                    formIsValid={editDonorForm.formState.isValid}
                    onSave={editDonorHandler}
                  />
                ) : (
                  <Button type="primary" onClick={turnOnEditMode}>
                    {t('edit')}
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          {getDonorQuery?.data?.getDonor.createdAt &&
            format(
              new Date(getDonorQuery?.data?.getDonor.createdAt),
              config.DATE_FORMAT
            )}
        </Col>
      </Row>
      <FormProvider {...editDonorForm}>
        <Form labelCol={{ span: 24 }}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" gutter={[10, 10]}>
                <Col sm={{ order: 1, span: 12 }} span={24} order={2}>
                  <PetFormSection
                    fieldNamePrefix="required"
                    disabled={!isEditMode}
                  />
                </Col>
                <Col lg={6} sm={{ order: 2, span: 12 }} span={24} order={1}>
                  <DonorStatusSection
                    fieldNamePrefix="donorStatus"
                    disabled={!isEditMode}
                    canChangeDonorStatus={canChangeDonorStatus}
                  />
                  {pictureUrl && (
                    <Col span={24}>
                      <DonorAvatar src={pictureUrl} />
                    </Col>
                  )}
                </Col>
              </Row>
            </Col>
            <Divider />
            <Col span={24}>
              <Row gutter={[32, 0]}>
                <Col sm={12} span={24}>
                  <OptionalFormSection
                    fieldNamePrefix="optional"
                    disabled={!isEditMode}
                    donor={
                      getDonorQuery?.data?.getDonor as DonorOutput | undefined
                    }
                  />
                </Col>
                <Col sm={12} span={24}>
                  {canViewVitalsChart && <CollectedMlsChart />}
                  <AdditionalFormSection
                    fieldNamePrefix="additional"
                    disabled={!isEditMode}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Spin>
  );
};

export default EditDonorForm;
