import { Card, Col, Pagination, Row } from 'antd';
import { Routes } from 'config/routes';
import { Link } from 'modules/common/components';
import DonorCardInfo from 'modules/donor/components/DonorCardInfo';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { DonorOutput, DonorStatus } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  donorList: DonorOutput[];
  pagination: ReturnType<typeof usePagination>;
};

const IndividualDonorCardList: FC<PropTypes> = ({ donorList, pagination }) => {
  return (
    <Row gutter={[0, 30]}>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          {donorList.map((donor) => {
            return (
              <Col key={donor.id} span={24}>
                <Card
                  title={
                    <Link
                      to={`${Routes.DonorsProfile.replace(
                        ':id',
                        String(donor.shortId)
                      )}`}
                    >
                      {donor.name}
                    </Link>
                  }
                  className={
                    donor.status === DonorStatus.Archived ? styles.archived : ''
                  }
                >
                  <DonorCardInfo donor={donor} />
                </Card>
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="end">
          <Col>
            <Pagination {...pagination.config} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default IndividualDonorCardList;
