import { DatePicker } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import LabworkStatusSelect from 'modules/donor/components/LabworkStatusSelect';
import styles from 'modules/donor/components/LabworkTable/styles.module.scss';
import moment, { Moment } from 'moment';
import { LabworkOutput, LabworkStatus } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.LabworkCardInfo');

type Option = {
  label: string;
  value: JSX.Element | string;
};

type Props = {
  labwork: LabworkOutput;
  updateLabworkHandler: (data: LabworkOutput) => void;
  updateLabworkStatusHandler: (id: string, status: LabworkStatus) => void;
  canUpdateLabwork: boolean;
};

export const getOptions = ({
  labwork,
  updateLabworkHandler,
  updateLabworkStatusHandler,
  canUpdateLabwork,
}: Props): Option[] => [
  {
    label: t('date'),
    value: (
      <DatePicker
        value={moment(new Date(labwork.collectionDate))}
        onChange={(value: Moment | null, dateString: string) => {
          updateLabworkHandler({
            ...labwork,
            collectionDate: new Date(dateString),
          });
        }}
        format={(value) => format(value.toDate(), config.DATE_FORMAT)}
        className={styles.datePicker}
        allowClear={false}
        disabled={!canUpdateLabwork}
        disabledDate={(current) => current && current > moment().endOf('day')}
      />
    ),
  },
  {
    label: t('status'),
    value: (
      <LabworkStatusSelect
        defaultValue={labwork.status}
        onSelect={(status: LabworkStatus) =>
          updateLabworkStatusHandler(labwork.id, status)
        }
        disabled={!canUpdateLabwork}
      />
    ),
  },
];
