import { CloseCircleFilled } from '@ant-design/icons';
import { BaseOptionType } from 'antd/lib/select';
import config from 'config';
import { Select } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFindAllGroupQuery, useFindGroupQuery } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
  userIds?: string[];
  showClearButtonByDefault?: boolean;
} & Omit<ComponentProps<typeof Select>, 'options'>;

const GroupSelect: FC<PropTypes> = ({
  controlled,
  className,
  userIds,
  allowClear,
  showClearButtonByDefault,
  ...restProps
}) => {
  const { t } = useTranslation('donor.GroupSelect');
  const classNames = [styles.select, className || ''].join(' ');
  const [isCloseBtnHidden, setIsCloseBtnHidden] = useState<boolean>(
    !showClearButtonByDefault
  );
  const controller = useController(controlled);
  const { onChange, ...restControllerField } = controller.field;
  const { clearDebounce, debounce } = useDebounce();
  const findGroupQuery = useFindAllGroupQuery({
    variables: {
      input: {
        take: config.DEFAULT_LIMIT,
        filter: userIds?.length
          ? {
              userIds,
            }
          : undefined,
      },
    },
  });

  const currentGroup = useFindGroupQuery({
    variables: {
      input: {
        id: controller.field.value?.value,
      },
    },
  });

  const groups = findGroupQuery.data?.findAllGroup.data;
  const containsCurrentGroup = groups?.find(
    (group) => group.id === controller.field.value?.value
  );

  const groupOptions = useMemo(() => {
    const defaultOptions = groups?.map(({ id, title }) => {
      return {
        value: id,
        label: title,
      };
    });

    const optionalOption = {
      value: currentGroup.data?.findGroup.id,
      label: currentGroup.data?.findGroup.title,
    };

    const options = containsCurrentGroup
      ? defaultOptions
      : [...(defaultOptions || []), optionalOption];

    return options;
  }, [groups, findGroupQuery]);

  const handleSearch = (searchingQuery: string) => {
    findGroupQuery.refetch({
      input: {
        filter: {
          searchingQuery,
          userIds: userIds?.length ? userIds : undefined,
        },
      },
    });
  };

  useEffect(() => clearDebounce, []);

  const onClear = () => {
    onChange(null);
    setIsCloseBtnHidden(true);
  };

  const clearButton = isCloseBtnHidden ? undefined : (
    <CloseCircleFilled onClick={onClear} />
  );

  return (
    <Select
      {...restControllerField}
      {...restProps}
      options={groupOptions}
      showSearch
      adjustDropdownPosition
      className={classNames}
      loading={findGroupQuery.loading}
      onSearch={debounce(handleSearch)}
      filterOption={false}
      placeholder={t('selectGroup')}
      onSelect={(_value: any, option: BaseOptionType) => {
        onChange(option);

        if (allowClear) {
          setIsCloseBtnHidden(false);
        }
      }}
      onDeselect={() => onChange(null)}
      suffixIcon={allowClear ? clearButton : undefined}
    />
  );
};

export default GroupSelect;
