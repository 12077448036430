import { TablePaginationConfig } from 'antd';
import config from 'config';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type Parameters = {
  total?: number;
  startPage?: number;
  skip?: number;
  pageSize?: number;
  onChange?: (page: number, pageSize: number) => void;
  urlPagination?: boolean;
};

type ReturnedProps = {
  config: TablePaginationConfig;
  skip: number;
  setTotal: (value: number) => void;
  moveTo: (page: number) => void;
};

const defaultParameters = {
  startPage: 1,
  pageSize: config.PAGE_SIZE,
  skip: 0,
};

const usePagination = (parameters?: Parameters): ReturnedProps => {
  const settings = { ...defaultParameters, ...parameters };
  const { startPage, pageSize, onChange, urlPagination = true } = settings;
  const [total, setTotal] = useState(settings.total);
  const [skip, setSkip] = useState<number>(settings.skip);
  const [current, setCurrent] = useState<number>(startPage);
  const navigate = useNavigate();
  const location = useLocation();

  const setUrlPage = (page: number) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set('page', page.toString());
    navigate(`?${urlSearchParams.toString()}`);
  };

  const onChangeHandler = (page: number, curPageSize: number) => {
    setSkip((page - 1) * curPageSize);
    setCurrent(page);
    onChange && onChange(page, curPageSize);

    if (urlPagination) {
      setUrlPage(page);
    }
  };

  const moveTo = (page: number, setUrl = true) => {
    setSkip((page - 1) * pageSize);
    setCurrent(page);
    setUrl && setUrlPage(page);
  };

  useEffect(() => {
    if (!urlPagination) {
      return;
    }

    const urlSearchParams = new URLSearchParams(location.search);
    const pageInUrl =
      parseInt(urlSearchParams.get('page') as string) ||
      defaultParameters.startPage;

    const maxPage = Math.ceil((total || 0) / pageSize);
    const page = pageInUrl > maxPage ? 1 : pageInUrl;
    moveTo(page, false);
  }, [location.search, total]);

  return {
    config: {
      total,
      current,
      pageSize,
      onChange: onChangeHandler,
      showSizeChanger: false,
      hideOnSinglePage: true,
    },
    skip,
    setTotal,
    moveTo,
  };
};

export { usePagination };
