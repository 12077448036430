export enum PersonalInfoFields {
  firstName = 'firstName',
  lastName = 'lastName',
  phoneNumber = 'phoneNumber',
  streetAddress = 'streetAddress',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  locationPreferences = 'locationPreferences',
  birthday = 'birthday',
  contactPreferences = 'contactPreferences',
}
