import i18next from 'i18next';
import locales from 'locales';
import { useEffect, useState } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';

const i18nextConfig = i18next
  .createInstance({
    fallbackLng: 'en',
    resources: locales,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
  })
  .use(initReactI18next)
  .init();

interface IProps {
  children: React.ReactNode;
}

const I18next = ({ children }: IProps) => {
  const [i18nConfig, setI18nConfig] = useState();
  useEffect(() => {
    const getI18NextConfig = async () => {
      setI18nConfig(await Promise.resolve(i18nextConfig));
    };

    getI18NextConfig();
  }, []);

  return i18nConfig ? (
    <I18nextProvider i18n={i18nConfig}>{children}</I18nextProvider>
  ) : (
    <div>{children}</div>
  );
};

export default I18next;
