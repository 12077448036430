import { Routes } from 'config/routes';
import { Link } from 'modules/common/components';
import { ReactNode } from 'react';
import { DonationOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donation.DonationDetails');

type ParamType = {
  donation: DonationOutput;
};

export const getFields = ({ donation }: ParamType) => {
  const bloodType = donation.donor.bloodType
    ? t(donation.donor.bloodType?.toLowerCase())
    : '-';
  const donationDetailsFields: Record<string, ReactNode> = {
    [t('donationId')]: (
      <Link
        target="_blank"
        to={Routes.EditDonation.replace(':id', String(donation.shortId))}
      >
        {/*TODO: change id on short id in this link*/}
        {donation.shortId}
      </Link>
    ),
    [t('donorName')]: donation.donor.name,
    [t('ownerLastName')]: donation.donor.user?.lastName || '-',
    [t('bloodType')]: bloodType,
    [t('location')]: donation.donor.location,
  };
  return donationDetailsFields;
};
