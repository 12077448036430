import { ModalProps } from 'antd';
import { useState } from 'react';
import { AppointmentOutput } from 'types';

import { ContextType } from './type';

export const useProviderValue = (): ContextType => {
  const [modalProps, setModalProps] = useState<ModalProps>();
  const [appointment, setAppointment] = useState<AppointmentOutput>();
  const [visible, setVisible] = useState(false);

  const showModal = (appointmentData?: AppointmentOutput) => {
    setVisible(true);
    setAppointment(appointmentData);
  };

  const updateModalProps = (props: ModalProps) => {
    setModalProps(props);
  };

  const hideModal = () => {
    setVisible(false);
    setAppointment(undefined);
  };

  return {
    modalConfig: {
      ...modalProps,
      appointment,
      visible,
      hide: hideModal,
    },
    showModal,
    hideModal,
    updateModalProps,
  };
};
