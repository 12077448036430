import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form, message, Row, Col, Button } from 'antd';
import { UserType } from 'modules/admin/components/EditUserForm/EmailSection/enum';
import { GET_USER_QUERY } from 'modules/admin/graphql/queries';
import { ControlledInput, FormItem, Spin } from 'modules/common/components';
import { GET_USER_LIST } from 'modules/user/graphql/queries/user';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useUpdateUserEmailMutation,
  UserOutput,
  useUpdateCurrentOwnerEmailMutation,
  useUpdateOwnerEmailMutation,
  PermissionsEnum,
} from 'types.d';

import { EditUserEmailSchema, editUserEmailSchema } from './schema';

type Props = {
  user: UserOutput;
  canEdit: boolean;
  userType: UserType;
};

const EditUserEmailForm: FC<Props> = ({ user, canEdit, userType }) => {
  const { t } = useTranslation('admin.EditUserEmailForm');
  const [formEnabled, setFormEnabled] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const canEditProfile = currentUser.isCan(PermissionsEnum.UpdateOwnersData);
  const { control, formState, reset, handleSubmit } =
    useForm<EditUserEmailSchema>({
      resolver: yupResolver(editUserEmailSchema),
      mode: 'onChange',
    });

  useEffect(() => {
    reset({
      email: user.email,
    });
  }, [user]);

  const [updateUserEmail, updateUserEmailMutation] = useUpdateUserEmailMutation(
    {
      refetchQueries: [GET_USER_QUERY, GET_USER_LIST],
      onCompleted: (data) => {
        if (data.updateUserEmail.email !== user.email) {
          message.success(t('letterHasBeenSentToNewEmail'));
        } else {
          message.success(t('successfullySaved'));
        }
      },
      onError: (error) => {
        message.error(error.message);
        reset({
          email: user.email,
        });
      },
    }
  );

  const [updateCurrentOwnerEmail, updateCurrentOwnerEmailMutation] =
    useUpdateCurrentOwnerEmailMutation({
      refetchQueries: [GET_USER_QUERY, GET_USER_LIST],
      onCompleted: (data) => {
        if (data.updateCurrentOwnerEmail.email !== user.email) {
          message.success(t('letterHasBeenSentToNewEmail'));
        } else {
          message.success(t('successfullySaved'));
        }
      },
      onError: (error) => {
        message.error(error.message);
        reset({
          email: user.email,
        });
      },
    });

  const [updateOwnerEmail, updateOwnerEmailMutation] =
    useUpdateOwnerEmailMutation({
      refetchQueries: [GET_USER_QUERY, GET_USER_LIST],
      onCompleted: (data) => {
        if (data.updateOwnerEmail.email !== user.email) {
          message.success(t('letterHasBeenSentToNewEmail'));
        } else {
          message.success(t('successfullySaved'));
        }
      },
      onError: (error) => {
        message.error(error.message);
        reset({
          email: user.email,
        });
      },
    });

  const mutationsMap = {
    [UserType.Owner]: updateOwnerEmail,
    [UserType.CurrentOwner]: updateCurrentOwnerEmail,
    [UserType.InternalTeam]: updateUserEmail,
  };

  const editUserEmailHandler = handleSubmit((data) => {
    const mutation = mutationsMap[userType];
    mutation({
      variables: {
        input: {
          id: user.id || '',
          ...data,
        },
      },
    });
    setFormEnabled(!formEnabled);
  });

  const closeHandler = () => {
    reset({
      email: user.email,
    });
    setFormEnabled(false);
  };

  const name = 'email';
  const error = formState.errors[name]?.message;

  const loading =
    updateCurrentOwnerEmailMutation.loading ||
    updateOwnerEmailMutation.loading ||
    updateUserEmailMutation.loading;

  const isSaveButtonDisabled = !formState.isValid || !formState.isDirty;

  return (
    <Spin spinning={loading}>
      <Form>
        <FormItem
          key={name}
          label={t(name)}
          validateStatus={error && 'error'}
          extra={error}
        >
          <Row align="middle">
            <Col span={canEdit ? 18 : 24}>
              <ControlledInput
                key={name}
                controlled={{ name, control }}
                placeholder={t('enterEmail')}
                disabled={!formEnabled}
              />
            </Col>
            {canEdit && (
              <Col offset={1} span={5}>
                {formEnabled ? (
                  <Row justify="space-between">
                    <Col>
                      <Button type="link" onClick={closeHandler}>
                        <CloseOutlined />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="link"
                        onClick={editUserEmailHandler}
                        disabled={isSaveButtonDisabled}
                      >
                        <CheckOutlined />
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row justify="end">
                    {canEditProfile && (
                      <EditOutlined onClick={() => setFormEnabled(true)} />
                    )}
                  </Row>
                )}
              </Col>
            )}
          </Row>
        </FormItem>
      </Form>
    </Spin>
  );
};
export default EditUserEmailForm;
