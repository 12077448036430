import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { PermissionsEnum } from 'types.d';

import CreateProductModalProvider from './contexts/CreateProductModal';
import UploadDonationsModalProvider from './contexts/UploadDonationsModal';
import AdditionalDonationInfoPage from './pages/AdditionalDonationInfoPage';
import CreateDonationPage from './pages/CreateDonation';
import DonationDataCollectionPage from './pages/DonationDataCollectionPage';
import EditDonationPage from './pages/EditDonation';

const donation: IModule = {
  routes: [
    {
      path: Routes.CreateDonation,
      Component: CreateDonationPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.AddNewDonation],
    },
    {
      path: Routes.EditDonation,
      Component: EditDonationPage,
      type: RouteType.Auth,
      permissions: [
        PermissionsEnum.UpdateDonationData,
        PermissionsEnum.ViewSpecificDonationDataAtDonorProfile,
      ],
    },
    {
      path: Routes.EditDonationAdditionalInfo,
      Component: AdditionalDonationInfoPage,
      type: RouteType.Auth,
      permissions: [
        PermissionsEnum.UpdateDonationData,
        PermissionsEnum.ViewSpecificDonationDataAtDonorProfile,
      ],
    },
    {
      path: Routes.CollectionDonations,
      Component: DonationDataCollectionPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewDonationDataCollectionPage],
    },
  ],
  providers: [
    { Provider: CreateProductModalProvider },
    { Provider: UploadDonationsModalProvider },
  ],
};

export default donation;
