import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const EditLocationsModalContext = createContext<ContextType | null>(null);

const EditLocationsModalProvider: ComponentWithChildren = ({ children }) => {
  const providerValue = useProviderValue();

  return (
    <EditLocationsModalContext.Provider value={providerValue}>
      {children}
    </EditLocationsModalContext.Provider>
  );
};

export const useEditLocationsModalContext = () => {
  const context = useContext(EditLocationsModalContext);
  if (context === undefined) {
    throw new Error(
      'the useEditLocationsModalContext should be used within EditLocationsModalProvider!'
    );
  }

  return context;
};

export default EditLocationsModalProvider;
