import { RcFile } from 'antd/lib/upload';
import Papa from 'papaparse';

import { highlightedHeaders } from './highlightedHeaders';

import { CreateDonationSchemaType } from '../../schema';

export const getDonationsFromCsv = (
  file: RcFile
): Promise<CreateDonationSchemaType[]> => {
  const promise = new Promise<CreateDonationSchemaType[]>(
    (resolve, _reject) => {
      Papa.parse<CreateDonationSchemaType>(file, {
        skipEmptyLines: true,
        header: true,
        quoteChar: `\"`,
        transformHeader: (header) => {
          //TODO: add validation for headers
          return headersMap[header];
        },
        transform: (value, field: keyof CreateDonationSchemaType) => {
          const parser = parserByField[field];

          if (!value) {
            return undefined;
          }

          return parser ? parser(value) : value;
        },
        complete: (results) => {
          resolve(results.data);
        },
      });
    }
  );
  return promise;
};

const parserByField: Partial<
  Record<keyof CreateDonationSchemaType, (value: string) => any>
> = {
  weatherTypes: (value) => value.split('|'),
  holdersPosition: (value) => value.split('|'),
  emotionalSupport: (value) => value.split('|'),
  dislikes: (value) => value.split('|'),
  likes: (value) => value.split('|'),
  treatPreferences: (value) => value.split('|'),
};

const headersMap = highlightedHeaders.reduce((headers, { label, key }) => {
  return { ...headers, [label]: key };
}, {} as Record<string, string>);
