import { Col } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import { BadgeAsterisk, FormItem, Text } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { PreviousDonationValue } from 'modules/donations/components';
import styles from 'modules/donations/components/CreateDonationForm/styles.module.scss';
import { UseFormReturn } from 'react-hook-form';
import { DonationOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { IExaminationTableData } from './interfaces';
import { examinationSchema } from './schema';

const t = getTranslation('donation.CreateDonationForm.Examination');

export const getColumns = (
  formContext: UseFormReturn,
  fieldNamePrefix: string,
  showPreviousDonation: boolean,
  sectionNamePrefix?: string
): ColumnsType<IExaminationTableData> & { hidden?: boolean } => {
  const isRequired = getIsRequiredInSchema(examinationSchema);

  const columns = [
    {
      title: t('characteristic'),
      align: 'center',
      render: (row: IExaminationTableData) => (
        <Col>
          <Text>{t(row.characteristic)}</Text>
        </Col>
      ),
    },
    {
      title: t('previousDonation'),
      align: 'center',
      render: (row: IExaminationTableData) => {
        const value = row.previousDonation
          ? row.previousDonation[row.characteristic as keyof DonationOutput]
          : '';
        return <PreviousDonationValue value={value} />;
      },
      hidden: showPreviousDonation,
    },
    {
      title: t('value'),
      align: 'center',
      render: (row: IExaminationTableData) => {
        const { name, Component } = row.value;
        const sectionName = sectionNamePrefix ? sectionNamePrefix + '.' : '';
        const fieldName = `${sectionName}${fieldNamePrefix}.${name}`;
        const sectionErrors = sectionNamePrefix
          ? formContext.formState.errors[sectionNamePrefix]
          : formContext.formState.errors;
        const errorMessage = sectionErrors?.[fieldNamePrefix]?.[name]?.message;

        return (
          <Col key={name}>
            <FormItem
              className={styles.formItem}
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
            >
              <BadgeAsterisk hidden={!isRequired(name)}>
                <Component control={formContext.control} name={fieldName} />
              </BadgeAsterisk>
            </FormItem>
          </Col>
        );
      },
    },
  ];
  return columns.filter((c) => !c.hidden) as ColumnsType<IExaminationTableData>;
};
