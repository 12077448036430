import { Col, Divider, Form, Row } from 'antd';
import {
  Button,
  ControlledDatePicker,
  FormItem,
} from 'modules/common/components';
import {
  DonationInfoSection,
  DonorSelect,
  EnvironmentSection,
  ExaminationSection,
  QuantitySection,
} from 'modules/donations/components';
import styles from 'modules/donations/components/EditDonationForm/styles.module.scss';
import { DonorAvatar } from 'modules/donor/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum } from 'types.d';

import { DeleteDonationButton } from '../DeleteDonationButton';

const { UpdateDonationData, ViewDonorsPictures } = PermissionsEnum;

type Props = {
  formEnabled: boolean;
  isFormValid: boolean;
  closeHandler: () => void;
  saveHandler: () => void;
  editHandler: () => void;
  pictureUrl: string;
  donationId: string;
};

const DesktopEditDonationFormView: FC<Props> = ({
  formEnabled,
  closeHandler,
  saveHandler,
  editHandler,
  pictureUrl,
  donationId,
  isFormValid,
}) => {
  const { t } = useTranslation('donation.EditDonationForm');
  const currentUser = useCurrentUser();
  const canUpdateDonation = currentUser.isCan(UpdateDonationData);
  const canViewDonorsPictures = currentUser.isCan(ViewDonorsPictures);
  const formContext = useFormContext();

  return (
    <Form>
      <Row gutter={16}>
        {donationId && (
          <Col>
            <DeleteDonationButton donationId={donationId} />
          </Col>
        )}
        <Divider plain>{t('examination')}</Divider>

        <Col span={6}>
          <Row gutter={[0, 15]}>
            {canViewDonorsPictures && (
              <Col span={24}>
                <DonorAvatar src={pictureUrl} />
              </Col>
            )}
            <Col span={24}>
              <Row>
                <Col span={24}>{t('donorId')}:</Col>
                <Col span={24}>
                  <DonorSelect
                    disabled={!formEnabled}
                    className={styles.donorSelect}
                    controlled={{
                      control: formContext.control,
                      name: 'donorId',
                    }}
                    placeholder={t('enterDonorName')}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <FormItem
                required
                label={t('donationDate')}
                validateStatus={
                  formContext.formState.errors.donationDate?.message && 'error'
                }
                extra={formContext.formState.errors.donationDate?.message}
              >
                <ControlledDatePicker
                  placeholder={t('pickDonationDate')}
                  disabled={!formEnabled}
                  controlled={{
                    control: formContext.control,
                    name: 'donationDate',
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={18}>
          <ExaminationSection
            enabled={formEnabled}
            fieldNamePrefix="examination"
          />
        </Col>
      </Row>
      <Divider plain>{t('donationInfo')}</Divider>
      <Row gutter={16}>
        <Col span={6}>
          <EnvironmentSection
            enabled={formEnabled}
            fieldNamePrefix="environment"
          />
        </Col>
        <Col span={12}>
          <DonationInfoSection
            enabled={formEnabled}
            fieldNamePrefix="donationInfo"
          />
        </Col>
        <Col span={6}>
          <QuantitySection enabled={formEnabled} fieldNamePrefix="quantity" />
        </Col>
      </Row>
      {canUpdateDonation && (
        <Row justify="space-between">
          <Col>
            <Button disabled={!formEnabled} onClick={closeHandler}>
              {t('close')}
            </Button>
          </Col>
          <Col>
            {formEnabled && (
              <Button
                type="primary"
                disabled={!isFormValid || !formContext.formState.isDirty}
                onClick={saveHandler}
              >
                {t('save')}
              </Button>
            )}

            {!formEnabled && (
              <Button type="primary" onClick={editHandler}>
                {t('edit')}
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default DesktopEditDonationFormView;
