import { Tabs, TabsProps } from 'antd';
import { FC } from 'react';

import { ITabsItem } from './interfaces';
import styles from './styles.module.scss';

type PropTypes = {
  items: ITabsItem[];
} & TabsProps;

const { TabPane } = Tabs;

const CardTabs: FC<PropTypes> = ({ items, className, ...restProps }) => {
  const defaultProps: TabsProps = {
    type: 'card',
    className: `${className}`,
  };
  return (
    <Tabs {...defaultProps} {...restProps} className={styles.tabsContainer}>
      {items.map((item) => (
        <TabPane tab={item.title} key={item.key}>
          {item.content}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default CardTabs;
export type { ITabsItem };
