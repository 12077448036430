import { ControlledInput } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import UserSelect from 'modules/user/components/UserSelect';
import { OwnerType, UserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

enum CreateGroupFields {
  title = 'title',
  teamTitle = 'teamTitle',
  userId = 'userId',
}

const t = getTranslation('donor.CreateGroupModal');

const createGroupFormFields: FormFieldWithComponent<CreateGroupFields>[] = [
  {
    name: CreateGroupFields.title,
    label: t('nameOfGroup'),
    Component: ({ control }) => (
      <ControlledInput
        controlled={{ control, name: CreateGroupFields.title }}
        placeholder={t('enterGroupName')}
      />
    ),
  },
  {
    name: CreateGroupFields.teamTitle,
    label: t('nameOfTeam'),
    Component: ({ control }) => (
      <ControlledInput
        controlled={{ control, name: CreateGroupFields.teamTitle }}
        placeholder={t('enterTeamName')}
      />
    ),
  },
  {
    name: CreateGroupFields.userId,
    label: t('owner'),
    Component: ({ control }) => (
      <UserSelect
        userRoles={[UserRole.Owner]}
        ownerTypes={[OwnerType.Individual]}
        controlled={{ control, name: CreateGroupFields.userId }}
        placeholder={t('enterOwnerName')}
      />
    ),
  },
];

export { createGroupFormFields, CreateGroupFields };
