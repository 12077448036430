import { Col, Row } from 'antd';
import { RadioButtonInBox } from 'modules/donor/components/RoutineTest/components/RadioButtonInBox';
import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  title: string;
  subTitle: string;
  caption: string;
};

export const BundleTab: FC<Props> = ({ title, subTitle, caption }) => {
  return (
    <Col className={styles.tab}>
      <Row align="middle">
        <Col className={styles.titleWrapper}>
          <RadioButtonInBox
            boxHeight={15}
            boxWidth={15}
            circleHeight={13}
            circleWidth={13}
          />
        </Col>
        <Col className={styles.title}>{title}</Col>
      </Row>
      <Col className={styles.subTitle}>{subTitle}</Col>
      <Row align="middle">
        <RadioButtonInBox
          boxHeight={11}
          boxWidth={11}
          circleHeight={9}
          circleWidth={9}
        />
        <span className={styles.caption}>{caption}</span>
      </Row>
    </Col>
  );
};
