import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type PropTypes = {
  status?: 'error' | 'success';
};

const ValidatingStatus: FC<PropTypes> = ({ status }) => {
  const { t } = useTranslation(
    'donation.UploadDonationsModal.ValidatingStatus'
  );

  const messageByStatus = {
    error: t('dataIsNotValid'),
    success: t('dataIsValid'),
  };

  const statusIcon = status ? iconByStatus[status] : null;
  const statusMessage = status ? messageByStatus[status] : null;

  return (
    <Col span={24}>
      <Row gutter={[10, 0]} justify="center">
        <Col>{statusIcon}</Col>
        <Col span={24} />
        <Col>
          <h4>{statusMessage}</h4>
        </Col>
      </Row>
    </Col>
  );
};

const iconByStatus = {
  error: <CloseCircleOutlined className={styles.errorIcon} />,
  success: <CheckCircleOutlined className={styles.successIcon} />,
};

export default ValidatingStatus;
