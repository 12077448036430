import * as yup from 'yup';

import { AdditionalFormSectionField } from './fields';

const { FirstCertificatePrinted, SecondCertificatePrinted } =
  AdditionalFormSectionField;

type AdditionalFormSectionSchemaType = {
  firstCertificatePrinted?: boolean;
  secondCertificatePrinted?: boolean;
};

const additionalFormSectionSchema: yup.SchemaOf<AdditionalFormSectionSchemaType> =
  yup.object({
    [FirstCertificatePrinted]: yup.boolean(),
    [SecondCertificatePrinted]: yup.boolean(),
  });

export { additionalFormSectionSchema };
export type { AdditionalFormSectionSchemaType };
