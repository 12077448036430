import { Col, Row } from 'antd';
import InviteForm from 'modules/admin/components/InviteForm';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const InvitePage: FC = () => {
  const { t } = useTranslation('admin.InvitePage');

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Row>
        <Col flex="0 1 440px">
          <InviteForm />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default InvitePage;
