export enum TableColumns {
  donationId = 'id',
  donorName = 'DonorName',
  ownerLastName = 'OwnerLastName',
  bloodTypes = 'bloodTypes',
  outcome = 'outcome',
  locations = 'locations',
  donationStatus = 'donationStatus',
  productStatus = 'productStatus',
  dateOfLastDonation = 'LastDonationDate',
}
