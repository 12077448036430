import CreateAppointmentsModal from 'modules/appointments/components/CreateAppointmentsModal';
import { ComponentProps, useState } from 'react';

import { ContextType } from './type';

type ModalProps = Omit<ComponentProps<typeof CreateAppointmentsModal>, 'hide'>;

export const useProviderValue = (): ContextType => {
  const [modalProps, setModalProps] = useState<ModalProps>();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const updateModalProps = (props: ModalProps) => {
    setModalProps({ ...modalProps, ...props });
  };

  const hideModal = () => {
    setVisible(false);
  };

  return {
    modalConfig: {
      ...modalProps,
      visible,
      hide: hideModal,
    },
    showModal,
    hideModal,
    updateModalProps,
  };
};
