import { CheckboxOptionType, RadioGroupProps } from 'antd';
import { ControlledRadioGroup } from 'modules/common/components';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { AnimalGender } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & RadioGroupProps;

const t = getTranslation('donor.DonorGenderRadioGroup');

const constantOptions: CheckboxOptionType[] = [
  {
    value: AnimalGender.Male,
    label: t('male'),
  },
  {
    value: AnimalGender.Female,
    label: t('female'),
  },
];

const DonorGenderRadioGroup: FC<PropTypes> = ({ controlled, ...restProps }) => {
  return (
    <ControlledRadioGroup
      options={constantOptions}
      controlled={controlled}
      {...restProps}
    />
  );
};

export default DonorGenderRadioGroup;
