import {
  CapillaryRefillTime,
  CollectionSet,
  DonationUnit,
  JugularVein,
  Mm,
  WeatherType,
  CollectionUnitsUsed,
  VeinUsed,
  VascularStructure,
  FlowRate,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donation.Schemas');

export const mMSchema = yup
  .mixed<Mm>()
  .oneOf(Object.values(Mm))
  .typeError(t('invalid', { field: t('mucousMembrane') }))
  .required(t('required', { field: t('mucousMembrane') }));

export const capillaryRefillTimeSchema = yup
  .mixed<CapillaryRefillTime>()
  .oneOf(Object.values(CapillaryRefillTime))
  .typeError(t('invalid', { field: t('capillaryRefillTime') }))
  .required(t('required', { field: t('capillaryRefillTime') }));

export const jugularVeinSchema = yup
  .mixed<JugularVein>()
  .oneOf(Object.values(JugularVein))
  .typeError(t('invalid', { field: t('jugularVein') }))
  .required(t('required', { field: t('jugularVein') }));

export const collectionSetSchema = yup
  .mixed<CollectionSet>()
  .oneOf(Object.values(CollectionSet))
  .typeError(t('invalid', { field: t('collectionSet') }))
  .required(t('required', { field: t('collectionSet') }));

//DISABLED CODE, the Position must be in types.d
// export const positionSchema = yup
//   .mixed<Position>()
//   .oneOf(Object.values(Position))
//   .typeError(t('invalid', { field: t('position') }))
//   .required(t('required', { field: t('position') }));

export const donationUnitSchema = yup
  .mixed<DonationUnit>()
  .oneOf(Object.values(DonationUnit))
  .required(t('required', { field: t('donationUnit') }));

export const actualSchema = yup
  .number()
  .required(t('required', { field: t('actual') }))
  .min(0, t('enterValidValues'))
  .max(1000, t('invalid', { field: t('actual') }))
  .typeError(t('enterValidValues'));

export const weatherTypesSchema = yup
  .array(yup.mixed().oneOf<WeatherType>(Object.values(WeatherType)).required())
  .min(1, t('required', { field: t('weatherTypes') }))
  .ensure()
  .test(
    t('weatherTypes'),
    t('invalid', { field: t('weatherTypes') }),
    (array) => (array ? array.length > 0 : false)
  )
  .required();

export const collectionUnitsUsedSchema = yup
  .mixed<CollectionUnitsUsed>()
  .oneOf(Object.values(CollectionUnitsUsed))
  .required(t('required', { field: t('usedCollectionUnits') }));

export const veinUsedSchema = yup
  .mixed<VeinUsed>()
  .oneOf(Object.values(VeinUsed))
  .required(t('required', { field: t('veinUsed') }));

export const vascularStructureSchema = yup
  .mixed<VascularStructure>()
  .oneOf(Object.values(VascularStructure));

export const flowRateSchema = yup
  .mixed<FlowRate>()
  .oneOf(Object.values(FlowRate))
  .required(t('required', { field: t('flowRate') }));

export const donationDateSchema = yup
  .date()
  .nullable()
  .required(t('donationDateRequired'));
