import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useLabworkAttachmentsModalContext } from 'modules/donor/contexts/LabworkAttachmentsModal';
import { usePrintLabworkModalContext } from 'modules/donor/contexts/PrintLabworkFormModal';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabworkOutput, PermissionsEnum } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  labwork: LabworkOutput;
  removeHandler: () => void;
};

const ActionsCell: FC<PropTypes> = ({ labwork, removeHandler }) => {
  const { t } = useTranslation('donor.LabworkTable.ActionsCell');
  const printLabworkModal = usePrintLabworkModalContext();
  const labworkAttachmentsModal = useLabworkAttachmentsModalContext();
  const currentUser = useCurrentUser();
  const canViewLabworkResults = currentUser.isCan(
    PermissionsEnum.ViewLabworkResults
  );
  const canDeleteLabworkData = currentUser.isCan(
    PermissionsEnum.DeleteLabworkData
  );
  const defaultMenuOptions: ItemType[] = [
    {
      label: t('attachments'),
      key: 'attachments',
      disabled: !canViewLabworkResults,
      onClick: () => {
        labworkAttachmentsModal?.showModal({ labworkId: labwork.id });
      },
    },
    {
      label: t('printLabwork'),
      key: 'print-labwork',
      onClick: () => printLabworkModal?.showModal({ labwork }),
    },
  ];

  const removeMenuOption = canDeleteLabworkData
    ? {
        label: t('deleteLabwork'),
        key: 'delete',
        onClick: removeHandler,
      }
    : undefined;

  const menuOptions = [...defaultMenuOptions, removeMenuOption] as ItemType[];

  return (
    <Dropdown trigger={['click']} overlay={<Menu items={menuOptions} />}>
      <MoreOutlined className={styles.moreIcon} />
    </Dropdown>
  );
};

export default ActionsCell;
