import {
  collectionSetSchema,
  jugularVeinSchema,
} from 'modules/donations/schemas';
import { CollectionSet, JugularVein } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donation.CreateDonationForm.DonationInfo');

type DonationInfoSchemaType = {
  shouldDonate: boolean;
  maxLimit: number;
  collectionSet: CollectionSet;
  jugularVein: JugularVein;
  //Removed position from here
  preferences?: string;
};

const donationInfoSchema: yup.SchemaOf<DonationInfoSchemaType> = yup.object({
  shouldDonate: yup
    .boolean()
    .typeError(t('invalid', { field: t('shouldDonateField') }))
    .required(t('required', { field: t('shouldDonateField') })),
  maxLimit: yup
    .number()
    .min(0, t('invalid', { field: t('maxLimitField') }))
    .max(2000, t('invalid', { field: t('maxLimitField') }))
    .typeError(t('invalid', { field: t('maxLimitField') }))
    .required(t('required', { field: t('maxLimitField') })),
  collectionSet: collectionSetSchema,
  jugularVein: jugularVeinSchema,
  preferences: yup.string().max(255, t('lessThan', { max: 255 })),
});

export { donationInfoSchema };
export type { DonationInfoSchemaType };
