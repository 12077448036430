import config from 'config';
import { FormItem } from 'modules/common/components';
import ControlledTeamSelect from 'modules/donor/components/ControlledTeamSelect';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { TeamOutput, useFindAllGroupQuery } from 'types.d';

type PropTypes = {
  control: Control<any, any>;
  userIds?: string[];
};

const OwnerTeamSelect: FC<PropTypes> = ({ control, userIds }) => {
  const fieldError = control.getFieldState('teamId').error;
  const errorMessage = fieldError?.message;
  const findGroupQuery = useFindAllGroupQuery({
    variables: {
      input: {
        take: config.DEFAULT_LIMIT,
        filter: userIds?.length
          ? {
              userIds,
            }
          : undefined,
      },
    },
  });
  const teams = findGroupQuery.data?.findAllGroup.data[0]?.teams as
    | TeamOutput[]
    | undefined;

  return (
    <FormItem validateStatus={errorMessage && 'error'} extra={errorMessage}>
      <ControlledTeamSelect
        allowClear
        teams={teams}
        controlled={{
          control,
          name: 'teamId',
        }}
      />
    </FormItem>
  );
};

export default OwnerTeamSelect;
