import { envFileValidationSchema, EnvFile } from './schema';

class EnvServices {
  private readonly envFile: EnvFile;

  constructor() {
    const validationSchemaKeys = Object.keys(
      envFileValidationSchema.fields
    ) as (keyof EnvFile)[];
    this.envFile = validationSchemaKeys.reduce((fields, fieldKey) => {
      const fieldType = envFileValidationSchema.fields[fieldKey].type;
      const envField = process.env[fieldKey];
      const parsedValue = this.parseValue(fieldType, envField);
      return { ...fields, [fieldKey]: parsedValue };
    }, {} as EnvFile);
  }

  validate() {
    envFileValidationSchema.validateSync(this.envFile);
  }

  get<EnvField extends keyof EnvFile>(envField: EnvField): EnvFile[EnvField] {
    return this.envFile[envField] as EnvFile[EnvField];
  }

  private parseValue(toType: string, value?: string): any {
    const parseEnvValue = envValueParsers[toType];
    if (!value || !parseEnvValue) {
      return value;
    }
    return parseEnvValue(value);
  }
}

const envValueParsers: Record<string, (value: string) => any> = {
  boolean: (value: string) => {
    if (value === 'true' || value === 'false') {
      return JSON.parse(value);
    }
    return value;
  },
};

export const envServices = new EnvServices();
