import {
  endOfDay,
  lastDayOfWeek,
  startOfDay,
  startOfWeek,
  addDays,
} from 'date-fns';

import { TaskListMode } from './enum';

const { Daily, Weekly } = TaskListMode;

type ParamTypes = {
  selectedDay: Date;
  minDate?: Date;
};

export const getDateIntervalsByMode = ({
  selectedDay,
  minDate,
}: ParamTypes): Record<TaskListMode, Interval> => {
  return {
    [Daily]: {
      start: startOfDay(minDate || selectedDay),
      end: endOfDay(selectedDay),
    },
    [Weekly]: {
      start: minDate || startOfWeek(selectedDay, { weekStartsOn: 1 }),
      end: addDays(lastDayOfWeek(selectedDay, { weekStartsOn: 1 }), 1),
    },
  };
};
