import { Table, message, TableProps, Row, Col, Spin, Grid } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { FilterValue } from 'antd/lib/table/interface';
import config from 'config';
import { SearchInput } from 'modules/common/components';
import {
  useSort,
  useDebounce,
  useModal,
  useFilter,
} from 'modules/common/hooks';
import AddDonorNoteModal from 'modules/donor/components/AddDonorNoteModal';
import { TableColumns } from 'modules/donor/components/ReserveDonorsTable/enums';
import { AddDonorNoteModalProps } from 'modules/donor/types';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DonorListFilterInput,
  DonorListSort,
  DonorOutput,
  useGetDonorsListQuery,
  DonorStatus,
  BloodType,
  Order,
  useDeleteReserveDonorNoteMutation,
} from 'types.d';

import { getColumns } from './columns';

const { useBreakpoint } = Grid;

const ReserveDonorsTable: FC = () => {
  const pagination = usePagination();
  const { debounce, clearDebounce } = useDebounce();
  const donorListSort = useSort<DonorListSort>();
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const reserveDonorsFilters = useFilter<DonorListFilterInput>();
  const addDonorNoteModal = useModal<AddDonorNoteModalProps>();
  const { t } = useTranslation('donor.ReserveDonorsTable');
  const setSearchInput = useRef(false);
  const getDonorsListQuery = useGetDonorsListQuery({
    variables: {
      input: {
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        sort: donorListSort.options,
        filter: {
          ...reserveDonorsFilters.getFilterObject(),
          statuses: [DonorStatus.Trp],
        },
      },
    },
    onCompleted: (result) => {
      pagination.setTotal(result.getDonorsList.meta.total);
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const [deleteDonorNoteMutation] = useDeleteReserveDonorNoteMutation();
  const donorList =
    (getDonorsListQuery.data?.getDonorsList.data as DonorOutput[]) || [];

  const toFilter = (filters: Record<string, FilterValue | null>) => {
    const bloodType = filters[TableColumns.bloodType] as
      | BloodType[]
      | undefined;

    return { bloodType };
  };

  const onTableChange: TableProps<DonorOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<DonorOutput>;
      const fieldName = tableSorter.columnKey as DonorListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        donorListSort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        donorListSort.removeSortOption(fieldName);
      }
    }

    if (extra.action === 'filter') {
      const newFilterData = toFilter(filters);
      Object.keys(newFilterData).forEach((fieldName) => {
        reserveDonorsFilters?.setFilterOption({
          fieldName: fieldName as keyof DonorListFilterInput,
          value: (newFilterData as any)[fieldName],
        });
      });
    }
  };

  const addNewNoteHandler = (donorId: string) => {
    addDonorNoteModal.show({
      noteId: undefined,
      donorId,
      previousValue: undefined,
    });
  };

  const updateNoteHandler = (noteId: string, previousValue: string) => {
    addDonorNoteModal.show({
      noteId,
      previousValue,
    });
  };

  const deleteNoteHandler = async (noteId: string) => {
    await deleteDonorNoteMutation({
      variables: {
        input: {
          id: noteId,
        },
      },
      refetchQueries: ['getDonorsList'],
      onCompleted: () => {
        message.success(t('noteWasDeleted'));
      },
      onError: () => {
        message.error(t('noteWasntDeleted'));
      },
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    pagination.moveTo(1);
    debounce(() => {
      reserveDonorsFilters.setFilterOption({
        fieldName: 'searchingQuery',
        value: searchValue,
      });
    })(searchValue);
  };

  const { md } = useBreakpoint();
  const columns = getColumns({
    addNewNoteHandler,
    updateNoteHandler,
    deleteNoteHandler,
    showSorterTooltip: md,
    sort: donorListSort?.options[0],
    filter: reserveDonorsFilters?.getFilterObject(),
  });

  useEffect(() => {
    return clearDebounce;
  }, []);

  useEffect(() => {
    if (
      !setSearchInput.current &&
      reserveDonorsFilters?.getFilterObject().searchingQuery
    ) {
      setSearchInputValue(
        reserveDonorsFilters?.getFilterObject().searchingQuery || ''
      );
      setSearchInput.current = true;
    }
  }, [reserveDonorsFilters]);

  return (
    <Spin spinning={getDonorsListQuery.loading}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <SearchInput
            onChange={handleSearch}
            value={searchInputValue}
            placeholder={t('searchDonor')}
            maxLength={100}
          />
        </Col>
        <Col span={24}>
          <Table
            rowKey={({ id }) => id}
            columns={columns}
            dataSource={donorList}
            scroll={{ x: 750 }}
            onChange={onTableChange}
            pagination={{ ...pagination.config }}
          />
        </Col>
      </Row>
      <AddDonorNoteModal {...addDonorNoteModal.config} />
    </Spin>
  );
};

export default ReserveDonorsTable;
