import { Typography } from 'antd';
import { FC } from 'react';
import { envServices } from 'utils/EnvServices';

import styles from './styles.module.scss';

type PropTypes = {
  trackingCode: string;
};

const UpsTrackingLink: FC<PropTypes> = ({ trackingCode }) => {
  const linkParams = new URLSearchParams({
    loc: 'null',
    tracknum: trackingCode,
    requester: 'MB/trackdetails',
  });

  return (
    <Typography.Link
      target="_blank"
      title={trackingCode}
      className={styles.trackingLink}
      href={`${envServices.get('REACT_APP_UPS_URI')}/track?${linkParams}`}
    >
      {trackingCode}
    </Typography.Link>
  );
};

export default UpsTrackingLink;
