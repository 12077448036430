import { Row, Col } from 'antd';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import Request from 'modules/requests/components/Request';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const RequestPage: FC = () => {
  const { t } = useTranslation('requests.RequestPage');

  return (
    <ProfileSideMenuWrapper title={t('request')}>
      <Row>
        <Col span={24}>
          <Request />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default RequestPage;
