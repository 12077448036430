import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button, Row, Col, message, Alert } from 'antd';
import { FormItem, Spin } from 'modules/common/components';
import { useHidingFields } from 'modules/common/hooks';
import { convertPoundsToKilograms } from 'modules/donor/utils/convertWeight';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateDonorMutation } from 'types.d';

import { defaultFormValues } from './defaultFormValues';
import { getFields, DonorApplicationField } from './fields';
import { DonorApplicationSchemaType, donorApplicationSchema } from './schema';

const DonorApplicationForm: FC = () => {
  const { t } = useTranslation('donor.DonorApplicationForm');
  const { hiddenFields, showField, hideField } =
    useHidingFields<DonorApplicationField>({
      hiddenFields: [DonorApplicationField.MedicationsCourseComment],
    });
  const {
    control,
    handleSubmit,
    formState,
    reset,
    watch,
    trigger,
    resetField,
  } = useForm<DonorApplicationSchemaType>({
    resolver: yupResolver(donorApplicationSchema),
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });
  const donorSpecie = watch('species');
  const region = watch('region');

  const donorApplicationFormFields = useMemo(
    () => getFields({ donorSpecie, region }),
    [donorSpecie, region]
  );
  const doesDonorHaveTransfusion = watch('hasTransfusion');
  const registerPetAllowed = !doesDonorHaveTransfusion && formState.isValid;
  const [createDonor, createDonorMutation] = useCreateDonorMutation({
    update(cache) {
      cache.evict({ fieldName: 'getCurrentUserDonorsList' });
    },
    onCompleted: () => {
      message.success(t('donorHasBeenAdded'));
      reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const submitForm = handleSubmit(({ region, ...formData }) => {
    createDonor({
      variables: {
        input: {
          ...formData,
          weight: convertPoundsToKilograms(formData.weight),
        },
      },
    });
  });

  const donorTakesMedications = watch(
    DonorApplicationField.TakeMedications
  ) as boolean;

  useEffect(() => {
    donorTakesMedications
      ? showField(DonorApplicationField.MedicationsCourseComment)
      : hideField(DonorApplicationField.MedicationsCourseComment);
  }, [donorTakesMedications]);

  const specie = watch('species');
  const weight = watch('weight');

  useEffect(() => {
    if (weight) {
      trigger('weight');
    }
  }, [specie, weight]);

  useEffect(() => {
    resetField('location');
  }, [region]);

  return (
    <Spin spinning={createDonorMutation.loading}>
      <Form>
        <Row gutter={[0, 8]}>
          {doesDonorHaveTransfusion && (
            <Col span={24}>
              <Alert
                message={t('donorWithTransfusionNotAllowed')}
                type="warning"
              />
            </Col>
          )}
          {donorApplicationFormFields.map(
            ({ Component, label, name, tooltip }, i) => {
              const isFieldDisplayed = !hiddenFields.includes(name);
              if (!isFieldDisplayed) {
                return null;
              }
              return (
                <Col key={`${name}_${i}`} span={24}>
                  <FormItem
                    key={name}
                    label={label}
                    tooltip={tooltip}
                    extra={formState.errors[name]?.message}
                    validateStatus={formState.errors[name]?.message && 'error'}
                  >
                    <Component control={control} name={name} />
                  </FormItem>
                </Col>
              );
            }
          )}
          <Col span={24}>
            <Button
              disabled={!registerPetAllowed}
              onClick={submitForm}
              type="primary"
            >
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DonorApplicationForm;
