import { rejectionReasonSchema } from 'modules/requests/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('requests.RejectionReasonModal');

export type RejectionReasonFormType = {
  reason: string;
};

export const rejectionReasonFromSchema: yup.SchemaOf<RejectionReasonFormType> =
  yup.object({
    reason: rejectionReasonSchema.required(t('reasonIsRequired')),
  });
