import { gql } from '@apollo/client';
import { DONOR_FRAGMENT } from 'modules/donor/graphql/fragments';
import { OWNER_FRAGMENT } from 'modules/owner/graphql/fragments';
import { CONTACT_OUTPUT_FRAGMENT } from 'modules/user/graphql/fragments';

export const GET_REQUEST_LIST = gql`
  query getRequestList($input: RequestsListInput!) {
    getRequestList(input: $input) {
      data {
        donor {
          ...Donor
          team {
            id
            title
          }
          user {
            ...Owner
            contacts {
              ...Contact
            }
          }
        }
        id
        number
        updatedAt
      }
      meta {
        total
      }
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

export const GET_REQUEST = gql`
  query getRequest($input: GetRequestInput!) {
    getRequest(input: $input) {
      donor {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
      }
      id
      number
      updatedAt
    }
  }
  ${DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${OWNER_FRAGMENT}
`;
