import { Button, Popconfirm, message } from 'antd';
import { Routes } from 'config/routes';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserRole, useDeleteDonationMutation } from 'types.d';

type PropTypes = {
  donationId: string;
};

export const DeleteDonationButton: FC<PropTypes> = ({ donationId }) => {
  const { t } = useTranslation('donation.DeleteDonationButton');
  const [deleteDonation, { loading }] = useDeleteDonationMutation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.data?.role.value === UserRole.Admin;

  const handleDeleteClick = () => {
    deleteDonation({
      variables: {
        donationId,
      },
      onCompleted() {
        message.success(t('donationDeleted'));
        navigate(Routes.CollectionDonations);
      },
      onError() {
        message.error(t('deleteDonationError'));
      },
    });
  };

  if (!donationId || !isAdmin) {
    return null;
  }

  return (
    <Popconfirm
      title={t('deleteDonationConfirmText')}
      onConfirm={handleDeleteClick}
      okText={t('yes')}
      cancelText={t('no')}
    >
      <Button danger loading={loading}>
        {t('deleteDonation')}
      </Button>
    </Popconfirm>
  );
};
