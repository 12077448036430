import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Select } from 'modules/common/components';
import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TreatPreferences } from './enum';
import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & SelectProps;

export const TreatmentPreferencesSelect: FC<PropTypes> = ({
  controlled,
  ...restProps
}) => {
  const { t } = useTranslation('donation.TreatmentPreferencesSelect');
  const { field } = useController(controlled);

  const classNames = `${styles.select} ${restProps.className || ''}`;
  const options: DefaultOptionType[] = Object.values(
    TreatPreferences
  ).map<DefaultOptionType>((treatmentPreferences) => {
    return {
      label: t(
        `${treatmentPreferences
          .charAt(0)
          .toLowerCase()}${treatmentPreferences.slice(1)}`
      ),
      value: treatmentPreferences,
    };
  });

  return (
    <Select
      {...restProps}
      mode="tags"
      {...field}
      placeholder={t('selectTreatmentPreferences')}
      options={options}
      className={classNames}
    />
  );
};
