import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donor.UpdateVitalsParametersModal');

type UpdateVitalsParametersSchemaType = {
  temperatureToCheckMax: number;
  temperatureToCheckMin: number;
  temperatureToStopMax: number;
  temperatureToStopMin: number;
  pulseMax: number;
  pulseMin: number;
  respirationMin: number;
  respirationCriticalValues: string[];
  packedCellVolumeMax: number;
  packedCellVolumeMin: number;
  totalSolidsMin: number;
  totalSolidsMax: number;
  mucousMembraneCriticalValues: string[];
  capillaryRefillTimeMax: number;
  capillaryRefillTimeMin: number;
  weightPercentageDifference: number;
  weightMin: number;
};

const numberSchema = yup
  .number()
  .min(0, t('moreThan', { min: 0 }))
  .nullable()
  .required(t('required'));

const updateVitalsParametersSchema: yup.SchemaOf<UpdateVitalsParametersSchemaType> =
  yup.object({
    temperatureToCheckMax: numberSchema,
    temperatureToCheckMin: numberSchema,
    temperatureToStopMax: numberSchema,
    temperatureToStopMin: numberSchema,
    pulseMax: numberSchema,
    pulseMin: numberSchema,
    respirationMin: numberSchema,
    respirationCriticalValues: yup.array().required(),
    packedCellVolumeMax: numberSchema,
    packedCellVolumeMin: numberSchema,
    totalSolidsMin: numberSchema,
    totalSolidsMax: numberSchema,
    mucousMembraneCriticalValues: yup.array().required(),
    capillaryRefillTimeMax: numberSchema,
    capillaryRefillTimeMin: numberSchema,
    weightPercentageDifference: numberSchema,
    weightMin: numberSchema,
  });

export { updateVitalsParametersSchema };
export type { UpdateVitalsParametersSchemaType };
