export default {
  AppointmentsPage: {
    appointmentsPage: 'Appointments',
  },
  AppointmentsList: {
    assignedTo: 'Assigned to',
    appointmentCanceled: 'Appointment has been canceled!',
    appointmentRemoved: 'Appointment has been removed!',
  },
  AppointmentOverlay: {
    location: 'Location',
    donor: 'Donor',
    enroll: 'Enroll',
    cancel: 'Cancel',
    delete: 'Delete',
    edit: 'Edit',
    members: 'Members',
    group: 'Group',
    team: 'Team',
    enrolled: 'Enrolled',
    register: 'Register',
    info: 'Info',
    detail: 'Details',
  },
  MembersSelect: {
    members: 'Members',
  },
  AppointmentsCalendar: {
    Header: {
      create: 'Create',
    },
    ModeTabs: {
      month: 'Month',
      year: 'Year',
    },
  },
  CreateAppointmentsModal: {
    appointmentHasBeenCreated: 'Appointment(s) has been created!',
    typeRequired: 'Type is required',
    titleRequired: 'Title is required',
    rangeRequired: 'Range is required',
    archivedDonor: 'Donor is archived',
    locationRequired: 'Location is required',
    memberRequired: 'Member is required',
    maxMembers: 'Max members is {{max}}',
    dateInPast: 'Date in the past',
    selectAtLeastOneDate: 'Select at least one date',
    groupRequired: 'Group is required',
    maxLengthIs: 'Max length is {{max}}',
    wrongHours: 'Wrong hour value is selected',
    selectFutureDate: 'Please select a time in the future',
    createAppointment: 'Create appointment',
  },
  EditAppointmentsModal: {
    editAppointment: 'Edit appointment',
    save: 'Save',
    close: 'Close',
    saved: 'Appointment has been successfully updated',
    errorWhileSaving: 'Error while trying to update appointment.',
  },
  registerDaycareModal: {
    title: 'Bark and boarding',
    donationDay: 'Donation day',
    chooseDonor: 'Choose donors',
    leaveNotes: 'Leave a comment here',
    notes: 'Notes',
    yesAnswer: 'Yes! My dog(s) will be in attendance and can participate',
    noAnswer: 'No! My dog(s) can not participate this day',
    thanksForAnswer: 'Thanks for your response!',
    donorUnenrolled: 'The donor has been successfully unenrolled. ',
    unenrollError: 'An error occurred while attempting to unenroll the donor.',
    unenroll: 'Unenroll',
    selectAtLeastDonor: 'Select at least 1 donor',
    required: 'Donors field is required',
    noteSize: 'Note must contain less than {{size}} characters',
    cancelRequest: 'Cancel request',
    donorsInRequest: 'Donor(s) waiting for approval: ',
    donorRequestCanceled: 'The request has been successfully canceled.',
    cancelError: 'An error occurred while attempting to cancel the request.',
    donorRegistered:
      'The donor(s) have been successfully registered for the appointment.',
    registerError: 'An error occurred while attempting to register the donor.',
    alreadyRegisteredWithNoAnswer:
      'You have already stated that you will not participate in this appointment.',
    alreadyRegistered: 'You have already registered for this appointment.',
    registeredDonors: 'Registered donor(s): ',
    limitReached:
      'The donor limit for the appointment has been reached. Registered donors will await confirmation and may be declined.',
    helperMessage:
      'If you would like your dog(s) to donate but they will not be attending  daycare this day, please contact NAVBB to schedule in appointment 703-574-7417',
  },
  CreateAppointmentsFormFields: {
    title: 'Title',
    timeSlots: 'Time slots',
    donorName: 'Donor name',
    location: 'Location',
    assignTo: 'Assign to',
    recurring: 'Choose date(s)',
    titleRequired: 'Title required',
    rangeRequired: 'Time range required',
    donorRequired: 'Donor required',
    locationRequired: 'Location required',
    memberRequired: 'Member required',
    archivedDonor: 'Donor is archived',
    maxUsers: 'You can assign appointment maximum for {{max}} users',
    group: 'Group',
    team: 'Team',
    type: 'Type',
    splitTimeSlot: 'Split time slot on 30 min',
    notes: 'Notes',
    leftNotesHere: 'Leave notes here',
    isPrivate: 'Is appointment private?',
  },
  DaycareDetailModal: {
    title: 'Daycare appointment details',
    owner: 'Owner',
    answer: 'Answer',
    yes: 'Yes',
    no: 'No',
    detail: 'Details',
    note: 'Note',
    donors: 'Donors',
    donor: 'Donor',
    processRequest: 'Process request',
    ownerDonors: 'Owner registered donors:',
    requests: 'Requests',
    registered: 'Registered',
    unenroll: 'Unenroll',
    donorUnenrolled: 'The donor has been successfully unenrolled. ',
    unenrollError: 'An error occurred while attempting to unenroll the donor.',
    donorName: 'Donor name',
    donorStatus: 'Status',
    action: 'Actions',
    registeredDonors: 'Registered donors',
    active: 'Active',
    affiliate: 'Affiliate',
    pending: 'Pending',
    retired: 'Retired',
    trp: 'TRP',
    notaccepted: 'Not Accepted',
    archived: 'Archived',
    requested: 'Requested',
    close: 'Close',
    save: 'Save',
    addDonors: 'Add donors to appointment',
    donorAdded: 'The donors have been successfully added.',
    donorAddedError: 'Error occurred while adding donors.',
  },
  EnrollDonorModal: {
    enrollDonor: 'Enroll donor',
    title: 'Title',
    date: 'Date',
    time: 'Time',
    appointmentWith: 'Appointment with',
    location: 'Location',
    hasBeenEnrolled: 'Donor has been enrolled!',
    selectDonor: 'Select or search donor',
    pleaseSelectDonor: 'Please select donor',
    donor: 'Donor',
    archivedDonor: 'Donor is archived',
  },
  TaskSchedulePage: {
    taskSchedule: 'Task schedule',
  },
  TaskSchedule: {
    Header: {
      weekly: 'Weekly',
      daily: 'Daily',
    },
  },
  AppointmentTitleSelect: {
    newDonorAppointment: 'New donor appointment',
    donation: 'Donation',
    screening: 'Screening',
    recheck: 'Recheck',
    selectOrEnterTitle: 'Select or enter title',
  },
  AppointmentTypeSelect: {
    individual: 'Individual',
    grouped: 'Grouped',
    daycare: 'Daycare',
    selectType: 'Select type',
  },
  daycareAppointmentRequestTable: {
    requestApproved: 'The request has been successfully confirmed.',
    processRequestError: 'An error occurred during request processing.',
    requestDisproved: 'The request has been declined.',
  },
};
