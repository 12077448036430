import { REFRESH_TOKEN_MUTATION } from 'modules/auth/graphql/mutations';
import clientWithRefreshToken from 'providers/graphql/clientWithRefreshToken';
import { Mutation } from 'types.d';

class AuthServices {
  async getTokens() {
    const response = await clientWithRefreshToken.mutate<
      Pick<Mutation, 'refreshToken'>
    >({
      mutation: REFRESH_TOKEN_MUTATION,
    });

    return response.data?.refreshToken;
  }
}

export const authServices = new AuthServices();
