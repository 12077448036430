import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FC } from 'react';

import styles from './styles.module.scss';

const { RangePicker } = DatePicker;

const RangeDatePicker: FC<RangePickerProps> = (props) => {
  const className = `${styles.rangeDatePicker} ${props.className || ''}`;
  return <RangePicker {...props} className={className} />;
};
export default RangeDatePicker;
