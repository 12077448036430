import { Col, Row } from 'antd';
import { useModal } from 'modules/common/hooks';
import { DonationsTable } from 'modules/donations/components/DonationsTable';
import UploadDonationsModal from 'modules/donations/components/UploadDonationsModal';
import { useCreateProductModalContext } from 'modules/donations/contexts/CreateProductModal';
import { useUploadDonationsModalContext } from 'modules/donations/contexts/UploadDonationsModal';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import CreateProductModal from 'modules/products/components/CreateProductModal';
import LabelsInfoModal from 'modules/products/components/LabelsInfoModal';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductOutput } from 'types.d';
import { envServices } from 'utils/EnvServices';

const DonationsSplashPage: FC = () => {
  const { t } = useTranslation('donation.DonationDataCollectionPage');
  const labelsInfoModal = useModal<{ products?: ProductOutput[] }>();
  const createProductModalContext = useCreateProductModalContext();
  const uploadDonationsModalContext = useUploadDonationsModalContext();
  const isAllowedProductCreation = envServices.get(
    'REACT_APP_PRODUCT_CREATION_ALLOWED'
  );
  const creationProductAllowed =
    isAllowedProductCreation && createProductModalContext;
  useEffect(() => {
    createProductModalContext?.updateModalProps({
      onProductsCreation: (products) => {
        labelsInfoModal.show({
          products,
        });
      },
    });
  }, []);

  return (
    <ProfileSideMenuWrapper title={t('donationsDataCollectionPage')}>
      <Row>
        <Col span={24}>
          <DonationsTable />
        </Col>
      </Row>
      {creationProductAllowed && (
        <CreateProductModal {...createProductModalContext.modalConfig} />
      )}
      {uploadDonationsModalContext && (
        <UploadDonationsModal {...uploadDonationsModalContext.modalConfig} />
      )}
      <LabelsInfoModal products={[]} {...labelsInfoModal.config} />
    </ProfileSideMenuWrapper>
  );
};

export default DonationsSplashPage;
