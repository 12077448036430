import { DefaultOptionType } from 'antd/lib/select';
import { ControlledInput, ControlledTextArea } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import {
  BloodTypeSelect,
  ControlledYesNoRadioGroup,
  DonorTeamSelect,
  DonorTypeSelect,
} from 'modules/donor/components';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.CreateDonorForm.OptionalSection');

export enum OptionalFormSectionField {
  Marks = 'distinguishableMarks',
  BloodType = 'bloodType',
  DonorType = 'donorType',
  Team = 'teamId',
  Breed = 'breed',
  DonationFrequency = 'donationFrequency',
  Comment = 'comment',
  ChipNo = 'microchipNumber',
  Color = 'color',
  hasMicrochip = 'hasMicrochip',
}

type FormFieldType = FormFieldWithComponent<
  OptionalFormSectionField,
  { name: string }
>;

type PropTypes = {
  groups: DefaultOptionType[];
  userId?: string;
  hiddenFields?: OptionalFormSectionField[];
};

export const getFields = ({
  groups,
  userId,
  hiddenFields = [],
}: PropTypes): FormFieldType[] => {
  const formFields: FormFieldType[] = [
    {
      name: OptionalFormSectionField.hasMicrochip,
      label: t('hasMicrochip'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup controlled={{ control, name }} />
      ),
    },
    {
      name: OptionalFormSectionField.ChipNo,
      label: t('microchip'),
      Component: ({ control, name }) => (
        <ControlledInput controlled={{ control, name }} />
      ),
    },
    {
      name: OptionalFormSectionField.Marks,
      label: t('distinguishableMarks'),
      Component: ({ control, name }) => (
        <ControlledTextArea
          controlled={{ control, name }}
          placeholder={t('enterDistinguishableMarks')}
          className={styles.textArea}
        />
      ),
    },
    {
      name: OptionalFormSectionField.BloodType,
      label: t('bloodType'),
      Component: ({ control, name }) => (
        <BloodTypeSelect
          controlled={{ control, name }}
          placeholder={t('selectBloodType')}
        />
      ),
    },
    {
      name: OptionalFormSectionField.DonorType,
      label: t('donorType'),
      Component: ({ control, name }) => (
        <DonorTypeSelect
          controlled={{ control, name }}
          placeholder={t('selectDonorType')}
        />
      ),
    },
    {
      name: OptionalFormSectionField.Team,
      label: t('team'),
      Component: ({ control, name }) => {
        return (
          <DonorTeamSelect
            controlled={{ control, name }}
            placeholder={t('selectTeam')}
            userIds={userId ? [userId] : undefined}
          />
        );
      },
    },
    {
      name: OptionalFormSectionField.Breed,
      label: t('breed'),
      Component: ({ control, name }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('enterBreed')}
        />
      ),
    },
    {
      name: OptionalFormSectionField.Color,
      label: t('color'),
      Component: ({ control, name }) => (
        <ControlledInput
          controlled={{ control, name }}
          placeholder={t('enterColor')}
        />
      ),
    },
  ];
  if (hiddenFields.length) {
    return formFields.filter(({ name }) => !hiddenFields.includes(name));
  }
  return formFields;
};
