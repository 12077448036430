import { Row } from 'antd';
import { DefaultClinicParams } from 'config/default-clinic-params';
import { RoutineTestFormSchemaType } from 'modules/donor/components/RoutineTestForm/schema';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import FormInput from '../../../components/Input';
import Title from '../Title';

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};
export default function AnimalIdentification({ data }: PropTypes) {
  const { t } = useTranslation(
    'donor.FormsToPrint.StateLabSubmissionForm.AnimalIdentification'
  );
  return (
    <div className={styles.animalIdentification}>
      <Row className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>
          <Title position="start">{t('animalIdentificationTitle')}</Title>
          <p>{t('additionalSpaceNote')}</p>
        </div>
        <FormInput label={t('dateCollected')} />
        <FormInput label={t('dateShipped')} />
      </Row>
      <Row>
        <table>
          <thead>
            <tr>
              <td>{t('no')}</td>
              <td>{t('officialAnimalID')}</td>
              <td>{t('species')}</td>
              <td>{t('breed')}</td>
              <td>{t('sex')}</td>
              <td>{t('age')}</td>
              <td>{t('specimenType')}</td>
              <td>{t('testRequested')}</td>
            </tr>
          </thead>
          <tbody>
            {new Array(10).fill(1).map((_, i) => {
              return (
                <tr key={i}>
                  <td>{i + 2}</td>
                  <td>{i === 0 && data.animalName}</td>
                  <td>{i === 0 && data.species}</td>
                  <td>{i === 0 && data.breed}</td>
                  <td>{i === 0 && data.gender}</td>
                  <td>{i === 0 && data.age}</td>
                  <td>{i === 0 && t('serum')}</td>
                  <td>{i === 0 && t('IFA')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Row>
    </div>
  );
}
