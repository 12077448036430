import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { Button, DeletePopconfirm, Spin } from 'modules/common/components';
import { TeamTitleFormSchema } from 'modules/donor/components/TeamsTable/schema';
import { useAttachDonorsModalContext } from 'modules/donor/contexts/AttachDonorsModal';
import { FIND_GROUP_QUERY } from 'modules/donor/graphql/queries';
import { GET_USER_DONOR_LIST_QUERY } from 'modules/owner/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  TeamOutput,
  useFindGroupQuery,
  useUpdateTeamMutation,
  useRemoveTeamMutation,
} from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  team: TeamOutput;
  onToggleEditMode: (value: boolean) => void;
  isEditMode?: boolean;
  form: UseFormReturn<TeamTitleFormSchema>;
  isMobile?: boolean;
};

const TeamActionsButtons: FC<PropTypes> = ({
  team,
  onToggleEditMode,
  isEditMode,
  form,
  isMobile,
}) => {
  const { t } = useTranslation('donor.TeamActionsButtons');
  const { id } = team;
  const params = useParams();
  const groupId = params.id || '';
  const getGroupVariables = {
    variables: {
      input: {
        id: groupId,
      },
    },
  };
  const group = useFindGroupQuery({
    ...getGroupVariables,
  });

  const userId = (group.data && group.data?.findGroup?.user?.id) || '';

  const [updateTeam, { loading: updateTeamLoading }] = useUpdateTeamMutation({
    refetchQueries: [
      {
        query: FIND_GROUP_QUERY,
        ...getGroupVariables,
      },
      {
        query: GET_USER_DONOR_LIST_QUERY,
        variables: {
          input: {
            userId,
          },
        },
      },
    ],
    onError: ({ message: m }) => {
      message.error(m);
    },
    onCompleted: (data) => {
      message.success(t('successfullyUpdated'));
      onToggleEditMode(false);
      if (!isMobile) {
        return;
      }

      form.reset({ title: data.updateTeam.title });
    },
    awaitRefetchQueries: true,
  });

  const [removeTeam, { loading: removeLoading }] = useRemoveTeamMutation({
    refetchQueries: [
      {
        query: FIND_GROUP_QUERY,
        ...getGroupVariables,
      },
      {
        query: GET_USER_DONOR_LIST_QUERY,
        variables: {
          input: {
            userId,
          },
        },
      },
    ],
    onCompleted: () => {
      message.success(t('successfullyDeleted'));
    },
    onError: ({ message: m }) => {
      message.error(m);
    },
    awaitRefetchQueries: true,
  });

  const updateTeamHandler = form.handleSubmit((data) => {
    updateTeam({
      variables: {
        input: { id, title: data.title },
      },
    });
  });

  const removeTeamHandler = () => {
    removeTeam({
      variables: {
        input: {
          id: team.id,
        },
      },
    });
  };

  const user = useCurrentUser();
  const canUpdateDonors = user.data?.permissions?.updateDonorsData || false;
  const canArchiveDonors = user.data?.permissions?.archiveDonors || false;

  const attachDonorsModalContext = useAttachDonorsModalContext();

  const onPinClickHandler = async () => {
    attachDonorsModalContext?.showModal({
      teamId: team.id,
    });
  };

  const isLoading = removeLoading || updateTeamLoading;
  const turnEditModeTo = (value: boolean) => () => onToggleEditMode(value);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[10, 0]} justify="center">
        {isEditMode ? (
          <>
            <Col>
              <CloseOutlined onClick={turnEditModeTo(false)} />
            </Col>
            <Col>
              <Button
                type="text"
                size="small"
                onClick={updateTeamHandler}
                disabled={!form.formState.isDirty || !form.formState.isValid}
              >
                <CheckOutlined className={styles.checkIcon} />
              </Button>
            </Col>
          </>
        ) : (
          <>
            {canUpdateDonors && (
              <>
                <Col>
                  <EditOutlined onClick={turnEditModeTo(true)} />
                </Col>

                <Col>
                  <PushpinOutlined onClick={onPinClickHandler} />
                </Col>
              </>
            )}
            {canArchiveDonors && (
              <Col>
                <DeletePopconfirm
                  title={t('sureToDelete')}
                  onConfirm={removeTeamHandler}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </Spin>
  );
};

export default TeamActionsButtons;
