import { Row, Col, Layout } from 'antd';
import ResendEmailConfirmationCodeForm from 'modules/auth/components/ResendEmailConfirmationCodeForm';
import { MainLayout } from 'modules/common/components';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from './styles.module.scss';

const ResendConfirmationCodePage = () => {
  const { t } = useTranslation('auth.ResendConfirmationCodePage');
  const [searchParams] = useSearchParams();
  const defaultUserEmail = searchParams.get('email') || undefined;

  return (
    <MainLayout>
      <Layout.Content className={styles.content}>
        <Row justify="center" align="middle" className={styles.formContainer}>
          <Col span={24}>
            <h1 className={styles.title}>{t('resendConfirmationCode')}</h1>
          </Col>
          <Col flex="440px">
            <ResendEmailConfirmationCodeForm
              defaultEmail={
                defaultUserEmail
                  ? decodeURIComponent(defaultUserEmail)
                  : undefined
              }
            />
          </Col>
        </Row>
      </Layout.Content>
    </MainLayout>
  );
};

export default ResendConfirmationCodePage;
