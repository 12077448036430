import { DonationListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.DonorDonationCardListSorter');
export const options = [
  {
    value: DonationListSort.LastDonationDate,
    label: t('date'),
  },
];
