import { Table, message } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DaycareAppointmentResponseOutput,
  useUnenrollDonorFromDaycareMutation,
} from 'types.d';

import { getColumns } from './columns';
import { ExpandedRowRender } from './expandedRowRender';

type PropTypes = {
  daycareAppointmentInfo: DaycareAppointmentResponseOutput[];
};

export const DaycareAppointmentDetailTable: FC<PropTypes> = ({
  daycareAppointmentInfo,
}) => {
  const { t } = useTranslation('appointments.DaycareDetailModal');
  const [unenrollDonorFromDaycare, { loading: enrollLoading }] =
    useUnenrollDonorFromDaycareMutation();

  const tableData = daycareAppointmentInfo.map((daycareDetail) => ({
    ...daycareDetail,
    key: daycareDetail.id,
  }));

  const unenrollHandler = (daycareId: string, donorId: string) => {
    unenrollDonorFromDaycare({
      variables: {
        input: {
          donorId,
          daycareId,
        },
      },
      refetchQueries: ['getDaycareAppointmentInfo'],
      onCompleted() {
        message.success(t('donorUnenrolled'));
      },
      onError() {
        message.error(t('unenrollError'));
      },
    });
  };

  const columns = getColumns();

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      expandable={{
        expandedRowRender: (record) => {
          return record.ownerResponse ? (
            <ExpandedRowRender
              daycareData={record}
              enrollLoading={enrollLoading}
              unenrollHandler={unenrollHandler}
            />
          ) : null;
        },
        rowExpandable: (record) => record.ownerResponse,
      }}
      pagination={false}
    />
  );
};
