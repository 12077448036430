import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import EditOwnerProfileCardTabs from 'modules/owner/components/EditOwnerProfileCardTabs';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOwnerLazyQuery } from 'types.d';

const EditOwnerPage: FC = () => {
  const { t } = useTranslation('owner.EditOwnerPage');
  const searchParams = useParams();
  const navigate = useNavigate();
  const id = searchParams.id || '';
  const ownerId = isNaN(Number(id)) ? id : Number(id);
  const [getOwner] = useGetOwnerLazyQuery({
    onCompleted: (data) => {
      //THIS LOGIC HAS BEEN PROVIDED TO SEE THIS PAGE ONLY WITH SHORT ID WHICH IS NUMBER
      navigate(`${Routes.Users}/${data.getOwner.shortId}`, {
        replace: true,
      });
    },
    onError() {
      navigate(Routes.Owners);
    },
  });

  useEffect(() => {
    if (typeof ownerId === 'string') {
      getOwner({
        variables: {
          input: { id: ownerId },
        },
      });
    }
  }, [ownerId]);

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Row>
        <Col span={24}>
          {typeof ownerId === 'number' && (
            <EditOwnerProfileCardTabs ownerId={id} />
          )}
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default EditOwnerPage;
