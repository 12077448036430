import { Input as AntInput, InputProps, InputRef } from 'antd';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const Input = forwardRef<InputRef, InputProps>(
  ({ className, ...restProps }: InputProps, ref) => {
    const classNames = `${styles.input} ${className || ''}`;
    return <AntInput ref={ref} className={classNames} {...restProps} />;
  }
);

export default Input;
