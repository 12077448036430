import { CheckCircleTwoTone } from '@ant-design/icons';
import { Row, Col } from 'antd';
import colors from 'config/colors.scss';
import { Routes } from 'config/routes';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

type PropTypes = {
  message: string;
};

const EmailVerified: FC<PropTypes> = ({ message }) => {
  const { t } = useTranslation('user.EmailVerified');
  const navigate = useNavigate();

  const navigateTo = (route: Routes) => () => {
    navigate(route);
  };

  return (
    <Col flex="200px">
      <CheckCircleTwoTone className={styles.icon} twoToneColor={colors.salad} />
      <div className={styles.message}>{message}</div>
      <Row>
        <Col span={24}>
          <Button
            type="link"
            className={styles.navigationButton}
            onClick={navigateTo(Routes.Login)}
          >
            {t('login')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default EmailVerified;
