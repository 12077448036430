import AppointmentsCalendar from 'modules/appointments/components/AppointmentsCalendar';
import CreateAppointmentsModal from 'modules/appointments/components/CreateAppointmentsModal';
import { DaycareAppointmentDetail } from 'modules/appointments/components/DaycareDetailModal';
import { EditAppointmentsModal } from 'modules/appointments/components/EditAppointmentsModal';
import EnrollDonorModal from 'modules/appointments/components/EnrollDonorModal';
import { RegisterOnDaycareAppointmentModal } from 'modules/appointments/components/RegisterOnDaycareAppointmentModal';
import { useCreateAppointmentsModalContext } from 'modules/appointments/contexts/CreateAppointmentsModal';
import { useDaycareDetailModalContext } from 'modules/appointments/contexts/DaycareInfoModal';
import { useDaycareRegisterModalContext } from 'modules/appointments/contexts/DaycareRegisterModal';
import { useEditAppointmentsModalContext } from 'modules/appointments/contexts/EditAppointmentModal';
import { useEnrollDonorModalContext } from 'modules/appointments/contexts/EnrollDonorModal';
import { useRecurringDateContext } from 'modules/appointments/contexts/RecurringDate';
import EditLocationsModal from 'modules/locations/components/EditLocationsModal';
import { useEditLocationsModalContext } from 'modules/locations/contexts/EditLocationsModal';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const AppointmentsPage: FC = () => {
  const { t } = useTranslation('appointments.AppointmentsPage');
  const createAppointmentsModal = useCreateAppointmentsModalContext();
  const enrollDonorModal = useEnrollDonorModalContext();
  const editLocationsModal = useEditLocationsModalContext();
  const editAppointmentsModal = useEditAppointmentsModalContext();
  const daycareRegisterModal = useDaycareRegisterModalContext();
  const daycareDetailModal = useDaycareDetailModalContext();
  const recurringDate = useRecurringDateContext();

  return (
    <ProfileSideMenuWrapper title={t('appointmentsPage')}>
      <AppointmentsCalendar />
      {createAppointmentsModal && (
        <CreateAppointmentsModal
          {...createAppointmentsModal?.modalConfig}
          onFormHandle={recurringDate?.onClear}
        />
      )}
      {enrollDonorModal && (
        <EnrollDonorModal {...enrollDonorModal?.modalConfig} />
      )}
      {editAppointmentsModal && (
        <EditAppointmentsModal
          {...editAppointmentsModal.modalConfig}
          onFormHandle={recurringDate?.onClear}
        />
      )}
      {editLocationsModal && (
        <EditLocationsModal {...editLocationsModal.modalConfig} />
      )}
      {daycareRegisterModal && (
        <RegisterOnDaycareAppointmentModal
          {...daycareRegisterModal.modalConfig}
        />
      )}
      {daycareDetailModal && (
        <DaycareAppointmentDetail {...daycareDetailModal.modalConfig} />
      )}
    </ProfileSideMenuWrapper>
  );
};

export default AppointmentsPage;
