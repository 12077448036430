import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import config from 'config';
import { format } from 'date-fns';
import { convertKilogramsToPounds } from 'modules/donor/utils/convertWeight';
import { downloadFileHandler } from 'modules/donor/utils/download-file-handler';
import { DonorOutput, UserDonorListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('owner.DonorsPage');

export const columns: ColumnsType<DonorOutput> = [
  {
    title: t('donorId'),
    render: (row: DonorOutput) => row.shortId,
    align: 'center',
  },
  {
    title: t('petName'),
    render: (row: DonorOutput) => row.name,
    key: UserDonorListSort.Name,
    sorter: true,
    align: 'center',
  },
  {
    title: t('species'),
    render: (row: DonorOutput) => row.species,
    align: 'center',
  },
  {
    title: t('gender'),
    render: (row: DonorOutput) => row.gender,
    key: UserDonorListSort.Gender,
    sorter: true,
    align: 'center',
  },
  {
    title: t('age'),
    render: (row: DonorOutput) => row.age,
    align: 'center',
  },
  {
    title: t('weight'),
    render: (row: DonorOutput) => convertKilogramsToPounds(row.weight),
    align: 'center',
  },
  {
    title: t('sterilized'),
    render: (row: DonorOutput) => (row.neutered ? t('yes') : t('no')),
    align: 'center',
  },
  {
    title: t('nextDonationDate'),
    render: (row: DonorOutput) =>
      row.nextDonationDate
        ? format(new Date(row.nextDonationDate), config.DATE_FORMAT)
        : '-',
    align: 'center',
  },
  {
    title: t('pdfLatestLabwork'),
    render: (row: DonorOutput) => {
      return row.lastLabworkFile
        ? row.lastLabworkFile.map((file) => {
            return (
              <>
                <Typography.Link
                  download
                  onClick={async () =>
                    row.lastLabworkFile &&
                    downloadFileHandler(
                      file.filename,
                      `${process.env.REACT_APP_SERVER_URI}/labwork/current-owner/files/${file.id}`
                    )
                  }
                >
                  {file.filename}
                </Typography.Link>
                <br />
              </>
            );
          })
        : '-';
    },
    align: 'center',
  },
  {
    title: t('status'),
    render: ({ status }: DonorOutput) =>
      t(status.charAt(0).toLowerCase() + status.slice(1)),
    align: 'center',
  },
];
