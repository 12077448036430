import { Col } from 'antd';
import AppointmentTypeSelect from 'modules/appointments/components/AppointmentTypeSelect';
import { FormItem } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

type PropTypes = {
  control: Control<CreateAppointmentValidationSchema, any>;
  disabled?: boolean;
  disableDaycareSelect?: boolean;
} & ComponentProps<typeof FormItem>;

const FIELD_NAME = AppointmentFormField.Type;

const TypeSelect: FC<PropTypes> = ({
  control,
  disabled,
  disableDaycareSelect,
  ...restFormItemProps
}) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const errorMessage = control.getFieldState(FIELD_NAME).error?.message;

  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('type')}
        extra={errorMessage}
        validateStatus={errorMessage && 'error'}
        {...restFormItemProps}
      >
        <AppointmentTypeSelect
          disableDaycareSelect={disableDaycareSelect}
          controlled={{ control, name: FIELD_NAME }}
          disabled={disabled}
        />
      </FormItem>
    </Col>
  );
};

export default TypeSelect;
