import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import ControlledDatePicker from 'modules/common/components/ControlledDatePicker';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

import { PersonalInfoFields } from './enum';

const t = getTranslation('owner.CreateOwnerPage');

export const personalInfoFields: FormFieldWithComponent<
  PersonalInfoFields,
  { name: string }
>[] = [
  {
    label: t('firstName'),
    name: PersonalInfoFields.firstName,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterFirstName')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('lastName'),
    name: PersonalInfoFields.lastName,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterLastName')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('email'),
    name: PersonalInfoFields.email,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterEmail')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('phone'),
    name: PersonalInfoFields.phoneNumber,
    Component: ({ control, name }) => (
      <ControlledPhoneInput
        placeholder={t('enterPhone')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('streetAddress'),
    name: PersonalInfoFields.streetAddress,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterStreetAddress')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('city'),
    name: PersonalInfoFields.city,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterCity')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('state'),
    name: PersonalInfoFields.state,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterState')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('zipCode'),
    name: PersonalInfoFields.zipCode,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterZipCode')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('locationPreferences'),
    name: PersonalInfoFields.locationPreferences,
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterLocationPreferences')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('birthday'),
    name: PersonalInfoFields.birthday,
    Component: ({ control, name }) => (
      <ControlledDatePicker
        placeholder={t('selectBirthdayDate')}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
];
