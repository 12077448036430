import { Col, Divider, message, Row } from 'antd';
import { Routes } from 'config/routes';
import { ScreenSpinner, Spin } from 'modules/common/components';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DonorNoteOutput,
  useGetDonorNotesLazyQuery,
  useGetDonorQuery,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { CreateNoteForm } from './CreateNoteForm';
import { NoteContent } from './NoteContent';
import styles from './styles.module.scss';

const t = getTranslation('donor.EditDonorForm.NoteSection');

const DonorNotes: React.FC = () => {
  const pageParams = useParams();
  const navigate = useNavigate();
  const [editingNote, setEditingNote] = useState<string | null>(null);
  const donorShortId = pageParams.id || '';

  const [getNotes, { data: notesQuery, loading: loadingNotes }] =
    useGetDonorNotesLazyQuery();

  const { data: getDonorData, loading: getDonorLoading } = useGetDonorQuery({
    variables: {
      input: {
        id: donorShortId,
      },
    },
    onError: (err) => {
      message.error(err.message);
      if (err.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.IndividualDonors);
    },

    onCompleted(data) {
      getNotes({
        variables: {
          donorId: data.getDonor.id,
        },
      });
    },
  });

  const donorId = getDonorData?.getDonor.id;

  const notes = [
    ...(notesQuery?.getDonorNotes.filter((note) => !note.isPinned) || []),
  ];
  const pinnedNotes = [
    ...(notesQuery?.getDonorNotes.filter((note) => note.isPinned) || []),
  ];

  const isLoading = loadingNotes || getDonorLoading;

  if (isLoading) {
    return <ScreenSpinner />;
  }

  if (!donorId) {
    return null;
  }

  return (
    <Row className={styles.notes}>
      <Col span={24} className={styles.noteInputWrapper}>
        <CreateNoteForm donorId={donorId} />
      </Col>
      {isLoading ? (
        <Spin />
      ) : (
        <Col span={24} className={styles.notesWrapper}>
          <div className={styles.notesWrapper}>
            {!!pinnedNotes?.length && <p>{t('pinnedNotes')}</p>}

            {pinnedNotes?.map((note) => (
              <NoteContent
                note={note as DonorNoteOutput}
                key={note.id}
                editingNote={editingNote}
                setEditingNote={setEditingNote}
                isPinned={true}
              />
            ))}
            {!!pinnedNotes?.length && <Divider />}
          </div>

          {notes.map((note) => (
            <NoteContent
              note={note as DonorNoteOutput}
              key={note.id}
              editingNote={editingNote}
              setEditingNote={setEditingNote}
            />
          ))}
        </Col>
      )}
    </Row>
  );
};

export default DonorNotes;
