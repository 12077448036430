import type { ColumnsType } from 'antd/es/table';
import { Routes } from 'config/routes';
import { Link } from 'react-router-dom';
import { DaycareAppointmentResponseOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './style.module.scss';

const t = getTranslation('appointments.DaycareDetailModal');

export const getColumns = (): ColumnsType<DaycareAppointmentResponseOutput> => {
  return [
    {
      title: t('owner'),
      key: 'owner',
      render: (data: DaycareAppointmentResponseOutput) => {
        const owner = data.owner;
        return (
          <Link
            target="_blank"
            to={Routes.EditOwner.replace(':id', `${owner.shortId}`)}
          >
            {owner.firstName} {owner.lastName}
          </Link>
        );
      },
    },
    {
      title: t('answer'),
      key: 'ownerResponse',
      render: (data: DaycareAppointmentResponseOutput) =>
        data.ownerResponse ? t('yes') : t('no'),
    },
    {
      title: t('note'),
      key: 'note',
      className: styles.note,
      render: (data: DaycareAppointmentResponseOutput) => data.note || '-',
    },
  ];
};
