import { Row, Col, message, Divider } from 'antd';
import { Button, Spin } from 'modules/common/components';
import DonationDetails from 'modules/donations/components/DonationDetails';
import OrderDetails from 'modules/orders/components/OrderDetails';
import { useChangeProductTrackingInfoModalContext } from 'modules/products/contexts/ChangeProductTrackingInfoModal';
import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_QUERY,
} from 'modules/products/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProductOutput,
  WoocommerceProductCategoryOutput,
  useUpdateProductStatusMutation,
  ProductStatus,
  useGetOrderQuery,
  OrderOutput,
  PermissionsEnum,
} from 'types.d';

import styles from './styles.module.scss';

import ProductDetails from '../ProductDetails';

type PropTypes = {
  product: ProductOutput;
  woocommerceProductCategory?: WoocommerceProductCategoryOutput;
};

const ProductCard: FC<PropTypes> = ({
  product,
  woocommerceProductCategory,
}) => {
  const { t } = useTranslation('products.ProductCard');
  const currentUser = useCurrentUser();
  const canUpdateProductStatus = currentUser.isCan(
    PermissionsEnum.UpdateProductStatus
  );
  const [updateProductStatus, updateProductStatusMutation] =
    useUpdateProductStatusMutation({
      refetchQueries: [GET_PRODUCT_LIST, GET_PRODUCT_QUERY],
      onCompleted: () => {
        message.success(t('productHasBeenUpdated'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  const productOrderQuery = useGetOrderQuery({
    skip: !product.woocommerceOrderId,
    variables: {
      input: {
        id: product.woocommerceOrderId as number,
      },
    },
  });
  const productOrder = productOrderQuery.data?.getOrder as
    | OrderOutput
    | undefined;
  const isProductCanBeUpdated = product.status === ProductStatus.Available;
  const changeProductTrackingInfoModalContext =
    useChangeProductTrackingInfoModalContext();

  const updateProductStatusOn = (status: ProductStatus) => () => {
    updateProductStatus({
      variables: {
        input: {
          id: product.id,
          status,
        },
      },
    });
  };

  return (
    <Spin spinning={updateProductStatusMutation.loading}>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <ProductDetails
            product={product}
            woocommerceProductCategory={woocommerceProductCategory}
          />
        </Col>
        <Col span={24}>
          <Divider orientation="left">{t('donationDetails')}</Divider>
        </Col>
        <Col span={24}>
          <DonationDetails donation={product.donation} />
        </Col>
        {productOrder && (
          <>
            <Col span={24}>
              <Divider orientation="left">{t('orderDetails')}</Divider>
            </Col>
            <Col span={24}>
              <OrderDetails order={productOrder} />
            </Col>
          </>
        )}
        <Col md={12} span={24}>
          <Button
            className={styles.button}
            onClick={() =>
              changeProductTrackingInfoModalContext?.showModal(product)
            }
          >
            {t('updateProductInfo')}
          </Button>
        </Col>
        {canUpdateProductStatus && (
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col md={6} span={24}>
                <Button
                  disabled={!isProductCanBeUpdated}
                  onClick={updateProductStatusOn(ProductStatus.Sold)}
                  className={styles.button}
                >
                  {t('sold')}
                </Button>
              </Col>
              <Col md={6} span={24}>
                <Button
                  disabled={!isProductCanBeUpdated}
                  onClick={updateProductStatusOn(ProductStatus.Discarded)}
                  className={styles.button}
                >
                  {t('discarded')}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Spin>
  );
};

export default ProductCard;
