import { Table, message } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DaycareAppointmentRequestOutput,
  useApproveDaycareAppointmentRequestMutation,
  useDisproveDaycareAppointmentRequestMutation,
} from 'types.d';

import { getColumns } from './column';

type PropTypes = {
  daycareAppointmentRequests: DaycareAppointmentRequestOutput[];
};

export const DaycareAppointmentRequestsTable: FC<PropTypes> = ({
  daycareAppointmentRequests,
}) => {
  const { t } = useTranslation('appointments.daycareAppointmentRequestTable');
  const [approveRequest, approveRequestMutation] =
    useApproveDaycareAppointmentRequestMutation();

  const [disproveRequest, disproveRequestMutation] =
    useDisproveDaycareAppointmentRequestMutation();

  const approveHandler = (id: string) => {
    approveRequest({
      variables: {
        id,
      },
      onCompleted() {
        message.success(t('requestApproved'));
      },
      onError() {
        message.error(t('processRequestError'));
      },
      refetchQueries: ['getDaycareAppointmentInfo'],
    });
  };

  const disproveHandler = (id: string) => {
    disproveRequest({
      variables: {
        id,
      },
      onCompleted() {
        message.success(t('requestDisproved'));
      },
      onError() {
        message.error(t('processRequestError'));
      },
      refetchQueries: ['getDaycareAppointmentInfo'],
    });
  };

  const columns = getColumns({
    approveHandler,
    disproveHandler,
  });
  const loading =
    approveRequestMutation.loading || disproveRequestMutation.loading;
  return (
    <Table
      columns={columns}
      dataSource={daycareAppointmentRequests}
      pagination={false}
      loading={loading}
    />
  );
};
