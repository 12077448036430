import { FormItem } from 'modules/common/components';
import UserSelect from 'modules/user/components/UserSelect';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  control: Control<any, any>;
  onClear: () => void;
};

const OwnerSelect: FC<PropTypes> = ({ control, onClear }) => {
  const { t } = useTranslation('donor.CsvDonorsUploadingModal');
  const fieldError = control.getFieldState('userId').error;
  const errorMessage = fieldError?.message;

  return (
    <FormItem validateStatus={errorMessage && 'error'} extra={errorMessage}>
      <UserSelect
        className={styles.ownerSelect}
        placeholder={t('selectOwner')}
        userRoles={[UserRole.Owner]}
        isArchived={false}
        allowClear
        onClear={onClear}
        controlled={{
          control,
          name: 'userId',
        }}
      />
    </FormItem>
  );
};

export default OwnerSelect;
