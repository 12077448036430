import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FileOutput } from 'types';
import { fetchWithAuth } from 'utils/fetchWithAuth';

type PropTypes = {
  canDelete: boolean;
  refetchNotes: () => void;
  noteFileId: string;
} & FileOutput;

export const NoteFile: FC<PropTypes> = ({
  id,
  filename,
  canDelete,
  noteFileId,
  refetchNotes,
}) => {
  const [fileUrl, setFileUrl] = useState('');
  const [deleteLoading, setDeleteLoad] = useState(false);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetchWithAuth(
          `${process.env.REACT_APP_SERVER_URI}/donor/donor-note-files/${id}`,
          {
            method: 'GET',
          }
        );
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        setFileUrl(fileUrl);
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    fetchFile();
  }, [id]);

  const handleNoteFileDelete = async () => {
    setDeleteLoad(true);
    const response = await fetchWithAuth(
      `${process.env.REACT_APP_SERVER_URI}/donor/donor-note-files/${noteFileId}`,
      {
        method: 'DELETE',
      }
    );

    if (!response.ok) {
      return message.error('Error while deleting file');
    }

    refetchNotes();
    setDeleteLoad(false);
  };

  return (
    <div>
      {fileUrl ? (
        <div>
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {filename}
          </a>
          {canDelete && (
            <Button
              type="link"
              danger
              onClick={handleNoteFileDelete}
              loading={deleteLoading}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
};
