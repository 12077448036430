import { getMenuItemsOfInternalTeam } from 'modules/navigation/components/ProfileSideMenuWrapper/menuItems/internalTeam';
import { PermissionsType } from 'modules/user/types';
import { UserRole, RoleOutput } from 'types.d';

import { menuItemsOfOwner } from './owner';

import { MenuOptionType } from '../types';

type ParamTypes = {
  userRole: RoleOutput;
  permissions: PermissionsType;
};

export const getMenuItemsByRole = ({
  userRole,
  permissions,
}: ParamTypes): MenuOptionType[] => {
  return userRole.value === UserRole.Owner
    ? menuItemsOfOwner
    : getMenuItemsOfInternalTeam(userRole, permissions);
};
