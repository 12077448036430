export enum UpdateVitalsParameterFormFields {
  temperatureToCheckMax = 'temperatureToCheckMax',
  temperatureToCheckMin = 'temperatureToCheckMin',
  temperatureToStopMax = 'temperatureToStopMax',
  temperatureToStopMin = 'temperatureToStopMin',
  pulseMax = 'pulseMax',
  pulseMin = 'pulseMin',
  respirationMin = 'respirationMin',
  respirationCriticalValues = 'respirationCriticalValues',
  packedCellVolumeMax = 'packedCellVolumeMax',
  packedCellVolumeMin = 'packedCellVolumeMin',
  totalSolidsMin = 'totalSolidsMin',
  totalSolidsMax = 'totalSolidsMax',
  mucousMembraneCriticalValues = 'mucousMembraneCriticalValues',
  capillaryRefillTimeMax = 'capillaryRefillTimeMax',
  capillaryRefillTimeMin = 'capillaryRefillTimeMin',
  weightPercentageDifference = 'weightPercentageDifference',
  weightMin = 'weightMin',
}
