import * as yup from 'yup';

type DonorSchemaType = {
  donorId: string;
  donorName: string;
};

const donorSchema: yup.SchemaOf<DonorSchemaType> = yup.object({
  donorId: yup.string().max(255).required(),
  donorName: yup.string().max(255).required(),
});

export { donorSchema };
export type { DonorSchemaType };
