import { Col, Row } from 'antd';
import { FormItem } from 'modules/common/components';
import { contactFields } from 'modules/owner/components/CreateOwnerProfileForm/AdditionalContactsSection/fields';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  fieldNamePrefix: string;
};

const AdditionalContactsField: FC<Props> = ({ fieldNamePrefix }) => {
  const { t } = useTranslation('owner.CreateOwnerPage');
  const { control, formState } = useFormContext();

  return (
    <Col span={24}>
      <h2>{t('additionalContact')}</h2>
      {contactFields.map(({ name, Component, label }) => {
        const fieldName = `${fieldNamePrefix}.${name}`;
        const errorMessage = formState.errors[fieldNamePrefix]?.[name]?.message;
        return (
          <Row key={fieldName}>
            <Col span={24}>
              <FormItem
                label={label}
                extra={errorMessage}
                validateStatus={errorMessage && 'error'}
              >
                <Component control={control} name={fieldName} />
              </FormItem>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

export default AdditionalContactsField;
