import { Routes } from 'config/routes';
import InternalUsersTable from 'modules/admin/components/InternalUsersTable';
import PermissionsTable from 'modules/admin/components/PermissionsTable';
import { ITabsItem } from 'modules/common/components/CardTabs';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum } from 'types.d';

const UsersCardTabs: FC = () => {
  const { t } = useTranslation('admin.UsersListPage');
  const currentUser = useCurrentUser();
  const isManagePermissionsAllowed = currentUser.isCan(
    PermissionsEnum.ManagePermissions
  );

  const internalUsers: ITabsItem = {
    title: t('users'),
    content: <InternalUsersTable />,
    key: Routes.Users,
  };

  const permissionsTable: ITabsItem = {
    title: t('permissions'),
    content: <PermissionsTable />,
    key: Routes.UsersPermissions,
  };

  return (
    <NavigationCardTabs
      items={
        isManagePermissionsAllowed
          ? [internalUsers, permissionsTable]
          : [internalUsers]
      }
    />
  );
};

export default UsersCardTabs;
