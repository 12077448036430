import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button, message } from 'antd';
import { Routes } from 'config/routes';
import {
  FormItem,
  Spin,
  ControlledPasswordInput,
} from 'modules/common/components';
import { RESET_PASSWORD_MUTATION } from 'modules/user/graphql/mutations';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MutationResetPasswordArgs, Mutation } from 'types.d';

import { ResetPasswordSchemaType, resetPasswordSchema } from './schema';

type PropTypes = {
  code: string;
};

const ResetPasswordForm: FC<PropTypes> = ({ code }) => {
  const { t } = useTranslation('user.ResetPasswordForm');
  const navigate = useNavigate();
  const [resetPassword, resetPasswordMutation] = useMutation<
    Pick<Mutation, 'resetPassword'>,
    MutationResetPasswordArgs
  >(RESET_PASSWORD_MUTATION, {
    onCompleted: () => {
      message.success(t('passwordSuccessfullyChanged'));
      navigate(Routes.Login);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const { control, formState, handleSubmit } = useForm<ResetPasswordSchemaType>(
    {
      resolver: yupResolver(resetPasswordSchema),
      mode: 'onChange',
    }
  );

  const submitForm = handleSubmit((formData) => {
    resetPassword({
      variables: {
        input: {
          password: formData.password,
          code,
        },
      },
    });
  });

  return (
    <Spin spinning={resetPasswordMutation.loading}>
      <Form>
        <FormItem
          label={t('newPassword')}
          validateStatus={formState.errors.password?.message && 'error'}
          extra={formState.errors.password?.message}
        >
          <ControlledPasswordInput
            placeholder={t('enterPassword')}
            controlled={{ control, name: 'password' }}
          />
        </FormItem>
        <FormItem
          label={t('confirmPassword')}
          validateStatus={formState.errors.confirmPassword?.message && 'error'}
          extra={formState.errors.confirmPassword?.message}
        >
          <ControlledPasswordInput
            placeholder={t('enterPassword')}
            controlled={{ control, name: 'confirmPassword' }}
          />
        </FormItem>
        <Button
          disabled={!formState.isValid}
          onClick={submitForm}
          type="primary"
        >
          {t('resetPassword')}
        </Button>
      </Form>
    </Spin>
  );
};

export default ResetPasswordForm;
