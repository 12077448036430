import { FC } from 'react';
import { CSVLink } from 'react-csv';

import { dataExample } from './example';
import { headers } from './headers';

const DonorsCsvTemplateDownload: FC = () => {
  return (
    <CSVLink filename="PossibleValues.csv" data={dataExample} headers={headers}>
      PossibleValues.csv
    </CSVLink>
  );
};

export default DonorsCsvTemplateDownload;
