import { DefaultOptionType } from 'antd/lib/select';
import { AppointmentType } from 'modules/appointments/enums';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('appointments.AppointmentTypeSelect');

export const appointmentTypes: DefaultOptionType[] = Object.values(
  AppointmentType
).map<DefaultOptionType>((type) => {
  return {
    label: t(type),
    value: type,
  };
});
