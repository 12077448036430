import { Table, TableProps, Col, Row } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { SearchInput, Spin } from 'modules/common/components';
import { useDebounce, useSort } from 'modules/common/hooks';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DonorOutput,
  DonorListSort,
  Order,
  DonorListFilterInput,
} from 'types.d';

import { getColumns } from './columns';

const useDonorsListSort = () => useSort<DonorListSort>();

type Props = {
  donors: DonorOutput[];
  attachedDonors: DonorOutput[];
  onAttachClickHandler: (donorsToAttach: DonorOutput) => void;
  refetchDonors?: (filter: DonorListFilterInput) => void;
  sort: ReturnType<typeof useDonorsListSort>;
  pagination: ReturnType<typeof usePagination>;
  setFilter: (filter: DonorListFilterInput) => void;
  filter: DonorListFilterInput;
  isDonorsLoading: boolean;
};

const DonorsTeamTable: FC<Props> = ({
  onAttachClickHandler,
  donors,
  attachedDonors,
  sort,
  pagination,
  filter,
  setFilter,
  isDonorsLoading,
}) => {
  const { t } = useTranslation('donor.DonorsTable');
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const { debounce, clearDebounce } = useDebounce();

  const columns = useMemo(
    () =>
      getColumns({
        attachedDonors,
        filterValues: filter,
        onAttachClickHandler: onAttachClickHandler,
      }),
    [attachedDonors]
  );

  const onTableChange: TableProps<DonorOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<DonorOutput>;
      const fieldName = tableSorter.columnKey as DonorListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        sort?.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        sort?.removeSortOption(fieldName);
      }
    }

    if (extra.action === 'filter') {
      setFilter && setFilter({ ...filters, searchingQuery: null });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    debounce((searchingQuery?: string) =>
      setFilter({
        searchingQuery,
      })
    )(searchValue);
  };

  useEffect(() => {
    return clearDebounce;
  }, []);

  return (
    <Row gutter={[0, 15]}>
      <Col span={24}>
        <SearchInput
          onChange={handleSearch}
          placeholder={t('searchDonor')}
          maxLength={100}
          value={filter?.searchingQuery ? searchInputValue : undefined}
        />
      </Col>
      <Col span={24}>
        <Spin spinning={isDonorsLoading}>
          <Table
            onChange={onTableChange}
            rowKey={({ id }) => id}
            columns={columns}
            dataSource={donors}
            pagination={pagination.config}
            scroll={{ x: 750 }}
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default DonorsTeamTable;
