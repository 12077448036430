import { getTranslation } from 'utils/getTranslation';

import { IColumnFieldsConfig } from './build-column';

const t = getTranslation(
  'donor.FormsToPrint.AthensVeterinaryDiagnostic.columnBuilderConfig'
);

export const firstColumnFieldsConfig: IColumnFieldsConfig[] = [
  {
    title: t('bacteriologyMycology'),
    fields: [
      t('aerobicCulture'),
      t('anaerobicCulture'),
      t('fungalYeastCulture'),
      t('minimumAntimicrobialInhibitoryConcentration'),
      t('salmonellaCulture'),
      t('mycobacteriumCulture'),
      t('mycoplasmaCulture'),
      {
        fieldName: t('stains'),
        fields: [
          t('acidFast'),
          t('gram'),
          { fieldName: t('other'), withInput: true },
        ],
      },
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
  {
    title: t('virology'),
    fields: [
      {
        fieldName: t('virusIsolation'),
        withInput: true,
      },
      t('electronMicroscopy'),
      {
        fieldName: t('rabies'),
        fields: [t('humanExposure'), t('animalExposure'), t('noExposure')],
      },
    ],
  },
  {
    title: t('clinicalPathology'),
    fields: [
      t('cbc'),
      t('chemistryProfile'),
      {
        fieldName: t('urinalysis'),
        withInput: true,
      },
      t('amylase'),
      t('bileAcid'),
      t('bloodParasites'),
      t('cortisol'),
      t('lipase'),
      t('t4'),
      t('tsh'),
      t('urineProteinCreatinineRatio'),
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
];

export const secondColumnFieldConfig: IColumnFieldsConfig[] = [
  {
    title: t('pathology'),
    subTitle: t('completeHistory'),
    fields: [
      {
        fieldName: t('biopsy'),
        addToRow: [t('extended'), t('routine')],
      },
      t('cytology'),
      t('dermatopathology'),
      t('necropsy'),
      t('mailInNecropsy'),
    ],
  },
  {
    title: t('antibodyAntigenMolecularDetection'),
    subTitle: t('checkAppropriateBox'),
    subTitleSize: 'sm',
    fields: [],
  },
  {
    title: t('bovine'),
    optionsToChoose: ['SER', 'FA', 'PCR'],
    fields: [
      {
        fieldName: t('leukosis'),
        optionsToChoose: ['SER'],
      },
      {
        fieldName: t('bluetongue'),
        optionsToChoose: ['SER', 'FA'],
      },
      t('bovineViralDiarrhea'),
      {
        fieldName: t('epizooticHemorrhagicDisease'),
        optionsToChoose: ['SER', 'FA'],
      },
      t('infectiousBovineRhino'),
      t('respiratorySyncytialVirus'),
      {
        fieldName: t('rotavirus'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('salmonellaCulture'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
  {
    title: t('canine'),
    optionsToChoose: ['SER', 'FA', 'PCR'],
    fields: [
      {
        fieldName: t('coronavirus'),
        optionsToChoose: ['FA', 'PCR'],
      },
      t('distemper'),
      {
        fieldName: t('infectiousCanineHepatitis'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('parvovirus'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('canineHerpesvirus'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('other'),
        withInput: true,
        checked: 'PCR',
        inputValue: 'BPCR Canine Blood Bank',
      },
    ],
  },
  {
    title: t('equine'),
    optionsToChoose: ['SER', 'FA', 'PCR'],
    fields: [
      t('equineViralArteritis'),
      {
        fieldName: t('influenza'),
        optionsToChoose: ['FA', 'PCR'],
      },
      t('rhinopneumonitis'),
      {
        fieldName: t('salmonellaCulture'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('prescottiaEqui'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('streptococcusEqui'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
];

export const thirdColumnFieldConfig: IColumnFieldsConfig[] = [
  {
    title: t('feline'),
    optionsToChoose: ['SER', 'FA', 'PCR'],
    fields: [
      {
        fieldName: t('infectiousPeritonitis'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('leukemia'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('panleukopenia'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('rhinotracheitis'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('tularemia'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('chlamydia'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('cfelisRhino'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
  {
    title: t('porcine'),
    optionsToChoose: ['SER', 'FA', 'PCR'],
    fields: [
      {
        fieldName: t('influenza'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('parvovirusPorcine'),
        optionsToChoose: ['FA'],
      },
      {
        fieldName: t('pseudorabies'),
        optionsToChoose: ['FA', 'SER'],
      },
      t('prrs'),
      {
        fieldName: t('tge'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('rotavirus'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
  {
    title: t('miscellaneous'),
    optionsToChoose: ['SER', 'FA', 'PCR'],
    fields: [
      {
        fieldName: t('brucellaCanis'),
        optionsToChoose: ['SER'],
      },
      {
        fieldName: t('brucellaAbortus'),
        optionsToChoose: ['SER'],
      },
      {
        fieldName: t('brucellaSpp'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('vesicularStomatitisVirus'),
        optionsToChoose: ['SER', 'PCR'],
      },
      t('leptospiraSpp'),
      t('toxoplasmaSpp'),
      {
        fieldName: t('campylobacterSpp'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('chlamydophilaSpp'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('clostridium'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('cryptosporidiumSpp'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('giardiaSpp'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('fungiSpecify'),
        withInput: true,
      },
      {
        fieldName: t('influenzaTypeA'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('listeriaSpp'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('mycobacteriumSpp'),
        optionsToChoose: ['PCR'],
      },
      {
        fieldName: t('mycoplasmaSpp'),
        optionsToChoose: ['FA', 'PCR'],
      },
      t('neosporaSpp'),
    ],
  },
];

export const fourthColumnFieldConfig: IColumnFieldsConfig[] = [
  {
    title: '',
    optionsToChoose: ['SER', 'FA', 'PCR'],
    fields: [
      {
        fieldName: t('ecoliPilusAntigen'),
        optionsToChoose: ['FA', 'PCR'],
      },
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
  {
    title: t('specialProcedures'),
    fields: [
      t('avianInfluenzaPcr'),
      t('clostridiumDifficile'),
      t('sealHerpesvirus1Sn'),
      {
        fieldName: t('morbillivirus'),
        addToRow: ['SN', 'PCR'],
      },
      t('newcastleDiseasePcr'),
      t('pachecosDiseaseFa'),
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
  {
    title: t('parasitology'),
    fields: [
      t('directSalineSmear'),
      t('fecalEggCount'),
      t('fecalFloat'),
      t('parasiteId'),
      {
        fieldName: t('other'),
        withInput: true,
      },
    ],
  },
];
