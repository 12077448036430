import { PrinterOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import config from 'config';
import { format } from 'date-fns';
import AvailabilityCheckbox from 'modules/products/components/AvailabilityCheckbox';
import ProductDropdownActions from 'modules/products/components/ProductDropdownActions';
import { AlignType } from 'rc-table/lib/interface';
import {
  BloodType,
  ProductListFilterInput,
  ProductListSort,
  ProductOutput,
  ProductStatus,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('products.ProductsTable');

type ParamTypes = {
  onAvailable?: (product: ProductOutput) => void;
  onPrint?: (product: ProductOutput) => void;
  productCategories?: Record<number, string>;
  canCreateLabels: boolean;
  canMakeProductsAvailable: boolean;
  filterValue?: ProductListFilterInput;
  showSorterTooltip?: boolean;
};

export const getColumns = (params: ParamTypes): ColumnsType<ProductOutput> => {
  const {
    onAvailable,
    onPrint,
    productCategories,
    canCreateLabels,
    canMakeProductsAvailable,
    filterValue,
    showSorterTooltip,
  } = params;

  const productsFilters =
    productCategories &&
    Object.keys(productCategories).map((categoryId) => {
      return {
        text: productCategories[Number(categoryId)],
        value: Number(categoryId),
      };
    });

  return [
    ...(canCreateLabels
      ? [
          {
            title: t('label'),
            align: 'center' as AlignType,
            render: (product: ProductOutput) => (
              <PrinterOutlined
                className={styles.printIcon}
                onClick={() => onPrint && onPrint(product)}
              />
            ),
          },
        ]
      : []),
    {
      title: t('donationId'),
      render: ({ donation }: ProductOutput) => donation.shortId,
    },
    {
      title: t('orderId'),
      key: 'orderId',
      render: ({ woocommerceOrderId }: ProductOutput) => {
        return woocommerceOrderId || '-';
      },
    },
    {
      title: t('petName'),
      sorter: true,
      showSorterTooltip,
      key: ProductListSort.DonorName,
      render: ({ donation }: ProductOutput) => donation?.donor?.name,
    },
    {
      title: t('ownerLastName'),
      sorter: true,
      showSorterTooltip,
      key: ProductListSort.OwnerLastName,
      render: ({ donation }: ProductOutput) => donation?.donor?.user?.lastName,
    },
    {
      title: t('location'),
      key: ProductListSort.ProductLocation,
      render: ({ locationPreference }: ProductOutput) => locationPreference,
    },
    {
      title: t('mls'),
      sorter: true,
      showSorterTooltip,
      key: ProductListSort.Value,
      render: ({ value }: ProductOutput) => value,
    },
    {
      title: t('expirationDate'),
      key: ProductListSort.ExpirationDate,
      render: ({ expirationDate }: ProductOutput) => {
        return format(new Date(expirationDate), config.DATE_FORMAT);
      },
      sorter: true,
      showSorterTooltip,
    },
    {
      title: t('product'),
      render: (product: ProductOutput) =>
        productCategories?.[product.woocommerceCategoryId],
      key: 'woocommerceCategory',
      filters: productsFilters,
      filteredValue: filterValue?.woocommerceCategory || null,
    },
    {
      title: t('bloodType'),
      render: ({ donation }: ProductOutput) =>
        donation?.donor?.bloodType
          ? t(`${donation?.donor?.bloodType?.toLowerCase()}`)
          : '',
      key: 'bloodType',
      filters: Object.values(BloodType).map((bloodType) => {
        return {
          text: t(bloodType.toLowerCase()),
          value: bloodType,
        };
      }),
      filteredValue: filterValue?.bloodType || null,
    },
    ...(canMakeProductsAvailable
      ? [
          {
            title: t('available'),
            align: 'center' as AlignType,
            render: (product: ProductOutput) => (
              <AvailabilityCheckbox
                product={product}
                onChecked={() => onAvailable && onAvailable(product)}
              />
            ),
          },
        ]
      : []),
    {
      title: t('soldInfo'),
      render: (product: ProductOutput) => product.status,
      key: 'statuses',
      filters: Object.values(ProductStatus).map((status) => {
        return {
          text: t(status.toLowerCase()),
          value: status,
        };
      }),
      filteredValue: filterValue?.statuses || null,
    },

    {
      render: (row: ProductOutput) => <ProductDropdownActions product={row} />,
    },
  ];
};
