import { dateSchema, locationPreferencesSchema } from 'modules/owner/schemas';
import {
  emailSchema,
  usernameSchema,
  phoneNumberSchema,
  userLastNameSchema,
  contactPreferencesSchema,
} from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { ContactFields, PersonalInfoFields } from './enum';

type ContactSchemaType = Record<ContactFields, string | undefined | null>;
type PersonalInfoSchemaType = Record<PersonalInfoFields, any>;

type EditProfileFormSchemaType = {
  personalInfo: PersonalInfoSchemaType;
  contact: ContactSchemaType;
};
const t = getTranslation('owner.ValidationErrors');

const transformEmptyString = (value: string) => (value === '' ? null : value);

export const contactSchema: yup.SchemaOf<ContactSchemaType> = yup.object({
  firstName: usernameSchema
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  lastName: usernameSchema
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  email: emailSchema.notRequired().nullable().transform(transformEmptyString),
  streetAddress: yup
    .string()
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  city: yup.string().notRequired().nullable().transform(transformEmptyString),
  state: yup.string().notRequired().nullable().transform(transformEmptyString),
  zipCode: yup
    .string()
    .length(5, t('mustBeExactLength', { field: t('zipCode'), value: 5 }))
    .matches(/^[0-9]+$/, t('onlyNumbers'))
    .notRequired()
    .nullable()
    .transform(transformEmptyString),
  phoneNumber: yup
    .string()
    .matches(
      /\(___\) ___-____|^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g,
      t('phoneNumberDoesNotMatch')
    )
    .notRequired()
    .nullable()
    .transform((value) =>
      value === '' || value === '(___) ___-____' ? null : value
    ),
});

const personalInfoSchema: yup.SchemaOf<PersonalInfoSchemaType> = yup.object({
  firstName: usernameSchema.nullable(),
  lastName: userLastNameSchema.nullable(),
  phoneNumber: phoneNumberSchema.nullable(),
  locationPreferences: locationPreferencesSchema.nullable(),
  birthday: dateSchema.nullable(),
  streetAddress: yup
    .string()
    .nullable()
    .required(t('required', { field: t('streetAddress') })),
  city: yup
    .string()
    .nullable()
    .required(t('required', { field: t('city') })),
  state: yup
    .string()
    .nullable()
    .required(t('required', { field: t('state') })),
  zipCode: yup
    .string()
    .nullable()
    .length(5, t('mustBeExactLength', { field: t('zipCode'), value: 5 }))
    .matches(/^[0-9]+$/, t('onlyNumbers'))
    .required(t('required', { field: t('zipCode') })),
  contactPreferences: contactPreferencesSchema
    .nullable()
    .required(t('required', { field: t('contactPreferences') })),
});

const editProfileFormSchema: yup.SchemaOf<EditProfileFormSchemaType> = yup
  .object()
  .shape({
    personalInfo: personalInfoSchema,
    contact: contactSchema,
  });

export { editProfileFormSchema };
export type { EditProfileFormSchemaType };
