import { addDays } from 'date-fns';
import { CATEGORY_ID } from 'modules/products/constants/categoryId';
import { PRODUCT_EXPIRATIONS } from 'modules/products/constants/productExpirations';

type ParametersType = {
  categoryId: number;
  donationDate: number | Date;
};

export const getProductExpirationDate = ({
  categoryId,
  donationDate,
}: ParametersType) => {
  const mapCategoryExpirations = {
    [CATEGORY_ID.FRESH_FROZEN_PLASMA]: PRODUCT_EXPIRATIONS.FRESH_FROZEN_PLASMA,
    [CATEGORY_ID.PRBS]: PRODUCT_EXPIRATIONS.PRBS,
    [CATEGORY_ID.WHOLE_BLOOD]: PRODUCT_EXPIRATIONS.WHOLE_BLOOD,
    [CATEGORY_ID.FROZEN_PLASMA]: PRODUCT_EXPIRATIONS.FROZEN_PLASMA,
  };
  const expirationDays = mapCategoryExpirations[categoryId];
  const donationCollectionDate = new Date(donationDate);
  return expirationDays
    ? addDays(donationCollectionDate, expirationDays)
    : donationCollectionDate;
};
