import { Radio, RadioChangeEvent, Row, Col, message } from 'antd';
import { Spin } from 'modules/common/components';
import { resetCache } from 'providers/cacheExpiration/resetCache';
import { apolloClient, cachePersistor } from 'providers/graphql';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cacheState } from './var';

enum CacheState {
  On,
  Off,
}

export const CacheManagement: FC = () => {
  const { t } = useTranslation('donor.AppointmentsDonors');
  const [loading, setLoading] = useState(false);
  const radioValue = cacheState() ? CacheState.On : CacheState.Off;

  const turnOnCacheHandler = async () => {
    await resetCache();
    await cachePersistor.persist();
    message.success(t('cacheEnabled'));
  };

  const turnOffCacheHandler = async () => {
    await cachePersistor.purge();
    await apolloClient.cache.reset();
    message.success(t('cacheDisabled'));
  };

  const handleRadioChange = async (e: RadioChangeEvent) => {
    const isOn = e.target.value === CacheState.On;
    setLoading(true);

    if (isOn) {
      await turnOnCacheHandler();
    } else {
      await turnOffCacheHandler();
    }
    setLoading(false);
    cacheState(isOn);
  };

  return (
    <Row align="middle" gutter={20}>
      <Col>{t('cacheState')}</Col>
      <Col>
        <Spin spinning={loading}>
          <Radio.Group
            value={radioValue}
            onChange={handleRadioChange}
            disabled={loading}
          >
            <Radio.Button value={CacheState.On}>{t('on')}</Radio.Button>
            <Radio.Button value={CacheState.Off}>{t('off')}</Radio.Button>
          </Radio.Group>
        </Spin>
      </Col>
      <Col>{cacheState() ? t('cacheWorking') : t('cacheNotWorking')}</Col>
    </Row>
  );
};
