import { Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Spin } from 'modules/common/components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetProductsCountInput,
  GetWoocommerceProductsInput,
  GetWoocommerceTopSellersInput,
  TopSellersPeriod,
  useGetProductsCountQuery,
  useGetWoocommerceCategoriesQuery,
  useGetWoocommerceProductsQuery,
  useGetWoocommerceTopSellersQuery,
} from 'types.d';

import {
  getDropsProductsCountFilter,
  getTopSellersCountFilter,
  getWoocommerceProductsFilter,
  parseTableFilters,
} from './filters';

const ProductsDashboard: FC = () => {
  const { t } = useTranslation('products.ProductsDashboard');
  const [productCountFilter, setProductCountFilter] =
    useState<GetProductsCountInput>({});
  const [topSellersFilter, setTopSellersFilter] =
    useState<GetWoocommerceTopSellersInput>({
      period: TopSellersPeriod.Week,
    });
  const [woocommerceProductsFilter, setWoocommerceProductsFilter] =
    useState<GetWoocommerceProductsInput>({});
  const getProductsCountQuery = useGetProductsCountQuery({
    variables: { input: productCountFilter },
  });
  const getWoocommerceProductsQuery = useGetWoocommerceProductsQuery({
    variables: { input: woocommerceProductsFilter },
  });
  const getWoocommerceTopSellersQuery = useGetWoocommerceTopSellersQuery({
    variables: {
      input: topSellersFilter,
    },
  });
  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
  });
  const dropsProductsCount = getProductsCountQuery.data?.getProductsCount;
  const woocommerceProductsCount =
    getWoocommerceProductsQuery.data?.getWoocommerceProducts.data.reduce(
      (count, product) => {
        return count + (product.stock_quantity || 0);
      },
      0
    );
  const woocommerceProductsTotal =
    getWoocommerceTopSellersQuery.data?.getWoocommerceTopSellers.reduce(
      (total, { quantity }) => total + quantity,
      0
    );
  const dropsProductsCountFilters = getDropsProductsCountFilter(
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories
  );
  const woocommerceProductsFilters = getWoocommerceProductsFilter(
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories
  );
  const topSellersCountFilters = getTopSellersCountFilter();
  const columns: ColumnsType<any> = [
    {
      title: (
        <Spin size="small" spinning={getProductsCountQuery.loading}>
          {t('products')}
        </Spin>
      ),
      render: (value) => value,
      filterMode: 'tree',
      dataIndex: 'dropsProductsCount',
      filterMultiple: true,
      filters: dropsProductsCountFilters,
    },
    {
      title: (
        <Spin size="small" spinning={getWoocommerceProductsQuery.loading}>
          {t('woocommerceProducts', { context: 'inStock' })}
        </Spin>
      ),
      dataIndex: 'woocommerceProducts',
      filterMultiple: false,
      render: (value) => value,
      filters: woocommerceProductsFilters,
    },
    {
      title: (
        <Spin size="small" spinning={getWoocommerceTopSellersQuery.loading}>
          {t('woocommerceProducts', { context: 'sold' })}
        </Spin>
      ),
      dataIndex: 'woocommerceTopSellers',
      render: (value) => value,
      filterMultiple: false,
      filters: topSellersCountFilters,
      defaultFilteredValue: [`period.${TopSellersPeriod.Week}`],
      filterResetToDefaultFilteredValue: true,
    },
  ];

  const dataSource = [
    {
      dropsProductsCount: dropsProductsCount,
      woocommerceProducts: woocommerceProductsCount,
      woocommerceTopSellers: woocommerceProductsTotal,
    },
  ];

  const handleTableChange: TableProps<any>['onChange'] = (
    _pagination,
    filters,
    _sorter,
    _extra
  ) => {
    const parsedTableFilters = parseTableFilters(filters);
    setProductCountFilter({
      ...parsedTableFilters.dropsProductsCount,
      woocommerceCategory:
        parsedTableFilters.dropsProductsCount.woocommerceCategory?.map(Number),
    });
    setTopSellersFilter({
      period: parsedTableFilters.woocommerceTopSellers.period?.[0],
    });
    setWoocommerceProductsFilter({
      category: Number(parsedTableFilters.woocommerceProducts.category?.[0]),
    });
  };

  return (
    <Table
      onChange={handleTableChange}
      columns={columns}
      dataSource={dataSource}
      rowKey={(value) => JSON.stringify(value)}
      pagination={false}
    />
  );
};

export default ProductsDashboard;
