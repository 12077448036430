import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const CopyInfoSection: FC = () => {
  const { t } = useTranslation('donor.RoutineTest.CopyInfoSection');

  return (
    <Row className={styles.copyInfoSection}>
      <span className={styles.label}>{t('whiteCopy').toUpperCase()}:</span>
      <span className={styles.info}>{t('lab').toUpperCase()}</span>
      <span className={styles.label}>{t('yellowCopy').toUpperCase()}:</span>
      <span className={styles.info}>{t('customer').toUpperCase()}</span>
    </Row>
  );
};
