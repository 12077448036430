import { ColumnsType } from 'antd/es/table/Table';
import TeamActionsButtons from 'modules/donor/components/TeamActionsButtons';
import { TeamTitleFormSchema } from 'modules/donor/components/TeamsTable/schema';
import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TeamOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import Header from '../TeamCollapse/components/Header';

const t = getTranslation('donor.GroupsTable');
type Props = {
  isEditMode: boolean;
  onToggleEditMode: (value: boolean) => void;
  form: UseFormReturn<TeamTitleFormSchema>;
  currentTeam: TeamOutput | undefined;
  setCurrentTeam: Dispatch<SetStateAction<TeamOutput | undefined>>;
};

export const getTeamsColumns = ({
  isEditMode,
  onToggleEditMode,
  form,
  currentTeam,
  setCurrentTeam,
}: Props): ColumnsType<TeamOutput> => [
  {
    title: t('teams'),
    render: (row: TeamOutput) => (
      <Header
        isEditMode={isEditMode && row === currentTeam}
        controlled={{ control: form.control, name: 'title' }}
        team={row}
        errorMessage={form.formState.errors.title?.message}
      />
    ),
    align: 'center',
  },
  {
    title: t('total'),
    render: (row: TeamOutput) =>
      `${t('total')} : ${row.donors?.length} ${t('donors').toLowerCase()}`,
    align: 'center',
  },
  {
    title: t('action'),
    render: (row: TeamOutput) => (
      <TeamActionsButtons
        team={row}
        isEditMode={isEditMode && row === currentTeam}
        onToggleEditMode={(value: boolean) => {
          setCurrentTeam(row);
          form.reset({ title: row.title });
          onToggleEditMode(value);
        }}
        form={form}
      />
    ),
    align: 'center',
  },
];
