import {
  CapillaryRefillTime,
  CollectionSet,
  CollectionUnitsUsed,
  DonationUnit,
  DonorOutput,
  FlowRate,
  JugularVein,
  Mm,
  VeinUsed,
  WeatherType,
} from 'types.d';

import { CreateDonationSchemaType } from './schema';

import { DonationPositionUsed } from '../DonationPositionSelect/enum';
import { DonorMovement } from '../DonorMovementSelect/enum';
import { HoldersPosition } from '../HolderPositionSelect/enum';

export const getIneligibleFieldsData = (
  donor: DonorOutput
): CreateDonationSchemaType => {
  return {
    vitalsSection: {
      donationDate: new Date(),
      donor: {
        donorName: donor?.name,
        donorId: donor?.shortId.toString(),
      },
      donationInfo: {
        shouldDonate: false,
        maxLimit: 0,
        collectionSet: CollectionSet.NotApplicable,
        jugularVein: JugularVein.NotApplicable,
        //Removed position from here
        preferences: '',
      },
      environment: {
        weatherTypes: [WeatherType.NotApplicable],
        weatherTemperature: 0,
      },
      quantity: {
        unit: DonationUnit.Unit0,
        actual: 0,
        approvedDonationsMl: 0,
      },
      examination: {
        temperature: 0,
        pulse: 0,
        respiration: 0,
        capillaryRefillTime: CapillaryRefillTime.NotApplicable,
        mucousMembrane: Mm.NotApplicable,
        packedCellVolume: 0,
        totalSolids: 0,
        weight: 0,
        examinationNotes: undefined,
        ineligible: true,
      },
    },
    additionalInfoSection: {
      donationPosition: DonationPositionUsed.NotApplicable,
      donorMovement: DonorMovement.NotApplicable,
      flowRate: FlowRate.NotApplicable,
      holdersPosition: [HoldersPosition.NotApplicable],
      numberOfCollectionUnitsUsed: CollectionUnitsUsed.Zero,
      veinUsed: VeinUsed.NotApplicable,
    },
  };
};
