import { Col, Row, ModalProps } from 'antd';
import { Button } from 'modules/common/components';
import DonorsCsvTemplateDownload from 'modules/donor/components/DonorsCsvTemplateDownload';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  okButtonProps?: ModalProps['okButtonProps'];
  cancelButtonProps?: ModalProps['okButtonProps'];
};

const Footer: FC<PropTypes> = ({ cancelButtonProps, okButtonProps }) => {
  const { t } = useTranslation('donor.CsvDonorsUploadingModal.Footer');

  return (
    <Row gutter={[10, 0]}>
      <Col>
        <DonorsCsvTemplateDownload />
      </Col>
      <Col flex={1} />
      <Col>
        <Button {...cancelButtonProps}>{t('cancel')}</Button>
      </Col>
      <Col>
        <Button {...okButtonProps} type="primary">
          {t('upload')}
        </Button>
      </Col>
    </Row>
  );
};

export default Footer;
