import { Col, Pagination, Row } from 'antd';
import { Checkbox } from 'modules/common/components';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { DonorOutput } from 'types';

import styles from './styles.module.scss';

import GroupDonorCardInfo from '../GroupDonorCardInfo';

type Props = {
  donors: DonorOutput[];
  attachedDonors: DonorOutput[];
  pagination: ReturnType<typeof usePagination>;
  onAttachClickHandler: (donor: DonorOutput) => void;
};

const DonorsTeamList: FC<Props> = ({
  donors,
  attachedDonors,
  pagination,
  onAttachClickHandler,
}) => {
  return (
    <>
      {donors.map((donor) => (
        <Row key={donor.id} className={styles.donorCardItem}>
          <Col span={22}>
            <GroupDonorCardInfo donor={donor as DonorOutput} />
          </Col>
          <Col>
            <Checkbox
              checked={attachedDonors?.some(({ id }) => id === donor.id)}
              onClick={() => onAttachClickHandler(donor)}
            />
          </Col>
        </Row>
      ))}
      <Row justify="end">
        <Col>
          <Pagination {...pagination.config} />
        </Col>
      </Row>
    </>
  );
};

export default DonorsTeamList;
