import InputNumber from 'modules/common/components/InputNumber';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof InputNumber>;

const ControlledInputNumber: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);

  return <InputNumber {...controller.field} {...restProps} />;
};

export default ControlledInputNumber;
