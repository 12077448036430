import {
  PushpinFilled,
  DeleteOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, message } from 'antd';
import config from 'config';
import { format, isEqual } from 'date-fns';
import { ControlledTextArea } from 'modules/common/components';
import { GET_DONORS_NOTES } from 'modules/donor/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  DonorNoteOutput,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { NoteFile } from './NoteFile';
import { CreateNoteSchemaType, createNoteSchema } from './schema';
import styles from './styles.module.scss';

type PropTypes = {
  note: DonorNoteOutput;
  editingNote: string | null;
  setEditingNote: React.Dispatch<React.SetStateAction<string | null>>;
  isPinned?: boolean;
};

const t = getTranslation('donor.EditDonorForm.NoteSection');

export const NoteContent: React.FC<PropTypes> = ({
  note,
  editingNote,
  setEditingNote,
  isPinned = false,
}) => {
  const editNoteForm = useForm<CreateNoteSchemaType>({
    resolver: yupResolver(createNoteSchema),
    mode: 'onChange',
    defaultValues: {
      text: note.text,
    },
  });

  const [updateNote, updateNoteMutation] = useUpdateNoteMutation({
    refetchQueries: [GET_DONORS_NOTES],
  });
  const [deleteNote, deleteNoteMutation] = useDeleteNoteMutation({
    refetchQueries: [GET_DONORS_NOTES],
  });

  const refetchNotes = () => {
    deleteNoteMutation.client.refetchQueries({
      include: [GET_DONORS_NOTES],
    });
  };

  const currentUser = useCurrentUser();
  const canUserEditNote = note.user.id === currentUser.data?.id;
  const isEditCurrentNote = editingNote === note.id;
  const saveButtonDisabled = !editNoteForm.formState.isDirty;

  const editTime = isEqual(new Date(note.createdAt), new Date(note.updatedAt))
    ? null
    : new Date(note.updatedAt);

  const handleSave = editNoteForm.handleSubmit(({ text }) => {
    updateNote({
      variables: {
        input: {
          id: note.id,
          text,
          isPinned: note.isPinned,
        },
      },
      onCompleted() {
        message.success(t('noteUpdating'));
        setEditingNote(null);
      },
      onError() {
        message.error(t('noteUpdatingError'));
      },
    });
  });

  const pinHandler = (noteId: string) => {
    updateNote({
      variables: {
        input: {
          id: noteId,
          isPinned: true,
          text: note.text,
        },
      },
    });
  };

  const unpinHandler = (noteId: string) => {
    updateNote({
      variables: {
        input: {
          id: noteId,
          isPinned: false,
          text: note.text,
        },
      },
    });
  };

  const handleClose = () => {
    setEditingNote(null);
    editNoteForm.reset();
  };

  const handleDelete = () => {
    deleteNote({
      variables: {
        input: {
          id: note.id,
        },
      },
      onCompleted() {
        message.success(t('noteDeleted'));
      },
      onError() {
        message.error(t('noteDeletingError'));
      },
    });
  };

  return (
    <Card bodyStyle={{ padding: 8 }}>
      <form>
        <div className={styles.pin}>
          {isPinned ? (
            <PushpinFilled
              onClick={() => unpinHandler(note.id)}
              disabled={updateNoteMutation.loading}
            />
          ) : (
            <PushpinOutlined
              onClick={() => pinHandler(note.id)}
              disabled={updateNoteMutation.loading}
            />
          )}
        </div>
        <div className={styles.noteHeader}>
          <div>
            <div className={styles.noteInfo}>
              <p className={styles.noteAuthor}>{note.user.firstName}</p>
              <p className={styles.noteDate}>
                {format(new Date(note.createdAt), config.DATE_AND_TIME_FORMAT)}
              </p>
            </div>
            {editTime && (
              <p className={`${styles.noteDate} ${styles.editDate}`}>
                {t('noteEditedTime', {
                  time: format(editTime, config.DATE_AND_TIME_FORMAT),
                })}
              </p>
            )}
          </div>
        </div>
        {isEditCurrentNote ? (
          <ControlledTextArea
            controlled={{
              name: 'text',
              control: editNoteForm.control,
            }}
            maxLength={2000}
            className={styles.commentArea}
          />
        ) : (
          note.text
        )}
        {note.files.map((file) => {
          return (
            <NoteFile
              filename={file.file.filename}
              id={file.file.id}
              noteFileId={file.id}
              canDelete={isEditCurrentNote}
              refetchNotes={refetchNotes}
            />
          );
        })}
        {canUserEditNote && (
          <div className={styles.noteEdit}>
            {isEditCurrentNote ? (
              <div className={styles.editNoteBtns}>
                <Button type="link" onClick={handleClose}>
                  {t('close')}
                </Button>
                <Button
                  type="link"
                  onClick={handleSave}
                  disabled={saveButtonDisabled}
                  loading={updateNoteMutation.loading}
                >
                  {t('save')}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  type="link"
                  danger
                  onClick={handleDelete}
                  loading={deleteNoteMutation.loading}
                >
                  <DeleteOutlined />
                </Button>
                <Button
                  type="link"
                  onClick={() => setEditingNote(note.id)}
                  loading={deleteNoteMutation.loading}
                >
                  {t('edit')}
                </Button>
              </div>
            )}
          </div>
        )}
      </form>
    </Card>
  );
};
