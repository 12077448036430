import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import { SortOrder } from 'antd/lib/table/interface';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Link } from 'modules/common/components';
import { ISortOption } from 'modules/common/interfaces';
import {
  UserOutput,
  UserListSort,
  OwnerType,
  Order,
  OwnersListFilterInput,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import { shortenTextWithEllipsis } from 'utils/shortenTextWithEllipsis';

const t = getTranslation('owner.OwnerListPage');

const ownerTypes = Object.values(OwnerType);

type ColumnsProps = {
  showDeletedAtColumn?: boolean;
  showSorterTooltip?: boolean;
  sort: ISortOption<UserListSort>;
  filters: OwnersListFilterInput;
};

export const getColumns = ({
  showDeletedAtColumn: showDeletedColumn,
  showSorterTooltip,
  filters,
  sort,
}: ColumnsProps): ColumnsType<UserOutput> => {
  const getDefaultSort = (fieldName: UserListSort): SortOrder | undefined => {
    if (sort?.fieldName === fieldName) {
      return sort.order === Order.Asc ? 'ascend' : 'descend';
    }
    return undefined;
  };

  const defaultColumns = [
    {
      title: t('firstName'),
      render: (row: UserOutput) => row.firstName,
      key: UserListSort.FirstName,
      sorter: true,
      sortOrder: getDefaultSort(UserListSort.FirstName),
      showSorterTooltip,
      align: 'center',
    },
    {
      title: t('lastName'),
      render: (row: UserOutput) => (
        <Link to={`${Routes.Owners}/${row.shortId}`}>{row.lastName}</Link>
      ),
      key: UserListSort.LastName,
      sorter: true,
      sortOrder: getDefaultSort(UserListSort.LastName),
      showSorterTooltip,
      align: 'center',
    },
    {
      title: t('ownerType'),
      key: 'ownerTypes',
      render: (row: UserOutput) => t(row.type!.toLocaleLowerCase()),
      align: 'center',
      filteredValue: filters?.ownerTypes || null,
      filters: ownerTypes.map((type) => {
        return {
          text: t(type.toLowerCase()),
          value: type,
        };
      }),
    },
    {
      title: t('email'),
      render: (row: UserOutput) => row.email,
      align: 'center',
    },
    {
      title: t('phoneNumber'),
      render: (row: UserOutput) => row.phoneNumber,
      align: 'center',
    },
    {
      key: UserListSort.LocationPreferences,
      title: t('locationPreferences'),
      render: (row: UserOutput) => {
        return row.locationPreferences ? (
          <Tooltip title={row.locationPreferences}>
            {shortenTextWithEllipsis(row.locationPreferences, 30)}
          </Tooltip>
        ) : (
          '-'
        );
      },
      align: 'center',
      sorter: true,
      sortOrder: getDefaultSort(UserListSort.LocationPreferences),
      showSorterTooltip,
    },
  ] as ColumnsType<UserOutput>;

  const deletedAtColumn = {
    title: t('deletedAt'),
    render: (row: UserOutput) =>
      format(new Date(row.deletedAt), config.DATE_AND_TIME_FORMAT) || '-',
    align: 'center',
  };

  const columns = !showDeletedColumn
    ? defaultColumns
    : ([...defaultColumns, deletedAtColumn] as ColumnsType<UserOutput>);

  return columns;
};
