import { weatherTypesSchema } from 'modules/donations/schemas';
import { WeatherType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donation.CreateDonationForm.Environment');

type EnvironmentSchemaType = {
  weatherTemperature: number;
  weatherTypes: WeatherType[];
};

const environmentSchema: yup.SchemaOf<EnvironmentSchemaType> = yup.object({
  weatherTemperature: yup
    .number()
    .min(0, t('invalid', { field: t('weatherTemperature') }))
    .max(125, t('invalid', { field: t('weatherTemperature') }))
    .typeError(t('invalid', { field: t('weatherTemperature') }))
    .required(t('required', { field: t('weatherTemperature') })),
  weatherTypes: weatherTypesSchema,
});

export { environmentSchema };
export type { EnvironmentSchemaType };
