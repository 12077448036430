import { Row, Col } from 'antd';
import {
  FormItem,
  ControlledInput,
  ControlledTextArea,
} from 'modules/common/components';
import {
  ControlledYesNoRadioGroup,
  DonorStatusSelect,
} from 'modules/donor/components';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DonorStatus, RetiredReasons } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { PetChipField } from './fields';

import DonorRetiredReasonSelect from '../../DonorRetiredReasonSelect';

const t = getTranslation('donor.EditDonorForm.DonorStatusSection');

type PropTypes = {
  fieldNamePrefix: string;
  disabled?: boolean;
  canChangeDonorStatus?: boolean;
};

const DonorStatusSection: FC<PropTypes> = ({
  fieldNamePrefix,
  disabled,
  canChangeDonorStatus,
}) => {
  const { control, watch, formState, setValue } = useFormContext();
  const getFieldName = (fieldName: PetChipField) => {
    return `${fieldNamePrefix}.${fieldName}`;
  };
  const hasMicrochip = watch(getFieldName(PetChipField.hasMicrochip)) as
    | boolean
    | undefined;
  const microchipNumber = watch(getFieldName(PetChipField.chipNo)) as
    | number
    | undefined;
  const donorStatus = watch(getFieldName(PetChipField.status));
  const retiredReason = watch(getFieldName(PetChipField.RetiredReason));
  const isRetired = donorStatus === DonorStatus.Retired;

  const microchipNumberErrorMessage =
    formState.errors?.donorStatus?.microchipNumber?.message;

  useEffect(() => {
    if (!isRetired) {
      setValue(getFieldName(PetChipField.RetiredReason), null);
      setValue(getFieldName(PetChipField.RetiredClarification), null);
    }
  }, [donorStatus]);

  return (
    <Row>
      <Col span={24}>
        {canChangeDonorStatus && (
          <FormItem label={t('status')}>
            <DonorStatusSelect
              controlled={{ control, name: getFieldName(PetChipField.status) }}
              disabled={disabled}
            />
          </FormItem>
        )}
      </Col>
      <Col span={24}>
        {isRetired && (
          <FormItem label={t('retirementReason')}>
            <DonorRetiredReasonSelect
              controlled={{
                control,
                name: getFieldName(PetChipField.RetiredReason),
              }}
              disabled={disabled}
            />
          </FormItem>
        )}
        {isRetired && retiredReason === RetiredReasons.Other && (
          <FormItem label={t('retiredClarification')}>
            <ControlledTextArea
              maxLength={250}
              disabled={disabled}
              controlled={{
                control,
                name: getFieldName(PetChipField.RetiredClarification),
              }}
            />
          </FormItem>
        )}
      </Col>
      <Col span={24}>
        <FormItem label={t('hasMicrochip')}>
          <ControlledYesNoRadioGroup
            controlled={{
              control,
              name: getFieldName(PetChipField.hasMicrochip),
            }}
            disabled={disabled}
          />
        </FormItem>
      </Col>
      {(hasMicrochip || microchipNumber) && (
        <Col span={24}>
          <FormItem
            label={t('microchipNo')}
            extra={microchipNumberErrorMessage}
            validateStatus={
              microchipNumberErrorMessage ? 'error' : 'validating'
            }
          >
            <ControlledInput
              controlled={{ control, name: getFieldName(PetChipField.chipNo) }}
              disabled={disabled}
            />
          </FormItem>
        </Col>
      )}
    </Row>
  );
};

export default DonorStatusSection;
