import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Row, Col, Divider } from 'antd';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { Routes } from 'config/routes';
import {
  FormItem,
  Spin,
  Alert,
  Button,
  ControlledCheckbox,
} from 'modules/common/components';
import { useLocationState } from 'modules/common/hooks';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from 'types.d';

import { signInFormFields } from './fields';
import { signInSchema, SignInSchemaType } from './schema';
import styles from './styles.module.scss';

const { ACCESS_TOKEN, REFRESH_TOKEN } = LOCALE_STORAGE_KEYS;

const SignInForm: FC = () => {
  const { t } = useTranslation('auth.SignInForm');
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const locationState = useLocationState();
  const currentUser = useCurrentUser();
  const [login, loginMutation] = useLoginMutation({
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });
  const signInForm = useForm<SignInSchemaType>({
    resolver: yupResolver(signInSchema),
    mode: 'onChange',
    defaultValues: {
      durable: false,
    },
  });
  const { control, formState, handleSubmit } = signInForm;

  const submitForm = handleSubmit(async (formData) => {
    const { data } = await login({
      variables: {
        input: {
          ...formData,
        },
      },
    });
    if (data?.login) {
      const { accessToken, refreshToken } = data?.login;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      await currentUser.refetchData();
      navigate(locationState.data?.redirectionPath || Routes.Profile, {
        replace: true,
      });
    }
  });

  useEffect(() => locationState.clearLocationState, []);

  return (
    <Spin spinning={loginMutation.loading || currentUser.isLoading}>
      <Row>
        {errorMessage && (
          <Col span={24}>
            <Alert type="error" message={errorMessage} />
          </Col>
        )}
        <Col span={24}>
          <Form>
            {signInFormFields.map(
              ({
                label,
                name,
                placeholder,
                type,
                Component,
                errorsEnabled,
              }) => {
                const errorMessage =
                  errorsEnabled && formState.errors[name]?.message;
                const validateStatus =
                  errorsEnabled && errorMessage ? 'error' : undefined;
                return (
                  <FormItem
                    key={name}
                    label={label}
                    extra={errorMessage}
                    validateStatus={validateStatus}
                  >
                    <Component
                      type={type}
                      controlled={{ control, name }}
                      placeholder={placeholder}
                    />
                  </FormItem>
                );
              }
            )}
            <Row gutter={[0, 10]} justify="space-between" align="middle">
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <ControlledCheckbox
                      controlled={{ control, name: 'durable' }}
                    >
                      {t('rememberMe')}
                    </ControlledCheckbox>
                  </Col>
                  <Col>
                    <Link to={Routes.ForgotPassword}>
                      {t('forgotPassword')}
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Button
                  onClick={submitForm}
                  type="primary"
                  htmlType="submit"
                  className={styles.signInButton}
                >
                  {t('login')}
                </Button>
              </Col>
              <Col span={24} className={styles.textCentered}>
                <Divider className={styles.divider} plain>
                  or
                </Divider>
                {t('doNotHaveAnAccount')}
              </Col>
              <Col span={24}>
                <Link to={Routes.SignUp}>
                  <Button className={styles.signUpButton} type="primary">
                    {t('signUp')}
                  </Button>
                </Link>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col>
                    <Link to={Routes.ResendConfirmationCode}>
                      {t('resendEmailConfirmationCode')}
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default SignInForm;
