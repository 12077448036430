import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { CreateTeamFields } from './fields';

const { title } = CreateTeamFields;

const t = getTranslation('donor.CreateTeamModal');

type CreateTeamSchemaType = {
  title: string;
};

const createTeamSchema: yup.SchemaOf<CreateTeamSchemaType> = yup.object({
  [title]: yup
    .string()
    .required(t('required', { field: t('title') }))
    .matches(/^\S.*\S$|^\S$/, t('noSpacesAtEdgesMessage'))
    .max(255, t('lessThan', { max: 255 })),
});
export { createTeamSchema };
export type { CreateTeamSchemaType };
