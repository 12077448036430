import { CheckboxOptionType } from 'antd';
import { ControlledRadioGroup } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = Omit<ComponentProps<typeof ControlledRadioGroup>, 'options'>;

const ContactPreferences: FC<PropTypes> = (props) => {
  const { t } = useTranslation('owner.ContactPreferences');

  const contactMethods: CheckboxOptionType[] = [
    {
      value: 'phone',
      label: t('phone'),
    },
    {
      value: 'email',
      label: t('email'),
    },
    {
      value: 'text',
      label: t('text'),
    },
  ];
  return <ControlledRadioGroup options={contactMethods} {...props} />;
};

export default ContactPreferences;
