export type DefaultClinicParams = {
  clinicName: string;
  clinicAddress: string;
  CityStateZip: string;
  telephone: string;
  email: string;
  cornelAHDC: string;
  specimen: string;
};

export const defaultClinicParams: DefaultClinicParams = {
  CityStateZip: 'Manassas, VA 20110',
  clinicAddress: '9431 Main St',
  clinicName: 'NAVBB',
  telephone: ' 703-574-7417',
  email: 'labwork@navbb.com',
  cornelAHDC: '364871',
  specimen: 'whole blood, edta',
};
