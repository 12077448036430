export async function getOnlineStatus(): Promise<boolean> {
  if (!window.navigator.onLine) return false;
  const url = new URL(window.location.origin);
  url.searchParams.set('rand', `${new Date().getTime()}`);

  try {
    const response = await fetch(url.toString(), { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
}
