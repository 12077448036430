import { Col } from 'antd';
import AppointmentTitleSelect from 'modules/appointments/components/AppointmentTitleSelect';
import { FormItem } from 'modules/common/components';
import { ComponentProps, FC, useEffect } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

const FIELD_NAME = AppointmentFormField.Title;

type PropTypes = {
  control: Control<CreateAppointmentValidationSchema, any>;
} & ComponentProps<typeof FormItem>;

const TitleSelect: FC<PropTypes> = ({ control, ...restFormItemProps }) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const formContext = useFormContext<CreateAppointmentValidationSchema>();
  const appointmentType = formContext.watch('type');
  const errorMessage = formContext.formState.errors[FIELD_NAME]?.message;

  useEffect(() => {
    formContext.resetField(FIELD_NAME);
  }, [appointmentType]);

  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('title')}
        extra={errorMessage}
        validateStatus={errorMessage && 'error'}
        {...restFormItemProps}
      >
        <AppointmentTitleSelect
          appointmentType={appointmentType}
          controlled={{ control, name: AppointmentFormField.Title }}
        />
      </FormItem>
    </Col>
  );
};

export default TitleSelect;
