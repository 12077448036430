import { DonorOutput, GroupOutput } from 'types.d';

export type CreateTeamModalPropTypes = {
  groupId?: string;
};

export type AddDonationCommentProps = {
  donationId: string;
  previousValue: string;
};

export type AttachDonorsModalPropTypes = {
  teamId: string;
};

export type LabworkAttachmentsModalPropTypes = {
  labworkId?: string;
};

export type AddDonorNoteModalProps = {
  noteId?: string;
  previousValue?: string;
  donorId?: string;
};

export type UpdateDonorsOwnerModalPropTypes = {
  donor?: DonorOutput;
};

export type UpdateGroupsOwnerModalPropTypes = {
  group?: GroupOutput;
};

export enum DonationDateFilters {
  from0to24daysFilter = 'from0to24daysFilter',
  from24to29daysFilter = 'from24to29daysFilter',
  moreThan30daysFilter = 'moreThan30daysFilter',
}
