import { Col, Row } from 'antd';
import { ControlledInput } from 'modules/common/components';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { TeamOutput } from 'types';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any, string>;
  team: TeamOutput;
  isEditMode?: boolean;
  errorMessage?: string;
};

const Header: FC<PropTypes> = ({
  isEditMode,
  controlled,
  team,
  errorMessage,
}) => {
  return isEditMode ? (
    <Row className={styles.inputWrapper}>
      <Col span={24}>
        <ControlledInput
          status={errorMessage && 'error'}
          placeholder={team.title}
          className={styles.input}
          controlled={controlled}
        />
      </Col>
      <Col span={24}>{errorMessage}</Col>
    </Row>
  ) : (
    <>{`${team.title} (${team.donors?.length})`}</>
  );
};

export default Header;
