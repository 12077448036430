import { Col } from 'antd';
import { ColumnsType } from 'antd/es/table/Table';
import { FormItem, BadgeAsterisk } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import styles from 'modules/donations/components/CreateDonationForm/styles.module.scss';
import { UseFormReturn } from 'react-hook-form';
import { getTranslation } from 'utils/getTranslation';

import { IDonationInfoTableData } from './interfaces';
import { donationInfoSchema } from './schema';

const t = getTranslation('donation.CreateDonationForm.DonationInfo');

export const getColumns = (
  formContext: UseFormReturn,
  fieldNamePrefix: string,
  sectionNamePrefix?: string
): ColumnsType<IDonationInfoTableData> => {
  const isRequired = getIsRequiredInSchema(donationInfoSchema);

  return [
    {
      title: t('question'),
      align: 'center',
      render: (row: IDonationInfoTableData) => <Col>{t(row.question)}</Col>,
    },
    {
      title: t('answer'),
      align: 'center',
      render: (row: IDonationInfoTableData) => {
        const { name, Component } = row.answer;
        const sectionName = sectionNamePrefix ? sectionNamePrefix + '.' : '';
        const fieldName = `${sectionName}${fieldNamePrefix}.${name}`;
        const sectionErrors = sectionNamePrefix
          ? formContext.formState.errors[sectionNamePrefix]
          : formContext.formState.errors;
        const errorMessage = sectionErrors?.[fieldNamePrefix]?.[name]?.message;

        return (
          <Col key={name}>
            <FormItem
              className={`${styles.formItem} ${styles.selectInCell}`}
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
            >
              <BadgeAsterisk hidden={!isRequired(name)}>
                <Component control={formContext.control} name={fieldName} />
              </BadgeAsterisk>
            </FormItem>
          </Col>
        );
      },
    },
  ];
};
