import { Select } from 'modules/common/components';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppointmentType } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
};

export const TypeSelect: FC<PropTypes> = ({ controlled }) => {
  const controller = useController(controlled);
  const { t } = useTranslation('appointments.AppointmentTypeSelect');

  const options = Object.values(AppointmentType).map((value) => ({
    value,
    label: t(value.toLowerCase()),
  }));

  return (
    <Select
      className={styles.typeSelect}
      options={options}
      placeholder={t('selectType')}
      allowClear
      {...controller.field}
    />
  );
};
