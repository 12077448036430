import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const RecurringDateContext = createContext<ContextType | null>(null);

const RecurringDateProvider: ComponentWithChildren = ({ children }) => {
  const providerValue = useProviderValue();

  return (
    <RecurringDateContext.Provider value={providerValue}>
      {children}
    </RecurringDateContext.Provider>
  );
};

export const useRecurringDateContext = () => {
  const context = useContext(RecurringDateContext);
  if (context === undefined) {
    throw new Error(
      'the useRecurringDateContext should be used within RecurringDateProvider!'
    );
  }

  return context;
};

export default RecurringDateProvider;
