import { ControlledTextArea } from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

enum AddDonorNoteFormFields {
  text = 'text',
}

const t = getTranslation('donor.AddDonorNoteModal');

const addDonorNoteFormFields: FormFieldWithComponent<AddDonorNoteFormFields>[] =
  [
    {
      name: AddDonorNoteFormFields.text,
      label: t(AddDonorNoteFormFields.text),
      Component: ({ control }) => (
        <ControlledTextArea
          controlled={{ control, name: AddDonorNoteFormFields.text }}
          placeholder={t('enterNote')}
          size={'middle'}
        />
      ),
    },
  ];

export { addDonorNoteFormFields, AddDonorNoteFormFields };
