import { Row, Col } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Link } from 'modules/common/components';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DonorOutput } from 'types.d';

import styles from './styles.module.scss';

type Option = {
  label: string;
  value: string | ReactNode;
};

type PropTypes = {
  donor: DonorOutput;
};

const DonorCardInfo: FC<PropTypes> = ({ donor }) => {
  const { t } = useTranslation('donor.DonorCardInfo');

  const options: Option[] = [
    { label: t('id'), value: `${donor.shortId}` },
    {
      label: t('lastName'),
      value: donor.user ? (
        <Link
          to={`${Routes.EditOwner.replace(':id', String(donor.user.shortId))}`}
        >
          {donor.user.lastName}
        </Link>
      ) : (
        ''
      ),
    },
    {
      label: t('bloodType'),
      value: `${t(donor.bloodType?.toLowerCase() || '')}`,
    },
    {
      label: t('status'),
      value: t(donor.status.charAt(0).toLowerCase() + donor.status.slice(1)),
    },
    {
      label: t('donationDate'),
      value: donor.lastDonationDate
        ? format(new Date(donor.lastDonationDate), config.DATE_AND_TIME_FORMAT)
        : '-',
    },
    {
      label: t('location'),
      value: donor.location,
    },
  ];
  return (
    <Row gutter={[10, 0]}>
      {options.map(({ label, value }, i) => {
        return (
          <Col span={24} key={`${donor?.id}_${i}`}>
            <Row gutter={[10, 0]}>
              <Col span={12} className={styles.optionLabel}>
                {label}:
              </Col>
              <Col span={12}>{value}</Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};
export default DonorCardInfo;
