import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button, message } from 'antd';
import { Routes } from 'config/routes';
import { FormItem, ControlledInput, Spin } from 'modules/common/components';
import { FORGOT_PASSWORD_MUTATION } from 'modules/user/graphql/mutations';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MutationForgotPasswordArgs, Mutation } from 'types.d';

import { ResetPasswordSchema, resetPasswordSchema } from './schema';

const ForgotPasswordForm: FC = () => {
  const { t } = useTranslation('user.ForgotPasswordForm');
  const navigate = useNavigate();
  const [forgotPassword, forgotPasswordMutation] = useMutation<
    Pick<Mutation, 'forgotPassword'>,
    MutationForgotPasswordArgs
  >(FORGOT_PASSWORD_MUTATION, {
    onCompleted: () => {
      message.success(t('linkSuccessfullySent'));
      navigate(Routes.Login);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const { control, formState, handleSubmit } = useForm<ResetPasswordSchema>({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange',
  });
  const disabledSubmitButton =
    !formState.isValid || Boolean(forgotPasswordMutation.data);

  const submitForm = handleSubmit((formData) => {
    forgotPassword({
      variables: {
        input: formData,
      },
    });
  });

  useEffect(() => {
    return () => {
      forgotPasswordMutation.reset();
    };
  }, []);

  return (
    <Spin spinning={forgotPasswordMutation.loading}>
      <Form>
        <FormItem
          label={t('email')}
          validateStatus={formState.errors.email?.message && 'error'}
          extra={formState.errors.email?.message}
        >
          <ControlledInput
            placeholder={t('enterEmail')}
            controlled={{ control, name: 'email' }}
          />
        </FormItem>
        <Button
          disabled={disabledSubmitButton}
          onClick={submitForm}
          type="primary"
        >
          {t('sendLink')}
        </Button>
      </Form>
    </Spin>
  );
};

export default ForgotPasswordForm;
