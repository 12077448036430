import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal, Popconfirm, message } from 'antd';
import { Spin, Button, FormItem } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { FIND_GROUP_QUERY } from 'modules/donor/graphql/queries';
import { UpdateGroupsOwnerModalPropTypes } from 'modules/donor/types';
import UserSelect from 'modules/user/components/UserSelect';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OwnerType, UserRole, useUpdateGroupOwnerMutation } from 'types.d';

import { updateDonorsOwnerSchema, IUpdateDonorsOwner } from './schema';

type PropTypes = UpdateGroupsOwnerModalPropTypes & BaseModalProps;

const UpdateGroupOwnerModal: FC<PropTypes> = ({ group, visible, hide }) => {
  const { t } = useTranslation('donor.UpdateGroupOwnerModal');
  const placeholder = group?.user
    ? `${group?.user?.firstName} ${group?.user?.lastName}`
    : t('selectOrSearchOwner');
  const [updateGroupOwner, updateDonorsOwnerMutation] =
    useUpdateGroupOwnerMutation({
      refetchQueries: [
        {
          query: FIND_GROUP_QUERY,
          variables: {
            input: {
              id: group?.id,
            },
          },
        },
      ],
      update(cache) {
        cache.evict({ fieldName: 'getUserDonorsList' });
      },
      onCompleted: () => {
        message.success(t('ownerChanged'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  const isLoading = updateDonorsOwnerMutation.loading;
  const { control, handleSubmit, formState, reset, watch, setValue } =
    useForm<IUpdateDonorsOwner>({
      resolver: yupResolver(updateDonorsOwnerSchema),
      mode: 'onChange',
      defaultValues: {
        id: group?.id,
      },
    });
  const selectedOwnerId = watch('ownerId');
  const isDisabledOkButton =
    selectedOwnerId === group?.user?.id ||
    isLoading ||
    !formState.isDirty ||
    !formState.isValid;

  const submitForm = handleSubmit(async (formData) => {
    await updateGroupOwner({
      variables: {
        input: formData,
      },
    });
  });

  const okHandler = async () => {
    await submitForm();
    reset();
    hide();
  };

  const cancelHandler = () => {
    hide();
    reset();
  };

  useEffect(() => {
    if (group?.id) {
      setValue('id', group.id);
    }
  }, [group?.id, visible]);

  return (
    <Spin spinning={isLoading}>
      <Modal
        title={t('updateOwner')}
        visible={visible}
        onCancel={cancelHandler}
        footer={
          <>
            <Button onClick={cancelHandler}> {t('cancel')}</Button>
            <Popconfirm
              disabled={isDisabledOkButton}
              title={t('areYouSureSaveEdits')}
              onConfirm={okHandler}
            >
              <Button disabled={isDisabledOkButton} type="primary">
                {t('save')}
              </Button>
            </Popconfirm>
          </>
        }
      >
        <Form>
          <FormItem label={t('selectNewOwner')}>
            <UserSelect
              allowClear
              placeholder={placeholder}
              userRoles={[UserRole.Owner]}
              ownerTypes={[OwnerType.Individual]}
              controlled={{ control, name: 'ownerId' }}
            />
          </FormItem>
        </Form>
      </Modal>
    </Spin>
  );
};

export default UpdateGroupOwnerModal;
