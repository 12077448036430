import {
  bloodTypeSchema,
  petMarksSchema,
  donorTypeSchema,
  colorSchema,
  breedSchema,
  petApprovedDonationMLSchema,
} from 'modules/donor/schemas';
import { BloodType, DonorType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { OptionalFormSectionField } from './fields';

const t = getTranslation('donor.ValidationErrors');
const {
  Marks,
  BloodType: bloodType,
  Breed,
  DonorType: donorType,
  Team,
  Color,
  RabiesDate,
  RetiredDate,
  DistemperDate,
  IsDaycare,
  DonationMLApprovedAmount,
  ApprovedDonationML,
  AttendDaycareDays,
  Comment,
  WontRegularlyAttendDaycare,
} = OptionalFormSectionField;

type OptionalFormSectionSchemaType = {
  distinguishableMarks?: string;
  bloodType?: BloodType;
  donorType?: DonorType;
  breed?: string | null;
  team?: string;
  color?: string | null;
  rabiesDate?: Date | null;
  distemperDate?: Date | null;
  retiredDate?: Date | null;
  isDaycare?: boolean;
  donationMLApprovedAmount?: number | null;
  approvedDonationML?: number;
  wontRegularlyAttendDaycare?: boolean | null;
  attendDaycareDays?: string[] | null;
  comment?: string | null;
};

const optionalFormSectionSchema: yup.SchemaOf<OptionalFormSectionSchemaType> =
  yup.object({
    [Marks]: petMarksSchema,
    [bloodType]: bloodTypeSchema,
    [donorType]: donorTypeSchema,
    [Breed]: breedSchema,
    [Team]: yup.string().when([donorType], {
      is: DonorType.Grouped,
      then: () => yup.string().required(t('required', { field: t('team') })),
    }),
    [Color]: colorSchema,
    [RabiesDate]: yup.date().nullable(),
    [RetiredDate]: yup.date().nullable(),
    [DistemperDate]: yup.date().nullable(),
    [IsDaycare]: yup.boolean(),
    [DonationMLApprovedAmount]: yup
      .number()
      .nullable()
      .oneOf([250, 420, 450, null])
      .optional(),
    [ApprovedDonationML]: petApprovedDonationMLSchema,
    [WontRegularlyAttendDaycare]: yup.boolean().optional().nullable(),
    [AttendDaycareDays]: yup.array().of(yup.string().required()).optional(),
    [Comment]: yup
      .string()
      .max(255, t('shorterThan', { max: 255, field: t('comment') }))
      .nullable(),
  });

export { optionalFormSectionSchema };
export type { OptionalFormSectionSchemaType };
