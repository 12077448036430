import { Statistic } from 'antd';
import { FC } from 'react';

const { Countdown } = Statistic;

type PropTypes = {
  deadline?: number;
  onComplete?: () => void;
  className?: string;
};

const Cooldown: FC<PropTypes> = ({
  deadline = Date.now(),
  onComplete,
  className,
}) => {
  return (
    <Countdown
      className={className}
      value={deadline}
      onFinish={onComplete}
      format="s"
    />
  );
};

export default Cooldown;
