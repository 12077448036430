import { ColumnsType } from 'antd/lib/table';
import { CheckCell } from 'modules/common/components';
import { UserRole, PermissionsEnum, PermissionsOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('admin.PermissionsPage');

type DataSourceType = {
  permissionName: PermissionsEnum;
};

const { CollectionTeam, DogHandler, MedicalDirector, InventoryTeam } = UserRole;

export const dataSource: DataSourceType[] = Object.values(PermissionsEnum).map(
  (permissionName) => ({
    permissionName,
  })
);

type ClickCellValueType = {
  permissionId: string;
  value: boolean;
};

type GetColumnsPropTypes = {
  onCellClick: (value: ClickCellValueType) => void;
  permissionsList: PermissionsOutput[];
};

const columnsToDisplay = [
  InventoryTeam,
  CollectionTeam,
  DogHandler,
  MedicalDirector,
];

export const getColumns = ({
  onCellClick,
  permissionsList,
}: GetColumnsPropTypes): ColumnsType<DataSourceType> => {
  const columns: ColumnsType<DataSourceType> = columnsToDisplay.map(
    (userRole) => {
      return {
        title: t(userRole.charAt(0).toLowerCase() + userRole.slice(1)),
        render: ({ permissionName }: DataSourceType) => {
          const rolePermission = permissionsList.find(
            (p) => p.role.value === userRole && p.permission === permissionName
          );
          const showTick = Boolean(rolePermission?.value);
          const onCLickHandler = () => {
            onCellClick({
              permissionId: rolePermission?.id || '',
              value: !showTick,
            });
          };
          return <CheckCell showTick={showTick} onClick={onCLickHandler} />;
        },
        align: 'center',
      };
    }
  );
  return [
    {
      title: t('permissions'),
      render: ({ permissionName }) => t(permissionName),
      align: 'center',
    },
    ...columns,
  ];
};
