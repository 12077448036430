export default {
  SignInForm: {
    email: 'Email',
    enterEmail: 'Enter email',
    password: 'Password',
    enterPassword: 'Enter password',
    login: 'Login',
    doNotHaveAnAccount: 'Do not have an account?',
    rememberMe: 'Remember me for 30 days',
    forgotPassword: 'Forgot password',
    signUp: 'Sign up',
    resendEmailConfirmationCode: 'Resend email confirmation code',
  },
  SignInPage: {
    signIn: 'Sign In',
  },
  ResendEmailConfirmationCodeForm: {
    email: 'Email',
    resend: 'Resend',
    goBackToLogin: 'Go back to log in',
    codeHasBeenResent: 'Code has been successfully resent!',
  },
  ResendConfirmationCodePage: {
    resendConfirmationCode: 'Resend email confirmation code',
  },
};
