import { Col } from 'antd';
import { FormItem } from 'modules/common/components';
import ControlledTeamSelect from 'modules/donor/components/ControlledTeamSelect';
import { ComponentProps, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import { AppointmentFormField } from '../fields';

type PropTypes = {
  canAddDonorToAppointment?: boolean;
} & ComponentProps<typeof FormItem>;

const FIELD_NAME = AppointmentFormField.TeamId;

const TeamSelect: FC<PropTypes> = ({
  canAddDonorToAppointment,
  ...restFormItemProps
}) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const { control, formState, watch } =
    useFormContext<CreateAppointmentValidationSchema>();
  const selectedGroup = watch(AppointmentFormField.SelectedGroup) as any;
  const errorMessage = formState.errors.teamId?.message;

  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('team')}
        extra={errorMessage}
        validateStatus={errorMessage && 'error'}
        {...restFormItemProps}
      >
        <ControlledTeamSelect
          allowClear
          groupId={selectedGroup?.value}
          controlled={{ control, name: FIELD_NAME }}
        />
      </FormItem>
    </Col>
  );
};

export default TeamSelect;
