import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal, message, Popconfirm } from 'antd';
import { FormItem, Spin, Button } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { showGraphqlErrorMessage } from 'modules/common/utils/showGraphqlErrorMessage';
import { addDonorNoteFormFields } from 'modules/donor/components/AddDonorNoteModal/fields';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useAddNoteToReserveDonorMutation,
  useUpdateReserveDonorNoteMutation,
} from 'types.d';

import { AddDonorNoteSchemaType, addDonorNoteSchema } from './schema';

type Props = BaseModalProps & {
  noteId?: string;
  previousValue?: string;
  donorId?: string;
};

const AddDonorNoteModal: FC<Props> = ({
  visible,
  hide,
  previousValue,
  noteId,
  donorId,
}) => {
  const { t } = useTranslation('donor.AddDonorNoteModal');

  const { control, handleSubmit, formState, reset } =
    useForm<AddDonorNoteSchemaType>({
      resolver: yupResolver(addDonorNoteSchema),
      mode: 'onChange',
    });

  const [addDonorNote, { loading: addDonorNoteLoading }] =
    useAddNoteToReserveDonorMutation({
      refetchQueries: ['getDonorsList'],
      onCompleted: () => {
        message.success(t('noteSaved'));
        reset();
      },
      onError: showGraphqlErrorMessage,
    });

  const [updateDonorNote, { loading: updateDonorNoteLoading }] =
    useUpdateReserveDonorNoteMutation({
      refetchQueries: ['getDonorsList'],
      onCompleted: () => {
        message.success(t('noteUpdated'));
        reset();
      },
      onError: showGraphqlErrorMessage,
    });

  const submitForm = handleSubmit((formData) => {
    noteId
      ? updateDonorNote({
          variables: {
            input: {
              id: noteId,
              text: formData.text,
            },
          },
        })
      : addDonorNote({
          variables: {
            input: {
              donorId: donorId || '',
              text: formData.text,
            },
          },
        });
  });

  const okHandler = async () => {
    await submitForm();
    reset();
    hide();
  };

  const cancelHandler = () => {
    hide();
    reset();
  };

  useEffect(() => {
    reset({ text: previousValue });
  }, [previousValue]);

  const loading = addDonorNoteLoading || updateDonorNoteLoading;

  return (
    <Spin spinning={loading}>
      <Modal
        title={t('addDonorNoteModal')}
        visible={visible}
        onCancel={cancelHandler}
        footer={[
          <Button onClick={cancelHandler}> {t('cancel')}</Button>,
          <Popconfirm
            disabled={!formState.isValid || loading}
            title={t('areYouSureSaveEdits')}
            onConfirm={okHandler}
          >
            <Button disabled={!formState.isValid || loading} type="primary">
              {t('save')}
            </Button>
          </Popconfirm>,
        ]}
      >
        <Form>
          {addDonorNoteFormFields.map(({ Component, label, name }) => {
            const errorMessage = formState.errors[name]?.message;
            return (
              <FormItem
                key={name}
                label={label}
                extra={errorMessage}
                validateStatus={errorMessage && 'error'}
              >
                <Component control={control} />
              </FormItem>
            );
          })}
        </Form>
      </Modal>
    </Spin>
  );
};

export default AddDonorNoteModal;
