import { Row, Col } from 'antd';
import { getOptions } from 'modules/donor/components/GroupCardInfo/options';
import { FC } from 'react';
import { GroupListItemOutput } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  group: GroupListItemOutput;
};

const GroupCardInfo: FC<PropTypes> = ({ group }) => (
  <Row gutter={[10, 0]}>
    {getOptions(group).map(({ label, value }, i) => {
      return (
        <Col span={24} key={`${group?.id}_${i}`}>
          <Row gutter={[10, 0]}>
            <Col span={12} className={styles.optionLabel}>
              {label}:
            </Col>
            <Col span={12}>{value}</Col>
          </Row>
        </Col>
      );
    })}
  </Row>
);

export default GroupCardInfo;
