import { ControlledSelect } from 'modules/common/components';
import Select from 'modules/common/components/Select';
import { ComponentProps, FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { JugularVein } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

type Props = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Select>;

const t = getTranslation('donation.JugularVeinsSelect');

const jugularVeinsOptions = Object.values(JugularVein).map((value) => {
  return {
    value,
    label: t(value.charAt(0).toLowerCase() + value.slice(1)),
  };
});

export const JugularVeinsSelect: FC<Props> = ({
  controlled,
  ...otherProps
}) => {
  const { t } = useTranslation('donation.JugularVeinsSelect');

  return (
    <ControlledSelect
      options={jugularVeinsOptions}
      defaultValue={undefined}
      controlled={controlled}
      placeholder={t('selectJugularVein')}
      {...otherProps}
    />
  );
};
