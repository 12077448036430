import { LinkOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table/Table';
import { SortOrder } from 'antd/lib/table/interface';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Link } from 'modules/common/components';
import RestrictedLink from 'modules/common/components/RestrictedLink';
import { ISortOption } from 'modules/common/interfaces';
import {
  LabworkOutput,
  LabworkListSort,
  LabworkStatus,
  PermissionsEnum,
  Order,
  LabworkListFilterInput,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.GeneralLabworkTable');

export const statusFilters = Object.values(LabworkStatus).map((status) => {
  return {
    text: t(status.toLowerCase()),
    value: status,
  };
});

type ColumnsProps = {
  showSorterTooltip?: boolean;
  sort: ISortOption<LabworkListSort>;
  filter: LabworkListFilterInput;
};

export const getColumns = ({
  showSorterTooltip,
  sort,
  filter,
}: ColumnsProps): ColumnsType<LabworkOutput> => {
  const getDefaultSort = (
    fieldName: LabworkListSort
  ): SortOrder | undefined => {
    if (sort?.fieldName === fieldName) {
      return sort.order === Order.Asc ? 'ascend' : 'descend';
    }
    return undefined;
  };
  return [
    {
      title: t('donorId'),
      render: (row: LabworkOutput) => row.donor.shortId,
      align: 'center',
    },
    {
      title: t('petName'),
      key: LabworkListSort.PetName,
      render: ({ donor }: LabworkOutput) => (
        <RestrictedLink
          to={Routes.DonorsProfile.replace(':id', String(donor.shortId))}
          permissions={[PermissionsEnum.ViewTheListOfDonors]}
        >
          {donor.name}
        </RestrictedLink>
      ),
      align: 'center',
      sorter: true,
      sortOrder: getDefaultSort(LabworkListSort.PetName),
      showSorterTooltip,
    },
    {
      title: t('lastName'),
      key: LabworkListSort.LastName,
      render: (row: LabworkOutput) => row.donor.user?.lastName,
      align: 'center',
      sorter: true,
      sortOrder: getDefaultSort(LabworkListSort.LastName),
      showSorterTooltip,
    },
    {
      title: t('dateOfLatestLabwork'),
      key: LabworkListSort.CollectionDate,
      render: (row: LabworkOutput) =>
        format(new Date(row.collectionDate), config.DATE_FORMAT),
      align: 'center',
      sorter: true,
      sortOrder: getDefaultSort(LabworkListSort.CollectionDate),
      showSorterTooltip,
    },
    {
      title: t('status'),
      key: 'statuses',
      render: (row: LabworkOutput) => t(row.status.toLowerCase()),
      align: 'center',
      filters: statusFilters,
      filteredValue: filter?.statuses || undefined,
    },
    {
      title: t('link'),
      render: (row: LabworkOutput) => (
        <Link
          to={`${Routes.DonorsLabworkBy.replace(
            ':id',
            String(row.donor.shortId)
          )}`}
        >
          <LinkOutlined className={styles.linkIcon} />
        </Link>
      ),
      align: 'center',
    },
  ];
};
