import { Layout } from 'antd';
import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  children: ReactNode;
};

const MainLayout: FC<PropTypes> = ({ children }) => {
  return <Layout className={styles.layout}>{children}</Layout>;
};

export default MainLayout;
