import { Row, Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const CopyrightSection: FC = () => {
  const { t } = useTranslation('donor.RoutineTest.CopyrightSection');

  return (
    <Row className={styles.copyrightSection} justify="space-between">
      <Col>
        {t('firstRow')}
        <br />
        {t('secondRow')}
      </Col>
      <Col>
        <img
          src="https://navbb-assets.s3.amazonaws.com/laboratory-logo.png"
          alt="logo"
          className={styles.logo}
        />
      </Col>
    </Row>
  );
};
