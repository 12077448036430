import { CloseCircleOutlined } from '@ant-design/icons';
import { SelectProps, Spin } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import config from 'config';
import { Select } from 'modules/common/components';
import styles from 'modules/common/components/Select/styles.module.scss';
import { useDebounce } from 'modules/common/hooks';
import { ComponentProps, FC, useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DonorOutput,
  useGetDonorsListByIdsLazyQuery,
  useGetDonorsListQuery,
} from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
  onSelect?: (donor: DonorOutput) => void;
  daycare?: boolean;
  showClearButtonByDefault?: boolean;
} & Omit<ComponentProps<typeof Select>, 'options' | 'onSelect'>;

const DonorSelect: FC<PropTypes> = ({
  controlled,
  className,
  onSelect,
  daycare,
  showClearButtonByDefault,
  allowClear,
  ...restProps
}) => {
  const classNames = [styles.select, className || ''].join(' ');
  const { t } = useTranslation('donor.DonorSelect');
  const controller = useController(controlled);
  const { onChange, ...restControllerField } = controller.field;
  const [isCloseBtnHidden, setIsCloseBtnHidden] = useState<boolean>(
    !showClearButtonByDefault
  );
  const { clearDebounce, debounce } = useDebounce();
  const [getDonorsByIds, donorsByIdsQuery] = useGetDonorsListByIdsLazyQuery();
  const getDonorsListQuery = useGetDonorsListQuery({
    variables: {
      input: {
        filter: {
          searchingQuery: '',
          isDaycare: daycare,
        },
        take: config.DEFAULT_LIMIT,
      },
    },
  });

  const fetchedDonors = [
    ...(getDonorsListQuery.data?.getDonorsList.data || []),
    ...(donorsByIdsQuery.data?.getDonorsListByIds || []),
  ];

  const donorList: SelectProps['options'] = fetchedDonors.map((donor) => {
    return {
      value: donor.id,
      label: (
        <>
          {donor.name} <b>{donor.user?.lastName}</b>
        </>
      ),
    };
  });

  useEffect(() => {
    if (Array.isArray(controller.field.value || !controller.field.value)) {
      return;
    }

    const knowCurrentValue = donorList?.find(
      (donor) => donor.value === controller.field.value
    );
    if (knowCurrentValue) {
      return;
    }

    getDonorsByIds({
      variables: {
        input: {
          ids: [controller.field.value],
        },
      },
    });
  }, [controller.field.value]);

  const handleSearch = (value: string) => {
    getDonorsListQuery.refetch({
      input: {
        filter: {
          searchingQuery: value,
        },
      },
    });
  };

  const handleSelect = (donorId: string) => {
    const selectedDonor = getDonorsListQuery.data?.getDonorsList.data.find(
      ({ id }) => id === donorId
    );
    onSelect && onSelect(selectedDonor as DonorOutput);
  };

  useEffect(() => clearDebounce, []);

  const onClear = () => {
    onChange(undefined);
    setIsCloseBtnHidden(true);
  };

  const clearButton =
    isCloseBtnHidden || !controller.field.value ? undefined : (
      <CloseCircleOutlined onClick={onClear} />
    );

  return (
    <Spin spinning={getDonorsListQuery.loading} className={styles.loader}>
      <Select
        {...restControllerField}
        options={donorList}
        placeholder={t('selectOrSearchDonor')}
        {...restProps}
        onSelect={(donorId: any, option: BaseOptionType) => {
          onChange(option.value);
          handleSelect(donorId);
          if (allowClear) {
            setIsCloseBtnHidden(false);
          }
        }}
        className={classNames}
        showSearch
        mode={daycare ? 'multiple' : undefined}
        adjustDropdownPosition
        loading={getDonorsListQuery.loading}
        filterOption={false}
        onSearch={debounce(handleSearch)}
        defaultActiveFirstOption={false}
        onDeselect={() => onChange(null)}
        suffixIcon={allowClear ? clearButton : undefined}
      />
    </Spin>
  );
};

export default DonorSelect;
