import { Col } from 'antd';
import { CreateAppointmentValidationSchema } from 'modules/appointments/components/CreateAppointmentsModal/schema';
import { ControlledTextArea, FormItem } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { AppointmentFormField } from '../fields';

const FIELD_NAME = AppointmentFormField.Notes;

type PropTypes = {
  control: Control<CreateAppointmentValidationSchema, any>;
} & ComponentProps<typeof FormItem>;

const NotesInput: FC<PropTypes> = ({ control, ...restFormItemProps }) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const fieldState = control.getFieldState(FIELD_NAME);
  const error = fieldState.error as unknown as FieldError | undefined;
  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('notes')}
        extra={error?.message}
        validateStatus={error?.message && 'error'}
        {...restFormItemProps}
      >
        <ControlledTextArea
          className={styles.notesField}
          placeholder={t('leftNotesHere')}
          controlled={{ control, name: FIELD_NAME }}
        />
      </FormItem>
    </Col>
  );
};

export default NotesInput;
