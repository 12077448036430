import { EmptyCircle } from 'modules/donor/components/RoutineTest/components/EmptyCircle';
import { FilledCircle } from 'modules/donor/components/RoutineTest/components/FilledCircle';
import { IndividualResultsSection } from 'modules/donor/components/RoutineTest/components/IndividualResultsSection';
import { FC } from 'react';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.RoutineTest.SerologySection');

const preset = {
  title: t('stronglyOrInfectionDiseases').toUpperCase(),
  items: [
    {
      label: (
        <>
          {t('tickPanel')}
          <i className={styles.additionalInfo}>{t('tickPanelDiseases')}</i>
        </>
      ),
      details: t('tickPanelDetails'),
      dots: [
        <EmptyCircle color="#D94C2F" />,
        <FilledCircle color="#D94C2F" />,
        <FilledCircle color="#8A4F9F" />,
      ],
    },
    {
      label: t('heartwormAntigen'),
      details: t('heartwormAntigenDetails'),
      checked: false,
      dots: [
        <EmptyCircle color="#D94C2F" />,
        <FilledCircle color="#D94C2F" />,
        <FilledCircle color="#8A4F9F" />,
      ],
    },
    {
      label: t('felvOrFiv'),
      details: t('felvOrFivDetails'),
      dots: [
        <EmptyCircle color="#D94C2F" />,
        <FilledCircle color="#D94C2F" />,
        <FilledCircle color="#8A4F9F" />,
      ],
    },
  ],
};

export const SerologySection: FC = () => (
  <IndividualResultsSection {...preset} />
);
