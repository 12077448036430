import { Row, Col, message, Popconfirm } from 'antd';
import { Routes } from 'config/routes';
import { Button, FormItem, Spin } from 'modules/common/components';
import { useModal } from 'modules/common/hooks';
import RejectionReasonModal from 'modules/requests/components/RejectionReasonModal';
import { RejectionReasonModalProps } from 'modules/requests/types';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  RequestStatus,
  useGetRequestQuery,
  useProcessRequestMutation,
  DonorOutput,
  PermissionsEnum,
  useRemoveRequestMutation,
} from 'types.d';

import { getFieldsWithDonorData } from './fields';

const Request: FC = () => {
  const { t } = useTranslation('requests.Request');
  const { t: popconfirmLocales } = useTranslation('common.DeletePopconfirm');
  const rejectionReasonModal = useModal<RejectionReasonModalProps>({
    onOk: (reason) => resolveRequest(RequestStatus.Reject, reason)(),
  });
  const currentUser = useCurrentUser();
  const canApproveDisapprove = currentUser.isCan(
    PermissionsEnum.ApproveDisapproveRequests
  );
  const navigate = useNavigate();
  const params = useParams();
  const requestId = params.id as string;
  const getRequestQuery = useGetRequestQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        id: requestId,
      },
    },
    onError: (error) => {
      const errorMessage =
        error.message === 'Not found'
          ? error.message
          : t('requestAlreadyProcessed');

      message.error(errorMessage);
      if (window.history.length > 1) {
        navigate(-1);
        return;
      }

      navigate(Routes.Requests, { replace: true });
    },
  });
  const [processRequest, processRequestMutation] = useProcessRequestMutation({
    update(cache) {
      cache.evict({ fieldName: 'getRequestList' });
    },
    onCompleted: (data) => {
      if (data.processRequest) {
        message.success(t('resolved'));
        navigate(Routes.Requests, { replace: true });
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const [removeRequest, removeRequestMutation] = useRemoveRequestMutation({
    update(cache) {
      cache.evict({ fieldName: 'getRequestList' });
    },
    onCompleted: (data) => {
      if (data.removeRequest) {
        message.success(t('resolved'));
        navigate(Routes.Requests, { replace: true });
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const donorData = getRequestQuery.data?.getRequest.donor;
  const isLoading =
    processRequestMutation.loading ||
    getRequestQuery.loading ||
    removeRequestMutation.loading;
  const requestFields = getFieldsWithDonorData(donorData as DonorOutput);
  const resolveRequest = (status: RequestStatus, reason?: string) => () => {
    processRequest({
      variables: {
        input: {
          status,
          reason,
          id: requestId,
        },
      },
    });
  };

  const handleDelete = () => {
    removeRequest({ variables: { input: { id: requestId } } });
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        {requestFields.map(({ label, Component, hidden }, i) => {
          if (hidden) {
            return null;
          }
          return (
            <Col span={24} key={`${i}_${label}`}>
              <FormItem label={label}>
                <Component disabled />
              </FormItem>
            </Col>
          );
        })}
        {canApproveDisapprove && (
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col>
                <Button onClick={() => rejectionReasonModal.show()}>
                  {t('disapprove')}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={resolveRequest(RequestStatus.Approve)}
                >
                  {t('approve')}
                </Button>
              </Col>
              <Col>
                <Popconfirm
                  title={popconfirmLocales('doYouWantToDelete')}
                  okText={popconfirmLocales('yes')}
                  cancelText={popconfirmLocales('no')}
                  onConfirm={handleDelete}
                >
                  <Button type="primary">{t('delete')}</Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <RejectionReasonModal {...rejectionReasonModal.config} />
    </Spin>
  );
};

export default Request;
