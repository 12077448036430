import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  letter?: string;
};

export const RadioButtonWithLetterInside: FC<Props> = ({ letter }) => (
  <div className={styles.radioButtonWrapper}>
    <label>
      <input type="radio" name="select" readOnly />
      <span className={letter ? '' : styles.empty}>{letter && letter}</span>
    </label>
  </div>
);
