import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import ProfileCardTabs from 'modules/user/components/ProfileCardTabs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ProfileAccountPage: FC = () => {
  const { t } = useTranslation('owner.ProfilePage');

  return (
    <ProfileSideMenuWrapper title={t('account')}>
      <ProfileCardTabs />
    </ProfileSideMenuWrapper>
  );
};

export default ProfileAccountPage;
