const POUNDS_IN_KILOGRAM = 2.20462262185;

const fixNumber = (value: number) => {
  return parseFloat((value * 100).toFixed()) / 100;
};

export const convertKilogramsToPounds = (kilograms: number) =>
  fixNumber(kilograms * POUNDS_IN_KILOGRAM);

export const convertPoundsToKilograms = (pounds: number) =>
  fixNumber(pounds / POUNDS_IN_KILOGRAM);
