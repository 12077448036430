import { Row } from 'antd';
import React from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  label?: string;
  classNames?: string;
  value?: string;
  fill?: boolean;
};

export default function FormInput({
  classNames,
  label,
  value,
  fill,
}: PropTypes) {
  return (
    <Row
      className={`${styles.inputWrapper} ${classNames} ${
        fill ? styles.fill : ''
      }`}
    >
      {label && <label>{label}</label>}
      <input className={`${styles.input} input`} value={value} />
    </Row>
  );
}
