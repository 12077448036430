import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Modal, Row, message, Alert } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { FormItem, Spin } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import AnticoagulantSelect from 'modules/products/components/AnticoagulantSelect';
import CategorySelect from 'modules/products/components/CategorySelect';
import ProductExpirationDates from 'modules/products/components/ProductExpirationDates';
import ProductQuantitySelect from 'modules/products/components/ProductQuantitySelect';
import ProductTypeSelect from 'modules/products/components/ProductTypeSelect';
import ProductValueFields from 'modules/products/components/ProductValueFields';
import StorageRecommendations from 'modules/products/components/StorageRecommendations';
import { CreateProductModalExtraProps } from 'modules/products/types';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ProductOutput,
  useGetDonationLazyQuery,
  useCreateProductsMutation,
  CreateProductInput,
} from 'types.d';

import { createProductSchema, CreateProductSchema } from './schema';
import styles from './styles.module.scss';

type PropTypes = BaseModalProps & CreateProductModalExtraProps;

const CreateProductModal: FC<PropTypes> = ({
  hide,
  onCancel,
  onOk,
  onProductsCreation,
  donationId = '',
  ...restModalProps
}) => {
  const { t } = useTranslation('products.CreateProductModal');
  const user = useCurrentUser();
  const productLocation = user.data?.productLocationPreference;

  const [getDonation, getDonationQuery] = useGetDonationLazyQuery({
    onError: (error) => {
      message.error(error.message);
    },
  });
  const donation = getDonationQuery.data?.getDonation;
  const donationDate = donation?.donationDate
    ? new Date(donation?.donationDate)
    : undefined;
  const {
    control,
    handleSubmit,
    watch,
    resetField,
    getValues,
    reset,
    formState,
    setValue,
  } = useForm<CreateProductSchema>({
    resolver: yupResolver(createProductSchema),
    mode: 'onChange',
  });
  const [createProducts, createProductsMutation] = useCreateProductsMutation({
    refetchQueries: ['getProducts'],
    onCompleted: (data) => {
      const createdProducts = (data.createProducts as ProductOutput[]) || [];
      message.success(t('productHasBeenCreated'));
      reset({ values: [] });
      hide();
      onProductsCreation && onProductsCreation(createdProducts);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const isLoading = getDonationQuery.loading || createProductsMutation.loading;
  const quantity = watch('quantity');
  const woocommerceCategoryId = watch('woocommerceCategoryId');

  const onCancelHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onCancel && onCancel(e);
    reset({ values: [] });
    hide();
  };

  const onOkHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    createProductHandler(e);
    onOk && onOk(e);
  };

  const createProductHandler = handleSubmit(
    async ({ values, quantity: _, ...restFormData }) => {
      const products: CreateProductInput[] = values.map((value) => {
        return {
          value,
          donationId,
          locationPreference: productLocation,
          ...restFormData,
        };
      });
      createProducts({
        variables: {
          input: {
            products,
          },
        },
      });
    }
  );

  useEffect(() => {
    resetField('woocommerceProductId');
  }, [woocommerceCategoryId]);

  useEffect(() => {
    const productValues = getValues('values');
    if (productValues && quantity) {
      resetField('values', { defaultValue: productValues.slice(0, quantity) });
    }
  }, [quantity]);

  useEffect(() => {
    if (donationId) {
      getDonation({
        variables: {
          input: {
            id: donationId,
          },
        },
      });
    }
  }, [donationId]);

  return (
    <Modal
      {...restModalProps}
      className={styles.modal}
      onCancel={onCancelHandler}
      onOk={onOkHandler}
      width="100%"
      okText={t('createProduct')}
      okButtonProps={{ disabled: !formState.isValid || !donationId }}
    >
      <Spin spinning={isLoading}>
        <Form>
          <Row gutter={[0, 16]}>
            {!donationId && (
              <Col span={24}>
                <Alert
                  className={styles.errorAlert}
                  message={t('donationDataIsWrong')}
                  type="error"
                />
              </Col>
            )}

            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <FormItem className={styles.categories}>
                    <CategorySelect
                      controlled={{ control, name: 'woocommerceCategoryId' }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <ProductTypeSelect
                    donorBloodType={donation?.donor.bloodType || undefined}
                    productCategoryId={woocommerceCategoryId}
                    controlled={{ control, name: 'woocommerceProductId' }}
                    onChangeDefaultValue={({ value }) => {
                      setValue('woocommerceProductId', value);
                    }}
                  />
                </Col>
                <Col span={8}>
                  {donationDate && (
                    <ProductExpirationDates
                      productCategoryId={woocommerceCategoryId}
                      donationDate={donationDate}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <FormItem
                className={styles.formItem}
                label={t('storageRecommendations')}
                colon
              >
                <StorageRecommendations
                  controlled={{ control, name: 'storageRequirements' }}
                  categoryId={woocommerceCategoryId}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <span className={styles.subtitle}>{t('donorId')}</span>{' '}
              {donation?.donor.shortId}
            </Col>
            <Col span={24}>
              <span className={styles.subtitle}>{t('bloodType')}</span>{' '}
              {donation?.donor.bloodType}
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col md={3} xs={24} className={styles.subtitle}>
                  {t('quantity')}
                </Col>
                <Col md={2} xs={24}>
                  <ProductQuantitySelect
                    controlled={{ control, name: 'quantity' }}
                  />
                </Col>
                <Col md={2} xs={24}>
                  <ProductValueFields
                    productQuantity={quantity}
                    name="values"
                    control={control}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <FormItem className={styles.formItem} label="Anticoagulant" colon>
                <AnticoagulantSelect
                  controlled={{ control, name: 'anticoagulant' }}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <span className={styles.subtitle}>{t('donationDate')} </span>
              {donationDate && format(donationDate, config.SHORT_DATE_FORMAT)}
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateProductModal;
