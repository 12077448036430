import { Routes } from 'config/routes';
import { ICustomRoute } from 'modules/common/interfaces';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { Navigate } from 'react-router-dom';

const NoAuthRoute = ({ componentProps = {}, Component }: ICustomRoute) => {
  const userQuery = useCurrentUser();

  return userQuery?.data ? (
    <Navigate to={Routes.Profile} />
  ) : (
    <Component {...componentProps} />
  );
};

export default NoAuthRoute;
