import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Col, message, Modal, Row, Spin } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { BaseModalProps } from 'modules/common/types';
import { FC, useState } from 'react';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DonationWithAdditionalInfoInput,
  useCreateDonationsWithAdditionalInfoMutation,
} from 'types.d';

import DonationsDragger from './components/DonationsDragger';
import ErrorMessages from './components/ErrorMessages';
import Footer from './components/Footer';
import ValidatingStatus from './components/ValidatingStatus';
import { prepareDonationsForCreating } from './prepareDonationsForCreating';
import {
  CreateDonationSchemaType,
  createDonationsSchema,
  CreateDonationsSchemaType,
} from './schema';
import styles from './styles.module.scss';

type PropTypes = {
  donorId?: string;
} & BaseModalProps;

const UploadDonationsModal: FC<PropTypes> = ({
  hide,
  onCancel,
  onOk,
  donorId,
  ...restProps
}) => {
  const { t } = useTranslation('donation.UploadDonationsModal');
  const [selectedFile, setSelectedFile] = useState<UploadFile[]>([]);
  const abstractCreateDonationsForm = useForm<CreateDonationsSchemaType>({
    resolver: yupResolver(createDonationsSchema),
    defaultValues: {
      donations: [],
      csvHeaders: [],
    },
  });
  const [createDonations, createDonationsMutation] =
    useCreateDonationsWithAdditionalInfoMutation({
      refetchQueries: ['getDonationList'],
      onCompleted: () => {
        clearForm();
        message.success(t('donationsHasBeenUploaded'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  const isLoading = createDonationsMutation.loading;
  const donationErrors =
    abstractCreateDonationsForm.formState.errors.donations || [];
  const headersErrors =
    abstractCreateDonationsForm.formState.errors.csvHeaders || [];
  const donationsErrorMessage = (donationErrors as unknown as FieldError)
    ?.message;
  const validatingStatus = abstractCreateDonationsForm.formState.isValid
    ? 'success'
    : 'error';
  const displayErrors = Boolean(donationErrors.length || headersErrors.length);

  const clearForm = () => {
    setSelectedFile([]);
    abstractCreateDonationsForm.reset();
  };
  const onCancelHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    clearForm();
    onCancel && onCancel(e);
    hide();
  };

  const uploadDonations = abstractCreateDonationsForm.handleSubmit(
    (formData) => {
      const donations: DonationWithAdditionalInfoInput[] =
        prepareDonationsForCreating(formData.donations);
      createDonations({
        variables: {
          input: {
            donations,
          },
        },
      });
    }
  );
  const donations = abstractCreateDonationsForm.watch('donations') as
    | CreateDonationSchemaType[]
    | undefined;

  return (
    <Modal
      {...restProps}
      className={styles.modal}
      title={t('uploadDonations')}
      onCancel={onCancelHandler}
      footer={
        <Footer
          cancelButtonProps={{ onClick: onCancelHandler }}
          okButtonProps={{
            onClick: uploadDonations,
            disabled:
              !abstractCreateDonationsForm.formState.isValid || isLoading,
          }}
        />
      }
    >
      <Spin spinning={isLoading}>
        <FormProvider {...abstractCreateDonationsForm}>
          <Row gutter={[10, 10]}>
            <Col span={24} className={styles.uploadContainer}>
              <DonationsDragger
                clear={clearForm}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />
            </Col>
            {Boolean(donations?.length) && (
              <ValidatingStatus status={validatingStatus} />
            )}

            {Boolean(donationsErrorMessage) && (
              <Col span={24} className={styles.errors}>
                <Alert type="error" message={donationsErrorMessage} />
              </Col>
            )}
            {displayErrors && (
              <Col span={24} className={styles.errors}>
                <ErrorMessages />
              </Col>
            )}
          </Row>
        </FormProvider>
      </Spin>
    </Modal>
  );
};

export default UploadDonationsModal;
