import { Col, Row, message } from 'antd';
import { Routes } from 'config/routes';
import { Button, Text } from 'modules/common/components';
import { ITabsItem } from 'modules/common/components/CardTabs';
import { GroupProfileDonorsTab } from 'modules/donor/components/GroupProfileDonorsTab';
import { GroupProfileTeamsTab } from 'modules/donor/components/GroupProfileTeamsTab';
import { useUpdateDonorsOwnerModalContext } from 'modules/donor/contexts/UpdateDonorsOwnerModal';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { GroupOutput, UserRole, useFindGroupQuery } from 'types.d';

import UpdateGroupOwnerModal from '../UpdateGroupsOwnerModal';

type Props = {
  groupId: string;
};

const GroupProfileCards: FC<Props> = ({ groupId }) => {
  const { t } = useTranslation('donor.GroupProfileCards');
  const updateGroupsOwnerModal = useUpdateDonorsOwnerModalContext();
  const currentUser = useCurrentUser();
  const isCurrentUserAdmin = currentUser.data?.role.value === UserRole.Admin;
  const params = useParams();
  const id = params.id || '';
  const navigate = useNavigate();
  const groupQuery = useFindGroupQuery({
    variables: {
      input: {
        id,
      },
    },
    onError: (err) => {
      message.error(err.message);
      if (err.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.GroupDonors);
    },
  });

  const donorsTab: ITabsItem = {
    title: t('donorsList'),
    content: <GroupProfileDonorsTab />,
    key: `${Routes.Group}/${groupId}`,
  };

  const teamsTab: ITabsItem = {
    title: t('teamsList'),
    content: <GroupProfileTeamsTab />,
    key: `${Routes.Group}${Routes.Teams}/${groupId}`,
  };
  const group = groupQuery.data?.findGroup as GroupOutput | undefined;
  const groupTitle = group?.title || '';

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Text>{`${t('group')} ${groupTitle}`}</Text>
            </Col>
            {isCurrentUserAdmin && (
              <Col>
                <Button onClick={() => updateGroupsOwnerModal?.showModal()}>
                  {t('changeOwner')}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <NavigationCardTabs items={[donorsTab, teamsTab]} />
        </Col>
      </Row>
      {updateGroupsOwnerModal && (
        <UpdateGroupOwnerModal
          group={group}
          {...updateGroupsOwnerModal.modalConfig}
        />
      )}
    </>
  );
};

export default GroupProfileCards;
