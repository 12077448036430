import * as yup from 'yup';

import {
  contactSchema,
  ContactSchemaType,
} from './AdditionalContactsSection/schema';
import {
  personalInfoSchema,
  PersonalInfoSchemaType,
} from './PersonalInfoSection/schema';

type EditOwnerFormSchemaType = {
  personalInfo: PersonalInfoSchemaType;
  contact: ContactSchemaType;
};

const editOwnerFormSchema: yup.SchemaOf<EditOwnerFormSchemaType> = yup.object({
  personalInfo: personalInfoSchema,
  contact: contactSchema,
});

export { editOwnerFormSchema };
export type { EditOwnerFormSchemaType };
