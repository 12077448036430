import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  color: string;
};
export const FilledCircle: FC<Props> = ({ color }) => {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={styles.circle}
    />
  );
};
