import { ModalProps } from 'antd';
import { useState } from 'react';
import { ProductOutput } from 'types';

import { ContextType } from './type';

export const useProviderValue = (): ContextType => {
  const [modalProps, setModalProps] = useState<ModalProps>();
  const [product, setProduct] = useState<ProductOutput>();
  const [visible, setVisible] = useState(false);

  const showModal = (product?: ProductOutput) => {
    setVisible(true);
    setProduct(product);
  };

  const updateModalProps = (props: ModalProps) => {
    setModalProps(props);
  };

  const hideModal = () => {
    setVisible(false);
    setProduct(undefined);
  };

  return {
    modalConfig: {
      ...modalProps,
      product,
      visible,
      hide: hideModal,
    },
    showModal,
    hideModal,
    updateModalProps,
  };
};
