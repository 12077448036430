import { Col, Layout, Row } from 'antd';
import { MainLayout } from 'modules/common/components';
import ForgotPasswordForm from 'modules/user/components/ForgotPasswordForm';
import { FC } from 'react';

import styles from './styles.module.scss';

const ForgotPasswordPage: FC = () => {
  return (
    <MainLayout>
      <Layout.Content className={styles.content}>
        <Row justify="center" align="middle" className={styles.formContainer}>
          <Col flex="440px">
            <ForgotPasswordForm />
          </Col>
        </Row>
      </Layout.Content>
    </MainLayout>
  );
};

export default ForgotPasswordPage;
