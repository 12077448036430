import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Col, Form, message, Row } from 'antd';
import { Button, Spin } from 'modules/common/components';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CreateContactInput,
  InviteUserRole,
  useInviteUserMutation,
} from 'types.d';

import AdditionalContactsSection from './AdditionalContactsSection';
import PersonalInfoSection from './PersonalInfoSection';
import { createProfileFormSchema, CreateProfileFormSchemaType } from './schema';
import styles from './styles.module.scss';

import { contactSchema } from '../EditProfileForm/schema';

const CreateProfileForm: FC = () => {
  const { t } = useTranslation('owner.CreateOwnerPage');

  const createOwnerForm = useForm<CreateProfileFormSchemaType>({
    resolver: yupResolver(createProfileFormSchema),
    mode: 'onChange',
  });

  const [inviteUser, inviteUserMutation] = useInviteUserMutation({
    onCompleted: () => {
      createOwnerForm.reset();
      message.success(t('successfullyInvited'));
    },
    onError: ({ message: m }) => {
      message.error(m);
    },
  });
  const contactFormIsValid = () => {
    const contactsFieldNames = Object.keys(
      contactSchema.fields
    ) as (keyof CreateProfileFormSchemaType['contact'])[];
    const someFieldIsInvalid = contactsFieldNames.some((fieldName) => {
      return !createOwnerForm.formState.dirtyFields.contact?.[fieldName];
    });
    return (
      !createOwnerForm.getFieldState('contact').error && !someFieldIsInvalid
    );
  };

  const inviteHandler = createOwnerForm.handleSubmit(
    ({ personalInfo, contact }) => {
      inviteUser({
        variables: {
          input: {
            ...personalInfo,
            role: InviteUserRole.Owner,
            additionalContact: contactFormIsValid()
              ? (contact as CreateContactInput)
              : undefined,
          },
        },
      });
    }
  );

  const resetForm = () => {
    createOwnerForm.reset();
  };

  return (
    <Spin spinning={inviteUserMutation.loading}>
      <FormProvider {...createOwnerForm}>
        <Form labelCol={{ span: 12 }}>
          <Row gutter={[10, 0]}>
            <Col sm={12} span={24}>
              <PersonalInfoSection fieldNamePrefix="personalInfo" />
            </Col>
            <Col sm={12} span={24}>
              <AdditionalContactsSection fieldNamePrefix="contact" />
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <Row className={styles.bottomFormButtonsContainer}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button onClick={resetForm}>{t('close')}</Button>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={!createOwnerForm.formState.isValid}
                onClick={inviteHandler}
              >
                {t('create')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default CreateProfileForm;
