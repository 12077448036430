import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Image, Card, Row, Col } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { Spin } from 'modules/common/components';
import { IPicture } from 'modules/donor/components/DonorPicturesList/interfaces';
import { getPictureUrl } from 'modules/donor/components/DonorPicturesList/utils';
import UploadDonorPhoto from 'modules/donor/components/UploadDonorPhoto';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import { DonorOutput, PermissionsEnum } from 'types.d';
import { fetchWithAuth } from 'utils/fetchWithAuth';

import styles from './styles.module.scss';

const { UploadDonorsPictures, DeleteDonorsPictures } = PermissionsEnum;

type Props = {
  donor: DonorOutput;
  refetchHandler: () => void;
};

export const DonorPicturesList: FC<Props> = ({ donor, refetchHandler }) => {
  const [pictures, setPictures] = useState<IPicture[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const currentUser = useCurrentUser();
  const canUploadDonorPictures = currentUser.isCan(UploadDonorsPictures);
  const canDeleteDonorPictures = currentUser.isCan(DeleteDonorsPictures);

  const onChangeUploadFile = (uploadParams: UploadChangeParam) => {
    if (uploadParams.file.status === 'done') {
      refetchHandler();
    }
  };

  const deletePhotoHandler = async (id: string) => {
    setLoading(true);
    const response = await fetchWithAuth(
      `${process.env.REACT_APP_SERVER_URI}/donor/files/${id}`,
      {
        method: 'DELETE',
      }
    );
    if (response.ok) {
      refetchHandler();
    }
  };

  useEffect(() => {
    setLoading(true);
    const updateUrls = async () => {
      const newPictures =
        donor?.pictures &&
        (await Promise.all(
          donor.pictures.map(async ({ file, id }) => {
            const url = await getPictureUrl(file);
            return { id, url };
          })
        ));
      setPictures(newPictures || []);
      setLoading(false);
    };
    updateUrls();
  }, [donor]);

  return (
    <Spin spinning={loading} size="large">
      {pictures && pictures.length > 0 && (
        <Image.PreviewGroup>
          <Row gutter={[10, 10]} className={styles.imagesWrapper}>
            {pictures.map((picture) => (
              <Col>
                <Card
                  hoverable
                  key={picture.id}
                  className={styles.imageCard}
                  cover={
                    <div className={styles.imageContainer}>
                      <Image
                        alt="example"
                        src={picture.url}
                        className={styles.image}
                      />
                    </div>
                  }
                  bodyStyle={{ padding: 0 }}
                  actions={[
                    canUploadDonorPictures && (
                      <UploadDonorPhoto
                        data={{
                          donorId: donor.id,
                          pictureId: picture.id,
                        }}
                        action="update"
                        onChange={onChangeUploadFile}
                      >
                        <EditOutlined key="edit" className={styles.edit} />
                      </UploadDonorPhoto>
                    ),
                    canDeleteDonorPictures && (
                      <DeleteOutlined
                        key="delete"
                        onClick={() => deletePhotoHandler(picture.id)}
                      />
                    ),
                  ]}
                />
              </Col>
            ))}
          </Row>
        </Image.PreviewGroup>
      )}
    </Spin>
  );
};
