import { FilterValue } from 'antd/lib/table/interface';
import { CATEGORY_ID } from 'modules/products/constants/categoryId';
import {
  BloodType,
  GetProductsCountInput,
  GetWoocommerceProductsInput,
  GetWoocommerceTopSellersInput,
  ProductStatus,
  TopSellersPeriod,
  WoocommerceProductCategoryOutput,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('products.ProductsDashboard');

const categoriesBlackList = [
  CATEGORY_ID.UNCATEGORIZED,
  CATEGORY_ID.MEDICAL_SUPPLY,
];

enum ColumnIndexKey {
  DropsProductsCount = 'dropsProductsCount',
  WoocommerceTopSellers = 'woocommerceTopSellers',
  WoocommerceProducts = 'woocommerceProducts',
}

export const getDropsProductsCountFilter = (
  woocommerceCategories?: WoocommerceProductCategoryOutput[]
) => {
  const categories = woocommerceCategories?.reduce((ctgs, { id, name }) => {
    if (categoriesBlackList.includes(id)) {
      return ctgs;
    }
    return [...ctgs, { value: `woocommerceCategory.${id}`, text: name }];
  }, [] as any[]);
  const dropsProductCount = [
    {
      text: t('bloodType'),
      value: 'bloodType',
      children: Object.values(BloodType).map((bloodType) => {
        return {
          text: t(`${bloodType[0].toLowerCase()}${bloodType.substring(1)}`),
          value: `bloodType.${bloodType}`,
        };
      }),
    },
    {
      text: t('status'),
      value: 'statuses',
      children: Object.values(ProductStatus).map((status) => {
        return {
          text: t(`${status[0].toLowerCase()}${status.substring(1)}`),
          value: `statuses.${status}`,
        };
      }),
    },
    {
      text: t('woocommerceCategory'),
      value: 'woocommerceCategory',
      children: categories,
    },
  ];

  return dropsProductCount;
};

type ParsedColumnFilters<FilterInputType = Record<string, unknown>> = Record<
  keyof FilterInputType,
  any[] | undefined
>;

type ParsedTableFilters = {
  [ColumnIndexKey.DropsProductsCount]: ParsedColumnFilters<GetProductsCountInput>;
  [ColumnIndexKey.WoocommerceTopSellers]: ParsedColumnFilters<GetWoocommerceTopSellersInput>;
  [ColumnIndexKey.WoocommerceProducts]: ParsedColumnFilters<GetWoocommerceProductsInput>;
};

export const parseTableFilters = (
  tableFilters: Record<string, FilterValue | null>
): ParsedTableFilters => {
  const mappedFilters = Object.values(ColumnIndexKey).reduce(
    (columnsFilters, columnIndexKey) => {
      const incomingFilters = tableFilters[columnIndexKey] || [];
      const columnFilter = incomingFilters.reduce(
        (filterValues, filterValue) => {
          const [key, value] = String(filterValue).split('.');
          const parsedFilterValue = value
            ? [...(filterValues[key] || []), value]
            : filterValues[key];
          return {
            ...filterValues,
            [key]: parsedFilterValue,
          };
        },
        {} as any
      );
      return { ...columnsFilters, [columnIndexKey]: columnFilter };
    },
    {} as any
  );
  return mappedFilters;
};

export const getTopSellersCountFilter = () => {
  const orderedFilterKeys = [
    TopSellersPeriod.Year,
    TopSellersPeriod.Month,
    TopSellersPeriod.LastMonth,
    TopSellersPeriod.Week,
  ];
  const topSellersCountFilter = orderedFilterKeys.map((period) => {
    return {
      text: t(`${period[0].toLowerCase()}${period.substring(1)}`),
      value: `period.${period}`,
    };
  });

  return topSellersCountFilter;
};

export const getWoocommerceProductsFilter = (
  woocommerceCategories?: WoocommerceProductCategoryOutput[]
) => {
  return woocommerceCategories?.reduce((ctgs, { id, name }) => {
    if (categoriesBlackList.includes(id)) {
      return ctgs;
    }
    return [...ctgs, { value: `category.${id}`, text: name }];
  }, [] as any[]);
};
