import { gql } from '@apollo/client';

export const PRODUCT_FRAGMENT = gql`
  fragment Product on ProductOutput {
    anticoagulant
    createdAt
    id
    shortId
    status
    storageRequirements
    updatedAt
    value
    woocommerceProductId
    woocommerceCategoryId
    woocommerceOrderId
    expirationDate
    published
    locationPreference
  }
`;
