import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { PermissionsEnum } from 'types.d';

import OrdersPage from './pages/Orders';

const ownerModule: IModule = {
  routes: [
    {
      path: Routes.Orders,
      Component: OrdersPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewListOfOrders],
    },
  ],
};

export default ownerModule;
