import { Anticoagulant } from 'types.d';
import * as yup from 'yup';

export const productQuantity = yup.number().min(1).max(4);

export const productValue = yup.number().min(100).max(500);

export const anticoagulantSchema = yup
  .mixed<Anticoagulant>()
  .oneOf(Object.values(Anticoagulant));
