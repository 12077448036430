import { Layout } from 'antd';
import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  children: ReactNode;
};

const PageContainer: FC<PropTypes> = ({ children }) => {
  return (
    <Layout className={styles.screenLayout}>
      <Layout className={styles.pageLayout}>{children}</Layout>
    </Layout>
  );
};

export default PageContainer;
