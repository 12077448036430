import admin from './admin';
import appointments from './appointments';
import auth from './auth';
import common from './common';
import donation from './donation';
import donor from './donor';
import error from './error';
import locations from './locations';
import navigation from './navigation';
import notification from './notification';
import orders from './orders';
import owner from './owner';
import products from './products';
import reports from './reports';
import requests from './requests';
import user from './user';

export default {
  auth,
  appointments,
  admin,
  common,
  user,
  navigation,
  notification,
  owner,
  requests,
  donor,
  error,
  donation,
  orders,
  products,
  reports,
  locations,
};
