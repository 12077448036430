import { Col, Row } from 'antd';
import config from 'config';
import { DefaultClinicParams } from 'config/default-clinic-params';
import { format } from 'date-fns';
import { RoutineTestFormSchemaType } from 'modules/donor/components/RoutineTestForm/schema';
import React from 'react';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

import CheckboxGroup from '../../components/CheckboxGroup';
import FormInput from '../../components/Input';

const t = getTranslation(
  'donor.FormsToPrint.GeneralSubmissionForm.AnimalIdentification'
);

const checkboxOptions = [
  t('fedex'),
  t('mail'),
  t('fedexGrnd'),
  t('priMail'),
  t('upsGrnd'),
  t('expMail'),
  t('upsNd'),
  { fieldName: t('other'), withInput: true },
];

const secondCheckboxOptions = [
  t('frozen'),
  t('dryIce'),
  t('rmTemp'),
  t('coldPack'),
  t('cool'),
  t('none'),
  t('cold'),
  { fieldName: t('comment'), withInput: true },
];

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};

export default function AnimalIdentification({ data }: PropTypes) {
  return (
    <div className={styles.animalIdentification}>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th colSpan={6} className={styles.tableAnimalIdentification}>
                {t('AnimalIdentification')}
              </th>
              <th rowSpan={3}>
                {t('indicate')}
                <b>
                  <i>{t('specimenType')}</i>
                </b>
                <br /> {t('anatomicLocation')}
              </th>
              <th rowSpan={3}>{t('dateTaken')}</th>
              <th rowSpan={3}>
                {t('testRequested')} <br /> t{'perAnimal'} <br />
                {t('enterFullName')}
              </th>
            </tr>
            <tr>
              <th colSpan={6}>{t('codes')}</th>
            </tr>
            <tr>
              <th>{t('number')}</th>
              <th>{t('nameOrIdNum')}</th>
              <th>{t('species')}</th>
              <th>{t('breed')}</th>
              <th>{t('sex')}</th>
              <th>{t('ageOrDob')}</th>
            </tr>
          </thead>
          <tbody>
            {new Array(10).fill(undefined).map((_, i) => {
              return (
                <tr key={i}>
                  <td className={styles.itemNumber}>{i + 1}</td>
                  <td>{i === 0 && data.animalName}</td>
                  <td>{i === 0 && data.species}</td>
                  <td>{i === 0 && data.breed}</td>
                  <td>{i === 0 && data.gender}</td>
                  <td>{i === 0 && data.age}</td>
                  <td
                    className={`${styles.noBottomBorder} ${styles.specimenType}`}
                  >
                    {t('serum')}
                  </td>
                  <td className={styles.noBottomBorder}>
                    {i === 0 && format(data.collectionDate, config.DATE_FORMAT)}
                  </td>
                  <td className={styles.noBottomBorder}></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Row className={styles.comments}>
        <Col span={20}>
          <p>{t('comments')}</p>
        </Col>
        <Col span={4} className={styles.commentCheckbox}>
          <div></div>
          <p>{t('checkIfcontinuationPageIncluded')}</p>
        </Col>
      </Row>
      <Row className={styles.productDataSection}>
        <Col
          span={17}
          className={`${styles.checkboxGroup} ${styles.checkboxLeftGroup}`}
        >
          <Row>
            <Col span={6}>
              <p>
                <i>{t('AHDCUseOnly')}</i>
              </p>
              <FormInput
                label={t('openedBy')}
                classNames={styles.openedByInput}
              />
            </Col>
            <Col span={9}>
              <div className={styles.checkboxTable}>
                {checkboxOptions.map((option, i) => (
                  <CheckboxGroup options={[option]} key={i} />
                ))}
              </div>
            </Col>
            <Col span={9} className={styles.productDataWrapper}>
              <FormInput label="DATE REC'D:" classNames={styles.productData} />
              <FormInput label="TIME REC'D:" classNames={styles.productData} />
              <FormInput
                label="DATE SHIPPED:"
                classNames={styles.productData}
              />
            </Col>
          </Row>
        </Col>
        <Col span={6} offset={1} className={styles.checkboxGroup}>
          <div className={styles.checkboxTable}>
            {secondCheckboxOptions.map((option, i) => (
              <CheckboxGroup options={[option]} key={i} />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}
