import { Row, Col } from 'antd';
import { Routes } from 'config/routes';
import { Button, Link, Spin } from 'modules/common/components';
import { ITabsItem } from 'modules/common/components/CardTabs';
import Charts from 'modules/donor/components/Charts';
import { DonorDonationsList } from 'modules/donor/components/DonorDonationsList';
import DonorLabwork from 'modules/donor/components/DonorLabwork';
import DonorPictures from 'modules/donor/components/DonorPictures';
import EditDonorForm from 'modules/donor/components/EditDonorForm';
import UpdateDonorsOwnerModal from 'modules/donor/components/UpdateDonorsOwnerModal';
import { useUpdateDonorsOwnerModalContext } from 'modules/donor/contexts/UpdateDonorsOwnerModal';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DonorOutput, DonorStatus, useGetDonorQuery } from 'types.d';

import styles from './styles.module.scss';

import DonorNotes from '../DonorsNotes';

type Props = {
  donorId: string;
};

const DonorProfileCardTabs: FC<Props> = ({ donorId }) => {
  const { t } = useTranslation('donor.DonorProfileCardTabs');
  const user = useCurrentUser();
  const getDonorsQuery = useGetDonorQuery({
    variables: {
      input: {
        id: donorId,
      },
    },
  });
  const updateDonorsOwnerModal = useUpdateDonorsOwnerModalContext();
  const isDonorArchived =
    getDonorsQuery.data?.getDonor.status === DonorStatus.Archived;
  const canAddDonation = user.data?.permissions?.addNewDonation || false;
  const canViewDonorsPictures =
    user.data?.permissions?.viewDonorsPictures || false;
  const canViewLabwork = user.data?.permissions?.viewLabworkResults || false;
  const canViewDonorDonations =
    user.data?.permissions?.viewSpecificDonationDataAtDonorProfile || false;
  const canViewDonorCharts = user.data?.permissions?.viewVitalsChart || false;
  const donor = getDonorsQuery.data?.getDonor as DonorOutput | undefined;

  const profile: ITabsItem = {
    title: t('profile'),
    content: <EditDonorForm />,
    key: `${Routes.Donors}/${donorId}`,
  };

  const pictures: ITabsItem = {
    title: t('pictures'),
    content: <DonorPictures />,
    key: `${Routes.Donors}${Routes.Pictures}/${donorId}`,
  };

  const labwork: ITabsItem = {
    title: t('labwork'),
    content: <DonorLabwork />,
    key: `${Routes.Donors}${Routes.Labwork}/${donorId}`,
  };

  const donations: ITabsItem = {
    title: t('donations'),
    content: <DonorDonationsList />,
    key: `${Routes.Donors}${Routes.Donations}/${donorId}`,
  };

  const vitalsChart: ITabsItem = {
    title: t('vitalsChart'),
    content: <Charts />,
    key: `${Routes.Donors}${Routes.Charts}/${donorId}`,
  };
  const donorNotes: ITabsItem = {
    title: t('notes'),
    content: <DonorNotes />,
    key: `${Routes.Donors}${Routes.Notes}/${donorId}`,
  };
  return (
    <Spin spinning={getDonorsQuery.loading}>
      <Row gutter={[0, 10]}>
        {canAddDonation && (
          <Col span={24} className={styles.addDonationButton}>
            <Link to={`${Routes.CreateDonation}/?donorId=${donorId}`}>
              <Button type="primary" disabled={isDonorArchived}>
                {t('addNewDonation')}
              </Button>
            </Link>
          </Col>
        )}
        <Col span={24}>
          <NavigationCardTabs
            items={[
              profile,
              ...(canViewDonorsPictures ? [pictures] : []),
              ...(canViewLabwork ? [labwork] : []),
              ...(canViewDonorDonations ? [donations] : []),
              ...(canViewDonorCharts ? [vitalsChart] : []),
              donorNotes,
            ]}
          />
        </Col>
      </Row>
      {updateDonorsOwnerModal && (
        <UpdateDonorsOwnerModal
          donor={donor}
          {...updateDonorsOwnerModal?.modalConfig}
        />
      )}
    </Spin>
  );
};

export default DonorProfileCardTabs;
