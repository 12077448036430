import { Row, Col } from 'antd';
import { EmptyCircle } from 'modules/donor/components/RoutineTest/components/EmptyCircle';
import { FilledCircle } from 'modules/donor/components/RoutineTest/components/FilledCircle';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.RoutineTest.Marks');

const preset = [
  {
    label: t('sst').toUpperCase(),
    meaning: t('sstMeaning').toUpperCase(),
    mark: <EmptyCircle color="#D94C2F" />,
  },
  {
    label: t('rtt').toUpperCase(),
    meaning: t('rttMeaning').toUpperCase(),
    mark: <FilledCircle color="#D94C2F" />,
  },
  {
    label: t('ltt').toUpperCase(),
    meaning: t('lttMeaning').toUpperCase(),
    mark: <FilledCircle color="#8A4F9F" />,
  },
  {
    label: t('btt').toUpperCase(),
    meaning: t('bttMeaning').toUpperCase(),
    mark: <FilledCircle color="#3599CC" />,
  },
  {
    label: t('grntt').toUpperCase(),
    meaning: t('grnttMeaning').toUpperCase(),
    mark: <FilledCircle color="#58B947" />,
  },
  {
    label: t('ctt').toUpperCase(),
    meaning: t('cttMeaning').toUpperCase(),
  },
];

export const Marks = () => {
  return (
    <Row className={styles.text} align="middle">
      {preset.map(({ label, mark, meaning }) => (
        <Col className={styles.mark} key={label}>
          <Row align="middle">
            <strong>{label}</strong>
            {mark && mark} {meaning && ` : ${meaning}`}
          </Row>
        </Col>
      ))}
    </Row>
  );
};
