import {
  petWeightSchema,
  petSpeciesSchema,
  petGenderSchema,
  petNameSchema,
  petDateOfBirthSchema,
} from 'modules/donor/schemas';
import { AnimalGender, AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { PetField } from '../fields';

const t = getTranslation('donor.ValidationErrors');

const {
  Name,
  DateOfBirth,
  Neutered,
  Weight,
  HasTransfusion,
  TakeMedications,
  PrimaryVeterinarian,
  Species,
  Gender,
  MedicationsCourseComment,
  Location,
  Region,
  HasHeartMurmur,
  HasMicrochip,
  Breed,
  AttendDaycareDays,
  WontRegularlyAttendDaycare: IsRegularlyAttendDaycare,
} = PetField;

export type PetSchemaType = {
  name: string;
  breed: string;
  dateOfBirth: Date;
  neutered: boolean;
  weight: number;
  hasTransfusion: boolean;
  takeMedications: boolean;
  primaryVeterinarian: string;
  species: AnimalSpecies;
  gender: AnimalGender;
  medicationCourse?: string;
  location: string;
  hasHeartMurmur: boolean;
  hasMicrochip: boolean;
  wontRegularlyAttendDaycare: boolean;
  region?: string;
  attendDaycareDays?: string[];
};

export const petSchema: yup.SchemaOf<PetSchemaType> = yup.object({
  [Name]: petNameSchema,
  [DateOfBirth]: petDateOfBirthSchema,
  [Neutered]: yup.boolean().required(t('required', { field: t('neutered') })),
  [HasHeartMurmur]: yup
    .boolean()
    .required(t('required', { field: t('thisField') })),
  [Weight]: petWeightSchema,
  [HasTransfusion]: yup
    .boolean()
    .required(t('required', { field: t('hasTransfusion') })),
  [TakeMedications]: yup
    .boolean()
    .required(t('required', { field: t('takeMedications') })),
  [PrimaryVeterinarian]: yup
    .string()
    .strict(true)
    .trim(t('mustBeTrimmed'))
    .required(t('required', { field: t('primaryVeterinarian') }))
    .max(255),
  [Species]: petSpeciesSchema,
  [Breed]: yup
    .string()
    .max(50, t('atMost', { max: 50, field: t('breed') }))
    .required(t('required', { field: t('breed') })),
  [Gender]: petGenderSchema,
  [MedicationsCourseComment]: yup
    .string()
    .strict(true)
    .trim(t('mustBeTrimmed'))
    .when(TakeMedications, {
      is: true,
      then: yup
        .string()
        .strict(true)
        .trim(t('mustBeTrimmed'))
        .required(t('provideTreatmentInfo')),
    })
    .max(255),
  [HasMicrochip]: yup
    .boolean()
    .required(t('required', { field: t('hasMicrochip') })),
  [Location]: yup
    .string()
    .strict(true)
    .trim(t('mustBeTrimmed'))
    .required(t('required', { field: t('location') }))
    .max(255),
  [IsRegularlyAttendDaycare]: yup.boolean().default(false),
  [AttendDaycareDays]: yup.array().of(yup.string().required()).optional(),
  [Region]: yup.string().optional(),
});
