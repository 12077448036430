import { Table, TableProps, Row, Col, Grid } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import config from 'config';
import { Spin, SearchInput } from 'modules/common/components';
import { useSort, useDebounce, useFilter } from 'modules/common/hooks';
import { getColumns } from 'modules/donor/components/GeneralLabworkTable/columns';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC, useEffect, useRef, useState } from 'react';
import {
  useGetLabworkListQuery,
  LabworkOutput,
  LabworkListSort,
  Order,
  LabworkListFilterInput,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const { useBreakpoint } = Grid;

const GeneralLabworkTable: FC = () => {
  const t = getTranslation('donor.GeneralLabworkTable');
  const { debounce } = useDebounce();
  const pagination = usePagination();
  const labworkSort = useSort<LabworkListSort>();
  const labworkFilter = useFilter<LabworkListFilterInput>();
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const setSearchInput = useRef(false);

  const { data, loading: getLabworkListLoading } = useGetLabworkListQuery({
    variables: {
      input: {
        filter: labworkFilter.getFilterObject(),
        take: config.PAGE_SIZE,
        skip: pagination.skip,
        sort: labworkSort.options,
      },
    },
    onCompleted: (result) => {
      pagination.setTotal(result.getLabworkList.meta.total);
    },
  });

  const isLoading = getLabworkListLoading;
  const labworks = data?.getLabworkList.data as LabworkOutput[];

  const onTableChange: TableProps<LabworkOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<LabworkOutput>;
      const fieldName =
        LabworkListSort[
          tableSorter.columnKey?.toString() as keyof typeof LabworkListSort
        ];
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        labworkSort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        labworkSort.removeSortOption(fieldName);
      }
    }

    if (extra.action === 'filter') {
      Object.keys(filters).forEach((fieldName) => {
        labworkFilter?.setFilterOption({
          fieldName: fieldName as keyof LabworkListFilterInput,
          value: (filters as any)[fieldName],
        });
      });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchInputValue(searchValue);
    pagination.moveTo(1);
    debounce(() => {
      labworkFilter.setFilterOption({
        fieldName: 'searchingQuery',
        value: searchValue,
      });
    })(searchValue);
  };

  const { md } = useBreakpoint();
  const columns = getColumns({
    showSorterTooltip: md,
    sort: labworkSort.options[0],
    filter: labworkFilter.getFilterObject(),
  });

  useEffect(() => {
    if (
      !setSearchInput.current &&
      labworkFilter?.getFilterObject().searchingQuery
    ) {
      setSearchInputValue(
        labworkFilter?.getFilterObject().searchingQuery || ''
      );
      setSearchInput.current = true;
    }
  }, [labworkFilter]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <SearchInput
            onChange={handleSearch}
            placeholder={t('searchLabwork')}
            maxLength={100}
            value={searchInputValue}
          />
        </Col>
        <Col span={24}>
          <Table
            rowKey={({ id }) => id}
            columns={columns}
            dataSource={labworks}
            onChange={onTableChange}
            pagination={pagination.config}
            scroll={{ x: 750 }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default GeneralLabworkTable;
