import { teamTitleSchema } from 'modules/donor/schemas';
import * as yup from 'yup';

export type TeamTitleFormSchema = {
  title: string;
};

export const teamTitleFormSchema: yup.SchemaOf<TeamTitleFormSchema> =
  yup.object({
    title: teamTitleSchema.required(),
  });
