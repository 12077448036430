import { Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { BaseModalProps } from 'modules/common/types';
import { LabworkAttachmentsTable } from 'modules/donor/components';
import { LabworkAttachmentsModalPropTypes } from 'modules/donor/types';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = BaseModalProps & LabworkAttachmentsModalPropTypes;

const LabworkAttachmentsModal: FC<PropTypes> = ({
  labworkId,
  ...modalProps
}) => {
  const { t } = useTranslation('donor.LabworkAttachmentsModal');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {}, [labworkId]);

  const okHandler = (e: MouseEvent<HTMLElement>) => {
    setFileList([]);
    modalProps.onOk && modalProps.onOk(e);
    modalProps.hide();
  };

  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    setFileList([]);
    modalProps.hide();
    modalProps.onCancel && modalProps.onCancel(e);
  };
  return (
    <Modal
      {...modalProps}
      width={500}
      title={t('labworkAttachments')}
      onOk={okHandler}
      onCancel={cancelHandler}
    >
      {labworkId && (
        <LabworkAttachmentsTable
          labworkId={labworkId}
          fileList={fileList}
          setFileList={setFileList}
        />
      )}
    </Modal>
  );
};

export default LabworkAttachmentsModal;
