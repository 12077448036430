import { ModalProps } from 'antd';
import { BaseModalProps } from 'modules/common/types';
import { useState } from 'react';

type ReturnedProps<ExtraParams> = {
  config: MergeModalProps<ExtraParams> & BaseModalProps;
  hide: () => void;
  show: (params?: MergeModalProps<ExtraParams>) => void;
};

type MergeModalProps<T> = Omit<ModalProps, keyof T> & T;

export const useModal = <ExtraParams = Record<string, never>>(
  props?: MergeModalProps<ExtraParams>
): ReturnedProps<ExtraParams> => {
  const [modalProps, setModalProps] = useState<MergeModalProps<ExtraParams>>(
    props || ({} as MergeModalProps<ExtraParams>)
  );
  const [visible, setVisible] = useState(false);

  const show = (params?: Partial<MergeModalProps<ExtraParams>>) => {
    params && setModalProps({ ...modalProps, ...params });
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  return {
    config: {
      ...modalProps,
      visible,
      hide,
    },
    show,
    hide,
  };
};
