import { message } from 'antd';
import { useTabActive } from 'modules/common/hooks';
import useOnlineStatus from 'modules/common/hooks/useOnlineStatus';
import { ComponentWithChildren } from 'modules/common/types';
import { cachePersistor } from 'providers/graphql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import offlineServices from 'utils/OfflineServices';

import { getOfflineMutations } from './getOfflineMutations';
import { runOfflineMutations } from './runOfflineMutations';

const OfflineMutationsProvider: ComponentWithChildren = ({ children }) => {
  const { t } = useTranslation('common.OfflineMutationsProvider');
  const isOnline = useOnlineStatus();
  const { isCurrentTabActive } = useTabActive();
  useEffect(() => {
    if (isOnline && isCurrentTabActive) {
      processOfflineMutations();
    }
  }, [isOnline]);

  const processOfflineMutations = async () => {
    const offlineMutations = getOfflineMutations();
    if (!offlineMutations.length) {
      return;
    }
    const processMutations = confirm(t('unsavedDataExist'));
    if (!processMutations) {
      return;
    }
    await runOfflineMutations(offlineMutations);
    message.success(t('dataHasBeenSaved'));
    await cachePersistor.purge();
    await offlineServices.prepareCache();
  };

  return <>{children}</>;
};

export default OfflineMutationsProvider;
