import { message } from 'antd';
import config from 'config';
import { DonationBaseChart } from 'modules/donor/components';
import { getConfig } from 'modules/donor/components/CollectedMlsChart/config';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetChartsDataLazyQuery,
  DonationListSort,
  Order,
  useGetDonorQuery,
} from 'types.d';

const CollectedMlsChart: FC = () => {
  const params = useParams();
  const shortDonorId = params.id || '';

  const [getCharts, chartsQuery] = useGetChartsDataLazyQuery({
    onError: (error) => {
      message.error(error.message);
    },
  });

  useGetDonorQuery({
    variables: {
      input: {
        id: shortDonorId,
      },
    },
    onCompleted: ({ getDonor }) => {
      if (getDonor.id) {
        getCharts({
          variables: {
            input: {
              sort: [
                {
                  fieldName: DonationListSort.LastDonationDate,
                  order: Order.Asc,
                },
              ],
              filter: { donorId: getDonor.id },
              take: config.CHARTS_DEFAULT_DONATIONS_LIMIT,
            },
          },
        });
      }
    },
  });

  const data =
    (chartsQuery?.data?.getChartsData?.data?.map(
      ({ additionalInfo, ...otherValues }) => {
        return { ...otherValues, ...additionalInfo };
      }
    ) as any[]) || [];

  return <DonationBaseChart lineConfig={getConfig(data)} />;
};

export default CollectedMlsChart;
