import { useCallback, useEffect, useState } from 'react';

type UseTabActiveHookReturnType = {
  isCurrentTabActive: boolean;
};

export const useTabActive = (): UseTabActiveHookReturnType => {
  const [visibilityState, setVisibilityState] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { isCurrentTabActive: visibilityState };
};
