import { RcFile } from 'antd/lib/upload';
import { DONORS_CSV_HEADERS } from 'modules/donor/constants';
import Papa from 'papaparse';

import { CreateDonorSchemaType } from './schema';

type ParamTypes = {
  file: RcFile;
  onComplete?: (results: Papa.ParseResult<any>) => void;
};

export const parseDonorsCsv = ({ file, onComplete }: ParamTypes) => {
  Papa.parse<CreateDonorSchemaType>(file, {
    skipEmptyLines: true,
    header: true,
    transformHeader: (header) => {
      return headersMap[header];
    },
    transform: (value, field: keyof CreateDonorSchemaType) => {
      const parser = parsersByFields[field];
      return parser ? parser(value) : value;
    },
    complete: (results) => {
      onComplete && onComplete(results);
    },
  });
};

const parsersByFields: Partial<
  Record<keyof CreateDonorSchemaType, (value: string) => any>
> = {
  neutered: (value) => neuteredMap[value.toLowerCase()],
  rabiesDate: (value) => transformToDate(value),
  retiredDate: (value) => transformToDate(value),
  distemperDate: (value) => transformToDate(value),
  // nextDonationDate: (value) => new Date(value), //Disabled code
};

const transformToDate = (value: string) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const matchedMonth = value.match(/[a-z]+/i)?.at(0);

  if (!matchedMonth) {
    return new Date(value);
  }

  const indexOfMonth = months.indexOf(matchedMonth) + 1;
  const transformedValue = value.replace(matchedMonth, indexOfMonth.toString());
  return new Date(transformedValue);
};

const neuteredMap: Record<string, boolean> = {
  yes: true,
  no: false,
};

const headersMap = DONORS_CSV_HEADERS.reduce((headers, { label, key }) => {
  return { ...headers, [label]: key };
}, {} as Record<string, string>);
