import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { INTERNAL_TEAM_ROLES } from 'modules/user/constants';
import { PermissionsEnum } from 'types.d';

import ReportsPage from './pages/Reports';

const productsModule: IModule = {
  routes: [
    {
      path: Routes.Reports,
      Component: ReportsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewReports],
      roles: INTERNAL_TEAM_ROLES,
    },
    {
      path: Routes.DonorsReports,
      Component: ReportsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewReports],
      roles: INTERNAL_TEAM_ROLES,
    },
    {
      path: Routes.DonationReports,
      Component: ReportsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewReports],
      roles: INTERNAL_TEAM_ROLES,
    },
    {
      path: Routes.ProductsReports,
      Component: ReportsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewReports],
      roles: INTERNAL_TEAM_ROLES,
    },
    {
      path: Routes.OwnersReports,
      Component: ReportsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewReports],
      roles: INTERNAL_TEAM_ROLES,
    },
    {
      path: Routes.IneligiblesReports,
      Component: ReportsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewReports],
      roles: INTERNAL_TEAM_ROLES,
    },
  ],
};

export default productsModule;
