import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import { Button } from 'modules/common/components';
import OwnerDonorsTable from 'modules/donor/components/OwnerDonorsTable';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const DonorsPage: FC = () => {
  const { t } = useTranslation('owner.DonorsPage');
  const navigate = useNavigate();

  const navigateTo = (route: Routes) => () => navigate(route);

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Row gutter={[8, 8]}>
        <Col flex={1} />
        <Col>
          <Button onClick={navigateTo(Routes.DonorsApplication)}>
            {t('createDonor')}
          </Button>
        </Col>
        <Col span={24}>
          <OwnerDonorsTable />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default DonorsPage;
