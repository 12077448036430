import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const EnrollDonorModalContext = createContext<ContextType | null>(null);

const EnrollDonorModalProvider: ComponentWithChildren = ({ children }) => {
  const providerValue = useProviderValue();

  return (
    <EnrollDonorModalContext.Provider value={providerValue}>
      {children}
    </EnrollDonorModalContext.Provider>
  );
};

export const useEnrollDonorModalContext = () => {
  const context = useContext(EnrollDonorModalContext);
  if (context === undefined) {
    throw new Error(
      'the useEnrollDonorModalContext should be used within EnrollDonorModalProvider!'
    );
  }

  return context;
};

export default EnrollDonorModalProvider;
