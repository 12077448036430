import {
  emailSchema,
  usernameSchema,
  phoneNumberSchema,
  contactPreferencesSchema,
} from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { UserField } from '../fields';

const t = getTranslation('donor.RegisterPetForm');

const { FirstName, LastName, Email, PhoneNumber, ContactPreferences } =
  UserField;

export type UserSchemaType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  contactPreferences: string;
};

export const userSchema: yup.SchemaOf<UserSchemaType> = yup.object({
  [FirstName]: usernameSchema,
  [LastName]: usernameSchema,
  [Email]: emailSchema,
  [PhoneNumber]: phoneNumberSchema,
  [ContactPreferences]: contactPreferencesSchema.required(
    t('required', { field: t('contactPreferences') })
  ),
});
