import { ProductStatus } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

export interface IUpdateProductTrackingInfoSchemaType {
  id: string;
  woocommerceOrderId?: string;
  orderExist?: boolean;
  status?: ProductStatus;
}

const t = getTranslation('products.ChangeProductTrackingInfoModal');

export const updateProductTrackingInfoSchema: yup.SchemaOf<IUpdateProductTrackingInfoSchemaType> =
  yup.object({
    id: yup.string().required(),
    woocommerceOrderId: yup
      .string()
      .required(t('idRequired'))
      .matches(/^\d+$/g, t('orderIdShouldBeNumber')), //TODO: add reg exp for numbers only
    orderExist: yup.boolean().when(['woocommerceOrderId'], {
      is: (value: string) => Boolean(value),
      then: () => yup.boolean().oneOf([true]).required(),
      otherwise: () => yup.boolean().notRequired(),
    }),
    status: yup
      .mixed<ProductStatus>()
      .oneOf(Object.values(ProductStatus))
      .notRequired(),
  });
