import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row, message } from 'antd';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { Routes } from 'config/routes';
import { isPast } from 'date-fns';
import {
  Alert,
  Button,
  ControlledInput,
  Cooldown,
  FormItem,
  Link,
  Spin,
} from 'modules/common/components';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResendEmailConfirmationCodeMutation } from 'types.d';
import { envServices } from 'utils/EnvServices';

import { validationSchema, SchemaType } from './shcema';
import styles from './styles.module.scss';

const { RESEND_EMAIL_CONFIRMATION_CODE_ALLOWED_IN } = LOCALE_STORAGE_KEYS;

type PropTypes = {
  defaultEmail?: string;
};

const ResendEmailConfirmationCodeForm: FC<PropTypes> = ({ defaultEmail }) => {
  const { t } = useTranslation('auth.ResendEmailConfirmationCodeForm');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [cooldownDeadline, setCooldownDeadline] = useState<number>();
  const { control, formState, handleSubmit } = useForm<SchemaType>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      email: defaultEmail,
    },
  });
  const [resendCode, resendCodeMutation] =
    useResendEmailConfirmationCodeMutation({
      onError: (err) => {
        setErrorMessage(err.message);
      },
      onCompleted: () => {
        message.success(t('codeHasBeenResent'));
        const cooldownDeadlineValue =
          Date.now() +
          envServices.get('REACT_APP_RESEND_EMAIL_CODE_COOL_DOWN') * 60000;
        saveCooldownDeadline(cooldownDeadlineValue);
        setCooldownDeadline(cooldownDeadlineValue);
      },
    });
  const validationErrorMessage = formState.errors.email?.message;
  const canResendEmailConfirmationCode =
    !cooldownDeadline && formState.isValid && !resendCodeMutation.loading;
  const submitForm = handleSubmit(({ email }) => {
    resendCode({
      variables: {
        input: {
          email,
        },
      },
    });
  });

  const onCooldownComplete = () => {
    dropCooldownDeadline();
    setCooldownDeadline(undefined);
  };

  useEffect(() => {
    const deadlineFromStorage = getCooldownDeadline();
    if (deadlineFromStorage && isPast(new Date(deadlineFromStorage))) {
      dropCooldownDeadline();
      setCooldownDeadline(undefined);
    } else {
      setCooldownDeadline(deadlineFromStorage);
    }
  }, []);

  return (
    <Form>
      <Spin spinning={resendCodeMutation.loading}>
        <Row gutter={[0, 10]}>
          {errorMessage && (
            <Col span={24}>
              <Alert type="error" message={errorMessage} />
            </Col>
          )}
          <Col span={24}>
            <FormItem
              label={t('email')}
              extra={validationErrorMessage}
              validateStatus={validationErrorMessage && 'error'}
            >
              <ControlledInput controlled={{ name: 'email', control }} />
            </FormItem>
          </Col>
          <Col span={24}>
            <Button
              disabled={!canResendEmailConfirmationCode}
              onClick={submitForm}
              type="primary"
              className={styles.submitButton}
            >
              <Row>
                <Col span={2}>
                  {cooldownDeadline && (
                    <Cooldown
                      className={styles.cooldown}
                      deadline={cooldownDeadline}
                      onComplete={onCooldownComplete}
                    />
                  )}
                </Col>
                <Col span={20}>{t('resend')}</Col>
                <Col span={2} />
              </Row>
            </Button>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col>
                <Link to={Routes.Login}>{t('goBackToLogin')}</Link>
              </Col>
            </Row>
          </Col>
          <Col span={24}></Col>
        </Row>
      </Spin>
    </Form>
  );
};

const getCooldownDeadline = (): number | undefined => {
  const value = window.localStorage.getItem(
    RESEND_EMAIL_CONFIRMATION_CODE_ALLOWED_IN
  );

  return value ? new Date(Number(value)).getTime() : undefined;
};

const saveCooldownDeadline = (value: number) => {
  window.localStorage.setItem(
    RESEND_EMAIL_CONFIRMATION_CODE_ALLOWED_IN,
    `${value}`
  );
};

const dropCooldownDeadline = () => {
  window.localStorage.removeItem(RESEND_EMAIL_CONFIRMATION_CODE_ALLOWED_IN);
};

export default ResendEmailConfirmationCodeForm;
