import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

import { ContactFields } from './enum';

const t = getTranslation(
  'owner.EditOwnerProfileForm.AdditionalContactsSection'
);

export const contactFields: FormFieldWithComponent<
  ContactFields,
  { name: string; disabled?: boolean }
>[] = [
  {
    label: t('firstName'),
    name: ContactFields.firstName,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterFirstName')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('lastName'),
    name: ContactFields.lastName,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterLastName')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('streetAddress'),
    name: ContactFields.streetAddress,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterStreetAddress')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('city'),
    name: ContactFields.city,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterCity')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('state'),
    name: ContactFields.state,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterState')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('zipCode'),
    name: ContactFields.zipCode,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterZipCode')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('email'),
    name: ContactFields.email,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterEmail')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('phone'),
    name: ContactFields.phoneNumber,
    Component: ({ control, name, disabled }) => (
      <ControlledPhoneInput
        placeholder={t('enterPhone')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
];
