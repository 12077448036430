import en from './en';

const removeNestedObjects = (object: { [key: string]: any }) => {
  return Object.entries(object).reduce((result, [key, value]) => {
    if (typeof value === 'object') {
      return result;
    }
    return { ...result, [key]: value };
  }, {});
};

const mapLocales = (moduleLocales: { [key: string]: any }) => {
  let resultLocales = {};
  Object.entries(moduleLocales).map(([moduleName, localeObj]) => {
    const combine = (prefix: string, locale: any) => {
      if (typeof locale === 'object') {
        Object.entries(locale).map(([innerModuleName, innerLocale]) => {
          if (typeof innerLocale === 'object') {
            combine(`${prefix}.${innerModuleName}`, innerLocale);
            resultLocales = {
              ...resultLocales,
              [`${prefix}.${innerModuleName}`]: removeNestedObjects(
                innerLocale as any
              ),
            };
          }
        });
      }
    };
    combine(moduleName, localeObj);
  });
  return resultLocales;
};

export default {
  en: mapLocales(en),
};
