import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Select } from 'modules/common/components';
import { FC, useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DonationPositionUsed } from './enum';
import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & SelectProps;

export const DonationPositionSelect: FC<PropTypes> = ({
  controlled,
  ...restProps
}) => {
  const { t } = useTranslation('donation.DonationPositionSelect');
  const { field } = useController(controlled);
  const [selectedDonationPosition, setSelectedDonationPosition] = useState<
    string[] | undefined
  >();

  const onChangeHandler = (value: string) => {
    const [firstLocation, secondLocation] = value as unknown as string[];
    const newSelectedValue = value.length
      ? [secondLocation || firstLocation]
      : undefined;
    setSelectedDonationPosition(newSelectedValue);
    field.onChange(newSelectedValue?.[0]);
  };

  useEffect(() => {
    setSelectedDonationPosition(field.value ? [field.value] : undefined);
  }, [field.value]);
  const classNames = `${styles.select} ${restProps.className || ''}`;
  const options: DefaultOptionType[] = Object.values(
    DonationPositionUsed
  ).map<DefaultOptionType>((position) => {
    return {
      label: t(`${position.charAt(0).toLowerCase()}${position.slice(1)}`),
      value: position,
    };
  });

  return (
    <Select
      {...restProps}
      mode="tags"
      options={options}
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      ref={field.ref}
      placeholder={t('selectDonationPosition')}
      value={selectedDonationPosition as any}
      // className={classNames}
    />
  );
};
