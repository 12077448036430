import { Input, TextArea } from 'modules/common/components';
import YesNoRadioGroup from 'modules/donor/components/YesNoRadioGroup';
import { FC } from 'react';
import { DonorOutput, DonorType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('requests.Request');

type RequestField = {
  label: string;
  Component: FC<{ disabled?: boolean }>;
  hidden?: (() => boolean) | boolean;
};

export const getFieldsWithDonorData = (
  donor?: Partial<DonorOutput>
): RequestField[] => [
  {
    label: t('name'),
    Component: (props) => <Input value={donor?.name} {...props} />,
  },
  {
    label: t('lastName'),
    Component: (props) => <Input value={donor?.user?.lastName} {...props} />,
  },
  {
    label: t('species'),
    Component: (props) => <Input value={donor?.species} {...props} />,
  },
  {
    label: t('age'),
    Component: (props) => <Input value={donor?.age} {...props} />,
  },
  {
    label: t('gender'),
    Component: (props) => <Input value={donor?.gender} {...props} />,
  },
  {
    label: t('neutered'),
    Component: (props) => (
      <YesNoRadioGroup value={donor?.neutered} {...props} />
    ),
  },
  {
    label: t('weight'),
    Component: (props) => (
      <Input
        value={donor?.weight}
        addonAfter={t('kg').toUpperCase()}
        {...props}
      />
    ),
  },
  {
    label: t('distinguishableMarks'),
    Component: (props) => (
      <TextArea
        value={donor?.distinguishableMarks || undefined}
        className={styles.textArea}
        {...props}
      />
    ),
    hidden:
      donor?.distinguishableMarks === undefined ||
      donor?.distinguishableMarks === null,
  },
  {
    label: t('bloodType'),
    Component: (props) => (
      <Input value={donor?.bloodType || undefined} {...props} />
    ),
    hidden: donor?.bloodType === undefined || donor?.bloodType === null,
  },
  {
    label: t('donorType'),
    Component: (props) => <Input value={donor?.type} {...props} />,
  },
  {
    label: t('team'),
    Component: (props) => <Input value={donor?.team?.title} {...props} />,
    hidden: donor?.type === DonorType.Individual,
  },
  {
    label: t('breed'),
    Component: (props) => <Input value={donor?.breed || ''} {...props} />,
    hidden: donor?.breed === undefined || donor?.breed === null,
  },
  {
    label: t('hasTransfusion'),
    Component: (props) => (
      <YesNoRadioGroup value={donor?.hasTransfusion} {...props} />
    ),
    hidden:
      donor?.hasTransfusion === undefined || donor?.hasTransfusion === null,
  },
  {
    label: t('takesMedications'),
    Component: (props) => (
      <YesNoRadioGroup value={donor?.takeMedications} {...props} />
    ),
    hidden:
      donor?.takeMedications === undefined || donor?.takeMedications === null,
  },
  {
    label: t('medicationCourse'),
    Component: (props) => (
      <TextArea
        value={donor?.medicationCourse || undefined}
        className={styles.textArea}
        {...props}
        disabled
      />
    ),
    hidden:
      donor?.medicationCourse === undefined || donor?.medicationCourse === null,
  },
  {
    label: t('primaryVeterinarian'),
    Component: (props) => (
      <Input value={donor?.primaryVeterinarian} {...props} />
    ),
  },
  //TODO: add donation frequency field if it's needed
  //TODO: add comment field if it's needed
  //TODO: add ID field if it's needed
];
