import { CloseCircleTwoTone } from '@ant-design/icons';
import { Row, Col } from 'antd';
import colors from 'config/colors.scss';
import { Routes } from 'config/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

type PropTypes = {
  message: string;
  userEmail?: string;
};

const EmailNotVerified: FC<PropTypes> = ({ message, userEmail }) => {
  const { t } = useTranslation('user.EmailNotVerified');

  return (
    <Col flex="200px">
      <CloseCircleTwoTone className={styles.icon} twoToneColor={colors.red} />
      <div className={styles.message}>{message}</div>
      <Row justify="space-between">
        <Col span={12}>
          <Link to={Routes.Login}>{t('loginPage')}</Link>
        </Col>
        <Col span={12} className={styles.resendLinkContainer}>
          <Link
            to={{
              pathname: Routes.ResendConfirmationCode,
              search: userEmail
                ? `?email=${encodeURIComponent(userEmail)}`
                : undefined,
            }}
          >
            {t('resend')}
          </Link>
        </Col>
      </Row>
    </Col>
  );
};

export default EmailNotVerified;
