import {
  bloodTypeSchema,
  petMarksSchema,
  donorTypeSchema,
  breedSchema,
  colorSchema,
} from 'modules/donor/schemas';
import { BloodType, DonorType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donor.CreateDonorForm.OptionalSection');

type OptionalFormSectionSchemaType = {
  distinguishableMarks?: string;
  bloodType?: BloodType;
  donorType?: DonorType;
  breed?: string | null;
  color?: string | null;
  donationFrequency?: string;
  teamId?: string;
  comment?: string;
  hasMicrochip?: boolean;
  microchipNumber?: string;
};

const optionalFormSectionSchema: yup.SchemaOf<OptionalFormSectionSchemaType> =
  yup.object({
    distinguishableMarks: petMarksSchema,
    bloodType: bloodTypeSchema,
    donorType: donorTypeSchema,
    breed: breedSchema,
    color: colorSchema,
    donationFrequency: yup.string(),
    teamId: yup.string().when('donorType', {
      is: DonorType.Grouped,
      then: yup.string().required(t('teamRequired')),
    }),
    comment: yup
      .string()
      .max(255, t('shorterThan', { max: 255, field: t('comment') })),
    hasMicrochip: yup.boolean(),
    microchipNumber: yup
      .string()
      .max(50, t('shorterThan', { max: 50, field: t('microchip') })),
  });

export { optionalFormSectionSchema };
export type { OptionalFormSectionSchemaType };
