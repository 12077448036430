import { Modal } from 'antd';
import { BaseModalProps } from 'modules/common/types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LabworkOutput } from 'types.d';

import PrintLabworkForm, { PrintHandleRef } from '../PrintLabworkForm';

export type PrintLabworkModalProps = Partial<BaseModalProps> & {
  labwork?: LabworkOutput;
};

const PrintLabworkModal: React.FC<PrintLabworkModalProps> = ({
  hide,
  visible,
  labwork,
}) => {
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState(true);
  const formRef = useRef<PrintHandleRef>(null);
  const { t } = useTranslation('donor.PrintLabworkModal');

  const onOkHandler = () => {
    formRef.current?.handlePrint();
    hide?.();
  };

  return (
    <Modal
      onCancel={hide}
      onOk={onOkHandler}
      visible={visible}
      title={t('title')}
      okText={t('print')}
      destroyOnClose
      okButtonProps={{
        disabled: isPrintButtonDisabled,
      }}
    >
      {labwork && (
        <PrintLabworkForm
          ref={formRef}
          labwork={labwork}
          setIsPrintButtonDisabled={setIsPrintButtonDisabled}
        />
      )}
    </Modal>
  );
};

export default PrintLabworkModal;
