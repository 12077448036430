import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import CheckboxGroup from '../../../components/CheckboxGroup';
import FormInput from '../../../components/Input';
import Title from '../Title';

export default function History() {
  const { t } = useTranslation(
    'donor.FormsToPrint.StateLabSubmissionForm.History'
  );

  return (
    <Row className={styles.historySection}>
      <Row className={styles.historySectionTitle}>
        <Title position="start">{t('historyTitle')}</Title>
        <p>{t('clinicalSignsNote')}</p>
      </Row>
      <Row className={styles.info}>
        <FormInput label={t('durationOfIllness')} />
        <FormInput label={t('dateOfDeath')} />
        <CheckboxGroup
          options={[t('yes'), t('no')]}
          label={t('euthanized')}
          labelPlacement="left"
          className={styles.checkbox}
        />
        <FormInput label={t('method')} />
      </Row>
      <Row className={styles.historyInputs}>
        <Row>
          <FormInput fill classNames={styles.historyInput} />
        </Row>
        <Row>
          <FormInput
            value="Health Screening"
            fill
            classNames={styles.historyInput}
          />
        </Row>
        <Row>
          <FormInput fill classNames={styles.historyInput} />
        </Row>
        <Row>
          <FormInput fill classNames={styles.historyInput} />
        </Row>
        <Row>
          <FormInput fill classNames={styles.historyInput} />
        </Row>
        <Row>
          <FormInput
            classNames={styles.historyInput}
            label={t('fixedTissueNote')}
          />
        </Row>
        <Row>
          <FormInput classNames={styles.historyInput} label={t('ruleOuts')} />
        </Row>
      </Row>
    </Row>
  );
}
