export const DONORS_CSV_HEADERS = [
  { label: 'Name', key: 'name' },
  { label: 'Location', key: 'location' }, //Should we keep this column, cause the donor by him self does not have the location, the location can have user only
  { label: 'Date of birth', key: 'dateOfBirth' },
  { label: 'Neutered', key: 'neutered' },
  { label: 'Weight', key: 'weight' },
  { label: 'Primary veterinarian', key: 'primaryVeterinarian' },
  { label: 'Specie', key: 'species' },
  { label: 'Gender', key: 'gender' },
  { label: 'Status', key: 'status' },
  { label: 'Breed', key: 'breed' },
  { label: 'Type', key: 'type' }, //Do we really need this column, 'cause before uploading csv file we are selecting the owner and group/team on demand
  { label: 'Blood type', key: 'bloodType' },
  { label: 'Color', key: 'color' },
  { label: 'rabiesDate', key: 'rabiesDate' },
  { label: 'distemperDate', key: 'distemperDate' },
  { label: 'retiredDate', key: 'retiredDate' },
  //{ label: 'nextDonationDate', key: 'nextDonationDate' }, //Disabled code
] as const;
