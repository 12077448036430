import { ComponentWithChildren } from 'modules/common/types';
import FallbackError from 'modules/error/components/FallbackError';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithRender,
} from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { logger } from 'utils/Logger';

type PropTypes = Omit<ErrorBoundaryPropsWithRender, 'fallbackRender'>;

const ErrorBoundary: ComponentWithChildren<PropTypes> = ({
  children,
  ...restProps
}) => {
  const { t } = useTranslation('error.ErrorBoundary');
  const handlerError: ErrorBoundaryPropsWithRender['onError'] = (error) => {
    logger.error(error.message);
  };

  return (
    <ReactErrorBoundary
      {...restProps}
      onError={handlerError}
      fallbackRender={(fallbackProps) => (
        <FallbackError {...fallbackProps} translation={t} />
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
