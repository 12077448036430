import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  color: string;
};
export const EmptyCircle: FC<Props> = ({ color }) => {
  return (
    <div
      style={{
        border: `2px solid ${color}`,
      }}
      className={styles.circle}
    />
  );
};
