import { emailSchema } from 'modules/user/schemas';
import * as yup from 'yup';

export type SchemaType = {
  email: string;
};

export const validationSchema: yup.SchemaOf<SchemaType> = yup.object({
  email: emailSchema,
});
