import { locationPreferencesSchema, dateSchema } from 'modules/owner/schemas';
import {
  contactPreferencesSchema,
  phoneNumberSchema,
  usernameSchema,
} from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { PersonalInfoFields } from './enum';

const {
  firstName,
  lastName,
  phoneNumber,
  streetAddress,
  city,
  state,
  zipCode,
  locationPreferences,
  birthday,
  contactPreferences,
} = PersonalInfoFields;

type PersonalInfoSchemaType = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  locationPreferences?: string;
  birthday?: any;
  contactPreferences?: string;
};

const t = getTranslation('owner.ValidationErrors');

const personalInfoSchema: yup.SchemaOf<PersonalInfoSchemaType> = yup.object({
  [firstName]: usernameSchema,
  [lastName]: usernameSchema,
  [phoneNumber]: phoneNumberSchema,
  [streetAddress]: yup.string(),
  [city]: yup.string(),
  [state]: yup.string(),
  [zipCode]: yup
    .string()
    .optional()
    .test({
      name: 'is-valid-zip',
      message: t('invalidZip'),
      test: function (value) {
        if (!value) {
          return true;
        }
        return yup
          .string()
          .length(5, t('mustBeExactLength', { field: t('zipCode'), value: 5 }))
          .matches(/^[0-9]+$/, t('onlyNumbers'))
          .isValidSync(value);
      },
    }),
  [locationPreferences]: locationPreferencesSchema.optional(),
  [birthday]: dateSchema,
  [contactPreferences]: contactPreferencesSchema.required(
    t('required', { field: t('contactPreferences') })
  ),
});

export { personalInfoSchema };
export type { PersonalInfoSchemaType };
