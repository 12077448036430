import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import CheckboxGroup from '../../../components/CheckboxGroup';
import FormInput from '../../../components/Input';
export default function Receipt() {
  const { t } = useTranslation(
    'donor.FormsToPrint.StateLabSubmissionForm.Receipt'
  );
  return (
    <Row className={styles.receipt}>
      <Col span={6}>
        <p className={styles.labUseOnly}>{t('labUseOnly')}</p>
      </Col>
      <Col span={18}>
        <div className={styles.receiptAdditionalInfo}>
          <p className={styles.receiptAdditionalInfoLabel}>
            {t('methodOfReceipt')}
          </p>
          <CheckboxGroup
            options={[t('dropOff')]}
            className={styles.receiptAdditionalInfoCheckbox}
          />
          <CheckboxGroup
            options={[t('mailIn')]}
            className={styles.receiptAdditionalInfoCheckbox}
          />
          <CheckboxGroup
            options={[{ fieldName: t('other'), withInput: true }]}
            className={styles.receiptAdditionalInfoCheckbox}
          />
          <p className={styles.receiptAdditionalInfoLabel}>
            {t('conditionUponReceipt')}
          </p>
          <CheckboxGroup
            options={[t('acceptable')]}
            className={styles.receiptAdditionalInfoCheckbox}
          />
          <CheckboxGroup
            options={[t('unacceptable')]}
            className={styles.receiptAdditionalInfoCheckbox}
          />
          <span className={styles.receiptAdditionalInfoEmpty}></span>
          <span className={styles.receiptAdditionalInfoEmpty}></span>
          <span className={styles.receiptAdditionalInfoEmpty}></span>
          <FormInput
            label={t('ifUnacceptableReason')}
            classNames={styles.receiptAdditionalInfoInput}
          />
        </div>
      </Col>
    </Row>
  );
}
