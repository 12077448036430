import { Col, Row } from 'antd';
import { ControlledInputNumber } from 'modules/common/components';
import { FC } from 'react';
import { Control } from 'react-hook-form';

type PropTypes = {
  productQuantity: number;
  control: Control<any, any>;
  name: string;
};

const ProductValueFields: FC<PropTypes> = ({
  productQuantity,
  control,
  name,
}) => {
  const valueFields = productQuantity
    ? Array.from(Array(productQuantity).keys())
    : undefined;

  return (
    <Row gutter={[0, 10]}>
      {valueFields?.map((_, i) => (
        <Col key={i} span={24}>
          <ControlledInputNumber
            placeholder="ml"
            min={0}
            controlled={{ control, name: `${name}[${i}]` }}
          />
        </Col>
      ))}
    </Row>
  );
};

export default ProductValueFields;
