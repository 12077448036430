import { emailSchema } from 'modules/user/schemas';
import * as yup from 'yup';

const resetPasswordSchema = yup.object({
  email: emailSchema,
});

type ResetPasswordSchema = yup.InferType<typeof resetPasswordSchema>;

export { resetPasswordSchema };
export type { ResetPasswordSchema };
