import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import ContactPreferences from 'modules/owner/components/ContactPreferences';
import { getTranslation } from 'utils/getTranslation';

import { UserFormField } from '../types';

const t = getTranslation('donor.RegisterPetForm');

export enum UserField {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  ContactPreferences = 'contactPreferences',
}

export const userFields: UserFormField[] = [
  {
    name: UserField.FirstName,
    label: t('yourFirstName'),
    Component: ({ control, name }) => (
      <ControlledInput
        size="large"
        controlled={{ control, name }}
        placeholder={t('enterFirstName')}
      />
    ),
  },
  {
    name: UserField.LastName,
    label: t('yourLastName'),
    Component: ({ control, name }) => (
      <ControlledInput
        size="large"
        controlled={{ control, name }}
        placeholder={t('enterLastName')}
      />
    ),
  },
  {
    name: UserField.Email,
    label: t('yourEmail'),
    Component: ({ control, name }) => (
      <ControlledInput
        size="large"
        controlled={{ control, name }}
        placeholder={t('enterYourEmail')}
      />
    ),
  },
  {
    name: UserField.PhoneNumber,
    label: t('yourPhoneNumber'),
    Component: ({ control, name }) => (
      <ControlledPhoneInput
        controlled={{ control, name }}
        placeholder={t('enterPhoneNumber')}
        size="large"
      />
    ),
  },
  {
    name: UserField.ContactPreferences,
    label: t('yourPreferredContactMethod'),
    Component: ({ control, name }) => (
      <ContactPreferences size="large" controlled={{ control, name }} />
    ),
  },
];
