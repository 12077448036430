import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import config from 'config';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DonationOutput, DonationProductOutput } from 'types.d';

type PropTypes = {
  donation: DonationOutput;
} & TableProps<DonationProductOutput>;

const DonationProductsTable: FC<PropTypes> = ({ donation, ...restProps }) => {
  const { t } = useTranslation('products.DonationProductDescriptions');
  const columns: ColumnsType<DonationProductOutput> = [
    {
      title: t('value'),
      render: ({ value }: DonationProductOutput) => value,
    },
    {
      title: t('expirationDate'),
      render: ({ expirationDate }: DonationProductOutput) =>
        format(new Date(expirationDate), config.DATE_FORMAT),
    },
    {
      title: t('status'),
      render: ({ status }: DonationProductOutput) => status,
    },
  ];
  return (
    <Table
      {...restProps}
      size="small"
      dataSource={donation.products || []}
      columns={columns}
    />
  );
};

export default DonationProductsTable;
