import { Radio, RadioGroupProps } from 'antd';
import { FC, forwardRef, Ref } from 'react';

import styles from './styles.module.scss';

type Props = RadioGroupProps & { ref?: Ref<HTMLDivElement> };

const RadioButtonGroup: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ className, ...restRadioGroupProps }: RadioGroupProps, ref) => {
    const classNames = `${styles.radioGroup} ${className || ''}`;
    return (
      <Radio.Group ref={ref} className={classNames} {...restRadioGroupProps} />
    );
  }
);

export default RadioButtonGroup;
