import { Col, Divider } from 'antd';
import { FormItem, BadgeAsterisk } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import styles from 'modules/donations/components/CreateDonationForm/styles.module.scss';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getEnvironmentFields } from './fields';
import { environmentSchema } from './schema';

type Props = {
  fieldNamePrefix: string;
  enabled: boolean;
  sectionNamePrefix?: string;
};

const EnvironmentSection: FC<Props> = ({
  fieldNamePrefix,
  enabled,
  sectionNamePrefix,
}) => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('donation.CreateDonationForm.Environment');
  const fields = useMemo(() => getEnvironmentFields({ enabled }), [enabled]);
  const isRequired = getIsRequiredInSchema(environmentSchema);

  return (
    <>
      <Divider plain orientation="left">
        {t('environment')}
      </Divider>
      {fields.map(({ name, Component }) => {
        const sectionName = sectionNamePrefix ? sectionNamePrefix + '.' : '';
        const fieldName = `${sectionName}${fieldNamePrefix}.${name}`;
        const sectionErrors = sectionNamePrefix
          ? formState.errors[sectionNamePrefix]
          : formState.errors;
        const errorMessage = sectionErrors?.[fieldNamePrefix]?.[name]?.message;

        return (
          <Col key={name}>
            <FormItem
              className={styles.formItem}
              extra={errorMessage}
              validateStatus={errorMessage && 'error'}
            >
              <BadgeAsterisk hidden={!isRequired(name)}>
                <Component control={control} name={fieldName} />
              </BadgeAsterisk>
            </FormItem>
          </Col>
        );
      })}
    </>
  );
};

export default EnvironmentSection;
