import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import AttachDonorsModalProvider from 'modules/donor/contexts/AttachDonorsModal';
import AppointmentsDonors from 'modules/donor/pages/AppointmentsDonors';
import DonorDonationsPage from 'modules/donor/pages/DonorDonations';
import DonorLabworkPage from 'modules/donor/pages/DonorLabwork';
import DonorPicturesPage from 'modules/donor/pages/DonorPictures';
import DonorVitalsChartPage from 'modules/donor/pages/DonorVitalsChart';
import GroupProfilePage from 'modules/donor/pages/GroupProfile';
import GroupProfileTeamsPage from 'modules/donor/pages/GroupProfileTeams';
import LabworkPage from 'modules/donor/pages/Labwork';
import ReserveDonors from 'modules/donor/pages/ReserveDonors';
import { UserRole, PermissionsEnum } from 'types.d';

import CreateTeamModalProvider from './contexts/CreateTeamModal';
import LabworkAttachmentsModalProvider from './contexts/LabworkAttachmentsModal';
import PrintLabworkModalProvider from './contexts/PrintLabworkFormModal';
import UpdateDonorsOwnerModalProvider from './contexts/UpdateDonorsOwnerModal';
import UpdateGroupsOwnerModalProvider from './contexts/UpdateGroupsOwnerModal';
import CreateDonorPage from './pages/CreateDonor';
import DonorApplicationPage from './pages/DonorApplication';
import DonorNotesPage from './pages/DonorNotes';
import DonorsPage from './pages/Donors';
import GroupsPage from './pages/Groups';
import IndividualDonorListPage from './pages/IndividualDonorList';
import DonorProfilePage from './pages/Profile';
import RegisterPetPage from './pages/RegisterPet';

const donorModule: IModule = {
  routes: [
    {
      path: Routes.Donors,
      Component: DonorsPage,
      type: RouteType.Auth,
      roles: [UserRole.Owner],
    },
    {
      path: Routes.DonorsApplication,
      Component: DonorApplicationPage,
      type: RouteType.Auth,
      roles: [UserRole.Owner],
    },
    {
      path: Routes.IndividualDonors,
      Component: IndividualDonorListPage,
      type: RouteType.Auth,
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
      permissions: [PermissionsEnum.ViewTheListOfDonors],
    },
    {
      path: Routes.GroupDonors,
      Component: GroupsPage,
      type: RouteType.Auth,
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
      permissions: [PermissionsEnum.ViewTheListOfDonors],
    },
    {
      path: Routes.GroupProfile,
      Component: GroupProfilePage,
      type: RouteType.Auth,
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
      permissions: [PermissionsEnum.ViewTheListOfDonors],
    },
    {
      path: Routes.GroupProfileTeams,
      Component: GroupProfileTeamsPage,
      type: RouteType.Auth,
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
      permissions: [PermissionsEnum.ViewTheListOfDonors],
    },
    {
      path: Routes.RegisterPet,
      Component: RegisterPetPage,
      type: RouteType.Public,
    },
    {
      path: Routes.DonorsProfile,
      Component: DonorProfilePage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewTheListOfDonors],
    },
    {
      path: Routes.DonorsPictures,
      Component: DonorPicturesPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewDonorsPictures],
    },
    {
      path: Routes.DonorsNotes,
      Component: DonorNotesPage,
      type: RouteType.Auth,
    },
    {
      path: Routes.DonorsLabworkBy,
      Component: DonorLabworkPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewLabworkResults],
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
    },
    {
      path: Routes.Labwork,
      Component: LabworkPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewLabworkResults],
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
    },
    {
      path: Routes.DonorsDonations,
      Component: DonorDonationsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewSpecificDonationDataAtDonorProfile],
    },
    {
      path: Routes.DonorsVitalsChart,
      Component: DonorVitalsChartPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewVitalsChart],
    },
    {
      path: Routes.CreateDonors,
      Component: CreateDonorPage,
      type: RouteType.Auth,
      roles: [
        UserRole.Admin,
        UserRole.CollectionTeam,
        UserRole.DogHandler,
        UserRole.MedicalDirector,
        UserRole.InventoryTeam,
      ],
      permissions: [PermissionsEnum.UpdateDonorsData],
    },
    {
      path: Routes.ReserveDonors,
      Component: ReserveDonors,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewTheListOfTrainingReserveDonors],
    },
    {
      path: Routes.AppointmentsDonors,
      Component: AppointmentsDonors,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ViewTheListOfDonors],
    },
  ],
  providers: [
    { Provider: CreateTeamModalProvider },
    { Provider: AttachDonorsModalProvider },
    { Provider: UpdateDonorsOwnerModalProvider },
    { Provider: UpdateGroupsOwnerModalProvider },
    { Provider: LabworkAttachmentsModalProvider },
    { Provider: PrintLabworkModalProvider },
  ],
};

export default donorModule;
