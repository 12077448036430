import { ComponentWithChildren } from 'modules/common/types';
import { createContext, useContext } from 'react';

import { ContextType } from './type';
import { useProviderValue } from './value';

const PrintLabworkModalContext = createContext<ContextType | null>(null);

const PrintLabworkModalProvider: ComponentWithChildren = ({ children }) => {
  const providerValue = useProviderValue();

  return (
    <PrintLabworkModalContext.Provider value={providerValue}>
      {children}
    </PrintLabworkModalContext.Provider>
  );
};

export const usePrintLabworkModalContext = () => {
  const context = useContext(PrintLabworkModalContext);
  if (context === undefined) {
    throw new Error(
      'the usePrintLabworkModalContext should be used within LabworkAttachmentsModalProvider!'
    );
  }

  return context;
};

export default PrintLabworkModalProvider;
