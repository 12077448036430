import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { addMilliseconds, isPast } from 'date-fns';
import useOnlineStatus from 'modules/common/hooks/useOnlineStatus';
import { ComponentWithChildren } from 'modules/common/types';
import { getOfflineMutations } from 'providers/offlineMutations/getOfflineMutations';
import { useEffect } from 'react';
import { envServices } from 'utils/EnvServices';

import { resetCache } from './resetCache';

const cacheExpirationTime =
  envServices.get('REACT_APP_CACHE_EXPIRATION') * 60000;
const isCachedData = localStorage.getItem('apollo-cache-persist');

const CacheExpirationProvider: ComponentWithChildren = ({ children }) => {
  const isOnline = useOnlineStatus();
  const offlineMutations = getOfflineMutations();

  const checkCacheExpiration = async () => {
    const isCacheExpired = checkIsCacheExpired();

    if (
      !isCacheExpired ||
      !isOnline ||
      offlineMutations.length ||
      !isCachedData
    ) {
      return;
    }

    localStorage.setItem(
      LOCALE_STORAGE_KEYS.CACHE_EXPIRES_IN,
      addMilliseconds(new Date(), cacheExpirationTime).toISOString()
    );
    await resetCache();
  };

  useEffect(() => {
    const interval = setInterval(checkCacheExpiration, 5000);
    return () => clearInterval(interval);
  }, []);

  return <>{children}</>;
};

const checkIsCacheExpired = () => {
  const cacheExpiresIn = localStorage.getItem(
    LOCALE_STORAGE_KEYS.CACHE_EXPIRES_IN
  );

  if (cacheExpiresIn == null) {
    return true;
  }

  return isPast(new Date(cacheExpiresIn));
};

export default CacheExpirationProvider;
