import { SelectOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RequestOutput } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  request: RequestOutput;
  canUserProcessRequest: boolean;
};

const DateCell: FC<PropTypes> = ({ request, canUserProcessRequest }) => {
  const navigate = useNavigate();

  const navigateToRequest = () => {
    navigate(`${Routes.Requests}/${request.id}`);
  };
  const justify = canUserProcessRequest ? 'space-between' : 'center';

  return (
    <Row gutter={[10, 0]} justify={justify}>
      <Col>{format(new Date(request.updatedAt), config.DATE_FORMAT)}</Col>
      <Col>
        {canUserProcessRequest && (
          <SelectOutlined
            onClick={navigateToRequest}
            className={`linkIcon ${styles.linkIcon}`}
          />
        )}
      </Col>
    </Row>
  );
};

export default DateCell;
