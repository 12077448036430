import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Table } from 'antd';
import config from 'config';
import { format, nextMonday, previousMonday, addDays, subDays } from 'date-fns';
import { Spin } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  AppointmentOutput,
  AppointmentsFilterInput,
  PermissionsEnum,
  useGetAppointmentsQuery,
} from 'types.d';

import { getColumns } from './columns';
import Header from './components/Header';
import { TaskListMode } from './enum';
import { getDateIntervalsByMode } from './intervals';
import {
  appointmentsCalendarSchema,
  AppointmentsCalendarSchemaType,
} from './schema';
import styles from './styles.module.scss';

const CURRENT_DATE = new Date();

const TaskSchedule: FC = () => {
  const [searchParams] = useSearchParams();
  const user = searchParams.get('user') || '';
  const { debounce, clearDebounce } = useDebounce(1000);
  const [appointmentsFilter, setAppointmentsFilter] =
    useState<AppointmentsFilterInput>({});
  const { control, watch, setValue } = useForm<AppointmentsCalendarSchemaType>({
    resolver: yupResolver(appointmentsCalendarSchema),
  });
  const [mode, setMode] = useState<TaskListMode>(TaskListMode.Weekly);
  const [selectedDay, setSelectedDay] = useState(CURRENT_DATE);
  const currentUser = useCurrentUser();
  const canViewAppointmentsHistory = currentUser.isCan(
    PermissionsEnum.ViewTheHistoryOfAppointments
  );
  const minDate = canViewAppointmentsHistory ? undefined : CURRENT_DATE;
  const dateIntervalsByMode = getDateIntervalsByMode({ selectedDay, minDate });
  const taskListRef = useRef(null);
  const printTaskList = useReactToPrint({
    content: () => taskListRef.current,
    documentTitle: `${mode}_tasklist_${format(
      dateIntervalsByMode[mode].start,
      config.DATE_FORMAT
    )}_${format(dateIntervalsByMode[mode].end, config.DATE_FORMAT)}`,
  });
  const taskListColumns = useMemo(
    () =>
      getColumns({
        mode,
        selectedDay,
        onDayClick: (date) => {
          setMode(TaskListMode.Daily);
          setSelectedDay(date);
        },
      }),
    [mode, selectedDay]
  );

  const updateFilter = (filterValue: AppointmentsFilterInput) => {
    debounce(setAppointmentsFilter)({
      ...appointmentsFilter,
      ...filterValue,
    });
  };

  const onNextHandler = () => {
    const newSelectedDay =
      mode === TaskListMode.Weekly
        ? nextMonday(selectedDay)
        : addDays(selectedDay, 1);
    setSelectedDay(newSelectedDay);
  };

  const onPreviousHandler = () => {
    const newSelectedDay =
      mode === TaskListMode.Weekly
        ? previousMonday(selectedDay)
        : subDays(selectedDay, 1);
    setSelectedDay(newSelectedDay);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case 'userIds':
          updateFilter({
            userIds: value.userIds as string[] | undefined,
          });
          break;
        case 'locations':
          updateFilter({
            locations: value.locations as string[],
          });
          break;
      }
    });
    return () => {
      subscription.unsubscribe();
      clearDebounce();
    };
  }, [watch]);

  useEffect(() => {
    if (user && user.length > 0) setValue('userIds', [user]);
  }, []);

  const getAppointmentsQuery = useGetAppointmentsQuery({
    variables: {
      input: {
        ...dateIntervalsByMode[mode],
        filter: appointmentsFilter,
      },
    },
  });
  const appointments = getAppointmentsQuery.data?.getAppointments.reduce(
    (filteredAppointments, appointment) => {
      const { start, end } = appointment;
      const appointmentKey = `${format(
        new Date(start),
        config.DATE_AND_TIME_FORMAT
      )}_${format(new Date(end), config.DATE_AND_TIME_FORMAT)}`;
      return {
        ...filteredAppointments,
        [appointmentKey]: [
          ...(filteredAppointments[appointmentKey] || []),
          appointment as AppointmentOutput,
        ],
      };
    },
    {} as Record<string, AppointmentOutput[]>
  );

  return (
    <Spin spinning={getAppointmentsQuery.loading}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Header
            mode={mode}
            onTypeChange={setMode}
            control={control}
            onPrint={printTaskList}
            onPrevious={onPreviousHandler}
            onNext={onNextHandler}
            selectedDay={selectedDay}
          />
        </Col>
        <Col span={24}>
          <Table
            ref={taskListRef}
            rowKey={([appointment]) => appointment.id}
            columns={taskListColumns}
            dataSource={Object.values(appointments || {})}
            pagination={false}
            rowClassName={styles.row}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default TaskSchedule;
