import { Input as AntInput, InputRef } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const Input = forwardRef<InputRef, PasswordProps>((props, ref) => {
  const className = `${styles.passwordInput} ${props.className || ''}`;
  return <AntInput.Password className={className} ref={ref} {...props} />;
});

export default Input;
