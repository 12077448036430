import { gql } from '@apollo/client';
import {
  APPOINTMENT_DONOR_FRAGMENT,
  APPOINTMENT_FRAGMENT,
  APPOINTMENT_GROUP_FRAGMENT,
  APPOINTMENT_TEAM_FRAGMENT,
  APPOINTMENT_USER_FRAGMENT,
} from 'modules/appointments/graphql/fragments';
import { DONOR_FRAGMENT } from 'modules/donor/graphql/fragments';
import { OWNER_FRAGMENT } from 'modules/owner/graphql/fragments';
import {
  CONTACT_OUTPUT_FRAGMENT,
  NOTIFICATION_USER_OUTPUT_FRAGMENT,
  ROLE_WITHOUT_PERMISSIONS_OUTPUT_FRAGMENT,
} from 'modules/user/graphql/fragments';

import {
  NOTIFICATION_CONFIG_FRAGMENT,
  NOTIFICATION_FRAGMENT,
} from '../fragments';

export const GET_NOTIFICATIONS_CONFIG_QUERY = gql`
  query getNotificationConfigList {
    getNotificationConfigList {
      ...NotificationConfig
    }
  }
  ${NOTIFICATION_CONFIG_FRAGMENT}
`;

export const GET_NOTIFICATION_LIST = gql`
  query getNotificationList($input: GetNotificationListInput!) {
    getNotificationsList(input: $input) {
      data {
        ...Notification
        payload {
          appointment {
            ...Appointment
            users {
              ...AppointmentUser
              contacts {
                ...Contact
              }
            }
            donor {
              ...AppointmentDonor
              user {
                ...AppointmentUser
                contacts {
                  ...Contact
                }
              }
            }
            group {
              ...AppointmentGroup
              user {
                ...AppointmentUser
                contacts {
                  ...Contact
                }
              }
            }
            team {
              ...AppointmentTeam
            }
          }
          request {
            donor {
              ...Donor
              team {
                id
                title
              }
              user {
                ...Owner
                contacts {
                  ...Contact
                }
              }
            }
            id
            number
            updatedAt
          }
        }
        user {
          ...NotificationUser
          contacts {
            ...Contact
          }
          role {
            ...RoleWithoutPermissions
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${APPOINTMENT_FRAGMENT}
  ${APPOINTMENT_DONOR_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${APPOINTMENT_USER_FRAGMENT}
  ${NOTIFICATION_FRAGMENT}
  ${ROLE_WITHOUT_PERMISSIONS_OUTPUT_FRAGMENT}
  ${NOTIFICATION_USER_OUTPUT_FRAGMENT}
  ${APPOINTMENT_GROUP_FRAGMENT}
  ${APPOINTMENT_TEAM_FRAGMENT}
  ${DONOR_FRAGMENT}
  ${OWNER_FRAGMENT}
`;
