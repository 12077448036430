import { MutationHookOptions } from '@apollo/client';
import { message, Popconfirm } from 'antd';
import { Button } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OwnerOutput,
  PermissionsEnum,
  useArchiveUserMutation,
  UserOutput,
  useUnArchiveUserMutation,
} from 'types.d';

type PropTypes = {
  user: UserOutput | OwnerOutput;
};

const ArchivingButton: FC<PropTypes> = ({ user }) => {
  const { t } = useTranslation('user.ArchivingButton');
  const currentUser = useCurrentUser();
  const baseMutationsOptions: MutationHookOptions<any, any> = {
    refetchQueries: ['getOwner'],
    variables: {
      input: {
        userId: user.id,
      },
    },
    onError: (error) => {
      message.error(error.message);
    },
  };
  const canArchiveOwners = currentUser.isCan(PermissionsEnum.ArchiveOwners);
  const [archiveUser, archiveUserMutation] = useArchiveUserMutation({
    ...baseMutationsOptions,
    onCompleted: () => {
      message.success(t('ownerArchived'));
    },
  });
  const [unArchiveUser, unArchiveUserMutation] = useUnArchiveUserMutation({
    ...baseMutationsOptions,
    onCompleted: () => {
      message.success(t('ownerActivated'));
    },
  });

  return (
    <>
      {user.isArchived ? (
        <Button
          loading={unArchiveUserMutation.loading}
          disabled={!canArchiveOwners}
          onClick={() => unArchiveUser()}
        >
          {t('unarchive')}
        </Button>
      ) : (
        <Popconfirm
          disabled={!canArchiveOwners}
          title={t('archiveConfirmation')}
          onConfirm={() => archiveUser()}
        >
          <Button
            loading={archiveUserMutation.loading}
            disabled={!canArchiveOwners}
          >
            {t('archive')}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default ArchivingButton;
