import { Sorter } from 'modules/common/components';
import { useSort } from 'modules/common/hooks';
import { options } from 'modules/donor/components/LabworkCardList/LabworkCardListSorter/options';
import { FC } from 'react';
import { LabworkListSort, Order } from 'types.d';

const useLabworkListSort = () => useSort<LabworkListSort>();

type PropTypes = {
  sort: ReturnType<typeof useLabworkListSort>;
};

export const LabworkCardListSorter: FC<PropTypes> = ({ sort }) => {
  return (
    <Sorter
      sorting={sort as ReturnType<typeof useSort>}
      options={options}
      defaultFieldName={LabworkListSort.CollectionDate}
      defaultOrder={Order.Desc}
    />
  );
};
