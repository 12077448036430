import { SorterResult } from 'antd/lib/table/interface';
import { Order } from 'types.d';

type StandardEnum<T> = {
  [key: string]: T;
};

const sortMap = {
  ascend: Order.Asc,
  descend: Order.Desc,
};

export const getMapSorter =
  <OutputType, EnumType>(listSort: StandardEnum<EnumType>) =>
  (sorters: SorterResult<OutputType>[]) => {
    const mapSorter = sorters.flatMap((sorter) => {
      const sorterKey =
        sorter.columnKey?.toString() as keyof StandardEnum<EnumType>;
      const mappedKey = listSort[sorterKey];
      const mappedOrder = sorter.order ? sortMap[sorter.order] : undefined;
      return mappedOrder ? { fieldName: mappedKey, order: mappedOrder } : [];
    });
    return mapSorter.length ? mapSorter : undefined;
  };
