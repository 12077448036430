import { ControlledSelect } from 'modules/common/components';
import { FC, useEffect } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BloodType, useGetWoocommerceProductsLazyQuery } from 'types.d';

import { productByBloodType } from './productsByBloodType';
import styles from './styles.module.scss';

type PropTypes = {
  productCategoryId?: number;
  controlled: UseControllerProps<any>;
  donorBloodType?: BloodType;
  onChangeDefaultValue?: (value: { label: string; value: number }) => void;
};

const ProductTypeSelect: FC<PropTypes> = ({
  productCategoryId,
  controlled,
  donorBloodType,
  onChangeDefaultValue,
}) => {
  const { t } = useTranslation('products.ProductTypeSelect');
  const [getWoocommerceProduct, getWoocommerceProductQuery] =
    useGetWoocommerceProductsLazyQuery({
      variables: {
        input: {
          category: productCategoryId,
        },
      },
      onCompleted: ({ getWoocommerceProducts }) => {
        const products = getWoocommerceProducts.data.map(({ id, name }) => ({
          value: id,
          label: name,
        }));

        const filteredProducts =
          donorBloodType &&
          products?.filter(({ value }) => {
            return productByBloodType[donorBloodType].includes(value);
          });

        const defaultValue =
          filteredProducts?.length === 1 ? filteredProducts[0] : undefined;

        defaultValue && onChangeDefaultValue?.(defaultValue);
      },
    });

  useEffect(() => {
    if (productCategoryId !== undefined) {
      getWoocommerceProduct({
        variables: {
          input: {
            category: productCategoryId,
          },
        },
      });
    }
  }, [productCategoryId]);

  const products =
    getWoocommerceProductQuery.data?.getWoocommerceProducts.data.map(
      ({ id, name }) => ({ value: id, label: name })
    );

  const filteredProducts =
    donorBloodType &&
    products?.filter(({ value }) => {
      return productByBloodType[donorBloodType].includes(value);
    });

  return (
    <ControlledSelect
      loading={getWoocommerceProductQuery.loading}
      className={styles.select}
      options={filteredProducts?.length ? filteredProducts : products}
      controlled={controlled}
      placeholder={t('selectProductType')}
    />
  );
};

export default ProductTypeSelect;
