import { IField } from 'modules/common/interfaces';
import { getTranslation } from 'utils/getTranslation';

import { AcceptInviteFields } from './enum';

const acceptInviteTranslations = getTranslation('admin.AcceptInvitePage');

const fields: IField<AcceptInviteFields>[] = [
  {
    label: acceptInviteTranslations('password'),
    name: AcceptInviteFields.password,
    placeholder: acceptInviteTranslations('enterPassword'),
  },
  {
    label: acceptInviteTranslations('confirmPassword'),
    name: AcceptInviteFields.confirmPassword,
    placeholder: acceptInviteTranslations('confirmPassword'),
  },
];

export default fields;
