import { Table, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useSort } from 'modules/common/hooks';
import { getColumns } from 'modules/donor/components/DonorDonationsTable/columns';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  DonationListSort,
  DonationOutput,
  Order,
  PermissionsEnum,
} from 'types.d';

const useDonationListSort = () => useSort<DonationListSort>();

type Props = {
  donations: DonationOutput[];
  addCommentHandler: (donationId: string, previousValue?: string) => void;
  sort: ReturnType<typeof useDonationListSort>;
  pagination: ReturnType<typeof usePagination>;
  productCategories?: Record<number, string>;
};

const DonorDonationsTable: FC<Props> = ({
  sort,
  addCommentHandler,
  donations,
  pagination,
  productCategories,
}) => {
  const params = useParams();
  const shortDonorId = params.id || '';
  const currentUser = useCurrentUser();
  const canAddCommentsToDonation = currentUser.isCan(
    PermissionsEnum.AddCommentsToTheDonationAtTheDonorProfile
  );

  const onTableChange: TableProps<DonationOutput>['onChange'] = (
    _,
    _filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<DonationOutput>;
      const fieldName =
        DonationListSort[
          tableSorter.columnKey?.toString() as keyof typeof DonationListSort
        ];
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        sort.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        sort.removeSortOption(fieldName);
      }
    }
  };

  const columns = useMemo(
    () =>
      getColumns({
        updateDonationHandler: addCommentHandler,
        canAddCommentsToDonation,
        productCategories,
      }),
    [shortDonorId]
  );

  return (
    <Table
      rowKey={({ id }) => id}
      columns={columns}
      dataSource={donations}
      onChange={onTableChange}
      scroll={{ x: 750 }}
      pagination={pagination.config}
    />
  );
};
export default DonorDonationsTable;
