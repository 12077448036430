import { CheckOutlined } from '@ant-design/icons';
import { FC } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  onClick: () => void;
  showTick: boolean;
};

const Tick: FC<PropTypes> = ({ onClick, showTick }) => {
  return (
    <div
      className={`${styles.container} ${
        showTick ? styles.containerOn : styles.containerOff
      }`}
      onClick={onClick}
    >
      <CheckOutlined />
    </div>
  );
};

export default Tick;
