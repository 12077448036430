import { Col, Row } from 'antd';
import DonationProfileCardTabs from 'modules/donations/components/DonationProfileCardTabs';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const AdditionalDonationInfoPage: FC = () => {
  const { t } = useTranslation('donation.AdditionalDonationInfoPage');
  const params = useParams();
  const donationId = params.id;

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Row>
        <Col span={24}>
          {donationId && <DonationProfileCardTabs donationId={donationId} />}
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default AdditionalDonationInfoPage;
