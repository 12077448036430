import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, PopconfirmProps } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DeletePopconfirm: FC<PopconfirmProps> = ({ title, ...restProps }) => {
  const { t } = useTranslation('common.DeletePopconfirm');

  return (
    <Popconfirm
      title={title || t('doYouWantToDelete')}
      okText={t('yes')}
      cancelText={t('no')}
      {...restProps}
    >
      <DeleteOutlined />
    </Popconfirm>
  );
};

export default DeletePopconfirm;
