import config from 'config';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationPayloadOutput } from 'types';

type PropTypes = {
  payload: NotificationPayloadOutput;
};

const DonorUnenrolled: FC<PropTypes> = ({ payload }) => {
  const { t } = useTranslation('notification.NotificationCard.DonorUnenrolled');
  const appointment = payload?.appointment;
  if (!appointment) {
    return null;
  }
  const owner = appointment.donor?.user;
  const content = t('donorJustUnenrolled', {
    appointmentTitle: appointment.title,
    ownerName: `${owner?.lastName} ${owner?.firstName}`,
    donorName: `${appointment.donor?.name} ${appointment.donor?.user?.lastName}`,
    start: format(new Date(appointment.start), config.LOCALIZED_TIME_FORMAT),
    end: format(new Date(appointment.end), config.LOCALIZED_TIME_FORMAT),
    context: appointment.group ? 'withDonor' : undefined,
  });
  return <>{content}</>;
};

export default DonorUnenrolled;
