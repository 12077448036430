import {
  ControlledInput,
  ControlledInputNumber,
  ControlledSelect,
  ControlledTextArea,
} from 'modules/common/components';
import { YearMonthSelect } from 'modules/common/components/YearMonthSelect';
import {
  ControlledYesNoRadioGroup,
  LocationSelect,
  DonorSpeciesRadioGroup,
  DonorGenderRadioGroup,
} from 'modules/donor/components';
import RegionSelect from 'modules/locations/components/RegionSelect/inex';
import { AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from '../styles.module.scss';
import { PetFormField } from '../types';

export enum PetField {
  Name = 'name',
  DateOfBirth = 'dateOfBirth',
  Neutered = 'neutered',
  Weight = 'weight',
  HasTransfusion = 'hasTransfusion',
  TakeMedications = 'takeMedications',
  PrimaryVeterinarian = 'primaryVeterinarian',
  Species = 'species',
  Breed = 'breed',
  Gender = 'gender',
  MedicationsCourseComment = 'medicationCourse',
  Region = 'region',
  Location = 'location',
  HasHeartMurmur = 'hasHeartMurmur',
  HasMicrochip = 'hasMicrochip',
  AttendDaycareDays = 'attendDaycareDays',
  WontRegularlyAttendDaycare = 'wontRegularlyAttendDaycare',
}

const t = getTranslation('donor.RegisterPetForm');

type ParamTypes = {
  donorSpecie?: AnimalSpecies;
  region?: string;
};

export const getPetFields = ({
  donorSpecie = AnimalSpecies.Dog,
  region,
}: ParamTypes): PetFormField[] => {
  const petFields: PetFormField[] = [
    {
      name: PetField.Name,
      label: t('petName'),
      Component: ({ control, name }) => (
        <ControlledInput
          size="large"
          controlled={{ control, name }}
          placeholder={t('typePetsName')}
        />
      ),
    },
    {
      name: PetField.DateOfBirth,
      label: t('petAge'),
      Component: ({ control, name }) => (
        <YearMonthSelect
          size="large"
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: PetField.Species,
      label: t('petSpecies'),
      Component: ({ control, name }) => (
        <DonorSpeciesRadioGroup
          size="large"
          defaultValue={undefined}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: PetField.Breed,
      label: t('petBreed'),
      Component: ({ control, name }) => (
        <ControlledInput
          size="large"
          controlled={{ control, name }}
          placeholder={t('typeBreed')}
        />
      ),
    },
    {
      name: PetField.Gender,
      label: t('gender'),
      Component: ({ control, name }) => (
        <DonorGenderRadioGroup
          size="large"
          defaultValue={undefined}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: PetField.Neutered,
      label: t('neutered'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          size="large"
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: PetField.Weight,
      label: t('petWeight', { context: donorSpecie }),
      Component: ({ control, name }) => (
        <ControlledInputNumber
          size="large"
          className={styles.inputNumber}
          controlled={{ control, name }}
          placeholder={t('typePetsWeight')}
        />
      ),
    },
    {
      name: PetField.TakeMedications,
      label: t('doesItTakeMedications'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          size="large"
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: PetField.MedicationsCourseComment,
      label: t('medicationCommentary'),
      tooltip: `${t('kindOfMedication')} \n ${t('medicationTerm')}`,
      Component: ({ control, name }) => (
        <ControlledTextArea
          size="large"
          className={styles.yesNoFiled}
          controlled={{ control, name: name }}
          placeholder={t('yourAnswer')}
        />
      ),
    },
    {
      name: PetField.HasTransfusion,
      label: t('hasItTransfusion'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          size="large"
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: PetField.HasHeartMurmur,
      label: t('hasItHeartworms'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          size="large"
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: PetField.PrimaryVeterinarian,
      label: t('primaryVeterinarian'),
      Component: ({ control, name }) => (
        <ControlledInput
          size="large"
          controlled={{
            control,
            name,
          }}
          placeholder={t('yourAnswer')}
        />
      ),
    },
    {
      name: PetField.HasMicrochip,
      label: t('doesPetHaveMicrochip'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          size="large"
          className={styles.yesNoFiled}
          controlled={{ control, name }}
        />
      ),
    },
    {
      name: PetField.Region,
      label: t('locationPreferences'),
      Component: ({ control, name }) => (
        <RegionSelect
          size="large"
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
    {
      name: PetField.Location,
      Component: ({ control, name }) => (
        <LocationSelect
          size="large"
          publicLocationsOnly
          controlled={{
            control,
            name,
          }}
          regions={region ? [region] : undefined}
          placeholder={t('yourAnswer')}
        />
      ),
    },
    {
      name: PetField.AttendDaycareDays,
      label: t('attendDaycareDays'),
      Component: ({ control, name, disabled }) => (
        <ControlledSelect
          disabled={disabled}
          controlled={{
            control,
            name,
          }}
          options={[
            { label: 'Monday', value: 'Monday' },
            { label: 'Tuesday', value: 'Tuesday' },
            { label: 'Wednesday', value: 'Wednesday' },
            { label: 'Thursday', value: 'Thursday' },
            { label: 'Friday', value: 'Friday' },
          ]}
          mode="multiple"
          placeholder={t('attendDaycareDaysPlaceholder')}
          size="large"
        />
      ),
    },
    {
      name: PetField.WontRegularlyAttendDaycare,
      label: t('isRegularlyAttendDaycare'),
      Component: ({ control, name }) => (
        <ControlledYesNoRadioGroup
          size="large"
          defaultValue={false}
          controlled={{
            control,
            name,
          }}
        />
      ),
    },
  ];
  return petFields;
};
