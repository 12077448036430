import {
  petSpeciesSchema,
  petGenderSchema,
  petWeightSchemaInKg,
  petNameSchema,
  petDateOfBirthSchema,
} from 'modules/donor/schemas';
import { AnimalGender, AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { RequiredFormSectionField } from './fields';

const {
  Name,
  Age,
  Neutered,
  Weight,
  Species,
  Gender,
  MedicationsCourseComment,
  PrimaryVeterinarian,
  LastName,
  Location,
  DonationFrequency,
  AcceptSimparicaTrio,
  DateOfBirth,
} = RequiredFormSectionField;

type RequiredFormSectionSchemaType = {
  name: string;
  age: number;
  dateOfBirth: Date;
  neutered: boolean;
  weight: number;
  species: AnimalSpecies;
  gender: AnimalGender;
  medicationCourse?: string;
  primaryVeterinarian: string;
  lastName?: string;
  location: string;
  donationFrequency: number;
  acceptSimparicaTrio: boolean;
};

const t = getTranslation('donor.ValidationErrors');

const requiredFormSectionSchema: yup.SchemaOf<RequiredFormSectionSchemaType> =
  yup.object({
    [Name]: petNameSchema,
    [Age]: yup
      .number()
      .integer(t('mustBeInt'))
      .typeError(t('mustBeNumber'))
      .required(t('required', { field: t('petAge') })),
    [DateOfBirth]: petDateOfBirthSchema,
    [Neutered]: yup.boolean().required(t('required', { field: t('neutered') })),
    [Weight]: petWeightSchemaInKg,
    [Species]: petSpeciesSchema,
    [Gender]: petGenderSchema,
    [MedicationsCourseComment]: yup
      .string()
      .max(255, t('shorterThan', { max: 255, field: t('medicationsHistory') })),
    [PrimaryVeterinarian]: yup
      .string()
      .required(t('required', { field: t('primaryVeterinarian') }))
      .matches(/^[a-zA-Z\s]+$/, t('onlyAlphabets'))
      .max(
        255,
        t('shorterThan', { max: 255, field: t('primaryVeterinarian') })
      ),
    [LastName]: yup.string(),
    [Location]: yup
      .string()
      .required(t('required', { field: t('location') }))
      .max(100, t('maxLengthIs', { max: 100 })),
    [DonationFrequency]: yup
      .number()
      .typeError(t('mustBeNumber'))
      .required(t('required', { field: t('donationFrequency') }))
      .min(4, t('moreThan', { field: t('donationFrequency'), moreThan: 4 }))
      .max(20, t('lessThan', { field: t('donationFrequency'), lessThan: 20 })),
    [AcceptSimparicaTrio]: yup
      .boolean()
      .required(t('required', { field: t('acceptSimparicaTrio') })),
  });

export { requiredFormSectionSchema };
export type { RequiredFormSectionSchemaType };
