import { Badge, Col, Modal, Row, Spin, Tabs } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { BaseModalProps } from 'modules/common/types';
import { FC, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppointmentOutput,
  DaycareAppointmentRequestOutput,
  DaycareAppointmentResponseOutput,
  useGetDaycareAppointmentInfoLazyQuery,
} from 'types.d';

import { AddDonorsButton } from './AddDonorsButton';
import styles from './styles.module.scss';

import { DaycareAppointmentDetailTable } from '../DaycareAppointmentDetailTable';
import { DaycareAppointmentRequestsTable } from '../DaycareAppointmentRequestTable';

type PropTypes = {
  appointment?: AppointmentOutput;
} & BaseModalProps;

export const DaycareAppointmentDetail: FC<PropTypes> = ({
  appointment,
  onCancel,
  visible,
  onOk,
  hide,
  ...restModalProps
}) => {
  const { t } = useTranslation('appointments.DaycareDetailModal');
  const [getDaycareAppointmentInfo, daycareAppointmentInfoQuery] =
    useGetDaycareAppointmentInfoLazyQuery();

  useEffect(() => {
    if (!appointment) {
      return;
    }

    const getInfo = async () => {
      await getDaycareAppointmentInfo({
        fetchPolicy: 'network-only',
        variables: {
          input: appointment.id,
        },
      });
    };

    getInfo();
  }, [appointment]);

  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    onCancel && onCancel(e);
    hide();
  };

  const okHandler = async (e: MouseEvent<HTMLElement>) => {
    onOk && onOk(e);
    hide();
  };

  if (!appointment) {
    return null;
  }

  const meta = daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfo.meta;
  const start = new Date(appointment.start);
  const end = new Date(appointment.end);

  const date = format(start, config.DATE_FORMAT);
  const startHour = format(start, config.TIME_FORMAT);
  const endHour = format(end, config.TIME_FORMAT);

  const requestsCount =
    daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfo.requests.length;
  const donorAppointmentInfo =
    (daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfo
      .interactions as DaycareAppointmentResponseOutput[]) || [];
  const selectedDonorIds = donorAppointmentInfo.reduce(
    (registeredDonorIds, { registeredDonors }) => {
      const donorIds = registeredDonors.map(({ id }) => id);
      return [...registeredDonorIds, ...donorIds];
    },
    [] as string[]
  );

  return (
    <Modal
      className={styles.modal}
      visible={visible}
      onCancel={cancelHandler}
      onOk={okHandler}
      cancelButtonProps={{ hidden: true }}
      title={t('title')}
      {...restModalProps}
    >
      <Spin spinning={daycareAppointmentInfoQuery.loading}>
        <Row className={styles.center}>{appointment.location}</Row>
        <Row className={styles.center}>
          {`${date} ${startHour} - ${endHour}`}
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            {t('registeredDonors')} {meta?.registeredDonors}/
            {meta?.maxDonorsCapacity}
          </Col>
          <Col>
            <AddDonorsButton
              selectedDonorIds={selectedDonorIds}
              appointmentId={appointment.id}
              location={appointment.location}
            />
          </Col>
        </Row>
        <Row>
          <Tabs defaultActiveKey="1" className={styles.table}>
            <Tabs.TabPane tab={t('registered')} key={1}>
              <DaycareAppointmentDetailTable
                daycareAppointmentInfo={donorAppointmentInfo}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <Badge dot={!!requestsCount} size="small">
                  {t('requests')}
                </Badge>
              }
              key={2}
            >
              <DaycareAppointmentRequestsTable
                daycareAppointmentRequests={
                  (daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfo
                    .requests as DaycareAppointmentRequestOutput[]) || []
                }
              />
            </Tabs.TabPane>
          </Tabs>
        </Row>
      </Spin>
    </Modal>
  );
};
