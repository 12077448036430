import RadioGroup from 'modules/common/components/RadioGroup';
import { FC, ComponentProps } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type PropTypes = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof RadioGroup>;

const ControlledRadioGroup: FC<PropTypes> = ({ controlled, ...restProps }) => {
  const controller = useController(controlled);
  return <RadioGroup {...controller.field} {...restProps} />;
};

export default ControlledRadioGroup;
