import { AdditionalDonationInfoFields } from 'modules/donations/components/AdditionalDonationInfoForm/enum';
import {
  collectionUnitsUsedSchema,
  veinUsedSchema,
  vascularStructureSchema,
  flowRateSchema,
} from 'modules/donations/schemas';
import {
  CollectionUnitsUsed,
  VeinUsed,
  VascularStructure,
  FlowRate,
} from 'types';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const {
  numberOfCollectionUnitsUsed,
  veinUsed,
  vascularStructure,
  flowRate,
  donationPosition,
  holdersPosition,
  donorMovement,
  typeOfMovement,
  emotionalSupport,
  dislikes,
  likes,
  treatPreferences,
  primaryHolder,
  secondaryHolder,
  poker,
} = AdditionalDonationInfoFields;

type AdditionalDonationInfoSchema = {
  // totalCollectedMls: number;
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  veinUsed: VeinUsed;
  vascularStructure?: VascularStructure;
  flowRate: FlowRate;
  donationPosition: string;
  holdersPosition: string[];
  donorMovement: string;
  typeOfMovement?: string;
  emotionalSupport?: string[];
  dislikes?: string[];
  likes?: string[];
  treatPreferences?: string[];
  primaryHolder?: string;
  secondaryHolder?: string;
  poker?: string;
};

const t = getTranslation('donation.ValidationErrors');

function validateStringsArray(array: unknown, maxLength: number) {
  if (!Array.isArray(array)) {
    return false;
  }

  const isSomeItemInvalid = array.some(
    (item) => typeof item !== 'string' || item.length > maxLength
  );

  return !isSomeItemInvalid;
}

function validateOptionalStringArray(value: unknown, maxItemLength: number) {
  return !value || validateStringsArray(value, maxItemLength);
}

const additionalDonationInfoSchema: yup.SchemaOf<AdditionalDonationInfoSchema> =
  yup.object({
    // DISABLED CODE (totalCollectedMls duplicates in donation form)
    // [totalCollectedMls]: yup
    //   .number()
    //   .typeError(t('mustBeNumber'))
    //   .required(t('required', { field: t('totalCollectedMls') })),
    [numberOfCollectionUnitsUsed]: collectionUnitsUsedSchema,
    [veinUsed]: veinUsedSchema,
    [vascularStructure]: vascularStructureSchema,
    [flowRate]: flowRateSchema,
    [donationPosition]: yup
      .string()
      .max(
        100,
        t('itemsShouldBeLessThan', {
          max: 100,
          field: t('donationPosition'),
        })
      )
      .required(t('required', { field: t('donationPosition') })),
    [holdersPosition]: yup
      .array()
      .test({
        message: t('itemsShouldBeLessThan', {
          max: 100,
          field: t('holdersPosition'),
        }),
        test: (value) => validateStringsArray(value, 100),
      })
      .min(1, t('required', { field: t('holdersPosition') }))
      .required(t('required', { field: t('holdersPosition') })),
    [donorMovement]: yup
      .string()
      .max(
        100,
        t('itemsShouldBeLessThan', {
          max: 100,
          field: t('donorMovement'),
        })
      )
      .required(t('required', { field: t('donorMovement') })),
    [typeOfMovement]: yup.string().max(
      100,
      t('itemsShouldBeLessThan', {
        max: 100,
        field: t('typeOfMovement'),
      })
    ),
    [emotionalSupport]: yup.mixed().test({
      message: t('itemsShouldBeLessThan', {
        max: 100,
        field: t('emotionalSupport'),
      }),
      test: (value) => validateOptionalStringArray(value, 100),
    }),
    [dislikes]: yup.mixed().test({
      message: t('itemsShouldBeLessThan', {
        max: 100,
        field: t('dislikes'),
      }),
      test: (value) => validateOptionalStringArray(value, 100),
    }),
    [likes]: yup.mixed().test({
      message: t('itemsShouldBeLessThan', {
        max: 100,
        field: t('likes'),
      }),
      test: (value) => validateOptionalStringArray(value, 100),
    }),
    [treatPreferences]: yup.mixed().test({
      message: t('itemsShouldBeLessThan', {
        max: 100,
        field: t('treatPreferences'),
      }),
      test: (value) => validateOptionalStringArray(value, 100),
    }),
    [primaryHolder]: yup.string().notRequired(),
    [secondaryHolder]: yup.string().notRequired(),
    [poker]: yup.string().notRequired(),
  });
export { additionalDonationInfoSchema };
export type { AdditionalDonationInfoSchema };
