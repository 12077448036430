import { Row, Col } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { NavbbLogo } from 'modules/common/components';
import ProductQR from 'modules/products/components/ProductQR';
import { LabelSize } from 'modules/products/enums';
import { getRecommendationsByCategoryId } from 'modules/products/utils/getRecommendationsByCategory';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductOutput, useGetWoocommerceCategoriesQuery } from 'types.d';

import {
  QR_SIZES,
  LABEL_SIZE_STYLES,
  BLOOD_TYPE_TRANSLATIONS,
  LABEL_GUTTER,
  DONOR_SPECIES,
} from './config';
import styles from './styles.module.scss';

type PropTypes = {
  product: ProductOutput;
  size?: LabelSize;
};

const Label = forwardRef<any, PropTypes>(
  ({ product, size = LabelSize.Small }, ref) => {
    const { donation, woocommerceCategoryId, expirationDate } = product;
    const { t } = useTranslation('products.Label');
    const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
      variables: {
        input: {},
      },
    });
    const containerClassNames = `${styles.container} ${LABEL_SIZE_STYLES[size]}`;
    const bloodType =
      donation.donor.bloodType &&
      BLOOD_TYPE_TRANSLATIONS[donation.donor.bloodType];
    const gender = DONOR_SPECIES[donation.donor.species];
    const qrSize = QR_SIZES[size];
    const productCategory =
      getWoocommerceCategoriesQuery.data?.getWoocommerceCategories.find(
        (category) => category.id === woocommerceCategoryId
      );
    const storageRecommendations = getRecommendationsByCategoryId(
      woocommerceCategoryId
    );

    return (
      <div ref={ref} className={containerClassNames}>
        <Row gutter={LABEL_GUTTER[size]}>
          <Col span={24}>
            <div className={styles.content}>
              <Row>
                <Col>
                  <NavbbLogo
                    className={styles.logo}
                    preview={false}
                    width={484}
                    height={232}
                  />
                </Col>
                <Col flex={1}>
                  <Row
                    className={styles.bloodTypeContainer}
                    justify="center"
                    align="bottom"
                  >
                    <Col>
                      DEA 1 <br />
                      <span className={styles.bold}>{bloodType}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.content}>
              <Row gutter={[24, 0]}>
                <Col>
                  {t('expiresOn')}:&nbsp;
                  <span className={styles.bold}>
                    {format(new Date(expirationDate), config.DATE_FORMAT)}
                  </span>
                </Col>
                <Col>
                  {t('volume')}:&nbsp;
                  <span className={styles.bold}>{product.value} ml</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.content}>
              {t('product')}:&nbsp;
              <span className={styles.productType}>
                {productCategory?.name}
              </span>
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.content}>
              <Row align="middle">
                <Col span={24}>
                  <span className={styles.recommendationsTitle}>
                    {t('storageRecommendations')}:
                  </span>
                </Col>
                <Col span={20}>
                  <ul className={styles.recommendationsList}>
                    {storageRecommendations.map((recommendation, i) => {
                      return (
                        <li key={`${recommendation}_${i}`}>{recommendation}</li>
                      );
                    })}
                  </ul>
                </Col>
                <Col span={4}>
                  <ProductQR
                    width={qrSize}
                    height={qrSize}
                    productId={product.shortId}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.content}>
              <Row justify="space-between" gutter={4}>
                <Col span={12}>
                  {t('donationId')}: {donation.shortId}
                </Col>
                <Col span={12}>
                  {t('anticoagulant')}: {product.anticoagulant}
                </Col>
                <Col span={12}>
                  {t('dd')}:&nbsp;
                  {format(
                    new Date(donation.donationDate),
                    config.SHORT_DATE_FORMAT
                  )}
                </Col>
                <Col span={12}>
                  {t('species')}:&nbsp;
                  <span className={styles.species}>{gender}</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col className={styles.usage}>{t('forVeterinaryOnly')}</Col>
              <Col className={styles.footer} span={24}>
                {t('pleaseContact', { contact: '(703) 574 7417' })}
              </Col>
            </Row>
          </Col>
          <Col className={styles.donorName} span={24}>
            {donation.donor.name} {donation.donor.user?.lastName}
          </Col>
        </Row>
      </div>
    );
  }
);

export default Label;
