import { gql } from '@apollo/client';

export const APPOINTMENT_FRAGMENT = gql`
  fragment Appointment on AppointmentOutput {
    id
    createdAt
    updatedAt
    title
    start
    end
    location
    notes
    isDaycare
    isPrivate
  }
`;

export const APPOINTMENT_USER_FRAGMENT = gql`
  fragment AppointmentUser on AppointmentUser {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
  }
`;

export const APPOINTMENT_GROUP_FRAGMENT = gql`
  fragment AppointmentGroup on AppointmentGroupOutput {
    id
    title
  }
`;

export const APPOINTMENT_TEAM_FRAGMENT = gql`
  fragment AppointmentTeam on AppointmentTeamOutput {
    id
    title
  }
`;

export const APPOINTMENT_DONOR_FRAGMENT = gql`
  fragment AppointmentDonor on AppointmentDonor {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    shortId
  }
`;
