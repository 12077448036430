import { Col } from 'antd';
import { FormItem } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { ComponentProps, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum } from 'types.d';

import styles from './styles.module.scss';

import { CreateAppointmentValidationSchema } from '../../CreateAppointmentsModal/schema';
import DaycareDonorSelect from '../../DaycareRegisterForm/DaycareDonorsSelect';
import { AppointmentFormField } from '../fields';

const FIELD_NAME = AppointmentFormField.DaycareDonorsIds;

type PropTypes = { location?: string } & ComponentProps<typeof FormItem>;

const DaycareDonorsSelect: FC<PropTypes> = ({
  location,
  ...restFormItemProps
}) => {
  const { t } = useTranslation('appointments.CreateAppointmentsFormFields');
  const { control, formState, resetField } =
    useFormContext<CreateAppointmentValidationSchema>();
  const currentUser = useCurrentUser();
  const errorMessage =
    formState.errors.donorId?.message || formState.errors.donorStatus?.message;
  const canAddDonorToAppointment = currentUser.isCan(
    PermissionsEnum.AddDonorToAppointment
  );

  return (
    <Col span={24}>
      <FormItem
        colon
        className={styles.formItem}
        label={t('donorName')}
        extra={errorMessage}
        validateStatus={errorMessage && 'error'}
        {...restFormItemProps}
      >
        <DaycareDonorSelect
          allowClear
          locations={location ? [location] : []}
          adjustDropdownPosition
          onClear={() => resetField(FIELD_NAME)}
          controlled={{ control, name: FIELD_NAME }}
          disabled={!canAddDonorToAppointment}
        />
      </FormItem>
    </Col>
  );
};

export default DaycareDonorsSelect;
