import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

type AddDonorNoteSchemaType = {
  text: string;
};

const t = getTranslation('donor.AddDonorNoteModal');

const addDonorNoteSchema: yup.SchemaOf<AddDonorNoteSchemaType> = yup.object({
  text: yup
    .string()
    .matches(/^\S.*\S$|^\S$/s, t('noSpacesAtEdgesMessage'))
    .max(255, t('atMost', { max: 255 }))
    .required(t('required', { field: t('text') })),
});

export { addDonorNoteSchema };
export type { AddDonorNoteSchemaType };
