import { Card, Col } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { DeletePopconfirm, Spin } from 'modules/common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationOutput, useDeleteNotificationMutation } from 'types.d';

import { NotificationContentByType } from './notificationContent';
import styles from './styles.module.scss';

type Props = {
  notification: NotificationOutput;
};

const NotificationCard: FC<Props> = ({ notification }) => {
  const { t } = useTranslation('notification.NotificationCard');
  const [deleteNotification, deleteNotificationMutation] =
    useDeleteNotificationMutation();
  const onDeletePubSub = () => {
    deleteNotification({
      refetchQueries: ['getNotificationList'],
      variables: {
        input: {
          id: notification.id,
        },
      },
    });
  };

  const Content = NotificationContentByType[notification.type];
  const title = t(
    `${notification.type.charAt(0).toLowerCase()}${notification.type.slice(1)}`
  );

  return (
    <Spin spinning={deleteNotificationMutation.loading}>
      <Card
        title={title}
        extra={
          <Col>
            <Col>
              {format(
                new Date(notification.createdAt),
                config.DATE_AND_TIME_FORMAT
              )}
              <span className={styles.deleteButton}>
                <DeletePopconfirm
                  title={t('sureToDeleteNotification')}
                  onConfirm={onDeletePubSub}
                />
              </span>
            </Col>
          </Col>
        }
      >
        <Content payload={notification.payload} />
      </Card>
    </Spin>
  );
};

export default NotificationCard;
