import { ControlledInput } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { ComponentProps, FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { PermissionsEnum } from 'types.d';

type PropTypes = {
  controlled: UseControllerProps<any>;
  disabled?: boolean;
} & ComponentProps<typeof ControlledInput>;

const DonationFrequencyInput: FC<PropTypes> = ({
  controlled,
  disabled,
  ...restProps
}) => {
  const currentUser = useCurrentUser();
  const isPermitted = currentUser.isCan(PermissionsEnum.DonationFrequency);

  return (
    <ControlledInput
      disabled={disabled || !isPermitted}
      controlled={controlled}
      {...restProps}
    />
  );
};

export default DonationFrequencyInput;
