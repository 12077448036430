import { Row, Col } from 'antd';
import { RadioButtonInBox } from 'modules/donor/components/RoutineTest/components/RadioButtonInBox';
import { FC, Fragment, ReactNode } from 'react';

import styles from './styles.module.scss';

type Props = {
  title: string;
  items: {
    label: ReactNode | string;
    details: ReactNode | string;
    dots?: ReactNode[];
    checked?: boolean;
  }[];
};

export const IndividualResultsSection: FC<Props> = ({ title, items }) => {
  return (
    <Row className={styles.section}>
      <Col span={24} className={styles.title}>
        {title}
      </Col>
      {items.map(({ label, dots, details, checked }, index) => (
        <Fragment key={index}>
          <Col span={24} key={index + 'title'}>
            <Row align="middle">
              <Col className={styles.itemTitleWrapper}>
                <RadioButtonInBox
                  boxHeight={15}
                  boxWidth={15}
                  circleHeight={12}
                  circleWidth={12}
                  checked={checked}
                />
              </Col>
              <Col className={styles.itemTitle}>
                <strong>{label}</strong>
              </Col>
              {dots && <Col flex="1 1 0px" className={styles.bottomLine}></Col>}
              {dots &&
                dots.map((dot, dotIndex) => <Col key={dotIndex}>{dot}</Col>)}
            </Row>
          </Col>
          <Col span={22} className={styles.itemDetails} key={index + 'details'}>
            <Row align="bottom">
              <Col>{details}</Col>
            </Row>
          </Col>
        </Fragment>
      ))}
    </Row>
  );
};
