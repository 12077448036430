import { Col, Row, Typography } from 'antd';
import { Routes } from 'config/routes';
import AppointmentMembers from 'modules/appointments/components/AppointmentMembers';
import { Link } from 'modules/common/components';
import { FC } from 'react';
import { AppointmentOutput } from 'types.d';

import styles from './styles.module.scss';

const { Text } = Typography;

type PropTypes = {
  appointments: AppointmentOutput[];
  extended?: boolean;
};

const TaskScheduleItem: FC<PropTypes> = ({ appointments, extended }) => {
  return (
    <Row gutter={[0, 5]} className={styles.container}>
      {appointments.map(
        ({ users, title, donor, group, team, id, location }) => {
          const teamContent = team ? (
            <Link
              to={Routes.GroupProfileTeams.replace(':id', String(group?.id))}
            >
              {team.title}
            </Link>
          ) : null;
          const groupContent = group ? (
            <Link
              to={Routes.GroupProfile.replace(':id', String(group.id))}
              className={styles.block}
            >
              {group.title}
            </Link>
          ) : null;
          const individualContent = donor ? (
            <Link
              className={styles.block}
              to={Routes.DonorsProfile.replace(':id', String(donor.shortId))}
            >
              {donor.name} {donor.user?.lastName}
            </Link>
          ) : null;
          const content = (
            <>
              <Text ellipsis={{ tooltip: title }}>{title}</Text> <br />
              <AppointmentMembers members={users} />
              <Text ellipsis={{ tooltip: location }}>{location}</Text> <br />
              {individualContent}
              {groupContent}
              {teamContent}
            </>
          );
          //TODO: display different content
          return (
            <Col span={24} className={styles.contentWrapper} key={id}>
              {extended ? content : content}
            </Col>
          );
        }
      )}
    </Row>
  );
};

export default TaskScheduleItem;
