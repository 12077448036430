import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form, message, Row, Col, Button } from 'antd';
import { GET_USER_QUERY } from 'modules/admin/graphql/queries';
import { FormItem, Spin } from 'modules/common/components';
import InvitationRolesSelect from 'modules/user/components/InvitationRolesSelect';
import { GET_USER_LIST } from 'modules/user/graphql/queries/user';
import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserOutput, useUpdateUserRoleMutation, InviteUserRole } from 'types.d';

import { EditUserRoleSchema, editUserRoleSchema } from './schema';

type Props = {
  user: UserOutput;
};

const EditUserRoleForm: FC<Props> = ({ user }) => {
  const { t } = useTranslation('admin.EditUserRoleForm');
  const [formEnabled, setFormEnabled] = useState<boolean>(false);

  const { control, formState, reset, handleSubmit } =
    useForm<EditUserRoleSchema>({
      resolver: yupResolver(editUserRoleSchema),
      mode: 'onChange',
    });

  useEffect(() => {
    reset({
      role: user.role.value,
    });
  }, [user]);

  const [updateUserRole, updateUserRoleMutation] = useUpdateUserRoleMutation({
    refetchQueries: [GET_USER_QUERY, GET_USER_LIST],
    onCompleted: () => {
      message.success(t('roleHasBeenChanged'));
    },
    onError: (error) => message.error(error.message),
  });

  const editUserRoleHandler = handleSubmit((data) => {
    updateUserRole({
      variables: {
        input: {
          id: user.id || '',
          ...data,
        },
      },
    });
    setFormEnabled(!formEnabled);
  });

  const closeHandler = () => {
    reset({
      role: user.role.value,
    });
    setFormEnabled(false);
  };

  const name = 'role';
  const error = formState.errors[name]?.message;

  const isSaveButtonDisabled = !formState.isValid || !formState.isDirty;

  return (
    <Spin spinning={updateUserRoleMutation.loading}>
      <Form>
        <FormItem
          key={name}
          label={t(name)}
          validateStatus={error && 'error'}
          extra={error}
        >
          <Row align="middle">
            <Col span={18}>
              <InvitationRolesSelect
                key={name}
                controlled={{ name, control }}
                omittedRoles={[InviteUserRole.Owner]}
                disabled={!formEnabled}
              />
            </Col>
            <Col offset={1} span={5}>
              {formEnabled ? (
                <Row justify="space-between">
                  <Col>
                    <Button onClick={closeHandler} type="link">
                      <CloseOutlined />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={editUserRoleHandler}
                      disabled={isSaveButtonDisabled}
                      type="link"
                    >
                      <CheckOutlined />
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row justify="end">
                  <EditOutlined onClick={() => setFormEnabled(true)} />
                </Row>
              )}
            </Col>
          </Row>
        </FormItem>
      </Form>
    </Spin>
  );
};
export default EditUserRoleForm;
