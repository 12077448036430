import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import ReportsTabs from 'modules/reports/components/ReportsTabs';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const ReportsPage: FC = () => {
  const { t } = useTranslation('reports.ReportsPage');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === Routes.Reports) {
      navigate(Routes.DonorsReports, { replace: true });
    }
  }, []);

  return (
    <ProfileSideMenuWrapper title={t('reports')}>
      <Row gutter={[10, 10]} justify="space-between">
        <Col span={24}>
          <ReportsTabs />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default ReportsPage;
