import { LineConfig, Line } from '@ant-design/charts';
import config from 'config';
import { format } from 'date-fns';
import { getConfig } from 'modules/donor/components/DonationBaseChart/config';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  lineConfig: LineConfig;
};

const DonationBaseChart: FC<Props> = ({ lineConfig }) => {
  const navigate = useNavigate();
  const { data } = lineConfig;

  const convertedData = data.map((donation) => {
    return {
      ...donation,
      donationDate: format(new Date(donation.donationDate), config.DATE_FORMAT),
    };
  });

  const baseConfig = getConfig(
    { ...lineConfig, data: convertedData },
    navigate
  );

  return <Line {...baseConfig} />;
};

export default DonationBaseChart;
