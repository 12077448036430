import { Table, TableProps, Grid } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useSort } from 'modules/common/hooks';
import { UseFilterObject } from 'modules/common/hooks/useFilter';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import {
  UserOutput,
  Order,
  UserListSort,
  OwnersListFilterInput,
} from 'types.d';

import { getColumns } from './columns';
import styles from './styles.module.scss';

const useUserListSort = () => useSort<UserListSort>();
const { useBreakpoint } = Grid;

type Props = {
  ownerList: UserOutput[];
  pagination: ReturnType<typeof usePagination>;
  sorting: ReturnType<typeof useUserListSort>;
  ownersFilters: UseFilterObject<OwnersListFilterInput>;
  showDeletedColumn?: boolean;
};

const OwnerListTable: FC<Props> = ({
  ownerList,
  pagination,
  sorting,
  showDeletedColumn,
  ownersFilters,
}) => {
  const { md } = useBreakpoint();

  const columns = getColumns({
    showDeletedAtColumn: showDeletedColumn,
    showSorterTooltip: md,
    sort: sorting.options[0],
    filters: ownersFilters?.getFilterObject(),
  });
  const onTableChange: TableProps<UserOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<UserOutput>;
      const fieldName =
        UserListSort[
          tableSorter.columnKey?.toString() as keyof typeof UserListSort
        ];
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        sorting.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        sorting.removeSortOption(fieldName);
      }
    }

    if (extra.action === 'filter') {
      Object.keys(filters).forEach((fieldName) => {
        ownersFilters?.setFilterOption({
          fieldName: fieldName as keyof OwnersListFilterInput,
          value: (filters as any)[fieldName],
        });
      });
    }
  };

  return (
    <Table
      rowKey={({ id }) => id}
      onRow={() => {
        return {
          className: styles.row,
        };
      }}
      rowClassName={({ isArchived }) => (isArchived ? styles.archived : '')}
      columns={columns}
      dataSource={ownerList}
      onChange={onTableChange}
      scroll={{ x: 750 }}
      pagination={pagination.config}
    />
  );
};

export default OwnerListTable;
