import { Row, Col } from 'antd';
import ReserveDonorsTable from 'modules/donor/components/ReserveDonorsTable';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ReserveDonors: FC = () => {
  const { t } = useTranslation('donor.ReserveDonors');

  return (
    <ProfileSideMenuWrapper title={t('pageTitle')}>
      <Row>
        <Col span={24}>
          <ReserveDonorsTable />
        </Col>
      </Row>
    </ProfileSideMenuWrapper>
  );
};

export default ReserveDonors;
