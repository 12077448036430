import colors from 'config/colors.scss';
import { DonationOutput } from 'types';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.TotalSolidsChart');

export const getConfig = (donationData: DonationOutput[]) => {
  return {
    data: donationData,
    xField: 'donationDate',
    yField: 'totalSolids',
    color: colors.pastelOrange,
    xAxis: {
      title: {
        text: t('date'),
      },
    },
    yAxis: {
      title: {
        text: t('totalSolids'),
      },
      alias: t('totalSolids'),
    },
  };
};
