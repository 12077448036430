import * as yup from 'yup';

export type AddDonorsSchema = {
  daycareDonorsIds?: string[];
};

export const addDonorsValidationSchema: yup.SchemaOf<AddDonorsSchema> =
  yup.object({
    daycareDonorsIds: yup.array().min(1).required(),
  });
