import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import NotificationsPage from 'modules/notification/page/Notifications';
import ProfilePage from 'modules/user/pages/Profile';
import { PermissionsEnum } from 'types.d';

import EmailConfirmationPage from './pages/EmailConfirmation';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SignUpPage from './pages/SignUp';

const userModule: IModule = {
  routes: [
    {
      path: Routes.SignUp,
      Component: SignUpPage,
      type: RouteType.NoAuth,
    },
    {
      path: Routes.ForgotPassword,
      Component: ForgotPasswordPage,
      type: RouteType.NoAuth,
    },
    {
      path: Routes.ResetPassword,
      Component: ResetPassword,
      type: RouteType.NoAuth,
    },
    {
      path: Routes.EmailConfirmation,
      Component: EmailConfirmationPage,
      type: RouteType.Public,
    },
    {
      path: Routes.Profile,
      Component: ProfilePage,
      type: RouteType.Auth,
    },
    {
      path: Routes.UserNotifications,
      Component: NotificationsPage,
      type: RouteType.Auth,
      permissions: [PermissionsEnum.ReceiveNotifications],
    },
  ],
};

export default userModule;
