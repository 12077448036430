import { MutationHookOptions } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, message, Modal, Row } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { FormItem, Spin } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { DonorSelect, CurrentUserDonorsSelect } from 'modules/donor/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AppointmentOutput,
  DonorOutput,
  useEnrollDonorToAppointmentMutation,
  useEnrollMyDonorToAppointmentMutation,
  UserRole,
} from 'types.d';

import { enrollDonorSchema, EnrollDonorSchemaType } from './schema';
import styles from './styles.module.scss';

type PropTypes = {
  appointment?: AppointmentOutput;
} & BaseModalProps;

const EnrollDonorModal: FC<PropTypes> = ({
  appointment,
  visible,
  onCancel,
  onOk,
  hide,
  ...restModalProps
}) => {
  const { t } = useTranslation('appointments.EnrollDonorModal');
  const currentUser = useCurrentUser();
  const currentUserRole = currentUser.data?.role.value;
  const isCurrentUserOwner = currentUserRole === UserRole.Owner;
  const enrollDonorForm = useForm<EnrollDonorSchemaType>({
    resolver: yupResolver(enrollDonorSchema),
    mode: 'onChange',
  });
  const refetchQueries = [
    isCurrentUserOwner ? 'getAppointmentsForCurrentUser' : 'getAppointments',
  ];
  const defaultMutationsOptions: MutationHookOptions<any, any, any> = {
    refetchQueries,
    awaitRefetchQueries: true,
    update: (cache) => {
      if (!isCurrentUserOwner) {
        cache.evict({ fieldName: 'getAppointments' });
      }
    },
    onCompleted: () => {
      message.success(t('hasBeenEnrolled'));
      hide();
      enrollDonorForm.reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  };
  const [enrollDonor, enrollDonorMutation] =
    useEnrollDonorToAppointmentMutation(defaultMutationsOptions);
  const [enrollMyDonor, enrollMyDonorMutation] =
    useEnrollMyDonorToAppointmentMutation(defaultMutationsOptions);
  const isLoading =
    enrollDonorMutation.loading || enrollMyDonorMutation.loading;
  const isOkButtonDisabled = isLoading || !enrollDonorForm.formState.isValid;
  const DonorSelectComponent = isCurrentUserOwner
    ? CurrentUserDonorsSelect
    : DonorSelect;
  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    onCancel && onCancel(e);
    hide();
    enrollDonorForm.resetField('donorId');
  };

  const okHandler = async (e: MouseEvent<HTMLElement>) => {
    onOk && onOk(e);
    handleDonorEnrolling();
    enrollDonorForm.resetField('donorId');
  };

  const handleDonorEnrolling = enrollDonorForm.handleSubmit(({ donorId }) => {
    if (!appointment) {
      return;
    }
    const variables = {
      input: {
        donorId,
        id: appointment.id,
      },
    };
    isCurrentUserOwner
      ? enrollMyDonor({ variables })
      : enrollDonor({ variables });
  });

  const appointmentDate = appointment?.start
    ? format(new Date(appointment.start), config.DATE_FORMAT)
    : '-';
  const startTime = appointment?.start
    ? format(new Date(appointment.start), config.APPOINTMENT_TIME_FORMAT)
    : '-';
  const endTime = appointment?.end
    ? format(new Date(appointment.end), config.APPOINTMENT_TIME_FORMAT)
    : '-';
  const appointmentMembers = appointment?.users.reduce(
    (membersNames, user, i) =>
      `${membersNames} ${user.firstName} ${user.lastName}\n`,
    ''
  );
  const appointmentInfoFields = [
    {
      label: t('title'),
      value: appointment?.title,
    },
    { label: t('date'), value: appointmentDate },
    {
      label: t('time'),
      value: `${startTime} - ${endTime}`,
    },
    {
      label: t('appointmentWith'),
      value: appointmentMembers,
    },
    {
      label: t('location'),
      value: appointment?.location,
    },
  ];
  const donorErrorMessage =
    enrollDonorForm.formState.errors.donorId?.message ||
    enrollDonorForm.formState.errors.donorStatus?.message;
  const sedDonorStatus = (donor: DonorOutput) => {
    enrollDonorForm.setValue('donorStatus', donor.status, {
      shouldValidate: true,
    });
  };

  return (
    <Modal
      width={500}
      onOk={okHandler}
      onCancel={cancelHandler}
      okButtonProps={{ disabled: isOkButtonDisabled }}
      {...restModalProps}
      visible={visible}
    >
      <Spin spinning={isLoading}>
        <Form labelCol={{ span: 8 }}>
          <Row gutter={[0, 5]}>
            <Col span={24} className={styles.title}>
              {t('enrollDonor')}
            </Col>
            {appointmentInfoFields.map(({ label, value }) => {
              return (
                <Col span={24} key={label}>
                  <Row>
                    <Col span={12}>{label}:</Col>
                    <Col span={12} className={styles.preWrap}>
                      {value}
                    </Col>
                  </Row>
                </Col>
              );
            })}
            <Col span={24}>
              <Row>
                <Col span={12}>{t('donor')}:</Col>
                <Col span={12}>
                  <FormItem
                    extra={donorErrorMessage}
                    validateStatus={donorErrorMessage && 'error'}
                  >
                    <DonorSelectComponent
                      placeholder={t('selectDonor')}
                      className={styles.donorSelect}
                      onSelect={sedDonorStatus}
                      controlled={{
                        control: enrollDonorForm.control,
                        name: 'donorId',
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EnrollDonorModal;
