import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Routes } from 'config/routes';
import { Link } from 'react-router-dom';
import { DaycareAppointmentRequestOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './style.module.scss';

const t = getTranslation('appointments.DaycareDetailModal');

type PropTypes = {
  approveHandler: (id: string) => void;
  disproveHandler: (id: string) => void;
};

export const getColumns = ({
  approveHandler,
  disproveHandler,
}: PropTypes): ColumnsType<DaycareAppointmentRequestOutput> => [
  {
    title: t('donor'),
    key: 'donor',
    render: (data: DaycareAppointmentRequestOutput) => {
      return (
        <Link
          target="_blank"
          to={Routes.DonorsProfile.replace(':id', `${data.donor.shortId}`)}
        >
          {data.donor.name}
        </Link>
      );
    },
  },
  {
    title: t('owner'),
    key: 'owner',
    render: (data: DaycareAppointmentRequestOutput) => {
      return (
        <Link
          target="_blank"
          to={Routes.EditOwner.replace(':id', `${data.user.shortId}`)}
        >
          {data.user.firstName} {data.user.lastName}
        </Link>
      );
    },
  },
  {
    title: t('note'),
    key: 'note',
    className: styles.note,
    render: (data: DaycareAppointmentRequestOutput) => {
      return data.note || '-';
    },
  },
  {
    title: t('processRequest'),
    key: 'actions',
    width: 150,
    render: (data: DaycareAppointmentRequestOutput) => {
      const requestId = data.id;

      return (
        <Row justify="space-between">
          <Col>
            <CheckOutlined onClick={() => approveHandler(requestId)} />
          </Col>
          <Col>
            <CloseOutlined onClick={() => disproveHandler(requestId)} />
          </Col>
        </Row>
      );
    },
  },
];
