import { Image } from 'antd';
import { FC } from 'react';

import styles from './styles.module.scss';

type PropTypes = {
  src: string;
};

const DonorAvatar: FC<PropTypes> = ({ src }) => {
  return (
    <div className={styles.avatarContainer}>
      <Image src={src} />
    </div>
  );
};

export default DonorAvatar;
