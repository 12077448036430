import { Col, message, Row } from 'antd';
import { Spin } from 'modules/common/components';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import ChangeProductTrackingInfoModal from 'modules/products/components/ChangeProductTrackingInfoModal';
import ProductCard from 'modules/products/components/ProductCard';
import { useChangeProductTrackingInfoModalContext } from 'modules/products/contexts/ChangeProductTrackingInfoModal';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ProductOutput,
  useGetProductQuery,
  useGetWoocommerceCategoriesQuery,
  WoocommerceProductCategoryOutput,
} from 'types.d';

const ProductsPage: FC = () => {
  const { t } = useTranslation('products.ProductPage');
  const params = useParams();
  const productId = Number(params.id);
  const productQuery = useGetProductQuery({
    variables: {
      input: {
        id: Number(productId),
      },
    },
    skip: isNaN(productId),
  });
  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
    skip: isNaN(productId),
  });
  const woocommerceCategories =
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories;
  const product = productQuery.data?.getProduct as ProductOutput | undefined;
  const changeProductTrackingInfoModal =
    useChangeProductTrackingInfoModalContext();
  const productCategory = woocommerceCategories?.find(
    (category) => category.id === product?.woocommerceCategoryId
  ) as WoocommerceProductCategoryOutput | undefined;

  useEffect(() => {
    if (isNaN(productId)) {
      message.error(t('productIdNotValid'));
    }
  }, [productId]);

  return (
    <ProfileSideMenuWrapper title={t('product')}>
      <Spin spinning={productQuery.loading}>
        <Row gutter={[10, 10]} align="middle">
          <Col xl={12} lg={20} span={24}>
            <Row justify="center">
              <Col sm={20} md={16} lg={24} span={24}>
                {product && (
                  <ProductCard
                    woocommerceProductCategory={productCategory}
                    product={product}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {changeProductTrackingInfoModal && (
          <ChangeProductTrackingInfoModal
            {...changeProductTrackingInfoModal?.modalConfig}
          />
        )}
      </Spin>
    </ProfileSideMenuWrapper>
  );
};

export default ProductsPage;
