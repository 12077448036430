import { DisconnectOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useApolloClient, useReactiveVar } from '@apollo/client';
import { Layout, Menu, MenuProps, Row, Col, Tooltip } from 'antd';
import { SiderProps } from 'antd/lib/layout/Sider';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { Routes } from 'config/routes';
import { LOGOUT_MUTATION } from 'modules/auth/graphql/mutations';
import PageContainer from 'modules/common/components/PageContainer';
import useOnlineStatus from 'modules/common/hooks/useOnlineStatus';
import { cacheState } from 'modules/donor/components/CacheManagement/var';
import NotificationsWrapper from 'modules/navigation/components/NotificationsWrapper';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { cachePersistor } from 'providers/graphql';
import {
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { PermissionsEnum, useGetAppVersionQuery } from 'types.d';

import { getMenuItemsByRole } from './menuItems';
import { getSelectedKey } from './menuItems/getSelectedKey';
import styles from './styles.module.scss';

const { Content, Sider, Header } = Layout;

const defaultSiderProps: SiderProps = {
  theme: 'dark',
  breakpoint: 'lg',
  collapsedWidth: '0',
  zeroWidthTriggerStyle: { top: 0 },
  className: styles.sider,
  width: 300,
};

type PropTypes = {
  children?: ReactNode;
  title?: string;
  tip?: string;
};

const ProfileSideMenuWrapper: FC<PropTypes> = ({ children, title, tip }) => {
  const { t } = useTranslation('navigation.ProfileSideMenuWrapper');
  const getAppVersionQuery = useGetAppVersionQuery({
    fetchPolicy: 'cache-first',
  });
  const [collapsed, setCollapsed] = useState(true);
  const defaultCollapsed = useMemo(() => {
    if (window.innerWidth >= 992) {
      return false;
    }
    return collapsed;
  }, [window.innerWidth, collapsed]);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const isOnline = useOnlineStatus();
  const backendAppVersion = getAppVersionQuery.data?.getAppVersion;
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const siderRef = useRef<any>(null);
  const printModEnabledRef = useRef<any>(false);
  const client = useApolloClient();
  const [logout] = useMutation(LOGOUT_MUTATION, {
    onCompleted: async () => {
      localStorage.removeItem(LOCALE_STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(LOCALE_STORAGE_KEYS.REFRESH_TOKEN);
      currentUser.cleanData();
      await cachePersistor.purge();
      await client.clearStore();
    },
  });
  const isCacheEnabled = useReactiveVar(cacheState);
  const logoutHandler = async () => {
    await client.stop();
    await logout();
    navigate(Routes.Login);
  };
  const userRole = currentUser.data?.role;
  const userPermissions = currentUser.data?.permissions;
  const menuItems = useMemo(() => {
    if (!userRole || !userPermissions) {
      return [];
    }
    const menuItemsByRole = getMenuItemsByRole({
      userRole,
      permissions: userPermissions,
    });
    return menuItemsByRole;
  }, [userRole]);

  const selectedKeys = getSelectedKey(location.pathname);

  const defaultMenuProps: MenuProps = {
    selectedKeys,
    theme: 'dark',
    mode: 'inline',
    className: styles.menu,
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      siderRef.current &&
      !siderRef.current.contains(event.target) &&
      isMobileDevice
    ) {
      setCollapsed(true);
    }
  };

  const handleAfterPrint = (_event: Event) => {
    printModEnabledRef.current = false;
  };

  const handleBeforePrint = (_event: Event) => {
    printModEnabledRef.current = true;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    window.addEventListener('afterprint', handleAfterPrint, true);
    window.addEventListener('beforeprint', handleBeforePrint, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      window.removeEventListener('afterprint', handleAfterPrint, true);
      window.removeEventListener('beforeprint', handleBeforePrint, true);
    };
  }, []);

  useLayoutEffect(() => {
    setIsMobileDevice(window.innerWidth < 992);
    if (window.innerWidth >= 992) {
      setCollapsed(false);
    }
  }, [window.innerWidth]);

  const receiveNotifications = currentUser.isCan(
    PermissionsEnum.ReceiveNotifications
  );

  return (
    <PageContainer>
      <Header className={styles.header}>
        <Row align="middle">
          <Col>
            <div className={styles.logo}>
              <img
                src="https://navbb-assets.s3.amazonaws.com/logo.webp"
                alt="logo"
              />
            </div>
          </Col>
          <Col>
            <Row align="middle" gutter={15}>
              <Col>
                <h1 className={styles.title}>{title}</h1>
              </Col>
              <Col>
                {tip ? (
                  <Tooltip
                    overlay={<>{tip}</>}
                    placement="right"
                    overlayClassName={styles.pageTip}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                ) : undefined}
              </Col>
            </Row>
          </Col>
          {receiveNotifications && (
            <Col flex="1 1 auto" className={styles.notificationWrapper}>
              <NotificationsWrapper />
            </Col>
          )}
        </Row>
      </Header>
      <Layout hasSider>
        <Sider
          {...defaultSiderProps}
          ref={siderRef}
          defaultCollapsed={isMobileDevice}
          collapsible={isMobileDevice}
          collapsed={defaultCollapsed}
          onCollapse={(collapsed, _type) => {
            !printModEnabledRef.current && setCollapsed(collapsed);
          }}
        >
          <Menu {...defaultMenuProps} className={styles.menu}>
            {menuItems.map(({ label, children, ...restProps }) => {
              if (children?.length) {
                return (
                  <Menu.SubMenu key={restProps.key} title={label}>
                    {children.map((props) => {
                      return (
                        <Menu.Item key={props.key}>
                          <Link to={`${props.key}`}>{props.label}</Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              }
              return (
                <Menu.Item {...restProps}>
                  <Link to={`${restProps.key}`}>{label}</Link>
                </Menu.Item>
              );
            })}
            <Menu.Item
              key="logout"
              danger
              onClick={logoutHandler}
              className={styles.logoutButton}
            >
              {t('logout')}
            </Menu.Item>
          </Menu>
          <div className={styles.version}>
            <Row justify="space-between">
              <Col span={18}>
                <Row>
                  <Col span={6}>{t('client')}: </Col>
                  <Col span={18}>v{process.env.REACT_APP_VERSION}</Col>
                  <Col span={6}>{t('server')}: </Col>
                  <Col span={18}>v{backendAppVersion}</Col>
                  <Col span={18}>
                    {isCacheEnabled ? t('cacheEnabled') : t('cacheDisabled')}
                  </Col>
                </Row>
              </Col>
              {!isOnline && (
                <Col offset={1} className={styles.iconContainer}>
                  <DisconnectOutlined
                    className={styles.disconnectIcon}
                    title="Offline"
                  />
                </Col>
              )}
            </Row>
          </div>
        </Sider>
        <Content className={styles.content}>{children}</Content>
      </Layout>
    </PageContainer>
  );
};

export default ProfileSideMenuWrapper;
