import { FetchPolicy, useApolloClient } from '@apollo/client';
import ScreenSpinner from 'modules/common/components/ScreenSpinner';
import {
  GET_CURRENT_USER_QUERY,
  GET_MY_PERMISSIONS_QUERY,
} from 'modules/user/graphql/queries/user';
import { CurrentUser, PermissionsType } from 'modules/user/types';
import { createContext, FC, useState, ReactNode, useEffect } from 'react';
import {
  UserOutput,
  PermissionsEnum,
  PermissionsOutput,
  UserRole,
} from 'types.d';

type PropTypes = {
  children?: ReactNode;
};

const UserProvider: FC<PropTypes> = ({ children }) => {
  const { fetch, providerValue, isLoading } = useCurrentUserContext();

  useEffect(() => {
    fetch();
  }, []);

  return isLoading ? (
    <ScreenSpinner />
  ) : (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

const emptyFunction = () => {
  return;
};

const emptyAsyncFunction = async (): Promise<void> => {
  return;
};

type ContextType = {
  cleanData: () => void;
  refetchData: () => Promise<void>;
  isCan: (permission: PermissionsEnum) => boolean;
  data: CurrentUser | null;
  isLoading: boolean;
};

const UserContext = createContext<ContextType>({
  cleanData: emptyFunction,
  refetchData: emptyAsyncFunction,
  isCan: () => false,
  data: null,
  isLoading: false,
});

const useCurrentUserContext = () => {
  const apolloClient = useApolloClient();
  const [userData, setUserData] = useState<UserOutput | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [permissionsData, setPermissionsData] =
    useState<PermissionsType | null>(null);

  const fetch = async (fetchPolicy: FetchPolicy = 'cache-first') => {
    try {
      setIsLoading(true);
      const currentUserResponse = await apolloClient.query({
        query: GET_CURRENT_USER_QUERY,
        fetchPolicy,
      });
      const currentUser = currentUserResponse.data?.currentUser;

      if (!currentUser) {
        return;
      }

      const permissionsResponse = await apolloClient.query({
        query: GET_MY_PERMISSIONS_QUERY,
        fetchPolicy,
      });
      const permissions = permissionsResponse.data?.getMyPermissions;

      if (!permissions) {
        return;
      }

      setUserData(currentUser);
      setPermissions(permissions, currentUser.role.value);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const refetchData = async () => {
    await fetch('network-only');
  };

  const cleanData = () => {
    setUserData(null);
    setPermissionsData(null);
  };

  const isCan = (permission: PermissionsEnum): boolean => {
    return permissionsData?.[permission] || false;
  };

  const setPermissions = (permissions: PermissionsOutput[], role: UserRole) => {
    const preparedPermissions = permissions.reduce(
      (permissions, incomingPermission) => {
        if (incomingPermission.role.value !== role) {
          return permissions;
        }

        return {
          ...permissions,
          [incomingPermission.permission]: incomingPermission.value,
        };
      },
      {} as PermissionsType
    );
    setPermissionsData(preparedPermissions);
  };

  const data =
    userData && permissionsData
      ? {
          ...userData,
          permissions: permissionsData,
        }
      : null;

  return {
    fetch,
    isLoading,
    providerValue: {
      isLoading,
      data,
      isCan,
      cleanData,
      refetchData,
    },
  };
};

export { UserProvider, UserContext };
