import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { CreateGroupFields } from './fields';

const t = getTranslation('donor.CreateGroupModal');

const { title, teamTitle, userId } = CreateGroupFields;

type CreateGroupSchemaType = {
  title: string;
  teamTitle: string;
  userId: string;
};

export const titleSchema = yup
  .string()
  .strict(true)
  .trim(t('mustBeTrimmed'))
  .required(t('nameOfGroupRequired'))
  .max(100, t('lessThan', { max: 100 }))
  .test({
    test: (value) =>
      value ? new RegExp(/^[a-z ,.'-\d]+$/i).test(value) : true,
    message: t('onlyValidSymbols'),
  }); //Alphabets & spaces & numbers;

export const teamTitleSchema = yup
  .string()
  .strict(true)
  .trim(t('mustBeTrimmed'))
  .required(t('nameOfTeamRequired'))
  .max(100, t('lessThan', { max: 100 }))
  .test({
    test: (value) =>
      value ? new RegExp(/^[a-z ,.'-\d]+$/i).test(value) : true,
    message: t('onlyValidSymbols'),
  }); //Alphabets & spaces & numbers;

const createGroupSchema: yup.SchemaOf<CreateGroupSchemaType> = yup.object({
  [title]: titleSchema,
  [teamTitle]: teamTitleSchema,
  [userId]: yup.string().required(t('ownerRequired')),
});

export { createGroupSchema };
export type { CreateGroupSchemaType };
