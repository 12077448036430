import admin from 'modules/admin';
import appointments from 'modules/appointments';
import auth from 'modules/auth';
import common from 'modules/common';
import { IModule, IRoute, IProvider } from 'modules/common/interfaces';
import donation from 'modules/donations';
import donor from 'modules/donor';
import error from 'modules/error';
import location from 'modules/locations';
import notification from 'modules/notification';
import orders from 'modules/orders';
import owner from 'modules/owner';
import products from 'modules/products';
import reports from 'modules/reports';
import requests from 'modules/requests';
import user from 'modules/user';

const modules = [
  admin,
  appointments,
  auth,
  user,
  common,
  owner,
  donor,
  requests,
  reports,
  donation,
  orders,
  products,
  error,
  notification,
  location,
];
const mergedModules: IModule = {
  routes: modules.reduce((acc: IRoute[], { routes }) => {
    return routes ? [...acc, ...routes] : acc;
  }, []),
  providers: modules.reduce((acc: IProvider[], { providers }) => {
    return providers ? [...acc, ...providers] : acc;
  }, []),
};

export default mergedModules;
