import { Row, Col, Pagination } from 'antd';
import { useSort } from 'modules/common/hooks';
import DonorDonationCard from 'modules/donor/components/DonorDonationCard';
import { DonorDonationCardListSorter } from 'modules/donor/components/DonorDonationCardList/DonorDonationCardListSorter';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import { DonationOutput, DonationListSort } from 'types.d';

import styles from './styles.module.scss';

const useDonationListSort = () => useSort<DonationListSort>();

type PropTypes = {
  donations?: DonationOutput[];
  pagination: ReturnType<typeof usePagination>;
  sort: ReturnType<typeof useDonationListSort>;
  addCommentHandler: (donationId: string, previousValue?: string) => void;
  productCategories?: Record<number, string>;
};

const DonorDonationCardList: FC<PropTypes> = ({
  donations,
  pagination,
  sort,
  addCommentHandler,
  productCategories,
}) => {
  return (
    <Row gutter={[0, 30]}>
      <Col className={styles.sorterWrapper}>
        <DonorDonationCardListSorter sorting={sort} />
      </Col>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          {donations?.map((donation) => (
            <Col span={24} key={donation.id}>
              <DonorDonationCard
                donation={donation}
                addCommentHandler={addCommentHandler}
                productCategories={productCategories}
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        <Pagination {...pagination.config} />
      </Col>
    </Row>
  );
};

export default DonorDonationCardList;
