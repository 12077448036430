import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

export const getIsRequiredInSchema =
  <SchemaType extends ObjectShape>(
    validationSchema: yup.ObjectSchema<SchemaType, any>
  ) =>
  (fieldName: keyof SchemaType): boolean => {
    const field = validationSchema?.fields[fieldName] as any;
    return field?.exclusiveTests.required || false;
  };
