import { isPast, isToday } from 'date-fns';
import { MultipleDatePicker } from 'modules/common/components';
import { FC } from 'react';
import { UseControllerProps, useFormContext } from 'react-hook-form';

import Header from './components/Header';

type PropTypes = {
  controlled: UseControllerProps<any>;
};

const RecurringCalendar: FC<PropTypes> = ({ controlled }) => {
  const { resetField, getValues, setValue } = useFormContext();
  const dates: Date[] = getValues().dates;

  return (
    <MultipleDatePicker
      controlled={controlled}
      disabledDate={(date) => isPast(date) && !isToday(date)}
      headerRender={(props) => (
        <Header
          {...props}
          resetField={resetField}
          dates={dates}
          setValue={setValue}
        />
      )}
    />
  );
};

export default RecurringCalendar;
