import { ApolloQueryResult } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import { apolloClient } from 'providers/graphql';
import { GetAppointmentsDonorsListQuery } from 'types.d';

import { GET_APPOINTMENTS_DONORS_QUERY } from '../../../../modules/donor/graphql/queries';

const CURRENT_DATE = new Date();

export const getAppointmentDonors = (): Promise<
  ApolloQueryResult<GetAppointmentsDonorsListQuery>
> => {
  return apolloClient.query<GetAppointmentsDonorsListQuery>({
    fetchPolicy: 'network-only',
    query: GET_APPOINTMENTS_DONORS_QUERY,
    variables: {
      input: {
        start: startOfDay(CURRENT_DATE),
        end: endOfDay(CURRENT_DATE),
      },
    },
  });
};
