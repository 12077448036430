import { ModalProps } from 'antd';
import { UploadDonationsModalExtraProps } from 'modules/donations/types';
import { useState } from 'react';

import { ContextType } from './type';

export const useProviderValue = (): ContextType => {
  const [modalProps, setModalProps] = useState<
    ModalProps & UploadDonationsModalExtraProps
  >();
  const [visible, setVisible] = useState(false);

  const showModal = (
    params?: UploadDonationsModalExtraProps & { donorId: string }
  ) => {
    params && setModalProps({ ...modalProps, ...params });
    setVisible(true);
  };

  const updateModalProps = (
    props: ModalProps & UploadDonationsModalExtraProps
  ) => {
    setModalProps(props);
  };

  const hideModal = () => {
    setVisible(false);
  };

  return {
    modalConfig: {
      ...modalProps,
      visible,
      hide: hideModal,
    },
    showModal,
    hideModal,
    updateModalProps,
  };
};
