import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form } from 'antd';
import { ControlledTextArea, FormItem } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { RejectionReasonModalProps } from 'modules/requests/types';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { rejectionReasonFromSchema, RejectionReasonFormType } from './schema';
import styles from './styles.module.scss';

type PropTypes = BaseModalProps & RejectionReasonModalProps;

const RejectionReasonModal: FC<PropTypes> = ({ onOk, ...restModalProps }) => {
  const { t } = useTranslation('requests.RejectionReasonModal');
  const { formState, control, handleSubmit, reset } =
    useForm<RejectionReasonFormType>({
      resolver: yupResolver(rejectionReasonFromSchema),
      mode: 'onChange',
    });

  const onOkHandler = handleSubmit((formData) => {
    onOk!(formData.reason);
    restModalProps.hide();
    reset();
  });

  const onCancelHandler = () => {
    restModalProps.hide();
    reset();
  };
  const errorMessage = formState.errors.reason?.message;

  return (
    <Modal
      {...restModalProps}
      okButtonProps={{ title: t('ok') }}
      cancelButtonProps={{ title: t('cancel') }}
      onCancel={onCancelHandler}
      onOk={onOkHandler}
    >
      <Form>
        <FormItem
          className={styles.reasonFieldWrapper}
          label={t('rejectionReason')}
          extra={errorMessage}
          validateStatus={errorMessage && 'error'}
        >
          <ControlledTextArea
            placeholder={t('reason')}
            className={styles.reasonField}
            controlled={{ control, name: 'reason' }}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default RejectionReasonModal;
