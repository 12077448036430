import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import config from 'config';
import { format } from 'date-fns';
import UpsTrackingLink from 'modules/orders/components/UpsTrackingLink';
import { OrderOutput, OrdersListSort, OrderStatusFilter } from 'types.d';
import { envServices } from 'utils/EnvServices';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('orders.OrdersTable');

const filteredOrderStatusFilter = Object.values(OrderStatusFilter).filter(
  (status) => {
    return status !== OrderStatusFilter.Any;
  }
);
const statusFilters = filteredOrderStatusFilter.map((value) => ({
  value,
  text: t(value.toLowerCase()),
}));

export const getOrdersTableColumn = (
  showSorterTooltip?: boolean
): ColumnsType<OrderOutput> => [
  {
    title: t('orderNumber'),
    width: '10%',
    align: 'center',
    render: ({ id }: OrderOutput) => {
      const woocommerceOrdersLinkParams = new URLSearchParams({
        post: id,
        action: 'edit',
      });
      return (
        <Typography.Link
          target="_blank"
          href={`${envServices.get(
            'REACT_APP_WP_ADMIN_URI'
          )}/post.php?${woocommerceOrdersLinkParams}`}
        >
          {id}
        </Typography.Link>
      );
    },
  },
  {
    title: t('orderDate'),
    width: '25%',
    align: 'center',
    render: ({ date }: OrderOutput) => {
      return format(new Date(date), config.DATE_FORMAT);
    },
    key: OrdersListSort.Date,
    sorter: true,
    showSorterTooltip,
  },
  //Disabled part of code
  // {
  //   title: t('paymentMethod'),
  //   render: ({ paymentMethod }: OrderOutput) => paymentMethod,
  // },
  {
    title: t('status'),
    render: ({ status }: OrderOutput) => status,
    key: 'status',
    filters: statusFilters,
  },
  {
    title: t('trackingCode'),
    width: '45%',
    align: 'left',
    render: ({ trackingCode }: OrderOutput) => {
      if (!trackingCode) {
        return null;
      }
      const splittedTrackingCodes = trackingCode.split(',');
      return (
        <Typography.Paragraph
          ellipsis={{ rows: 2, expandable: true, symbol: t('more') }}
        >
          {splittedTrackingCodes.map((code) => {
            return <UpsTrackingLink trackingCode={code} />;
          })}
        </Typography.Paragraph>
      );
    },
  },
];
