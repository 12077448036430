import {
  ControlledInput,
  ControlledCheckbox,
  ControlledInputNumber,
  ControlledTextArea,
} from 'modules/common/components';
import ControlledDatePicker from 'modules/common/components/ControlledDatePicker';
import { FormFieldWithComponent } from 'modules/common/types';
import { PetSpeciesSelect } from 'modules/donor/components/index';
import RoutineTestGenderSelect from 'modules/donor/components/RoutineTestGenderSelect';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

const t = getTranslation('donor.RoutineTestForm');

export enum ContactsFormSectionField {
  Veterinarian = 'veterinarian',
  OwnerLastName = 'ownerLastName',
  AnimalName = 'animalName',
  CollectionDate = 'collectionDate',
  Species = 'species',
  Breed = 'breed',
  Age = 'age',
  Gender = 'gender',
  PatientID = 'patientID',
  StaffPet = 'staffPet',
  Notes = 'notes',
  DonorId = 'donorId',
}

type FormFiledType = FormFieldWithComponent<
  ContactsFormSectionField,
  { name: string }
>;

export const routineTestFormFields: FormFiledType[] = [
  {
    name: ContactsFormSectionField.Veterinarian,
    label: t(ContactsFormSectionField.Veterinarian),
    Component: ({ control, name }) => (
      <ControlledInput
        controlled={{ control, name }}
        placeholder={t('enterVeterinarian')}
      />
    ),
  },
  {
    name: ContactsFormSectionField.OwnerLastName,
    label: t(ContactsFormSectionField.OwnerLastName),
    Component: ({ control, name }) => (
      <ControlledInput
        controlled={{ control, name }}
        placeholder={t('enterOwnerLastName')}
      />
    ),
  },
  {
    name: ContactsFormSectionField.AnimalName,
    label: t(ContactsFormSectionField.AnimalName),
    Component: ({ control, name }) => (
      <ControlledInput
        controlled={{ control, name }}
        placeholder={t('enterAnimalName')}
      />
    ),
  },
  {
    name: ContactsFormSectionField.CollectionDate,
    label: t(ContactsFormSectionField.CollectionDate),
    Component: ({ control, name }) => (
      <ControlledDatePicker
        controlled={{ control, name }}
        adjustDropdownPosition
      />
    ),
  },
  {
    name: ContactsFormSectionField.Species,
    label: t(ContactsFormSectionField.Species),
    Component: ({ control, name }) => (
      <PetSpeciesSelect controlled={{ control, name }} />
    ),
  },
  {
    name: ContactsFormSectionField.Breed,
    label: t(ContactsFormSectionField.Breed),
    Component: ({ control, name }) => (
      <ControlledInput
        controlled={{ control, name }}
        placeholder={t('enterBreed')}
      />
    ),
  },
  {
    name: ContactsFormSectionField.Age,
    label: t(ContactsFormSectionField.Age),
    Component: ({ control, name }) => (
      <ControlledInputNumber
        controlled={{ control, name }}
        placeholder={t('enterAge')}
      />
    ),
  },
  {
    name: ContactsFormSectionField.Gender,
    label: t(ContactsFormSectionField.Gender),
    Component: ({ control, name }) => (
      <RoutineTestGenderSelect controlled={{ control, name }} />
    ),
  },
  {
    name: ContactsFormSectionField.PatientID,
    label: t(ContactsFormSectionField.PatientID),
    Component: ({ control, name }) => (
      <ControlledInput
        controlled={{ control, name }}
        placeholder={t('enterPatientId')}
      />
    ),
  },
  {
    name: ContactsFormSectionField.StaffPet,
    label: t(ContactsFormSectionField.StaffPet),
    Component: ({ control, name }) => (
      <>
        <ControlledCheckbox controlled={{ control, name }} />
        <span className={styles.staffPetExplanation}>
          {t('staffPetExplanation').toString()}
        </span>
      </>
    ),
  },
  {
    name: ContactsFormSectionField.Notes,
    label: t(ContactsFormSectionField.Notes),
    Component: ({ control, name }) => (
      <ControlledTextArea
        controlled={{ control, name }}
        placeholder={t('enterNotes')}
      />
    ),
  },
];
