import { SelectProps } from 'antd';
import { FormItem } from 'modules/common/components';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum, RoleOutput, UserRole } from 'types.d';

import LocationSelect from '../../../locations/components/LocationSelect';

type ProductLocationSelectProps = {
  control: Omit<UseControllerProps<any>, 'name'>;
  disabled: boolean;
  userRole: RoleOutput;
  error?: string;
} & SelectProps;

const ProductLocationSelect: FC<ProductLocationSelectProps> = ({
  control,
  disabled,
  userRole,
  ...restProps
}) => {
  const { t } = useTranslation('user');
  const user = useCurrentUser();
  const allowedRoles = [
    UserRole.Admin,
    UserRole.MedicalDirector,
    UserRole.CollectionTeam,
  ];
  const isAllowToDisplay = allowedRoles.includes(userRole.value);
  const isAllowToUpdate = user.isCan(
    PermissionsEnum.UpdateProductLocationPreference
  );

  return isAllowToDisplay ? (
    <FormItem label={t('productLocationPreference')}>
      <LocationSelect
        {...restProps}
        tags
        mode={'tags'}
        controlled={{
          ...control,
          name: 'productLocationPreference',
        }}
        disabled={disabled || !isAllowToUpdate}
      />
    </FormItem>
  ) : null;
};

export default ProductLocationSelect;
