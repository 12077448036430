import { ControlledSelect } from 'modules/common/components';
import Select from 'modules/common/components/Select';
import { ComponentProps, FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CollectionSet } from 'types.d';

type Props = {
  controlled: UseControllerProps<any>;
} & ComponentProps<typeof Select>;

export const CollectionSetsSelect: FC<Props> = ({
  controlled,
  ...otherProps
}) => {
  const { t } = useTranslation('donation.CollectionSetsSelect');
  const collectionSetOptions = Object.values(CollectionSet).map((value) => {
    return {
      value,
      label: t(value.charAt(0).toLowerCase() + value.slice(1)),
    };
  });

  return (
    <ControlledSelect
      {...otherProps}
      options={collectionSetOptions}
      defaultValue={undefined}
      controlled={controlled}
      placeholder={t('selectCollectionSet')}
    />
  );
};
