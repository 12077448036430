import { ColumnsType } from 'antd/es/table/Table';
import { Routes } from 'config/routes';
import { Link } from 'modules/common/components';
import { DonorOutput, DonorListSort } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('owner.EditOwnerProfileForm.DonorsTable');

export const columns: ColumnsType<DonorOutput> = [
  {
    title: t('petName'),
    render: (donor: DonorOutput) => (
      <Link
        to={`${Routes.DonorsProfile.replace(':id', String(donor.shortId))}`}
      >
        {donor.name}
      </Link>
    ),
    key: DonorListSort.Name,
  },
];
