import { Routes } from 'config/routes';
import { ITabsItem } from 'modules/common/components/CardTabs';
import {
  AdditionalDonationInfoForm,
  EditDonationForm,
} from 'modules/donations/components';
import NavigationCardTabs from 'modules/navigation/components/NavigationCardTabs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  donationId: string;
};

const DonationProfileCardTabs: FC<Props> = ({ donationId }) => {
  const { t } = useTranslation('donation.DonationProfileCardTabs');

  const editDonation: ITabsItem = {
    title: t('vitals'),
    content: <EditDonationForm />,
    key: `${Routes.Donations}/${donationId}`,
  };

  const additionalDonationInfo: ITabsItem = {
    title: t('additionalInfo'),
    content: <AdditionalDonationInfoForm />,
    key: `${Routes.DonationAdditionalInfo}/${donationId}`,
  };

  return <NavigationCardTabs items={[editDonation, additionalDonationInfo]} />;
};

export default DonationProfileCardTabs;
