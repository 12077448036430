import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { LocationSelect } from 'modules/donor/components';
import { getTranslation } from 'utils/getTranslation';

import { PersonalInfoFields } from './enum';

import ContactPreferences from '../../ContactPreferences';

const t = getTranslation('owner.EditOwnerProfileForm.PersonalInfoSection');

export const personalInfoFields: FormFieldWithComponent<
  PersonalInfoFields,
  { name: string; disabled?: boolean }
>[] = [
  {
    label: t('firstName'),
    name: PersonalInfoFields.firstName,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterFirstName')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('lastName'),
    name: PersonalInfoFields.lastName,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterLastName')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('streetAddress'),
    name: PersonalInfoFields.streetAddress,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterStreetAddress')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('city'),
    name: PersonalInfoFields.city,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterCity')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('state'),
    name: PersonalInfoFields.state,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterState')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('zipCode'),
    name: PersonalInfoFields.zipCode,
    Component: ({ control, name, disabled }) => (
      <ControlledInput
        placeholder={t('enterZipCode')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('phone'),
    name: PersonalInfoFields.phoneNumber,
    Component: ({ control, name, disabled }) => (
      <ControlledPhoneInput
        placeholder={t('enterPhone')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('location'),
    name: PersonalInfoFields.locationPreferences,
    Component: ({ control, name, disabled }) => (
      <LocationSelect
        placeholder={t('selectLocation')}
        controlled={{
          control,
          name,
        }}
        disabled={disabled}
      />
    ),
  },
  {
    label: t('contactPreferences'),
    name: PersonalInfoFields.contactPreferences,
    Component: ({ control, name, disabled }) => (
      <ContactPreferences
        disabled={disabled}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
];
