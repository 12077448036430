import { Col, Row, ModalProps } from 'antd';
import { Button } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import DonationsCsvTemplateDownload from 'modules/donations/components/DonationsCsvTemplateDownload';
import PossibleDonationValuesDownload from 'modules/donations/components/PossibleDonationValuesDownload';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateDonationSchemaType, createDonationSchema } from '../../schema';

type PropTypes = {
  okButtonProps?: ModalProps['okButtonProps'];
  cancelButtonProps?: ModalProps['okButtonProps'];
};

const Footer: FC<PropTypes> = ({ cancelButtonProps, okButtonProps }) => {
  const { t } = useTranslation('donation.UploadDonationsModal.Footer');
  const isRequired = getIsRequiredInSchema(createDonationSchema);
  const createDonationSchemaKeys = Object.keys(
    createDonationSchema.fields
  ) as unknown as (keyof CreateDonationSchemaType)[];
  const requiredHeaders = createDonationSchemaKeys.filter(isRequired);

  return (
    <Row gutter={[10, 0]}>
      <Col>
        <DonationsCsvTemplateDownload requiredHeaders={requiredHeaders} />
      </Col>
      <Col>
        <PossibleDonationValuesDownload />
      </Col>
      <Col flex={1} />
      <Col>
        <Button {...cancelButtonProps}>{t('cancel')}</Button>
      </Col>
      <Col>
        <Button {...okButtonProps} type="primary">
          {t('upload')}
        </Button>
      </Col>
    </Row>
  );
};

export default Footer;
