import { RcFile } from 'antd/lib/upload';
import Papa from 'papaparse';

type ParamTypes<Entity> = {
  file: RcFile;
  config?: Papa.ParseLocalConfig<Entity, Papa.LocalFile>;
};

export const getCsvHeaders = <Entity = string>({
  file,
  config,
}: ParamTypes<Entity>): Promise<Entity[] | undefined> => {
  const promise = new Promise<Entity[] | undefined>((resolve, _reject) => {
    Papa.parse<Entity>(file, {
      skipEmptyLines: true,
      quoteChar: `\"`,
      preview: 1,
      complete: (results, file) => {
        resolve(results.data[0] as unknown as Entity[]);
        config?.complete?.(results, file);
      },
    });
  });

  return promise;
};
