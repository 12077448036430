import { Table, TableProps } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { UseFilterObject } from 'modules/common/hooks/useFilter';
import { UseSortObject } from 'modules/common/hooks/useSort';
import { DonationDateFilters } from 'modules/donor/types';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC } from 'react';
import {
  BloodType,
  DonorListFilterInput,
  DonorListSort,
  DonorOutput,
  DonorStatus,
  DonorType,
  Order,
  useGetLocationsQuery,
} from 'types.d';

import { donationDateIntervals, getColumns } from './columns';
import styles from './styles.module.scss';

type PropTypes = {
  donorList: DonorOutput[];
  pagination: ReturnType<typeof usePagination>;
  setFilter?: (filterValue: DonorListFilterInput) => void;
  donorListSort?: UseSortObject<DonorListSort>;
  donorListFilters?: UseFilterObject<DonorListFilterInput>;
};

const IndividualDonorListTable: FC<PropTypes> = ({
  pagination,
  donorList,
  setFilter,
  donorListSort,
  donorListFilters,
}) => {
  const { data: locationQueryData } = useGetLocationsQuery({
    variables: {
      input: {
        //TODO fix it using search query with async filters if it possible
        take: 100,
      },
    },
  });
  const toFilter = (
    filters: Record<string, FilterValue | null>
  ): DonorListFilterInput => {
    const statuses = filters.status as DonorStatus[] | undefined;
    const types = filters.type as DonorType[] | undefined;
    const bloodType = filters.bloodType as BloodType[] | undefined;
    const donationDates = filters.lastDonationDate as
      | DonationDateFilters[]
      | undefined;
    const locations = (filters.locationPreferences as string[]) || undefined;

    const donationIntervals = donationDates?.reduce<{
      from: number[];
      to: number[];
    }>(
      (interval, donationDate) => {
        const { from, to } = donationDateIntervals[donationDate];
        return {
          from: [...interval.from, from],
          to: [...interval.to, to],
        };
      },
      { from: [], to: [] }
    );

    const lastDonationInterval =
      donationIntervals?.from.length && donationIntervals?.to.length
        ? {
            from: Math.min(...donationIntervals.from),
            to: Math.max(...donationIntervals.to),
          }
        : undefined;

    return {
      statuses,
      types,
      bloodType,
      lastDonationInterval,
      locations,
    };
  };

  const onTableChange: TableProps<DonorOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const tableSorter = sorter as SorterResult<DonorOutput>;
      const fieldName = tableSorter.columnKey as DonorListSort;
      const order = tableSorter.order === 'ascend' ? Order.Asc : Order.Desc;
      if (fieldName && order && tableSorter.order) {
        donorListSort?.setSortOption({ fieldName, order });
      }
      if (!(sorter as any).order) {
        donorListSort?.removeSortOption(fieldName);
      }
    }

    if (extra.action === 'filter') {
      const newFilterData = toFilter(filters);
      Object.keys(newFilterData).forEach((fieldName) => {
        donorListFilters?.setFilterOption({
          fieldName: fieldName as keyof DonorListFilterInput,
          value: (newFilterData as any)[fieldName],
        });
      });
    }
  };

  const columns = getColumns({
    showSorters: donorListSort?.setSortOption !== undefined,
    showFilters: donorListFilters !== undefined,
    filter: donorListFilters?.getFilterObject(),
    sort: donorListSort?.options[0],
    locations: locationQueryData?.getLocations.data,
  });

  return (
    <Table
      rowKey={({ id }) => id}
      rowClassName={({ status }) =>
        status === DonorStatus.Archived ? styles.archived : ''
      }
      columns={columns}
      dataSource={donorList}
      scroll={{ x: 750 }}
      onChange={onTableChange}
      pagination={pagination.config}
    />
  );
};

export default IndividualDonorListTable;
