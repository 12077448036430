import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useCreateProductModalContext } from 'modules/donations/contexts/CreateProductModal';
import { DonationAction } from 'modules/donations/enums';
import { FC } from 'react';
import { DonationOutput } from 'types';
import { envServices } from 'utils/EnvServices';

import styles from './styles.module.scss';

type PropTypes = {
  donation: DonationOutput;
};

const DonationDropdownActions: FC<PropTypes> = ({ donation }) => {
  const createProductModalContext = useCreateProductModalContext();
  const isAllowedProductCreation =
    envServices.get('REACT_APP_PRODUCT_CREATION_ALLOWED') &&
    !donation.ineligible;
  const menuOptions: ItemType[] = [
    {
      label: 'Create product',
      key: DonationAction.CreateProduct,
      disabled: !isAllowedProductCreation,
      onClick: () =>
        createProductModalContext?.showModal({ donationId: donation.id }),
    },
  ];
  return (
    <Dropdown trigger={['click']} overlay={<Menu items={menuOptions} />}>
      <MoreOutlined className={styles.moreIcon} />
    </Dropdown>
  );
};

export default DonationDropdownActions;
