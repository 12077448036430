import { Routes } from 'config/routes';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';

import EditLocationsModalProvider from './contexts/EditLocationsModal';
import LocationsPage from './pages/Locations';
import RegionsPage from './pages/Regions';

const locationModule: IModule = {
  routes: [
    {
      Component: LocationsPage,
      type: RouteType.Auth,
      path: Routes.Locations,
    },
    {
      Component: RegionsPage,
      type: RouteType.Auth,
      path: Routes.Regions,
    },
  ],
  providers: [{ Provider: EditLocationsModalProvider }],
};

export default locationModule;
