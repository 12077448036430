import {
  ControlledPasswordInput,
  ControlledInput,
} from 'modules/common/components';
import { getTranslation } from 'utils/getTranslation';

import { SignUpFields } from './enum';

const t = getTranslation('user.SignUpForm');

const signUpFormFields = [
  {
    label: t('firstName'),
    placeholder: t('enterFirstName'),
    name: SignUpFields.firstName,
    Component: ControlledInput,
  },
  {
    label: t('lastName'),
    placeholder: t('enterLastName'),
    name: SignUpFields.lastName,
    Component: ControlledInput,
  },
  {
    label: t('email'),
    placeholder: t('enterEmail'),
    name: SignUpFields.email,
    Component: ControlledInput,
  },
  {
    label: t('password'),
    placeholder: t('enterPassword'),
    name: SignUpFields.password,
    Component: ControlledPasswordInput,
  },
  {
    label: t('confirmPassword'),
    placeholder: t('confirmYourPassword'),
    name: SignUpFields.confirmPassword,
    Component: ControlledPasswordInput,
  },
];

export { signUpFormFields };
