import { DatePicker } from 'modules/common/components';
import { ComponentProps, FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
  adjustDropdownPosition?: boolean;
} & ComponentProps<typeof DatePicker>;

const ControlledDatePicker: FC<PropTypes> = ({
  controlled,
  className,
  adjustDropdownPosition,
  ...restProps
}) => {
  const controller = useController(controlled);
  const classNames = `${styles.datePicker} ${className} `;

  const getPopupContainer = (triggerNode: any) => {
    return triggerNode.parentNode;
  };

  return (
    <DatePicker
      {...restProps}
      {...controller.field}
      className={classNames}
      getPopupContainer={adjustDropdownPosition ? getPopupContainer : undefined}
    />
  );
};
export default ControlledDatePicker;
