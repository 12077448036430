import { message } from 'antd';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';
import {
  FileOutput,
  useDetachLabworkFileMutation,
  useGetLabworkAttachmentsQuery,
  useShareLabworkResultsMutation,
} from 'types.d';
import { fetchWithAuth } from 'utils/fetchWithAuth';
import { getAuthHeaders } from 'utils/getAuthHeaders';

import { LabworkAttachmentsTableProps } from './types';

export const useLabworkAttachmentsTable = (
  props: LabworkAttachmentsTableProps
) => {
  const { labworkId, setFileList } = props;
  const { t } = useTranslation('donor.LabworkAttachmentsTable');
  const getLabworkAttachmentsQuery = useGetLabworkAttachmentsQuery({
    variables: {
      input: {
        labworkId,
      },
    },
  });
  const [removeLabworkFile, removeLabworkFileMutation] =
    useDetachLabworkFileMutation({
      refetchQueries: ['getLabworkAttachments'],
      onCompleted: () => {
        message.success(t('labworkHasBeenDeleted'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });

  const removeLabworkFileHandler = (fileId: string) => {
    removeLabworkFile({
      variables: {
        input: {
          fileId,
          id: labworkId,
        },
      },
    });
  };

  const [shareLabworkResults, shareLabworkResultsMutation] =
    useShareLabworkResultsMutation({
      onCompleted: () => {
        message.success(t('emailHasBeenSent'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });

  const shareLabworkResultsHandler = (fileId: string) => {
    shareLabworkResults({
      variables: {
        input: {
          fileId,
          labworkId,
        },
      },
    });
  };

  const downloadFileHandler = async (file: FileOutput) => {
    const response = await fetchWithAuth(
      `${process.env.REACT_APP_SERVER_URI}/labwork/files/${file.id}`,
      {
        method: 'GET',
      }
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${file?.filename}`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  const inserFileIntoFilelist = (newFile: UploadFile) => {
    setFileList((files) => {
      const currentFileIndex = files.findIndex(
        (file) => file.name === newFile.name
      );

      if (currentFileIndex === -1) {
        return [...files, newFile];
      }

      return [
        ...files.slice(0, currentFileIndex),
        newFile,
        ...files.slice(currentFileIndex + 1),
      ];
    });
  };

  const isLoading = [
    getLabworkAttachmentsQuery,
    removeLabworkFileMutation,
    shareLabworkResultsMutation,
  ].some(({ loading }) => loading);
  const labworAttachments =
    getLabworkAttachmentsQuery.data?.getLabworkAttachments;

  const onChangeUploadFile = (uploadParams: UploadChangeParam) => {
    if (uploadParams.file.status === 'error') {
      const errorMessage =
        (uploadParams.file?.response?.message as string | undefined) ||
        t('uploadError');
      uploadParams.file.error = errorMessage;
      inserFileIntoFilelist(uploadParams.file);
      message.error(errorMessage);
    }

    if (uploadParams.file.status === 'done') {
      message.success(t('fileUploaded'));
      setFileList([]);
      getLabworkAttachmentsQuery.refetch({
        input: {
          labworkId,
        },
      });
    }
  };

  const beforeUpload = (file: RcFile) => {
    setFileList([file]);
    const isLessThan1Mb = file.size / 1024 / 1024 < 1;
    if (!isLessThan1Mb) {
      message.error(t('fileMustBeLessThan', { size: 1 }));
      (file as UploadChangeParam['file']).status = 'error';
      (file as UploadChangeParam['file']).error = t('fileMustBeLessThan', {
        size: 1,
      });
    }

    return isLessThan1Mb;
  };

  const uploadAttachmentProps: UploadProps = {
    name: 'file',
    accept: 'image/png, image/jpeg, application/pdf',
    action: `${process.env.REACT_APP_SERVER_URI}/labwork/upload`,
    headers: getAuthHeaders(),
    maxCount: 1,
    onChange: onChangeUploadFile,
    beforeUpload,
  };

  return {
    isLoading,
    labworAttachments,
    uploadAttachmentProps,
    removeLabworkFileHandler,
    shareLabworkResultsHandler,
    downloadFileHandler,
  };
};
