import { Col, Row, Table, TableProps, message, Grid } from 'antd';
import config from 'config';
import { Button, SearchInput, Spin } from 'modules/common/components';
import { useDebounce } from 'modules/common/hooks';
import { ISortOption } from 'modules/common/interfaces';
import { getMapSorter } from 'modules/common/utils/sort/getMapSorter';
import { getColumns } from 'modules/donations/components/DonationsTable/columns';
import { useUploadDonationsModalContext } from 'modules/donations/contexts/UploadDonationsModal';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import DonationProductDescriptions from 'modules/products/components/DonationProductsTable';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  DonationListFilterInput,
  DonationListSort,
  DonationOutput,
  Order,
  PermissionsEnum,
  useGetDonationListQuery,
  useGetWoocommerceCategoriesQuery,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const defaultOrder = [
  {
    fieldName: DonationListSort.LastDonationDate,
    order: Order.Desc,
  },
];

const { useBreakpoint } = Grid;

export const DonationsTable: FC = () => {
  const { debounce, clearDebounce } = useDebounce();
  const pagination = usePagination();
  const [filter, setFilter] = useState<DonationListFilterInput>();
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const [sort, setSort] = useState<ISortOption<DonationListSort>[] | undefined>(
    defaultOrder
  );
  const uploadDonationsModalContext = useUploadDonationsModalContext();
  const t = getTranslation('donation.DonationsTable');
  const currentUser = useCurrentUser();
  const canAddProducts = currentUser.isCan(PermissionsEnum.AddProducts);
  const canAddDonation = currentUser.isCan(PermissionsEnum.AddNewDonation);
  const getDonationListQuery = useGetDonationListQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter,
        sort,
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
      },
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: ({ getDonationsList }) => {
      pagination.setTotal(getDonationsList.meta.total);
    },
  });
  const donationList = getDonationListQuery.data?.getDonationsList
    .data as DonationOutput[];

  const onTableChange: TableProps<DonationOutput>['onChange'] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === 'sort') {
      const sorterToMap = Array.isArray(sorter) ? sorter : [sorter];
      const mappedSorter = mapSorter(sorterToMap);
      setSort(mappedSorter);
    }

    if (extra.action === 'filter') {
      setFilter((previousFilter) => {
        return {
          ...previousFilter,
          ...filters,
          searchQuery: null,
        };
      });
    }
  };

  useEffect(() => clearDebounce, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    pagination.moveTo(1);
    setSearchInputValue(searchValue);
    debounce((value?: string) =>
      setFilter({
        searchQuery: value,
      })
    )(searchValue);
  };
  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
  });
  const productCategories =
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories.reduce(
      (categories, category) => {
        return { ...categories, [category.id]: category.name };
      },
      {} as Record<number, string>
    );
  const { md } = useBreakpoint();
  const columns = useMemo(() => {
    return getColumns({
      canAddProducts,
      productCategories,
      showSorterTooltip: md,
      filtersValues: filter,
    });
  }, [canAddProducts, productCategories, filter]);

  useEffect(() => {
    return clearDebounce;
  }, []);

  const searchInputColumnWidth = canAddDonation ? 19 : 24;

  return (
    <>
      <Spin spinning={getDonationListQuery.loading}>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col sm={searchInputColumnWidth} span={24}>
                <SearchInput
                  placeholder={t('searchPlaceholder')}
                  onChange={handleSearch}
                  value={
                    filter?.searchQuery === null ? undefined : searchInputValue
                  }
                />
              </Col>
              {canAddDonation && (
                <Col sm={5} span={24}>
                  <Button
                    onClick={() => uploadDonationsModalContext.showModal()}
                    block
                    type="primary"
                  >
                    {t('uploadCsv')}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24}>
            <Table
              rowKey={({ id }) => id}
              columns={columns}
              dataSource={donationList}
              scroll={{ x: 750 }}
              onChange={onTableChange}
              pagination={pagination.config}
              expandable={{
                expandedRowRender: (donation) => {
                  return (
                    <DonationProductDescriptions
                      donation={donation}
                      pagination={false}
                    />
                  );
                },
                rowExpandable: ({ products }) => {
                  return Boolean(products?.length);
                },
              }}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );
};

const mapSorter = getMapSorter<DonationOutput, DonationListSort>(
  DonationListSort
);
