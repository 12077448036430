import { TypePolicies } from '@apollo/client';

import { skipTakePagination } from './skipTakePagination';

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      getUsersList: skipTakePagination(['input', ['roles', 'take', 'filter']]),
      getDonorsList: skipTakePagination(['input', ['take', 'sort', 'filter']]),
      findAllGroup: skipTakePagination([
        'input',
        ['take', 'sort', 'skip', 'filter'],
      ]),
      getRequestList: skipTakePagination(['input', ['take', 'sort', 'filter']]),
      getDonationsList: skipTakePagination([
        'input',
        ['take', 'sort', 'filter'],
      ]),
      getOrdersList: skipTakePagination(['input', ['take', 'sort']]),
      getProducts: skipTakePagination(['input', ['take', 'sort']]),
      getLabworkList: skipTakePagination(['input', ['take', 'sort', 'filter']]),
    },
  },
};
export { typePolicies };
