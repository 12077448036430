import { CreateDonationWithAdditionalInfoMutationVariables } from 'types.d';

import { CreateDonationSchemaType } from './schema';

type ParamType = {
  donorId: string;
  formData: CreateDonationSchemaType;
};

export const prepareDonationToCreate = ({
  donorId,
  formData,
}: ParamType): CreateDonationWithAdditionalInfoMutationVariables => {
  const { vitalsSection, additionalInfoSection } = formData;
  const { examination, donationInfo, environment, quantity, donationDate } =
    vitalsSection;
  const { poker, secondaryHolder, primaryHolder, ...otherFormData } =
    additionalInfoSection;
  const { species, ...restExaminationFields } = examination;

  return {
    input: {
      vitals: {
        ...restExaminationFields,
        ...donationInfo,
        ...environment,
        ...quantity,
        weatherTypes: environment.weatherTypes,
        donorId,
        donationDate,
        maxLimit: Number(donationInfo.maxLimit),
      },
      additionalInfo: {
        ...otherFormData,
        pokerId: poker,
        secondaryHolderId: secondaryHolder,
        primaryHolderId: primaryHolder,
      },
    },
  };
};
