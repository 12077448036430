import { ApolloQueryResult } from '@apollo/client';
import config from 'config';
import { GET_CHARTS_DATA_QUERY_QUERY } from 'modules/donor/graphql/queries';
import { apolloClient } from 'providers/graphql';
import {
  DonationListSort,
  GetChartsDataQuery,
  GetChartsDataQueryVariables,
  Order,
} from 'types.d';

export const getDonorCharts = (
  donorId: string
): Promise<ApolloQueryResult<GetChartsDataQuery>> => {
  return apolloClient.query<GetChartsDataQuery, GetChartsDataQueryVariables>({
    query: GET_CHARTS_DATA_QUERY_QUERY,
    variables: {
      input: {
        take: config.CHARTS_DEFAULT_DONATIONS_LIMIT,
        sort: [
          { fieldName: DonationListSort.LastDonationDate, order: Order.Desc },
        ],
        filter: {
          donorId,
        },
      },
    },
  });
};
