import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Modal, Popconfirm, Row, message } from 'antd';
import { Spin, Button, FormItem } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import { GET_DONOR_QUERY } from 'modules/donor/graphql/queries';
import { UpdateDonorsOwnerModalPropTypes } from 'modules/donor/types';
import UserSelect from 'modules/user/components/UserSelect';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserRole, useUpdateDonorsOwnerMutation } from 'types.d';

import { updateDonorsOwnerSchema, IUpdateDonorsOwner } from './schema';

type PropTypes = UpdateDonorsOwnerModalPropTypes & BaseModalProps;

const UpdateDonorsOwnerModal: FC<PropTypes> = ({ donor, visible, hide }) => {
  const { t } = useTranslation('donor.UpdateDonorsOwnerModal');
  const placeholder = donor?.user
    ? `${donor?.user?.firstName} ${donor?.user?.lastName}`
    : t('selectOrSearchOwner');
  const [updateDonorsOwner, updateDonorsOwnerMutation] =
    useUpdateDonorsOwnerMutation({
      refetchQueries: [
        {
          query: GET_DONOR_QUERY,
          variables: {
            input: {
              id: donor?.id,
            },
          },
        },
      ],
      onError: (error) => {
        message.error(error.message);
      },
    });
  const isLoading = updateDonorsOwnerMutation.loading;
  const { control, handleSubmit, formState, reset, watch, setValue } =
    useForm<IUpdateDonorsOwner>({
      resolver: yupResolver(updateDonorsOwnerSchema),
      mode: 'onChange',
      defaultValues: {
        id: donor?.id,
      },
    });
  const selectedOwnerId = watch('ownerId');
  const isDisabledOkButton = useMemo(
    () =>
      selectedOwnerId === donor?.user?.id ||
      isLoading ||
      !formState.isDirty ||
      !formState.isValid,
    [selectedOwnerId, formState.isDirty, formState.isValid, isLoading]
  );
  const isDisabledDropButton = !donor?.user;

  const submitForm = handleSubmit(async (formData) => {
    await updateDonorsOwner({
      variables: {
        input: formData,
      },
      onCompleted: () => {
        message.success(t('ownerChanged'));
      },
    });
  });

  const dropOwner = async () => {
    if (!donor) {
      return;
    }

    await updateDonorsOwner({
      variables: {
        input: { id: donor.id, ownerId: null },
      },
      onCompleted() {
        message.success(t('ownerDropped'));
      },
    });
    reset();
    hide();
  };

  const okHandler = async () => {
    await submitForm();
    reset();
    hide();
  };

  const cancelHandler = () => {
    hide();
    reset();
  };

  useEffect(() => {
    if (donor?.id) {
      setValue('id', donor?.id);
    }
  }, [donor?.id, visible]);

  return (
    <Spin spinning={isLoading}>
      <Modal
        title={t('updateOwner')}
        visible={visible}
        onCancel={cancelHandler}
        footer={
          <Row justify="space-between">
            <Col>
              <Popconfirm
                disabled={isDisabledDropButton}
                title={t('areYouSureDropOwner')}
                onConfirm={dropOwner}
              >
                <Button disabled={isDisabledDropButton}>{t('drop')}</Button>
              </Popconfirm>
            </Col>
            <Col>
              <Button onClick={cancelHandler}> {t('cancel')}</Button>
              <Popconfirm
                disabled={isDisabledOkButton}
                title={t('areYouSureSaveEdits')}
                onConfirm={okHandler}
              >
                <Button disabled={isDisabledOkButton} type="primary">
                  {t('save')}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        }
      >
        <Form>
          <FormItem label={t('selectNewOwner')}>
            <UserSelect
              allowClear
              placeholder={placeholder}
              userRoles={[UserRole.Owner]}
              controlled={{ control, name: 'ownerId' }}
            />
          </FormItem>
        </Form>
      </Modal>
    </Spin>
  );
};

export default UpdateDonorsOwnerModal;
