import { gql } from '@apollo/client';
import {
  PERMISSION_FRAGMENT,
  ROLE_OUTPUT_FRAGMENT,
  CONTACT_OUTPUT_FRAGMENT,
  USER_OUTPUT_FRAGMENT,
} from 'modules/user/graphql/fragments';

export const GET_ALL_PERMISSIONS_QUERY = gql`
  query getPermissions($input: GetPermissionsInput!) {
    getPermissions(input: $input) {
      ...Permission
      role {
        ...Role
      }
    }
  }
  ${ROLE_OUTPUT_FRAGMENT}
  ${PERMISSION_FRAGMENT}
`;

export const GET_USER_QUERY = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${ROLE_OUTPUT_FRAGMENT}
  ${CONTACT_OUTPUT_FRAGMENT}
  ${USER_OUTPUT_FRAGMENT}
`;
