import { getAccessToken } from 'utils/getAccessToken';

export const fetchWithAuth = async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  const token = getAccessToken();
  return fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  });
};
