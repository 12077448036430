import {
  createHttpLink,
  fromPromise,
  Operation,
  split,
  ApolloClient,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';
import { createClient } from 'graphql-ws';
import { authServices } from 'modules/auth/services/auth';
import {
  OfflineLink,
  checkIfOperationSupportsOfflineMode,
} from 'providers/graphql/offline-link';
import { typePolicies } from 'providers/graphql/typePolicies';
import { envServices } from 'utils/EnvServices';
import { getAccessToken } from 'utils/getAccessToken';
import { getTranslation } from 'utils/getTranslation';
import { logger } from 'utils/Logger';

const { ACCESS_TOKEN, REFRESH_TOKEN } = LOCALE_STORAGE_KEYS;

const t = getTranslation('common.GraphqlErrorMessages');

class ApolloClientService {
  isRefreshing = false;

  pendingRequests: any[] = [];

  storageTokensLocally = ({ accessToken, refreshToken }: TokensPair) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  };

  clearLocalTokenStorage() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }

  async refreshTokensPair(operation: Operation) {
    try {
      this.isRefreshing = true;
      const tokensPair = await authServices.getTokens();
      if (tokensPair) {
        this.storageTokensLocally(tokensPair);
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            Authorization: `Bearer ${tokensPair.accessToken}`,
          },
        });
      }
      return tokensPair;
    } catch (error) {
      this.pendingRequests = [];
      this.clearLocalTokenStorage();
      fromPromise(cachePersistor.purge());
      fromPromise(apolloClient.resetStore());
      return;
    } finally {
      this.isRefreshing = false;
    }
  }

  resolvePendingRequests = () => {
    this.pendingRequests.map((callback) => callback());
    this.pendingRequests = [];
  };

  getErrorLink = () => {
    return onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions.code) {
            case 'UNAUTHENTICATED':
              let forward$;
              if (!localStorage.getItem(REFRESH_TOKEN)) {
                return forward(operation);
              }

              if (!this.isRefreshing) {
                this.isRefreshing = true;
                forward$ = fromPromise(this.refreshTokensPair(operation));
              } else {
                forward$ = fromPromise(
                  new Promise((resolve) => {
                    this.pendingRequests.push(() => resolve(undefined));
                  })
                );
              }
              return forward$.flatMap(() => {
                return forward(operation);
              });
          }
        }
        logger.info(graphQLErrors.map(({ message }) => message).join('\n'));
      }
      if (networkError) {
        logger.info(
          `[Network error]: ${JSON.stringify({
            message: networkError.message,
            name: networkError.name,
            stack: networkError.stack,
          })}`
        );
        const operationSupportsOfflineMode =
          checkIfOperationSupportsOfflineMode(operation);
        if (operationSupportsOfflineMode && !window.navigator.onLine) {
          networkError.message = t('networkError');
        }
      }
    });
  };

  getHttpLink() {
    return createHttpLink({
      uri: `${envServices.get('REACT_APP_SERVER_URI')}/graphql`,
    });
  }

  getAuthLink() {
    return setContext((_, { headers }) => {
      const accessToken = getAccessToken();
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    });
  }

  getWsLink() {
    const accessToken = getAccessToken();
    return new GraphQLWsLink(
      createClient({
        url: process.env.REACT_APP_HOST_WS || '',
        connectionParams: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        },
      })
    );
  }

  getLinks() {
    const errorLink = this.getErrorLink();
    const authLink = this.getAuthLink();
    const httpLink = this.getHttpLink();
    const offlineLink = new OfflineLink();
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      this.getWsLink(),
      ApolloLink.from([offlineLink, errorLink, authLink, httpLink])
    );
  }
}

export const apolloClientService = new ApolloClientService();

type TokensPair = {
  accessToken: string;
  refreshToken: string;
};

const cache = new InMemoryCache(typePolicies);

export const cachePersistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
  debug: true,
  trigger: false,
  maxSize: false,
});
cachePersistor.restore();

export const apolloClient: ApolloClient<NormalizedCacheObject> =
  new ApolloClient({
    link: apolloClientService.getLinks(),
    cache,
    connectToDevTools: Boolean(process.env.REACT_APP_IS_DEV),
  });
