import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, TableColumnType } from 'antd';
import { ControlledInput, FormItem } from 'modules/common/components';
import LocationTypeSelect from 'modules/locations/components/LocationTypeSelect';
import { Control, FormState } from 'react-hook-form';
import { LocationOutput, LocationType } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { ICreateLocationsFormSchema } from './schema';
import styles from './styles.module.scss';

import RegionSelect from '../RegionSelect/inex';

//TODO: update locales for this component
const t = getTranslation('user.LocationsTable');

type FormErrorsType = FormState<ICreateLocationsFormSchema>['errors'];
type ParamType = {
  editableKey?: string;
  setEditableKey?: (
    editableKey?: string,
    locationName?: string,
    currentLocationType?: LocationType,
    currentLocationRegion?: string
  ) => void;
  onSaveChanges?: () => void;
  onDelete?: (locationId: string) => void;
  control: Control<ICreateLocationsFormSchema, any>;
  savingIsDisabled?: boolean;
  isAllowedToEdit?: boolean;
  isAllowedToDelete?: boolean;
  errors: FormErrorsType;
  isMobile: boolean;
};

export const getColumns = (
  params: ParamType
): TableColumnType<LocationOutput>[] => {
  const {
    control,
    setEditableKey,
    editableKey,
    onDelete,
    onSaveChanges,
    savingIsDisabled,
    isAllowedToDelete,
    isAllowedToEdit,
    errors,
    isMobile,
  } = params;
  const isEditing = (locationId: string) => editableKey === locationId;
  const getErrorMessage = (errors: FormErrorsType) => errors.name?.message;

  return [
    {
      width: '25%',
      title: t('name'),
      render: ({ id, name }: LocationOutput) => {
        const errorMessage = getErrorMessage(errors);
        const validateStatus = errorMessage && 'error';
        return !isEditing(id) ? (
          <span title={name} className={styles.locationName}>
            {name}
          </span>
        ) : (
          <FormItem
            extra={errorMessage}
            validateStatus={validateStatus}
            className={styles.formItem}
          >
            <ControlledInput
              size="small"
              controlled={{ control, name: 'name', defaultValue: name }}
            />
          </FormItem>
        );
      },
    },
    {
      width: '25%',
      title: t('type'),
      render: ({ id, type }: LocationOutput) => {
        return !isEditing(id) ? (
          t(`${type[0].toLowerCase()}${type.substring(1)}`)
        ) : (
          <LocationTypeSelect
            adjustDropdownPosition
            size="small"
            className={isMobile ? styles.locationTypeInput : ''}
            dropdownMatchSelectWidth={!isMobile}
            controlled={{ control, name: 'type', defaultValue: type }}
          />
        );
      },
    },
    {
      width: '25%',
      title: t('region'),
      render: ({ id, region }: LocationOutput) => {
        return !isEditing(id) ? (
          region?.name || '-'
        ) : (
          <RegionSelect
            controlled={{ control, name: 'region', defaultValue: region?.id }}
          />
        );
      },
    },
    {
      width: '25%',
      render: ({ id, name, type, region }: LocationOutput) => {
        return isEditing(id) ? (
          <Row gutter={15}>
            <Col span={12}>
              <Popconfirm
                overlayClassName={styles.popConfirm}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                title={t('sureToSave')}
                onConfirm={onSaveChanges}
                disabled={savingIsDisabled}
              >
                <Button type="text" size="small" disabled={savingIsDisabled}>
                  <CheckOutlined />
                </Button>
              </Popconfirm>
            </Col>
            <Col span={12}>
              <Button
                onClick={() => setEditableKey?.(undefined)}
                type="text"
                size="small"
              >
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
        ) : (
          <Row align="middle">
            <Col span={12}>
              <Button
                onClick={() => setEditableKey?.(id, name, type, region?.id)}
                type="link"
                size="small"
                disabled={!isAllowedToEdit}
              >
                <EditOutlined />
              </Button>
            </Col>
            <Col span={12}>
              <Popconfirm
                overlayClassName={styles.popConfirm}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                title={t('sureToDelete')}
                onConfirm={() => onDelete?.(id)}
                disabled={!isAllowedToDelete}
              >
                <Button danger type="link" disabled={!isAllowedToDelete}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];
};
