import DonorProfileCardTabs from 'modules/donor/components/DonorProfileCardTabs';
import ProfileSideMenuWrapper from 'modules/navigation/components/ProfileSideMenuWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const DonorDonationsPage: FC = () => {
  const { t } = useTranslation('donor.DonorDonationsPage');
  const params = useParams();
  const id = params.id || '';

  return (
    <ProfileSideMenuWrapper title={t('donorDonations')}>
      <DonorProfileCardTabs donorId={id} />
    </ProfileSideMenuWrapper>
  );
};
export default DonorDonationsPage;
