import { DonorOutput, UpdateDonorInput } from 'types.d';

import { EditDonorSchemaType } from './schema';

export const prepareDataForDonorUpdating = (
  formData: EditDonorSchemaType,
  donor: DonorOutput
): UpdateDonorInput => {
  const {
    gender,
    name,
    neutered,
    primaryVeterinarian,
    species,
    weight,
    medicationCourse,
    location,
    acceptSimparicaTrio,
    donationFrequency,
    dateOfBirth,
  } = formData.required;
  const {
    bloodType,
    donorType,
    distinguishableMarks,
    breed,
    color,
    distemperDate,
    rabiesDate,
    retiredDate,
    team,
    isDaycare,
    donationMLApprovedAmount,
    attendDaycareDays,
    wontRegularlyAttendDaycare,
    comment,
  } = formData.optional;
  const { firstCertificatePrinted, secondCertificatePrinted } =
    formData.additional;
  const { retiredClarification, ...donorStatus } = formData.donorStatus;
  return {
    id: donor.id,
    gender,
    name,
    neutered,
    primaryVeterinarian,
    species,
    weight,
    medicationCourse,
    bloodType,
    type: donorType,
    distinguishableMarks,
    location,
    breed,
    color,
    firstCertificatePrinted,
    secondCertificatePrinted,
    distemperDate,
    rabiesDate,
    retiredDate,
    teamId: team,
    isDaycare,
    acceptSimparicaTrio,
    donationMLApprovedAmount,
    attendDaycareDays,
    comment,
    canRegularlyAttendDaycare: !wontRegularlyAttendDaycare,
    retiredReasonComment: retiredClarification,
    donationFrequency,
    dateOfBirth,
    ...donorStatus,
  };
};
