import { highlightCsvHeadersLabel } from 'modules/common/utils/papaparse/highlightCsvHeadersLabel';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { DONATIONS_CSV_HEADERS } from 'modules/donations/constants';
import {
  donationUnitSchema,
  actualSchema,
  capillaryRefillTimeSchema,
  collectionSetSchema,
  collectionUnitsUsedSchema,
  donationDateSchema,
  flowRateSchema,
  jugularVeinSchema,
  mMSchema,
  vascularStructureSchema,
  veinUsedSchema,
  weatherTypesSchema,
} from 'modules/donations/schemas';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import {
  CapillaryRefillTime,
  Mm,
  WeatherType,
  CollectionSet,
  JugularVein,
  DonationUnit,
  CollectionUnitsUsed,
  VeinUsed,
  VascularStructure,
  FlowRate,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donation.UploadDonationsModal');

export type CreateDonationSchemaType = {
  // Vitals
  temperature: number;
  pulse: number;
  respiration: number;
  capillaryRefillTime: CapillaryRefillTime;
  mucousMembrane: Mm;
  packedCellVolume: number;
  totalSolids: number;
  weight: number;
  working: boolean;
  weatherTemperature: number;
  weatherTypes: WeatherType[];
  shouldDonate: boolean;
  collectionSet: CollectionSet;
  jugularVein: JugularVein;
  unit?: DonationUnit;
  actual?: number;
  shortDonorId: string;
  maxLimit: number;
  approvedDonationsMl: number;
  preferences?: string;
  examinationNotes?: string;
  comment?: string;
  donationDate: Date;
  // Additional info
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  veinUsed: VeinUsed;
  vascularStructure?: VascularStructure;
  flowRate: FlowRate;
  donationPosition: string;
  holdersPosition: string[];
  donorMovement: string;
  typeOfMovement?: string;
  emotionalSupport?: string[];
  dislikes?: string[];
  likes?: string[];
  treatPreferences?: string[];
  shortPrimaryHolderId?: string;
  shortSecondaryHolderId?: string;
  shortPokerId?: string;
};

type JsonFieldsSchemaType = {
  weatherTypes: boolean;
  holdersPosition: boolean;
  emotionalSupport: boolean;
  dislikes: boolean;
  likes: boolean;
  treatPreferences: boolean;
};

export type CreateDonationsSchemaType = {
  donations: CreateDonationSchemaType[];
  csvHeaders: string[];
};

export const createDonationSchema: yup.SchemaOf<CreateDonationSchemaType> = yup
  .object()
  .shape(
    {
      //Vitals
      temperature: yup
        .number()
        .typeError(t('invalid', { field: t('temperature') }))
        .required(t('required', { field: t('temperature') })),
      pulse: yup
        .number()
        .typeError(t('invalid', { field: t('pulse') }))
        .required(t('required', { field: t('pulse') })),
      respiration: yup
        .number()
        .typeError(t('invalid', { field: t('respiration') }))
        .required(t('required', { field: t('respiration') })),
      capillaryRefillTime: capillaryRefillTimeSchema,
      mucousMembrane: mMSchema,
      packedCellVolume: yup
        .number()
        .typeError(t('invalid', { field: t('packedCellVolume') }))
        .required(t('required', { field: t('packedCellVolume') }))
        .max(100, t('lessThan', { max: 100 })),
      totalSolids: yup
        .number()
        .typeError(t('invalid', { field: t('totalSolids') }))
        .required(t('required', { field: t('totalSolids') })),
      weight: yup
        .number()
        .typeError(t('invalid', { field: t('weight') }))
        .required(t('required', { field: t('weight') })),
      working: yup
        .boolean()
        .typeError(t('invalid', { field: t('working') }))
        .required(t('required', { field: t('working') })),
      examinationNotes: yup.string(),
      weatherTemperature: yup
        .number()
        .typeError(t('invalid', { field: t('weatherTemperature') }))
        .required(t('required', { field: t('weatherTemperature') })),
      weatherTypes: weatherTypesSchema.required(
        t('required', { field: t('weatherTypes') })
      ),
      shouldDonate: yup
        .boolean()
        .typeError(t('invalid', { field: t('shouldDonateField') }))
        .required(t('required', { field: t('shouldDonateField') })),
      maxLimit: yup
        .number()
        .typeError(t('invalid', { field: t('maxLimitField') }))
        .required(t('required', { field: t('maxLimitField') })),
      collectionSet: collectionSetSchema,
      jugularVein: jugularVeinSchema,
      preferences: yup.string().max(255, t('lessThan', { max: 255 })),
      approvedDonationsMl: yup
        .number()
        .typeError(t('invalid', { field: t('expected') }))
        .required(t('required', { field: t('expected') })),
      unit: donationUnitSchema,
      actual: actualSchema,
      shortDonorId: yup.string().max(255).required(),
      donationDate: donationDateSchema,
      comment: yup.string().max(255),

      //Additional info
      numberOfCollectionUnitsUsed: collectionUnitsUsedSchema,
      veinUsed: veinUsedSchema,
      vascularStructure: vascularStructureSchema,
      flowRate: flowRateSchema,
      donationPosition: yup.string().required(
        t('required', {
          field: t('donationPosition'),
          context: 'jsonInvalid',
        })
      ),
      holdersPosition: yup
        .array()
        .min(1, t('required', { field: t('holdersPosition') }))
        .required(t('required', { field: t('holdersPosition') })),
      donorMovement: yup.string().required(),
      typeOfMovement: yup.string(),
      emotionalSupport: yup.array(),
      dislikes: yup.array(),
      likes: yup.array(),
      treatPreferences: yup.array(),
      shortPrimaryHolderId: yup.string(),
      shortSecondaryHolderId: yup.string(),
      shortPokerId: yup.string(),
    },
    [['unit', 'actual']]
  );

const isRequired = getIsRequiredInSchema(createDonationSchema);
const createDonationSchemaKeys = Object.keys(
  createDonationSchema.fields
) as unknown as (keyof CreateDonationSchemaType)[];
const requiredHeaders = createDonationSchemaKeys.filter(isRequired);

export const highlightedHeaders = highlightCsvHeadersLabel({
  headers: DONATIONS_CSV_HEADERS as unknown as LabelKeyObject[],
  toHighlight: requiredHeaders,
});
const headerLabels = highlightedHeaders.map(({ label }) => label);

export const createDonationsSchema: yup.SchemaOf<CreateDonationsSchemaType> =
  yup.object({
    donations: yup.array().of(createDonationSchema).min(1).required(),
    csvHeaders: yup
      .array()
      .of(yup.string().oneOf<string>(headerLabels).required())
      .required(),
  });
