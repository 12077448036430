import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { getEntries } from 'utils/object/getEntries';

const donationHeadersConfig = {
  id: 'id',
  shortId: 'shortId',
  temperature: 'temperature',
  pulse: 'pulse',
  respiration: 'respiration',
  capillaryRefillTime: 'capillaryRefillTime',
  mucousMembrane: 'mucousMembrane',
  packedCellVolume: 'packedCellVolume',
  totalSolids: 'totalSolids',
  weight: 'weight',
  working: 'working',
  unit: 'unit',
  actual: 'actual',
  approvedDonationsMl: 'approvedDonationsMl',
  donationDate: 'donationDate',
  donationNumber: 'donationNumber',
  jugularVein: 'jugularVein',
  ineligibleReason: 'ineligibleReason',
  additionalInfo: {
    id: 'additionalInfoId',
    numberOfCollectionUnitsUsed: 'numberOfCollectionUnitsUsed',
    primaryHolder: {
      firstName: 'primaryHolderFirstName',
      lastName: 'primaryHolderLastName',
    },
    poker: {
      firstName: 'pokerFirstName',
      lastName: 'pokerLastName',
    },
  },
  donor: {
    id: 'donorId',
    name: 'donorFirstName',
    user: {
      lastName: 'donorLastName',
    },
    location: 'donorLocation',
  },
  comment: 'comment',
};

const donationHeadersConfigEntries = getEntries(donationHeadersConfig);

export const donationReportHeaders: LabelKeyObject[] =
  donationHeadersConfigEntries.map(([key, label]) => ({ label, key }));
