export enum AdditionalDonationInfoFields {
  numberOfCollectionUnitsUsed = 'numberOfCollectionUnitsUsed',
  veinUsed = 'veinUsed',
  vascularStructure = 'vascularStructure',
  flowRate = 'flowRate',
  donationPosition = 'donationPosition',
  holdersPosition = 'holdersPosition',
  donorMovement = 'donorMovement',
  typeOfMovement = 'typeOfMovement',
  emotionalSupport = 'emotionalSupport',
  dislikes = 'dislikes',
  likes = 'likes',
  treatPreferences = 'treatPreferences',
  primaryHolder = 'primaryHolder',
  secondaryHolder = 'secondaryHolder',
  poker = 'poker',
}
