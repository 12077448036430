import { Row, Col, Pagination } from 'antd';
import { useSort } from 'modules/common/hooks';
import { GroupCard } from 'modules/donor/components';
import { GroupCardListSorter } from 'modules/donor/components/GroupCardList/GroupCardListSorter';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { FC, useState } from 'react';
import { GroupListSort, GroupListItemOutput } from 'types.d';

import styles from './styles.module.scss';

const useGroupListSort = () => useSort<GroupListSort>();

type PropTypes = {
  groups?: GroupListItemOutput[];
  pagination: ReturnType<typeof usePagination>;
  sorting: ReturnType<typeof useGroupListSort>;
};

const GroupCardList: FC<PropTypes> = ({ groups, pagination, sorting }) => {
  const [currentEdit, setCurrentEdit] = useState<string | null>(null);
  return (
    <Row gutter={[0, 30]}>
      <Col className={styles.sorterWrapper}>
        <GroupCardListSorter sorting={sorting} />
      </Col>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          {groups?.map((group) => (
            <Col span={24} key={group.id}>
              <GroupCard
                group={group}
                setCurrentEdit={setCurrentEdit}
                currentEdit={currentEdit}
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        <Pagination {...pagination.config} />
      </Col>
    </Row>
  );
};

export default GroupCardList;
