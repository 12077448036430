export default {
  CreateDonationPage: {
    pageTitle: 'Adding new donation',
  },
  CapillaryRefillTimeSelect: {
    selectCapillaryRefillTime: 'Select capillary refill time',
    betweenOneAndTwo: '1-2',
    moreThanTwo: '>2',
    lessThanTwo: '<2',
    sec: 'Sec',
    notApplicable: 'N/A',
  },
  CollectionSetsSelect: {
    selectCollectionSet: 'Select collection set',
    notApplicable: 'Not applicable',
    quad: 'Quad',
    single: 'Single',
  },
  DonationUnitRadioGroup: {
    unit0: '0 units',
    unit1: '1 unit',
    unit2: '2 units',
  },
  JugularVeinsSelect: {
    selectJugularVein: 'Select jugular vein',
    left: 'Left',
    notApplicable: 'N/A',
    right: 'Right',
  },
  MucousMembraneSelect: {
    selectMucousMembrane: 'Select mucous membrane',
    blue: 'Blue',
    darkPink: 'Dark pink',
    grey: 'Grey',
    lightPink: 'Light pink',
    pigmented: 'Pigmented',
    pink: 'Pink',
    tacky: 'Tacky',
    white: 'White',
    yellow: 'Yellow',
    notApplicable: 'N/A',
  },
  PositionsSelect: {
    selectPosition: 'Select position',
  },
  WeatherTypesSelect: {
    selectWeatherTypes: 'Select weather types',
    clear: 'Clear',
    cloudy: 'Cloudy',
    drizzle: 'Drizzle',
    notApplicable: 'N/A',
    overcast: 'Overcast',
    partiallyCloudy: 'Partially cloudy',
    rain: 'Rain',
    snow: 'Snow',
    stormy: 'Stormy',
    sunny: 'Sunny',
  },
  CreateDonationForm: {
    examination: 'Examination',
    donationInfo: 'Donation info',
    save: 'Save',
    donationCreated: 'Donation has been successfully created!',
    donorArchived: 'Donor is archived',
    Examination: {
      f: 'F',
      bmp: 'BMP',
      bpm: 'BPM',
      gPerDL: 'G/DL',
      kg: 'KG',
      s: 'S',
      yes: 'Yes',
      no: 'No',
      characteristic: 'Characteristic',
      previousDonation: 'Previous donation data',
      notApplicable: 'N/A',
      value: 'Value',
      temperature: 'Temperature',
      enterTemperature: 'Enter temperature',
      pulse: 'Pulse',
      enterPulse: 'Enter pulse',
      respiration: 'Respiration',
      enterRespiration: 'Enter respiration',
      capillaryRefillTime: 'Capillary refill time',
      enterCapillaryRefillTime: 'Enter capillary refill time',
      mucousMembrane: 'Mucous membrane',
      packedCellVolume: 'Packed cell volume',
      enterPackedCellVolume: 'Enter packed cell volume',
      totalSolids: 'Total solids',
      enterTotalSolids: 'Enter total solids',
      weight: 'Weight',
      enterWeight: 'Enter weight',
      working: 'Working',
      examinationNotes: 'Examination notes',
      betweenOneAndTwo: '1-2',
      moreThanTwo: '>2',
      lessThanOne: '<1',
      required: '{{field}} is required!',
      lessThan: 'It must be less than {{max}} characters',
      invalid: '{{field}} is invalid!',
      donationIneligible: 'Donation ineligible',
      ineligibleReason: 'Ineligible reason',
      ineligibleReasonDetails: 'Ineligible reason details',
      selectReason: 'Select reason',
    },
    DonationInfo: {
      question: 'Question',
      answer: 'Answer',
      shouldDonate: 'Should they donate?',
      maxLimit: 'Maximum draw volume?',
      shouldDonateField: 'Should donate',
      maxLimitField: 'Max limit',
      collectionSet: 'Which collection set should you use?',
      jugularVein: 'Which jugular vein?',
      position: 'What position?',
      preferences: 'Preferences?',
      enterPreferences: 'Enter preferences',
      failedToLoad: 'Failed to load data',
      ml: 'ML',
      totalCollectedMls: "Total collected mL's",
      enterTotalCollectedMls: "Enter total collected mL's",
      numberOfCollectionUnitsUsed: 'Number of Collection Units Used',
      primaryHolder: 'Primary holder',
      enterPrimaryHolderName: 'Enter enter primary holder name',
      secondaryHolder: 'Secondary holder',
      enterSecondaryHolderName: 'Enter enter secondary holder name',
      poker: 'Poker',
      enterPokerName: 'Enter poker name',
      veinUsed: 'Vein used',
      vascularStructure: 'Vascular structure',
      flowRate: 'Flow rate',
      donationPosition: 'Donation position',
      holdersPosition: 'Holder(s) position',
      donorMovement: 'Donor movement',
      typeOfMovement: 'Type of movement',
      emotionalSupport: 'Emotional support',
      dislikes: 'Dislikes',
      likes: 'Likes',
      treatPreferences: 'Treat preferences',
      required: '{{field}} is required!',
      invalid: '{{field}} is invalid!',
      lessThan: 'It must be less than {{max}} characters',
    },
    Environment: {
      f: 'F',
      environment: 'Environment',
      weatherTemperature: 'Weather temperature',
      weatherTypes: 'Weather types',
      enterTemperature: 'Enter temperature',
      required: '{{field}} is required!',
      invalid: '{{field}} is invalid!',
    },
    Quantity: {
      quantity: 'Quantity',
      expected: 'Expected',
      enterExpected: 'Enter expected',
      enterActual: 'Enter actual',
      ml: 'ML',
      required: '{{field}} is required!',
      invalid: '{{field}} is invalid!',
      actual: 'Actual',
      enterValidValues: 'Please enter valid values!',
    },
    DonorInfo: {
      donorId: 'Donor ID',
      donorName: 'Donor name',
      failedToLoad: 'Failed to load',
    },
  },
  EditDonationPage: {
    pageTitle: 'Editing donation',
  },
  EditDonationForm: {
    donorId: 'Donor ID',
    examination: 'Examination',
    donationInfo: 'Donation info',
    save: 'Save',
    edit: 'Edit',
    close: 'Close',
    donationUpdated: 'Donation has been successfully updated!',
    donationDonorUpdated: 'Donation donor updated!',
    enterDonorName: 'Enter donor name',
    pickDonationDate: 'Pick donation date',
    donationDate: 'Donation date',
    Examination: {
      f: 'F',
      bmp: 'BMP',
      bpm: 'BPM',
      gPerDL: 'G/DL',
      kg: 'KG',
      s: 'S',
      yes: 'Yes',
      no: 'No',
      characteristic: 'Characteristic',
      previousDonation: 'Previous donation data',
      value: 'Value',
      temperature: 'Temperature',
      enterTemperature: 'Enter temperature',
      pulse: 'Pulse',
      enterPulse: 'Enter pulse',
      respiration: 'Respiration',
      enterRespiration: 'Enter respiration',
      capillaryRefillTime: 'Capillary refill time',
      enterCapillaryRefillTime: 'Enter capillary refill time',
      mucousMembrane: 'Mucous membrane',
      selectMucousMembrane: 'Select mucous membrane',
      packedCellVolume: 'Packed cell volume',
      enterPackedCellVolume: 'Enter packed cell volume',
      totalSolids: 'Total solids',
      enterTotalSolids: 'Enter total solids',
      weight: 'Weight',
      enterWeight: 'Enter weight',
      working: 'Working',
      examinationNotes: 'Examination notes',
    },
    DonationInfo: {
      question: 'Question',
      answer: 'Answer',
      shouldDonate: 'Should they donate?',
      maxLimit: 'Maximum draw volume?',
      collectionSet: 'Which collection set should you use?',
      selectCollectionSet: 'Select collection set',
      jugularVein: 'Which jugular vein?',
      selectJugularVein: 'Select jugular vein',
      position: 'What position?',
      selectPosition: 'Select position',
      preferences: 'Preferences?',
      enterPreferences: 'Enter preferences',
      failedToLoad: 'Failed to load data',
      ml: 'ML',
    },
    Environment: {
      f: 'F',
      environment: 'Environment',
      weatherTemperature: 'Weather temperature',
      weatherTypes: 'Weather types',
      selectWeatherTypes: 'Select weather types',
      enterTemperature: 'Enter temperature',
    },
    Quantity: {
      quantity: 'Quantity',
      unit0: '0 units',
      unit1: '1 unit',
      unit2: '2 units',
      enterExpected: 'Enter expected',
      enterActual: 'Enter actual',
      ml: 'ML',
    },
    DonorInfo: {
      donorId: 'Donor ID',
      donorName: 'Donor name',
    },
  },
  DonationDataCollectionPage: {
    donationsDataCollectionPage: 'Donations data collection page',
  },
  DonationsTable: {
    searchPlaceholder:
      'Search by donor name, owner name, donation ID, location',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
    donorName: 'Donor name',
    ownerLastName: "Owner's last name",
    bloodType: 'Blood type',
    donationId: 'Donation ID',
    outcome: 'Outcome',
    location: 'Location',
    donationStatus: 'Donation status',
    dateOfLastDonation: 'Date of last donation',
    manassasva: 'Manassas, VA',
    alexandriava: 'Alexandria, VA (Harmony Animal Hospital)',
    middleburgva: 'Middleburg, VA',
    marshallva: 'Marshall, VA',
    fredericksburgva: 'Fredericksburg, VA',
    noData: 'No data',
    unit0: '0 units',
    unit1: '1 unit',
    unit2: '2 units',
    uploadCsv: 'Upload CSV',
    OutcomeCell: {
      productDonation: 'Product donation',
      ineligible: 'Ineligible',
    },
  },
  UploadDonationsModal: {
    Footer: {
      cancel: 'Cancel',
      upload: 'Upload',
    },
    ValidatingStatus: {
      dataIsNotValid: 'Some data is not valid!',
      dataIsValid: 'Data is valid',
    },
    ErrorMessages: {
      headers: 'Headers',
      headerMustBe: 'Header[{{index}}]: must be like "{{header}}"',
    },
    uploadDonations: 'Upload donations',
    donationsHasBeenUploaded: 'Donations has been uploaded',
    petAge: 'Pet age',
    petWeight: 'Pet weight',
    petGender: 'Pet gender',
    neutered: 'Spayed or neutered',
    location: 'Location',
    medicationHistory: 'Medication history',
    primaryVeterinarian: 'Primary veterinarian',
    ownerLastName: "Owner's lastname",
    petName: 'Pet name',
    species: 'Species',
    required: '{{field}} is required!',
    moreThan: 'It must be more than {{min}} characters',
    lessThan: 'It must be less than {{max}} characters',
    onlyAlphabets: 'It must contain only alphabets',
    mustBeNumber: 'Must be a number',
    mustBeString: 'Must be a string',
    bloodType: 'Blood type',
    veterinarian: 'Veterinarian',
    animalName: 'Animal name',
    collectionDate: 'Collection date',
    breed: 'Breed',
    age: 'Age',
    gender: 'Gender',
    patientID: 'Patient id',
    staffPet: 'Staff pet',
    mustBeInt: 'Must be an integer',
    provideTreatmentInfo:
      'Please provide additional information about course of treatment',
    hasTransfusion: 'Has transfusion',
    takeMedications: 'Take medications',
    holdersPosition: 'Holders positions',
    invalidJsonFormat: '{{field}} has invalid JSON format!',
  },
  DonationProfileCardTabs: {
    vitals: 'Vitals',
    additionalInfo: 'Additional info',
  },
  CollectionUnitsUsedSelect: {
    selectCollectionUnitsUsed: 'Select collection units used',
    one: 'One',
    three: 'Three',
    two: 'Two',
    zero: 'Zero',
  },
  VeinUsedSelect: {
    selectVeinUsed: 'Select vein used',
    both: 'Both',
    leftJugular: 'Left jugular',
    rightJugular: 'Right jugular',
    notApplicable: 'N/A',
  },
  VascularStructureSelect: {
    selectVascularStructure: 'Select vascular structure',
    deep: 'Deep',
    lateral: 'Lateral',
    medial: 'Medial',
    superficial: 'Superficial',
  },
  FlowRateSelect: {
    selectFlowRate: 'Select flow rate',
    fast: 'Fast',
    intermittentStopping: 'Intermittent stopping',
    medium: 'Medium',
    occasionalFlutter: 'Occasional flutter',
    persistentFlutter: 'Persistent flutter',
    slow: 'Slow',
    notApplicable: 'N/A',
  },
  DonationPositionSelect: {
    selectDonationPosition: 'Select donation position',
    leftLateral: 'Left lateral',
    rightLateral: 'Right lateral',
    sternal: 'Sternal',
    sitting: 'Sitting',
    standing: 'Standing',
    notApplicable: 'N/A',
  },
  HolderPositionSelect: {
    selectHolderPosition: 'Select holder position',
    atFeet: 'At feet',
    atHead: 'At head',
    behind: 'Behind',
    onTop: 'On top',
    spooning: 'Spooning',
    notApplicable: 'N/A',
  },
  DonorMovementSelect: {
    selectDonorMovement: 'Select donor movement',
    significantMovement: 'Significant movement',
    slightMovement: 'Slight movement',
    unableToSitStill: 'Unable to sit still',
    veryStill: 'Very still',
    notApplicable: 'N/A',
  },
  MovementTypeSelect: {
    selectMovementType: 'Select movement type',
    frontLegsKicking: 'Front legs kicking',
    backLegsKicking: 'Back legs kicking',
    headMovement: 'Head movement',
    needsShoulderHeldDown: 'Needs shoulder held down',
  },
  EmotionalSupportSelect: {
    selectEmotionalSupport: 'Select emotional support',
    likesEarRubs: 'Likes ear rubs',
    likesFaceKisses: 'Likes face kisses',
    likesNameSaid: 'Likes name said',
    likesNoseToNoseContact: 'Likes nose to nose contact',
    needsPersonsAtTheHeadToConsole: 'Needs persons at the head to console',
  },
  DislikesSelect: {
    selectDislikesSelect: 'Select dislikes',
    clippers: 'Clippers',
    earsTouched: 'Ears touched',
    feetTouched: 'Feet touched',
    pokes: 'Pokes',
    soundSensitivity: 'Sound sensitivity',
  },
  LikesSelect: {
    selectLikes: 'Select likes',
    earDials: 'Ear dials',
    emotionalSupporting: 'Emotional supporting',
    footSqueezing: 'Foot squeezing',
    gumRubs: 'Gum rubs',
    kisses: 'Kisses',
    roughPets: 'Rough pets',
    taps: 'Taps',
  },
  TreatmentPreferencesSelect: {
    selectTreatmentPreferences: 'Select treatment preferences',
    peanutButter: 'Peanut butter',
    milkBones: 'Milk bones',
    chicken: 'Chicken',
    doesNotLikeTreats: 'Does not like treats',
  },
  AdditionalDonationInfoForm: {
    close: 'Close',
    save: 'Save',
    edit: 'Edit',
    additionalDonationInfoCreated:
      'Donation additional info has been successfully created!',
    additionalDonationInfoUpdated:
      'Donation additional info has been successfully updated!',
  },
  Schemas: {
    required: '{{field}} is required!',
    invalid: '{{field}} is invalid!',
    packedCellVolume: 'Packed cell volume',
    capillaryRefillTime: 'Capillary refill time',
    mucousMembrane: 'Mucous membrane',
    weatherTypes: 'Weather type(s)',
    collectionSet: 'Collection set',
    jugularVein: 'Jugular vein',
    position: 'Position',
    donationUnit: 'Donation unit',
    actual: 'Actual',
    donationDateRequired: 'Donation date is required',
    usedCollectionUnits: 'Number of Collection Units Used',
    flowRate: 'Flow rate',
    veinUsed: 'Vein used',
    atLeastOneItem: '{{field}} must have at least 1 item',
    enterValidValues: 'Please enter valid values!',
  },
  VitalsSection: {
    vitalsSection: 'Vitals section',
    examination: 'Examination',
    donationInfo: 'Donation info',
    pickDonationDate: 'Pick donation date',
    donationDate: 'Donation date',
  },
  AdditionalDonationInfoSection: {
    additionalInfoSection: 'Additional info section',
  },
  AdditionalDonationInfoPage: {
    pageTitle: 'Additional donation info',
  },
  ValidationErrors: {
    mustBeNumber: 'Must be a number',
    required: '{{field}} is required!',
    charactersLimit: 'Only {{limit}} characters allowed!',
    totalCollectedMls: 'Total collected mls',
    itemsShouldBeLessThan:
      "Items in '{{field}}' field must contain less than {{max}} characters",
    holdersPosition: 'Holders position',
    donationPosition: 'Donation position',
    donorMovement: 'Donor movement',
    typeOfMovement: 'Type of movement',
    emotionalSupport: 'Emotional support',
    dislikes: 'Dislikes',
    likes: 'Likes',
    treatPreferences: 'Treat preferences',
  },
  PossibleDonationValuesDownload: {
    example: 'Example',
    possibleValue: 'Possible value',
    field: 'Field',
  },
  DonationDetails: {
    donationId: 'Donation ID',
    donorName: 'Donor name',
    ownerLastName: "Owner's last name",
    bloodType: 'Blood type',
    location: 'Location',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
  },
  DeleteDonationButton: {
    donationDeleted: 'Donation deleted successfully.',
    deleteDonationError: 'An error occurred while deleting the donation.',
    deleteDonationConfirmText: 'Are you sure you want to delete this donation?',
    deleteDonation: 'Delete donation',
    yes: 'Yes',
    no: 'No',
  },
};
