export const getSelectedKey = (pathname: string) => {
  const keyMappings: Record<string, string> = {
    reports: '/reports',
    locations: '/locations',
  };

  const selectedKeys = Object.keys(keyMappings).reduce<string[]>(
    (keys, keyword) => {
      if (pathname.includes(keyword)) {
        keys.push(keyMappings[keyword]);
      }
      return keys;
    },
    []
  );

  if (selectedKeys.length === 0) {
    selectedKeys.push(pathname);
  }

  return selectedKeys;
};
