import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Select } from 'modules/common/components';
import { FC, useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetLocationWithAvailableDaycareQuery } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  controlled: UseControllerProps<any>;
  multiply?: boolean;
} & SelectProps;

const OwnerLocationFilterSelect: FC<PropTypes> = ({
  controlled,
  className,
  multiply = true,
  ...restProps
}) => {
  const { t } = useTranslation('donor.LocationSelect');
  const classNames = `${styles.locationSelect} ${className || ''}`;
  const { field } = useController(controlled);

  const locationsQuery = useGetLocationWithAvailableDaycareQuery();

  const [selectLocationValue, setSelectLocationValue] = useState<string>(
    field.value ? field.value : undefined
  );

  useEffect(() => {
    if (field.value) {
      setSelectLocationValue(field.value);
    }
  }, [field.value]);

  const isLoading = locationsQuery.loading;
  const onChangeHandler = (value: string) => {
    setSelectLocationValue(value);
    const locations = value as unknown as string[];
    const [firstLocation, secondLocation] = locations;
    if (multiply) {
      field.onChange(locations);
      return;
    }
    const newSelectedValue = value.length
      ? [secondLocation || firstLocation]
      : undefined;
    field.onChange(newSelectedValue?.[0]);
  };
  const locations: DefaultOptionType[] | undefined =
    locationsQuery.data?.getLocationWithAvailableDaycare?.map(({ name }) => {
      return {
        label: name,
        value: name,
      };
    });

  useEffect(() => {
    field.value === undefined && setSelectLocationValue(field.value);
  }, [field.value]);

  return (
    <Select
      {...restProps}
      adjustDropdownPosition
      maxTagCount="responsive"
      allowClear
      className={classNames}
      options={locations}
      loading={isLoading}
      mode={'multiple'}
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      ref={field.ref}
      placeholder={t('selectLocation')}
      value={selectLocationValue}
    />
  );
};

export default OwnerLocationFilterSelect;
