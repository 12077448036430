import {
  ControlledInput,
  ControlledPhoneInput,
} from 'modules/common/components';
import { FormFieldWithComponent } from 'modules/common/types';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('owner.ProfilePage');

type NewType = {
  name: string;
};

export const fields: FormFieldWithComponent<string, NewType>[] = [
  {
    label: t('phone', { number: null }),
    name: 'phoneNumber',
    Component: ({ control, name }) => (
      <ControlledPhoneInput
        placeholder={t('enterPhone', { number: null })}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
  {
    label: t('changePassword'),
    name: 'password',
    Component: ({ control, name }) => (
      <ControlledInput
        placeholder={t('enterChangePassword', { number: null })}
        controlled={{
          control,
          name,
        }}
      />
    ),
  },
];
