import { SwapOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Divider, Radio, RadioChangeEvent } from 'antd';
import { Button } from 'modules/common/components';
import { UseSortObject } from 'modules/common/hooks/useSort';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DonorListSort, Order, SortDonorListInput } from 'types.d';

import styles from './styles.module.scss';

type PropTypes = {
  defaultValue?: SortDonorListInput;
  donorListSort?: UseSortObject<DonorListSort>;
};

const IndividualDonorListSort: FC<PropTypes> = ({
  defaultValue,
  donorListSort,
}) => {
  const { t } = useTranslation('donor.IndividualDonorListSort');
  const [isVisible, setIsVisible] = useState(false);
  const [sort, setSort] = useState<string>();

  const setIsVisibleTo = (value: boolean) => () => setIsVisible(value);

  const handleVisibleChange = (flag: boolean) => {
    setIsVisible(flag);
  };

  const filterHandler = () => {
    const splitedSortValues = sort?.split('.');
    const parsedSortValues = splitedSortValues
      ? {
          fieldName: splitedSortValues[0] as DonorListSort,
          order: splitedSortValues[1] as Order,
        }
      : undefined;
    if (parsedSortValues) {
      donorListSort?.setSortOption(parsedSortValues);
    }
    setIsVisible(false);
  };

  const onRadioChange = (event: RadioChangeEvent) => {
    setSort(event.target.value);
  };

  useEffect(() => {
    const sortValue = defaultValue
      ? `${defaultValue?.fieldName}.${defaultValue?.order}`
      : undefined;
    setSort(sortValue);
  }, [defaultValue]);

  const sortOptions: DonorListSort[] = [
    DonorListSort.Name,
    DonorListSort.LastName,
  ];

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={isVisible}
      overlayClassName={styles.dropdownOverlay}
      trigger={['click']}
      overlay={
        <Row>
          <Radio.Group value={sort} onChange={onRadioChange}>
            {sortOptions.map((fieldName) => (
              <Col span={24} key={fieldName}>
                <Row>
                  <Col span={24} className={styles.title}>
                    {t(fieldName.charAt(0).toLowerCase() + fieldName.slice(1))}
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Col span={12}>
                    <Radio value={`${fieldName}.${Order.Asc}`}>
                      {t(Order.Asc)}
                    </Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={`${fieldName}.${Order.Desc}`}>
                      {t(Order.Desc)}
                    </Radio>
                  </Col>
                </Row>
              </Col>
            ))}
          </Radio.Group>
          <Divider />
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Button onClick={setIsVisibleTo(false)}>{t('cancel')}</Button>
              </Col>
              <Col span={12}>
                <Button type="primary" onClick={filterHandler}>
                  {t('sort')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <SwapOutlined
        className={styles.sortIcon}
        onClick={setIsVisibleTo(true)}
      />
    </Dropdown>
  );
};

export default IndividualDonorListSort;
