import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const QuestionsSection: FC = () => {
  const { t } = useTranslation('donor.RoutineTest.QuestionsSection');

  return <Row className={styles.questionsSection}>{t('text')}</Row>;
};
