import { LOCALE_STORAGE_KEYS } from 'config/localStorageKeys';

import { donorCachingQueries } from './donorCachingQueries';
import { getAppointmentDonors, getDonorsByIds } from './queries/donors/';

const CURRENT_DATE = new Date();

class OfflineServices {
  async prepareCache() {
    await this.prepareDonorsCache();
  }

  private async prepareDonorsCache() {
    const { data } = await getAppointmentDonors();
    const donorsToCache = data.getAppointmentsDonorsList || [];
    await Promise.all(
      donorsToCache.map(async ({ id }) => {
        await Promise.all(
          donorCachingQueries.map(async (query) => {
            await query(id);
          })
        );
      })
    );

    //TODO: refactor code below.
    const donorsExpirationJson = localStorage.getItem(
      LOCALE_STORAGE_KEYS.DONOR_IDS_EXPIRATION_DATE
    );
    const donorsExpirationDate = donorsExpirationJson
      ? new Date(donorsExpirationJson)
      : null;

    if (donorsExpirationDate && CURRENT_DATE < donorsExpirationDate) {
      const donorIdsJson =
        localStorage.getItem(LOCALE_STORAGE_KEYS.DONOR_IDS) || '';
      const additionalDonorIds: string[] =
        donorIdsJson !== '' ? JSON.parse(donorIdsJson) : [];
      await getDonorsByIds(additionalDonorIds);
      additionalDonorIds.map((id) => {
        donorCachingQueries.forEach((query) => query(id));
      });
    }

    if (donorsExpirationDate && CURRENT_DATE >= donorsExpirationDate) {
      localStorage.removeItem(LOCALE_STORAGE_KEYS.DONOR_IDS);
      localStorage.removeItem(LOCALE_STORAGE_KEYS.DONOR_IDS_EXPIRATION_DATE);
    }
  }
}

export default new OfflineServices();
