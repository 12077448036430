import {
  CreateLogStreamCommand,
  PutLogEventsCommand,
  GetLogEventsCommand,
} from '@aws-sdk/client-cloudwatch-logs';
import { envServices } from 'utils/EnvServices';

import { CloudWatchClient } from './CloudWatchClient';
import { IPutLogEventsInput } from './interfaces';

const logGroupName = envServices.get('REACT_APP_LOG_GROUP_NAME');

class CloudWatchCommands extends CloudWatchClient {
  protected getLogEvents(logStreamName: string) {
    try {
      return this.client.send(
        new GetLogEventsCommand({
          logGroupName,
          logStreamName,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  protected createLogStream(logStreamName: string) {
    return this.client.send(
      new CreateLogStreamCommand({
        logGroupName,
        logStreamName,
      })
    );
  }

  async putLogEvents({
    logEvents,
    logStreamName,
    sequenceToken,
  }: IPutLogEventsInput) {
    return this.client.send(
      new PutLogEventsCommand({
        logEvents,
        logGroupName,
        logStreamName,
        sequenceToken,
      })
    );
  }
}

export { CloudWatchCommands };
