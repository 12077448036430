import { NavigateState } from 'modules/navigation/types';
import { useLocation } from 'react-router-dom';

export const useLocationState = () => {
  const data = useLocation().state as NavigateState | null;

  const clearLocationState = () => {
    window?.history?.replaceState({}, document?.title);
  };

  return {
    data,
    clearLocationState,
  };
};
