import {
  ControlledPasswordInput,
  ControlledInput,
} from 'modules/common/components';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('auth.SignInForm');

export enum SignInFields {
  email = 'email',
  password = 'password',
}

export const signInFormFields = [
  {
    label: t('email'),
    placeholder: t('enterEmail'),
    name: SignInFields.email,
    Component: ControlledInput,
    type: 'email',
    errorsEnabled: true,
  },
  {
    label: t('password'),
    placeholder: t('enterPassword'),
    name: SignInFields.password,
    Component: ControlledPasswordInput,
    type: 'password',
    errorsEnabled: false,
  },
];
