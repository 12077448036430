import { SelectProps } from 'antd';
import { LabworkStatus } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.LabworkStatusSelect');

export const options: SelectProps['options'] = Object.values(LabworkStatus).map(
  (status) => {
    return {
      value: status,
      label: t(`${status[0].toLowerCase()}${status.slice(1)}`),
    };
  }
);
