import {
  donationInfoSchema,
  DonationInfoSchemaType,
} from 'modules/donations/components/DonationInfoSection/schema';
import {
  environmentSchema,
  EnvironmentSchemaType,
} from 'modules/donations/components/EnvironmentSection/schema';
import {
  examinationSchema,
  ExaminationSchemaType,
} from 'modules/donations/components/ExaminationSection/schema';
import {
  quantitySchema,
  QuantitySchemaType,
} from 'modules/donations/components/QuantitySection/schema';
import { donationDateSchema } from 'modules/donations/schemas';
import * as yup from 'yup';

type EditDonationSchemaType = {
  examination: ExaminationSchemaType;
  donationInfo: DonationInfoSchemaType;
  environment: EnvironmentSchemaType;
  quantity: QuantitySchemaType;
  donorId: string;
  donationDate: Date;
};

const editDonationSchema: yup.SchemaOf<EditDonationSchemaType> = yup.object({
  examination: examinationSchema,
  donationInfo: donationInfoSchema,
  environment: environmentSchema,
  quantity: quantitySchema,
  donorId: yup.string().max(255).required(),
  donationDate: donationDateSchema,
});

export { editDonationSchema };
export type { EditDonationSchemaType };
