import { Button, Divider, Table, message } from 'antd';
import { GET_ALL_PERMISSIONS_QUERY } from 'modules/admin/graphql/queries';
import { Spin } from 'modules/common/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUpdateAllPermissionsMutation,
  PermissionsOutput,
  useGetPermissionsQuery,
} from 'types.d';
import { deepEqual } from 'utils/deepEqual';

import { getColumns, dataSource } from './columns';
import styles from './styles.module.scss';

const PermissionsTable = () => {
  const { t } = useTranslation('admin.PermissionsPage');
  const [permissionsList, setPermissionsList] = useState<PermissionsOutput[]>(
    []
  );
  const [initialPermissionsList, setInitialPermissionsList] = useState<
    PermissionsOutput[]
  >([]);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const permissionsQuery = useGetPermissionsQuery({
    variables: {
      input: {},
    },
    onCompleted: (data) => {
      setPermissionsList(data.getPermissions as PermissionsOutput[]);
      setInitialPermissionsList(data.getPermissions as PermissionsOutput[]);
    },
  });

  useEffect(() => {
    setIsSaveButtonActive(!deepEqual(permissionsList, initialPermissionsList));
  }, [permissionsList, initialPermissionsList]);

  const [updateAllPermissions, updateAllPermissionsMutation] =
    useUpdateAllPermissionsMutation({
      refetchQueries: [GET_ALL_PERMISSIONS_QUERY],
      onError: (error) => {
        message.error(error.message);
      },
      onCompleted: () => {
        message.success(t('success'));
      },
    });

  const isLoading =
    permissionsQuery.loading || updateAllPermissionsMutation.loading;

  const saveHandler = () => {
    setIsSaveButtonActive(false);
    const permissions = permissionsList.map(
      ({ __typename, permission, role, ...rest }) => {
        return rest;
      }
    );

    updateAllPermissions({
      variables: {
        input: {
          values: permissions,
        },
      },
    });
  };

  const tableColumns = getColumns({
    onCellClick: ({ permissionId, value }) => {
      const updatedRolePermissions = permissionsList.map((permission) => {
        if (permission.id === permissionId) {
          return { ...permission, value };
        }
        return permission;
      });
      setPermissionsList(updatedRolePermissions);
    },
    permissionsList,
  });

  return (
    <Spin spinning={isLoading} size="large">
      <Table
        rowKey={({ permissionName }) => permissionName}
        columns={tableColumns}
        dataSource={dataSource}
        bordered
        scroll={{ x: 750, y: '60vh' }}
        pagination={false}
      />
      <Divider className={styles.divider} />
      <Button
        className={styles.submitButton}
        onClick={saveHandler}
        disabled={!isSaveButtonActive}
      >
        Save
      </Button>
    </Spin>
  );
};

export default PermissionsTable;
