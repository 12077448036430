import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Table, message } from 'antd';
import { Spin } from 'modules/common/components';
import DonorsTable from 'modules/donor/components/DonorsTable';
import { getTeamsColumns } from 'modules/donor/components/TeamsTable/columns';
import {
  TeamTitleFormSchema,
  teamTitleFormSchema,
} from 'modules/donor/components/TeamsTable/schema';
import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  TeamOutput,
  useFindGroupQuery,
  useUpdateDonorMutation,
  DonorOutput,
} from 'types.d';

//TODO: add sorting
const TeamsTable: FC = () => {
  const { t } = useTranslation('donor.TeamsTable');
  const [searchParams] = useSearchParams();
  const params = useParams();
  const teamId = searchParams.get('teamId');
  const groupId = params.id || '';
  const { data: groupData, loading: findGroupLoading } = useFindGroupQuery({
    variables: {
      input: {
        id: groupId,
      },
    },
  });
  const [currentTeam, setCurrentTeam] = useState<TeamOutput>();
  const [isEditMode, setIsEditMode] = useState(false);

  const teamTitleForm = useForm<TeamTitleFormSchema>({
    resolver: yupResolver(teamTitleFormSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isEditMode && teamTitleForm.formState.isDirty) {
      teamTitleForm.reset();
    }
  }, [isEditMode]);

  const [updateDonor, { loading: updateDonorLoading }] = useUpdateDonorMutation(
    {
      refetchQueries: ['findGroup', 'getUserDonorsList'],
      onCompleted: () => {
        message.success(t('successfullyUpdated'));
      },
      onError: ({ message: m }) => {
        message.error(m);
      },
    }
  );

  const removeDonorFromTeamHandler = (donor: DonorOutput) => {
    updateDonor({
      variables: {
        input: {
          id: donor.id,
          teamId: null,
        },
      },
    });
  };

  const isLoading = findGroupLoading || updateDonorLoading;
  const columns = getTeamsColumns({
    form: teamTitleForm,
    isEditMode: isEditMode,
    onToggleEditMode: setIsEditMode,
    currentTeam,
    setCurrentTeam,
  });
  const teams = (groupData?.findGroup.teams as TeamOutput[]) || [];
  const owner = groupData?.findGroup.user;
  return (
    <Spin spinning={isLoading}>
      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={teams}
        pagination={false}
        expandable={{
          defaultExpandedRowKeys: teamId ? [teamId] : undefined,
          expandedRowRender: ({ donors }) => {
            const mappedDonors = donors?.map((donor) => {
              return { ...donor, user: owner };
            });
            return (
              <DonorsTable
                withoutSearch
                donors={mappedDonors || []}
                showRemoveColumn={true}
                removeHandler={removeDonorFromTeamHandler}
              />
            );
          },
        }}
      />
    </Spin>
  );
};

export default TeamsTable;
