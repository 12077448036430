import { Col, Row, Table } from 'antd';
import { Routes } from 'config/routes';
import {
  FormItem,
  Link,
  ControlledCheckbox,
  ControlledTextArea,
  ControlledSelect,
} from 'modules/common/components';
import styles from 'modules/donations/components/CreateDonationForm/styles.module.scss';
import { getColumns } from 'modules/donations/components/ExaminationSection/columns';
import { ExaminationFields } from 'modules/donations/components/ExaminationSection/enum';
import { ExaminationFormFiledType } from 'modules/donations/components/ExaminationSection/fields';
import { IExaminationTableData } from 'modules/donations/components/ExaminationSection/interfaces';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DonationOutput, IneligibleReason } from 'types.d';

type Props = {
  fieldNamePrefix: string;
  fields: ExaminationFormFiledType[];
  previousDonation?: DonationOutput;
  showLinkToPreviousDonation?: boolean;
  sectionNamePrefix?: string;
  formEnabled?: boolean;
};

const DesktopExaminationSectionView: FC<Props> = ({
  fieldNamePrefix,
  fields,
  previousDonation,
  showLinkToPreviousDonation,
  sectionNamePrefix,
  formEnabled,
}) => {
  const formContext = useFormContext();
  const { t } = useTranslation('donation.CreateDonationForm.Examination');

  const withPrefix = (fieldName: ExaminationFields): string => {
    const sectionName = sectionNamePrefix ? sectionNamePrefix + '.' : '';
    return `${sectionName}${fieldNamePrefix}.${fieldName}`;
  };

  const dataSource: IExaminationTableData[] = fields.reduce(
    (previousField, currentField) =>
      currentField.name === ExaminationFields.examinationNotes
        ? previousField
        : [
            ...previousField,
            {
              characteristic: currentField.name,
              previousDonation,
              value: currentField,
            },
          ],
    [] as IExaminationTableData[]
  );
  const examinationNotes = fields.find(
    (field) => field.name === ExaminationFields.examinationNotes
  );
  const ExaminationNotesComponent = examinationNotes!.Component;
  const examinationName = withPrefix(ExaminationFields.examinationNotes);
  const sectionErrors = sectionNamePrefix
    ? formContext.formState.errors[sectionNamePrefix]
    : formContext.formState.errors;
  const examinationErrorMessage =
    sectionErrors?.[fieldNamePrefix]?.[ExaminationFields.examinationNotes]
      ?.message;
  const isIneligible: boolean | undefined = formContext.watch(
    withPrefix(ExaminationFields.ineligible)
  );

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Table
          rowKey={({ characteristic }) => characteristic}
          columns={getColumns(
            formContext,
            fieldNamePrefix,
            previousDonation === undefined,
            sectionNamePrefix
          )}
          dataSource={dataSource}
          size="small"
          pagination={false}
        />
      </Col>
      <Col span={8}>
        <Row>
          <Col span={24}>
            <FormItem
              key={examinationNotes?.name}
              className={styles.formItem}
              extra={examinationErrorMessage}
              validateStatus={examinationErrorMessage && 'error'}
            >
              <ExaminationNotesComponent
                control={formContext.control}
                name={examinationName}
              />
              {showLinkToPreviousDonation && (
                <Link
                  target="_blank"
                  to={`${Routes.Donations}/${previousDonation?.shortId}`}
                >
                  {t('previousDonation')}
                </Link>
              )}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem className={styles.formItem}>
              <ControlledCheckbox
                disabled={!formEnabled}
                controlled={{
                  control: formContext.control,
                  name: withPrefix(ExaminationFields.ineligible),
                }}
              >
                {t('donationIneligible')}
              </ControlledCheckbox>
            </FormItem>
          </Col>
          {isIneligible && (
            <>
              <Col span={24}>
                <FormItem
                  label={t('ineligibleReason')}
                  className={styles.formItem}
                >
                  <ControlledSelect
                    disabled={!formEnabled}
                    controlled={{
                      control: formContext.control,
                      name: withPrefix(ExaminationFields.ineligibleReason),
                    }}
                    options={Object.values(IneligibleReason).map((value) => ({
                      value,
                      label: t(value),
                    }))}
                    placeholder={t('selectReason')}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label={t('ineligibleReasonDetails')}
                  className={styles.formItem}
                >
                  <ControlledTextArea
                    disabled={!formEnabled}
                    controlled={{
                      control: formContext.control,
                      name: withPrefix(
                        ExaminationFields.ineligibleReasonDetails
                      ),
                    }}
                  />
                </FormItem>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};
export default DesktopExaminationSectionView;
