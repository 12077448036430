import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Spin, message } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { BaseModalProps } from 'modules/common/types';
import { FC, MouseEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AppointmentOutput,
  DaycareRegistrationStatus,
  useGetDaycareAppointmentInfoForCurrentUserLazyQuery,
  useRegisterOnDaycareAppointmentMutation,
} from 'types.d';

import {
  Answers,
  RegisterDaycareAppointmentSchema,
  registerDaycareAppointmentSchema,
} from './schema';
import styles from './styles.module.scss';
import { DaycareRegisterModalHeader } from './UI/DaycareRegisterModalHeader';
import { RegisteredDonors } from './UI/RegisteredDonors';
import { WaitingForApprovalDonors } from './UI/WaitingForApprovalDonors';

import { DaycareRegisterForm } from '../DaycareRegisterForm';

type PropTypes = {
  appointment?: AppointmentOutput;
} & BaseModalProps;

export const RegisterOnDaycareAppointmentModal: FC<PropTypes> = ({
  appointment,
  visible,
  onCancel,
  onOk,
  hide,
  ...restModalProps
}) => {
  const { t } = useTranslation('appointments.registerDaycareModal');
  const registerDaycareAppointmentForm =
    useForm<RegisterDaycareAppointmentSchema>({
      resolver: yupResolver(registerDaycareAppointmentSchema),
      mode: 'onChange',
    });
  const [registerOnDaycare, registerOnDaycareMutation] =
    useRegisterOnDaycareAppointmentMutation();
  const [getDaycareAppointmentInfo, daycareAppointmentInfoQuery] =
    useGetDaycareAppointmentInfoForCurrentUserLazyQuery();

  useEffect(() => {
    if (!appointment) {
      return;
    }

    const getInfo = async () => {
      await getDaycareAppointmentInfo({
        fetchPolicy: 'network-only',
        variables: {
          appointmentId: appointment.id,
        },
      });
    };

    getInfo();
  }, [appointment]);
  const isOkButtonActive = !registerDaycareAppointmentForm.formState.isValid;
  const cancelHandler = (e: MouseEvent<HTMLElement>) => {
    onCancel && onCancel(e);
    hide();
    registerDaycareAppointmentForm.reset();
  };

  const submitHandler = registerDaycareAppointmentForm.handleSubmit(
    async (registerData) => {
      if (!appointment) {
        return;
      }
      const { answer, donors, note } = registerData;
      const ownerResponse = answer === Answers.Yes;

      if (ownerResponse && !donors?.length) {
        return;
      }

      await registerOnDaycare({
        variables: {
          input: {
            appointmentId: appointment.id,
            ownerResponse: answer === Answers.Yes,
            donorIds: donors as string[],
            note,
          },
        },
        onCompleted() {
          const messageText =
            answer === Answers.Yes
              ? t('donorRegistered')
              : t('thanksForAnswer');
          message.success(messageText);
          hide();
          registerDaycareAppointmentForm.reset();
        },
        onError() {
          message.error(t('registerError'));
          hide();
          registerDaycareAppointmentForm.reset();
        },
        refetchQueries: ['getDaycareAppointmentInfoForCurrentUser'],
      });
    }
  );

  const okHandler = async (e: MouseEvent<HTMLElement>) => {
    onOk && onOk(e);
    await submitHandler();
  };

  if (!appointment) {
    return null;
  }

  const start = new Date(appointment.start);
  const end = new Date(appointment.end);

  const date = format(start, config.DATE_FORMAT);
  const startHour = format(start, config.TIME_FORMAT);
  const endHour = format(end, config.TIME_FORMAT);

  const status =
    daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfoForCurrentUser
      .status;
  const requests =
    daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfoForCurrentUser
      .requests;
  const registeredDonors =
    daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfoForCurrentUser
      .registeredDonors;
  const daycareId =
    daycareAppointmentInfoQuery.data?.getDaycareAppointmentInfoForCurrentUser
      .daycareId || undefined;
  return (
    <Modal
      className={styles.modal}
      visible={visible}
      onOk={okHandler}
      onCancel={cancelHandler}
      okButtonProps={{
        disabled: isOkButtonActive,
        loading: registerOnDaycareMutation.loading,
      }}
      {...restModalProps}
    >
      <Spin spinning={daycareAppointmentInfoQuery.loading}>
        <DaycareRegisterModalHeader
          date={date}
          end={endHour}
          start={startHour}
          location={appointment.location}
        />
        {status === DaycareRegistrationStatus.Unregister && (
          <>
            <FormProvider {...registerDaycareAppointmentForm}>
              <DaycareRegisterForm location={appointment.location} />
            </FormProvider>
            <p className={styles.helper}>{t('helperMessage')}</p>
          </>
        )}
        {status === DaycareRegistrationStatus.Registered && (
          <p className={styles.alreadyRegistered}>{t('alreadyRegistered')}</p>
        )}
        {!!registeredDonors?.length && (
          <RegisteredDonors
            registeredDonors={registeredDonors}
            daycareId={daycareId}
          />
        )}
        {!!requests?.length && (
          <WaitingForApprovalDonors daycareRequests={requests} />
        )}
        {status === DaycareRegistrationStatus.RegisteredWithNo && (
          <>
            <p className={styles.alreadyRegistered}>
              {t('alreadyRegisteredWithNoAnswer')}
            </p>
            <p className={styles.helper}>{t('helperMessage')}</p>
          </>
        )}
      </Spin>
    </Modal>
  );
};
