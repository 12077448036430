import { Sorter } from 'modules/common/components';
import { useSort } from 'modules/common/hooks';
import { options } from 'modules/donor/components/GroupDonorCardList/GroupDonorCardListSorter/options';
import { FC } from 'react';
import { DonorListSort } from 'types.d';

const useDonorListSort = () => useSort<DonorListSort>();

type PropTypes = {
  sorting: ReturnType<typeof useDonorListSort>;
};

export const GroupDonorCardListSorter: FC<PropTypes> = ({ sorting }) => (
  <Sorter sorting={sorting as ReturnType<typeof useSort>} options={options} />
);
