import { Card, Col, Pagination, Row, message } from 'antd';
import config from 'config';
import { DeletePopconfirm, SearchInput, Spin } from 'modules/common/components';
import { useDebounce, useSort } from 'modules/common/hooks';
import GroupDonorCardInfo from 'modules/donor/components/GroupDonorCardInfo';
import { GroupDonorCardListSorter } from 'modules/donor/components/GroupDonorCardList/GroupDonorCardListSorter';
import { IndividualDonorListFilter } from 'modules/donor/components/index';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { GET_USER_DONOR_LIST_QUERY } from 'modules/owner/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  DonorOutput,
  DonorListFilterInput,
  DonorListSort,
  useFindGroupQuery,
  useGetUserDonorsListQuery,
  useRemoveDonorMutation,
  useUpdateDonorMutation,
  PermissionsEnum,
} from 'types.d';

type PropTypes = {
  showRemoveColumn?: boolean;
  showSorting?: boolean;
  showFilters?: boolean;
};

const GroupDonorCardList: FC<PropTypes> = ({
  showRemoveColumn,
  showSorting,
  showFilters,
}) => {
  const { t } = useTranslation('donor.GroupDonorCardList');
  const { debounce } = useDebounce();
  const params = useParams();
  const groupId = params.id || '';
  const groupQuery = useFindGroupQuery({
    variables: {
      input: {
        id: groupId,
      },
    },
  });

  const userId =
    (groupQuery.data && groupQuery.data?.findGroup?.user?.id) || '';
  const donorListSort = useSort<DonorListSort>();
  const pagination = usePagination();
  const [filter, setFilter] = useState<DonorListFilterInput>({});
  const setFilterHandler = (filterValue?: DonorListFilterInput) => {
    setFilter({ ...filter, ...filterValue });
  };

  const getUserDonorsListQueryVariables = {
    variables: {
      input: {
        userId,
        sort: donorListSort.options,
        skip: pagination.skip,
        take: config.DEFAULT_LIMIT,
        filter,
      },
    },
  };
  const { data: userDonorsData, loading: gettingDonorsLoading } =
    useGetUserDonorsListQuery({
      ...getUserDonorsListQueryVariables,
      onCompleted: ({ getUserDonorsList }) => {
        pagination.setTotal(getUserDonorsList.meta.total);
      },
    });

  const [removeDonor, { loading: removeDonorLoading }] = useRemoveDonorMutation(
    {
      refetchQueries: [
        {
          query: GET_USER_DONOR_LIST_QUERY,
          ...getUserDonorsListQueryVariables,
        },
        'findGroup',
      ],
      onCompleted: () => {
        message.success(t('successfullyDeleted'));
      },
      onError: ({ message: m }) => {
        message.error(m);
      },
      awaitRefetchQueries: true,
    }
  );

  const removeDonorHandler = (donorId: string) => {
    removeDonor({
      variables: {
        input: {
          id: donorId,
        },
      },
    });
  };

  const [updateDonor, { loading: updateDonorLoading }] = useUpdateDonorMutation(
    {
      refetchQueries: [
        {
          query: GET_USER_DONOR_LIST_QUERY,
          ...getUserDonorsListQueryVariables,
        },
        'findGroup',
      ],
      onCompleted: () => {
        message.success(t('successfullyUpdated'));
      },
      onError: ({ message: m }) => {
        message.error(m);
      },
      awaitRefetchQueries: true,
    }
  );

  const changeDonorTeamHandler = (donor: DonorOutput, teamId?: string) => {
    updateDonor({
      variables: {
        input: {
          id: donor.id,
          teamId: teamId ? teamId : null,
        },
      },
    });
  };

  const isLoading =
    gettingDonorsLoading || updateDonorLoading || removeDonorLoading;
  const currentUser = useCurrentUser();
  const canArchiveDonors = currentUser.isCan(PermissionsEnum.ArchiveDonors);
  const showRemove = showRemoveColumn && canArchiveDonors && false;
  const donors = userDonorsData?.getUserDonorsList.data;
  const teams = groupQuery.data?.findGroup.teams;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchingQuery = e.target.value || undefined;
    setFilter((previousFilter) => {
      return {
        ...previousFilter,
        searchingQuery,
      };
    });
  };

  return (
    <Row gutter={[0, 15]}>
      <Col span={24}>
        <SearchInput
          onChange={debounce(handleSearch)}
          placeholder={t('searchDonor')}
          maxLength={100}
        />
      </Col>
      <Col span={24}>
        <Spin spinning={isLoading}>
          <Row gutter={[0, 30]}>
            <Row gutter={[20, 0]} justify="space-evenly">
              {showFilters && (
                <Col>
                  <IndividualDonorListFilter
                    defaultValues={filter}
                    onFilter={setFilterHandler}
                  />
                </Col>
              )}
              {showSorting && (
                <Col>
                  <GroupDonorCardListSorter sorting={donorListSort} />
                </Col>
              )}
            </Row>
            <Col span={24}>
              <Row gutter={[0, 20]}>
                {donors?.map((donor) => {
                  return (
                    <Col key={donor.id} span={24}>
                      <Card
                        title={donor.name}
                        extra={
                          showRemove && (
                            <DeletePopconfirm
                              title={t('sureToDelete')}
                              onConfirm={() => removeDonorHandler(donor.id)}
                            />
                          )
                        }
                      >
                        <GroupDonorCardInfo
                          donor={donor as DonorOutput}
                          teams={teams}
                          changeDonorTeamHandler={changeDonorTeamHandler}
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Pagination {...pagination.config} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
};

export default GroupDonorCardList;
