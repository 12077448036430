import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useState, FC, useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

import Select from '../Select';

type PropTypes = {
  size?: SizeType;
  disabled?: boolean;
  controlled: UseControllerProps<any>;
};

const t = getTranslation('common.yearMonthSelect');

const months = [
  { label: t('january'), value: 0 },
  { label: t('february'), value: 1 },
  { label: t('march'), value: 2 },
  { label: t('april'), value: 3 },
  { label: t('may'), value: 4 },
  { label: t('june'), value: 5 },
  { label: t('july'), value: 6 },
  { label: t('august'), value: 7 },
  { label: t('september'), value: 8 },
  { label: t('october'), value: 9 },
  { label: t('november'), value: 10 },
  { label: t('december'), value: 11 },
];

export const YearMonthSelect: FC<PropTypes> = ({
  size,
  controlled,
  disabled,
}) => {
  const { field } = useController(controlled);
  const controlledDate = field.value;
  const [selectedYear, setSelectedYear] = useState<number | null>(
    controlledDate ? controlledDate.getUTCFullYear() : null
  );
  const [selectedMonth, setSelectedMonth] = useState<number | null>(
    controlledDate ? controlledDate.getUTCMonth() : null
  );
  const currentYear = new Date().getUTCFullYear();
  const years = Array.from({ length: 31 }, (_, i) => currentYear - i);

  const handleDateChange = (year: number | null, month: number | null) => {
    if (year !== null && month !== null) {
      const newDate = new Date(Date.UTC(year, month, 1));
      field.onChange(newDate);
    }
  };

  useEffect(() => {
    if (!controlledDate) {
      return;
    }

    const year = controlledDate.getUTCFullYear();
    const month = controlledDate.getUTCMonth();

    setSelectedYear(year);
    setSelectedMonth(month);
  }, [controlledDate]);

  const handleMonthChange = (month: number) => {
    setSelectedMonth(month);
    handleDateChange(selectedYear, month);
  };

  const handleYearChange = (year: number) => {
    setSelectedYear(year);
    handleDateChange(year, selectedMonth);
  };

  return (
    <div className={styles.selectsWrapper}>
      <Select
        options={years.map((year) => ({
          label: year.toString(),
          value: year.toString(),
        }))}
        size={size}
        onChange={handleYearChange}
        placeholder={t('selectYear')}
        value={selectedYear}
        disabled={disabled}
      />
      <Select
        size={size}
        options={months}
        onChange={handleMonthChange}
        placeholder={t('selectMonth')}
        value={selectedMonth}
        disabled={disabled}
      />
    </div>
  );
};
