// eslint-disable-next-line unused-imports/no-unused-imports
import { Col, Row } from 'antd';

import styles from './styles.module.scss';

type ColumnFieldType =
  | {
      fieldName: string;
      withInput?: boolean;
      checked?: boolean | string;
      inputValue?: string;
      fields?: ColumnFieldType[];
      addToRow?: string[];
      optionsToChoose?: string[];
    }
  | string;

export interface IColumnFieldsConfig {
  title: string;
  subTitle?: string;
  subTitleSize?: 'sm' | 'md';
  optionsToChoose?: string[];
  fields: ColumnFieldType[];
}

function buildField(field: ColumnFieldType) {
  if (typeof field === 'string') {
    return (
      <div className={styles.checkboxWrapper}>
        <input type="checkbox" />
        <label>{field}</label>
      </div>
    );
  }

  const applyAddToRow = !!field.addToRow && field.addToRow?.length > 0;

  return (
    <div className={applyAddToRow ? styles.checkboxRows : ''}>
      <div className={styles.checkboxWrapper}>
        <input type="checkbox" />
        <div className={styles.inputWrapper}>
          <label>{field.fieldName}</label>
          {field.withInput && <input className={styles.input} />}
        </div>
      </div>
      {applyAddToRow &&
        field?.addToRow?.map((value) => {
          return (
            <div className={`${styles.checkboxWrapper} ${styles.rowCheckbox}`}>
              <input type="checkbox" />
              <label>{value}</label>
            </div>
          );
        })}
      <div className={styles.subFields}>
        {field.fields && field.fields.map(buildField)}
      </div>
    </div>
  );
}

function buildFieldWithAcceptableAnswers(section: IColumnFieldsConfig) {
  return (
    <div>
      <Row className={styles.optionsLabels}>
        {section.optionsToChoose?.map((option) => {
          return <p>{option}</p>;
        })}
      </Row>
      <Row>
        {section.fields.map((field, i) => {
          const isFieldObject = typeof field === 'object';

          const isShowCheckbox = (option: string) => {
            const hasOptionsToChooseProperty =
              isFieldObject && !!field.optionsToChoose?.length;
            const showCurrentOption =
              hasOptionsToChooseProperty &&
              section.optionsToChoose &&
              field.optionsToChoose?.includes(option);
            const displayCheckbox =
              !isFieldObject ||
              !hasOptionsToChooseProperty ||
              showCurrentOption;

            return displayCheckbox;
          };

          const checkboxCheckedProperty = isFieldObject ? field.checked : false;

          const checkIsChecked = (option: string) => {
            if (!checkboxCheckedProperty) {
              return false;
            }

            if (typeof checkboxCheckedProperty === 'string') {
              return checkboxCheckedProperty === option;
            }

            return checkboxCheckedProperty;
          };

          return (
            <div className={styles.optionsCheckboxWrapper}>
              {section.optionsToChoose?.map((option) => {
                return isShowCheckbox(option) ? (
                  <input type="checkbox" checked={checkIsChecked(option)} />
                ) : (
                  <span className={styles.space}></span>
                );
              })}

              <div
                className={`${styles.inputWrapper} ${styles.optionsInputWrapper}`}
              >
                <label>{isFieldObject ? field.fieldName : field}</label>
                {isFieldObject && field.withInput && (
                  <input className={styles.input} value={field.inputValue} />
                )}
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );
}

export function buildColumns(config: IColumnFieldsConfig[]) {
  return config.map((section) => {
    const containFieldsWithSeveralOptions = !!section.optionsToChoose?.length;

    return (
      <Row>
        <Row className={styles.columnSectionTitleWrapper}>
          <h4 className={styles.columnSectionTitle}>{section.title}</h4>
        </Row>
        <span
          className={`${styles.columnSectionSubTitle} ${section.subTitleSize}`}
        >
          {section.subTitle}
        </span>
        <Row>
          {containFieldsWithSeveralOptions
            ? buildFieldWithAcceptableAnswers(section)
            : section.fields.map(buildField)}
        </Row>
      </Row>
    );
  });
}
